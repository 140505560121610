import {
  DialogContent,
  DialogContentText,
  makeStyles
} from "@material-ui/core";
import CountdownBar from "../../../../general/CountdownBar";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  root: {
    [theme.breakpoints.up("md")]: {
      width: "640px"
    },
    "& > .MuiDialogContentText-root": {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "20px"
      },
      paddingBottom: "20px"
    },
    "& > .MuiDialogActions-root": {
      paddingBottom: "20px"
    }
  },
  textfield: {
    marginBottom: "20px"
  },
  fileList: {
    margin: "0 -24px -8px"
  },
  countdownBar: {}
}));

export default ({ orderType, type, files, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <DialogContent className={classes.root}>
        <DialogContentText>
          {t("UploadDoneText1")}

          <br />
          {t("UploadDoneText2")}

          <br />
          {t("UploadDoneText3")}
        </DialogContentText>
      </DialogContent>
      <div className={classes.countdownBar}>
        <CountdownBar seconds={5} onFinish={onClose} />
      </div>
    </>
  );
};
