import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useTranslation } from "react-i18next";
import useEventTracking from "../../../hooks/useEventTracking";

const useStyles = makeStyles(theme => ({
  popover: {
    filter: "drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.32))",
    color: "rgba(0, 0, 0, 0.87)",
    "& .MuiMenuItem-root": {
      paddingTop: "12px",
      paddingBottom: "12px",
      minHeight: "auto",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "0.25px"
    }
  },
  [theme.breakpoints.up("md")]: {
    tagLineButton: {
      position: "absolute",
      top: "8px",
      right: "8px"
    }
  }
}));

const PickupsCardMenu = ({
  id,
  canConfirmPickup,
  canReschedulePickup,
  setUploadDialogOpen,
  isDemoEnviroment
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={classes.tagLineButton}>
      <IconButton
        aria-label="settings"
        aria-controls="simple-menu"
        aria-haspopup="true"
        aria-describedby="simple-menu"
        onClick={handleClick}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted={process.env.NODE_ENV !== "test"}
        elevation={0}
        open={Boolean(anchorEl)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={handleClose}
        className={classes.popover}
      >
        <MenuItem
          disabled={!canConfirmPickup}
          onClick={() => {
            handleClose();
            TrackEvent("Pick up confirmation started", {
              clickSource: "Elipsis",
              orderId: id
            });
            history.push(`/pickups?confirm=${id}`, {
              background: location
            });
          }}
        >
          {t("Pickups_ConfirmPickup")}
        </MenuItem>

        <MenuItem
          disabled={!canReschedulePickup || isDemoEnviroment}
          onClick={() => {
            handleClose();
            TrackEvent("Reschedule pickup started", {
              orderId: id,
              clickSource: "Button"
            });
            history.push(`/pickups?reschedule=${id}`, {
              background: location
            });
          }}
        >
          {t("Pickups_ReschedulePickup")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose();
            TrackEvent("Upload document flow started", {
              orderId: id,
              clickSource: "Elipsis"
            });
            setUploadDialogOpen(true);
          }}
        >
          {t("Upload Document")}
        </MenuItem>
      </Menu>
    </div>
  );
};

export default PickupsCardMenu;
