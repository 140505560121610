import { makeStyles } from "@material-ui/core";
import { useContext, useEffect } from "react";
import Loader from "../../Loader";
import { useMutation } from "react-apollo";
import ImportEnterpriseData from "../../../../../mutations/ImportEnterpriseData";
import UserContext from "../../../../../providers/UserProvider";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("md")]: {
      width: "640px",
      marginBottom: "50px",
      marginTop: "50px"
    },
    "& > .MuiDialogContentText-root": {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "20px"
      },
      paddingBottom: "20px"
    },
    "& > .MuiDialogActions-root": {
      paddingBottom: "20px"
    }
  }
}));

export default ({ setNextStepAction, fileContent, type, title }) => {
  const classes = useStyles();
  const {
    selectedCompany,
    user: { token }
  } = useContext(UserContext);
  const [sendDataMutation] = useMutation(ImportEnterpriseData);

  useEffect(() => {
    (async () => {
      if (fileContent) {
        await sendDataMutation({
          variables: {
            token: token,
            type: type,
            companyId: selectedCompany,
            data: JSON.stringify(fileContent)
          }
        })
          .then(data => {
            if (data?.data?.ImportEnterpriseData?.message === "ok")
              setNextStepAction();
          })
          .catch(error => {
            console.log("error", error);
          });
      }
    })();
  }, []);

  return (
    <>
      {title}
      <div className={classes.root}>
        <Loader />
      </div>
    </>
  );
};
