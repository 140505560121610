import { makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    "& > .MuiTypography-caption": {
      display: "block",
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.6)",
      marginBottom: "8px"
    }
  },
  progressBarRoot: {
    width: "100%",
    position: "relative",

    "& > div": {
      maxWidth: "100%",
      height: "4px",
      position: "absolute"
    }
  },
  progressBar: {
    width: "100%",
    background: "#E0E0E0"
  },
  progressBarActive: {
    background: "#52F597",
    width: "attr(data-percentage, '0')% !important"
  }
});

const ProgressBar = ({ percentage = 0, text = "", className }) => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.root, className)}>
      <Typography variant="caption">{text}</Typography>
      <div className={classes.progressBarRoot}>
        <div className={classes.progressBar} />
        <div
          className={classes.progressBarActive}
          style={{ width: percentage + "%" }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
