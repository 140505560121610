import gql from "graphql-tag";

export default gql`
  query GetPickupsCount($token: String!, $partnerId: String!) {
    PickupsDataCount(token: $token, partnerId: $partnerId) {
      all
      pending
      scheduled
    }
  }
`;
