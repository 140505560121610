// CustomTextField.js
import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  customTextFieldError: {
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: "red"
    }
  },
  customTextField: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#9E9E9E"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#52f597"
      }
    }
  }
});

const CustomTextField = ({ label, onChange, defaultValue, error = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <TextField
      label={t(label)}
      onChange={onChange}
      variant="outlined"
      fullWidth
      defaultValue={defaultValue}
      className={error ? classes.customTextFieldError : classes.customTextField}
      error={error}
      helperText={error && t("Field_Mandatory")}
    />
  );
};

export default CustomTextField;
