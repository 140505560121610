/* eslint-disable jsx-a11y/anchor-is-valid */
import { withTranslation } from "react-i18next";
import React from "react";
import Title from "../../components/login/Title";
import SubTitle from "../../components/login/SubTitle";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import LoginButton from "../login/button/LoginButton";
import LoginInput from "../login/LoginInput";
import { Field, reduxForm } from "redux-form";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import Error from "../login/Error";
import { Button } from "@material-ui/core";
import { withRouter } from "react-router";

const styles = theme => ({
  grid: {
    width: "100%",
    marginTop: "8px",
    "@media (max-width: 400px)": {
      marginTop: "12px"
    }
  },
  loginLink: {
    display: "flex",
    textDecoration: "none",
    justifyContent: "center",
    textAlign: "center",
    color: "#3759F0"
  },
  error: {
    color: "#E11900",
    display: "block",
    fontWeight: "normal",
    marginTop: "12px"
  },
  button: {
    width: "100%",
    marginTop: "20px"
  },
  actionsGrid: {
    width: "100%",
    display: "flex",
    marginTop: "20px",
    flexDirection: "column",
    "& >:last-child": {
      marginTop: "6px"
    }
  },
  iconButton: {
    position: "absolute",
    right: "0",
    top: "-4px"
  },
  subTitle: {
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
    marginBottom: "24px",
    "@media (max-width: 400px)": {
      width: "100%",
      padding: "0 16px",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      marginBottom: "12px"
    }
  },
  textField: {
    height: "40px",
    marginBottom: "10px",
    width: "100%",
    "& .MuiInputBase-input": {
      height: "40px",
      backgroundColor: "white!important",
      paddingTop: "0",
      paddingBottom: "0"
    },

    "& .MuiFormLabel-root.Mui-error": {
      color: "#E11900"
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E11900"
    },

    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)"
    },

    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 12px) scale(1)"
    },
    "& .MuiOutlinedInput-root": {
      height: "56x"
    }
  },
  formInput: {
    background: "white!important"
  },
  form: {
    width: "400px",
    "@media (max-width: 400px)": {
      width: "100%",
      padding: "0 16px"
    }
  },
  title: {
    marginTop: "20px",
    marginBottom: "16px",
    fontSize: "48px",
    lineHeight: "56px",
    "@media (max-width: 400px)": {
      margin: "16px 0 12px",
      fontSize: "28px",
      lineHeight: "36px"
    }
  },
  errorContainer: {
    display: "flex",
    alignItems: "flex-end"
  },
  accountLink: {
    textDecoration: "none",
    color: "#3759F0"
  }
});

class LoginFastForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      showError: false
    };
    this.onClick = this.onClick.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
  }
  onClick(event) {
    this.setState({
      showError: true
    });
  }
  handleEmailChange(event) {
    this.setState({
      email: event.target.value,
      showError: false
    });
  }

  render() {
    const { classes, handleSubmit, loginErrorMessage, t, history } = this.props;

    const { email, showError } = this.state;
    const errorMessageToShow =
      loginErrorMessage ===
      "There is no account associated to this email. Please check it is correct or create an"
        ? t("No_email_for_fast_link")
        : loginErrorMessage;
    return (
      <>
        <Title text={"Log in faster"} extraClasses={classes.title} />

        <SubTitle
          text={t("Receive a link in your email to login without a password")}
          extraClasses={classes.subTitle}
        />
        <form onSubmit={handleSubmit} className={classes.form}>
          <Grid item className={classes.grid}>
            <Field
              name="email"
              type="text"
              label={t("Email")}
              value={email}
              onChange={this.handleEmailChange}
              component={LoginInput}
              classes={classes}
              t={t}
              flagAsError={
                loginErrorMessage && loginErrorMessage.length !== 0 && showError
              }
            />
          </Grid>

          {loginErrorMessage && showError && (
            <Typography
              align="left"
              variant="caption"
              className={classes.error}
              gutterBottom
            >
              {errorMessageToShow}{" "}
            </Typography>
          )}
          <Field name="email" component={Error} alternativeError={true} />

          <LoginButton
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            text={t("get magic link")}
            onClick={this.onClick}
          />

          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.push("/login")}
            className={classes.button}
          >
            {t("BACK")}
          </Button>
        </form>
      </>
    );
  }
}

LoginFastForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};
const isItValidEmail = function (email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

const validate = values => {
  const errors = {};

  if (!isItValidEmail(values["email"])) {
    errors["email"] = "Please enter a valid email address";
  }

  if (!values["email"] || !values["email"].length) {
    errors["email"] = "To proceed please enter your email address";
  }
  return errors;
};

LoginFastForm.defaultProps = {
  classes: {},
  loginErrorMessage: ""
};

LoginFastForm.propTypes = {
  classes: PropTypes.object,
  loginErrorMessage: PropTypes.string
};

export default reduxForm({
  form: "LoginFastForm",
  validate
})(withRouter(withStyles(styles)(withTranslation()(LoginFastForm))));
