import { makeStyles } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";

const useStyles = makeStyles({
  root: {
    position: "relative",
    height: "4px",
    "& > div": {
      position: "absolute",
      width: "100%",
      maxWidth: "100%",
      height: "4px"
    }
  },
  bar: {
    background: "#E0E0E0"
  },
  activeBar: {
    transition: "width",
    background: "#52F597",
    transitionTimingFunction: "linear"
  }
});

const CountdownBar = ({ seconds, onFinish = () => {} }) => {
  const intervalId = useRef();
  const classes = useStyles();
  const [width, setWitdh] = useState(100);

  useEffect(() => {
    setTimeout(() => {
      setWitdh(0);
    }, 100);
    if (intervalId && intervalId.current) {
      clearTimeout(intervalId.current);
    }

    intervalId.current = setTimeout(
      () => {
        onFinish();
      },
      seconds * 1000 + 100
    );

    return () => {
      clearTimeout(intervalId.current);
    };
  }, [seconds]);

  return (
    <div className={classes.root}>
      <div className={classes.bar} />
      <div
        className={classes.activeBar}
        style={{ width: `${width}%`, transitionDuration: `${seconds}s` }}
      />
    </div>
  );
};

export default CountdownBar;
