import React from "react";
import { Query } from "react-apollo";

import UnauthWizardContext from "../../providers/UnauthWizardProvider";
import GET_SIMPLE_CATEGORIES from "../../queries/GetSimpleCategories";
import Loader from "../../components/core/shared/Loader";
import UnauthWizardStepSwitcher from "../../components/pricing/UnauthWizard/UnauthWizardStepSwitcher";

const QueryWrapper = props => (
  <Query query={GET_SIMPLE_CATEGORIES}>
    {({ data, loading, error }) => {
      if (loading) return <Loader />;
      if (error) return error.message;
      data.Categories = data.Categories.filter(
        c => !c.parent || c.parent === 0
      );

      return <UnauthWizardContainer data={data} {...props} />;
    }}
  </Query>
);

const WEIGHT_RANGE_CONST = [0, 3, 100, 500, 2000];

class UnauthWizardContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      weightRanges: WEIGHT_RANGE_CONST,
      selectedWeight: WEIGHT_RANGE_CONST,
      selectedCategories: [],
      selectedNumLocations: 1,
      selectedNumEmployee: "<10",
      selectedFields: [],
      companyData: {
        email: "",
        gender: "",
        message: "",
        nachname: "",
        telefonnummer: "",
        unternehmen: "",
        vorname: ""
      }
    };

    this.setStep = this.setStep.bind(this);
    this.setSelectedCategories = this.setSelectedCategories.bind(this);
    this.setSelectedNumEmployee = this.setSelectedNumEmployee.bind(this);
    this.setSelectedNumLocations = this.setSelectedNumLocations.bind(this);
    this.setSelectedFields = this.setSelectedFields.bind(this);
    this.setCompanyData = this.setCompanyData.bind(this);
    this.setWeight = this.setWeight.bind(this);
  }
  setStep(stepNo) {
    this.setState({
      step: stepNo
    });
  }
  setSelectedCategories(selectedCategories) {
    this.setState({
      selectedCategories
    });
  }
  setSelectedNumEmployee(numEmployee) {
    this.setState({
      selectedNumEmployee: numEmployee
    });
  }
  setSelectedNumLocations(numLocations) {
    this.setState({
      selectedNumLocations: numLocations
    });
  }
  setSelectedFields(selectedFields) {
    this.setState({
      selectedFields
    });
  }

  setCompanyData(property, data) {
    this.setState(prevState => {
      prevState.companyData[property] = data;
      return prevState;
    });
  }

  setWeight(weightRanges) {
    const finalWeight = [];
    for (let i = weightRanges[0]; i <= weightRanges[1]; i++) {
      finalWeight.push(WEIGHT_RANGE_CONST[i + 1]);
    }
    this.setState({ selectedWeight: finalWeight });
  }

  render() {
    const { step: currentStep } = this.state;
    const { data } = this.props;
    return (
      <UnauthWizardContext.Provider
        value={{
          categories: data.Categories,
          ...this.state,
          setStep: this.setStep,
          setSelectedCategories: this.setSelectedCategories,
          setWeight: this.setWeight,
          setSelectedNumEmployee: this.setSelectedNumEmployee,
          setSelectedNumLocations: this.setSelectedNumLocations,
          setSelectedFields: this.setSelectedFields,
          setSelectedCerts: this.setSelectedCerts,
          setCompanyData: this.setCompanyData
        }}
      >
        <UnauthWizardStepSwitcher step={currentStep} />
      </UnauthWizardContext.Provider>
    );
  }
}

export default QueryWrapper;
