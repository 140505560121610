import React from "react";
import UnauthWizardContext from "../../../providers/UnauthWizardProvider";

import SelectCategories from "./steps/CategorySelectScreen";
import WeightSelectScreen from "./steps/WeightSelectScreen";
import CompanyInformationScreen from "./steps/CompanyInformationScreen";
import FieldSelectScreen from "./steps/FieldSelectScreen";
import CompanySignupScreen from "./steps/CompanySignupScreen";
import WizardCompletedScreen from "./steps/WizardCompletedScreen";

const wizardSteps = [
  {
    name: "Step1",
    description: "This allows selection of accepted categories",
    path: "/pricing/wizard",
    component: props => <SelectCategories {...props} />
  },
  {
    name: "Step2",
    description: "This allows selecting weight ranges",
    path: "/pricing/wizard",
    component: props => <WeightSelectScreen {...props} />
  },
  {
    name: "Step3",
    description: "This allows defining his company",
    path: "/pricing/wizard",
    component: props => <CompanyInformationScreen {...props} />
  },
  {
    name: "Step3",
    description: "This allows selection of company properties",
    path: "/pricing/wizard",
    component: props => <FieldSelectScreen {...props} />
  },
  {
    name: "Step4",
    description: "This allows entering company signup information",
    path: "/pricing/wizard",
    component: props => <CompanySignupScreen {...props} />
  },
  {
    name: "Step5",
    description: "Wizard Completion",
    path: "/pricing/wizard",
    component: props => <WizardCompletedScreen {...props} />
  }
];

const WizardPageSwitcher = props => {
  const { step } = props;

  return (
    <UnauthWizardContext.Consumer>
      {context =>
        step
          ? wizardSteps[step].component(context)
          : wizardSteps[context.step].component(context)
      }
    </UnauthWizardContext.Consumer>
  );
};

export default WizardPageSwitcher;
