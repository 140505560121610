import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import REMOVE_USER from "../../mutations/removeRolesFromUser";
import { Portal } from "@material-ui/core";
import { useMutation, useQuery } from "react-apollo";
import CompanyUserQuery from "../../queries/CompanyUserQuery";
import Loader from "../../components/core/shared/Loader";
import useEventTracking from "../../hooks/useEventTracking";
import ErrorBoundary from "../../ErrorTracking/ErrorBoundary";
import UserTable from "../../components/account/users/UserTable";
import { useNotifications } from "../../hooks/Notifications";
import DeleteDialog from "../../components/core/shared/DeleteDialog";
import { showError } from "../../components/core/shared/Notify";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: "640px",
      minHeight: "240px",
      borderRadius: "0px",
      boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.1)",
      "@media (max-width: 960px)": {
        width: "100%",
        maxWidth: "100%",
        borderRadius: "0px",
        boxShadow: "none"
      }
    }
  },
  buttonRoot: {
    border: "2px solid rgba(225, 25, 0, 1)",
    color: "rgba(225, 25, 0, 1)"
  }
}));

export default ({ user, selectedCompany }) => {
  const { pushNotification } = useNotifications();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const [selectedUser, setSelectedUser] = useState(null);

  const [isDeleting, setIsDeleting] = useState(false);

  const setDeleteUser = user => {
    let fullUser = data?.CompanyUsers?.companyUsersArray.find(
      u => u.id === user
    );
    TrackEvent("User deleting started", {
      accountId: selectedCompany,
      userId: fullUser.id,
      firstName: fullUser.firstName,
      lastName: fullUser.lastName,
      email: fullUser.email
    });
    setSelectedUser(user);
    setIsDeleting(true);
  };

  const closeModals = () => {
    setIsDeleting(false);
    setSelectedUser(null);
  };

  const classes = useStyles();

  const [
    deleteUser,
    { loading: deleteLoading, error: deleteError, data: deleteData }
  ] = useMutation(REMOVE_USER);

  useEffect(() => {
    if (!deleteData) return;

    if (deleteData) {
      const message =
        deleteData?.deleteUser?.message || "User removed successfully";
      pushNotification(t(message), { fixed: true, color: "success" });
    }
    closeModals();
  }, [deleteData]);

  const { loading, error, data } = useQuery(CompanyUserQuery, {
    variables: {
      token: user.token,
      company: selectedCompany
    },
    fetchPolicy: "network-only"
  });

  if (loading) return <Loader />;

  if (error) {
    reportError(error);
    return <ErrorBoundary report={reportError} />;
  }

  const onDelete = userToRemove => {
    let userToDelete = data?.CompanyUsers?.companyUsersArray.find(
      u => u.id === userToRemove
    );

    if (!userToDelete) return;
    deleteUser({
      variables: {
        token: user.token,
        userId: userToDelete.id,
        partnerId: selectedCompany
      },
      refetchQueries: [
        {
          query: CompanyUserQuery,
          variables: {
            token: user.token,
            company: selectedCompany
          }
        }
      ]
    })
      .then(() => {
        TrackEvent("User deleted", {
          accountId: selectedCompany,
          userId: userToDelete.id,
          firstName: userToDelete.firstName,
          lastName: userToDelete.lastName,
          email: userToDelete.email
        });
      })
      .catch(error => {
        const errorBecauseLastUser =
          error.graphQLErrors[0].message === "[Can't remove the last user]";
        if (errorBecauseLastUser) {
          showError(t("Error_last_user"));
        }

        const errorBecauseAdmin =
          error.graphQLErrors[0].message ===
          "[Can't remove the last Company Admin]";
        if (errorBecauseAdmin) {
          showError(t("Error_last_admin_add_other"));
        }

        if (!errorBecauseLastUser && !errorBecauseAdmin) {
          showError(t("Error_deleting_user"));
        }
      });
    closeModals();
  };

  return (
    <>
      <UserTable
        user={user}
        data={data?.CompanyUsers || []}
        selectedCompany={selectedCompany}
        onDelete={setDeleteUser}
      />
      <Portal>
        <DeleteDialog
          title={"Delete_account_question"}
          text={"Delete_account_text"}
          delete_button_text={"Delete user"}
          onDelete={() => onDelete(selectedUser)}
          isDeleting={isDeleting}
          closeModals={closeModals}
        />
      </Portal>
    </>
  );
};
