import React, { useState } from "react";
import CategoryMobileFooter from "./CategoryMobileFooter";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import CategoryProductMobile from "./CategoryProductMobile";

const useStyles = makeStyles(theme => ({}));

const CategoryProductsMobile = props => {
  const {
    products,
    changeItem,
    changeReference,
    onSave,
    changeLink,
    onRemoveOrAddProduct,
    removeOrAddWeightRange
  } = props;
  const classes = useStyles();
  const [showActiveProducts, setShowActiveProducts] = useState(true);
  const { t } = useTranslation();

  return (
    <>
      {products.map(prod =>
        prod.accepted === showActiveProducts ? (
          <div className={classes.productWrapper} key={prod.id}>
            <CategoryProductMobile
              product={prod}
              changeItem={changeItem}
              changeReference={changeReference}
              save={onSave}
              changeLink={changeLink}
              onRemoveOrAddProduct={onRemoveOrAddProduct}
              removeOrAddWeightRange={removeOrAddWeightRange}
            />
          </div>
        ) : null
      )}
      <CategoryMobileFooter
        showActive={showActiveProducts}
        activeText={t("Active products")}
        setShowActive={setShowActiveProducts}
        inactiveText={t("Inactive products")}
      />
    </>
  );
};

export default CategoryProductsMobile;
