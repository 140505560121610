import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

const VIDEOS = [
  {
    id: 1,
    title: "Allgemeine Einführung",
    src: "https://www.useloom.com/embed/8dcab39c26914c6593d44d68478e2b4c"
  },
  {
    id: 2,
    title: "Upload von Wiegescheinen",
    src: "https://www.youtube.com/embed/VbGuCmTXHBM"
  }
];

const styles = theme => ({
  mainWrapper: {
    padding: "10px 46px 10px 46px",
    alignContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      padding: "0"
    }
  },
  title: {
    fontSize: "1.5rem",
    margin: "8px 0 16px 0"
  }
});

const VideosPage = ({ classes, t }) => (
  <Grid container className={classes.mainWrapper}>
    <Grid
      container
      item
      md={12}
      direction="row"
      alignItems="flex-end"
      spacing={24}
    >
      {VIDEOS.map(video => (
        <Grid item md={4} xs={12} key={video.id}>
          <Typography variant="h4" className={classes.title}>
            {t(video.title)}
          </Typography>
          <div
            style={{
              position: "relative",
              paddingBottom: "62.5%",
              height: "0"
            }}
          >
            <iframe
              src={video.src}
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%"
              }}
              title={video.title}
            />
          </div>
        </Grid>
      ))}
    </Grid>
  </Grid>
);

export default withStyles(styles)(withTranslation()(VideosPage));
