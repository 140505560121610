import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { CheckCircle, PanoramaFishEye } from "@material-ui/icons";
import { withTranslation } from "react-i18next";

const styles = theme => ({});

const Selector = props => {
  const {
    data: { _id, name, accepted },
    onChange,
    t
  } = props;

  return (
    <FormControlLabel
      control={
        <Checkbox
          color="secondary"
          icon={<PanoramaFishEye />}
          checkedIcon={<CheckCircle />}
          value={_id}
          onChange={onChange}
        />
      }
      label={t(name)}
      checked={accepted}
    />
  );
};

export default withStyles(styles)(withTranslation()(Selector));
