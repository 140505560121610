import React, { useContext, useState } from "react";
import { useQuery } from "react-apollo";
import Loader from "../core/shared/Loader";
import GetOffersData from "../../queries/GetOffersData";
import OffersContext from "../../providers/OffersProvider";
import InfoNavBarContext from "../../providers/InfoNavBarProvider";

const OffersQueryWrapper = ({ user, selectedCompany, children }) => {
  const InfoNavBar = useContext(InfoNavBarContext);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [biddingInfo, setBiddingInfo] = useState(null);
  const [searchFilters, setSearchFilters] = useState(null);

  const { setOpenOffers, setSubmittedOffers } = InfoNavBar;

  const { error, loading, data } = useQuery(GetOffersData, {
    variables: { token: user.token, partnerId: selectedCompany },
    fetchPolicy: "cache-and-network",
    onCompleted: () => {
      populateBiddingInfo();
    }
  });

  if (loading) return <Loader />;
  if (error) return error.graphQLErrors[0].message;

  const { OffersData, Categories: categories } = data;
  if (!OffersData) return <h1>Error: No offer Data</h1>;

  const { openOffers, submittedOffers } = OffersData;

  setOpenOffers(openOffers ? openOffers.length : 0);
  setSubmittedOffers(submittedOffers ? submittedOffers.length : 0);

  const populateBiddingInfo = () => {
    const initialBiddingInfo = {};
    openOffers.forEach(o => {
      initialBiddingInfo[o._id] = initialBiddingInfo[o._id] = {};
      o.buyers[0].bid.products.forEach(p => {
        const price = o.tenderType === "fixed" ? p.price : p.formula;
        initialBiddingInfo[o._id][p.randomId] = {
          price,
          comment: p.conditions,
          editing: !price
        };
      });
    });
    setBiddingInfo(initialBiddingInfo);
  };

  if (!biddingInfo) {
    populateBiddingInfo();
  }

  const values = {
    openOffers,
    submittedOffers,
    categories,
    selectedOffer,
    setSelectedOffer,
    biddingInfo,
    setBiddingInfo,
    searchFilters,
    setSearchFilters
  };

  return (
    <OffersContext.Provider value={values}>{children}</OffersContext.Provider>
  );
};

export default OffersQueryWrapper;
