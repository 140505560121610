import gql from "graphql-tag";

export default gql`
  mutation verifyVatNumber($taxNumber: String!) {
    verifyVatNumber(taxNumber: $taxNumber) {
      validated
      country
    }
  }
`;
