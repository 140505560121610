import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "../../mainContainer/Container";
import RightContent from "../../mainContainer/RightContent";
import StepImage from "../../stepImages/StepImage";

import { ReactComponent as ClipboardImage } from "../../../../assets/images/clipboards.svg";
import SignupFields from "../components/SignupFields";

const styles = theme => ({
  stepImage: {
    margin: "13%"
  }
});

class CompanySignupScreen extends React.Component {
  render() {
    const {
      classes,
      setStep,
      companyData,
      setCompanyData,
      selectedCategories,
      selectedNumLocations,
      selectedNumEmployee,
      selectedFields,
      selectedWeight
    } = this.props;

    return (
      <>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <StepImage
                src={<ClipboardImage className={classes.stepImage} />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RightContent>
                <SignupFields
                  companyData={companyData}
                  setCompanyData={setCompanyData}
                  onNext={() => setStep(5)}
                  onBack={() => setStep(3)}
                  allData={{
                    companyData,
                    selectedCategories,
                    selectedNumEmployee,
                    selectedNumLocations,
                    selectedFields,
                    selectedWeight
                  }}
                />
              </RightContent>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(CompanySignupScreen);
