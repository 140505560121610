import React, { Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Query, Mutation } from "react-apollo";
import { withTranslation } from "react-i18next";

import Loader from "../../components/core/shared/Loader";
import CompanyDocumentsQuery from "../../queries/CompanyDocuments";
import uploadCompanyDocument from "../../mutations/uploadCompanyDocument";
import removeCompanyDocument from "../../mutations/removeCompanyDocument";

import { showError, showSuccess } from "../../components/core/shared/Notify";
import { extractIdFromCode } from "../../utils/SharedUtils";
import { CheckErrors } from "../../ErrorTracking";
import DocumentsCard from "../../components/account/documents/DocumentsCard";
import DeleteDialog from "../../components/core/shared/DeleteDialog";

const styles = theme => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: 32,
    [theme.breakpoints.down("sm")]: {
      padding: 18
    }
  }
});
const handleDelete = deletingParams => {
  const {
    deletingDocId: id,
    deletingMutation: removeDocument,
    token,
    selectedCompany
  } = deletingParams;
  removeDocument({
    variables: {
      input: {
        token: token,
        documentId: id,
        companyId: selectedCompany
      }
    }
  });
};

class CompanyDocuments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDeleting: false,
      deletingParams: {
        deletingDocId: null,
        removeDocument: null,
        token: null,
        selectedCompany: null
      }
    };
  }

  render() {
    const { classes, user, params, t } = this.props;
    return (
      <Query
        query={CompanyDocumentsQuery}
        variables={{
          token: user.token,
          company: this.props.selectedCompany
        }}
        pollInterval={15000}
      >
        {({ loading, error, data }) => {
          if (error) return error.message;

          return (
            <Fragment>
              {loading ? (
                <Loader />
              ) : (
                <div className={classes.wrapper}>
                  <CheckErrors>
                    <Mutation
                      mutation={uploadCompanyDocument}
                      onCompleted={() =>
                        showSuccess(t("Successfully uploaded document"))
                      }
                      onError={error => showError(error.message)}
                      awaitRefetchQueries={true}
                      refetchQueries={() => [
                        {
                          query: CompanyDocumentsQuery,
                          variables: {
                            token: user.token,
                            company: this.props.selectedCompany
                          }
                        }
                      ]}
                    >
                      {(uploadDocument, { loading }) => (
                        <Mutation
                          mutation={removeCompanyDocument}
                          onCompleted={() =>
                            showSuccess(t("Successfully removed document"))
                          }
                          onError={error => showError(error.message)}
                          awaitRefetchQueries={true}
                          refetchQueries={() => [
                            {
                              query: CompanyDocumentsQuery,
                              variables: {
                                token: user.token,
                                company: this.props.selectedCompany
                              }
                            }
                          ]}
                        >
                          {removeDocument => (
                            <DocumentsCard
                              token={user.token}
                              company={this.props.selectedCompany}
                              documents={data.CompanyDocuments}
                              loading={loading}
                              onRemove={id => {
                                this.setState({
                                  deletingParams: {
                                    deletingDocId: id,
                                    deletingMutation: removeDocument,
                                    token: user.token,
                                    selectedCompany: this.props.selectedCompany
                                  },
                                  isDeleting: true
                                });
                              }}
                              onUpload={file =>
                                uploadDocument({
                                  variables: {
                                    input: {
                                      token: user.token,
                                      companyId: this.props.selectedCompany
                                    },
                                    file
                                  }
                                })
                              }
                              code={
                                params &&
                                params.code &&
                                extractIdFromCode(params.code)
                              }
                            />
                          )}
                        </Mutation>
                      )}
                    </Mutation>
                  </CheckErrors>
                  <DeleteDialog
                    title={"Delete_Document_question"}
                    text={"Delete_Document_text"}
                    delete_button_text={"Delete_Document_button"}
                    onDelete={() => {
                      handleDelete(this.state.deletingParams);
                      this.setState({ isDeleting: false });
                    }}
                    isDeleting={this.state.isDeleting}
                    closeModals={() => {
                      this.setState({ isDeleting: false });
                    }}
                  />
                </div>
              )}
            </Fragment>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(withTranslation()(CompanyDocuments));
