import gql from "graphql-tag";

export default gql`
  mutation OnboardingBillingInformationMutation(
    $token: String!
    $input: OnboardingBillingInformationInput!
    $options: OnboardingMutationOptions
  ) {
    OnboardingBillingInformationMutation(
      token: $token
      input: $input
      options: $options
    ) {
      success
    }
  }
`;
