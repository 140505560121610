import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import download from "in-browser-download";
import { showError } from "../../../../core/shared/Notify";
import { decode } from "base64-arraybuffer";
import GLOBAL_VARIABLES from "../../../../../config/config";
import Button from "@material-ui/core/Button";
import { ReactComponent as ArrowIcon } from "../../../../../assets/icons/arrow-down.svg";
import useEventTracking from "../../../../../hooks/useEventTracking";

const PRICES_URL = GLOBAL_VARIABLES.API_BASE_URL + "/api/v1/prices/download/";

const useStyles = makeStyles({
  materialsHeader: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "28px 32px"
  },
  materialsTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  deploymentIcon: {
    width: "37px",
    height: "37px",
    float: "right",
    marginLeft: "10px",
    padding: "5px",
    "& > span": {
      borderRadius: "37px",
      display: "block",
      height: "100%",
      width: "100%",
      boxShadow: "0px 0px 2px 4px #abafb2"
    },
    "& > span#deploying": {
      backgroundColor: "#7cb342",
      animation: "$blink 1s ease-out infinite alternate"
    },
    "& > span#error": {
      backgroundColor: "red"
    },
    "& > span#deployed": {
      backgroundColor: "#7cb342"
    }
  },
  rightButtonsContainer: {
    display: "flex",
    alignItems: "center"
  },
  downloadButton: {
    height: "40px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFeatureSettings: "'liga' off",
    paddingLeft: "12px",
    paddingRight: "18px"
  },
  downloadMenu: {
    marginTop: "50px",
    left: "-26px !important"
  },
  arrowIcon: {
    marginLeft: "18px"
  },
  sectionHeader: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "32px",
    lineHeight: "40px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  sectionSubHeader: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  helpButton: {
    height: "36px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFeatureSettings: "'liga' off",
    marginRight: "16px"
  },
  productHeaderText: {
    display: "flex",
    flexDirection: "column"
  },
  menuItem: {
    padding: "12px 20px 12px 20px"
  }
});

const CategoryProductHeader = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();
  const { selectedCompany, category } = props;

  const downloadFile = value => {
    let extension = "";
    let baseUrl = "";
    let fileName = "";
    let format = "";

    switch (value) {
      case "pricePdf":
        baseUrl = PRICES_URL + "seller/";
        extension = "pdf";
        fileName = "price-list.pdf";
        format = "shop_prices_PDF";
        break;
      case "priceXls":
        baseUrl = PRICES_URL + "seller/";
        extension = "csv";
        fileName = "price-list.xlsx";
        format = "shop_prices_xls";
        break;
      case "pricePdfFee":
        baseUrl = PRICES_URL + "partner/";
        extension = "pdf";
        fileName = "price-list-fees.pdf";
        format = "metaloop_prices_pdf";
        break;
      case "priceXlsFee":
        baseUrl = PRICES_URL + "partner/";
        extension = "csv";
        fileName = "price-list-fees.xlsx";
        format = "metaloop_prices_xls";
        break;
      default:
        break;
    }
    executeDownloadCall(baseUrl, extension, fileName);
    TrackEvent("Prices dowloaded", { format });
    setAnchorEl(null);
  };

  const executeDownloadCall = (baseUrl, extension, fileName) => {
    axios
      .get(baseUrl + selectedCompany + "/" + extension, {
        responseType: "application/octet-stream"
      })
      .then(response => {
        download(decode(response.data), fileName);
      })
      .catch(() => {
        showError(t("There was an error downloading the prices"));
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className={classes.materialsHeader}>
        <div className={classes.productHeaderText}>
          <span className={classes.sectionHeader}>{t(category)}</span>
        </div>
        <div className={classes.rightButtonsContainer}>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            color="primary"
            variant="contained"
            onClick={handleClick}
            className={classes.downloadButton}
          >
            {t("Download")}
            <ArrowIcon className={classes.arrowIcon} />
          </Button>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            className={classes.downloadMenu}
          >
            <MenuItem
              key={"pricePdf"}
              onClick={() => downloadFile("pricePdf")}
              className={classes.menuItem}
            >
              {t("Your shop prices") + " (PDF)"}
            </MenuItem>
            <MenuItem
              key={"priceXls"}
              onClick={() => downloadFile("priceXls")}
              className={classes.menuItem}
            >
              {t("Your shop prices") + " (.xls)"}
            </MenuItem>
            <MenuItem
              key={"pricePdfFee"}
              onClick={() => downloadFile("pricePdfFee")}
              className={classes.menuItem}
            >
              {t("Prices including S24 fee") + " (PDF)"}
            </MenuItem>
            <MenuItem
              key={"priceXlsFee"}
              onClick={() => downloadFile("priceXlsFee")}
              className={classes.menuItem}
            >
              {t("Prices including S24 fee") + " (.xls)"}
            </MenuItem>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default CategoryProductHeader;
