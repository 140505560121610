import React from "react";

export default React.createContext({
  categories: [],
  step: 0,
  selectedCategories: [],
  selectedLocations: 0,
  selectedNumEmployee: 0,
  setStep: () => {},
  setSelectedCategories: () => {},
  setSelectedLocations: () => {},
  setSelectedNumEmployee: () => {},
  sendCompletedWizard: () => {}
});
