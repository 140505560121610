import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const NoOpenOffers = () => {
  const { t } = useTranslation();

  return (
    <Grid container wrap="nowrap">
      <Grid item>
        <Typography
          variant="body2"
          style={{
            marginTop: "8px",
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: 16,
            lineHeight: "24px"
          }}
        >
          {t(
            "All new offers will appear here, for you to be able to review and place your offer"
          )}
          {"."}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const NoSubmitedOffers = () => {
  const { t } = useTranslation();

  return (
    <Grid container wrap="nowrap">
      <Grid item>
        <Typography
          variant="body2"
          style={{
            marginTop: "8px",
            color: "rgba(0, 0, 0, 0.6)",
            fontSize: 16,
            lineHeight: "24px"
          }}
        >
          {t(
            "Once the transactions have been completed, they will appear here"
          )}
          {"."}
        </Typography>
      </Grid>
    </Grid>
  );
};
export default NoOpenOffers;
