import React, { useState } from "react";
import {
  Grid,
  IconButton,
  TextField,
  makeStyles,
  Modal,
  FormControl,
  Button,
  Hidden,
  FormHelperText
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useTranslation } from "react-i18next";
import ErrorIcon from "@material-ui/icons/Error";
import classnames from "classnames";
import { useHistory } from "react-router";

import { usePickupForm } from "../../../../hooks/usePickupForm";
import PickUpUpload from "../shared/PickUpUpload";
import PickUpDocument from "../shared/PickUpDocument";
import useEventTracking from "../../../../hooks/useEventTracking";
import DateDropdownSLA from "../../../general/DateDropdownSLA";
import { DotAndLink } from "../../../products/card/PickUpStatus";
import PickUpCardInModal from "../shared/PickUpCardInModal";
import Loader from "../../../core/shared/Loader";
import { showError } from "../../../core/shared/Notify";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {},
  modalWrapper: {
    background: "#fff",
    width: "100%",
    height: "100%",
    maxWidth: "936px",
    overflowY: "auto"
  },
  header: {
    display: "flex",
    gap: "16px",
    padding: "8px",
    alignItems: "center"
  },
  header__title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    margin: 0,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  modalBody: {
    padding: "0 16px 16px"
  },
  muiPaperRoot: {
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    background: "#F9F9F9",
    position: "relative",
    "&:not(:last-of-type)": {
      marginBottom: "20px"
    }
  },
  MuiCardContentRoot: {
    position: "relative",
    padding: "20px"
  },
  listItems: {
    margin: 0,
    padding: 0,
    listStyle: "none",
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap"
  },
  listIndicators: {
    gap: "11px",
    marginBottom: "20px"
  },
  existingPickups: {
    display: "none"
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    gap: "8px"
  },
  indicators: {
    gap: "12px"
  },
  formContainer: {
    marginTop: "4px",
    maxWidth: "492px",
    marginBottom: "8px",
    padding: "12px 0"
  },
  unConfirmed: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0 0 20px",
    whiteSpace: "pre-line"
  },
  footer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "end",
    gap: "16px",
    marginTop: "11px"
  },
  ctaButtons: {
    display: "flex",
    gap: "16px"
  },
  // grid
  row: {
    display: "flex",
    flexWrap: "wrap"
  },
  gridRoot: {
    margin: "0 -8px"
  },
  detailCol: {
    flexBasis: "100%",
    width: "100%",
    padding: "0 8px"
  },
  detailGridRoot: {
    margin: "0 -10px"
  },
  panelDescription: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0"
  },
  panelDescription__mb: {
    marginBottom: "8px"
  },
  panelWrapCol: {
    flexBasis: "50%",
    width: "50%"
  },
  detailColRoot: {
    padding: "0 10px"
  },
  fullBasis: {
    flexBasis: "100%",
    width: "100%"
  },
  headingTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0"
  },
  loader: {
    width: "100%",
    height: "100%",
    alignSelf: "center",
    position: "absolute"
  },
  [theme.breakpoints.up("md")]: {
    modalWrapper: {
      position: "relative",
      height: "auto",
      left: "50%",
      top: "50%",
      transform: "translate(-50%,-50%)"
    },
    header: {
      justifyContent: "flex-end",
      padding: "4px 0",
      "& p": {
        display: "none"
      }
    },
    modalBody: {
      padding: "0 24px 21px",
      maxHeight: "90vh",
      overflowY: "auto"
    },
    existingPickups: {
      display: "flex",
      alignItems: "center",
      gap: "10px",
      "& span": {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "20px",
        lineHeight: "24px",
        letterSpacing: "0.25px",
        color: "rgba(0, 0, 0, 0.87)"
      }
    },
    unConfirmed: {
      fontSize: "16px",
      lineHeight: "24px"
    },
    formContainer: {
      marginTop: "0"
    },
    detailCol: {
      flexBasis: "calc(100% - 116px)",
      width: "calc(100% - 116px)",
      paddingTop: "12px"
    },
    panelWrapCol: {
      flexBasis: "calc(100% - 188px)",
      width: "calc(100% - 188px)"
    },
    footer: {
      justifyContent: "space-between",
      marginTop: "24px"
    }
  }
}));

const ExistingPickUpModal = ({
  pickupStatusCondition,
  pickupId,
  conditionId,
  open,
  className,
  confirmation_required,
  pre_scheduled,
  confirmed,
  productId,
  onClose,
  item
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { TrackEvent } = useEventTracking();
  const [rescheduleButtonDisabled, setRescheduleButtonDisabled] =
    useState(false);

  if (pickupId === undefined) return <> </>;

  const {
    user,
    selectedCompany,
    background,
    pickUpDateFormatted,
    form,
    date,
    loading_single_pickup,
    pickupQueryError,
    startDate,
    endDate,
    request, // reschedule or confirm
    errors,
    setErrors,
    setDate,
    setStartDate,
    setEndDate,
    onChange,
    onSubmit,
    onRefetchPickupQuery
  } = usePickupForm({
    pickupStatusCondition,
    pickupId,
    open,
    confirmation_required,
    pre_scheduled,
    confirmed,
    onClose,
    item,
    setDisabledButton: setRescheduleButtonDisabled
  });

  const files = item ? item.documents : [];

  if (loading_single_pickup) {
    return (
      <div className={classes.loader}>
        <Loader />
      </div>
    );
  }
  if (pickupQueryError) {
    showError(pickupQueryError.message);
    return null;
  }

  const formattedStartDate = moment().format("DD-MM-YYYY");

  //Next week two weeks monday
  const formattedEndDate = moment().weekday(14).format("DD-MM-YYYY");

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby={item.pickupName}
      className={className}
    >
      <div className={classes.modalWrapper}>
        <div className={classes.header}>
          <IconButton aria-label="close modal" onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <p className={classes.header__title}>{item.pickupName}</p>
        </div>

        <div className={classes.modalBody}>
          <ul className={classnames(classes.listIndicators, classes.listItems)}>
            <li className={classes.existingPickups}>
              <ErrorIcon />{" "}
              <span>{t("PickupModal_ExistingPickups_Header")}</span>
            </li>
            <li>
              <ul className={classnames(classes.listItems, classes.indicators)}>
                <li className={classes.listItem}>
                  <DotAndLink
                    status={"Materials_ConfirmationRequired"}
                    amount={confirmation_required}
                    productId={productId}
                    extraQuery={`&startDate=${formattedStartDate}&endDate=${formattedEndDate}&date=dateRange`}
                  />
                </li>
                <li className={classes.listItem}>
                  <DotAndLink
                    status={"Materials_Confirmed"}
                    amount={confirmed}
                    productId={productId}
                  />
                </li>
                <li className={classes.listItem}>
                  <DotAndLink
                    status={"Materials_Prescheduled"}
                    amount={pre_scheduled}
                    productId={productId}
                  />
                </li>
              </ul>
            </li>
          </ul>
          <p className={classes.unConfirmed}>
            {request === "confirm"
              ? t("PickupModal_ExistingPickups_BodyConfirmed", {
                  pickupDate_unconfirmed: pickUpDateFormatted
                })
              : t("PickupModal_ExistingPickups_BodyUnconfirmed", {
                  pickupDate_confirmed: pickUpDateFormatted
                })}
          </p>
          {/* pickup card */}
          <PickUpCardInModal item={item} imageHeight="100%" showSla={true} />

          {/* form */}
          <div className={classes.formContainer}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextField
                  id="quantity"
                  label={t("Declared Quantity (kg)")}
                  variant="outlined"
                  type={"number"}
                  fullWidth
                  name="quantity"
                  value={form.quantity}
                  onChange={e => {
                    if (errors?.quantity) {
                      const errors_new = { ...errors };
                      delete errors_new.quantity;
                      setErrors(errors_new);
                    }
                    onChange(e);
                  }}
                />
                {errors?.quantity && (
                  <FormHelperText error>{t("Required")}</FormHelperText>
                )}
              </Grid>

              {request === "confirm" ? (
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <TextField
                      id="packaging"
                      label={t("Packaging")}
                      variant="outlined"
                      name="packaging"
                      value={form.packaging}
                      disabled
                      fullWidth
                      onChange={onChange}
                    />
                  </FormControl>
                </Grid>
              ) : (
                <Grid item xs={12} md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <DateDropdownSLA
                      date={date}
                      setDate={e => {
                        if (errors?.date) {
                          const errors_new = { ...errors };
                          delete errors_new.date;
                          setErrors(errors_new);
                        }
                        setDate(e);
                      }}
                      label={t("Pick up Date")}
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                      slaLevel={item.slaLevel}
                    />
                    {errors?.date && (
                      <FormHelperText error>{t("Required")}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              )}

              <Grid item xs={12} md={12}>
                <TextField
                  multiline
                  minRows={5}
                  variant="outlined"
                  label={t("Additional Comments")}
                  InputProps={{ className: classes.textField }}
                  fullWidth
                  name="comments"
                  value={form.comments}
                  onChange={onChange}
                />
              </Grid>
            </Grid>
          </div>
          {/* documents */}
          {
            <PickUpDocument
              headingTitle={
                <p className={classes.headingTitle}>
                  {t("PickupModal_AttachedDocuments")}
                </p>
              }
              orderId={item._id}
              documents={files}
            />
          }

          {/* footer */}
          <div className={classes.footer}>
            <div>
              <PickUpUpload
                item={item}
                user={user}
                selectedCompany={selectedCompany}
                buttonProps={{
                  variant: "text",
                  color: "primary"
                }}
                onRefetch={onRefetchPickupQuery}
              />
            </div>
            <div className={classes.ctaButtons}>
              <Hidden smDown>
                <Button onClick={onClose}>{t("Cancel")}</Button>
              </Hidden>
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  TrackEvent("Pick up requested", {
                    source: "button",
                    productId: productId,
                    quantity: item.quantity,
                    conditionsId: conditionId
                  });
                  history.push(`/pickups?request=${conditionId}`, {
                    background
                  });
                }}
              >
                {t("PickupModal_NewRequest_Button")}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={onSubmit}
                disabled={rescheduleButtonDisabled}
              >
                {request === "confirm"
                  ? t("Button_Confirm")
                  : t("Button_Reschedule")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

ExistingPickUpModal.defaultProps = {
  request: "confirm", // reschedule or confirm
  confirmation_required: 0,
  pre_scheduled: 0,
  confirmed: 0
};

export default ExistingPickUpModal;
