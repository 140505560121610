import React from "react";
import { Grid, Typography, Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { Query, withApollo } from "react-apollo";
import {
  GET_ZONES,
  PUT_ZONES,
  EDIT_ZONES,
  REMOVE_ZONES
} from "../../queries/Zones";
import { showError, showSuccess } from "../../components/core/shared/Notify";
import UserContext from "../../providers/UserProvider";
import AddZoneModal from "./AddZoneModal";
import ZoneList from "./ZoneList";
import DeleteZoneModal from "./ZoneModal";

const styles = theme => ({
  mainWrapper: {
    padding: "10px 46px 10px 46px",
    alignContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      padding: "0"
    }
  },
  button: {
    margin: theme.spacing(1)
  },
  buttonIcon: {
    margin: theme.spacing(1),
    fontSize: 20
  },
  title: {
    fontSize: "1.5rem",
    margin: "8px 0 16px 0"
  }
});

class ZonesPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalOpened: false,
      editingModal: {
        editing: false,
        id: null,
        name: null,
        dayOfTheWeek: null,
        interval: null,
        startingDate: null
      },
      deletePrompt: {
        isOpen: false,
        id: null,
        partnerId: null,
        token: null
      }
    };
  }
  openModal = () => {
    this.setState({ modalOpened: true });
  };
  closeModal = () => {
    this.setState({
      modalOpened: false,
      editingModal: {
        editing: false,
        id: null,
        name: null,
        dayOfTheWeek: null,
        interval: null,
        startingDate: null
      }
    });
  };
  openDeletePrompt = (token, partnerId, id) => {
    this.setState({ deletePrompt: { isOpen: true, id, partnerId, token } });
  };
  closeDeletePrompt = () => {
    this.setState({
      deletePrompt: { isOpen: false, id: null, partnerId: null, token: null }
    });
  };
  openEditingModal = (
    token,
    partnerId,
    { _id, zoneName, zipCodesText, dayOfTheWeek, interval, startingDate }
  ) => {
    console.log(zipCodesText);
    this.setState({
      editingModal: {
        editing: true,
        _id,
        zoneName,
        zipCodes: zipCodesText,
        dayOfTheWeek,
        interval,
        startingDate
      },
      modalOpened: true
    });
  };
  addZonesZipCode = async (token, partnerId, zone, resetForm) => {
    const { client } = this.props;
    try {
      let {
        data: { putZones: r }
      } = await client.mutate({
        mutation: PUT_ZONES,
        variables: {
          zone,
          partnerId,
          token
        },
        refetchQueries: () => [
          {
            query: GET_ZONES,
            variables: { token, partnerId }
          }
        ],
        awaitRefetchQueries: true
      });

      showSuccess(r.message);
      resetForm();
      this.closeModal();
    } catch (e) {
      showError(e.message);
    }
  };
  editZones = async (
    token,
    partnerId,
    id,
    name,
    dayOfTheWeek,
    interval,
    zipCodes,
    resetForm
  ) => {
    const { client } = this.props;
    try {
      let {
        data: { editZones: r }
      } = await client.mutate({
        mutation: EDIT_ZONES,
        variables: {
          zoneName: name,
          id,
          token,
          dayOfTheWeek,
          interval,
          zipCodes
        },
        refetchQueries: () => [
          {
            query: GET_ZONES,
            variables: { token, partnerId }
          }
        ],
        awaitRefetchQueries: true
      });

      showSuccess(r.message);
      resetForm();
      this.closeModal();
    } catch (e) {
      showError(e.message);
    }
  };
  deleteZones = async () => {
    const { client } = this.props;
    const { token, partnerId, id } = this.state.deletePrompt;
    if (!token || !partnerId || !id) showError("Error ocurred");
    try {
      let {
        data: { removeZones: r }
      } = await client.mutate({
        mutation: REMOVE_ZONES,
        variables: {
          id,
          token
        },
        refetchQueries: () => [
          {
            query: GET_ZONES,
            variables: { token, partnerId }
          }
        ],
        awaitRefetchQueries: true
      });
      showSuccess(r.message);
      this.closeDeletePrompt();
    } catch (e) {
      showError(e.message);
    }
  };
  downloadZones = (zones, partnerId) => {
    let json = {
      type: "FeatureCollection",
      features: zones.map(z => z.geoJSON)
    };
    json = JSON.stringify(json);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    var a = document.createElement("a");
    a.download = `Zones-${partnerId}.json`;
    a.href = url;
    a.click();
  };
  render() {
    const { classes } = this.props;
    const { modalOpened, deletePrompt, editingModal } = this.state;
    const {
      openModal,
      closeModal,
      addZonesZipCode,
      editZones,
      deleteZones,
      openDeletePrompt,
      closeDeletePrompt,
      openEditingModal
    } = this;
    return (
      <UserContext.Consumer>
        {({ selectedCompany: partnerId, user: { token } }) => (
          <Query query={GET_ZONES} variables={{ token, partnerId }}>
            {({ data, loading, error }) => {
              if (loading) return null;
              let { getZones: zones } = data;
              return (
                <Grid container className={classes.mainWrapper}>
                  <Grid
                    container
                    item
                    md={12}
                    direction="row"
                    alignItems="flex-end"
                    justify="flex-end"
                  >
                    <Button
                      justify="flex-end"
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      onClick={openModal}
                    >
                      Add Zone
                    </Button>
                    {/*<Button
                      size="small"
                      justify="flex-end"
                      variant="contained"
                      color="primary"
                      className={classes.buttonIcon}
                      onClick={() => downloadZones(zones, partnerId)}
                    >
                      <DownloadIcon />
                    </Button>*/}
                  </Grid>
                  <AddZoneModal
                    editing={editingModal}
                    isModalOpen={modalOpened}
                    onCloseModal={closeModal}
                    addZone={(zone, resetForm) => {
                      addZonesZipCode(token, partnerId, zone, resetForm);
                    }}
                    editZone={(id, name, days, interval, zipCodes, resetForm) =>
                      editZones(
                        token,
                        partnerId,
                        id,
                        name,
                        days,
                        interval,
                        zipCodes,
                        resetForm
                      )
                    }
                  />
                  <DeleteZoneModal
                    open={deletePrompt.isOpen}
                    closeModal={closeDeletePrompt}
                    action={deleteZones}
                    actionText={"Remove Zone"}
                    primary={"Are you sure?"}
                    secondary={"You will remove this zone!"}
                  />
                  {zones && zones.length > 0 ? (
                    <Grid container item md={12} direction="row">
                      <ZoneList
                        zones={zones}
                        deleteZones={id =>
                          openDeletePrompt(token, partnerId, id)
                        }
                        editZones={zone =>
                          openEditingModal(token, partnerId, zone)
                        }
                      />
                    </Grid>
                  ) : (
                    <Grid container item md={12} direction="row">
                      <Typography>No Zones defined yet</Typography>
                    </Grid>
                  )}
                </Grid>
              );
            }}
          </Query>
        )}
      </UserContext.Consumer>
    );
  }
}

export default withStyles(styles)(withApollo(withTranslation()(ZonesPage)));
