import gql from "graphql-tag";

export default gql`
  query getPartnerImportedContracts($token: String!, $partnerId: String!) {
    getPartnerImportedContracts(token: $token, partnerId: $partnerId) {
      contract_id
      contract_name
      supplier_name
      site_name
      material_name
      metaloop_material_id
      material_rate
      total_quantity
      metaloop_account_id
    }
  }
`;
