import React from "react";
import { useTranslation } from "react-i18next";

import { makeStyles, Typography } from "@material-ui/core";

import { canSeeItem } from "../../utils/SharedUtils.js";
import ContactCard from "./ContactCard.js";
import useCompany from "../../hooks/useCompany.js";

const Contacts = ({ data, loading, userData }) => {
  const useStyles = makeStyles(theme => ({
    container: {
      padding: "28px 0 24px",
      [theme.breakpoints.down("sm")]: {
        padding: "32px 0 8px"
      }
    },
    headerContainer: {
      lineHeight: "28px",
      fontWeight: 500,
      marginBottom: 20,
      [theme.breakpoints.down("sm")]: {
        fontSize: "20px",
        lineHeight: "24px",
        letterSpacing: "0.15px"
      }
    },
    contactsContainer: {
      display: "flex",
      flexWrap: "wrap",
      gap: 20
    }
  }));

  const classes = useStyles();
  const { t } = useTranslation();
  const { isRetail: isRetailCompany, selectedCompany } = useCompany();

  const dashboardManagers = data?.GetDashboardManagers || {};
  const commercialManager = dashboardManagers.commercialManager;
  const operationalManager = dashboardManagers.operationalManager;

  const contactsData = [
    {
      role: t("AccountManager_Commercial"),
      exists: loading || !!commercialManager,
      name: "********* *****",
      email: "*********************",
      phone: "**************",
      ...commercialManager
    },
    {
      role: t("AccountManager_Operational"),
      exists: loading || !!operationalManager,
      name: "********* *****",
      email: "*********************",
      phone: "**************",
      ...operationalManager
    }
  ];

  const canSeeContacts = canSeeItem(selectedCompany, userData.UserCompanies, {
    rules: "dashboard_contacts_ui"
  });

  //Patch until GSD-5201 is fixed
  const isEleneEmailForBoth =
    contactsData.length === 2 &&
    contactsData[0].email === contactsData[1].email &&
    contactsData[0].email === "elene.papava@metaloop.com";

  if (isRetailCompany || !canSeeContacts || isEleneEmailForBoth) {
    return null;
  }

  return (
    <div className={classes.container}>
      <Typography variant="h5" className={classes.headerContainer}>
        {t("Dashboard_Contacts")}
      </Typography>
      <div className={classes.contactsContainer}>
        {contactsData.map((item, i) => (
          <ContactCard
            contact={item}
            loading={loading}
            key={`contactCard-${i}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Contacts;
