import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import axios from "axios";
import download from "in-browser-download";
import { showError } from "../../../../core/shared/Notify";
import { decode } from "base64-arraybuffer";
import GLOBAL_VARIABLES from "../../../../../config/config";
import useEventTracking from "../../../../../hooks/useEventTracking";
import { IconButton } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
const PRICES_URL = GLOBAL_VARIABLES.API_BASE_URL + "/api/v1/prices/download/";

const useStyles = makeStyles(theme => ({
  materialsHeader: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      marginTop: "50px",
      borderBottom: "none"
    }
  },
  materialsTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "24px",
    lineHeight: "28px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  deploymentIcon: {
    width: "37px",
    height: "37px",
    float: "right",
    marginLeft: "10px",
    padding: "5px",
    "& > span": {
      borderRadius: "37px",
      display: "block",
      height: "100%",
      width: "100%",
      boxShadow: "0px 0px 2px 4px #abafb2"
    },
    "& > span#deploying": {
      backgroundColor: "#7cb342",
      animation: "$blink 1s ease-out infinite alternate"
    },
    "& > span#error": {
      backgroundColor: "red"
    },
    "& > span#deployed": {
      backgroundColor: "#7cb342"
    }
  },
  downloadButton: {
    border: "1px solid #4A6572",
    height: "36px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFeatureSettings: "'liga' off",
    width: "100%"
  },
  downloadMenu: {
    marginTop: "44px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "55px"
    }
  },
  downloadText: {
    position: "relative",
    width: "100%"
  },
  arrowIcon: {
    position: "absolute",
    top: "4px",
    right: "18px"
  },
  uploadButton: {
    height: "36px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFeatureSettings: "'liga' off",
    width: "100%",
    marginTop: "24px",
    marginBottom: "16px"
  },
  deployButton: {
    height: "36px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFeatureSettings: "'liga' off",
    width: "100%",
    marginBottom: "16px"
  },
  deployProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -10,
    marginLeft: -12
  },
  menuItem: {
    padding: "12px 20px 12px 20px"
  },
  iconButton: {
    padding: "7px",
    marginLeft: "16px",
    background: "rgba(0, 0, 0, 0.1)"
  }
}));

const CategoryListHeader = props => {
  const { selectedCompany } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const downloadFile = value => {
    let extension = "";
    let baseUrl = "";
    let fileName = "";
    let format = "";

    switch (value) {
      case "pricePdf":
        baseUrl = PRICES_URL + "seller/";
        extension = "pdf";
        fileName = "price-list.pdf";
        format = "shop_prices_PDF";
        break;
      case "priceXls":
        baseUrl = PRICES_URL + "seller/";
        extension = "csv";
        fileName = "price-list.xlsx";
        format = "shop_prices_xls";
        break;
      case "pricePdfFee":
        baseUrl = PRICES_URL + "partner/";
        extension = "pdf";
        fileName = "price-list-fees.pdf";
        format = "metaloop_prices_pdf";
        break;
      case "priceXlsFee":
        baseUrl = PRICES_URL + "partner/";
        extension = "csv";
        fileName = "price-list-fees.xlsx";
        format = "metaloop_prices_xls";
        break;
      default:
        break;
    }
    executeDownloadCall(baseUrl, extension, fileName);
    TrackEvent("Prices dowloaded", { format });
    setAnchorEl(null);
  };

  const executeDownloadCall = (baseUrl, extension, fileName) => {
    axios
      .get(baseUrl + selectedCompany + "/" + extension, {
        responseType: "application/octet-stream"
      })
      .then(response => {
        download(decode(response.data), fileName);
      })
      .catch(() => {
        showError(t("There was an error downloading the prices"));
      });
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <div className={classes.materialsHeader}>
        <IconButton className={classes.iconButton} onClick={handleClick}>
          <GetAppIcon />
        </IconButton>

        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          className={classes.downloadMenu}
        >
          <MenuItem
            key={"pricePdf"}
            onClick={() => downloadFile("pricePdf")}
            className={classes.menuItem}
          >
            {t("Your shop prices") + " (PDF)"}
          </MenuItem>
          <MenuItem
            key={"priceXls"}
            onClick={() => downloadFile("priceXls")}
            className={classes.menuItem}
          >
            {t("Your shop prices") + " (.xls)"}
          </MenuItem>
          <MenuItem
            key={"pricePdfFee"}
            onClick={() => downloadFile("pricePdfFee")}
            className={classes.menuItem}
          >
            {t("Prices including S24 fee") + " (PDF)"}
          </MenuItem>
          <MenuItem
            key={"priceXlsFee"}
            onClick={() => downloadFile("priceXlsFee")}
            className={classes.menuItem}
          >
            {t("Prices including S24 fee") + " (.xls)"}
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default CategoryListHeader;
