import React, { useState } from "react";
import GenericCard from "../../../general/GenericCard";
import { Menu, MenuItem, IconButton } from "@material-ui/core";
import {
  LocationOn,
  AccountCircle,
  MoreVert,
  InfoOutlined,
  Star,
  StarBorderOutlined
} from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import DeleteDialog from "../../../core/shared/DeleteDialog";
import StyledTooltip from "../../../core/StyledTooltip";

const useStyles = makeStyles(theme => ({
  emptyContainer: {
    width: "480px",
    marginRight: "20px",
    "@media (max-width: 960px)": {
      display: "none"
    }
  },
  container: {
    width: "480px",
    minHeight: "296px",
    marginRight: "20px",

    "@media (max-width: 960px)": {
      width: "100%",
      marginLeft: "16px",
      marginRight: "16px"
    }
  },

  companyName: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "28px",
    lineHeight: "36px",
    color: "rgba(0, 0, 0, 0.87)",
    marginBottom: "16px",
    display: "flex",
    "@media (max-width: 960px)": {
      fontSize: "24px",
      lineHeight: "28px"
    }
  },
  cardRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  bodyRow: {
    display: "flex",
    marginBottom: "4px"
  },
  textInCard: {
    margin: "0",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  itemContainer: {
    width: "20px",
    marginRight: "12px",
    display: "flex"
  },
  menuContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  whiteIcons: {
    color: "white"
  },
  icons: {
    color: "#666666"
  }
}));

const TransportCard = props => {
  const classes = useStyles();

  const { data, handleChange, isDefault, changeDefaultAddress, index } = props;

  const [anchorEl, setAnchorEl] = useState(false);

  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (data === null) {
    return <div className={classes.emptyContainer} />;
  }

  let title = "";
  //Title
  if (isDefault) {
    title =
      data.type === "pickup"
        ? t("Default pickup address")
        : t("Default delivery address");
  } else {
    title =
      data.type === "pickup" ? t("Pickup Address") : t("Delivery Address");
  }

  const handleDelete = () => {
    handleChange("Delete", data);
    setIsDeleting(false);
  };

  const toolTip_title =
    data.type === "pickup"
      ? t("tooltip_makeDefPickUpAdd")
      : t("tooltip_makeDefDelAdd");

  return (
    <div className={classes.container}>
      <GenericCard
        title={
          <>
            {title}
            {!isDefault && ` #${index + 2}`}
          </>
        }
        darkHeader={isDefault}
        menuInHeader={
          <div className={classes.menuContainer}>
            {isDefault ? (
              <Star className={classes.whiteIcons} />
            ) : (
              <StyledTooltip text={toolTip_title} placement={"top"}>
                <IconButton onClick={() => changeDefaultAddress(data)}>
                  <StarBorderOutlined color="action" />
                </IconButton>
              </StyledTooltip>
            )}
            <IconButton
              onClick={event => setAnchorEl(event.currentTarget)}
              aria-controls="menu"
            >
              <MoreVert className={isDefault && classes.whiteIcons} />
            </IconButton>
            <Menu
              id="menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleClose}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleChange("Edit", data);
                }}
              >
                {"Edit"}
              </MenuItem>{" "}
              {!isDefault && (
                <MenuItem
                  onClick={() => {
                    handleClose();
                    setIsDeleting(true);
                  }}
                >
                  {"Delete"}
                </MenuItem>
              )}
            </Menu>
          </div>
        }
        content={
          <>
            <span className={classes.companyName}>{data.companyName}</span>
            <div className={classes.bodyRow}>
              {" "}
              <div className={classes.itemContainer}>
                <LocationOn className={classes.icons} />{" "}
              </div>{" "}
              <div>
                <h4 className={classes.textInCard}>
                  {data.streetAddress}, {data.city}, {data.zipCode}
                </h4>
                <h4 className={classes.textInCard}>{data.country}</h4>
              </div>
            </div>
            <div className={classes.bodyRow}>
              {" "}
              <div className={classes.itemContainer}>
                <AccountCircle className={classes.icons} />{" "}
              </div>{" "}
              <div>
                <h4 className={classes.textInCard}>{data.contactName}</h4>
                <h4 className={classes.textInCard}>{data.contactEmail}</h4>
                <h4 className={classes.textInCard}>{data.contactPhone}</h4>
              </div>
            </div>
            {data.transportNotes !== null && data.transportNotes.length > 0 ? (
              <div className={classes.bodyRow}>
                {" "}
                <div className={classes.itemContainer}>
                  <InfoOutlined className={classes.icons} />{" "}
                </div>{" "}
                <div>
                  {" "}
                  <h4 className={classes.textInCard}>{data.transportNotes}</h4>
                </div>
              </div>
            ) : null}
          </>
        }
      />
      <DeleteDialog
        title={"Delete_Address_question"}
        text={"Delete_Address_text"}
        delete_button_text={"Delete_Address_button"}
        onDelete={handleDelete}
        isDeleting={isDeleting}
        closeModals={() => setIsDeleting(false)}
      />
    </div>
  );
};

export default TransportCard;
