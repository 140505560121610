import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import { withStyles } from "@material-ui/core/styles";
import { amber, blue } from "@material-ui/core/colors";
import { reportError } from "../../../ErrorTracking";
let showError, showSuccess, showWarning, showInfo;

const styles = theme => ({
  error: {
    backgroundColor: theme.palette.error.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  info: {
    backgroundColor: blue[700]
  },
  success: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.primary.main
  }
});
class Notify extends React.Component {
  constructor(props) {
    super(props);
    showError = this.showError.bind(this);
    showSuccess = this.showSuccess.bind(this);
    showWarning = this.showWarning.bind(this);
    showInfo = this.showInfo.bind(this);
  }
  state = {
    open: false,
    message: "",
    type: "info",
    duration: 3000,
    horizontal: "center",
    vertical: "bottom"
  };

  showError(message, duration, vertical, horizontal) {
    reportError(message);

    this.showSnackbar({
      type: "error",
      message,
      duration,
      vertical,
      horizontal
    });
  }
  showSuccess(message, duration, vertical, horizontal) {
    this.showSnackbar({
      type: "success",
      message,
      duration,
      vertical,
      horizontal
    });
  }
  showWarning(message, duration, vertical, horizontal) {
    this.showSnackbar({
      type: "warning",
      duration,
      message,
      duration,
      vertical,
      horizontal
    });
  }
  showInfo(message, duration, vertical, horizontal) {
    this.showSnackbar({
      type: "info",
      message,
      duration,
      vertical,
      horizontal
    });
  }

  showSnackbar({
    type = "info",
    message,
    duration = 3000,
    vertical = "bottom",
    horizontal = "center"
  }) {
    this.setState({
      open: true,
      message,
      type,
      duration,
      vertical,
      horizontal
    });
  }

  handleSnackbarClose = () => {
    this.setState({
      open: false,
      message: "",
      duration: 3000,
      vertical: "bottom",
      horizontal: "center"
    });
  };
  render() {
    const { message, type, open, vertical, horizontal, duration } = this.state;
    const { classes } = this.props;

    return (
      <Snackbar
        anchorOrigin={{ vertical: vertical, horizontal: horizontal }}
        message={message}
        autoHideDuration={duration}
        onClose={this.handleSnackbarClose}
        open={open}
        className={classes[type]}
        ContentProps={{
          "aria-describedby": "snackbar-message-id",
          className: classes[type]
        }}
      />
    );
  }
}

export default withStyles(styles)(Notify);

export { showError, showInfo, showSuccess, showWarning };
