import React from "react";
import TextField from "@material-ui/core/TextField";

class LoginInput extends React.Component {
  render() {
    const {
      input,
      classes,
      flagAsError,
      type,
      label,
      id,
      meta: { touched, error },
      ...rest
    } = this.props;

    return (
      <TextField
        type={type}
        error={
          flagAsError || (error && touched)
            ? flagAsError || (error && touched)
            : false
        }
        value={input.value}
        onChange={input.onChange}
        fullWidth
        autoComplete="off"
        id={id}
        label={label}
        variant="outlined"
        {...rest}
      />
    );
  }
}

export default LoginInput;
