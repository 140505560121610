import gql from "graphql-tag";

export default gql`
  query GetProductsAndCategoriesSimple {
    Categories {
      _id
      id
      name
      description
      parent
    }
    PriceProducts {
      _id
      productID
      categoryId
      productName
      lme
    }
  }
`;
