import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "../../mainContainer/Container";
import RightContent from "../../mainContainer/RightContent";
import StepImage from "../../stepImages/StepImage";
import Categories from "../components/Categories";
import { ReactComponent as ManWithCheckboard } from "../../../../assets/images/man_with_checkboard.svg";
const styles = theme => ({});

class CategorySelectScreen extends React.Component {
  render() {
    const { categories, selectedCategories, setSelectedCategories, setStep } =
      this.props;
    return (
      <>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <StepImage src={<ManWithCheckboard />} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RightContent>
                <Categories
                  onSubmit={setSelectedCategories}
                  data={categories}
                  selectedCategories={selectedCategories}
                  onNext={() => setStep(1)}
                />
              </RightContent>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(CategorySelectScreen);
