import React, { Fragment, useState } from "react";
import SalesDesktopFilters from "./sales/SalesDesktopFilters";
import PurchaseDesktopFilters from "./purchase/PurchaseDesktopFilters";

const DesktopFilters = props => {
  const {
    date,
    setDate,
    transport,
    startDateChanged,
    endDateChanged,
    filterChanged,
    searchText,
    startDate,
    endDate,
    onFilterReset,
    total,
    product,
    products,
    transports,
    statusId,
    statuses,
    onStatusChanged,
    transaction,
    onChangeSelectedTransaction,
    onChangeSelectedProduct,
    onChangeSelectedTransport,
    onChangeSelectedLocalization,
    downloadFile,
    downloadingFile,
    isOpen,
    open,
    close,
    queryType,
    localization,
    arrLocations,
    disabled = false
  } = props;

  const [showText, setShowText] = useState(false);

  const changeShowText = hide => {
    if (hide) {
      setShowText(false);

      return;
    }
    if (!showText) {
      setShowText(true);
    } else {
      setShowText(false);
    }
  };

  return (
    <Fragment>
      {!props.isSeller ? (
        <PurchaseDesktopFilters
          startDateChanged={startDateChanged}
          endDateChanged={endDateChanged}
          filterChanged={filterChanged}
          searchText={searchText}
          startDate={startDate}
          endDate={endDate}
          onFilterReset={onFilterReset}
          total={total}
          products={products}
          product={product}
          transaction={transaction}
          transports={transports}
          transport={transport}
          onChangeSelectedTransaction={onChangeSelectedTransaction}
          onChangeSelectedProduct={onChangeSelectedProduct}
          onChangeSelectedTransport={onChangeSelectedTransport}
          changeShowText={changeShowText}
          downloadFile={downloadFile}
          isOpen={isOpen}
          open={open}
          close={close}
          disabled={disabled}
        />
      ) : (
        <SalesDesktopFilters
          location={localization}
          arrLocations={arrLocations}
          date={date}
          setDate={setDate}
          startDateChanged={startDateChanged}
          endDateChanged={endDateChanged}
          filterChanged={filterChanged}
          searchText={searchText}
          startDate={startDate}
          endDate={endDate}
          onFilterReset={onFilterReset}
          transaction={transaction}
          total={total}
          products={products}
          product={product}
          transport={transport}
          transports={transports}
          statusId={statusId}
          statuses={statuses}
          onChangeSelectedTransaction={onChangeSelectedTransaction}
          onChangeSelectedProduct={onChangeSelectedProduct}
          onChangeSelectedLocalization={onChangeSelectedLocalization}
          onStatusChanged={onStatusChanged}
          onChangeSelectedTransport={onChangeSelectedTransport}
          changeShowText={changeShowText}
          downloadFile={downloadFile}
          downloadingFile={downloadingFile}
          queryType={queryType}
          disabled={disabled}
        />
      )}
    </Fragment>
  );
};

export default DesktopFilters;
