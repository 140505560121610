import React, { useState, useContext } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { Box } from "@material-ui/core";
import { Chip } from "@material-ui/core";

import UserAvatar from "../../user/Avatar";

import { DataGrid } from "@material-ui/data-grid";

import ActionsCell from "./ActionsCell";
import { useTranslation } from "react-i18next";
import UserContext from "../../../providers/UserProvider";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  root: {
    border: "none",
    marginTop: 24,
    height: "80vh",
    "& .MuiDataGrid-cell:hover, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within":
      {
        backgroundColor: "transparent"
      },
    "& .MuiDataGrid-renderingZone": {
      maxHeight: "none !important",
      overflow: "auto !important"
    }
  },
  row: {
    maxHeight: "none !important",
    cursor: "pointer",
    border: 0
  },
  iconHolder: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  },
  chip: {
    marginBottom: "8px"
  },
  cell: {
    lineHeight: "unset !important",
    maxHeight: "none !important",
    whiteSpace: "normal !important",
    padding: "14px !important",
    "&:first-child": {
      display: "flex",
      alignItems: "flex-start",
      padding: "8px"
    }
  }
}));

export default ({ user, data, onDelete, selectedCompany }) => {
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const [anchorEl, setAnchorEl] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { t } = useTranslation();
  const { userData } = useContext(UserContext);
  const history = useHistory();
  const isAdmin = userData.RawUserData.isAdmin;

  const handleSelect = (event, id) => {
    setSelectedId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId(null);
  };

  const handleDelete = cellValue => {
    onDelete(cellValue);
    handleClose();
  };

  let data_users = data.companyUsersArray;
  let user_type = isAdmin;

  if (!data_users) {
    history.replace(`/profile/userInformation`);
    location.reload();
    return null;
  }

  const columns = [
    {
      field: "avatar",
      headerName: "",
      renderHeader: () => <></>,
      width: matches ? 70 : 120,
      renderCell: params => (
        <Box className={classes.iconHolder}>
          <UserAvatar
            alt={params.row.name}
            src={params.row.avatar}
            name={params.row.name}
            email={params.row.email}
          />
        </Box>
      ),
      sortable: false
    },
    {
      field: "name",
      headerName: t("Name"),
      minWidth: 155,
      flex: 1
    },
    {
      field: "email",
      headerName: t("Email"),
      minWidth: 155,
      flex: 1
    },
    {
      field: "role",
      headerName: t("Role"),
      sortable: false,
      minWidth: 204,
      flex: "1 1 auto",
      renderCell: function (params) {
        return (
          <div>
            {params.row.roles.map(role => (
              <Chip
                className={classes.chip}
                key={role}
                label={role}
                size="small"
                style={{ marginRight: 5 }}
              />
            ))}
          </div>
        );
      }
    },
    {
      field: "actions",
      sortable: false,
      headerName: "",
      width: 96,
      renderCell: params => {
        const { id } = params;
        return (
          <ActionsCell
            anchorEl={anchorEl}
            handleSelect={handleSelect}
            isSelected={selectedId === id}
            handleDelete={() => handleDelete(id)}
            handleClose={handleClose}
            id={id}
            user_type={user_type}
            token={user.token}
            selectedCompany={selectedCompany}
          />
        );
      },
      renderHeader: () => <></>
    }
  ];

  const rows = data_users.map(user => ({
    id: user.id,
    name: user.name,
    email: user.email,
    avatar: user.avatar,
    roles: user.roles
  }));

  return (
    <DataGrid
      classes={{
        root: classes.root,
        cell: classes.cell,
        row: classes.row
      }}
      rows={rows}
      columns={columns}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      rowsPerPageOptions={[]}
      disableSelectionOnClick
      hideFooter
    />
  );
};
