import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton, Menu, MenuItem } from "@material-ui/core";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import MoreVert from "@material-ui/icons/MoreVert";

const useStyles = makeStyles(theme => ({
  iconHolder: {
    display: "flex",
    justifyContent: "center",
    width: "100%"
  }
}));

const ActionsCell = ({
  handleDelete,
  anchorEl,
  handleClose,
  isSelected,
  handleSelect,
  id,
  user_type,
  token,
  selectedCompany
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  let impersonate_link =
    "/impersonate/" + token + "/" + id + "/" + selectedCompany;
  return (
    <Box className={classes.iconHolder} id={id}>
      <IconButton
        onClick={event => handleSelect(event, id)}
        aria-controls="menu"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id={id}
        anchorEl={anchorEl}
        open={Boolean(anchorEl) && isSelected}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem onClick={() => history.push(`/profile/users/edit/${id}`)}>
          {t("Edit")}
        </MenuItem>
        <MenuItem onClick={handleDelete}>{t("Delete")}</MenuItem>
        {user_type ? (
          <MenuItem component={Link} to={impersonate_link}>
            {t("Impersonate")}
          </MenuItem>
        ) : (
          " "
        )}
      </Menu>
    </Box>
  );
};

export default ActionsCell;
