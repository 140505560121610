import React, { memo } from "react";
import { Typography, makeStyles, Hidden } from "@material-ui/core";
import config from "../../config/config";
import download from "in-browser-download";
import { decode } from "base64-arraybuffer";
import { DropdownButton } from "../core/Button";
import DownloadIcon from "@material-ui/icons/GetApp";
import { useTranslation } from "react-i18next";
import useEventTracking from "../../hooks/useEventTracking";
import { Buffer } from "buffer";
import AutoCompleteTextField from "../core/AutoCompleteTextField";
import IconButton from "@material-ui/core/IconButton";

const priceListHeaderStyle = makeStyles({
  headerTextContainer: {
    padding: "28px 32px",
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
    marginBottom: "0"
  },
  headerText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "32px",
    lineHeight: "40px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  downloadButton: {
    padding: "12px 16px"
  },
  containerMobile: {
    padding: "16px",
    paddingTop: "24px"
  },
  marginTop: {
    marginTop: "24px"
  },
  flexContainer: {
    display: "flex",
    gap: "16px",
    padding: "24px 16px 32px",
    alignItems: "flex-end"
  },
  flexItem: {
    flex: "1"
  },
  fab: {
    color: "rgba(0, 0, 0, 0.54)"
  }
});

const DROPDOWN_OPTIONS = [
  { label: "Download XLSX", value: "xlsx" },
  { label: "Download PDF", value: "pdf", disabled: true },
  { label: "Download CSV", value: "csv", disabled: true }
];

const generateDownload = data => {
  if (!data) return;

  const priceData = Buffer.from(JSON.stringify(data)).toString("base64");

  fetch(config.PRICE_HISTORY_EXCEL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ priceData })
  })
    .then(response => response.text())
    .then(response => {
      download(decode(response), "price-history.xlsx");
    })
    .catch(error => console.log(error));
};

const PriceListHeader = memo(
  ({ priceData, filters, productList, updateFilter }) => {
    const classes = priceListHeaderStyle();
    const { t } = useTranslation();
    const { TrackEvent } = useEventTracking();
    const { products } = filters;

    const onDownloadClick = item => {
      const { categories, products, date } = filters;
      switch (item) {
        case "xlsx":
          generateDownload(priceData);
          TrackEvent("Price history downloaded", {
            format: "xlsx",
            category: categories,
            product: products,
            date
          });
          break;
        default:
      }
    };

    return (
      <>
        <Hidden mdUp>
          <div className={classes.flexContainer}>
            <div className={classes.flexItem}>
              <AutoCompleteTextField
                onChange={item => {
                  var tempProduct = products;

                  if (
                    !tempProduct.filter(product => product.value === item.value)
                      .length > 0
                  ) {
                    tempProduct.push(item);
                  }

                  updateFilter("products", tempProduct);
                }}
                options={productList.map(c => ({
                  value: c.productID,
                  label: c.productID + " | " + c.productName
                }))}
                label={t("Product Name or ID")}
              />
            </div>
            <IconButton
              size={"small"}
              className={classes.fab}
              disabled={!priceData}
              onClick={() => onDownloadClick("xlsx")}
            >
              <DownloadIcon />
            </IconButton>
          </div>
        </Hidden>
        <Hidden smDown>
          <div className={classes.headerTextContainer}>
            <Typography variant="h5" className={classes.headerText}>
              {t("Price History")}
            </Typography>
            <DropdownButton
              variant="contained"
              color="primary"
              className={classes.downloadButton}
              onClick={onDownloadClick}
              options={DROPDOWN_OPTIONS}
              disabled={!priceData}
            >
              {t("Download")}
            </DropdownButton>
          </div>
        </Hidden>
      </>
    );
  }
);

export default PriceListHeader;
