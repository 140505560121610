import gql from "graphql-tag";

export default gql`
  query GetRequestPickUpInfo(
    $token: String!
    $conditionId: String
    $partnerId: String!
  ) {
    RequestPickUpInfo(
      token: $token
      partnerId: $partnerId
      conditionId: $conditionId
    ) {
      pickupStatusCondition
      pickup {
        _id
        transactionId
        pickupName
        companyName
        streetAddress
        zipCode
        city
        country
        pickupDate
        pickupOrderDate
        quantity
        items
        truckType
        truckNumberPlate
        imageSrc
        isContracted
        files
        packaging
        collectionType
        collectionDate
        collectionDateRange
        statusId
        status
        canConfirmPickup
        canReschedulePickup
        slaLevel
        new
        documents {
          documentType
          downloadLink
          documentName
          uploadedByMetaloop
          fileType
        }
      }
    }
  }
`;
