import React, { useState } from "react";
import {
  Button,
  FormControl,
  Hidden,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Close } from "@material-ui/icons";
import ImageUpload from "../../../general/ImageUpload";
import { useMutation } from "@apollo/react-hooks";
import EditUserInfo from "../../../../mutations/editUserInformationMutation";
import UserNavBar from "../../../../queries/UserNavBar";
import { showError, showSuccess } from "../../../core/shared/Notify";
import { bulkCheckInjectionAttack } from "../../../../utils/SharedUtils";

const Form = ({ cancel, token, company, userData }) => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      maxWidth: 400,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #9E9E9E"
      }
    },
    headerContainer: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        padding: "16px 0",
        gap: 16
      }
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24px",

      letterSpacing: 0.25
    },
    btnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 16
    }
  }));

  const classes = useStyles();

  const { t } = useTranslation();

  const [firstName, setFirstName] = useState(userData?.firstName);
  const [lastName, setLastName] = useState(userData?.lastName);
  const [position, setPosition] = useState(userData?.position);
  const [phone, setPhone] = useState(userData?.phone);
  const [mobile, setMobile] = useState(userData?.mobile);
  const [file, setFile] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [injectionAttack, setInjectionAttack] = useState({
    firstName: false,
    lastName: false,
    position: false,
    phone: false,
    mobile: false
  });

  const [EditUserInfoMutation] = useMutation(EditUserInfo, {
    refetchQueries: () => [
      {
        query: UserNavBar,
        variables: {
          token,
          company
        }
      }
    ]
  });

  const editInformation = () => {
    setSubmit(true);

    const atLeastOneInjectionAttack = bulkCheckInjectionAttack(
      {
        firstName,
        lastName,
        position,
        phone,
        mobile
      },
      setInjectionAttack
    );

    if (
      !firstName ||
      !lastName ||
      (phone && !isPhoneValid()) ||
      (mobile && !isMobileValid()) ||
      atLeastOneInjectionAttack
    )
      return null;

    EditUserInfoMutation({
      variables: {
        file: file,
        input: {
          firstName: firstName,
          lastName: lastName,
          phone: phone,
          position: position,
          mobile: mobile,
          id: userData._id,
          companyId: company,
          isDeletingDefault: isDeleting
        }
      }
    })
      .then(() => {
        showSuccess(t("Successfully edited"));
        cancel(true);
      })
      .catch(err => showError(err.message));
  };

  const handlePhoneChange = event => {
    const value = event.target.value.replace(/[^\d+]/g, "");
    setPhone(value);
  };

  const handleMobileChange = event => {
    const value = event.target.value.replace(/[^\d+]/g, "");
    setMobile(value);
  };
  const hasAvatar = userData.avatarUrl;

  const ppName = `${t("uploadImage_defaultName")}.jpg`;

  const mobileRegExp = /^\+?[1-9]\d{1,14}$/;

  const isMobileValid = function () {
    return mobileRegExp.test(mobile);
  };

  const isPhoneValid = function () {
    return mobileRegExp.test(phone);
  };

  return (
    <FormControl fullWidth className={classes.container}>
      <div className={classes.headerContainer}>
        <Hidden mdUp>
          <Close onClick={() => cancel()} size="small" />
        </Hidden>
        <Typography className={classes.title}>
          {t("Basic Information")}
        </Typography>
      </div>
      <TextField
        id="firstName"
        label={t("First Name")}
        variant="outlined"
        value={firstName}
        onChange={event => setFirstName(event.target.value)}
        error={submit && (!firstName || injectionAttack.firstName)}
        helperText={
          submit &&
          ((!firstName && t("This field is compulsory")) ||
            (injectionAttack.firstName && t("field_injection_attack")))
        }
      />
      <TextField
        id="lastName"
        label={t("Last Name")}
        variant="outlined"
        value={lastName}
        onChange={event => setLastName(event.target.value)}
        error={submit && (!lastName || injectionAttack.lastName)}
        helperText={
          submit &&
          ((!lastName && t("This field is compulsory")) ||
            (injectionAttack.lastName && t("field_injection_attack")))
        }
      />
      <TextField
        id="companyPosition"
        label={t("Company Position")}
        variant="outlined"
        value={position}
        onChange={event => setPosition(event.target.value)}
        error={submit && injectionAttack.position}
        helperText={
          submit && injectionAttack.position && t("field_injection_attack")
        }
      />
      <TextField
        id="email"
        label={t("Email")}
        variant="outlined"
        disabled
        defaultValue={userData.email}
      />
      <TextField
        id="phone"
        label={t("Phone Number")}
        variant="outlined"
        value={phone}
        onChange={handlePhoneChange}
        error={submit && ((phone && !isPhoneValid()) || injectionAttack.phone)}
        helperText={
          submit &&
          ((phone && !isPhoneValid() && t("contact_invalidPhoneNumber")) ||
            (injectionAttack.phone && t("field_injection_attack")))
        }
      />
      <TextField
        id="mobile"
        label={t("Mobile Number")}
        variant="outlined"
        value={mobile}
        onChange={handleMobileChange}
        error={
          submit && ((mobile && !isMobileValid()) || injectionAttack.mobile)
        }
        helperText={
          submit &&
          ((mobile && !isMobileValid() && t("contact_invalidMobileNumber")) ||
            (injectionAttack.mobile && t("field_injection_attack")))
        }
      />
      <ImageUpload
        onSetImage={(image, isDeleting) => {
          setFile(image);
          setIsDeleting(isDeleting);
        }}
        hasAvatar={hasAvatar}
        {...(hasAvatar
          ? {
              defaultImage: {
                url: userData.avatarUrl,
                filename: ppName
              }
            }
          : {})}
      />
      <div className={classes.btnContainer}>
        <Button variant="outlined" color="primary" onClick={() => cancel()}>
          {t("Cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={editInformation}>
          {t("Save")}
        </Button>
      </div>
    </FormControl>
  );
};

const InfoEditing = ({ cancel, userData, company, token }) => {
  const useStyles = makeStyles(theme => ({
    mobileContainer: {
      minWidth: "100%",
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
      position: "absolute",
      maxHeight: "100%",
      overflowY: "auto",
      zIndex: 100,
      background: "#FFFFFF",
      padding: "0 16px 30px"
    }
  }));

  const classes = useStyles();

  return (
    <>
      <Hidden smDown>
        <Form
          cancel={cancel}
          company={company}
          token={token}
          userData={userData}
        />
      </Hidden>
      <Hidden mdUp>
        <div className={classes.mobileContainer}>
          <Form
            cancel={cancel}
            company={company}
            token={token}
            userData={userData}
          />
        </div>
      </Hidden>
    </>
  );
};

export default InfoEditing;
