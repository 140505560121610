import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { useHistory, useLocation } from "react-router-dom";
import PricingPageSwitcher from "../../components/pricing/pricing-page-switcher/PricingPageSwitcher";
import PricingPageContainer from "../../containers/pricing/PricingPageContainer";
import CategoryProducts from "../../components/pricing/category-page/components/category/CategoryProducts";
import PriceListContainer from "../../components/priceList/PriceListContainer";
import queryString from "query-string";

const styles = theme => ({
  wrapper: { height: "100%", background: "white" }
});

const getSearchParams = location => {
  const searchParams = new URLSearchParams(location.search);

  return {
    type: searchParams.get("type") || "categories",
    category: searchParams.get("category") || "all",
    categoryId: searchParams.get("categoryId") || null
  };
};

const checkType = (location, history) => {
  let query = queryString.parse(location.search);
  const searchParams = new URLSearchParams(query);

  if (searchParams.get("type")) return;

  searchParams.set("type", "categories");
  history.push({
    pathname: location.pathname,
    search: searchParams.toString()
  });
};

const PricingPage = props => {
  const { classes, match } = props;
  const location = useLocation();
  const history = useHistory();
  checkType(location, history);

  const { type, category, categoryId } = getSearchParams(location);

  return (
    <>
      <div className={classes.wrapper}>
        <PricingPageContainer>
          {categoryId && <CategoryProducts />}
          {type === "categories" && !categoryId && (
            <PricingPageSwitcher category={category} />
          )}
          {type === "prices" && <PriceListContainer />}
        </PricingPageContainer>
      </div>
    </>
  );
};

export default withStyles(styles)(PricingPage);
