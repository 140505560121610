import React from "react";
import moment from "moment";
import { Query } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import TransactionStats from "../../components/dashboard/TransactionStats";
import Contacts from "../../components/dashboard/Contacts";
import UserContext from "../../providers/UserProvider";
import UserDashboardQuery from "../../queries/UserDashboard";
import { CheckErrors, reportError } from "../../ErrorTracking";
import { isBuyerAccount } from "../../utils/SharedUtils";
import Header from "../../components/dashboard/Header";
import OnboardingHeader from "../../components/dashboard/OnboardingHeader";

const styles = theme => ({
  sectionContainer: {
    padding: "0 32px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "0 16px"
    }
  },
  largeContainer: {
    padding: "0",

    [theme.breakpoints.down("md")]: {
      padding: "0 20px 0 20px"
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0"
    }
  }
});

class DashboardGraphContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null
    };

    this.startDateChanged = this.startDateChanged.bind(this);
    this.endDateChanged = this.endDateChanged.bind(this);
    this.resetFilters = this.resetFilters.bind(this);
  }

  startDateChanged(startDate) {
    this.setState({
      startDate
    });
  }

  endDateChanged(endDate) {
    this.setState({
      endDate
    });
  }

  resetFilters() {
    this.setState({
      startDate: null,
      endDate: null
    });
  }

  render() {
    const { classes } = this.props;

    return (
      <UserContext.Consumer>
        {({ user, userData, selectedCompany }) => (
          <Query
            query={UserDashboardQuery}
            fetchPolicy="cache-and-network"
            variables={{
              token: user.token,
              company: selectedCompany,
              startDate: convertDateToMutationType(this.state.startDate),
              endDate: convertDateToMutationType(this.state.endDate)
            }}
          >
            {({ data, loading, error }) => {
              const isBuyer = isBuyerAccount(
                selectedCompany,
                userData.UserCompanies
              );
              if (error) {
                reportError(error);
                return null;
              }

              const onboardingToken = userData?.RawUserData?.onboardingToken;

              return (
                <Grid
                  container
                  direction="column"
                  className={classes.largeContainer}
                >
                  {onboardingToken && (
                    <div className={classes.sectionContainer}>
                      <OnboardingHeader onboardingToken={onboardingToken} />
                    </div>
                  )}
                  <div className={classes.sectionContainer}>
                    <Header
                      userData={userData}
                      isOnboardingActive={onboardingToken}
                    />
                  </div>
                  <div className={classes.sectionContainer}>
                    <CheckErrors>
                      <Contacts
                        data={data}
                        loading={loading}
                        userData={userData}
                      />
                    </CheckErrors>
                  </div>
                  <div className={classes.sectionContainer}>
                    <CheckErrors>
                      <TransactionStats
                        data={data}
                        loading={loading}
                        dateChanged={this.dateChanged}
                        startDateChanged={this.startDateChanged}
                        endDateChanged={this.endDateChanged}
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        onFilterReset={this.resetFilters}
                        isSeller={!isBuyer}
                        userData={userData}
                        selectedCompany={selectedCompany}
                      />
                    </CheckErrors>
                  </div>
                </Grid>
              );
            }}
          </Query>
        )}
      </UserContext.Consumer>
    );
  }
}
export default withStyles(styles)(DashboardGraphContainer);

const convertDateToMutationType = date => {
  const dateObj = moment(date, "DD-MM-YYYY");
  if (!dateObj.isValid()) {
    return null;
  }

  return dateObj.format("YYYY-MM-DD");
};
