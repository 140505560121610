import React from "react";
import { Grid, Typography, makeStyles, createStyles } from "@material-ui/core";
import UserAvatar from "../../user/Avatar";
import { useTranslation } from "react-i18next";

const traderStyles = makeStyles(
  createStyles(theme => ({
    container: {
      height: "100%",
      padding: "20px 24px 24px",
      backgroundColor: "rgba(227, 226, 229, 0.5)",
      "& a": {
        color: "inherit",
        textDecoration: "inherit"
      },
      [theme.breakpoints.down("md")]: {
        padding: "20px 16px"
      }
    },
    title: {
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "4px",
      lineHeight: "20px"
    },
    avatar: {
      marginRight: "12px"
    }
  }))
);

const OfferTrader = ({ trader }) => {
  const classes = traderStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <Typography variant="subtitle2" className={classes.title}>
        {t("Have additional questions?")}
      </Typography>
      <Grid container>
        <Grid item className={classes.avatar}>
          <UserAvatar src={trader.avatarURL} width={46} height={46} />
        </Grid>
        <Grid item xs>
          <Typography variant="body2">
            {t("Feel free to contact me")}:
          </Typography>
          <Typography variant="body2">{trader.name}</Typography>
          <Typography variant="body2">
            <a href={"tel:" + trader.phone}>{trader.phone}</a>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default OfferTrader;
