import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import WizardContext from "../../../providers/WizardProvider";
import UserContext from "../../../providers/UserProvider";
import PricingCategoryPage from "../../pricing/category-page/CategoryPage";

const styles = theme => ({});

const PricingPageSwitcher = props => {
  const { category, categoryId } = props;
  return (
    <UserContext.Consumer>
      {({ user, selectedCompany }) => (
        <WizardContext.Consumer>
          {({
            selectedCategories,
            selectedProducts,
            setSelectedCategories,
            setSelectedProducts
          }) => (
            <PricingCategoryPage
              selectedCategories={selectedCategories}
              selectedProducts={selectedProducts}
              setSelectedCategories={setSelectedCategories}
              setSelectedProducts={setSelectedProducts}
              user={user}
              selectedCompany={selectedCompany}
              category={category}
              categoryId={categoryId}
            />
          )}
        </WizardContext.Consumer>
      )}
    </UserContext.Consumer>
  );
};

export default withRouter(withStyles(styles)(PricingPageSwitcher));
