import React from "react";
import AccountContainer from "../../containers/account/Account";
import UserContext from "../../providers/UserProvider";

const ProfilesPage = props => (
  <UserContext.Consumer>
    {({ user, selectedCompany }) => (
      <AccountContainer
        user={user}
        selectedCompany={selectedCompany}
        queryType={props.queryType}
        match={props.match}
      />
    )}
  </UserContext.Consumer>
);

export default ProfilesPage;
