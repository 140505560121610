import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import useStandardLocalization from "../../../hooks/useStandardLocalization";
import CustomTable from "../../general/Table";

const useStyles = makeStyles(theme => ({
  // wrapper: {
  //   "& .padded": {
  //     padding: "0 16px",
  //   },
  //   "& .topContainer": {
  //     marginTop: "24px",
  //   }
  // },
  root: {
    padding: "0 16px",
    marginBottom: "20px",
    maxWidth: canSeePricingInfo => (canSeePricingInfo ? "1082px" : "680px"),
    width: "100%",
    "& .materials": {
      maxWidth: "fit-content",
      whiteSpace: "nowrap"
    },
    "& .quantity": {
      // maxWidth: "120px",
      wordBreak: "break-all"
    },
    "& .purchases": {
      // maxWidth: "120px",
      wordBreak: "break-all"
    },
    "& .totalValue": {
      // maxWidth: "120px",
      wordBreak: "break-all"
    },
    "& .MuiTableContainer-root": {
      border: "1px solid rgba(0, 0, 0, 0.12)",
      boxShadow: "none",
      backgroundColor: "#FAFAFA",
      borderRadius: "4px"
    },
    "& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root": {
      borderBottom: "none"
    },
    [theme.breakpoints.down("sm")]: {
      order: 2,
      padding: "0",
      marginBottom: "24px",
      maxWidth: "auto",
      "& .MuiTableContainer-root": {
        borderRadius: "0"
      },
      "& .MuiTable-root": {
        minWidth: "max-content"
      },
      "& .MuiTableContainer-root": {
        backgroundColor: "#FFF"
      }
    }
  }
}));

const TransactionDesktopCart = props => {
  const { cart, isSeller, canSeePricingInfo = true } = props;
  const classes = useStyles(canSeePricingInfo);
  const { t } = useTranslation();
  const { parseNumber, parseCurrency } = useStandardLocalization();
  const columns = [
    {
      width: "16px",
      spacer: true
    },
    {
      field: "material",
      headerName: t("Material"),
      flex: 1,
      renderCell({ record }) {
        return <div className="materials">{record.material}</div>;
      }
    },
    {
      field: "amount",
      headerName: isSeller ? t("Declared Quantity (kg)") : t("Quantity (kg)"),
      type: "number",
      renderCell({ record }) {
        return <div className="quantity">{parseNumber(record.amount)}</div>;
      }
    },
    isSeller && {
      field: "invoicedAmount",
      headerName: t("Invoiced Quantity (kg)"),
      type: "number",
      renderCell({ record }) {
        return (
          <div className="quantity">
            {record.invoicedAmount ? parseNumber(record.invoicedAmount) : "-"}
          </div>
        );
      }
    },
    canSeePricingInfo && {
      field: isSeller ? "pricePurchase" : "priceAmount",
      headerName: isSeller ? t("Sell Price / kg") : t("Purchase price / kg"),
      flex: 1,
      type: "number",
      renderCell({ record }) {
        if (isSeller) {
          return (
            <div className="purchases">
              {pricePerKg(record.pricePurchase, parseCurrency)}
            </div>
          );
        }
        return (
          <div className="purchases">
            {pricePerKg(record.priceAmount, parseCurrency)}
          </div>
        );
      }
    },
    canSeePricingInfo && {
      headerName: t("Total Value"),
      flex: 1,
      type: "number",
      renderCell({ record }) {
        if (isSeller) {
          const amount = record.invoicedAmount || record.amount;
          return (
            <div className="totalValue">
              {price(
                record.pricePurchase &&
                  Math.round(record.pricePurchase * amount * 100) / 100,
                parseCurrency
              )}
            </div>
          );
        }
        return (
          <div className="totalValue">
            {price(
              record.priceAmount &&
                Math.round(record.priceAmount * record.amount * 100) / 100,
              parseCurrency
            )}
          </div>
        );
      }
    },
    {
      width: "16px",
      spacer: true
    }
  ];
  return (
    <CustomTable
      className={classes.root}
      columns={columns}
      rows={cart}
      getRowId={record => `${record.priceAmount}_${record.amount}`}
      pagination={false}
    />
  );
};

export default TransactionDesktopCart;

export const pricePerKg = (price, parseCurrency = c => c) => {
  if (!price) return "-";
  return <>{parseCurrency(price)}/kg</>;
};

export const price = (price, parseCurrency = c => c) => {
  if (!price) return "-";
  return <>{parseCurrency(price)}</>;
};
