import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import GenericCard from "../../../general/GenericCard";
import { Create } from "@material-ui/icons";
import EditAccountingEmail from "./EditAccountingEmail";
import Loader from "../../../core/shared/Loader";
import { GET_ACCOUNTING_EMAILS } from "../../../../queries/AccountVats";
import { useQuery } from "react-apollo";
import useEventTracking from "../../../../hooks/useEventTracking";
import EmailIcon from "../../../../assets/icons/alternate_email.svg";

const AccountingEmails = ({ onSetMode, mode, token, company }) => {
  const useStyles = makeStyles(_theme => ({
    vatList: {
      listStyleType: "none",
      textAlign: "left",
      margin: "4px 0 0"
    },
    vatListItem: {
      textAlign: "left",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      "&:not(:last-child)": {
        marginBottom: "1px"
      },
      display: "flex",
      alignItems: "center"
    }
  }));

  const { data, loading, error } = useQuery(GET_ACCOUNTING_EMAILS, {
    variables: {
      token: token,
      company: company
    },
    fetchPolicy: "cache-and-network"
  });

  const classes = useStyles();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  if (loading) return <Loader />;

  if (error) return "Error...";

  const { GetAccountingEmails } = data;
  const accountingEmails = GetAccountingEmails.emails || [];

  const onCancel = (isSaved = false) => {
    if (!isSaved)
      TrackEvent("Canceled editing accounting emails", {
        accountId: company
      });
    onSetMode("DEFAULT");
  };

  if (mode === "edit_accounting_info") {
    return (
      <EditAccountingEmail
        type={mode}
        emails={accountingEmails}
        cancel={onCancel}
        token={token}
        company={company}
      />
    );
  }

  if (mode === "DEFAULT") {
    return (
      <GenericCard
        title={t("Accounting Emails")}
        menuInHeader={
          <IconButton
            onClick={() => {
              onSetMode("edit_accounting_info");
            }}
          >
            <Create color="action" />
          </IconButton>
        }
        content={
          <div className={classes.vatWraps}>
            <ul className={classes.vatList}>
              {accountingEmails.map((email, index) => (
                <li
                  key={`${email}_${index}`}
                  className={classes.vatListItem}
                  style={{ paddingBottom: "4px" }}
                >
                  <img
                    src={EmailIcon}
                    alt="Email Icon"
                    style={{ paddingRight: "12px" }}
                  />
                  {email}
                </li>
              ))}
            </ul>
          </div>
        }
      />
    );
  }
  return null;
};

export default AccountingEmails;
