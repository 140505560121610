import React from "react";
import { useTranslation } from "react-i18next";
import { FormControl, makeStyles, Typography, Hidden } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import EditVatForm from "./EditVatForm";
import AddVatForm from "./AddVatForm";
import useEventTracking from "../../../../hooks/useEventTracking";

const Form = ({ currentVat, cancel, token, company, type, vatDetails }) => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      maxWidth: 400,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #9E9E9E"
      }
    },
    headerContainer: {
      display: "flex",
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        padding: "16px 0",
        gap: 16,
        marginBottom: "24px"
      }
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: 0.25
    }
  }));

  const classes = useStyles();

  const { t } = useTranslation();

  const { TrackEvent } = useEventTracking();

  const onCancel = (isSaved = false) => {
    if (!isSaved)
      TrackEvent("Cancel VAT Editing", {
        accountId: company
      });
    cancel();
  };

  return (
    <FormControl fullWidth>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <Hidden mdUp>
            <Close onClick={cancel} size="small" />
          </Hidden>
          <Typography className={classes.title}>
            {type === "add_tax_info"
              ? t("Add VAT number")
              : t("Payment Details")}
          </Typography>
        </div>
      </div>
      {type === "add_tax_info" ? (
        <AddVatForm token={token} company={company} cancel={onCancel} />
      ) : (
        <EditVatForm
          token={token}
          company={company}
          cancel={onCancel}
          vatDetails={vatDetails}
        />
      )}
    </FormControl>
  );
};

const PaymentEditing = ({
  data,
  cancel,
  token,
  company,
  type,
  currentVat,
  vatDetails
}) => {
  const useStyles = makeStyles(theme => ({
    mobileContainer: {
      minWidth: "100%",
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
      position: "absolute",
      maxHeight: "100%",
      overflowY: "auto",
      zIndex: 100,
      background: "#FFFFFF",
      padding: "0 16px 30px"
    }
  }));

  const classes = useStyles();

  return (
    <>
      <Hidden smDown>
        <Form
          currentVat={currentVat}
          cancel={cancel}
          token={token}
          company={company}
          type={type}
          vatDetails={vatDetails}
        />
      </Hidden>
      <Hidden mdUp>
        <div className={classes.mobileContainer}>
          <Form
            currentVat={currentVat}
            cancel={cancel}
            token={token}
            company={company}
            type={type}
            vatDetails={vatDetails}
          />
        </div>
      </Hidden>
    </>
  );
};

export default PaymentEditing;
