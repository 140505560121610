import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  wrapper: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "&>svg": {
      width: "80%",
      maxHeight: "80%"
    }
  }
});

const StepImage = props => (
  <div className={props.classes.wrapper}>{props.src}</div>
);

export default withStyles(styles)(StepImage);
