import React from "react";
import AttachmentIcon from "@material-ui/icons/Attachment";
import DeleteIcon from "@material-ui/icons/Delete";
import { makeStyles } from "@material-ui/core";

export default ({ document, onDelete }) => {
  const useStyles = makeStyles({
    downloadFilesLink: {
      display: "flex",
      textDecoration: "none",
      fontSize: "14px",
      color: "#276EF1",
      lineHeight: "20px",
      fontWeight: "400",
      letterSpacing: "0.25px",
      alignItems: "center",

      "&:visited": {
        color: "#276EF1"
      },
      margin: "6px 0px 6px 0px",
      "&:first-child": {
        marginTop: "0px"
      },
      "&:last-child": {
        marginBottom: "0px"
      }
    },
    clip: {
      color: "#666666",
      marginRight: "8px"
    },
    trash: {
      color: "#666666",
      marginLeft: "4px"
    },
    span: {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis"
    }
  });

  const classes = useStyles();

  return (
    <a
      href={`${document.downloadLink}?download=true`}
      target="_blank"
      download
      className={classes.downloadFilesLink}
      rel="noopener noreferrer"
    >
      <AttachmentIcon className={classes.clip} />
      <span className={classes.span}>{document.documentName}</span>
      <DeleteIcon
        className={classes.trash}
        onClick={e => e.preventDefault() || onDelete()}
      />
    </a>
  );
};
