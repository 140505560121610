import deepExtend from "deep-extend";
import React from "react";
import styled from "styled-components";
import StyledTippy from "../StyledTippy";
import { ReactComponent as CheckmarkIcon } from "../../../../../../assets/checkMarkIcon.svg";
const StyledButton = styled.button(({ theme }) => {
  const buttonStyles = deepExtend(
    {},
    {
      cursor: "pointer",
      border: "none",
      background: "transparent",
      borderRadius: "6px",
      padding: 0,
      marginLeft: "6px",
      height: "24px",
      width: "24px",
      transition: "background 200ms ease-in-out",

      "&:hover": {
        background: "rgba(21,17,36,0.14)"
      }
    },
    {
      path: {
        fill: theme?.brand?.colors?.primary
      }
    },
    theme.action
  );
  return buttonStyles;
});

const MarkAllRead = ({ onClick, label }) => {
  const title = label ?? "Mark All as Read";
  return (
    <StyledTippy content={title}>
      <StyledButton title={title} onClick={onClick}>
        <CheckmarkIcon />
      </StyledButton>
    </StyledTippy>
  );
};

export default MarkAllRead;
