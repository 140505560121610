import React from "react";
import {
  Grid,
  createStyles,
  makeStyles,
  Typography,
  Hidden
} from "@material-ui/core";

import { Link } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";

import { Button } from "./offerElement/inputComponents";
import { useTranslation } from "react-i18next";

const thankYouPopupStyle = createStyles(
  makeStyles(theme => ({
    PopupContainer: {
      position: "absolute",
      top: "0",
      left: "0",
      width: "100vw",
      zIndex: "1100",
      height: "100vh",
      backgroundColor: "rgba(0,0,0,0.5)",
      justifyContent: "center",
      alignItems: "center"
    },
    MobilePopupContainer: {
      backgroundColor: "#FFFFFF",
      position: "fixed",
      display: "flex",
      height: "fit-content",
      bottom: 0,
      padding: 16,
      boxShadow:
        "0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3)",
      borderRadius: "4px 4px 0px 0px"
    },
    PopupItem: {
      width: 640,
      height: "fit-content",
      backgroundColor: "#FFFFFF",
      boxShadow:
        "0px 19px 38px rgba(0, 0, 0, 0.3), 0px 15px 12px rgba(0, 0, 0, 0.22)",
      "& a": {
        color: "rgba(0, 0, 0, 0.87)",
        textDecoration: "none"
      },
      "& a:hover": {
        textDecoration: "underline"
      }
    },
    MobilePopupItem: {
      padding: "0px 21px",
      "& a": {
        color: "rgba(0, 0, 0, 0.87)",
        textDecoration: "none"
      },
      "& a:hover": {
        textDecoration: "underline"
      }
    },
    PopupHeader: {
      marginBottom: 8,
      letterSpacing: 0.25
    },
    MobilePopupHeader: {
      padding: "16px 0"
    },
    MobilePopupHeaderCloseIcon: {
      width: 30,
      height: 6,
      backgroundColor: "#E0E0E0",
      borderRadius: 12,
      cursor: "pointer",
      marginBottom: 16
    },
    MobilePopupHeaderText: {
      paddingBottom: 8,
      letterSpacing: 0.25
    },
    PopupBody: {
      color: "rgba(0, 0, 0, 0.6)",
      lineHeight: "28px",
      letterSpacing: 0.25
    },
    MobilePopupBody: {
      margin: "8px 0 16px",
      color: "rgba(0, 0, 0, 0.6)",
      fontSize: 14
    },
    PopupActions: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "48px"
    },
    closeWrapper: {
      display: "flex",
      justifyContent: "flex-end",
      padding: 8
    },
    container: {
      padding: "8px 24px 24px"
    }
  }))
);

const ThankYouPopup = ({ cancelAction, doneAction, completedOffer }) => {
  const classes = thankYouPopupStyle();
  const { t } = useTranslation();

  return (
    <>
      <Hidden mdUp>
        <Grid
          container
          direction="column"
          className={classes.MobilePopupContainer}
        >
          <Grid container justifyContent="center">
            <div
              className={classes.MobilePopupHeaderCloseIcon}
              onClick={cancelAction}
            />
          </Grid>
          <Grid item className={classes.MobilePopupHeaderText}>
            <Typography variant="h6">
              {t("Thank you for your offer")}
            </Typography>
          </Grid>
          <Grid container item className={classes.MobilePopupBody}>
            <Typography variant="body2">
              {t("Your can view your offer in submitted offers")}.
              <br />
              {t(
                "To bid on the next offer simply select an offer or continue to next offer"
              )}
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end">
            <Button variant="contained" color="primary" onClick={doneAction}>
              {t("Next Offer")}
            </Button>
          </Grid>
        </Grid>
      </Hidden>
      <Hidden smDown>
        <Grid container className={classes.PopupContainer}>
          <Grid container item direction="column" className={classes.PopupItem}>
            <div className={classes.closeWrapper}>
              <CloseIcon
                onClick={cancelAction}
                color="action"
                style={{ cursor: "pointer" }}
              />
            </div>
            <Grid className={classes.container}>
              <Grid item>
                <Typography variant="h6" className={classes.PopupHeader}>
                  {t("Thank you for your offer")}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body1" className={classes.PopupBody}>
                  {t("Your can view your offer in") + " "}
                  <Link
                    to={"/offers/submitted/" + completedOffer}
                    onClick={cancelAction}
                  >
                    {t("submitted offers")}
                  </Link>
                  .
                  <br />
                  {t(
                    "To bid on the next offer simply select an offer or continue to next offer"
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </Typography>
              </Grid>
              <Grid item className={classes.PopupActions}>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={doneAction}
                >
                  {t("Next Offer")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
};

export default ThankYouPopup;
