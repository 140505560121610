import React, { useState, useContext, useCallback } from "react";
import { DialogContent, Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import SUGGEST_NEW_MATERIAL_MUTATION from "../../../mutations/SuggestNewMaterialToCatalogueMutation";
import { useMutation } from "@apollo/react-hooks";
import UserContext from "../../../providers/UserProvider";
import CustomDialog from "../../../components/general/Dialog";
import MaterialInformationForm from "./form";
import { useRedirection } from "../../../hooks/useRedirection";
import useCompany from "../../../hooks/useCompany";
import useEventTracking from "../../../hooks/useEventTracking";
import useUserData from "../../../hooks/useUserData";

const ProductsProposePage = () => {
  const { t } = useTranslation();
  const { user, selectedCompany } = useContext(UserContext);
  const { TrackEvent } = useEventTracking();
  const [suggestNewMaterial] = useMutation(SUGGEST_NEW_MATERIAL_MUTATION);

  const [state, setState] = useState({
    materialName: t("Placeholder_NewMaterial"),
    baseMetalName: "",
    alloyName: "",
    formName: "",
    surfaceName: "",
    comment: "",
    files: []
  });

  const setField = (field, value) => {
    setState(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const notificationAndRedirection = useRedirection();

  const { companyRoles, name: companyName } = useCompany();
  const { fullName, email } = useUserData();

  const materialNotAddedRedirection = () =>
    notificationAndRedirection({
      label: "Snackbar_NoMaterialAdded",
      url: "/products",
      color: "secondary"
    });

  const TrackSubmitEventOnSuggestProduct = useCallback(() => {
    TrackEvent("Adding a new  material flow completed ", {
      accountId: selectedCompany,
      clickSource: "Button",
      roles: companyRoles,
      companyName,
      materialName: state.materialName,
      userName: fullName || email
    });
  }, [state, selectedCompany, companyRoles, companyName, fullName, email]);

  const onSubmit = async () => {
    const mutationVariables = {
      token: user.token,
      partnerId: selectedCompany,
      files: state.files,
      additionalComments: state.comment,
      materialName: state.materialName,
      baseMetal: state.baseMetalName,
      alloy: state.alloyName,
      form: state.formName,
      surface: state.surfaceName
    };

    try {
      TrackSubmitEventOnSuggestProduct();

      const { data } = await suggestNewMaterial({
        variables: mutationVariables
      });

      if (!data.SuggestNewMaterialToCatalogue.message) {
        throw new Error("No message returned from mutation");
      }

      notificationAndRedirection({
        label: "Snackbar_MaterialAddedSuccessfully",
        url: "/products",
        color: "success"
      });
    } catch (error) {
      materialNotAddedRedirection();
    }
  };

  return (
    <>
      <Hidden mdUp>
        <CustomDialog
          open={true}
          title={t("Header_SuggestMaterial")}
          onClose={materialNotAddedRedirection}
        >
          <DialogContent>
            {" "}
            <MaterialInformationForm
              state={state}
              setField={setField}
              onSubmit={onSubmit}
              onClose={materialNotAddedRedirection}
            />
          </DialogContent>
        </CustomDialog>
      </Hidden>
      <Hidden smDown>
        <MaterialInformationForm
          state={state}
          setField={setField}
          onSubmit={onSubmit}
          onClose={materialNotAddedRedirection}
        />
      </Hidden>
    </>
  );
};

export default ProductsProposePage;
