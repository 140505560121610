import {
  Box,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import CustomList from "../../../../../../components/general/List";
import { getDataTypesForOtherFileType } from "./fileTypeOptions";
import { Check, Delete, Visibility } from "@material-ui/icons";
import Info from "@material-ui/icons/Info";
import classNames from "classnames";
import { useCallback } from "react";
import useDocPreview from "../../../../../../hooks/useDocPreview";
import { getFilenameAndExtension } from "../../../../../../utils/Format";
import LoadingCircle from "../../../../../general/LoadingCircle";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(theme => ({
  filelistHeaderContainer: {
    display: "flex"
  },
  customListContainer: {
    width: "50%",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px "
    },

    background: "#F9F9F9",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "24px 24px 16px",
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: "0.25px",
    lineHeight: "24px",
    fontWeight: "400",
    "& .MuiTypography-caption": {
      fontWeight: "400"
    }
  },

  fileSelectionHeader: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "500",
    "&.MuiTypography-gutterBottom": {
      marginBottom: "12px"
    }
  },
  filelist: {
    display: "flex",
    flexDirection: "column",
    gap: "16px"
  },
  filePlaceholder: {
    display: "flex",
    gap: "8px",
    height: "24px",
    marginBottom: "24px",
    "& > span": {
      lineHeight: "24px"
    }
  },
  file: {
    display: "flex",
    gap: "8px",
    minHeight: "24px",
    "& > span": {
      lineHeight: "24px"
    }
  },
  infoMessage: {
    color: "rgba(0, 0, 0, 0.6)"
  },
  dropdownBox: {
    marginTop: "14px"
  },
  captionSVG: {
    color: "rgba(0, 0, 0, 0.6)",
    marginRight: "6px"
  },
  iconButton: {
    height: "24px",
    width: "24px",
    padding: 0,
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)"
    }
  },
  fileExtension: { color: "rgba(0, 0, 0, 0.87)" },
  renameFile: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex",
    alignItems: "center",
    gap: "4px",
    "& >.MuiTextField-root": {
      maxWidth: "280px"
    }
  },
  alignItems: {
    alignItems: "center"
  }
}));

const FileListTwoSteps = ({
  className,
  files,
  setFiles,
  canRename,
  canPreview,
  canRemove,
  uploading,
  step,
  hideInfo = false,
  disableInvoiceUpload = false
}) => {
  const classes = useStyles();

  const { setDocument } = useDocPreview();

  const setDocumentType = useCallback(
    (index, option) => {
      setFiles(
        files.map((file, i) => {
          if (i === index) {
            let newFile = new File([file], files[index].name, {
              type: file.type
            });

            newFile.documentType = option[0].value;

            return newFile;
          }
          return file;
        })
      );
    },
    [files, setFiles]
  );

  const renameFile = useCallback(
    (index, name) => {
      setFiles(
        files.map((file, i) => {
          if (i === index) {
            return new File([file], name, {
              type: file.type
            });
          }
          return file;
        })
      );
    },
    [files, setFiles]
  );

  const previewFile = useCallback(file => {
    setDocument({
      documentName: file.name,
      downloadLink: file.preview,
      fileType: file.type
    });
  }, []);

  const removeFile = useCallback(
    index => {
      files.splice(index, 1);
      setFiles([...files]);
    },
    [files, setFiles]
  );

  if (uploading) {
    canRename = false;
    canPreview = false;
    canRemove = false;
  }
  const { t } = useTranslation();
  return (
    <div>
      <div className={classNames(className, classes.root)}>
        <div className={classes.filelistHeaderContainer}>
          <Typography
            variant="body1"
            className={classNames(
              classes.fileSelectionHeader,
              classes.firstSectionHeader
            )}
            gutterBottom="12px"
          >
            {step === 0 ? (
              <>Selected files:</>
            ) : (
              <>Select the documents type for the selected files:</>
            )}
          </Typography>
        </div>
        {files && files.length > 0 ? (
          <div className={classes.filelist}>
            {files.map((file, i) => (
              <FileItem
                selectedOption={
                  file.documentType && file.documentType.length
                    ? [{ value: file.documentType }]
                    : []
                }
                step={step}
                index={i}
                file={file}
                canRename={canRename}
                onRename={renameFile}
                canPreview={canPreview}
                onPreview={previewFile}
                canRemove={canRemove}
                onRemove={removeFile}
                uploading={uploading}
                setDocumentType={setDocumentType}
                disableInvoiceUpload={disableInvoiceUpload}
              />
            ))}
          </div>
        ) : (
          <Typography variant="body1" className={classes.filePlaceholder}>
            <span>No Files selected yet.</span>
            <ActionButtons disabled />
          </Typography>
        )}
      </div>
      {!uploading && !hideInfo && (
        <Box display="flex" alignItems="center" className={classes.dropdownBox}>
          <Info className={classes.captionSVG} />

          <Typography variant="caption" className={classes.infoMessage}>
            {step === 0 ? (
              <>{t("DragDropInfo")}</>
            ) : (
              <>{t("DragDropFileType")}</>
            )}
          </Typography>
        </Box>
      )}
    </div>
  );
};

const FileItem = ({
  index,
  file = {},
  canRename = true,
  onRename = () => {},
  canPreview = true,
  onPreview = () => {},
  canRemove = true,
  onRemove = () => {},
  setDocumentType = () => {},
  uploading,
  step,
  selectedOption,
  setSelectedOption = () => {},
  disableInvoiceUpload = false
}) => {
  const classes = useStyles();

  const [filename, extension] = getFilenameAndExtension(file.name);
  const { t } = useTranslation();

  const dataTypes = getDataTypesForOtherFileType(t, disableInvoiceUpload);

  return (
    <span
      key={"file_" + index}
      className={classNames(classes.file, canRename && classes.alignItems)}
    >
      {canRename ? (
        <>
          {step === 0 ? (
            <Typography variant="body1" className={classes.renameFile}>
              <TextField
                style={{ backgroundColor: "#ffffff" }}
                label=""
                value={filename}
                onChange={({ currentTarget }) => {
                  onRename(index, currentTarget.value + "." + extension);
                }}
                variant="outlined"
                InputLabelProps={{ shrink: false }}
              />

              <span className={classes.fileExtension}>.{extension}</span>
            </Typography>
          ) : (
            <div className={classes.customListContainer}>
              <Typography variant="body1" className={classes.renameFile}>
                <span>
                  {filename}.{extension}
                </span>
              </Typography>
            </div>
          )}
        </>
      ) : (
        <>
          {uploading && (file.completed ? <Check /> : <LoadingCircle />)}
          <span>{file.name}</span>
        </>
      )}
      {step === 1 ? (
        <div className={classes.customListContainer}>
          <CustomList
            defaultSelected={selectedOption}
            dense={false}
            searchable={false}
            data={dataTypes}
            placeholder={t("UploadDocumentType")}
            multiple={false}
            checkbox={false}
            onChange={option => setDocumentType(index, option)}
          />
        </div>
      ) : (
        <></>
      )}
      {step === 0 ? (
        <ActionButtons
          canPreview={canPreview}
          onPreview={() => onPreview(file)}
          canRemove={canRemove}
          onRemove={() => onRemove(index)}
        />
      ) : (
        <></>
      )}
    </span>
  );
};

const ActionButtons = ({
  canPreview = true,
  onPreview,
  canRemove = true,
  onRemove,
  disabled
}) => {
  const classes = useStyles();

  return (
    <>
      {canPreview && (
        <IconButton
          disabled={disabled}
          onClick={onPreview}
          className={classes.iconButton}
          disableFocusRipple
          disableRipple
        >
          <Visibility />
        </IconButton>
      )}
      {canRemove && (
        <IconButton
          disabled={disabled}
          onClick={onRemove}
          className={classes.iconButton}
          disableFocusRipple
          disableRipple
        >
          <Delete />
        </IconButton>
      )}
    </>
  );
};

export default FileListTwoSteps;
