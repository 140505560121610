import { TextField } from "@material-ui/core/";

import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

const PlacesDropdown = ({
  classes,
  dense = false,
  maxVisibleOptions = 5,
  data = [],
  selected = [],
  multiple = false,
  handleChange = () => {},
  keyUp = () => {},
  placeholder = "",
  errors,
  disabled,
  ...props
}) => {
  const onChange = (event, value) => {
    if (value === null) {
      return handleChange();
    }
    handleChange({ id: value.id, label: value.label?.split(",")[0] });
  };

  const onKeyUp = (event, value) => {
    keyUp(event.target.value);
  };

  const useStyles = makeStyles(theme => ({
    noCheckboxOptions: {
      height: "35px",
      display: "flex",
      alignItems: "center"
    },
    googlePlaceContainer: {
      maxWidth: "400px",
      marginBottom: "20px"
    }
  }));

  const localClasses = useStyles();

  return (
    <div className={`${localClasses.googlePlaceContainer} ${classes}`}>
      <Autocomplete
        limitTags={2}
        ListboxProps={{
          style: { maxHeight: "45" }
        }}
        value={{ id: null, label: selected }}
        multiple={false}
        options={data}
        getOptionLabel={option => option.label}
        renderOption={option => (
          <div className={localClasses.noCheckboxOptions}>{option.label}</div>
        )}
        onKeyUp={onKeyUp}
        onChange={onChange}
        renderInput={params => {
          const inputProps = params.inputProps;
          inputProps.autoComplete = "nope";
          return (
            <TextField
              dense={false}
              disabled={true}
              {...params}
              variant="outlined"
              label={placeholder}
              size="small"
              error={errors}
              helperText={errors ? "This field is required" : ""}
            />
          );
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default PlacesDropdown;
