import React, { Fragment } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Transactions from "./Transactions";
import TransactionStatsFilter from "../../components/transactions/TransactionStatsFilter";
import { canSeeItem } from "../../utils/SharedUtils.js";
import InformationalComponent from "../general/InformationalComponent/index.js";

const styles = theme => ({
  transactionsContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
      marginTop: "4px"
    }
  },
  statsWrapper: {
    marginTop: "16px"
  },
  title: {
    lineHeight: "28px",
    fontWeight: 500,
    marginBottom: "8px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      marginBottom: "4px"
    }
  },
  informationalComponent: {
    width: "980px",
    maxWidth: "980px",
    marginTop: "20px",
    marginBottom: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "16px",
      marginBottom: "0px"
    },
    "& p": { lineHeight: "24px", letterSpacing: "0.25px" }
  }
});

class TransactionStats extends React.Component {
  render() {
    if (!this.props.data || !this.props.data.UserDashboard) {
      return "No data available";
    }

    const {
      loading,
      classes,
      data: { UserDashboard },
      startDateChanged,
      endDateChanged,
      startDate,
      endDate,
      onFilterReset,
      t,
      userData,
      selectedCompany
    } = this.props;

    const canSeeTransactions = canSeeItem(
      selectedCompany,
      userData.UserCompanies,
      {
        rules: "dashboard_transaction_view"
      }
    );

    if (!canSeeTransactions) {
      return null;
    }

    const hasNoTransactions = checkHasNoTransactions(UserDashboard);

    return (
      <Fragment>
        <Grid container spacing={2} className={classes.statsWrapper}>
          <Grid item sm={12}>
            <Typography variant="h5" align="left" className={classes.title}>
              {t("Transaction Statistics")}
            </Typography>
          </Grid>
          <Grid item sm={12} xs={12}>
            <TransactionStatsFilter
              startDateChanged={startDateChanged}
              endDateChanged={endDateChanged}
              startDate={startDate}
              endDate={endDate}
              onFilterReset={onFilterReset}
              disabled={hasNoTransactions}
            />
            {hasNoTransactions && (
              <InformationalComponent
                type="info"
                className={classes.informationalComponent}
              >
                <Typography variant="body1">
                  {t("dashboard_no_transactions")}
                </Typography>
              </InformationalComponent>
            )}
          </Grid>
          {UserDashboard.hasPurchases && (
            <Grid item sm={12} xs={12}>
              <div className={classes.transactionsContainer}>
                <Transactions
                  type="open"
                  loading={loading}
                  number={UserDashboard.openPurchasesNumber}
                  value={UserDashboard.openPurchasesValue}
                  isSeller={false}
                  currencySymbol={UserDashboard.currencySymbol}
                />
                <Transactions
                  type="completed"
                  loading={loading}
                  number={UserDashboard.pastPurchasesNumber}
                  value={UserDashboard.pastPurchasesValue}
                  isSeller={false}
                  currencySymbol={UserDashboard.currencySymbol}
                />
              </div>
            </Grid>
          )}
          {UserDashboard.hasSales && (
            <Grid item sm={12} xs={12}>
              <div className={classes.transactionsContainer}>
                <Transactions
                  type="open"
                  loading={loading}
                  number={UserDashboard.openSalesNumber}
                  value={UserDashboard.openSalesValue}
                  isSeller={true}
                  currencySymbol={UserDashboard.currencySymbol}
                />
                <Transactions
                  type="completed"
                  loading={loading}
                  number={UserDashboard.pastSalesNumber}
                  value={UserDashboard.pastSalesValue}
                  isSeller={true}
                  currencySymbol={UserDashboard.currencySymbol}
                />
              </div>
            </Grid>
          )}
        </Grid>
      </Fragment>
    );
  }
}

export default withStyles(styles)(withTranslation()(TransactionStats));

const checkHasNoTransactions = ({
  openPurchasesNumber,
  pastPurchasesNumber,
  openSalesNumber,
  pastSalesNumber
}) =>
  (openPurchasesNumber || 0) +
    (pastPurchasesNumber || 0) +
    (openSalesNumber || 0) +
    (pastSalesNumber || 0) ===
  0;
