import { useContext } from "react";
import UserProvider from "../providers/UserProvider";
import { isAtGroupAdminAccount } from "../utils/SharedUtils";
const useUserData = () => {
  const { userData, user } = useContext(UserProvider);

  let { RawUserData } = userData || {};

  RawUserData = RawUserData || {};

  return {
    ...RawUserData,
    token: user?.token,
    fullName: getFullName(RawUserData)
  };
};

const getFullName = ({ firstName = "", lastName = "" }) =>
  [firstName, lastName].join(" ").trim();

export const isItAndAdminLoggedInParentCompany = () => {
  const { userData, selectedCompany } = useContext(UserProvider);
  if (!userData) return false;
  const { RawUserData, UserCompanies } = userData;

  return (
    RawUserData.isGroupAdmin &&
    isAtGroupAdminAccount(selectedCompany, UserCompanies)
  );
};

export default useUserData;
