/* eslint-disable jsx-a11y/anchor-is-valid */
import { withTranslation } from "react-i18next";
import React from "react";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";

import Title from "../../components/login/Title";
import LoginButton from "../../components/login/button/LoginButton";
import { Button } from "@material-ui/core";

const styles = theme => ({
  email: {
    color: "#3759F0",
    marginLeft: "10px"
  },
  notSeeingEmail: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "26px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.6)",
    marginTop: "24px",
    "@media (max-width: 400px)": {
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.25px"
    }
  },
  title: {
    marginTop: "20px",
    marginBottom: "16px",
    fontWeight: "500",
    fontSize: "48px",
    lineHeight: "56px",
    "@media (max-width: 400px)": {
      marginTop: "16px",
      marginBottom: "12px",
      fontWeight: "500",
      fontSize: "28px",
      lineHeight: "36px"
    }
  },
  subTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0 0 32px",
    "@media (max-width: 400px)": {
      fontSize: "14px",
      lineHeight: "20px",
      marginBottom: "24px"
    }
  },
  wrapper: {
    width: "100%",
    margin: "0 auto",
    maxWidth: "400px",
    padding: "0 16px"
  },
  emailButton: {
    marginBottom: "10px"
  }
});

class WaitingForEmail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      waitingForMail: true,
      title: "Check your email",
      subTitle: "To login use the link we’ve sent to",
      notSeeingEmail: false,
      disableButton: false
    };
    this.resendMail = this.resendMail.bind(this);
  }
  resendMail() {
    var that = this;
    if (!this.state.disableButton) {
      this.props.sendMail({ email: this.props.email });
      this.setState({ notSeeingEmail: true, disableButton: true });

      setTimeout(function () {
        that.setState({
          disableButton: false
        });
      }, 30000);
    }
  }
  componentDidMount() {
    var that = this;
    if (this.props.redirect === false) {
      that.setState({
        title: "Check your email",
        subTitle: "A reset password has been sent to your address"
      });
    } else {
      setTimeout(function () {
        that.setState({
          waitingForMail: false,
          title: "Didn't receive an email?",
          subTitle: null
        });
      }, 30000);
    }
  }

  render() {
    const { classes, email, t } = this.props;
    return (
      <>
        <Title text={t(this.state.title)} extraClasses={classes.title} />
        {this.state.subTitle && (
          <p className={classes.subTitle}>
            {t(this.state.subTitle)}
            {this.state.waitingForMail && (
              <span className={classes.email}>{email}</span>
            )}
          </p>
        )}
        <div className={classes.wrapper}>
          {this.state.waitingForMail ? (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              href="mailto://"
            >
              {t("Open Email App")}
            </Button>
          ) : (
            <>
              <Button
                className={classes.emailButton}
                variant="contained"
                color="primary"
                fullWidth
                onClick={this.resendMail}
              >
                {t("Resend email")}
              </Button>
              <Button
                className={classes.emailButton}
                variant="outlined"
                color="primary"
                fullWidth
                href="mailto://"
              >
                {t("Open Email App")}
              </Button>

              {this.state.notSeeingEmail ? (
                <Typography
                  align="center"
                  variant="subtitle1"
                  className={classes.notSeeingEmail}
                >
                  {t("Not seeing your email? Check your spam folder")}
                </Typography>
              ) : null}
            </>
          )}
        </div>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(WaitingForEmail));
