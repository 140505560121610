import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";

import GetPickupsCount from "../../queries/GetPickUpCount";
import UserContext from "../../providers/UserProvider";

export const PickupsCounter = ({ type }) => {
  const { user, selectedCompany } = useContext(UserContext);

  const { data, error, loading, refetch } = useQuery(GetPickupsCount, {
    variables: {
      token: user?.token,
      partnerId: selectedCompany
    },
    fetchPolicy: "cache-and-network",
    skip: !user || !selectedCompany
  });

  const includesDownload = window.location.href.includes("download=true");

  if (error && includesDownload) {
    refetch();
    return null;
  }

  if (loading || error || !data) return null;

  const counter = data.PickupsDataCount[type];

  if (counter > 0) return <span>&nbsp;({counter})</span>;

  return null;
};

export default PickupsCounter;
