import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "../../mainContainer/Container";
import RightContent from "../../mainContainer/RightContent";
import StepImage from "../../stepImages/StepImage";

import { ReactComponent as ManPushingCartImage } from "../../../../assets/images/man_pushing_cart.svg";
import Fields from "../components/Fields";

const styles = theme => ({
  stepImage: {
    margin: "13%"
  }
});

const fields = [
  {
    _id: "cSXhKoKW3EdWg95kF",
    name: "Truck with graber"
  },

  {
    _id: "YFEv5AP6LPTCqQuqo",
    name: "Container Service"
  },
  {
    _id: "QgqnmdLcs3tvZTapS",
    name: "Truck with roll-on-roll-off container"
  },

  {
    _id: "GpaboicKojR9jPcWN",
    name: "Analysis Device"
  }
];

class FieldSelectScreen extends React.Component {
  render() {
    const { classes, setStep, selectedFields, setSelectedFields } = this.props;

    return (
      <>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <StepImage
                src={<ManPushingCartImage className={classes.stepImage} />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RightContent>
                <Fields
                  onBack={() => setStep(2)}
                  onNext={() => setStep(4)}
                  fields={fields}
                  selectedFields={selectedFields}
                  onSubmit={setSelectedFields}
                />
              </RightContent>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(FieldSelectScreen);
