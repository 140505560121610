import React from "react";
import { ReactComponent as Sorting_noSort } from "../../../assets/icons/Sorting_noSort.svg";
import { ReactComponent as Sorting_ascending } from "../../../assets/icons/Sorting_ascending.svg";
import { ReactComponent as Sorting_descending } from "../../../assets/icons/Sorting_descending.svg";

const SortingIcon = ({ active = false, order = "asc" }) => {
  if (active) {
    return order === "asc" ? <Sorting_ascending /> : <Sorting_descending />;
  }
  return <Sorting_noSort />;
};

export default SortingIcon;
