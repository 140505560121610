import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  container: {
    height: "132px",
    width: "calc(50% - 8px)", // The half of the space between two elements
    background: "#FFFFFF",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.24)",
    borderRadius: "2px",
    marginBottom: "16px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: 36
  },
  text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: theme.palette.primary.main,
    marginTop: "16px"
  }
}));

const HomeMobileItem = props => {
  const { to, icon, text } = props;
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const goToPage = () => {
    history.push(to);
  };

  return (
    <div className={classes.container} onClick={goToPage}>
      {icon}
      <span className={classes.text}>{t(text)}</span>
    </div>
  );
};

export default HomeMobileItem;
