import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Hidden } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  mobileContainer: {
    minWidth: "100%",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    position: "absolute",
    maxHeight: "100%",
    overflowY: "auto",
    zIndex: 100,
    background: "#FFFFFF",
    padding: "0 16px 30px"
  },
  form: {
    margin: "32px 0px 0px 32px",
    width: "100%",
    maxWidth: "400px"
  },
  mobileForm: {
    maxWidth: "100%",
    padding: "0 16px"
  }
}));

export default ({ children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <>
      <Hidden smDown>
        <div className={classes.form}>{children}</div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.mobileContainer}>
          <div className={classes.mobileForm}>{children}</div>
        </div>
      </Hidden>
    </>
  );
};
