import { Checkbox, TextField } from "@material-ui/core/";

import React from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { makeStyles } from "@material-ui/core/styles";

const calculateDropdownHeight = ({
  checkbox,
  dense,
  data,
  maxVisibleOptions
}) => {
  const heightNotDense = checkbox ? "50" : "45";
  const heightDense = checkbox ? "40" : "26.5";

  const multiplier = dense ? heightDense : heightNotDense;
  var maxHeight = maxVisibleOptions * multiplier + "px";

  if (maxVisibleOptions > data.length) {
    maxHeight = data.length * multiplier + "px";
  }

  return maxHeight;
};

const SearchableList = ({
  checkbox = false,
  searchable = false,
  dense = false,
  maxVisibleOptions = 5,
  data = [],
  selected = [],
  multiple = false,
  handleChange = () => {},
  placeholder = "",
  ...props
}) => {
  const onChange = (event, value) => {
    handleChange(value);
  };

  var maxHeight = calculateDropdownHeight({
    checkbox,
    dense,
    data,
    maxVisibleOptions
  });

  const useStyles = makeStyles(theme => ({
    noCheckboxOptions: {
      height: dense ? "15px" : "35px",
      display: "flex",
      alignItems: "center"
    }
  }));

  const classes = useStyles();

  return (
    <Autocomplete
      limitTags={2}
      ListboxProps={{
        style: { maxHeight: maxHeight }
      }}
      value={selected}
      multiple={multiple}
      options={data}
      disableCloseOnSelect
      getLimitTagsText={() => "..."}
      getOptionLabel={option => {
        if (Array.isArray(option) && option.length > 0 && option[0] !== null) {
          return option[0].label;
        } else {
          return option.label;
        }
      }}
      renderTags={value =>
        value.map((option, index) =>
          index === 0 ? option.label : ", " + option.label
        )
      }
      renderOption={(option, selected) => (
        <>
          {checkbox ? (
            <>
              <Checkbox
                color="primary"
                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                checkedIcon={<CheckBoxIcon fontSize="small" />}
                style={{ height: dense ? "10px" : "20px" }}
                checked={selected.selected}
              />{" "}
              {option.label}
            </>
          ) : (
            <div className={classes.noCheckboxOptions}> {option.label}</div>
          )}
        </>
      )}
      onChange={onChange}
      renderInput={params => (
        <TextField
          dense={false}
          disabled={true}
          {...params}
          variant="outlined"
          label={placeholder}
          size="small"
        />
      )}
    />
  );
};

export default SearchableList;
