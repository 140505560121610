import { makeStyles } from "@material-ui/core";
import { useState, useContext } from "react";
import { useLazyQuery } from "react-apollo";
import getNonExistingMaterials from "../../../../../queries/getNonExistingMaterials";
import UserContext from "../../../../../providers/UserProvider";

import { useTranslation } from "react-i18next";

import Loader from "../../Loader";
import Papa from "papaparse";
import {
  validateSchema,
  preAnalysis,
  createErrorsObject,
  validateUniqueIds,
  markDuplicateIds,
  extraValidation,
  cleanLastRowIfEmpty,
  getMaterialIds
} from "../utils/helpers";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("md")]: {
      width: "640px",
      marginBottom: "50px",
      marginTop: "50px"
    },
    "& > .MuiDialogContentText-root": {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "20px"
      },
      paddingBottom: "20px"
    },
    "& > .MuiDialogActions-root": {
      paddingBottom: "20px"
    }
  }
}));

export default ({
  file,
  setNextStepAction,
  fileContent,
  setFileContent,
  type,
  setNext2StepAction,
  title
}) => {
  const classes = useStyles();
  const [analized, setAnalized] = useState(false);
  const [getWrongMaterials, { data: wrongMaterials, loading, error }] =
    useLazyQuery(getNonExistingMaterials, { fetchPolicy: "network-only" });
  const { user, selectedCompany } = useContext(UserContext);
  if (loading) {
    return (
      <>
        {title}
        <div className={classes.root}>
          <Loader />
        </div>
      </>
    );
  }

  //We read the CSV file and analize it
  if (file && !fileContent) {
    const reader = new FileReader();
    reader.onload = function (e) {
      const csv = Papa.parse(e.target.result, {
        header: true
      });

      const parsedData = csv?.data;
      setFileContent(parsedData);
    };
    reader.readAsText(file);
  }

  if (fileContent) {
    const materialIds = getMaterialIds(fileContent);
    if (!wrongMaterials && !loading)
      getWrongMaterials({
        variables: {
          token: user.token,
          partnerId: selectedCompany,
          materialIds: materialIds
        }
      });
    if (!analized && !loading && !error && wrongMaterials) {
      //First we look if the contract_ids are unique and if not we mark them
      if (!validateUniqueIds(type, fileContent)) {
        markDuplicateIds(type, fileContent);
      }

      //We map the rows of the file content and if any value is empty we set the error
      fileContent.map((row, index) => {
        preAnalysis(type, row);
        extraValidation(
          type,
          row,
          wrongMaterials?.getNonExistingMaterials?.materialList
        );

        const { valid, errors } = validateSchema(type, row);
        if (!valid) {
          const { errorsArray, errorsWithData } = createErrorsObject(errors);
          fileContent[index].error = [
            ...errorsArray,
            ...(fileContent[index].error ? fileContent[index].error : [])
          ];
          fileContent[index].errorsWithData = {
            ...errorsWithData,
            ...(fileContent[index].errorsWithData
              ? fileContent[index].errorsWithData
              : {})
          };
        }
      });
      cleanLastRowIfEmpty(fileContent);
      setAnalized(true);
      setFileContent(fileContent);

      //If there is no error we go two steps on, else only one
      if (!fileContent.some(row => row.error?.length > 0)) {
        setNext2StepAction();
      } else {
        setNextStepAction();
      }
    }
  }

  return (
    <>
      {title}
      <div className={classes.root}>
        <Loader />
      </div>
    </>
  );
};
