import React from "react";
import { Typography, makeStyles, createStyles } from "@material-ui/core";

const commentStyle = makeStyles(
  createStyles(theme => ({
    container: {
      minHeight: "100%",
      padding: "20px 24px 24px",
      backgroundColor: "#151124",
      color: "#FFFFFF",
      lineHeight: "20px",
      letterSpacing: 0.25,
      "& p": {
        margin: 0
      },
      [theme.breakpoints.down("md")]: {
        padding: "20px 16px 24px"
      }
    },
    title: {
      fontWeight: 500,
      marginBottom: "8px",
      lineHeight: "20px",
      letterSpacing: 0.25,
      [theme.breakpoints.down("md")]: {
        marginBottom: "4px"
      }
    }
  }))
);

const DEFAULT_COMMENT = "-";

const OfferComment = ({ comment }) => {
  const classes = commentStyle();

  return (
    <div className={classes.container}>
      <Typography variant="subtitle2" className={classes.title}>
        Schrott24
      </Typography>
      <Typography
        variant="body2"
        dangerouslySetInnerHTML={{
          __html: comment || DEFAULT_COMMENT
        }}
      />
    </div>
  );
};

export default OfferComment;
