import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  circleContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  circle: {
    width: "20px",
    height: "20px",
    "border-radius": "50%",
    backgroundColor: "transparent",
    color: "white",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "bold"
  },
  circleActive: {
    backgroundColor: "#52F597"
  },
  circleInactive: {
    color: "#95949C",
    borderColor: "#95949C",
    backgroundColor: "white"
  }
}));

const CircleWithNumber = ({ number, activeStep, overrideClasses }) => {
  const classes = useStyles();
  const active = activeStep === number;
  const isAlreadyOver = number < activeStep;

  const circleClass = overrideClasses.circle || classes.circle;
  const circleActiveClass =
    overrideClasses.circleActive || classes.circleActive;
  const circleInactiveClass =
    overrideClasses.circleInactive || classes.circleInactive;
  const circleAlreadyOver =
    overrideClasses.circleAlreadyOver || classes.circleAlreadyOver;
  const borderStyleActive = overrideClasses.borderClassActive || {
    border: "2px solid #52F597"
  };
  const borderStyleInactive = overrideClasses.borderClassInactive || {
    border: "2px solid grey"
  };

  return (
    <div className={classes.circleContainer}>
      <div
        className={`${
          active
            ? circleActiveClass
            : isAlreadyOver
              ? circleAlreadyOver
              : circleInactiveClass
        } ${circleClass}`}
        style={
          isAlreadyOver || active ? borderStyleActive : borderStyleInactive
        }
      >
        {number}
      </div>
    </div>
  );
};

export default CircleWithNumber;
