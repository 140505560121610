import { makeStyles, Button } from "@material-ui/core";
import { useState, useContext } from "react";

import { useTranslation } from "react-i18next";

import Loader from "../../Loader";
import PairingTool from "../components/PairingTool";
import { Materials_table_headers } from "../utils/constants";
import GetEnterpriseAliases from "../../../../../queries/GetEnterpriseAliases";
import UserContext from "../../../../../providers/UserProvider";
import { useQuery } from "react-apollo";

const useStyles = makeStyles(theme => ({
  root: {
    width: "1000px",
    padding: "8px 24px",
    "& > .MuiDialogContentText-root": {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "20px"
      },
      paddingBottom: "20px"
    },
    "& > .MuiDialogActions-root": {
      paddingBottom: "20px"
    }
  },
  buttonClass: {
    marginTop: "16px",
    marginRight: "96px",
    float: "right"
  }
}));

export default ({
  file,
  setNextStepAction,
  fileContent,
  setFileContent,
  type,
  title
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [analized, setAnalized] = useState(false);
  const [materials, setMaterials] = useState({});
  const [objectPaired, setObjectPaired] = useState({});
  const [pairsAnalized, setPairsAnalized] = useState(false);
  const {
    selectedCompany,
    user: { token }
  } = useContext(UserContext);

  if (!analized) {
    const newMaterials = {};
    //First, we stract the already paired objects (metaloop_material_id and material_name included)
    fileContent.map(item => {
      if (item.metaloop_material_id && item.material_name)
        newMaterials[item.material_name] = item.metaloop_material_id;
    });

    //Now we push the rest of the names
    fileContent.map(item => {
      if (
        !item.metaloop_material_id &&
        !newMaterials.hasOwnProperty(item.material_name)
      )
        newMaterials[item.material_name] = null;
    });
    setMaterials(newMaterials);
    setObjectPaired(newMaterials);
    setAnalized(true);
  }

  const canContinue = () => {
    if (Object.keys(materials).length !== Object.keys(objectPaired).length)
      return false;
    for (let material in materials) {
      if (!(objectPaired[material] && objectPaired[material] !== "null"))
        return false;
    }
    return true;
  };

  const continueNextStep = () => {
    const newFileContent = fileContent.map(item => {
      if (item.metaloop_material_id) return item;

      return {
        ...item,
        ...(objectPaired[item.material_name]
          ? { metaloop_material_id: objectPaired[item.material_name] }
          : {})
      };
    });
    setFileContent(newFileContent);
    setNextStepAction();
  };

  const { data, loading } = useQuery(GetEnterpriseAliases, {
    variables: {
      token: token,
      companyId: selectedCompany
    },
    fetchPolicy: "network-only"
  });

  if (!loading && !pairsAnalized && Object.keys(materials).length > 0) {
    if (
      data &&
      data.getEnterpriseAliases &&
      data.getEnterpriseAliases.length > 0
    ) {
      const pairedAliasObj = {};
      const newMaterials = { ...materials };
      //First we index correctly the already paired objects
      data.getEnterpriseAliases.map(alias => {
        if (alias.aliasName && alias.metaloopAliasId) {
          pairedAliasObj[alias.aliasName] = alias.metaloopAliasId;
        }
      });

      //Now we loop the materials, and if it's already paired, we replace the value
      Object.keys(newMaterials).map(material => {
        if (pairedAliasObj[material]) {
          newMaterials[material] = pairedAliasObj[material];
        }
      });

      setMaterials(newMaterials);
      //We set the objects with the material preset as paired:
      const objectPaired_ = Object.fromEntries(
        Object.entries(newMaterials).filter(([_, value]) => value !== null)
      );
      setObjectPaired(objectPaired_);
      //If everything is paired, we continue to the next step
      if (Object.values(newMaterials).every(value => value !== null)) {
        const newFileContent = fileContent.map(item => {
          if (item.metaloop_material_id) return item;
          return {
            ...item,
            ...(objectPaired_[item.material_name]
              ? { metaloop_material_id: objectPaired_[item.material_name] }
              : {})
          };
        });
        setFileContent(newFileContent);
        setNextStepAction();
      }
    }
    setPairsAnalized(true);
  } else {
    //If the user write all the material ids and has no material names, then we just go to the next step
    if (
      analized &&
      !loading &&
      canContinue() &&
      Object.keys(materials).length === 0
    ) {
      continueNextStep();
    }
  }

  return (
    <>
      {title}
      <div className={classes.root}>
        {Object.keys(materials).length > 0 && !loading ? (
          <PairingTool
            columnHeaders={Materials_table_headers}
            rows={materials}
            type={"materials"}
            objectPaired={objectPaired}
            setObjectPaired={setObjectPaired}
          />
        ) : (
          <Loader />
        )}{" "}
        <Button
          color="secondary"
          classes={{ root: classes.buttonRoot }}
          variant="contained"
          onClick={() => {
            continueNextStep();
          }}
          disabled={!canContinue()}
          className={classes.buttonClass}
        >
          {t("import_dialog_continue")}
        </Button>
      </div>
    </>
  );
};
