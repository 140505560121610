import React from "react";
import classNames from "classnames";
import { useState, useRef, useEffect } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import AttachmentIcon from "@material-ui/icons/Attachment";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "rgba(21, 17, 36, 0.05)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
    maxWidth: "400px",
    height: "100px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer"
  },
  rootWithoutSize: {
    maxWidth: "unset",
    height: "unset"
  },
  imageDiv: {
    maxWidth: "400px"
  },
  text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    pointerEvents: "none"
  },
  icon: {
    marginRight: "4px"
  },
  image: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    marginBottom: "10px"
  },
  imageUpload: {
    margin: "4px 0",
    height: "92px",
    width: "92px",
    objectFit: "cover"
  },
  transparencyIcon: {
    color: "rgba(0, 0, 0, 0.6)"
  },
  deleteIcon: {
    cursor: "pointer"
  },
  imageText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0px 4px 0px 4px"
  },
  fileNameWrap: {
    display: "flex",
    alignItems: "center"
  },
  dragover: {
    backgroundColor: "rgba(21, 17, 36, 0.1)"
  }
}));

//default image is an object with url and filename as properties
export default ({
  text = "Upload profile picture",
  onSetImage = (image, isDeletingDefault) => {},
  hasAvatar,
  defaultImage = null,
  children
}) => {
  const { t } = useTranslation();
  const [existingImage, setExistingImage] = useState(defaultImage);
  const [image, setImage] = useState(null);
  const [isDefaultDeleted, setIsDefaultDeleted] = useState(false);

  const [isDraggedOver, setIsDraggedOver] = useState(false);

  const classes = useStyles();

  const fileInput = useRef(null);

  const openFileBrowser = () => {
    fileInput.current.click();
  };

  const onDragOver = e => {
    e.preventDefault();
    setIsDraggedOver(true);
  };
  const onDragLeave = e => {
    e.preventDefault();
    setIsDraggedOver(false);
  };

  const droppedFile = e => {
    e.preventDefault();
    setIsDraggedOver(false);

    const file = e.dataTransfer.files[0];
    if (file) {
      if (file.type.split("/")[0] === "image") {
        setImage(file);
      }
    }
  };

  const removeImage = () => {
    setExistingImage(null);
    setImage(null);
    setIsDefaultDeleted(true);
  };

  useEffect(() => {
    onSetImage(image, defaultImage !== null && existingImage === null);
  }, [image, existingImage]);

  const buttonText = hasAvatar ? "Replace profile picture" : text;
  return (
    <>
      {!image && !existingImage ? (
        <div
          className={classNames(
            classes.root,
            isDraggedOver && classes.dragover,
            children && classes.rootWithoutSize
          )}
          onClick={openFileBrowser}
          onDragOver={e => e.preventDefault()}
          onDragEnter={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={droppedFile}
        >
          {children ? (
            children
          ) : (
            <Typography variant="body1" className={classes.text}>
              <AddIcon className={classes.icon} />
              <span>{t(buttonText)}</span>
            </Typography>
          )}
          <input
            ref={fileInput}
            type="file"
            accept="image/*"
            hidden
            onChange={e => setImage(e.target.files[0])}
          />
        </div>
      ) : defaultImage && !isDefaultDeleted ? (
        <div>
          <img className={classes.image} src={defaultImage.url} />
          <div className={classes.fileNameWrap}>
            <AttachmentIcon className={classes.transparencyIcon} />
            <span className={classes.imageText}>{defaultImage.filename}</span>
            <DeleteIcon
              className={`${classes.transparencyIcon} ${classes.deleteIcon}`}
              onClick={removeImage}
            />
          </div>
        </div>
      ) : (
        <div>
          <img className={classes.image} src={URL.createObjectURL(image)} />
          <div className={classes.fileNameWrap}>
            <AttachmentIcon className={classes.transparencyIcon} />
            <span className={classes.imageText}>{image.name}</span>
            <DeleteIcon
              className={`${classes.transparencyIcon} ${classes.deleteIcon}`}
              onClick={removeImage}
            />
          </div>
        </div>
      )}
    </>
  );
};
