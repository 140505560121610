/* eslint import/no-webpack-loader-syntax: off */
// the above is a hack to import svg's as path
import Icon from "@material-ui/core/Icon";
import React, { Fragment, useContext, useEffect, useRef } from "react";
import { useApolloClient } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import UserContext from "../../../providers/UserProvider";
import {
  getFormattedCompanyList,
  convertFormattedCompanyListToObject
} from "../../../utils/SharedUtils";
import { useState } from "react";
import {
  Grid,
  Divider,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio
} from "@material-ui/core";
import { Button } from "../Button";
import UserAvatar from "../../user/Avatar";
import useMetaloopUrl from "../../../hooks/useMetaloopUrl";
import useEventTracking from "../../../hooks/useEventTracking";
import { showSuccess } from "./Notify";
import { isItAndAdminLoggedInParentCompany } from "../../../hooks/useUserData";

const useStyles = makeStyles(theme => ({
  caret: {
    cursor: "hand"
  },
  iconGray: {
    color: "rgba(0,0,0,0.33)"
  },
  nameContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "10px",
    cursor: "pointer"
  },
  icon: {
    color: "white",
    cursor: "pointer"
  },

  menuContainer: {
    display: "flex",
    position: "absolute",
    top: 50,
    right: 20,
    width: "296px",
    height: "500px",
    zIndex: 100,
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
      height: "calc(var(--vh, 1vh) * 100)",
      top: 0,
      right: 0
    }
  },
  menuContainerForAdmin: {
    height: "550px"
  },
  rightContainer: {
    display: "flex",
    backgroundColor: "white",
    flexGrow: 1,
    minWidth: "304px",
    flexBasis: 0,
    boxShadow:
      "0px 2px 4px rgba(0, 0, 0, 0.24), 0px 4px 8px rgba(0, 0, 0, 0.18)",
    [theme.breakpoints.down("sm")]: {
      minWidth: "204px"
    }
  },
  managedText: {
    color: "rgba(0, 0, 0, 0.6)",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    background: "#E9EDEE",
    padding: "10px 24px 10px 16px"
  },
  accountInfo: {
    padding: "25px 20px 0"
  },
  accountInfoText: {
    padding: "0 12px",
    width: "100px"
  },
  emailText: {
    width: "100%"
  },
  manageAccount: {
    padding: "25px 20px"
  },
  logoutButton: {
    padding: "20px 22px"
  },
  companiesContainer: {
    padding: "10px 20px",
    overflowY: "scroll",
    "& > fieldset > legend": {
      textTransform: "capitalize",
      padding: "10px 0"
    }
  },
  radioItem: {
    padding: "0 8px 8px"
  },
  text: {
    marginLeft: "7px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "#FFFFFF"
  },
  versionNumber: {
    color: "black",
    fontStyle: "normal",
    fontWeight: "400",
    marginTop: "14px"
  },
  tos: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "12px",
    "& a": {
      fontFamily: "Roboto",
      fontWeight: "400",
      fontsize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px",
      textDecoration: "underline",
      color: "rgba(0, 0, 0, 0.6)"
    }
  },
  changeButtonText: {
    marginTop: "24px"
  },
  radioLabelTop: {
    display: "flex",
    "align-items": "flex-start"
  }
}));

const DropDownNavMenu = ({ onLogout, showName }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { selectedCompany, userData } = useContext(UserContext);
  const { RawUserData } = userData;
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const releaseVersionNumber = process.env.REACT_APP_RELEASE_VERSION_NUMBER;
  const { metaloopBaseURL } = useMetaloopUrl();
  const history = useHistory();
  const client = useApolloClient();
  const handleClose = () => setAnchorEl(null);
  const toggleMenu = ev =>
    setAnchorEl(anchorEl === null ? ev.currentTarget : null);
  const containerRef = useRef(null);
  const user = RawUserData;
  const { TrackEvent } = useEventTracking();

  useEffect(() => {
    // Executed only once
    document.addEventListener("mousedown", handleClick, false);
    // set preferredLanguage on load
    const preferredLanguage = RawUserData?.preferredLanguage;
    i18n.changeLanguage(preferredLanguage || "de");
  }, []);

  const handleClick = e => {
    if (
      containerRef &&
      containerRef.current &&
      containerRef.current.contains(e.target)
    ) {
      // Click inside component --> Do nothing
      return;
    }
    handleClose();
  };

  // Manage swipe event on mobile view
  const moveRightContainer = evt => {
    let value = evt.targetTouches[0].clientX - 150;
    value = value > 0 ? value : 0;
    const div = containerRef.current.querySelector(
      'div[class*="rightContainer"]'
    );
    div.style.transform = `translateX(${value}px)`;
  };

  // Manage swipe event on mobile view
  const closeRightContainer = evt => {
    const value = evt.changedTouches[0].clientX - 150;
    if (value > 100) {
      const div = containerRef.current.querySelector(
        'div[class*="rightContainer"]'
      );
      div.style.transform = "none";
      setAnchorEl(null);
    }
  };

  //Copies link to clipboard
  const copyToClipboard = () => {
    const partnerId = selectedCompany;
    let url = window.location.href;
    url = url.includes("?")
      ? url + "&partner=" + partnerId
      : url + "?partner=" + partnerId;
    navigator.clipboard.writeText(url);
    handleClose();
    showSuccess(t("copy_clickboard_notification_text"));
  };

  const mapCompanies = () => {
    const companyList = getFormattedCompanyList(
      userData.UserCompanies,
      selectedCompany
    );
    const companyListObject = convertFormattedCompanyListToObject(companyList);

    const companyListKeys = Object.keys(companyListObject);

    return (
      <>
        {companyList &&
          companyListKeys.map(key =>
            companyListObject[key].length > 0 ? (
              <Fragment key="key">
                <FormControl component="fieldset">
                  <FormLabel component="legend">{t(key)}</FormLabel>
                  <RadioGroup
                    aria-label={key}
                    name={key}
                    value={selectedCompany}
                    onChange={item =>
                      client.writeData({
                        data: { selectedCompany: item.currentTarget.value }
                      })
                    }
                  >
                    {companyListObject[key].map(uc => (
                      <FormControlLabel
                        value={uc._id}
                        key={uc._id}
                        className={`${classes.radioItem} ${classes.radioLabelTop}`}
                        control={<Radio color="primary" />}
                        label={
                          <Typography
                            variant="body2"
                            color="primary"
                            style={{ paddingTop: "10px" }}
                          >
                            {uc.branch ? `${uc.name} (${uc.branch})` : uc.name}
                          </Typography>
                        }
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Fragment>
            ) : null
          )}
      </>
    );
  };

  const userCompanyObject = userData.UserCompanies.find(
    item => item._id === selectedCompany
  );

  const avatarUrl = RawUserData.avatarUrl;
  return (
    <div ref={containerRef}>
      <div className={classes.nameContainer} onClick={toggleMenu}>
        {avatarUrl ? (
          <UserAvatar src={avatarUrl} height={28} width={28} />
        ) : (
          <Icon className={classes.icon} style={{ fontSize: 28 }}>
            account_circle
          </Icon>
        )}
        {showName ? (
          <span
            className={classes.text}
          >{`${user.firstName} ${user.lastName}`}</span>
        ) : null}
      </div>

      {anchorEl && (
        <div
          className={` ${
            RawUserData.isAdmin || RawUserData.isGroupAdmin
              ? classes.menuContainerForAdmin
              : ""
          } ${classes.menuContainer} `}
          onTouchMove={moveRightContainer}
          onTouchEnd={closeRightContainer}
        >
          <div className={classes.rightContainer}>
            <Grid container xs direction="column" wrap="nowrap">
              <Grid
                container
                item
                className={classes.accountInfo}
                direction="row"
              >
                <Grid item>
                  <UserAvatar src={RawUserData.avatarUrl} />
                </Grid>
                <Grid item xs className={classes.accountInfoText}>
                  <Grid container item direction="column">
                    <Grid item>
                      <Typography variant="body1" style={{ color: "black" }}>
                        {`${user.firstName} ${user.lastName}`}
                      </Typography>
                    </Grid>
                    <Grid item className={classes.emailText}>
                      <Typography
                        variant="body2"
                        style={{
                          color: "rgba(0, 0, 0, 0.6)",
                          textOverflow: "ellipsis",
                          overflow: "hidden"
                        }}
                      >
                        {RawUserData.email}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item className={classes.manageAccount}>
                {userData.UserCompanies.find(
                  item => item._id === selectedCompany
                ).rules.find(
                  item => item === "account_settings_my_data_ui"
                ) && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => {
                      TrackEvent("User clicked Manage Account", {
                        accountId: selectedCompany
                      });
                      history.push("/profile/userInformation");
                      handleClose();
                    }}
                  >
                    {t("Manage Account")}
                  </Button>
                )}
                {RawUserData.isAdmin && (
                  <Button
                    variant="outlined"
                    color="primary"
                    className={classes.changeButtonText}
                    fullWidth
                    onClick={() => {
                      client.writeData({
                        data: { selectedCompany: "all" }
                      });
                    }}
                  >
                    {t("Change Company")}
                  </Button>
                )}
                {RawUserData.isGroupAdmin &&
                  !isItAndAdminLoggedInParentCompany() && (
                    <Button
                      variant="outlined"
                      color="primary"
                      className={classes.changeButtonText}
                      fullWidth
                      onClick={() => {
                        const path = userCompanyObject?.parentAccountId
                          ? {
                              pathname: `/dashboard`,
                              search: `?partner=${userCompanyObject.parentAccountId}`
                            }
                          : { pathname: "/dashboard" };
                        history.push(path);
                        handleClose();
                      }}
                    >
                      {t("Button_go_back_admin_portal")}
                    </Button>
                  )}
                {RawUserData.isAdmin && (
                  <Button
                    variant="text"
                    color="primary"
                    fullWidth
                    onClick={copyToClipboard}
                  >
                    {t("copy_clickboard_button_text")}
                  </Button>
                )}
              </Grid>
              <Divider />
              <Grid
                container
                item
                xs
                direction="column"
                wrap="nowrap"
                className={classes.companiesContainer}
              >
                {mapCompanies()}
              </Grid>
              <Divider />
              <Grid item className={classes.logoutButton}>
                <Button
                  variant="outlined"
                  fullWidth
                  onClick={() => {
                    history.push("/login");
                    handleClose();
                    onLogout();
                  }}
                >
                  {t("Logout")}
                </Button>
                <div className={classes.tos}>
                  <a
                    href={`${metaloopBaseURL}/terms-and-conditions`}
                    target="_blank"
                  >
                    {t("Footer_Terms-of-service")}
                  </a>
                  <a href={`${metaloopBaseURL}/disclaimer`} target="_blank">
                    {t("Footer_Disclaimer")}
                  </a>
                </div>
                <div className={classes.versionNumber}>
                  {t("release_version_number", {
                    version: releaseVersionNumber
                  })}
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </div>
  );
};

export default DropDownNavMenu;
