import React, { useState } from "react";
import {
  Button,
  FormControl,
  Hidden,
  makeStyles,
  TextField,
  Typography,
  InputAdornment
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Close,
  Visibility,
  VisibilityOff,
  AccountCircle
} from "@material-ui/icons";
import ChangePassword from "../../../../mutations/changePassword";
import { useMutation } from "@apollo/react-hooks";
import { showError, showSuccess } from "../../../core/shared/Notify";

const Form = ({ cancel, userData }) => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      maxWidth: 400,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #9E9E9E"
      }
    },
    headerContainer: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        padding: "16px 0",
        gap: 16
      }
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24px",

      letterSpacing: 0.25
    },
    titleInside: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: 0.25,
      [theme.breakpoints.down("sm")]: {
        fontSize: 16,
        marginBottom: "-16px"
      }
    },
    adornment: {
      cursor: "pointer"
    },
    btnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 16
    },
    disabledIcon: {
      color: "rgba(0, 0, 0, 0.20)"
    },
    textFieldBottomSpacing: {
      marginBottom: 28,
      [theme.breakpoints.down("sm")]: {
        marginBottom: 12
      }
    }
  }));

  const classes = useStyles();

  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [password, setPassword] = useState(null);
  const [newPassword, setNewPassword] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [ChangePasswordMutation] = useMutation(ChangePassword);

  const changePassword = () => {
    setSubmit(true);

    if (!password || !newPassword || !confirmPassword) return null;

    if (newPassword !== confirmPassword) {
      return showError(
        t("Your new passwords do not match, please check and try again")
      );
    }

    ChangePasswordMutation({
      variables: {
        input: {
          oldPassword: password,
          newPassword: newPassword,
          id: userData.RawUserData._id
        }
      }
    })
      .then(() => {
        showSuccess(t("Successfully changed"));
        cancel(true);
      })
      .catch(err =>
        showError(err.graphQLErrors[0].message.replace(/\[|\]/g, ""))
      );
  };

  const userName = userData.RawUserData.email;

  return (
    <FormControl fullWidth className={classes.container}>
      <Hidden mdUp>
        <div className={classes.headerContainer}>
          <Close onClick={() => cancel()} size="small" />
          <Typography className={classes.title}>
            {t("Login information")}
          </Typography>
        </div>
      </Hidden>

      <Typography className={classes.titleInside}>{t("User name")}</Typography>
      <TextField
        id="userEmail"
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={classes.adornment}>
              <AccountCircle className={classes.disabledIcon} />
            </InputAdornment>
          )
        }}
        disabled={true}
        value={userName}
        className={classes.textFieldBottomSpacing}
      />
      <Typography className={classes.titleInside}>
        {t("Change Password")}
      </Typography>
      <TextField
        id="oldPassword"
        label={t("Old password")}
        variant="outlined"
        type={showPassword ? "text" : "password"}
        onChange={event => setPassword(event.target.value)}
        error={!password && submit}
        helperText={!password && submit && t("This field is compulsory")}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={() => setShowPassword(!showPassword)}
              className={classes.adornment}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </InputAdornment>
          )
        }}
      />
      <TextField
        id="newPassword"
        label={t("New password")}
        variant="outlined"
        type={showNewPassword ? "text" : "password"}
        onChange={event => setNewPassword(event.target.value)}
        error={!newPassword && submit}
        helperText={!newPassword && submit && t("This field is compulsory")}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={() => setShowNewPassword(!showNewPassword)}
              className={classes.adornment}
            >
              {showNewPassword ? <Visibility /> : <VisibilityOff />}
            </InputAdornment>
          )
        }}
      />
      <TextField
        id="confirmNewPassword"
        label={t("Account_ConfirmNewPW")}
        variant="outlined"
        type={showConfirmPassword ? "text" : "password"}
        onChange={event => setConfirmPassword(event.target.value)}
        error={!confirmPassword && submit}
        helperText={!confirmPassword && submit && t("This field is compulsory")}
        InputProps={{
          endAdornment: (
            <InputAdornment
              position="end"
              onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              className={classes.adornment}
            >
              {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
            </InputAdornment>
          )
        }}
      />
      <div className={classes.btnContainer}>
        <Button variant="outlined" color="primary" onClick={() => cancel()}>
          {t("Cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={changePassword}>
          {t("Save")}
        </Button>
      </div>
    </FormControl>
  );
};

const CredentialsEditing = ({ cancel, userData }) => {
  const useStyles = makeStyles(theme => ({
    mobileContainer: {
      minWidth: "100%",
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
      position: "absolute",
      maxHeight: "100%",
      overflowY: "auto",
      zIndex: 100,
      background: "#FFFFFF",
      padding: "0 16px 30px"
    }
  }));

  const classes = useStyles();

  return (
    <>
      <Hidden smDown>
        <Form cancel={cancel} userData={userData} />
      </Hidden>
      <Hidden mdUp>
        <div className={classes.mobileContainer}>
          <Form cancel={cancel} userData={userData} />
        </div>
      </Hidden>
    </>
  );
};

export default CredentialsEditing;
