import gql from "graphql-tag";

export default gql`
  mutation addTaxInformationNumber(
    $token: String!
    $company: String!
    $data: VATDATA!
  ) {
    addTaxInformationNumber(token: $token, company: $company, data: $data) {
      message
    }
  }
`;
