import gql from "graphql-tag";

export default gql`
  query GetChildCompanys($token: String!, $companyId: String!) {
    GetChildCompanys(token: $token, companyId: $companyId) {
      _id
      name
      internalName
    }
  }
`;
