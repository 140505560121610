import PubSub from "pubsub-js";

//Possible topics
export const PS_TUTORIAL_STEP = "TUTORIAL_STEP";
export const PS_TUTORIAL_LOADED = "TUTORIAL_LOADED";

export const goToTutorialStep = step => PubSub.publish(PS_TUTORIAL_STEP, step);
export const subscribeToTutorial = func =>
  PubSub.subscribe(PS_TUTORIAL_STEP, func);

export const loadTutorial = step => PubSub.publish(PS_TUTORIAL_LOADED, true);
export const finishTutorial = step => PubSub.publish(PS_TUTORIAL_LOADED, false);

export const subscribeToTutorialLoaded = func =>
  PubSub.subscribe(PS_TUTORIAL_LOADED, func);

export default PubSub;
