import _ from "lodash";
import hasInjectionAttack from "./checkInjectionAttack";

const companyTypesTitle = {
  sellers: "Seller accounts",
  buyers: "Buyer accounts"
};

const isSelectedType = (arr, selectedCompany) =>
  selectedCompany &&
  arr.some(a => a.roles && a.roles.indexOf(selectedCompany.roles[0]) > -1);

const sortByNameAndSelectedCompany = (arr, selectedCompany) =>
  arr
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => (a._id === selectedCompany ? -1 : 0));

export const getFormattedCompanyList = (userCompanies, selectedCompany) => {
  const companyObj = { account: [...userCompanies] };
  const selectedCompanyDetails = userCompanies.find(
    c => c._id === selectedCompany
  );

  if (!companyObj.account.length) {
    return sortByNameAndSelectedCompany(userCompanies, selectedCompany);
  }

  var sorted = Object.keys(companyObj)
    .map(type => {
      const sortedByType = sortByNameAndSelectedCompany(
        companyObj[type],
        selectedCompany
      );
      sortedByType.unshift({
        _id: type,
        isTitle: true,
        name: companyTypesTitle[type]
      });
      return sortedByType;
    })
    .sort((a, b) => (isSelectedType(a, selectedCompanyDetails) ? -1 : 0));

  return [].concat(...sorted);
};

export const convertFormattedCompanyListToObject = companyList => {
  const companyTypes = companyList
    .map((a, i) => a.isTitle && a._id)
    .filter(a => a);

  const companyArrays = companyList.reduce((a, b) => {
    const length = a ? a.length : 0;
    let array = a || [];
    if (b.isTitle) {
      array[length] = [];
    } else {
      array[length - 1] = [...(array[length - 1] || []), b];
    }
    return array;
  }, null);

  return companyTypes.reduce((a, b, i) => {
    a[b] = companyArrays[i];
    return a;
  }, {});
};

const companyHasFlag = (company, canSeeRules = {}) => {
  const fieldsToCheck = Object.keys(canSeeRules);
  if (fieldsToCheck.length) {
    return fieldsToCheck.every(field => {
      if (field in company && Array.isArray(company[field])) {
        return company[field].indexOf(canSeeRules[field]) > -1;
      }
      if (field in company) {
        return company[field] === canSeeRules[field];
      }
      return true;
    });
  }
  return true;
};

export const canSeeItem = (selectedCompany, UserCompanies, canSeeRules) => {
  const company = _.find(
    UserCompanies,
    uCompany => selectedCompany === uCompany._id
  );
  return company && companyHasFlag(company, canSeeRules);
};

export const isSellerAccount = (selectedCompany, UserCompanies) =>
  canSeeItem(selectedCompany, UserCompanies, { roles: "seller" });

export const isBuyerAccount = (selectedCompany, UserCompanies) =>
  canSeeItem(selectedCompany, UserCompanies, { roles: "buyer" });

export const extractIdFromCode = code => code && code.split("-")[0];

export const translateObject = (t, obj, field) =>
  obj.map(item => ({
    ...item,
    [field]: t(item[field])
  }));

export const isAtGroupAdminAccount = (selectedCompany, UserCompanies) => {
  const company = _.find(
    UserCompanies,
    uCompany => selectedCompany === uCompany._id
  );
  return company && company.isGroupAccount;
};

export const bulkCheckInjectionAttack = (data, setInjectionAttack) => {
  const injectionAttack = {};
  Object.keys(data).forEach(key => {
    injectionAttack[key] = hasInjectionAttack(data[key]);
  });
  setInjectionAttack(injectionAttack);
  const atLeastOneInjectionAttack = Object.values(injectionAttack).some(
    value => value
  );
  return atLeastOneInjectionAttack;
};

export const applyFnOrValue = (fnOrValue, ...args) => {
  if (typeof fnOrValue === "function") {
    return fnOrValue(...args);
  }

  return fnOrValue;
};
