import gql from "graphql-tag";

export default gql`
  query CompanyUsersQuery(
    $token: String!
    $company: String!
    $avoidAdminUsers: Boolean
  ) {
    CompanyUsers(
      token: $token
      company: $company
      avoidAdminUsers: $avoidAdminUsers
    ) {
      companyUsersArray {
        name
        email
        avatar
        id
        roles
      }
    }
  }
`;
