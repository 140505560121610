/* eslint import/no-webpack-loader-syntax: off */
// the above is a hack to import svg's as path

import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import NF from "../../components/core/shared/NumberFormat";
import track from "../../modules/analytics";
import Loader from "../../components/core/shared/Loader";
import useStandardLocalization from "../../hooks/useStandardLocalization";
const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    width: "480px",
    minHeight: "223px",
    alignItems: "center",
    background: "#F9F9F9",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      "&:last-child": {
        marginBottom: "20px"
      },
      width: "100%"
    }
  },
  containerPadding: {
    padding: "23px 27px 23px 27px"
  },
  flexWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    borderBottom: "1px solid #E0E0E0",
    width: "100%",
    padding: "20px 20px 16px",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 16px 15px"
    }
  },
  numberWrapper: {
    width: "100%",
    display: "flex",
    flexGrow: "1",
    padding: "20px",
    gap: "120px",
    flexWrap: "no-wrap",
    [theme.breakpoints.down("sm")]: {
      gap: "16px",
      justifyContent: "space-between"
    }
  },
  iconWrapper: {
    display: "inline-block",
    padding: "28px 17px 22px 17px ",
    border: "1px solid rgba(238, 165, 44, 0.38)",
    backgroundColor: "rgba(238, 165, 44, 0.16)",
    borderRadius: "50%"
  },
  icon: {
    fill: "rgba(238, 165, 44, 1)",
    width: "47px",
    height: "29.72px"
  },
  paperTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  strong: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "32px",
    lineHeight: "40px",
    color: "rgba(0, 0, 0, 0.87)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
      lineHeight: "36px"
    }
  },
  number: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  button: {
    width: "100%",
    marginTop: "0",
    paddingTop: "12px",
    paddingBottom: "12px",
    fontSize: "14px"
  },
  buttonWrapper: {
    padding: "0 20px 20px",
    width: "100%"
  }
}));

const Transactions = props => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { parseCurrency } = useStandardLocalization();
  const { type, number, value, loading, isSeller } = props;

  if (loading) {
    return <Loader />;
  }
  // Boolean(isSeller) => true means sales, false means purchases
  let title = "";
  switch (true) {
    case type === "open" && isSeller:
      title = "Open Sales";
      break;
    case type === "open" && !isSeller:
      title = "Open Purchases";
      break;
    case type === "completed" && isSeller:
      title = "Completed Sales";
      break;
    case type === "completed" && !isSeller:
      title = "Completed Purchases";
      break;
  }

  return (
    <div className={classes.paper}>
      <div className={classes.flexWrapper}>
        <Typography variant="h5" className={classes.paperTitle}>
          {t(title)}
        </Typography>
      </div>
      <div className={classes.numberWrapper}>
        <Typography className={classes.number}>
          {t("Number")} <br />
          <strong className={classes.strong}>
            <NF value={number} nodec />
          </strong>
        </Typography>
        <Typography className={classes.number}>
          {t("Total")} <br />
          <strong className={classes.strong}>
            {parseCurrency(value, { minimumFractionDigits: 0 })}
          </strong>
        </Typography>
      </div>
      <div className={classes.buttonWrapper}>
        <Button
          component={Link}
          onClick={() =>
            track("button-clicks", "dashboard", `View all orders - ${type}`)
          }
          to={
            {
              open: isSeller ? "/sales?status=open" : "/orders?status=open",
              completed: isSeller
                ? "/sales?status=complete"
                : "/orders?status=complete"
            }[type]
          }
          variant="outlined"
          color="primary"
          size="large"
          className={classes.button}
        >
          {t("View All >")}
        </Button>
      </div>
    </div>
  );
};

export default Transactions;
