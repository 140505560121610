import gql from "graphql-tag";

const verificationSellerExists = gql`
  mutation registerUser($input: VerificationSellerInput!) {
    verificationSellerExists(input: $input)
  }
`;

const updateVerificationPicture = gql`
  mutation ($file: Upload!, $input: VerificationSellerImageInput!) {
    updateVerificationPicture(file: $file, input: $input) {
      filename
    }
  }
`;

const removeVerificationPicture = gql`
  mutation ($_id: String!) {
    removeVerificationPicture(_id: $_id)
  }
`;

export {
  verificationSellerExists,
  updateVerificationPicture,
  removeVerificationPicture
};
