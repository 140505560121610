import React, { useContext } from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import OpenOffers from "./OpenOffers";
import SubmittedOffers from "./SubmittedOffers";
import FinishOffers from "./FinishOffers";
import UserContext from "../../providers/UserProvider";
import { CheckErrors } from "../../ErrorTracking";
import InfoNavBarContext from "../../providers/InfoNavBarProvider";
import OffersQueryWrapper from "../../components/offers/OffersQueryWrapper";

const OffersRoute = () => {
  const match = useRouteMatch();
  const { user, selectedCompany } = useContext(UserContext);
  return (
    <CheckErrors>
      <OffersQueryWrapper user={user} selectedCompany={selectedCompany}>
        <Switch>
          <Route
            exact
            strict
            path={`${match.path}/:url`}
            component={props => <Redirect to={`${props.location.pathname}/`} />}
          />
          <Route
            path={`${match.path}/open/:tenderId/finish`}
            component={FinishOffers}
          />
          <Route
            path={`${match.path}/open/:tenderId?`}
            component={OpenOffers}
          />
          <Route
            path={`${match.path}/submitted/:tenderId?`}
            component={SubmittedOffers}
          />
          <Route
            path={`${match.path}`}
            component={() => <Redirect to="/offers/open/" />}
          />
        </Switch>
      </OffersQueryWrapper>
    </CheckErrors>
  );
};

export const OfferCounter = ({ type }) => {
  const InfoNavBar = useContext(InfoNavBarContext);

  const counter = getOfferCount(InfoNavBar, type);

  if (counter > 0) return <span>&nbsp;({counter})</span>;

  return null;
};

const getOfferCount = (data, type) => {
  if (!data) return 0;
  switch (type) {
    case "open":
      return data.openOffers;
    case "submitted":
      return data.submittedOffers;
    default:
      return 0;
  }
};

export default OffersRoute;
