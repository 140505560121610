import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CategoryItem from "./CategoryItem";

const useStyles = makeStyles(theme => ({
  activeWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "32px",
    paddingRight: "32px",
    background: "white",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px"
    }
  },
  sectionHeader: {
    margin: "32px 0 8px 0px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "28px",
    color: "rgba(0, 0, 0, 0.87)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      margin: "24px 0 8px 0px",
      letterSpacing: "0.15px"
    }
  },
  sectionHeaderInactive: {
    padding: "32px 0 20px 32px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "24px",
    lineHeight: "28px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
    background: "white",
    borderTop: "1px solid #E0E0E0",
    marginTop: "24px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "20px",
      lineHeight: "24px",
      padding: "24px 0 20px 16px",
      borderTop: "none",
      marginTop: "0"
    }
  },
  sectionSubHeader: {
    marginBottom: "20px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: "0.25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px",
      marginBottom: "8px"
    }
  },
  categoryWrapper: {
    display: "flex",
    flexWrap: "wrap",
    paddingLeft: "32px",
    background: "white",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 32px)",
      marginRight: "16px",
      marginLeft: "16px",
      marginBottom: "120px",
      paddingBottom: "120px",
      paddingLeft: "0px"
    }
  },
  categoryElementsLast: {
    paddingBottom: "37px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "0"
    }
  }
}));

const CategoryElements = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    acceptedCategories,
    notAcceptedCategories,
    products,
    removeCategoryFromAccepted,
    addCategoryToAccepted,
    linkCategoryTo,
    showActiveCategories,
    showInactiveCategories
  } = props;
  return (
    <>
      {showActiveCategories ? (
        <>
          <div className={classes.activeWrapper}>
            <span className={classes.sectionHeader}>
              {t("Active categories")}
            </span>
            <span className={classes.sectionSubHeader}>
              {t(
                "To ensure that we always supply the best price we only include you in the tenders that contain your active categories"
              )}
            </span>
          </div>

          <div className={classes.categoryWrapper}>
            {acceptedCategories.map(c => (
              <CategoryItem
                key={c.id}
                category={c}
                products={products.filter(
                  p => p && p.categoryId.indexOf(c.categoryId) > -1
                )}
                removeCategoryFromAccepted={removeCategoryFromAccepted}
                addCategoryToAccepted={addCategoryToAccepted}
                linkCategoryTo={linkCategoryTo}
              />
            ))}
          </div>
        </>
      ) : null}
      {showInactiveCategories ? (
        <>
          <Typography className={classes.sectionHeaderInactive}>
            {t("Inactive categories")}
          </Typography>
          <div
            className={`${classes.categoryWrapper} ${classes.categoryElementsLast}`}
          >
            {notAcceptedCategories.map(c => (
              <CategoryItem
                key={c.id}
                category={c}
                removeCategoryFromAccepted={removeCategoryFromAccepted}
                addCategoryToAccepted={addCategoryToAccepted}
                linkCategoryTo={linkCategoryTo}
              />
            ))}
          </div>
        </>
      ) : null}
    </>
  );
};

export default CategoryElements;
