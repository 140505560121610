import gql from "graphql-tag";

export default gql`
  mutation removeRolesFromUser(
    $token: String!
    $userId: String!
    $partnerId: String!
  ) {
    removeRolesFromUser(token: $token, userId: $userId, partnerId: $partnerId) {
      message
    }
  }
`;
