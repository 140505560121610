import ajv from "ajv";
import { contracts_schema, transactions_schema } from "./schemas.js";
import { IMPORT_DATA_CONTRACT_TYPE } from "./constants.js";
import moment from "moment";
import { COUNTRY_LIST } from "../../../../../utils/collections/countries.js";
import { DATE_FORMAT_STRING } from "../../../../../utils/Format.js";

export const validateSchema = (type, data) => {
  const schema =
    type === IMPORT_DATA_CONTRACT_TYPE ? contracts_schema : transactions_schema;
  const validator = new ajv({ allErrors: true });

  const valid = validator.validate(schema, data);
  return { valid, errors: validator.errors };
};

export const preAnalysis = (type, data) =>
  type === IMPORT_DATA_CONTRACT_TYPE
    ? preAnalysisContract(data)
    : preAnalysisTransaction(data);

export const preAnalysisContract = row => {
  row["contract_id"] = !isNaN(parseInt(row["contract_id"]))
    ? parseInt(row["contract_id"])
    : row["contract_id"];
  row["metaloop_material_id"] = !isNaN(parseInt(row["metaloop_material_id"]))
    ? parseInt(row["metaloop_material_id"])
    : row["metaloop_material_id"];
  row["total_quantity"] = !isNaN(parseInt(row["total_quantity"]))
    ? parseInt(row["total_quantity"])
    : row["total_quantity"];
  for (const key in row) {
    if (row[key] === "") {
      delete row[key];
    }
  }

  return row;
};

export const preAnalysisTransaction = row => {
  row["order_id"] = !isNaN(parseInt(row["order_id"]))
    ? parseInt(row["order_id"])
    : row["order_id"];
  row["metaloop_material_id"] = !isNaN(parseInt(row["metaloop_material_id"]))
    ? parseInt(row["metaloop_material_id"])
    : row["metaloop_material_id"];
  row["tonnage"] = !isNaN(parseFloat(row["tonnage"]))
    ? parseFloat(row["tonnage"])
    : row["tonnage"];
  row["cost_per_ton_local"] = !isNaN(parseFloat(row["cost_per_ton_local"]))
    ? parseFloat(row["cost_per_ton_local"])
    : row["cost_per_ton_local"];
  row["total_cost_local"] = !isNaN(parseFloat(row["total_cost_local"]))
    ? parseFloat(row["total_cost_local"])
    : row["total_cost_local"];
  row["cost_per_ton_eur"] = !isNaN(parseFloat(row["cost_per_ton_eur"]))
    ? parseFloat(row["cost_per_ton_eur"])
    : row["cost_per_ton_eur"];
  row["total_cost_eur"] = !isNaN(parseFloat(row["total_cost_eur"]))
    ? parseFloat(row["total_cost_eur"])
    : row["total_cost_eur"];
  row["contract_id"] = !isNaN(parseInt(row["contract_id"]))
    ? parseInt(row["contract_id"])
    : row["contract_id"];

  if (!isValidDate(row["date"])) {
    const newDate = moment(row["date"], DATE_FORMAT_STRING);
    if (newDate.isValid()) {
      row["date"] = newDate.format(DATE_FORMAT_STRING);
    }
  }
  for (const key in row) {
    if (row[key] === "") {
      delete row[key];
    }
  }
  return row;
};

export const createErrorsObject = errors => {
  const errorsArray = [];
  const errorsWithData = {};
  errors.forEach(error => {
    const { keyword, params, instancePath } = error;
    const fieldName =
      keyword === "required"
        ? params.missingProperty
        : instancePath.replace("/", "");
    errorsArray.push(fieldName);
    errorsWithData[fieldName] = {
      type: keyword,
      objType: params?.type && params.type[0]
    };
  });
  return { errorsArray, errorsWithData };
};

export const validateUniqueIds = (type, data, unmark = false) => {
  const idType =
    type === IMPORT_DATA_CONTRACT_TYPE ? "contract_id" : "order_id";
  const ids = data.map(row => row[idType]);
  const uniqueIds = [...new Set(ids)];
  if (ids.length === uniqueIds.length) {
    if (unmark) {
      data.forEach(row => {
        if (row.error?.includes(idType)) {
          //We check if the error is related to the duplicate type
          if (row.errorsWithData?.[idType]?.type === "duplicate") {
            delete row.errorsWithData[idType];
            row.error = row.error.filter(error => error !== idType);
          }
        }
      });
    }
    return true;
  } else {
    return false;
  }
};

export const extraValidation = (
  type,
  row,
  wrongMaterials,
  markOnlyMaterials = false
) => {
  //We check if the materialId is in the wrongMaterials array
  validateMaterialRow(row, wrongMaterials);

  if (markOnlyMaterials) return;

  if (type === IMPORT_DATA_CONTRACT_TYPE) return;

  //We check the date is correct
  if (!isValidDate(row["date"])) {
    row.error
      ? !row.error.includes("date") && row.error?.push("date")
      : (row["error"] = ["date"]);
    row.errorsWithData
      ? (row.errorsWithData["date"] = {
          type: "date"
        })
      : (row["errorsWithData"] = {
          date: {
            type: "date"
          }
        });
  }

  //We check the conutry is correct:
  const validCountry = COUNTRY_LIST.some(
    country => country.value === row["country_code"]
  );
  if (!validCountry) {
    row.error
      ? !row.error.includes("country_code") && row.error?.push("country_code")
      : (row["error"] = ["country_code"]);
    row.errorsWithData
      ? (row.errorsWithData["country_code"] = {
          type: "country_code"
        })
      : (row["errorsWithData"] = {
          country_code: {
            type: "country_code"
          }
        });
  }

  return row;
};
export const markDuplicateIds = (type, data) => {
  const idType =
    type === IMPORT_DATA_CONTRACT_TYPE ? "contract_id" : "order_id";
  const ids = data.map(row => row[idType]);
  const duplicateIds = ids.filter(id => ids.filter(i => i === id).length > 1);
  data.forEach(row => {
    if (duplicateIds.includes(row[idType])) {
      row.error ? row.error?.push(idType) : (row["error"] = [idType]);
      row.errorsWithData
        ? (row.errorsWithData[idType] = {
            type: "duplicate"
          })
        : (row["errorsWithData"] = {
            [idType]: {
              type: "duplicate"
            }
          });
    }
  });
  return data;
};

export const isValidDate = dateString => {
  const parsedDate = moment(dateString, DATE_FORMAT_STRING, true);
  return parsedDate.isValid();
};

export const cleanLastRowIfEmpty = data => {
  let clean = true;
  //We loop the data object
  const lastElement = data[data.length - 1];
  for (let key in lastElement) {
    if (
      key !== "error" &&
      key !== "errorsWithData" &&
      lastElement[key] !== "" &&
      lastElement[key] !== undefined &&
      lastElement[key] !== null
    ) {
      clean = false;
      break;
    }
  }
  if (clean) {
    data.pop();
  }
  return;
};

export const getMaterialIds = data => {
  const ids = data.map(row => row.metaloop_material_id);
  return [...new Set(ids)];
};

export const validateMaterials = (data, wrongMaterials) => {
  data.forEach(row => {
    validateMaterialRow(row, wrongMaterials);
  });
  return data;
};

export const validateMaterialRow = (row, wrongMaterials) => {
  if (wrongMaterials?.includes(row["metaloop_material_id"])) {
    row.error
      ? !row.error.includes("metaloop_material_id") &&
        row.error?.push("metaloop_material_id")
      : (row["error"] = ["metaloop_material_id"]);
    row.errorsWithData
      ? (row.errorsWithData["metaloop_material_id"] = {
          type: "wrong_material"
        })
      : (row["errorsWithData"] = {
          metaloop_material_id: {
            type: "wrong_material"
          }
        });
  }
  return row;
};
