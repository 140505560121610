import React from "react";
import GenericCard from "../../../general/GenericCard";
import { useTranslation } from "react-i18next";
import { IconButton } from "@material-ui/core";
import { Edit, HttpsOutlined, AccountCircle } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core";
import CredentialsEditing from "../forms/CredentialsEditing";

export const PasswordAndNameCard = ({ mode, onSetMode, userData }) => {
  const useStyles = makeStyles({
    userCredentialsSectionContainer: {
      maxWidth: "480px",
      width: "100%"
    },
    cardContainer: {
      display: "flex",
      flexDirection: "column"
    },
    cardFirstCardRow: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "10px"
    },
    cardRow: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    icons: {
      color: "#666666"
    },
    paddingBottomContainer: {
      paddingBottom: "20px!important"
    }
  });

  const classes = useStyles();
  const { t } = useTranslation();
  const extraClasses = {
    content: classes.paddingBottomContainer
  };
  if (mode === "EDIT_PASSWORD_AND_NAME") {
    return (
      <CredentialsEditing
        cancel={(isSuccess = false) => onSetMode("DEFAULT", isSuccess)}
        userData={userData}
      />
    );
  }
  if (mode === "DEFAULT") {
    const userName = userData.RawUserData.email;

    return (
      <div className={classes.userCredentialsSectionContainer}>
        <GenericCard
          extraClasses={extraClasses}
          title={t("Login information")}
          menuInHeader={
            <>
              <IconButton
                onClick={event => onSetMode("EDIT_PASSWORD_AND_NAME")}
                aria-controls="menu"
              >
                <Edit />
              </IconButton>
            </>
          }
          content={
            <div className={classes.cardContainer}>
              <div className={classes.cardFirstCardRow}>
                <AccountCircle className={classes.icons} />
                <span>{userName}</span>
              </div>
              <div className={classes.cardRow}>
                <HttpsOutlined className={classes.icons} />
                <span> *****************</span>
              </div>
            </div>
          }
        />
      </div>
    );
  }
  return null;
};
