import { makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";

import AccountingEmails from "./AccountingEmails";
import TaxInformationNumbers from "./TaxInformations";
import useEventTracking from "../../../hooks/useEventTracking";
import BillingContacts from "./BillingContacts";

const PaymentInformationCards = props => {
  const useStyles = makeStyles(theme => ({
    addressContainer: {
      display: "flex",
      gap: 12,
      fontSize: 16,
      lineHeight: "24px",
      fontWeight: 400
    },
    title: {
      fontSize: 28,
      lineHeight: "36px",
      paddingBottom: 4
    },
    subtitle: {
      fontSize: 14,
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.6)",
      paddingBottom: 16
    },
    chipContainer: {
      display: "flex",
      gap: 8,
      paddingBottom: 16
    },
    chip: {
      lineHeight: "18px",
      letterSpacing: 0.16
    },
    contactsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 4,
      fontSize: 16,
      lineHeight: "24px"
    },
    contactsIcon: {
      marginRight: 12
    },
    addContactContainer: {
      margin: "8px 0",
      display: "flex",
      gap: 4,
      alignItems: "center",
      justifyContent: "flex-end",
      maxWidth: 480
    },
    cardContainers: {
      display: "flex",
      flexDirection: "column"
      // gap: 20
    },
    padding: {
      padding: "8px 0"
    },
    billingCardWrap: {
      padding: "4px 0 0"
    },
    billingCardHeader: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "28px",
      lineHeight: "36px",
      color: "rgba(0, 0, 0, 0.87)",
      margin: "0 0 4px"
    },
    billingCardSubtitle: {
      display: "block",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.6)",
      marginBottom: "16px"
    },
    billingCardList: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      textAlign: "left",
      margin: 0,
      "& li": {
        textAlign: "left"
      }
    },
    chipsWraps: {
      marginBottom: "16px",
      "& .MuiChip-sizeSmall": {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "13px",
        lineHeight: "18px",
        letterSpacing: "0.16px",
        color: "rgba(0, 0, 0, 0.87)"
      },
      "& .MuiChip-sizeSmall:first-child": {
        marginRight: "8px"
      }
    },
    billingCardListItemWrap: {
      display: "inline-flex",
      alignItems: "center",
      "& span": {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "400",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.25px",
        color: "rgba(0, 0, 0, 0.87)"
      }
    },
    vatSubtitle: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      display: "block"
    },
    vatList: {
      listStyleType: "none",
      textAlign: "left",
      margin: "4px 0 0"
    },
    vatListItem: {
      textAlign: "left",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      "&:not(:last-child)": {
        marginBottom: "1px"
      }
    },
    vatListItemDefault: {
      fontWeight: "700"
    },
    vatCardListItemWrap: {
      display: "inline-flex",
      alignItems: "center"
    }
  }));

  const classes = useStyles();

  const { data, token, company } = props;

  const [mode, setMode] = React.useState("DEFAULT");

  const { TrackEvent } = useEventTracking();

  useEffect(() => {
    if (mode === "add_tax_info") {
      TrackEvent("Add VAT Number", {
        accountId: company
      });
    }
    if (mode === "edit_tax_info") {
      TrackEvent("Edit tax Details", {
        accountId: company
      });
    }
    if (mode === "edit_accounting_info") {
      TrackEvent("Edit accounting email Details", {
        accountId: company
      });
    }
  }, [mode]);

  const onSetMode = value => {
    if (value === mode) return;
    setMode(value);
  };

  return (
    <>
      <div className={classes.cardContainers}>
        <TaxInformationNumbers
          onSetMode={onSetMode}
          mode={mode}
          token={token}
          company={company}
        />
        {/*     Commented until we replace the accounting emails for accounting contacts    
        <BillingContacts
          onSetMode={onSetMode}
          mode={mode}
          token={token}
          company={company}
        /> */}
        <AccountingEmails
          onSetMode={onSetMode}
          mode={mode}
          token={token}
          company={company}
        />
      </div>
    </>
  );
};

export default PaymentInformationCards;
