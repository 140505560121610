import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import useDocPreview from "../../../../hooks/useDocPreview";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    cursor: "pointer",
    textDecoration: "none",
    "&:not(:last-child)": {
      marginBottom: "4px"
    }
  },
  icon: {
    width: "26px",
    height: "17px",
    background: "#E3E2E5",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "16px",
    letterSpacing: "0.5px",
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.87)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  link: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "#276ef1",
    display: "block",
    flex: "1",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  }
}));

const FileItemNotUploaded = ({ file }) => {
  if (!file) {
    throw new Error("file is missing");
  }

  const { documentName, downloadLink, fileType } = file;

  const classes = useStyles();
  const { setDocument } = useDocPreview();

  const { extension, text, href } = React.useMemo(() => {
    let text = "";
    let extension = "";
    const lastIndex = documentName.lastIndexOf(".");
    if (lastIndex === -1) {
      extension = fileType.substring(fileType.lastIndexOf("/") + 1);
      text = documentName;
    } else {
      text = documentName.substring(0, lastIndex);
      extension = documentName.substring(lastIndex + 1);
    }
    const href = `${file.downloadLink}?filename=${file.documentName}`;

    return {
      text,
      extension: extension || "txt",
      href
    };
  }, [documentName, downloadLink, fileType]);

  const onClick = event => {
    event.preventDefault();
    setDocument(file);
  };

  return (
    <a
      className={classes.root}
      target="_blank"
      onClick={onClick}
      href={href}
      rel="noopener noreferrer"
    >
      <span className={classes.icon}>{extension}</span>
      <span className={classes.link}>{text}</span>
    </a>
  );
};

FileItemNotUploaded.propTypes = {
  file: PropTypes.object.isRequired
};

FileItemNotUploaded.defaultProps = {
  file: {
    documentName: "sample.pdf",
    documentType: "Sample",
    downloadLink: "https://via.placeholder.com/600/92c952",
    fileType: "image/png"
  }
};

export default FileItemNotUploaded;
