import gql from "graphql-tag";

const forgotPassword = gql`
  mutation forgotPassword($input: ForgotPasswordInput!) {
    forgotPassword(input: $input) {
      email
      message
    }
  }
`;

export { forgotPassword };
