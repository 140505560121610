import { makeStyles } from "@material-ui/core";
import { useState, useEffect, useContext, useRef } from "react";

import { useTranslation } from "react-i18next";

import Button from "@material-ui/core/Button";
import TextFieldTable from "../components/TextFieldTable";
import {
  columns_transactions,
  columnHeaders_transactions,
  columns_contracts,
  columnHeaders_contracts,
  IMPORT_DATA_CONTRACT_TYPE
} from "../utils/constants";
import {
  preAnalysis,
  validateSchema,
  createErrorsObject,
  validateUniqueIds,
  markDuplicateIds,
  extraValidation,
  getMaterialIds
} from "../utils/helpers";
import { useLazyQuery } from "react-apollo";
import getNonExistingMaterials from "../../../../../queries/getNonExistingMaterials";
import UserContext from "../../../../../providers/UserProvider";
import Loader from "../../Loader";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    padding: "8px 24px",
    overflowY: "auto",
    "-webkit-overflow-scrolling": "touch",

    "& > .MuiDialogContentText-root": {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "20px"
      },
      paddingBottom: "20px"
    },
    "& > .MuiDialogActions-root": {
      paddingBottom: "20px"
    }
  },
  actionButtons: {
    paddingTop: "16px !important",
    paddingRight: "24px",
    [theme.breakpoints.down("sm")]: {
      background: "white",
      zIndex: "9",
      position: "fixed",
      bottom: "0",
      right: "0",
      left: "0",
      boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.12)",
      paddingTop: "24px!important"
    }
  }
}));

export default ({
  setNextStepAction,
  fileContent,
  setFileContent,
  type,
  title
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [newFileContent, setNewFileContent] = useState([...fileContent]);
  const [getWrongMaterials, { data: wrongMaterialsData, loading, error }] =
    useLazyQuery(getNonExistingMaterials, { fetchPolicy: "network-only" });
  const { user, selectedCompany } = useContext(UserContext);
  const [waitingForMaterialConfirmation, setWaitingForMaterialConfirmation] =
    useState(false);
  const [wrongMaterialsObj, setWrongMaterialsObj] = useState({
    wrongMaterials: null,
    check: false,
    cleaned: false
  });

  //If there is nothing to fix we go to the next step
  useEffect(() => {
    if (areChangesFixed()) {
      setNextStepAction();
    }
  }, []);

  /**
   * The "check" field is done to not be triggered the first time.
   * After we have two logics:
   * (wrongMaterials && cleaned) = We already have the result of the query so we handle the changes
   * (!wrongMaterials && !cleaned) = We still don't have the result of the query so we trigger the query
   */

  useEffect(() => {
    const { wrongMaterials, check, cleaned } = wrongMaterialsObj;
    if (
      check &&
      ((wrongMaterials && cleaned) || (!wrongMaterials && !cleaned))
    ) {
      changesFixed();
    }
  }, [wrongMaterialsObj]);

  useEffect(() => {
    if (wrongMaterialsData && !wrongMaterialsObj.wrongMaterials) {
      setWrongMaterialsObj({
        wrongMaterials: wrongMaterialsData,
        check: true,
        cleaned: true
      });
    }
  }, [wrongMaterialsData]);

  const columns =
    type === IMPORT_DATA_CONTRACT_TYPE
      ? columns_contracts
      : columns_transactions;
  const columnHeaders =
    type === IMPORT_DATA_CONTRACT_TYPE
      ? columnHeaders_contracts
      : columnHeaders_transactions;

  const handleChange = (value, id, field) => {
    setNewFileContent(prevContent => {
      const updatedContent = [...prevContent];
      updatedContent[id] = {
        ...updatedContent[id],
        [field]: value
      };

      preAnalysis(type, updatedContent[id]);

      const { valid, errors } = validateSchema(type, updatedContent[id]);
      if (valid) {
        updatedContent[id].error = [];
        updatedContent[id].errorsWithData = {};
      } else {
        const { errorsArray, errorsWithData } = createErrorsObject(errors);
        updatedContent[id].error = errorsArray;
        updatedContent[id].errorsWithData = errorsWithData;
      }

      if (!validateUniqueIds(type, updatedContent, true)) {
        markDuplicateIds(type, updatedContent);
      }
      extraValidation(type, updatedContent[id]);

      return updatedContent;
    });
  };

  const changesFixed = () => {
    const wrongMaterials = wrongMaterialsObj.wrongMaterials;
    //We need to asyncly validate the material
    const materialIds = getMaterialIds(newFileContent);
    if (!wrongMaterials && !loading) {
      getWrongMaterials({
        variables: {
          token: user.token,
          partnerId: selectedCompany,
          materialIds: materialIds
        }
      });
      setWaitingForMaterialConfirmation(true);
    }

    if (wrongMaterials) {
      if (wrongMaterials.getNonExistingMaterials?.materialList?.length === 0) {
        setFileContent(newFileContent);
        setNextStepAction();
      } else {
        //We still have wrong materials
        setNewFileContent(prevContent => {
          const updatedContent = [...prevContent];
          updatedContent.forEach((row, index) => {
            extraValidation(
              type,
              row,
              wrongMaterials?.getNonExistingMaterials?.materialList,
              true
            );
          });
          return updatedContent;
        });
        setFileContent(newFileContent);
      }
      setWaitingForMaterialConfirmation(false);
    }
  };

  const areChangesFixed = () => {
    for (const record of newFileContent) {
      if (record.error && record.error.length > 0) {
        return false;
      }
    }
    return true;
  };
  useEffect(() => {
    areChangesFixed();
  }, [newFileContent]);

  const isValidNumber = value => {
    const numericRegex = /^[-+]?[0-9]*\.?[0-9]+$/;
    return numericRegex.test(value);
  };

  if (loading || waitingForMaterialConfirmation) {
    return (
      <>
        {title}
        <div className={classes.root}>
          <Loader />
        </div>
      </>
    );
  }

  return (
    <>
      {title}
      <div className={classes.root}>
        <TextFieldTable
          columnHeaders={columnHeaders}
          fileContent={fileContent}
          columns={columns}
          handleChange={handleChange}
          isValidNumber={isValidNumber}
          newFileContent={newFileContent}
        />
      </div>
      <div className={classes.actionButtons}>
        <Button
          color="secondary"
          classes={{ root: classes.buttonRoot }}
          variant="contained"
          onClick={() => {
            setWrongMaterialsObj({
              wrongMaterials: null,
              check: true,
              cleaned: false
            });
          }}
          disabled={!areChangesFixed()}
          style={{ float: "right" }}
        >
          {t("import_dialog_error_fixed")}
        </Button>
      </div>
    </>
  );
};
