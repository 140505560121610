import React, { useState } from "react";
import { makeStyles, Menu, MenuItem, IconButton } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import { useTranslation } from "react-i18next";

const DropdownMenu = props => {
  const useStyles = makeStyles(theme => ({
    menuItem: {
      minWidth: 120,
      padding: "12px 30px 12px 16px",
      lineHeight: "24px",
      letterSpacing: 0.25
    }
  }));

  const classes = useStyles();
  const { t } = useTranslation();

  const { menuItems, overrideColor = null } = props;

  const [anchorEl, setAnchorEl] = useState(false);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        onClick={event => setAnchorEl(event.currentTarget)}
        aria-controls="menu"
      >
        <MoreVert color={"action"} className={overrideColor} />
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {menuItems.map(item => (
          <MenuItem
            onClick={() => {
              item.click();
              handleClose();
            }}
            className={classes.menuItem}
          >
            {t(item.text)}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropdownMenu;
