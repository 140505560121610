import React, { useState } from "react";
import classnames from "classnames";
import ItemImage from "./ItemImage";
import PriceArea from "../PriceArea/PriceArea";
import Switch from "@material-ui/core/Switch";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import Button from "../../core/Button";
import CustomList from "../../../components/general/List";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    maxWidth: 1092,
    backgroundColor: "#F9F9F9",
    position: "relative",
    borderRadius: "4px",
    border: "1px solid rgba(0, 0, 0, 0.12)"
  },
  acceptedButton: {
    pointerEvents: "all",
    display: "flex",
    alignItems: "center",
    marginRight: "24px",
    color: "grey",
    fontSize: "1rem"
  },
  acceptedButtonText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  red: {
    color: "red"
  },
  topItems: {
    display: "flex",
    paddingTop: "24px",
    paddingBottom: "24px",
    justifyContent: "space-between"
  },
  topItemsContainer: {
    display: "flex"
  },
  disabled: {
    opacity: "0.5",
    pointerEvents: "none"
  },
  itemInfo: {
    display: "flex",
    flex: "1",
    justifyContent: "flex-start",
    alignItems: "center",
    padding: "0px 0px 0px 60px"
  },
  productTopSubContainer: {
    display: "flex",
    flexDirection: "row"
  },
  greenButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 16
  },
  productImage: {
    width: "104px",
    height: "104px",
    marginLeft: "24px"
  },
  productName: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  productTopContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "24px",
    marginTop: "6px",
    marginBottom: "6px",
    gap: 20
  },
  selectLabel: {
    background: "#ffffff",
    paddingLeft: "5px",
    paddingRight: "5px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    mixBlendMode: "normal",
    transform: "translate(14px, -6px) !important"
  },
  list: {
    minWidth: "240px"
  },
  select: {
    width: "232px",
    height: "40px"
  }
}));

const ItemPriceCard = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);

  const {
    justPrices,
    productId,
    products,
    onChangeItem,
    onChangeReference,
    onSave,
    onChangeLink,
    onWeightCategoryChange,
    isTutorial,
    onRemoveOrAddProduct
  } = props;

  const toggleEdit = ev => {
    ev.stopPropagation();
    setIsEditing(!isEditing);
  };
  const toggleProduct = (productId, ev) => {
    onRemoveOrAddProduct(productId);
    toggleEdit(ev);
  };

  let product = products.find(pd => pd && pd.id === productId);

  const categoryLinkOptions = [
    { value: "nolink", label: "No link" },
    { value: "lme", label: "LME Index" }
  ];
  const getCategoryLinkObject = categoryLink =>
    categoryLinkOptions.find(o => o.value === categoryLink);

  return (
    <div
      className={classnames([
        classes.container,
        !product.accepted && !justPrices ? classes.disabled : null
      ])}
    >
      <div className={classes.topItems}>
        <div className={classes.topItemsContainer}>
          <ItemImage
            src={product.productImage.src}
            className={classes.productImage}
          />
          <div className={classes.productTopContainer}>
            <span className={classes.productName}>{product.productName}</span>
            <div className={classes.productTopSubContainer}>
              <FormControl variant="outlined" className={classes.list}>
                <CustomList
                  data={categoryLinkOptions}
                  placeholder={t("Category linked to")}
                  onChange={evt => onChangeLink(product.id, evt[0].value)}
                  defaultSelected={[
                    getCategoryLinkObject(
                      (product && product.linkedTo) || "nolink"
                    )
                  ]}
                />
              </FormControl>
              {justPrices ? null : product.isDirty || isTutorial ? (
                <div id="tutorial-activate-1" className={classes.greenButton}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => onSave(product.id)}
                  >
                    {t("save")}
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        {!isTutorial ? (
          <div className={classes.acceptedButton}>
            <span className={classes.acceptedButtonText}>{t("Accepted")}</span>
            <Switch
              defaultChecked={product.accepted}
              color="primary"
              onClick={ev => toggleProduct(product.id, ev)}
            />
          </div>
        ) : null}
      </div>
      <>
        <PriceArea
          isEditing={isEditing}
          isTutorial={isTutorial}
          justPrices={justPrices}
          weightData={product.rules.map(
            (
              { start, discount, price, indexPrice, accepted, margin },
              indx
            ) => ({
              start,
              id: `${product.id}-${indx}`,
              discount,
              price,
              indexPrice: Math.round(indexPrice * 1000),
              accepted,
              margin
            })
          )}
          referencePrice={product.referencePrice}
          onWeightCategoryChange={start =>
            onWeightCategoryChange(product.id, start)
          }
          onChangeItem={(price, start, type) =>
            onChangeItem(product.id, price, start, type)
          }
          onChangeReference={(price, lockedOn) => {
            onChangeReference(product.id, price, lockedOn);
          }}
        />
      </>
    </div>
  );
};

export default ItemPriceCard;
