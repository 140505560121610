import React, { useState } from "react";
import { Grid, makeStyles, Typography, IconButton } from "@material-ui/core";
import {
  AlternateEmail,
  Phone,
  PhoneAndroid,
  Room,
  Create,
  Star
} from "@material-ui/icons";
import DropdownMenu from "./DropdownMenu";
import { useTranslation } from "react-i18next";
import addNewAccount from "../../../assets/icons/addNewAccount.png";
import { useMutation } from "@apollo/react-hooks";
import RemoveContact from "../../../mutations/removeContact";
import AccountContacts from "../../../queries/AccountContacts";
import { showError, showSuccess } from "../../core/shared/Notify";
import GenericCard from "../../general/GenericCard";
import useEventTracking from "../../../hooks/useEventTracking";
import DeleteDialog from "../../core/shared/DeleteDialog";
import {
  roleOptions,
  SPECIAL_CONTACT_POSITIONS
} from "../../../utils/constants";

const useStyles = makeStyles(theme => ({
  addressContainer: {
    display: "flex",
    fontSize: 16,
    lineHeight: "24px",
    fontWeight: 400,
    alignItems: "center",
    marginTop: 4
  },
  contentContainer: {
    padding: "4px 0"
  },
  title: {
    fontSize: 28,
    lineHeight: "36px",
    paddingBottom: 4,
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      lineHeight: "28px"
    }
  },
  subtitle: {
    fontSize: 14,
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.6)",
    paddingBottom: 16
  },
  chipContainer: {
    display: "flex",
    gap: 8,
    paddingBottom: 16
  },
  chip: {
    lineHeight: "18px",
    letterSpacing: 0.16
  },
  contactsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 4,
    fontSize: 16,
    lineHeight: "24px"
  },
  contactsIcon: {
    marginRight: 12
  },
  addContactContainer: {
    paddingTop: 4,
    display: "flex",
    gap: 4,
    alignItems: "center",
    justifyContent: "flex-end",
    maxWidth: 480
  },
  cardContainers: {
    display: "flex",
    flexDirection: "column",
    gap: 20
  },
  padding: {
    padding: "8px 0"
  },
  menuContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  whiteIcons: {
    color: "white"
  }
}));

const CompanyInformationCards = props => {
  const { TrackEvent } = useEventTracking();
  const classes = useStyles();
  const { t } = useTranslation();
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingContactId, setDeletingContactId] = useState(null);

  const {
    data,
    contacts,
    setCompanyEditing,
    setContactAddingOrEditing,
    setCurrentContact,
    token,
    company,
    handleSetMainContact
  } = props;

  const mainContact = contacts.find(item => item.default);

  const [removeContactMutation] = useMutation(RemoveContact, {
    refetchQueries: () => [
      {
        query: AccountContacts,
        variables: {
          token: token,
          company: company
        }
      }
    ]
  });

  const handleDelete = () => {
    removeContact(deletingContactId);
    setIsDeleting(false);
  };

  const removeContact = item => {
    removeContactMutation({
      variables: {
        token: token,
        company: company,
        id: item._id
      }
    })
      .then(() => {
        showSuccess(t("Successfully deleted"));
        TrackEvent("Contact Deleted", {
          accountId: company,
          contact: item
        });
      })
      .catch(err => showError(err.message));
  };

  const positionObj = roleOptions.find(
    item => item.value === mainContact?.position
  );
  const isPredefinedPosition = positionObj !== undefined;

  return (
    <>
      <div className={classes.cardContainers}>
        <GenericCard
          title={t("Company Address")}
          menuInHeader={
            <IconButton onClick={setCompanyEditing}>
              <Create color="action" />
            </IconButton>
          }
          content={
            <Grid
              container
              direction="column"
              className={classes.contentContainer}
            >
              <Typography className={classes.title}>{data.name}</Typography>
              <Typography className={classes.subtitle}>
                {data.branch}
              </Typography>
              <div className={classes.addressContainer}>
                <Room
                  color="action"
                  fontSize="small"
                  className={classes.contactsIcon}
                />
                {data.address.street && data.address.street + ", "}
                {data.address.city && data.address.city + ", "}
                {data.address.country}
                {data.address.country && data.address.zip && ", "}
                {data.address.zip}
              </div>
            </Grid>
          }
        />
        {mainContact && (
          <GenericCard
            title={<div className={classes.padding}>{t("Main Contact")}</div>}
            darkHeader={true}
            menuInHeader={
              <div className={classes.menuContainer}>
                <Star className={classes.whiteIcons} />
                <DropdownMenu
                  overrideColor={classes.whiteIcons}
                  menuItems={[
                    {
                      text: "Edit",
                      click: () => {
                        setContactAddingOrEditing("edit");
                        setCurrentContact(mainContact);
                      }
                    }
                  ]}
                />
              </div>
            }
            content={
              <Grid
                container
                direction="column"
                className={classes.contentContainer}
              >
                {mainContact.name && (
                  <Typography className={classes.title}>
                    {mainContact.name}
                  </Typography>
                )}
                <Typography className={classes.subtitle}>
                  {mainContact.position
                    ? isPredefinedPosition
                      ? t(positionObj?.name)
                      : mainContact.position
                    : t("CEO")}
                </Typography>
                <div className={classes.contactsContainer}>
                  {mainContact.email && (
                    <Grid container alignItems="center">
                      <AlternateEmail
                        fontSize="small"
                        className={classes.contactsIcon}
                        color="action"
                      />
                      {mainContact.email}
                    </Grid>
                  )}
                  {mainContact.phone && (
                    <Grid container alignItems="center">
                      <Phone
                        fontSize="small"
                        className={classes.contactsIcon}
                        color="action"
                      />
                      {mainContact.phone}
                    </Grid>
                  )}
                  {mainContact.mobile && (
                    <Grid container alignItems="center">
                      <PhoneAndroid
                        fontSize="small"
                        className={classes.contactsIcon}
                        color="action"
                      />
                      {mainContact.mobile}
                    </Grid>
                  )}
                </div>
              </Grid>
            }
          />
        )}
        <div className={classes.addContactContainer}>
          {t("Add Contact")}
          <IconButton
            onClick={() => {
              setContactAddingOrEditing("add");
              setCurrentContact();
            }}
          >
            <img src={addNewAccount} alt="AddNewAccount" />
          </IconButton>
        </div>
        {contacts
          .filter(item => !item.default)
          .map(item => (
            <GenericCard
              title={<div />}
              menuInHeader={
                <DropdownMenu
                  menuItems={[
                    {
                      text: "dropdown_SetAsMainContact",
                      click: () => {
                        handleSetMainContact(item);
                      }
                    },
                    {
                      text: "Edit",
                      click: () => {
                        setContactAddingOrEditing("edit");
                        setCurrentContact(item);
                      }
                    },
                    {
                      text: "Delete",
                      click: () => {
                        setDeletingContactId(item);
                        setIsDeleting(true);
                      }
                    }
                  ]}
                />
              }
              content={
                <Grid
                  container
                  direction="column"
                  className={classes.contentContainer}
                >
                  {item.name && (
                    <Typography className={classes.title}>
                      {item.name}
                    </Typography>
                  )}
                  {item.position && (
                    <Typography className={classes.subtitle}>
                      {SPECIAL_CONTACT_POSITIONS[item.position]
                        ? t(SPECIAL_CONTACT_POSITIONS[item.position])
                        : item.position}
                    </Typography>
                  )}
                  <div className={classes.contactsContainer}>
                    {item.email && (
                      <Grid container alignItems="center">
                        <AlternateEmail
                          fontSize="small"
                          className={classes.contactsIcon}
                          color="action"
                        />
                        {item.email}
                      </Grid>
                    )}
                    {item.phone && (
                      <Grid container alignItems="center">
                        <Phone
                          fontSize="small"
                          className={classes.contactsIcon}
                          color="action"
                        />
                        {item.phone}
                      </Grid>
                    )}
                    {item.mobile && (
                      <Grid container alignItems="center">
                        <PhoneAndroid
                          fontSize="small"
                          className={classes.contactsIcon}
                          color="action"
                        />
                        {item.mobile}
                      </Grid>
                    )}
                  </div>
                </Grid>
              }
            />
          ))}
      </div>
      <DeleteDialog
        title={"Delete_Contact_question"}
        text={"Delete_Contact_text"}
        delete_button_text={"Delete_Contact_button"}
        onDelete={handleDelete}
        isDeleting={isDeleting}
        closeModals={() => setIsDeleting(false)}
      />
    </>
  );
};

export default CompanyInformationCards;
