import React from "react";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { pricePerKg, price } from "./TransactionDesktopCart";

const TransactionMobileCart = props => {
  const useStyles = makeStyles({
    tableContainer: {
      boxShadow: "none",
      borderRadius: "4px",
      marginBottom: "24px",
      whiteSpace: "nowrap"
    },
    cartTable: {
      borderRadius: "4px",
      boxShadow: "none"
    },
    tableHeader: {
      "& .MuiTableCell-root": {
        textAlign: "right"
      }
    },
    tableBody: {
      "& .MuiTableCell-root": {
        padding: "14px 16px",
        textAlign: "right"
      }
    },
    alignLeft: {
      textAlign: "left !important"
    }
  });

  const classes = useStyles();
  const { t } = useTranslation();
  const { cart, isSeller } = props;

  const getRow = product => {
    if (isSeller && product.pricePurchase === 0) {
      return null;
    } else if (!isSeller && product.priceAmount === 0) {
      return null;
    }

    return (
      <TableRow>
        <TableCell className={classes.alignLeft}>{product.material}</TableCell>
        <TableCell>{product.amount}</TableCell>
        {isSeller && <TableCell>{product.invoicedAmount}</TableCell>}
        {isSeller ? (
          <TableCell>{pricePerKg(product.pricePurchase)}</TableCell>
        ) : (
          <TableCell>{pricePerKg(product.priceAmount)}</TableCell>
        )}
        <TableCell>
          {isSeller
            ? price(
                product.pricePurchase &&
                  Math.round(product.pricePurchase * product.amount * 100) / 100
              )
            : price(
                product.priceAmount &&
                  Math.round(product.priceAmount * product.amount * 100) / 100
              )}
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.cartTable}>
        <TableHead className={classes.tableHeader}>
          <TableRow>
            <TableCell className={classes.alignLeft}>{t("Material")}</TableCell>
            <TableCell>
              {isSeller ? t("Declared Quantity (kg)") : t("Quantity (kg)")}
            </TableCell>
            {isSeller && <TableCell>{t("Invoiced Quantity (kg)")}</TableCell>}
            {isSeller ? (
              <TableCell>{t("Price / kg")}</TableCell>
            ) : (
              <TableCell>{t("Purchase price / kg")}</TableCell>
            )}
            <TableCell>{t("Total Value")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {cart.map((product, index) => getRow(product))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionMobileCart;
