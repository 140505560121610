import { useTranslation } from "react-i18next";
import { parse, isValid } from "date-fns";

const ENGLISH_LANGUAGE_CODE = "en";
const GERMAN_LANGUAGE_CODE = "de";

const useStandardLocalization = () => {
  const { i18n } = useTranslation();
  const { format } = i18n;

  const languageCode =
    i18n.language === ENGLISH_LANGUAGE_CODE
      ? ENGLISH_LANGUAGE_CODE
      : GERMAN_LANGUAGE_CODE;

  // ---------------
  // in parseCurrency we won't use format of Currency but format of number, because in English it makes EUR before the number, and in German it makes it after the number.
  const parseCurrency = (value, options = {}) =>
    `${format(value, "number", languageCode, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...options
    })} EUR`;

  // ---------------
  // here used en-GB only to make DD/MM/YYYY format, as requested in PDHD
  const parseDate = (value, options = {}) => {
    if (!value) return "-";

    let date = new Date(value);

    if (!isValid(date)) {
      date = parse(value, "dd-MM-yyyy", new Date());
    }
    // this second check only to make sure it was parsed correctly,
    if (!isValid(date)) {
      return "-";
    }
    return format(date, "dateTime", "en-GB", options);
  };

  // ----------------
  const parseNumber = (value, options = {}) => {
    if (isNaN(value)) return value;

    const parsedNumber = format(value, "number", languageCode, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
      ...options
    });
    return parsedNumber;
  };

  return {
    parseCurrency,
    parseDate,
    parseNumber
  };
};

export default useStandardLocalization;
