import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import LoginLogo from "../../components/login/LoginLogo";

import Title from "../../components/login/Title";
import OrSeparator from "../../components/login/OrSeparator";
import LongMagicLink from "../../components/login/button/LongMagicLink";
import LoginContainer from "../../containers/login/Login";

const styles = {
  container: {
    width: "100vw",
    height: "100vh"
  },
  fullHeight: {
    margin: 0,
    height: "100vh",
    overflow: "hidden"
  },
  sectionWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "200px 0 50px",
    // justifyContent: "center",
    height: "100vh",

    "@media (max-width: 400px)": {
      padding: "48px 0 24px"
    }
  },
  title: {
    margin: "20px 0 24px",
    fontSize: "48px",
    lineHeight: "56px",
    "@media (max-width: 400px)": {
      margin: "16px 0 20px",
      fontSize: "28px",
      lineHeight: "36px"
    }
  },
  formWrapper: {
    marginTop: "0",
    width: "400px",

    "@media (max-width: 400px)": {
      width: "100%",
      padding: "0 16px"
    }
  },
  noPadding: {
    padding: "0 !important"
  }
};

const LoginPage = ({ history, classes }) => (
  <Grid container className={classes.fullHeight}>
    <Grid item xs={12} md={12} lg={12}>
      <Grid item className={classes.sectionWrapper}>
        <LoginLogo />

        <Title text={"Log in to Metaloop"} extraClasses={classes.title} />
        <LongMagicLink />
        <OrSeparator />
        <Grid item className={classes.formWrapper}>
          <LoginContainer history={history} />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
);

LoginPage.defaultProps = {
  classes: {},
  history: {},
  location: {}
};

LoginPage.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object
};

export default withStyles(styles)(LoginPage);
