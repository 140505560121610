import gql from "graphql-tag";

export default gql`
  query OnboardingEmail($token: String!) {
    OnboardingInformationQuery(token: $token) {
      email
      onboardingStart
      language
    }
  }
`;
