import gql from "graphql-tag";

export const GET_ZONES = gql`
  query getZones($token: String!, $partnerId: String!) {
    getZones(token: $token, partnerId: $partnerId) {
      _id
      partnerId
      zoneName
      zipCodesText
      dayOfTheWeek
      interval
      startingDate
    }
  }
`;

export const PUT_ZONES = gql`
  mutation putZones($token: String!, $partnerId: String!, $zone: Object!) {
    putZones(token: $token, partnerId: $partnerId, zone: $zone) {
      message
    }
  }
`;

export const EDIT_ZONES = gql`
  mutation editZones(
    $token: String!
    $id: String!
    $zoneName: String
    $dayOfTheWeek: [Int]
    $interval: Int
    $zipCodes: String
  ) {
    editZones(
      token: $token
      id: $id
      zoneName: $zoneName
      dayOfTheWeek: $dayOfTheWeek
      interval: $interval
      zipCodes: $zipCodes
    ) {
      message
    }
  }
`;

export const REMOVE_ZONES = gql`
  mutation removeZones($token: String!, $id: String!) {
    removeZones(token: $token, id: $id) {
      message
    }
  }
`;
