/* eslint-disable indent */
import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import {
  Card,
  CardContent,
  Collapse,
  makeStyles,
  Button,
  Hidden,
  IconButton
} from "@material-ui/core";
import { ReactComponent as TruckIcon } from "../../assets/truckIcon.svg";
import classnames from "classnames";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";

import Status from "../general/Status";
import PickupsCardDocument from "../pickups/PickupsCard/PickupsCardDocument";
import UserContext from "../../providers/UserProvider";
import Skeleton from "../core/shared/Skeleton";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import EmptyModalPickupDisplay from "./EmptyModalPickupDisplay";
import { DATE_FORMAT_STRING } from "../../utils/Format";

const useStyles = makeStyles(theme => ({
  root: {},
  muiPaperRoot: {
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    position: "relative",
    "&:not(:last-of-type)": {
      marginBottom: "20px"
    }
  },
  MuiCardContentRoot: {
    position: "relative",
    padding: "20px"
  },
  newItem: {
    background: "#EAFEF3"
  },
  defaultBackground: {
    background: "#F9F9F9"
  },
  figureWrap: {
    position: "relative",
    marginBottom: "8px"
  },
  imgWrap: {
    margin: "0",
    // height: "100px",
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover"
      // maxWidth: 450
    }
  },
  pickUpBadge: {
    padding: "1px 4px 0 4px",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
    position: "absolute",
    top: "12px",
    left: "-20px"
  },
  tagLine: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "4px"
  },
  flexItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "4px"
  },
  pickUpDateFormatted: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px"
  },
  pickupdate_black: {
    color: "rgba(0, 0, 0, 1)"
  },
  pickupdate_grey: {
    color: "rgba(0, 0, 0, 0.38)"
  },
  tagLineHeaderWrap: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px"
  },
  tagLineHeader: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0 0 12px"
  },
  pickupBadgeWrap: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    paddingLeft: "4px"
  },
  indicatorBadge: {
    width: "8px",
    height: "8px",
    borderRadius: "100%"
  },
  card_orange: {
    backgroundColor: "#FFC043"
  },
  card_green: {
    backgroundColor: theme.palette.secondary.main
  },
  card_red: {
    backgroundColor: "#E11900"
  },
  pickupIndicator: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: 0
  },
  panels: {},
  panelTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0"
  },
  panelDescription: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0"
  },
  panelDescription__mb: {
    marginBottom: "8px"
  },
  mobileButtoContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
    marginRight: "10px"
  },
  ctaWrap: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px"
  },
  // grid
  row: {
    display: "flex",
    flexWrap: "wrap"
  },
  gridRoot: {
    margin: "0 -8px"
  },
  figureCol: {
    flexBasis: "100%",
    width: "100%",
    padding: "0 8px"
  },
  detailCol: {
    flexBasis: "100%",
    width: "100%",
    padding: "0 8px"
  },
  fullBasis: {
    flexBasis: "100%",
    width: "100%"
  },
  oneSixEightCent: {
    flexBasis: "100%",
    width: "100%"
  },
  tagLineHeadercol: {
    flexBasis: "100%",
    width: "100%"
  },
  panelCol: {
    flexBasis: "50%",
    width: "50%"
  },
  panelCol2: {
    flexBasis: "100%",
    width: "100%"
  },
  panelWrapCol: {
    flexBasis: "50%",
    width: "50%"
  },
  threeSixEightCent: {
    flexBasis: "100%",
    width: "100%"
  },
  detailGridRoot: {
    margin: "0 -10px"
  },
  detailColRoot: {
    padding: "0 10px"
  },
  disabled: {
    pointerEvents: "none",
    opacity: "0.5",
    filter: "grayscale(100%)"
  },
  [theme.breakpoints.down("md")]: {
    tagLine: {
      marginRight: "8px",
      marginTop: "8px",
      marginBottom: "8px"
    }
  },
  [theme.breakpoints.up("md")]: {
    figureWrap: {
      marginBottom: "0",
      maxHeight: "220px"
    },
    tagLineHeaderWrap: {
      flexDirection: "row",
      gap: "20px"
    },
    panelDescription__mb: {
      marginBottom: "16px"
    },
    tagLineHeader: {
      marginBottom: "0"
    },
    imgWrap: {
      // height: "100px",
      overflow: "hidden"
    },
    ctaWrap: {
      marginTop: "0",
      width: "100%"
    },
    figureCol: {
      flexBasis: "116px",
      width: "116px"
    },
    detailCol: {
      flexBasis: "calc(100% - 116px)",
      width: "calc(100% - 116px)",
      paddingTop: "12px"
    },
    oneSixEightCent: {
      flexBasis: "188px",
      width: "188px"
    },
    tagLineHeadercol: {
      flexBasis: "calc(100% - 188px)",
      width: "calc(100% - 188px)"
    },
    panelCol: {
      flexBasis: "188px",
      width: "188px"
    },
    panelCol2: {
      flexBasis: "188px",
      width: "188px"
    },
    panelWrapCol: {
      flexBasis: "calc(100% - 188px)",
      width: "calc(100% - 188px)"
    },
    threeSixEightCent: {
      flexBasis: "calc(100% - 564px)",
      width: "calc(100% - 564px)"
    },
    statusWrap: {
      marginTop: "12px"
    },
    statusTitle: {
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px",
      color: "rgba(0, 0, 0, 0.6)",
      margin: "0 0 4px"
    },
    statusClassName: {
      "& .status_root:first-child": {
        paddingLeft: "0"
      },
      "& .status_root:last-child": {
        paddingRight: "0"
      }
    },
    MuiCardContentRoot: {
      "&:last-child": {
        paddingBottom: 20
      }
    },
    muiPaperRootExtra: {
      paddingBottom: "10px"
    },
    muiPaperRootExtraExpanded: {
      paddingBottom: "0"
    },
    expander: {
      position: "absolute",
      bottom: "0",
      right: "0",
      padding: "0 10px 0 0"
    },
    expanderCollapsed: {
      bottom: "24px"
    },
    panelDescEllipsis: {
      height: "40px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginBottom: 0
    },
    tagLineButton: {
      position: "absolute",
      top: "16px",
      right: "16px"
    }
  }
}));

const EmptyPickUp = ({
  expandedByDefault = false,
  loading = false,
  data = null
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { user, selectedCompany } = useContext(UserContext);
  const theme = useTheme();
  // ismobile states for mobile device
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // upload modal states
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const [isExpanded, setExpanded] = useState(expandedByDefault);
  const [imageHeight, setImageHeight] = useState(
    isMobile ? "100px" : isExpanded ? height : "112px"
  );
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const documents = [
    {
      id: "",
      documentType: "Pickup Note",
      downloadLink: "",
      documentName: t("emptyState_fileName"),
      uploadedByMetaloop: true,
      fileType: "image/png",
      __typename: "Document"
    },
    {
      id: "",
      documentType: "Pickup Note",
      downloadLink: "",
      documentName: t("emptyState_fileName"),
      uploadedByMetaloop: false,
      fileType: "image/png",
      __typename: "Document"
    }
  ];

  // Determine image size based on screen size
  const imageResizeWdith = isSmallScreen ? 450 : 100;
  const imageResizeHeigh = isSmallScreen ? 100 : 148;

  const imageUrl = `https://images.scrap24.com/-${imageResizeWdith}x${imageResizeHeigh}-2v3Rm2xiAycWyiLqj.png}`;

  const [modalIsVisible, setModalIsVisible] = useState(false);

  const animation = loading;

  const cardConditions = useMemo(
    () => ({
      cardStates: t("confirmation pending"),
      cardStatesColor: "card_orange",
      iconDateColor: "pickupdate_grey",
      showReschedulteBtn: true,
      showConfirmBtn: false,
      showDocumentBtn: false,
      canReschedulePickup: true,
      canConfirmPickUp: false
    }),
    []
  );

  const statusElements = [
    {
      title: "1." + t("Pickup"),
      position: 1,
      text: `${t("Status_PickupConfirmationRequested")} `
    },
    {
      title: "2." + t("Status_Transport"),
      position: 2,
      text: t("Pickups_Organisation")
    },
    { title: "3." + t("Status_Transport"), position: 3, text: t("Documents") },
    { title: "4." + t("Status_Transport"), position: 4, text: t("In transit") },
    {
      title: "5." + t("Status_Transport"),
      position: 5,
      text: t("Sales_Delivered")
    }
  ];

  // always stay open for mobile
  useEffect(() => {
    if (isMobile) {
      setExpanded(true);
      setImageHeight("100px");
    }
  }, [isMobile]);

  //The image will be as height as the card
  useEffect(() => {
    if (isMobile) return;
    if (isExpanded) {
      const currentHeight =
        ref.current && ref.current.clientHeight
          ? ref.current.clientHeight
          : 500;
      setHeight(currentHeight);
      setImageHeight(currentHeight);
    }
  }, [isMobile, isExpanded]);

  const disableButton = true;

  const date = data?.date || DATE_FORMAT_STRING;
  const materialName = data?.materialName || t("col_head_mat_name");
  const state = data?.state || t("confirmation pending");
  const cardColor = data?.cardColor || "card_orange";

  return (
    <Card
      className={classnames(
        classes.muiPaperRoot,
        classes.defaultBackground,
        isExpanded
          ? classes.muiPaperRootExtraExpanded
          : classes.muiPaperRootExtra
      )}
      variant="outlined"
    >
      <Collapse in={isExpanded} collapsedSize={170}>
        <CardContent className={classnames(classes.MuiCardContentRoot)}>
          <div className={classnames(classes.row, classes.gridRoot)}>
            {/* pickup card image wrapper */}
            <div className={classes.figureCol}>
              <div className={classes.figureWrap}>
                {loading ? (
                  <Skeleton
                    width={isMobile ? "288px" : "100px"}
                    height={isMobile ? "100px" : "112px"}
                    animation={animation}
                  />
                ) : (
                  <figure
                    className={classes.imgWrap}
                    style={{
                      height: imageHeight
                    }}
                  >
                    <img
                      src={imageUrl}
                      width="100%"
                      height="100%"
                      alt="metal"
                    />
                  </figure>
                )}
              </div>
            </div>
            <div className={classes.detailCol} ref={ref}>
              <div className={classnames(classes.row, classes.detailGridRoot)}>
                <div
                  className={classnames(
                    classes.oneSixEightCent,
                    classes.detailColRoot
                  )}
                >
                  <div className={classes.tagLine}>
                    <Skeleton
                      animation={animation}
                      active={loading}
                      width={isMobile ? "155px" : "168px"}
                      height={"24px"}
                    >
                      <div
                        className={classnames(
                          classes.flexItem,
                          classes.pickUpDateFormatted,
                          classes["pickupdate_grey"]
                        )}
                      >
                        <TruckIcon />
                        {date}{" "}
                      </div>
                    </Skeleton>
                    <div
                      className={classes.tagLineButton}
                      style={isMobile ? {} : {}}
                    >
                      <MoreVertIcon />
                    </div>
                  </div>
                </div>
                <div
                  className={classnames(
                    classes.tagLineHeadercol,
                    classes.detailColRoot
                  )}
                >
                  <div className={classes.tagLineHeaderWrap}>
                    <h3
                      className={classes.tagLineHeader}
                      style={{ color: "rgba(0, 0, 0, 0.38)" }}
                    >
                      <Skeleton animation={animation} active={loading}>
                        {materialName}{" "}
                      </Skeleton>
                    </h3>
                    <Skeleton
                      animation={animation}
                      active={loading}
                      width={"145px"}
                      height={animation ? "20px" : "24px"}
                      extraStyle={{ display: "flex", alignItems: "center" }}
                    >
                      <div className={classes.pickupBadgeWrap}>
                        <span
                          className={classnames(
                            classes.indicatorBadge,
                            classes[cardColor]
                          )}
                        ></span>
                        <p className={classes.pickupIndicator}>{state}</p>
                      </div>
                    </Skeleton>
                  </div>
                </div>
                <div
                  className={classnames(
                    classes.panelCol,
                    classes.detailColRoot
                  )}
                >
                  <div className={classes.panels}>
                    <p className={classes.panelTitle}>
                      <Skeleton animation={animation} active={loading}>
                        {t("PICKUP ADDRESS")}
                      </Skeleton>
                    </p>
                    <p
                      className={classnames(
                        classes.panelDescription,
                        isExpanded ? "" : classes.panelDescEllipsis
                      )}
                    >
                      <Skeleton
                        width={isMobile ? "136px" : "168px"}
                        height={"14px"}
                        extraStyle={{ marginBottom: "6px", marginTop: "3px" }}
                        animation={animation}
                      />
                      <Skeleton
                        width={"49px"}
                        height={"14px"}
                        extraStyle={{ marginBottom: "6px" }}
                        animation={animation}
                      />
                      <Skeleton
                        width={"100px"}
                        height={"14px"}
                        animation={animation}
                        extraStyle={{ marginBottom: "6px" }}
                      />
                      <Skeleton
                        width={"48px"}
                        height={"14px"}
                        animation={animation}
                        extraStyle={{ marginBottom: "6px" }}
                      />
                    </p>
                  </div>
                </div>
                <div
                  className={classnames(
                    classes.panelWrapCol,
                    classes.detailColRoot
                  )}
                >
                  <div className={classes.row}>
                    <div className={classes.fullBasis}>
                      <div
                        className={classnames(
                          classes.row,
                          classes.detailGridRoot
                        )}
                      >
                        <div
                          className={classnames(
                            classes.panelCol2,
                            classes.detailColRoot
                          )}
                        >
                          <div className={classes.panels}>
                            <p className={classes.panelTitle}>
                              <Skeleton animation={animation} active={loading}>
                                {t("Quantity")} (mt)
                              </Skeleton>
                            </p>
                            <p
                              className={classnames(
                                classes.panelDescription,
                                classes.panelDescription__mb
                              )}
                            >
                              <Skeleton
                                width={"48px"}
                                height={"14px"}
                                extraStyle={{
                                  marginBottom: "6px",
                                  marginTop: "3px"
                                }}
                                animation={animation}
                              />
                            </p>
                          </div>
                        </div>
                        <div
                          className={classnames(
                            classes.panelCol2,
                            classes.detailColRoot
                          )}
                        >
                          <div className={classes.panels}>
                            <p className={classes.panelTitle}>
                              <Skeleton animation={animation} active={loading}>
                                {t("Products_Packaging")}
                              </Skeleton>
                            </p>
                            <p
                              className={classnames(
                                classes.panelDescription,
                                classes.panelDescription__mb
                              )}
                            >
                              <Skeleton
                                width={"96px"}
                                height={"14px"}
                                extraStyle={{
                                  marginBottom: "6px",
                                  marginTop: "3px"
                                }}
                                animation={animation}
                              />
                            </p>
                          </div>
                        </div>
                        <div
                          className={classnames(
                            classes.panelCol2,
                            classes.detailColRoot
                          )}
                        >
                          <div className={classes.panels}>
                            <p className={classes.panelTitle}>
                              <Skeleton animation={animation} active={loading}>
                                {t("Pickups_TransportDetails")}
                              </Skeleton>
                            </p>
                            <p
                              className={classnames(
                                classes.panelDescription,
                                classes.panelDescription__mb,
                                isExpanded ? "" : classes.panelDescEllipsis
                              )}
                            >
                              <Skeleton
                                width={"128px"}
                                animation={animation}
                                height={"14px"}
                                extraStyle={{
                                  marginBottom: "6px",
                                  marginTop: "3px"
                                }}
                              />
                              <Skeleton
                                width={"96px"}
                                animation={animation}
                                height={"14px"}
                                extraStyle={{
                                  marginBottom: "6px"
                                }}
                              />
                            </p>
                          </div>
                        </div>
                        {/* display on desktop only */}
                        <Hidden smDown>
                          <div
                            className={classnames(
                              classes.threeSixEightCent,
                              classes.detailColRoot
                            )}
                          >
                            <div className={classes.ctaWrap}>
                              <Skeleton
                                animation={animation}
                                active={loading}
                                width={"208px"}
                                height={"40px"}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={() => {}}
                                  disabled={true}
                                >
                                  {t("Pickups_ReschedulePickup")}
                                </Button>
                              </Skeleton>
                            </div>
                          </div>
                        </Hidden>
                      </div>
                    </div>
                    {/* display on desktop only */}
                    <Hidden smDown>
                      <>
                        {isExpanded && (
                          <div className={classes.fullBasis}>
                            <div
                              className={classnames(
                                classes.row,
                                classes.detailGridRoot
                              )}
                            >
                              <div
                                className={classnames(
                                  classes.panelCol,
                                  classes.detailColRoot
                                )}
                              >
                                <div className={classes.panels}>
                                  <p className={classes.panelTitle}>
                                    {t("Transaction ID")}
                                  </p>
                                  <p className={classes.panelDescription}>
                                    <Skeleton
                                      width={"96px"}
                                      height={"14px"}
                                      animation={animation}
                                      extraStyle={{
                                        marginBottom: "6px",
                                        marginTop: "3px"
                                      }}
                                    />
                                  </p>
                                </div>
                              </div>
                              {/* manage pick up documents */}
                              <PickupsCardDocument
                                item={{ documents }}
                                user={user}
                                selectedCompany={selectedCompany}
                                setUploadDialogOpen={() => {}}
                                handleRefetch={() => {}}
                                disableButtons={true}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    </Hidden>
                  </div>
                </div>
                {/* show on mobile only */}
                <Hidden mdUp>
                  <div
                    className={classnames(
                      classes.fullBasis,
                      classes.detailColRoot
                    )}
                  >
                    <div className={classes.ctaWrap}>
                      {!loading && (
                        <Button
                          type="button"
                          onClick={() => setModalIsVisible(true)}
                        >
                          {t("View More")}
                        </Button>
                      )}

                      <Skeleton
                        animation={animation}
                        active={loading}
                        width={"191px"}
                        height={"40px"}
                      >
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={() => {}}
                          disabled={disableButton}
                        >
                          {t("Pickups_ReschedulePickup")}
                        </Button>
                      </Skeleton>
                    </div>
                  </div>
                </Hidden>
              </div>
            </div>
            {/*  */}
          </div>
          {/* status here */}
          <Hidden smDown>
            <div className={classes.statusWrap}>
              <p className={classes.statusTitle}>
                <Skeleton animation={animation} active={loading}>
                  {t("Pick up status")}
                </Skeleton>
              </p>

              <div className={classes.statuses}>
                <Status
                  className={classes.statusClassName}
                  currentIndex={1}
                  statusElements={statusElements}
                  collapsed={!isExpanded}
                  disabled={true}
                />
              </div>
            </div>
          </Hidden>
        </CardContent>
      </Collapse>
      {/* expander */}
      <Hidden smDown>
        <div
          className={classnames(
            classes.expander,
            !isExpanded && classes.expanderCollapsed
          )}
          style={{ pointerEvents: "none", userSelect: "none" }}
        >
          <IconButton aria-label="toggle card" onClick={() => {}}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </Hidden>

      {/* mobile  view of expanded pick up card */}
      <Hidden mdUp>
        <EmptyModalPickupDisplay
          open={modalIsVisible}
          onClose={() => setModalIsVisible(false)}
          classes={classes}
          item={{ documents, pickupName: t("col_head_mat_name") }}
          user={user}
          selectedCompany={selectedCompany}
          parentClasses={classes}
          parentCardConditions={cardConditions}
        />
      </Hidden>
    </Card>
  );
};

export default EmptyPickUp;
