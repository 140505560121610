import React from "react";
import { Typography, Button, Modal, Paper } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

const styles = theme => ({
  modal: {
    width: 478,
    padding: 17,
    bordeRadius: 5,
    textAlign: "center",
    position: "fixed",
    left: "50% !important",
    top: "50% !important",
    height: 150,
    marginLeft: "-256px",
    marginTop: "-150px"
  },
  modalPaper: {
    padding: 20,
    "&>button": {
      margin: 10,
      width: 165,
      height: 52
    }
  }
});

class ZoneModal extends React.Component {
  render() {
    const {
      classes,
      open,
      closeModal,
      action,
      actionText,
      primary,
      secondary
    } = this.props;

    return (
      <Modal
        className={classes.modal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={open}
        onClose={closeModal}
      >
        <Paper className={classes.modalPaper}>
          <Typography variant="h6" id="modal-title">
            {primary}
          </Typography>
          <Typography variant="subtitle1" id="simple-modal-description">
            {secondary}
          </Typography>
          <Button
            justify="flex-end"
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={action}
          >
            {actionText}
          </Button>
          <Button
            justify="flex-end"
            variant="contained"
            color="secondary"
            className={classes.button}
            onClick={closeModal}
          >
            Cancel
          </Button>
        </Paper>
      </Modal>
    );
  }
}

export default withTranslation()(withStyles(styles)(ZoneModal));
