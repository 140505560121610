import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import ItemImage from "../../../card/ItemImage";
import Switch from "@material-ui/core/Switch";
import CategoryProductPricesMobile from "./CategoryProductPricesMobile";

const useStyles = makeStyles(theme => ({
  categoryCard: {
    width: "264px",
    marginRight: "24px",
    marginTop: "16px",
    marginBottom: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    transition: "0.5s",
    padding: "20px 0 0 0",
    background: "white",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: "4px",
    background: "#F9F9F9",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "0px"
    }
  },
  categoryTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  active: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    marginTop: "4px"
  },
  acceptContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "113px",
    alignItems: "center"
  },
  accepted: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)",
    marginTop: "12px",
    marginBottom: "16px"
  },
  openButton: {
    paddingRight: "8px",
    paddingLeft: "8px",
    background: "transparent",
    border: "none",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    fontFeatureSettings: "'liga' off",
    letterSpacing: "1px",
    color: "#151124",
    textTransform: "uppercase",
    marginTop: "0",
    padding: "0px",
    cursor: "pointer"
  },
  fullLoad: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    marginTop: "4px"
  },
  categoryCardImageWrapper: {
    display: "flex",
    width: "100%",
    padding: "0 16px",
    justifyContent: "space-between"
  },
  productImage: {
    width: "80px",
    height: "80px"
  },
  openButtonContainer: {
    display: "flex",
    borderTop: "1px solid #E0E0E0",
    width: "100%",
    marginTop: "12px",
    padding: "0 16px",
    justifyContent: "space-between"
  },
  fullLoadContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  }
}));

const CategoryProductMobile = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showPrices, setShowPrices] = useState(false);
  const {
    product,
    onRemoveOrAddProduct,
    removeOrAddWeightRange,
    changeReference,
    save,
    changeItem,
    changeLink
  } = props;

  const toggleProduct = (productId, ev) => {
    onRemoveOrAddProduct(productId);
  };

  return (
    <div className={classes.categoryCard}>
      <div className={classes.categoryCardImageWrapper}>
        <div className={classes.fullLoadContainer}>
          <span className={classes.categoryTitle}>{product.productName}</span>
          <span className={classes.fullLoad}>
            {t("Full load") + ": " + product.referencePrice}
          </span>
        </div>
        <ItemImage
          src={product.productImage.src}
          className={classes.productImage}
        />
      </div>
      <div className={classes.openButtonContainer}>
        <div className={classes.acceptContainer}>
          <span className={classes.accepted}>{t("Accepted")}</span>
          <Switch
            color="primary"
            defaultChecked={product.accepted}
            onClick={ev => toggleProduct(product.id, ev)}
          />
        </div>
        <button
          className={classes.openButton}
          onClick={() => setShowPrices(true)}
        >
          {t("Open")}
        </button>
      </div>
      {showPrices ? (
        <CategoryProductPricesMobile
          product={product}
          setShowPrices={setShowPrices}
          changeReference={changeReference}
          save={save}
          changeItem={changeItem}
          removeOrAddWeightRange={removeOrAddWeightRange}
          changeLink={changeLink}
        />
      ) : null}
    </div>
  );
};

export default CategoryProductMobile;
