import { makeStyles } from "@material-ui/core";
import { Select, MenuItem } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { SelectMenuProps } from "../../../../constants";

const useStyles = makeStyles(theme => ({
  inputText: {
    width: "100%"
  },
  row: {
    fontSize: "16px"
  }
}));

const ContractsSelect = ({ options, objectPaired, setObjectPaired, row }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Select
      className={classes.inputText}
      labelId="locations"
      id="locations"
      label={t("pairing_metaloop_account_title")}
      defaultValue={"null"}
      onChange={event => {
        setObjectPaired({
          ...objectPaired,
          [row]: event.target.value
        });
      }}
      MenuProps={SelectMenuProps}
    >
      <MenuItem key={"null"} value={"null"}>
        {t("pairing_select_default")}
      </MenuItem>
      {options.map(item => (
        <MenuItem key={item.value} value={item.value}>
          {item.name}
        </MenuItem>
      ))}
    </Select>
  );
};

export default ContractsSelect;
