import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";

const useStyles = makeStyles(theme => ({
  container: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "white",
    zIndex: "2000"
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "56px"
  },
  closeIcon: {
    marginLeft: "21px",
    marginRight: "37px"
  },
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  content: {
    marginTop: "24px",
    display: "flex",
    flexDirection: "column",
    paddingLeft: "16px",
    paddingRight: "16px"
  },
  row: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginBottom: "16px"
  },
  square: {
    width: "24px",
    height: "24px",
    marginRight: "8px"
  },
  green: {
    background: "#27AE60"
  },
  blue: {
    background: "#2F80ED"
  },
  orange: {
    background: "#F2994A"
  },
  purple: {
    background: "#9B51E0"
  },
  text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)"
  }
}));

const CategoryProductLegendMobile = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { setShowLegend } = props;

  const closeLegend = () => {
    setShowLegend(false);
  };
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <CloseIcon onClick={closeLegend} className={classes.closeIcon} />
        <span className={classes.title}>{t("Legend")}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.row}>
          <div className={classes.square + " " + classes.green}></div>
          <span className={classes.text}>{t("Discount")}</span>
        </div>
        <div className={classes.row}>
          <div className={classes.square + " " + classes.blue}></div>
          <span className={classes.text}>{t("My shop price")}</span>
        </div>
        <div className={classes.row}>
          <div className={classes.square + " " + classes.orange}></div>
          <span className={classes.text}>{t("S24 index")}</span>
        </div>
        <div className={classes.row}>
          <div className={classes.square + " " + classes.purple}></div>
          <span className={classes.text}>
            {t("Committed price categories, automatic sales")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CategoryProductLegendMobile;
