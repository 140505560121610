import React from "react";
import { withStyles } from "@material-ui/core/styles";
import HeaderLogo from "./HeaderLogo";

const styles = theme => ({
  wrapper: {
    width: "100%",
    height: "160px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 75px 0 75px"
  }
});

const HeaderComponent = props => (
  <div className={props.classes.wrapper}>
    <div>
      <HeaderLogo />
    </div>
    <div />
  </div>
);

export default withStyles(styles)(HeaderComponent);
