import React from "react";
import { withStyles } from "@material-ui/core/styles";
import classnames from "classnames";
import useStandardLocalization from "../../../hooks/useStandardLocalization";

const styles = theme => ({
  general: {
    textAlign: "center",
    fontSize: "16px",
    color: "grey",
    whiteSpace: "nowrap"
  },
  translate: {
    padding: "10px",
    fontSize: "12px",

    "&$weight": {
      color: "rgba(149,152,154,1)"
    },
    "&$disabled": {
      color: "rgba(149,152,154,0.3)"
    }
  },
  weight: {},
  disabled: {}
});

const WeightRange = props => {
  const { classes, data, selected, inSlider } = props;
  const { parseNumber } = useStandardLocalization();
  return (
    <div
      className={
        selected
          ? classnames(
              classes.general,
              classes.weight,
              inSlider ? classes.translate : ""
            )
          : classnames(
              classes.disabled,
              classes.general,
              inSlider ? classes.translate : ""
            )
      }
    >
      {data[1] !== Infinity
        ? parseNumber(data[0]) + " - " + parseNumber(data[1])
        : parseNumber(data[0]) + " +"}
    </div>
  );
};

export default withStyles(styles)(WeightRange);
