import React, { useCallback, useMemo, useEffect } from "react";
import {
  DialogContent,
  DialogContentText,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import ProgressBar from "../../components/general/ProgressBar";
import FileList from "../../components/core/shared/uploadDialog/components/FileList";
import UPLOAD_FILE_MUTATION from "../../mutations/uploadFileMutation";
import { useMutation } from "@apollo/react-hooks";
import { MAX_PORTAL_UPLOAD_FILE_SIZE } from "../../utils/constants";
import { WEIGHTNOTE_FILE_TYPE } from "../../components/core/shared/uploadDialog/components/FileList/fileTypeOptions";
const useStyles = makeStyles(theme => ({
  headerContainer: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      minHeight: "56px",
      display: "flex",
      alignItems: "center",
      marginTop: "0px"
    }
  },
  headerRoot: {
    width: "100%",
    paddingTop: "48px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "8px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      position: "initial",
      padding: "0"
    },
    "&.advanced": {
      paddingTop: "48px",
      paddingBottom: "0"
    },
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "10px",
        padding: "0"
      },
      display: "flex",
      gap: "10px"
    },
    "& .MuiIconButton-root": {
      [theme.breakpoints.up("md")]: {
        right: "1px"
      },
      [theme.breakpoints.down("sm")]: {
        left: "1px",
        position: "initial"
      },
      position: "absolute",
      top: "1px"
    }
  },
  fileSelection: {
    margin: "0 -24px"
  },
  closeButton: {
    marginRight: "16px"
  },
  root: {
    [theme.breakpoints.up("md")]: {
      width: "640px"
    }
  },
  progressBar: {
    marginBottom: "16px"
  }
}));

export default function SecondStep({
  files,
  setFiles,
  closingModal,
  driverTokenValidity,
  setStep
}) {
  const classes = useStyles();

  const [addFileMutation] = useMutation(UPLOAD_FILE_MUTATION);

  const handleUploadFiles = useCallback(async () => {
    for (let file of files) {
      const { size } = file;
      if (size > MAX_PORTAL_UPLOAD_FILE_SIZE) {
        closingModal("limitExceeded");
        return;
      }
      await addFileMutation({
        variables: {
          file,
          input: {
            token: driverTokenValidity?.data?.token,
            orderId: driverTokenValidity?.data?.order?._id,
            collection: "PartnerUploads",
            meta: {
              uploadByDriver: true,
              productId: driverTokenValidity?.data?.product?.productID,
              documentType: WEIGHTNOTE_FILE_TYPE
            }
          }
        }
      });
      file.completed = true;
      setFiles([...files]);
    }
    setStep(3);
  }, [files, addFileMutation, setFiles, closingModal, driverTokenValidity]);

  const [actualFile, totalFiles, percentage] = useMemo(() => {
    const totalFiles = files.length;
    const uploadingFile = files.findIndex(f => !f.completed);
    const actualFile = uploadingFile === -1 ? totalFiles : uploadingFile;
    const percentage = (actualFile / totalFiles) * 100;
    return [actualFile, totalFiles, percentage];
  }, [files]);

  useEffect(() => {
    handleUploadFiles();
  }, []);

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.headerRoot}>
          <IconButton
            aria-label="delete"
            onClick={() => closingModal("closeTab")}
            className={classes.closeButton}
          >
            <ClearIcon />
          </IconButton>
          <Typography variant="h2" gutterBottom>
            {"Upload Weight Note"}
          </Typography>
        </div>
      </div>
      <DialogContent className={classes.root}>
        <DialogContentText>
          {"The files you selected are being uploaded"} <br />
          {
            "Please do not close or refresh the browser window until the upload process is completed"
          }
        </DialogContentText>
        <ProgressBar
          className={classes.progressBar}
          percentage={percentage}
          text={`${actualFile} of ${totalFiles} files uploaded`}
        />
        <FileList
          files={files}
          className={classes.fileSelection}
          uploading={true}
          isDriver={true}
        />
      </DialogContent>
    </>
  );
}
