/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable react/prop-types */

import React from "react";
import queryString from "query-string";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useApolloClient, useQuery } from "react-apollo";
import { checkTokenError } from "./modules/authUtils";
import { LOGOUT_USER } from "./actions/auth";
import Menu from "./components/core/shared/Menu";
import MobileNavbar from "./components/core/shared/MobileNavbar";
import UserContext from "./providers/UserProvider";
import UserNavBar from "./queries/UserNavBar";
import SelectCompanyModal from "./components/core/shared/SelectLocationModal";
import { GET_SELECTED_COMPANY } from "./apollo-reducers";
import { useSelector, useDispatch } from "react-redux";
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch
} from "react-router";
import CreateAccountFromToken from "./components/account/createAccount/CreateAccountFromToken";
import { setUrlToRedirect } from "./utils/Redirection";
import { reportError } from "./ErrorTracking";
import "./App.css";
import Loader from "./components/core/shared/Loader";
import { CheckErrors } from "./ErrorTracking";
import { ROUTES as ROUTES_ORIGINAL } from "./components/core/shared/constants";
import { clearStorage } from "./actions/DataPersistence";
import { IdentifyEventUser } from "./modules/segment";
import { CheckPermissions } from "./components/core/shared/CheckPermissions";
import DocPreviewProvider from "./components/core/shared/DocPreviewProvider";
import ErrorBoundary from "../src/ErrorTracking/ErrorBoundary";
import ResetPasswordFromToken from "./components/account/resetPassword/ResetPasswordFromToken";
import { isAtGroupAdminAccount } from "./utils/SharedUtils";
import DashboardIconOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import InsertChartOutlinedIcon from "@material-ui/icons/InsertChartOutlined";
import MapOutlinedIcon from "@material-ui/icons/MapOutlined";
import { useTranslation } from "react-i18next";
import ProductFruits from "./productFruits";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      marginTop: "56px" // Same height than MobileNavbar
    }
  },
  body: {
    flexGrow: "1",
    display: "flex"
  },

  content: {
    flexGrow: "1",
    overflowY: "auto",
    overflowX: "hidden",
    height: "calc(100vh - 48px)",
    marginLeft: "264px",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "55px",
      marginLeft: "0px",
      height: "100vh",
      background: "white"
    }
  },
  fullScreenContent: {
    marginLeft: "0"
  },
  sidebar: {
    width: "264px"
  },
  test: {
    backgroundColor: "red"
  }
}));

const Wrapper = ({ children }) => {
  const classes = useStyles();
  return <div className={classes.container}>{children}</div>;
};

export default ({ children, leftNavigation }) => {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const client = useApolloClient();
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  const checkLoginNoRedirect = token => {
    if (token) return null;
    return user && user.token;
  };

  if (params && params.companyId)
    client.writeData({
      data: { selectedCompany: params.companyId }
    });

  // const [createAccount, setCreateAccount] = useState(false);
  // const [token, setToken] = useState(null);

  const {
    data: selectedCompanyQueryData,
    loading: selectedCompanyQueryLoading,
    error: selectedCompanyQueryError
  } = useQuery(GET_SELECTED_COMPANY);

  if (location.search) {
    let query = queryString.parse(location.search);

    if (query && query.create_token) {
      return (
        <ErrorBoundary>
          <Wrapper>
            <CreateAccountFromToken
              token={query.create_token}
              returnPath={location}
            />
          </Wrapper>
        </ErrorBoundary>
      );
    } else if (query && query.reset_token) {
      return (
        <ErrorBoundary>
          <Wrapper>
            <ResetPasswordFromToken
              token={query.reset_token}
              returnPath={location}
            />
          </Wrapper>
        </ErrorBoundary>
      );
    } else if (query && query.partner) {
      client.writeData({
        data: { selectedCompany: query.partner }
      });

      delete query.partner;
      const searchParams = new URLSearchParams(query);

      history.replace({
        pathname: location.pathname || "/",
        search: searchParams.toString()
      });
    }

    if (query && query.isDemoTriggered) {
      if (query.isDemoTriggered === "true") {
        localStorage.setItem("isDemoTriggered", query.isDemoTriggered);
      } else {
        localStorage.removeItem("isDemoTriggered");
      }
    }
  }

  if (!user)
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );

  if (selectedCompanyQueryLoading)
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );
  if (selectedCompanyQueryError)
    return (
      <Wrapper>
        <div>{selectedCompanyQueryError.message}</div>
      </Wrapper>
    );

  if (!checkLoginNoRedirect()) {
    dispatch({ type: LOGOUT_USER });
    history.push({
      pathname: "/login",
      state: {
        redirectLink: location.pathname + location.search
      }
    });
  }

  return (
    <ErrorBoundary>
      <UserContextFactory
        selectedCompany={selectedCompanyQueryData.selectedCompany}
        leftNavigation={leftNavigation}
      >
        {children}
      </UserContextFactory>
    </ErrorBoundary>
  );
};

const UserContextFactory = ({ selectedCompany, leftNavigation, children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const dispatch = useDispatch();
  const location = useLocation();
  const { pathname: activePathname } = location;
  const history = useHistory();
  const match = useRouteMatch();
  const client = useApolloClient();
  const user = useSelector(state => state.user);
  const { t } = useTranslation();

  const {
    data: userQueryData,
    loading: userQueryLoading,
    error: userQueryError
  } = useQuery(UserNavBar, {
    fetchPolicy: "network-only",
    variables: {
      company: selectedCompany,
      token: user.token
    },
    onCompleted: data => {
      const { UserCompanies, RawUserData } = data;

      const { isAdmin = false } = RawUserData;
      const includesSelectedCompany = UserCompanies.find(
        c => c._id === selectedCompany
      );

      if (!includesSelectedCompany && UserCompanies.length !== 1) {
        client.writeData({
          data: { selectedCompany: "all" }
        });
        return;
      }

      if (UserCompanies && UserCompanies.length === 1 && !isAdmin) {
        client.writeData({
          data: { selectedCompany: UserCompanies[0]._id }
        });
      }

      const companyData = { ...data, selectedCompany: includesSelectedCompany };

      IdentifyEventUser(companyData);
    },
    onError: error => {
      if (checkTokenError(error)) {
        dispatch({ type: LOGOUT_USER });
        reportError(error);
        history.push({
          pathname: "/login",
          state: { redirectLink: location.pathname }
        });
      }
    }
  });

  const onLogout = () => {
    client.cache.reset();
    clearStorage();
    dispatch({ type: LOGOUT_USER });
  };

  const childrenWithProps = obj =>
    React.cloneElement(children, {
      match,
      history,
      activePathname,
      user,
      ...obj
    });

  if (userQueryLoading)
    return (
      <Wrapper>
        <Loader />
      </Wrapper>
    );

  if (!userQueryData || userQueryData.RawUserData === null) {
    setUrlToRedirect(location.pathname);
    dispatch({ type: LOGOUT_USER });
    history.push("/login");
    return null;
  }

  if (
    userQueryData.UserCompanies === null &&
    !userQueryData.RawUserData?.isAdmin
  ) {
    history.push("/no-authorized");
    return null;
  }
  const includesSelectedCompany = userQueryData.UserCompanies.find(
    c => c._id === selectedCompany
  );

  if (!includesSelectedCompany && !userQueryData.RawUserData?.isAdmin) {
    client.writeData({
      data: { selectedCompany: "all" }
    });
  }

  if (userQueryData.UserCompanies)
    if (userQueryError)
      return <Wrapper>`2: Error: ${userQueryError.message}`</Wrapper>;

  if (
    selectedCompany &&
    selectedCompany === "all" &&
    userQueryData.UserCompanies.length > 1
  ) {
    //We are going to consider this user could be a Group Admin, so in that case we will add the company of the parent
    const distinctParentAccountIds = new Set();
    userQueryData.UserCompanies.forEach(item => {
      if (item.parentAccountId) {
        distinctParentAccountIds.add(item.parentAccountId);
      }
    });

    const distinctParentAccountIdsArray = Array.from(distinctParentAccountIds);

    const objectsWithNullParentAccountId = userQueryData.UserCompanies.filter(
      item => item.parentAccountId === null
    );

    if (
      distinctParentAccountIdsArray.length === 1 &&
      objectsWithNullParentAccountId.length === 1 &&
      distinctParentAccountIdsArray[0] === objectsWithNullParentAccountId[0]._id
    ) {
      client.writeData({
        data: { selectedCompany: objectsWithNullParentAccountId[0]._id }
      });
    }
  }

  const dashboardChilds =
    isAtGroupAdminAccount(selectedCompany, userQueryData.UserCompanies) &&
    userQueryData.RawUserData.isGroupAdmin
      ? [
          {
            id: "1.1",
            text: t("admin_dashboard_title")
          },
          {
            id: "1.2",
            to: "?view=overview",
            icon: <DashboardIconOutlinedIcon />,
            text: t("overview_title"),
            urlParams: true,
            appendInMobile: true
          },
          {
            id: "1.3",
            to: "?view=stats",
            icon: <InsertChartOutlinedIcon />,
            text: t("detailed_stats_title"),
            urlParams: true
          },
          {
            id: "1.4",
            to: "?view=map",
            urlParams: true,
            icon: <MapOutlinedIcon />,
            text: t("map_title")
          }
        ]
      : [];

  const ROUTES = (ROUTES_ORIGINAL[1].children = dashboardChilds);

  return (
    <Wrapper>
      {selectedCompany && selectedCompany !== "all" ? (
        <UserContext.Provider
          value={{
            userData: userQueryData,
            selectedCompany: selectedCompany,
            user
          }}
        >
          <CheckPermissions
            selectedCompany={selectedCompany}
            userCompanies={userQueryData.UserCompanies}
            routes={ROUTES}
          >
            <DocPreviewProvider
              token={user.token}
              selectedCompany={selectedCompany}
            >
              <Menu onLogout={onLogout} leftNavigation={leftNavigation} />
              <div className={classes.body}>
                <div
                  className={` ${classes.content} ${
                    leftNavigation ? null : classes.fullScreenContent
                  }`}
                  id="main-wrapper"
                >
                  {childrenWithProps({
                    selectedCompany: selectedCompany,
                    userData: userQueryData
                  })}
                </div>
                <MobileNavbar onLogout={onLogout} />
              </div>
            </DocPreviewProvider>
          </CheckPermissions>
          <ProductFruits />
        </UserContext.Provider>
      ) : (
        <CheckErrors>
          <SelectCompanyModal
            userData={userQueryData}
            selectedCompany={selectedCompany}
            user={user}
          />
        </CheckErrors>
      )}
    </Wrapper>
  );
};
