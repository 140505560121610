import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import LoginLogo from "../../components/login/LoginLogo";
import { withTranslation } from "react-i18next";

import LoginFastContainer from "../../containers/login/LoginFast";
const styles = {
  container: {
    width: "100vw",
    height: "100vh"
  },
  fullHeight: {
    margin: 0,
    height: "100vh",
    overflow: "hidden"
  },
  sectionWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    height: "100vh",
    padding: "200px 0 50px",
    "@media (max-width: 400px)": {
      paddingTop: "48px",
      paddingBottom: "24px"
    }
  }
};

const LoginLinkPage = ({ history, classes, t }) => (
  <Grid container className={classes.fullHeight}>
    <Grid item xs={12} md={12} lg={12}>
      <Grid item className={classes.sectionWrapper}>
        <LoginLogo />
        <LoginFastContainer history={history} />
      </Grid>
    </Grid>
  </Grid>
);

LoginLinkPage.defaultProps = {
  classes: {},
  history: {},
  location: {}
};

LoginLinkPage.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object
};

export default withStyles(styles)(withTranslation()(LoginLinkPage));
