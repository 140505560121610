import { useMutation } from "@apollo/react-hooks";
import impersonateUser from "../../../mutations/impersonateUser";
import { showError } from "../../core/shared/Notify";

/**
 *
 * @param match: The params of the URL, we will get the token, the id of the user to be impersonated and the company of the user from there
 * @returns nothing. Changes the stored cookies for the credentials of the impersonated token
 */
const Impersonate = ({ match }) => {
  //Token of the user who made the impersionation request
  let token = match.params.token;
  //Id of the user to be impersonated
  let objetiveId = match.params.objetiveId;
  //Company of the user
  let selectedCompany = match.params.company;

  const [mutation] = useMutation(impersonateUser);
  mutation({
    variables: {
      token: token,
      objetiveId: objetiveId,
      company: selectedCompany
    }
  })
    .then(({ data }) => {
      //We get the new credentials
      const { id, token, tokenExpires } = data?.impersonateUser;

      //Create the object for the new credentials
      let new_credentials = {
        user: {
          id: id,
          token: token,
          sellerId: null,
          tokenExpires: tokenExpires,
          loginErrorMessage: "",
          impersonating: true
        }
      };
      //We update the credentials of the session with the new ones of the impersonated user
      localStorage.setItem("s24-user", JSON.stringify(new_credentials));
      //We redirect the window to the dashboard
      window.location.replace(
        window.location.protocol + "//" + window.location.host + "/dashboard"
      );
    })
    .catch(err => showError(err.message));
};

export default Impersonate;
