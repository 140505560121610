import { makeStyles, createStyles } from "@material-ui/core";

export default makeStyles(theme =>
  createStyles({
    mainContainer: {
      height: "100%"
    },
    mainContainerMobile: {
      marginTop: "48px"
    },
    container: {
      padding: "28px 32px"
    },
    containerMobile: {
      padding: "24px 16px 16px"
    },
    pricingButton: {
      marginTop: 20
    },
    header: {
      fontWeight: "normal",
      fontSize: "32px",
      lineHeight: "40px"
    },
    divider: {
      backgroundColor: "#EEEEEE"
    },
    marginTop: {
      marginTop: "24px"
    },
    mainContentContainer: {
      display: "flex",
      flexDirection: "row",
      height: "100%"
    }
  })
);
