import { reportError } from "../ErrorTracking";
import localforage from "localforage";

export const loadState = item => {
  try {
    const serializedState = localStorage.getItem(item);
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    reportError(e);
    return undefined;
  }
};

export const loadStateKey = (item, key) => {
  try {
    const state = loadState(item);
    if (state && state[key]) {
      return state[key];
    }
    return undefined;
  } catch (e) {
    reportError(e);
    return undefined;
  }
};

export const saveState = (item, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(item, serializedState);
  } catch (e) {
    reportError(e);
    // ignore write
  }
};

export const clearStorage = (item, state) => {
  try {
    localforage.removeItem("apollo-cache-persist");
    localStorage.removeItem("s24-user");
  } catch (e) {
    reportError(e);
  }
};
