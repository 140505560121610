import gql from "graphql-tag";

const SET_VIEWED_TENDER = gql`
  mutation setViewedTender(
    $token: String!
    $tenderId: String!
    $buyerId: String!
  ) {
    setViewedTender(token: $token, tenderId: $tenderId, buyerId: $buyerId) {
      message
    }
  }
`;

const SAVE_BID_TENDER = gql`
  mutation saveBidTender(
    $token: String!
    $tenderId: String!
    $buyerId: String!
    $product: Object
    $buyerComment: String
  ) {
    saveBidTender(
      token: $token
      tenderId: $tenderId
      buyerId: $buyerId
      product: $product
      buyerComment: $buyerComment
    ) {
      message
    }
  }
`;

const PUBLISH_BID_TENDER = gql`
  mutation publishBidTender(
    $token: String!
    $tenderId: String!
    $buyerId: String!
    $products: [Object]
    $buyerComment: String
  ) {
    publishBidTender(
      token: $token
      tenderId: $tenderId
      buyerId: $buyerId
      products: $products
      buyerComment: $buyerComment
    ) {
      message
    }
  }
`;

export { SAVE_BID_TENDER, PUBLISH_BID_TENDER, SET_VIEWED_TENDER };
