import React from "react";
import { withStyles } from "@material-ui/core/styles";
import DashboardGraphContainer from "../../containers/dashboard/DashboardGraphContainer";
import Hidden from "@material-ui/core/Hidden";
import HomeMobile from "../../containers/home/HomeMobile";

const styles = () => ({
  container: {
    display: "flex",
    height: "100%"
  }
});

const HomePage = ({ user, classes, activePathname, selectedCompany }) => (
  <div className={classes.container}>
    <Hidden smDown>
      <DashboardGraphContainer user={user} selectedCompany={selectedCompany} />
    </Hidden>
    <Hidden mdUp>
      <HomeMobile />
    </Hidden>
  </div>
);

export default withStyles(styles)(HomePage);
