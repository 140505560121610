import gql from "graphql-tag";

export default gql`
  mutation uploadFileToOrder($file: Upload!, $input: UploadFileToOrderInput) {
    uploadFileToOrder(file: $file, input: $input) {
      id
      src
      alt
      fromBuyer
    }
  }
`;
