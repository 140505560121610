import React, { useState, useCallback } from "react";
import { createStyles, makeStyles, Typography, Grid } from "@material-ui/core";

import Button from "./Button";

import CloseIcon from "@material-ui/icons/Close";
import FilterListIcon from "@material-ui/icons/FilterList";

const componentStyles = makeStyles(
  createStyles(theme => ({
    FiltersButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#FFFFFF",
      cursor: "pointer",
      background: "#151124",
      boxShadow:
        "0px 2px 6px rgba(0, 0, 0, 0.18), 0px 2px 4px rgba(0, 0, 0, 0.24)",
      borderRadius: "64px",
      position: "fixed",
      width: "126px",
      height: "48px",
      top: "calc(100% - 74px)",
      left: " calc(50% - 63px)",
      "&:hover": {
        color: "rgba(255,255,255,0.8)"
      },
      "& > svg": {
        marginRight: "15px"
      }
    },
    FilterPanel: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#FFFFFF",
      zIndex: 1,
      padding: "0px 20px",
      color: "color: rgba(0, 0, 0, 0.87)"
    },
    filtersHeader: { padding: "16px 0" },
    filtersHeaderCloseIcon: {
      cursor: "pointer",
      "&:hover": {
        color: "#555555"
      }
    },
    filtersHeaderText: {
      padding: "0px 21px"
    },
    filterRow: {
      paddingTop: "31px"
    }
  }))
);

const FiltersButton = ({ children, filters, resetFilters }) => {
  const classes = componentStyles();
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  return (
    <>
      {showFilters && (
        <FiltersPanel
          filters={filters}
          closeAction={toggleFilters}
          resetFilters={resetFilters}
        >
          {children}
        </FiltersPanel>
      )}
      <div
        className={classes.FiltersButton}
        onClick={() => setShowFilters(!showFilters)}
      >
        <FilterListIcon />
        <Typography variant="button">Filters</Typography>
      </div>
    </>
  );
};

const FiltersPanel = ({ children, filters, closeAction, resetFilters }) => {
  const classes = componentStyles();

  const hasFilters = filters && Object.keys(filters).length > 0;

  return (
    <div className={classes.FilterPanel}>
      <Grid container direction="column">
        <Grid container item className={classes.filtersHeader}>
          <Grid item>
            <CloseIcon
              className={classes.filtersHeaderCloseIcon}
              onClick={closeAction}
            />
          </Grid>
          <Grid item xs className={classes.filtersHeaderText}>
            <Typography variant="h6">Filters</Typography>
          </Grid>
        </Grid>
        {children && children.map ? (
          children.map((c, i) => (
            <Grid item key={i} className={classes.filterRow}>
              {c}
            </Grid>
          ))
        ) : (
          <Grid item className={classes.filterRow}>
            {children}
          </Grid>
        )}
        {hasFilters && (
          <Grid item className={classes.filterRow}>
            <Button color="primary" variant="contained" onClick={resetFilters}>
              Clear All
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default FiltersButton;
