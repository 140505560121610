import React from "react";
import Avatar from "@material-ui/core/Avatar";
import { makeStyles } from "@material-ui/core/styles";
import Skeleton from "../core/shared/Skeleton";

const useStyles = makeStyles(theme => ({
  avatar: {
    backgroundColor: "rgba(224, 224, 224, 1)",
    fontSize: 14,
    lineHeight: 20,
    fontWeight: 500,
    fontFamily: "Roboto",
    color: "rgba(158, 158, 158, 1)"
  }
}));

const getInitials = name => {
  const names = name.split(" ");
  let initials = "";
  for (let i = 0; i < names.length; i++) {
    if (initials.length < 2) initials += names[i].substring(0, 1).toUpperCase();
  }
  return initials;
};

const getEmailInitials = email =>
  (email.split("@")[0] || "").substring(0, 2).toUpperCase();

export default ({
  src,
  width = 36,
  height = 36,
  name = "",
  email = "",
  loading = false
} = {}) => {
  const classes = useStyles();
  return !loading && src ? (
    <Avatar
      alt="avatar picture"
      src={src}
      style={{ width: width + "px", height: height + "px" }}
    />
  ) : (
    <Avatar
      style={{ width: width + "px", height: height + "px" }}
      className={classes.avatar}
    >
      {loading ? (
        <Skeleton
          width={`${width}px`}
          height={`${height}px`}
          active={loading}
          animation={loading}
        ></Skeleton>
      ) : name ? (
        getInitials(name)
      ) : (
        getEmailInitials(email)
      )}
    </Avatar>
  );
};
