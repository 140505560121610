import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import {
  getDate,
  isSameMonth,
  isToday,
  format,
  isWithinInterval,
  startOfWeek,
  startOfMonth,
  endOfWeek,
  endOfMonth,
  isBefore,
  addDays,
  isSameDay
} from "date-fns";

import Header from "./Header";
import Day from "./Day";

const weekDays = [
  "Calendar_Short_Sunday",
  "Calendar_Short_Monday",
  "Calendar_Short_Tuesday",
  "Calendar_Short_Wednesday",
  "Calendar_Short_Thursday",
  "Calendar_Short_Friday",
  "Calendar_Short_Saturday"
];

const Month = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      width: 320,
      height: "fit-content",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        paddingLeft: "25px",
        paddingRight: "25px"
      }
    },
    weekDaysContainerHeader: {
      height: 36
    },
    weekDaysContainer: {
      padding: 0,

      width: 264,
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    daysContainer: {
      marginTop: 10,
      padding: 0,
      width: 264,
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    }
  }));

  const classes = useStyles();

  const {
    helpers,
    handlers,
    value: date,
    dateRange,
    marker,
    minDate,
    maxDate,
    navState,
    disableWeekends
  } = props;

  const [back, forward] = navState;

  const chunks = (array, size) =>
    Array.from({ length: Math.ceil(array.length / size) }, (v, i) =>
      array.slice(i * size, i * size + size)
    );
  const getDaysInMonth = date => {
    const startWeek = startOfWeek(startOfMonth(date), { weekStartsOn: 0 });
    const endWeek = endOfWeek(endOfMonth(date), { weekStartsOn: 0 });
    const days = [];
    for (let curr = startWeek; isBefore(curr, endWeek); ) {
      days.push(curr);
      curr = addDays(curr, 1);
    }

    return days;
  };

  const isStartOfRange = ({ startDate }, day) =>
    startDate && isSameDay(day, startDate);

  const isEndOfRange = ({ endDate }, day) => endDate && isSameDay(day, endDate);

  const inDateRange = ({ startDate, endDate }, day) =>
    startDate &&
    endDate &&
    (isWithinInterval(day, {
      start: startDate,
      end: endDate
    }) ||
      isSameDay(day, startDate) ||
      isSameDay(day, endDate));

  const isRangeSameDay = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      return isSameDay(startDate, endDate);
    }

    return false;
  };
  const { t } = useTranslation();

  return (
    <Grid container className={classes.root}>
      <Header
        date={date}
        nextDisabled={!forward}
        prevDisabled={!back}
        onClickPrevious={() => handlers.onMonthNavigate(marker, -1)}
        onClickNext={() => handlers.onMonthNavigate(marker, 1)}
        marker={marker}
        onYearNavigate={handlers.onYearNavigate}
      />

      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        wrap="nowrap"
        className={`${classes.weekDaysContainerHeader}
        ${classes.weekDaysContainer}`}
      >
        {weekDays.map((day, idx) => (
          <Day key={idx} value={t(day)} disabled={true} forHeader={true} />
        ))}
      </Grid>

      <Grid
        item
        container
        direction="column"
        justifyContent="space-between"
        className={classes.daysContainer}
      >
        {chunks(getDaysInMonth(date), 7).map(week => (
          <Grid
            key={format(week[0], "I-y")}
            container
            direction="row"
            justifyContent="center"
          >
            {week.map(day => {
              const isStart = isStartOfRange(dateRange, day);
              const isEnd = isEndOfRange(dateRange, day);
              const isRangeOneDay = isRangeSameDay(dateRange);
              const highlighted =
                inDateRange(dateRange, day) || helpers.inHoverRange(day);
              const isEndOfWeek = day.getDay() === 6;
              return (
                <Day
                  isEndOfWeek={isEndOfWeek}
                  key={format(day, "mm-dd-yyyy")}
                  filled={isStart || isEnd}
                  outlined={isToday(day)}
                  highlighted={highlighted && !isRangeOneDay}
                  hidden={!isSameMonth(date, day)}
                  disabled={
                    (disableWeekends &&
                      (day.getDay() === 0 || day.getDay() === 6)) ||
                    !isSameMonth(date, day) ||
                    !isWithinInterval(day, {
                      start: minDate,
                      end: maxDate
                    })
                  }
                  startOfRange={isStart && !isRangeOneDay}
                  endOfRange={isEnd && !isRangeOneDay}
                  onClick={() => handlers.onDayClick(day)}
                  onHover={() => handlers.onDayHover(day)}
                  value={getDate(day)}
                />
              );
            })}
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Month;
