import SearchMaterial from "../../../../../../products/searchMaterial";

const MaterialsChooser = ({ row, objectPaired, setObjectPaired }) => (
  <SearchMaterial
    selected={null}
    setSelected={value => {
      setObjectPaired({
        ...objectPaired,
        [row]: value?.aliasId || null
      });
    }}
  />
);

export default MaterialsChooser;
