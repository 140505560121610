import React from "react";

import addNewAccount from "../../../assets/icons/addNewAccount.png";
import TransportForm from "./TransportForm";
import TransportCard from "./transportCard/TransportCard";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(theme => ({
  equipmentContainer: {
    marginTop: "24px"
  },
  wrapper: {
    width: "100%"
  },
  defaultAddressesContainer: {
    display: "flex",
    marginBottom: "14px"
  },
  addNewAddressContainer: {
    display: "flex",
    width: "480px",

    justifyContent: "end",
    marginRight: "20px",

    marginBottom: "9px",
    "@media (max-width: 960px)": {
      width: "100%"
    }
  },
  AddCircleRounded: {
    backgroundColor: "red"
  },
  addNewAddressSpan: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "#151124",
    marginRight: "14px"
  },
  addAddressIconContainer: {
    paddingRight: "10px"
  },
  addAddressIcon: {
    color: "#52F597"
  }
}));

const TransportsMobile = ({
  addAddress,
  saveForm,
  cancelChange,
  handleChange,
  editAddress,
  data,
  defaultDelivery,
  defaultPickUp,
  listOfDeliveriy,
  listOfPickUps,
  changeDefaultAddress
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  {
    return editAddress !== null || addAddress !== null ? (
      <TransportForm
        saveForm={saveForm}
        data={editAddress !== null ? editAddress : addAddress}
        contacts={data.AccountContacts}
        type={editAddress !== null ? "Edit" : "Add"}
        cancelChange={cancelChange}
      />
    ) : (
      <div className={classes.equipmentContainer}>
        <div className={classes.defaultAddressesContainer}>
          <TransportCard
            data={defaultPickUp}
            handleChange={handleChange}
            isDefault
          />
        </div>

        <div className={classes.defaultAddressesContainer}>
          <div className={classes.addNewAddressContainer}>
            <span className={classes.addNewAddressSpan}>
              {t("Add Pickup Address")}{" "}
            </span>
            <div className={classes.addAddressIconContainer}>
              <img
                src={addNewAccount}
                onClick={event => handleChange("Add", { type: "pickup" })}
                alt="AddNewAccount"
                className={classes.addAddressIcon}
              />
            </div>
          </div>
        </div>

        <div className={classes.defaultAddressesContainer}>
          <TransportCard
            data={defaultDelivery}
            handleChange={handleChange}
            isDefault
          />
        </div>

        <div className={classes.defaultAddressesContainer}>
          <div className={classes.addNewAddressContainer}>
            <span className={classes.addNewAddressSpan}>
              {t("Add Delivery Address")}{" "}
            </span>
            <div className={classes.addAddressIconContainer}>
              <img
                src={addNewAccount}
                onClick={event => handleChange("Add", { type: "delivery" })}
                alt="AddNewAccount"
                className={classes.addAddressIcon}
              />
            </div>
          </div>
        </div>

        {listOfDeliveriy.map((deliveryAddress, index) => (
          <div className={classes.defaultAddressesContainer}>
            <TransportCard
              data={deliveryAddress}
              handleChange={handleChange}
              changeDefaultAddress={changeDefaultAddress}
              index={index}
            />
          </div>
        ))}

        {listOfPickUps.map((pickupAddress, index) => (
          <div className={classes.defaultAddressesContainer}>
            <TransportCard
              data={pickupAddress}
              handleChange={handleChange}
              changeDefaultAddress={changeDefaultAddress}
              index={index}
            />
          </div>
        ))}
      </div>
    );
  }
};

export default TransportsMobile;
