import React from "react";
import UserContext from "./UserProvider";

const WizardContext = React.createContext({
  categories: [],
  products: [],
  s24index: { priceData: [] },
  buyerPricing: {},
  step: 0,
  selectedCategories: [],
  selectedProducts: [],
  priceData: null,
  setStep: () => {},
  setSelectedCategories: () => {},
  setSelectedProducts: () => {},
  setPriceData: () => {},
  setBuyerInfo: () => {},
  sendCompletedWizard: () => {}
});

export const withWizard = Component =>
  class extends React.Component {
    render() {
      return (
        <UserContext.Consumer>
          {usercontext => (
            <WizardContext.Consumer>
              {wizardcontext => (
                <Component {...{ ...usercontext, ...wizardcontext }} />
              )}
            </WizardContext.Consumer>
          )}
        </UserContext.Consumer>
      );
    }
  };

export default WizardContext;
