import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PickupDropdown from "./PickupDropdown";
import DateDropdown from "../../general/DateDropdown";
import FilterListIcon from "@material-ui/icons/FilterList";
import CustomDialog from "../../general/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Fab from "@material-ui/core/Fab";

const PickupMobileFilters = ({
  status,
  setDate,
  setProduct,
  setStatus,
  setReset,
  filters,
  date,
  product,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  disabled = false
}) => {
  const useStyles = makeStyles(theme => ({
    content: {
      [theme.breakpoints.down("sm")]: {
        padding: "0!important",
        "& .MuiDialogContent-root": {
          padding: "0!important"
        }
      }
    },
    root: {
      [theme.breakpoints.down("sm")]: {
        padding: 0
      },
      "&. MuiDialogContent-root": {
        [theme.breakpoints.down("sm")]: {
          padding: 0
        }
      }
    },
    fixedWrapper: {
      width: "100%",
      position: "fixed",
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: "1",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      padding: "16px"
    },
    filterContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      padding: "8px",
      width: "100%"
    }
  }));
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {true ? (
        <>
          <CustomDialog
            open={open}
            title={t("Sales_Filters")}
            className={classes.root}
            onClose={() => {
              setOpen(false);
            }}
          >
            <DialogContent className={classes.content}>
              <DialogContentText id="alert-dialog-description">
                <div className={classes.filterContainer}>
                  <DateDropdown
                    date={date}
                    setDate={setDate}
                    label={"Pick up Date"}
                    startDate={startDate}
                    setStartDate={setStartDate}
                    endDate={endDate}
                    setEndDate={setEndDate}
                    disabled={disabled}
                  />

                  <PickupDropdown
                    value={product}
                    options={filters.products}
                    callback={setProduct}
                    label={"Show Products"}
                    disabled={disabled}
                  />
                  <PickupDropdown
                    value={status}
                    options={filters.status}
                    callback={setStatus}
                    label={"Status"}
                    localization={true}
                    disabled={disabled}
                  />
                </div>
              </DialogContentText>

              <DialogActions>
                <Button
                  variant="outlined"
                  color="primary"
                  disabled={disabled}
                  onClick={() => {
                    setReset(true);
                  }}
                >
                  {t("Sales_ClearFilters")}
                </Button>
              </DialogActions>
            </DialogContent>
          </CustomDialog>
          <div className={classes.fixedWrapper}>
            <Fab
              variant="extended"
              color="primary"
              aria-label="filter"
              onClick={() => {
                setOpen(true);
              }}
            >
              <FilterListIcon />
              {t("Filters")}
            </Fab>
          </div>
        </>
      ) : null}
    </>
  );
};

export default PickupMobileFilters;
