import React from "react";
import InformationCards from "./InformationCards";
import UserContext from "../../../providers/UserProvider";

const InformationContent = ({ token, company, userCompanies }) => (
  <UserContext.Consumer>
    {({ userData }) => (
      <InformationCards
        userData={userData}
        token={token}
        company={company}
        userCompanies={userCompanies}
      />
    )}
  </UserContext.Consumer>
);

export default InformationContent;
