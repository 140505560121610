import gql from "graphql-tag";

export default gql`
  mutation OnboardingWelcome($token: String!, $input: OnboardingWelcomeInput!) {
    OnboardingWelcomeMutation(token: $token, input: $input) {
      success
      userData
    }
  }
`;
