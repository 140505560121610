import React from "react";
import { FormControl, InputAdornment, makeStyles } from "@material-ui/core";

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import ClearIcon from "@material-ui/icons/Clear";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import { DATE_FORMAT_STRING } from "../../utils/Format";

const DateSelectorStyle = makeStyles({
  DateSelector: {
    border: "1px solid #9E9E9E",
    position: "relative",
    borderRadius: "0",
    paddingRight: "12px",
    height: "40px",
    cursor: "pointer",
    "& > input": {
      padding: "9px 16px",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "16px",
      height: "100%",
      boxSizing: "border-box",
      lineHeight: "24px",
      letterSpacing: "0.5px"
    },
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)"
    },
    "&:focus": {
      borderColor: "#52F597"
    }
  }
});

const DateSelector = ({
  id,
  label,
  value,
  onChange,
  onClear,
  disableFuture
}) => {
  const classes = DateSelectorStyle();

  return (
    <FormControl fullWidth variant="outlined">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          {...(disableFuture ? { disableFuture: true } : null)}
          name={id}
          id={id}
          emptyLabel={label}
          format={DATE_FORMAT_STRING}
          InputProps={{
            className: classes.DateSelector,
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                {!(onClear && value) ? (
                  <CalendarIcon />
                ) : (
                  <ClearIcon
                    onClick={e => {
                      e.stopPropagation();
                      (onClear || (() => {}))();
                    }}
                  />
                )}
              </InputAdornment>
            )
          }}
          onChange={onChange}
          value={value}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};

export default DateSelector;
