import Grid from "@material-ui/core/Grid";
import React from "react";
import Hidden from "@material-ui/core/Hidden";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ImageIconAss from "../../assets/icon1assigment.png";

const styles = theme => ({
  root: {
    minWidth: "100%",
    minHeight: "100%",
    flexGrow: 1
  },
  wrapper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  wrapperSub: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    margin: "1em 0"
  },

  circle: {
    margin: "0 auto",
    padding: "20px",
    background: "#FCEFDB",
    borderRadius: "50%",
    width: "25vw",
    height: "25vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  divAsButton: {
    color: "#EEA52C",
    cursor: "pointer",
    width: "25%",
    margin: "0 auto",
    display: "flex",
    border: "2px solid #F3C374",
    minHeight: "55px",
    lineHeight: "55px",
    justifyContent: "center",
    borderRadius: "10px"
  },
  divAsButtonSmall: {
    color: "#EEA52C",
    cursor: "pointer",
    width: "80%",
    margin: "0 auto",
    display: "flex",
    border: "2px solid #ffaa00",
    minHeight: "55px",
    lineHeight: "55px",
    justifyContent: "center",
    borderRadius: "10px"
  },
  caretIcon: {
    lineHeight: "55px",
    marginLeft: "1.5em"
  },
  imageFake: {
    width: "65%"
    //height: "30vw"
  }
});

const Step1 = ({ next, seller, classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24} justify="center" alignItems="center">
      <Grid item xs={12}>
        <div className={classes.wrapper}>
          <Typography
            variant="title"
            gutterBottom
            align="center"
            className={classes.title}
          >
            Zeigen Sie uns, dass Sie wirklich{" "}
            {seller.sellerFullName || seller.email} sind
          </Typography>
          <Typography variant="caption" gutterBottom align="center">
            Fast geschafft! Wir müssen nur Ihre Identität verifizieren, um
            unsere Gemeinschaft gegen Kriminalität zu schützen.
          </Typography>
          <Typography variant="caption" gutterBottom align="center">
            Es sollte nur eine Minute dauern, am schnellsten geht es am
            Smartphone.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.wrapperSub}>
          <div className={classes.circle}>
            <img
              src={ImageIconAss}
              alt="ImageIconAss"
              className={classes.imageFake}
            />
          </div>
        </div>
      </Grid>
      <Grid item xs={12}>
        <Hidden mdUp>
          <div className={classes.divAsButtonSmall} onClick={next}>
            <strong>Identität überprüfen</strong>
          </div>
        </Hidden>
        <Hidden mdDown>
          <div className={classes.divAsButton} onClick={next}>
            <strong>Identität überprüfen</strong>
          </div>
        </Hidden>
      </Grid>
    </Grid>
  </div>
);

Step1.propTypes = {
  next: PropTypes.func.isRequired,
  seller: PropTypes.shape({
    email: PropTypes.string,
    sellerFullName: PropTypes.string
  }).isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Step1);
