import React from "react";
import { Query } from "react-apollo";

import WizardContext from "../../providers/WizardProvider";
import UserContext from "../../providers/UserProvider";
import GET_FULL_PRICING_DATA from "../../queries/GetFullPricingData";
import Loader from "../../components/core/shared/Loader";
import { CheckErrors } from "../../ErrorTracking";

class QueryWrapper extends React.Component {
  render() {
    const { props } = this;
    return (
      <UserContext.Consumer>
        {({ user, selectedCompany }) => (
          <Query
            query={GET_FULL_PRICING_DATA}
            fetchPolicy="cache-and-network"
            variables={{
              token: user.token,
              buyerId: selectedCompany
            }}
          >
            {({ data, loading, error }) => {
              if (loading) return <Loader />;
              if (error) {
                return error.graphQLErrors[0].message;
              }

              return (
                <CheckErrors>
                  <PricingPageContainer
                    ref={this.PricingRef}
                    data={data}
                    {...props}
                    selectedCompany={selectedCompany}
                  />
                </CheckErrors>
              );
            }}
          </Query>
        )}
      </UserContext.Consumer>
    );
  }
}

class PricingPageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      selectedCategories: ((props.data || {}).BuyerPricing || {}).categories
        ? props.data.BuyerPricing.categories
        : [],
      selectedProducts: ((props.data || {}).BuyerPricing || {}).products
        ? props.data.BuyerPricing.products
        : []
    };

    this.setStep = this.setStep.bind(this);
    this.setSelectedCategories = this.setSelectedCategories.bind(this);
    this.setSelectedProducts = this.setSelectedProducts.bind(this);
    this.setBuyerInfo = this.setBuyerInfo.bind(this);
    this.sendCompletedWizard = this.sendCompletedWizard.bind(this);
  }

  static getDerivedStateFromProps(props, state) {
    return {
      selectedCategories: ((props.data || {}).BuyerPricing || {}).categories
        ? props.data.BuyerPricing.categories
        : [],
      selectedProducts: ((props.data || {}).BuyerPricing || {}).products
        ? props.data.BuyerPricing.products
        : []
    };
  }

  setStep(stepNo) {
    this.setState({
      step: stepNo
    });
  }
  setSelectedCategories(selectedCategories) {
    this.setState({
      selectedCategories
    });
  }
  setSelectedProducts(selectedProducts) {
    this.setState({ selectedProducts });
  }
  setBuyerInfo(buyerInfo) {
    this.setState({ buyerInfo });
  }
  sendCompletedWizard() {
    //todo: implement wizard mutation
  }
  render() {
    const { data, children } = this.props;

    return (
      <WizardContext.Provider
        value={{
          products: data.PriceProducts,
          s24index: data.S24IndexPrices,
          buyerInfo: data.BuyerPricing,
          ...this.state,
          setStep: this.setStep,
          setSelectedCategories: this.setSelectedCategories,
          setSelectedProducts: this.setSelectedProducts,
          setBuyerInfo: this.setBuyerInfo,
          sendCompletedWizard: this.sendCompletedWizard
        }}
      >
        {children}
      </WizardContext.Provider>
    );
  }
}

export default QueryWrapper;
