import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import classNames from "classnames";
import Dropzone from "react-dropzone";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(theme => ({
  dropzoneFullScreen: {
    height: "100%",
    width: "100%",
    zIndex: 1
  },
  dropzoneActive: {
    overflow: "hidden",
    position: "absolute",
    background: "#151124",
    boxShadow:
      "0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3)",
    color: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    padding: 50,
    "& > .MuiTypography-h2": {
      marginBottom: "8px",
      fontWeight: "500",
      fontSize: "32px",
      lineHeight: "40px"
    },
    "& > .MuiTypography-body1": {
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px"
    },
    zIndex: 100
  },
  hide: {
    visibility: false
  }
}));

const CustomDropzone = React.forwardRef(
  (
    {
      children,
      className,
      onDrop,
      accept,
      disableClick = true,
      multiple = true,
      dragText = null,
      dragSubText = null
    },
    ref
  ) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
      <Dropzone
        className={classNames(className, classes.dropzoneFullScreen)}
        disableClick={disableClick}
        onDrop={onDrop}
        accept={accept}
        ref={ref}
        multiple={multiple}
      >
        {({ isDragActive }) => (
          <>
            {isDragActive && (
              <div
                className={classNames(
                  classes.dropzoneFullScreen,
                  classes.dropzoneActive
                )}
              >
                <Typography variant="h2">
                  {dragText || t("DragDropSelectedFiles")}
                </Typography>
                <Typography variant="body1">
                  {dragSubText || t("DragDropNoFilesSelected")}
                </Typography>
              </div>
            )}
            <div
              id="dropzone-content"
              className={classNames(
                classes.dropzoneFullScreen,
                isDragActive && classes.hide
              )}
            >
              {children}
            </div>
          </>
        )}
      </Dropzone>
    );
  }
);

export default CustomDropzone;
