import gql from "graphql-tag";

export default gql`
  query partnerImportedContractsInfo($token: String!, $partnerId: String!) {
    partnerImportedContractsInfo(token: $token, partnerId: $partnerId) {
      hasPartnerImportedContracts
      isAllowedToImportContracts
    }
  }
`;
