import React, { useState, useCallback } from "react";
import {
  Typography,
  makeStyles,
  createStyles,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputBase,
  Button as ButtonMui,
  InputAdornment
} from "@material-ui/core";
import classnames from "classnames";

const componentStyles = makeStyles(
  createStyles(theme => ({
    offerRadio: {
      padding: "8px"
    },
    noMarginLeft: {
      marginLeft: "0px"
    },
    hidden: {
      display: "none"
    },
    inputText: {
      border: "1px solid rgba(0, 0, 0, 0.38)",
      borderRadius: "4px"
    },
    inputTextbox: {
      padding: "10px 16px",
      ...theme.typography.body2,
      color: "rgba(0, 0, 0, 0.85)"
    },
    buttonRoot: { padding: "10px 16px" },
    buttonLabel: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px",
      letterSpacing: "1px"
    },
    startAdornment: {
      marginLeft: "10px"
    },
    endAdornment: {
      marginRight: "10px"
    }
  }))
);

const RadioOptions = ({ name, options, value, onChange, ref }) => {
  const classes = componentStyles();
  const labelMargin = { root: classes.noMarginLeft };

  return (
    <FormControl component="fieldset" fullWidth>
      <RadioGroup
        aria-label={name}
        name={name}
        value={value || options[0].value}
        onChange={onChange}
      >
        {options.map(o => {
          const shouldHide = value !== o.value;
          let newClasses = { ...labelMargin };
          if (shouldHide) {
            newClasses.root = classes.noMarginLeft + " " + classes.hidden;
          }

          return (
            <FormControlLabel
              classes={newClasses}
              value={o.value}
              key={o.value}
              disabled={value !== o.value}
              control={
                <Radio color="secondary" className={classes.offerRadio} />
              }
              label={<Typography variant="body2">{o.label}</Typography>}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

const InputText = React.forwardRef(
  (
    {
      value,
      placeholder,
      multiline,
      rows,
      id,
      onChange,
      className,
      type,
      required,
      showAdornments,
      onFocus
    },
    ref
  ) => {
    const classes = componentStyles();
    const [classList, setClassList] = useState([]);

    const toggleInvalidInput = useCallback(
      (el, valid) => {
        if (!el) return;
        const className = "invalid-input";
        if (!valid && !classList.includes(className)) {
          setClassList(preClassList => [...preClassList, className]);
        } else if (valid && classList.includes(className)) {
          setClassList(preClassList =>
            preClassList.filter(c => c !== className)
          );
        }
      },
      [classList]
    );

    const checkValidity = useCallback(({ target: el }) => {
      const valid = el.checkValidity();
      if (valid) toggleInvalidInput(el, true);
    });

    const onFocusInternal = evt => {
      if (onFocus) {
        onFocus(evt);
      }
    };

    return (
      <FormControl fullWidth className={className}>
        <InputBase
          defaultValue={value}
          {...(required ? { required: true } : null)}
          classes={{ input: classes.inputTextbox }}
          id={id}
          {...(multiline ? { multiline: true, rows } : null)}
          placeholder={placeholder}
          className={classnames(classes.inputText, classList)}
          onChange={onChange}
          onBlur={checkValidity}
          onKeyUp={checkValidity}
          onInvalid={({ target }) => toggleInvalidInput(target, false)}
          onFocus={onFocusInternal}
          type={type || "string"}
          inputRef={ref}
          startAdornment={
            showAdornments ? (
              <InputAdornment
                position="start"
                className={classes.startAdornment}
              >
                <span>€</span>
              </InputAdornment>
            ) : null
          }
          endAdornment={
            showAdornments ? (
              <InputAdornment position="end" className={classes.endAdornment}>
                <span>=€/t</span>
              </InputAdornment>
            ) : null
          }
        />
      </FormControl>
    );
  }
);

const Button = ({
  color,
  variant,
  children,
  onClick,
  className,
  style,
  type
}) => {
  const classes = componentStyles();
  return (
    <ButtonMui
      type={type}
      variant={variant}
      color={color}
      disableElevation
      onClick={onClick}
      classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
      className={className}
      style={style}
    >
      {children}
    </ButtonMui>
  );
};

export { Button, RadioOptions, InputText };
