import React from "react";
import NumberFormat from "react-number-format";
import _ from "lodash";
import UserContext from "../../../providers/UserProvider";

export const getCurrencySymbol = props => {
  if (props && props.selectedCompany) {
    const selectedCompany = props.selectedCompany;
    if (selectedCompany && props.userData && props.userData.UserCompanies) {
      const company = _.find(
        props.userData.UserCompanies,
        c => c._id === selectedCompany
      );
      if (company && company.currencySymbol) {
        return company.currencySymbol;
      }
    }
  }
  return "€";
};

class CurrencyFormat extends React.Component {
  render() {
    return (
      <UserContext.Consumer>
        {props => (
          <NumberFormat
            value={
              this.props.value
                ? _.isFinite(this.props.value)
                  ? this.props.value
                  : parseFloat(this.props.value)
                : 0
            }
            displayType={"text"}
            thousandSeparator={"."}
            decimalSeparator={","}
            decimalScale={this.props.nodec ? 0 : 2}
            fixedDecimalScale
            prefix={getCurrencySymbol(props) + " "}
          />
        )}
      </UserContext.Consumer>
    );
  }
}

export default CurrencyFormat;
