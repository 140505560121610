import React from "react";
import { useQuery } from "react-apollo";

import Loader from "../core/shared/Loader";
import GET_PRODUCTS_AND_CATEGORIES_SIMPLE from "../../queries/GetProductsAndCategoriesSimple";
import PriceListPage from "../../pages/PriceList/PriceListPage";

const PriceListContainer = props => {
  const { loading, error, data } = useQuery(
    GET_PRODUCTS_AND_CATEGORIES_SIMPLE,
    {
      fetchPolicy: "cache-and-network"
    }
  );

  if (loading) return <Loader />;
  if (error) return <h1>GraphQL Error: {error}</h1>;

  return <PriceListPage searchData={data} {...props} />;
};

export default PriceListContainer;
