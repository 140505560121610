import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { I18nextProvider } from "react-i18next";
import Notify from "./components/core/shared/Notify";
import { InMemoryCache } from "apollo-cache-inmemory";
import localforage from "localforage";
import ApolloClient from "apollo-client";
import { ApolloProvider } from "@apollo/react-hooks";
import { ApolloLink } from "apollo-link";
import { withClientState } from "apollo-link-state";
import { persistCache } from "apollo-cache-persist";
import { onError } from "apollo-link-error";
import { createUploadLink as CreateUploadLink } from "apollo-upload-client";
import config from "./config/config";
import { defaults, resolvers } from "./apollo-reducers";

import "./index.css";
import i18n from "./i18n/i18nInstance";
import Routing from "./Routing";
import Loader from "./components/core/shared/Loader";
import { startTracking, reportError } from "./ErrorTracking";
import theme from "./theme";
import { SnackbarProvider } from "notistack";
import Notifications from "./hooks/Notifications";
import GoogleAPI from "./hooks/GoogleAPI";

import { setDefaultOptions } from "date-fns";
import { deAT } from "date-fns/locale";

import { isDemoEnviroment as _isDemoEnviroment } from "./config/config";

setDefaultOptions({ locale: deAT });

startTracking();

// Fix issue of __typename preventing update in address fields
// https://github.com/apollographql/apollo-client/issues/1564
// To update, when theres a solution here: https://github.com/apollographql/apollo-feature-requests/issues/6
const stripTypenames = (obj, propToDelete) => {
  for (const property in obj) {
    if (typeof obj[property] === "object" && !(obj[property] instanceof File)) {
      delete obj.property;
      const newData = stripTypenames(obj[property], propToDelete);
      obj[property] = newData;
    } else {
      if (property === propToDelete) {
        delete obj[property];
      }
    }
  }
  return obj;
};

const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
  const isDemoEnviroment = _isDemoEnviroment();
  operation.setContext({
    headers: {
      "X-Is-Demo": isDemoEnviroment
    }
  });
  if (operation.variables) {
    operation.variables = stripTypenames(operation.variables, "__typename");
    return forward ? forward(operation) : null;
  }
});

const errorLink = onError(
  ({ operation, response, graphQLErrors, networkError }) => {
    const { operationName, variables } = operation;
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) =>
        reportError(message, { operationName, variables, response })
      );

    if (networkError) reportError(`[Network error]: ${networkError}`);
  }
);

class Base extends React.PureComponent {
  //check if we already have a selected
  state = {
    client: null,
    loaded: false
  };
  async componentDidMount() {
    const cache = new InMemoryCache();
    const stateLink = withClientState({ resolvers, cache, defaults });
    const client = new ApolloClient({
      link: ApolloLink.from([
        errorLink,
        removeTypenameMiddleware,
        stateLink,
        CreateUploadLink({ uri: config.GQL_URL })
      ]),
      cache,
      defaultOptions: {
        query: {
          fetchPolicy: "cache-and-network"
        }
      }
    });
    try {
      await persistCache({
        cache,
        storage: localforage,
        maxSize: 1048576 //sets to 1MB. If exceeded, persistence stops and starts cold on next launch
      });
    } catch (e) {
      console.error("Error restoring Apollo cache", e);
    }

    this.setState({
      client,
      loaded: true
    });
  }
  render() {
    const { client, loaded } = this.state;
    if (!loaded) return <Loader />;

    return (
      <Suspense fallback={<Loader />}>
        <GoogleAPI />
        <ApolloProvider client={client}>
          <I18nextProvider i18n={i18n} defaultNS={"default"}>
            <MuiThemeProvider theme={theme}>
              <SnackbarProvider>
                <Notifications />
                <CssBaseline />
                <Routing />
                <Notify />
              </SnackbarProvider>
            </MuiThemeProvider>
          </I18nextProvider>
        </ApolloProvider>
      </Suspense>
    );
  }
}

ReactDOM.render(<Base />, document.getElementById("root"));
