import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FileItemNotUploaded from "./FileItemNotUploaded";

const useStyles = makeStyles(theme => ({
  wrap: {
    width: "100%"
  },
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0 0 4px"
  },
  [theme.breakpoints.up("md")]: {
    title: {
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px"
    }
  }
}));

const FileListNotUploaded = ({ headingTitle, files }) => {
  if (!files) {
    return null;
  }
  const classes = useStyles();

  return (
    <div className={classes.wrap}>
      {headingTitle && <div className={classes.title}>{headingTitle}</div>}
      <div className={classes.items}>
        {files.map(file => (
          <FileItemNotUploaded key={file.downloadLink} file={file} />
        ))}
      </div>
    </div>
  );
};

FileList.propTypes = {
  headingTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  files: PropTypes.array.isRequired,
  orderId: PropTypes.string
};

FileList.defaultProps = {
  files: []
};

export default FileListNotUploaded;
