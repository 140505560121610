import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import useMetaloopUrl from "../../hooks/useMetaloopUrl";
import LanguagePicker from "../../components/onboarding/LanguagePicker";
import { LANGUAGES } from "../../utils/constants";

const useStyles = makeStyles(theme => ({
  topContainer: {
    position: "absolute",
    bottom: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    },
    "@media (max-height: 750px) and (max-width: 768px)": {
      position: "initial",
      marginBottom: "20px"
    }
  },
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "400px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)"
    }
  },
  column: {
    flex: 1,
    display: "inline-block",
    width: "auto"
  },
  text: {
    color: "black",
    opacity: 0.6,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    display: "inline-block",
    "& a": {
      color: "black"
    }
  }
}));

function Footer() {
  const classes = useStyles();
  const { metaloopBaseURL } = useMetaloopUrl();
  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState();

  useEffect(() => {
    const languageCode = i18n.services?.languageUtils?.getLanguagePartFromCode(
      i18n.language
    );
    setLanguage(languageCode);
  }, [i18n.language, i18n.services?.languageUtils]);

  const handleChangeLanguage = lang => {
    setLanguage(lang);
    i18n.changeLanguage(lang.toLowerCase());
  };

  const privacyPolicy = `${metaloopBaseURL}/privacy-policy`;
  const termsOfService = `${metaloopBaseURL}/terms-and-conditions`;

  return (
    <div className={classes.topContainer}>
      <div className={classes.container}>
        <div className={classes.column}>
          <LanguagePicker
            current={language}
            available={LANGUAGES}
            direction={"up"}
            onChange={handleChangeLanguage}
            onChangeEnd={() => {}}
          />
        </div>
        <div className={classes.column} style={{ textAlign: "center" }}>
          <Typography variant="body2" className={classes.text}>
            <a href={privacyPolicy} target="_blank" rel="noreferrer">
              {t("Footer_Terms-of-service")}
            </a>
          </Typography>
        </div>
        <div className={classes.column} style={{ textAlign: "right" }}>
          <Typography variant="body2" className={classes.text}>
            <a href={termsOfService} target="_blank" rel="noreferrer">
              {t("Footer_Disclaimer")}
            </a>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Footer;
