import Grid from "@material-ui/core/Grid";
import React from "react";
import Hidden from "@material-ui/core/Hidden";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import CheckIcon from "@material-ui/icons/Check";
import FibberIcon from "@material-ui/icons/FiberManualRecord";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Field, reduxForm, formValueSelector } from "redux-form";
import FormDropzoneCustom from "../core/forms/FormDropzoneCustom";

const styles = theme => ({
  root: {
    minWidth: "100%",
    minHeight: "100%",
    flexGrow: 1
  },
  stepper: {
    padding: "0px",
    paddingTop: "24px",
    background: "#fafafa",
    color: "#ffaa00"
  },
  pending: {
    color: "gray"
  },
  checkIcon: {
    color: "white",
    background: "#ffaa00",
    borderRadius: "50%"
  },
  activeIcon: {
    color: "white",
    border: "8px solid #ffaa00",
    background: "white",
    borderRadius: "50%"
  },
  pendingIcon: {
    color: "white",
    border: "4px solid #E0E0E0",
    background: "white",
    borderRadius: "50%"
  },
  connector: {
    border: "2px solid #ffaa00",
    width: "100%",
    height: "100%"
  },
  wrapper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary
  },
  wrapper1: {
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: ".5em"
  },
  coloredButton: {
    width: "90%",
    margin: "0 auto",
    minHeight: "5em",
    lineHeight: "5em",
    color: "white",
    textAlign: "center",
    background: "#EEA52C",
    cursor: "pointer"
  },
  nonColoredButton: {
    width: "40%",
    margin: "0 auto",
    minHeight: "5em",
    lineHeight: "5em",
    color: "#EEA52C",
    textAlign: "center",
    background: "white",
    marginTop: ".5em",
    cursor: "pointer"
  },
  coloredButtonSmall: {
    width: "80%",
    margin: "0 auto",
    minHeight: "5em",
    lineHeight: "5em",
    color: "white",
    textAlign: "center",
    background: "#EEA52C",
    cursor: "pointer"
  },
  nonColoredButtonSmall: {
    width: "80%",
    margin: "0 auto",
    minHeight: "5em",
    lineHeight: "5em",
    color: "#EEA52C",
    textAlign: "center",
    background: "white",
    marginTop: ".5em",
    cursor: "pointer"
  }
});

const Step3 = ({ back, next, images, classes, onChange, removeImage }) => {
  const currentImage = images.find(image => image.step === 3);

  return (
    <div className={classes.root}>
      <Grid container spacing={24} justify="center" alignItems="center">
        <Grid item xs={12}>
          <Stepper
            connector={<div className={classes.connector} />}
            activeStep={2}
            className={classes.stepper}
          >
            <Step completed className={classes.test} />
            <Step completed>
              <StepLabel icon={<CheckIcon className={classes.completed} />}>
                Ausweis hochladen
              </StepLabel>
            </Step>
            <Step completed>
              <StepLabel
                icon={<FibberIcon className={classes.activeIcon} />}
                className={classes.pending}
              >
                Foto machen
              </StepLabel>
            </Step>
            <Step completed={false}>
              <StepLabel
                icon={<FibberIcon className={classes.pendingIcon} />}
                className={classes.pending}
              >
                Fertig
              </StepLabel>
            </Step>
            <Step completed />
          </Stepper>
        </Grid>
        <Grid item xs={12}>
          <div className={classes.wrapper}>
            <Typography
              variant="title"
              gutterBottom
              align="center"
              className={classes.title}
            >
              Senden Sie uns ein Foto von Ihrem Gesicht
            </Typography>
            <Typography
              variant="caption"
              gutterBottom
              align="center"
              className={classes.title}
            >
              Mit dem Foto zeigen Sie uns, dass Sie die Person auf dem Ausweis
              sind.
            </Typography>
          </div>
          <Field
            name="imageStep3"
            step={3}
            images={images}
            onChange={onChange}
            boldText="Bitten achten Sie darauf, dass Ihr Gesicht gut erkennbar ist"
            component={FormDropzoneCustom}
            removeImage={removeImage}
          />
        </Grid>
        <Hidden mdDown>
          <Grid item xs={12}>
            <div
              onClick={() => {
                if (currentImage) {
                  next();
                }
              }}
              onKeyUp={() => {}}
              className={classes.coloredButton}
            >
              {currentImage
                ? "Mein selbst erstelltes Foto ist deutlich"
                : "Aufnehmen"}
            </div>
            {!currentImage && (
              <div
                onClick={back}
                className={classes.nonColoredButton}
                onKeyUp={() => {}}
              >
                Zurück
              </div>
            )}
            {currentImage && (
              <div
                onClick={() => removeImage(3)}
                className={classes.nonColoredButton}
                onKeyUp={() => {}}
              >
                Ein neues Bild aufnehmen
              </div>
            )}
          </Grid>
        </Hidden>
        <Hidden mdUp>
          <Grid item xs={12}>
            <div
              onClick={() => {
                if (currentImage) {
                  next();
                }
              }}
              onKeyUp={() => {}}
              className={classes.coloredButton}
            >
              {currentImage
                ? "Mein selbst erstelltes Foto ist deutlich"
                : "Aufnehmen"}
            </div>
            <div
              onClick={back}
              className={classes.nonColoredButtonSmall}
              onKeyUp={() => {}}
            >
              BACK
            </div>
          </Grid>
        </Hidden>
      </Grid>
    </div>
  );
};

Step3.propTypes = {
  back: PropTypes.func.isRequired,
  next: PropTypes.func.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      url: PropTypes.string,
      step: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  removeImage: PropTypes.func.isRequired
};

const validate = values => {
  const errors = {};
  const requiredFields = ["imageStep3"];

  requiredFields.forEach(field => {
    if (!values[field] || !values[field].length) {
      errors[field] = "Image is Required";
    }
  });

  return errors;
};

const FORM_NAME = "step3FormValidation";
const selector = formValueSelector(FORM_NAME);

const mapStateToProps = state => ({
  imageStep3: selector(state, "imageStep3")
});

export default connect(mapStateToProps)(
  reduxForm({
    form: FORM_NAME,
    validate
  })(withStyles(styles)(Step3))
);
