import gql from "graphql-tag";

export default gql`
  query getUserRoles($token: String!, $userId: String!, $partnerId: String!) {
    getUserRoles(token: $token, userId: $userId, partnerId: $partnerId) {
      id
      name
      label
      description
      partnerId
    }
  }
`;
