import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const LimitedTextWithMoreButton = ({ text = "", maxCharacters = 100 }) => {
  const [expanded, setExpanded] = useState(false);
  const useStyles = makeStyles(theme => ({
    expandButton: {
      cursor: "pointer",
      color: "rgba(0, 0, 0, 0.6)"
    }
  }));
  const classes = useStyles();
  const { t } = useTranslation();
  if (text === null) return <>-</>;

  if (expanded) {
    return (
      <>
        {text + "..."}

        <Link
          className={classes.expandButton}
          onClick={() => {
            setExpanded(false);
          }}
        >
          {t("Filter_Less")}
        </Link>
      </>
    );
  } else {
    return (
      <>
        <div>
          {text.substring(0, maxCharacters)}{" "}
          {text.length > maxCharacters && "..."}
          {text.length > maxCharacters && (
            <Link
              className={classes.expandButton}
              onClick={() => {
                setExpanded(true);
              }}
            >
              {t("Filter_More")}
            </Link>
          )}
        </div>
      </>
    );
  }
};
export default LimitedTextWithMoreButton;
