import gql from "graphql-tag";

export default gql`
  query OnboardingComponents($token: String!) {
    OnboardingInformationQuery(token: $token) {
      onboardingStep
      onboardingComponents {
        componentName
        fields
        optionals
      }
    }
    OnboardingPersonalInformationQuery(token: $token) {
      firstName
      lastName
      companyRole
      email
      phone
      mobile
    }
    OnboardingCompanyInformationQuery(token: $token) {
      companyName
      streetAddress
      zipCode
      city
      country
    }
    OnboardingTransportInformationQuery(token: $token) {
      companyName
      streetAddress
      zipCode
      city
      country
    }
    OnboardingBillingInformationQuery(token: $token) {
      UID
      IBAN
      name
      email
    }
  }
`;
