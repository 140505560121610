export const ToastTheme = {
  root: {
    "--toastify-toast-width": "400px",
    "--toastify-font-family": "Inter",
    fontFamily: "Inter",
    bottom: "12px",
    right: "32px",
    "& *": {
      fontFamily: "Inter"
    }
  },
  body: {
    padding: 0
  },
  toast: {
    fontFamily: "Inter",
    background: "white",
    boxShadow: "0px 0px 32px 0px rgba(21, 17, 36, 0.16)",
    borderRadius: "0px",
    "border-bottom-left-radius": "4px",
    "border-bottom-right-radius": "4px",
    width: "400px" // 320px
  },
  progressBar: { background: "rgba(82, 245, 151, 1)", height: "2px" },
  message: {
    container: {
      paddingRight: "24px"
    },
    contents: {
      padding: "16px 12px"
    },
    title: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "20px",
      letterSpacing: "0px",
      textAlign: "left",
      color: "rgba(0, 0, 0, 0.87)"
    },
    textElement: {
      fontSize: "13px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.15px",
      textAlign: "left",
      color: "rgba(0, 0, 0, 0.6)",
      marginTop: "4px"
    },
    actionElement: {
      color: "rgba(21, 17, 36, 1)",
      background: "rgba(82, 245, 151, 1)",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "14px",
      letterSpacing: "0.5px",
      textAlign: "center",
      padding: "9px 16px",
      borderRadius: "none",
      marginTop: "12px",
      height: "34px",
      textTransform: "uppercase",
      "&:not(:first-of-type)": {
        border: "1px solid rgba(82, 245, 151, 1)",
        background: "transparent"
      }
    }
  }
};
