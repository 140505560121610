import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

const styles = theme => ({});

const ButtonComponent = props => {
  const { text } = props;

  return (
    <Button {...props} variant="contained" color="primary">
      {text}
    </Button>
  );
};

export default withStyles(styles)(ButtonComponent);
