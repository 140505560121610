import gql from "graphql-tag";

export default gql`
  mutation updateEmailPricingRole(
    $token: String!
    $partnerId: String!
    $pricing: Boolean!
  ) {
    updateEmailPricingRole(
      token: $token
      partnerId: $partnerId
      pricing: $pricing
    ) {
      message
    }
  }
`;
