import React, { useState } from "react";
import CompanyEditing from "./CompanyEditing";
import Contact from "../Contact";
import CompanyInformationCards from "./CompanyInformationCards";
import { useQuery, useMutation } from "@apollo/react-hooks";
import AccountContacts from "../../../queries/AccountContacts";
import Loader from "../../../components/core/shared/Loader";
import useEventTracking from "../../../hooks/useEventTracking";
import SetMainContact from "../../../mutations/SetMainContact";
import { showSuccess } from "../../core/shared/Notify";
import { useTranslation } from "react-i18next";

const CompanyInformationContent = ({ data, token, company }) => {
  const { TrackEvent } = useEventTracking();
  const [companyEditing, setCompanyEditingBase] = useState(false);
  const [contactAddingOrEditing, setContactAddingOrEditingBase] =
    useState(false);
  const [currentContact, setCurrentContact] = useState();
  const [setMainContactMutation] = useMutation(SetMainContact);
  const { t } = useTranslation();

  const setCompanyEditing = (value, isSuccess) => {
    if (value) {
      TrackEvent("Company editing started", {
        accountId: company
      });
    } else if (isSuccess) {
      TrackEvent("Company editing finished", {
        accountId: company
      });
    } else {
      TrackEvent("Company editing canceled", {
        accountId: company
      });
    }
    setCompanyEditingBase(value);
  };

  const setContactAddingOrEditing = (
    value,
    isSuccess = false,
    modeUpdate,
    newContact
  ) => {
    if (value === "add" && !isSuccess) {
      TrackEvent("Contact adding started", {
        accountId: company
      });
    } else if (isSuccess && modeUpdate === "add") {
      TrackEvent("Contact adding finished", {
        accountId: company
      });
    } else if (value === "edit" && !isSuccess) {
      TrackEvent("Contact editing started", {
        accountId: company,
        contact: currentContact
      });
    } else if (isSuccess && modeUpdate === "edit") {
      TrackEvent("Contact editing finished", {
        accountId: company,
        fields: Object.keys(newContact).map(key => {
          if (newContact[key] !== currentContact[key]) {
            return {
              key: key,
              value: newContact[key]
            };
          } else {
            return {
              key: key,
              value: null
            };
          }
        })
      });
    } else {
      if (contactAddingOrEditing === "add") {
        TrackEvent("Contact adding canceled", {
          accountId: company
        });
      }
      if (contactAddingOrEditing === "edit") {
        TrackEvent("Contact editing canceled", {
          accountId: company
        });
      }
    }

    setContactAddingOrEditingBase(value);
  };

  const { data: companyContacts, loading, refetch } = useQuery(
    AccountContacts,
    {
      variables: {
        token: token,
        company: company
      },
      fetchPolicy: "cache-and-network"
    }
  );

  if (loading) return <Loader />;

  const { AccountContacts: contacts } = companyContacts;

  const handleSetMainContact = async contact => {
    await setMainContactMutation({
      variables: { token, companyId: company, contactId: contact._id }
    }).then(() => {
      TrackEvent("Main Contact edited", { contactId: contact._id });
      showSuccess(t("Successfully changed"));
      refetch();
    });
  };

  return (
    <>
      {companyEditing ? (
        <CompanyEditing
          data={data}
          cancel={(isSuccess = false) => setCompanyEditing(false, isSuccess)}
          token={token}
          company={company}
        />
      ) : contactAddingOrEditing ? (
        <Contact
          cancel={(isSuccess = false, modeUpdate, newContact) =>
            setContactAddingOrEditing(false, isSuccess, modeUpdate, newContact)
          }
          type={contactAddingOrEditing}
          token={token}
          company={company}
          contact={currentContact}
        />
      ) : (
        <CompanyInformationCards
          setCompanyEditing={() => setCompanyEditing(true)}
          setContactAddingOrEditing={setContactAddingOrEditing}
          contacts={contacts}
          data={data}
          token={token}
          company={company}
          setCurrentContact={setCurrentContact}
          handleSetMainContact={handleSetMainContact}
        />
      )}
    </>
  );
};

export default CompanyInformationContent;
