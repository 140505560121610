import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { ChatOutlined, Create } from "@material-ui/icons";
import GenericCard from "../../../general/GenericCard";
import { useTranslation } from "react-i18next";
import addNewAccount from "../../../../assets/icons/addNewAccount.png";
import PaymentEditing from "./PaymentEditing";
import { useQuery } from "react-apollo";
import { GET_VAT_DETAILS } from "../../../../queries/AccountVats";
import Loader from "../../../core/shared/Loader";
import { COMPANY_TAX_TYPES } from "./EditVatForm";

const TaxInformationNumbers = ({ onSetMode, mode, token, company }) => {
  const useStyles = makeStyles(theme => ({
    contactsIcon: {
      marginRight: 12
    },
    addContactContainer: {
      margin: "8px 0 12px 0",
      display: "flex",
      gap: 4,
      alignItems: "center",
      justifyContent: "flex-end",
      maxWidth: 480
    },
    padding: {
      padding: "8px 0"
    },
    vatSubtitle: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.60)",
      display: "block",
      textTransform: "capitalize"
    },
    vatList: {
      listStyleType: "none",
      textAlign: "left",
      margin: "0"
    },
    vatListItem: {
      textAlign: "left",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      "&:not(:last-child)": {
        marginBottom: "1px"
      },
      "&:last-child": {
        marginBottom: "8px"
      }
    },
    vatListItemDefault: {
      fontWeight: "700"
    },
    vatCardListItemWrap: {
      display: "inline-flex",
      alignItems: "center"
    }
  }));

  const { data, loading, error } = useQuery(GET_VAT_DETAILS, {
    variables: {
      token: token,
      company: company
    },
    fetchPolicy: "cache-and-network"
  });

  const classes = useStyles();
  const { t } = useTranslation();

  if (loading) return <Loader />;

  if (error) return "Error...";

  const { GetVatsDetails: vatDetails } = data;

  if (mode === "edit_tax_info" || mode === "add_tax_info") {
    return (
      <PaymentEditing
        type={mode}
        vatDetails={vatDetails}
        cancel={() => onSetMode("DEFAULT")}
        token={token}
        company={company}
      />
    );
  }
  if (mode === "DEFAULT") {
    const vatType = COMPANY_TAX_TYPES.find(
      o => o.value === vatDetails.companyTaxType
    );
    return (
      <>
        <GenericCard
          title={<div className={classes.padding}>{t("Payment Details")}</div>}
          menuInHeader={
            <IconButton
              onClick={() => {
                onSetMode("edit_tax_info");
              }}
            >
              <Create color="action" />
            </IconButton>
          }
          content={
            <div className={classes.vatWraps}>
              {vatType && (
                <>
                  <small className={classes.vatSubtitle}>
                    {t("Account_TaxClassification")}
                  </small>
                  <ul className={classes.vatList}>
                    <li className={classes.vatListItem}>{vatType.name}</li>
                  </ul>
                </>
              )}
              <ul className={classes.vatList}>
                <small className={classes.vatSubtitle}>
                  {t("Account_TaxIdentification")}
                </small>{" "}
                {vatDetails.taxIdentificationNumber.length === 0 && (
                  <li className={classes.vatListItem}>
                    {t("PaymentDetails_NoVAT")}
                  </li>
                )}
                {vatDetails.taxIdentificationNumber.map(vat => (
                  <li
                    key={vat.value}
                    className={`${classes.vatListItem} ${
                      vat.default &&
                      vatDetails.taxIdentificationNumber.length > 1
                        ? classes.vatListItemDefault
                        : ""
                    }`}
                  >
                    {vat.value}
                  </li>
                ))}
                {vatDetails.paymentTerms && (
                  <li className={classes.vatListItem}>
                    <div className={classes.vatCardListItemWrap}>
                      <ChatOutlined
                        fontSize="small"
                        className={classes.contactsIcon}
                        color="action"
                      />
                      <span>{vatDetails.paymentTerms}</span>
                    </div>
                  </li>
                )}
              </ul>
              <small className={classes.vatSubtitle}>
                {t("with IBAN number")}
              </small>
              <ul className={classes.vatList}>
                <li className={classes.vatListItem}>
                  {vatDetails.paymentCredentials || t("PaymentDetails_NoIBAN")}
                </li>
              </ul>
            </div>
          }
        />
        <div className={classes.addContactContainer}>
          {t("Add VAT Number")}
          <IconButton
            onClick={() => {
              onSetMode("add_tax_info");
            }}
          >
            <img src={addNewAccount} alt="AddNewAccount" />
          </IconButton>
        </div>
      </>
    );
  }
  return null;
};

export default TaxInformationNumbers;
