import gql from "graphql-tag";

export default gql`
  mutation createuserForBuyerFromToken($token: String!, $password: String!) {
    createuserForBuyerFromToken(token: $token, password: $password) {
      partnerId
      email
      password
      firstName
      lastName
      roles
    }
  }
`;
