import React from "react";
import SliderWithWeightRanges from "./SliderWithWeightRanges";

class FullSliderStep extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      minRange: 0,
      maxRange: ((this.props.weightRanges || []).length || 1) - 1
    };

    this.setRanges = this.setRanges.bind(this);
  }
  setRanges(arrayOfRanges) {
    this.setState({
      minRange: arrayOfRanges[0],
      maxRange: arrayOfRanges[1]
    });

    this.props.onChange(arrayOfRanges);
  }
  render() {
    const { weightRanges } = this.props;
    return (
      <SliderWithWeightRanges
        data={weightRanges.slice(1)}
        setRanges={this.setRanges}
        {...this.state}
      />
    );
  }
}

export default FullSliderStep;
