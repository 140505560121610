import React, { Fragment } from "react";
import UserContext from "../../providers/UserProvider";
import PurchaseTable from "./tables/purchase/Table";
import SaleTable from "./tables/sale/Table";
import EmptySaleTable from "../core/../emptyState/EmptySaleTable";

//we need to show different tables for mobile and desktop
//freaking six in total...
//this is just a switcher component

const TransactionsTable = props => {
  const {
    data,
    refetchQueryVariables,
    transactionType,
    trackOrderExpandedEvent
  } = props;

  const handleExpansion = panel => {
    if (transactionType === "sale") {
      trackOrderExpandedEvent({ order: panel });
    }
  };

  const {
    queryType,
    token,
    isPolling,
    loading,
    rowsPerPageOptions,
    rowsPerPage,
    page,
    onChangePage,
    onChangeRowsPerPage,
    rowCount,
    searchText,
    statuses,
    uploadInitialType
  } = props;
  const isSeller = transactionType === "sale";

  const showLoadingCondition = loading && !isPolling;
  const showNoDataContidion = data.length === 0 && !searchText && isSeller;
  const showCondition = showLoadingCondition || showNoDataContidion;

  return (
    <Fragment>
      <UserContext.Consumer>
        {({ selectedCompany, userData: { UserCompanies } }) => (
          <Fragment>
            {showCondition ? (
              <EmptySaleTable loading={loading} isSeller={isSeller} />
            ) : isSeller ? (
              <SaleTable
                token={token}
                isSeller={isSeller}
                type={queryType}
                data={data}
                loading={loading}
                isPolling={isPolling}
                handleExpansion={handleExpansion}
                rowsPerPageOptions={rowsPerPageOptions}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                rowCount={rowCount}
                queryParamTransactionID={searchText}
                statuses={statuses}
                uploadInitialType={uploadInitialType}
              />
            ) : (
              <PurchaseTable
                type={queryType}
                data={data}
                loading={loading}
                isPolling={isPolling}
                handleExpansion={handleExpansion}
                rowsPerPageOptions={rowsPerPageOptions}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={onChangePage}
                onChangeRowsPerPage={onChangeRowsPerPage}
                rowCount={rowCount}
                token={token}
                refetchQueryVariables={refetchQueryVariables}
                isSeller={isSeller}
                queryParamTransactionID={searchText}
                uploadInitialType={uploadInitialType}
              />
            )}
          </Fragment>
        )}
      </UserContext.Consumer>
    </Fragment>
  );
};

export default TransactionsTable;
