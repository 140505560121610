const PRODUCTION_ORIGINS = [
  "https://www.schrott24.de",
  "https://www.schrott24.at",
  "http://www.schrott24.de",
  "http://www.schrott24.at",
  "https://app.metaloop.com",
  "http://app.metaloop.com",
  "https://demo.metaloop.com",
  "http://demo.metaloop.com"
];

const STAGING_ORIGINS = [
  "https://dev.schrott24.de",
  "https://dev.schrott24.at",
  "http://dev.schrott24.at",
  "http://dev.schrott24.de",
  "https://app-dev.metaloop.com",
  "http://app-dev.metaloop.com"
];

const QA_ORIGINS = [
  "https://qa.schrott24.de",
  "https://qa.schrott24.at",
  "http://qa.schrott24.at",
  "http://qa.schrott24.de",
  "https://app-qa.metaloop.com",
  "http://app-qa.metaloop.com"
];

const ENVIROMENTS = {
  staging: "https://dev-app.scrap24.com/",
  production: "https://app.scrap24.com/",
  development: "http://localhost:3000/",
  qa: "https://qa.scrap24.com/"
  // development: "https://dev-app.scrap24.com/"
};

const DEMO_ORIGINS = ["https://demo.metaloop.com", "http://demo.metaloop.com"];

const METALOOPENVIROMENTS = {
  production: "https://www.metaloop.com",
  development: "http://localhost:8000",
  qa: "https://qa.metaloop.com",
  staging: "https://qa.metaloop.com"
  // development: "https://dev-app.scrap24.com/"
};

export const getEnviroment = () => {
  let env = "development";
  const location = process.env.REACT_APP_ORIGIN || window.location.origin;

  if (PRODUCTION_ORIGINS.includes(location)) env = "production";
  else if (STAGING_ORIGINS.includes(location)) env = "staging";
  else if (QA_ORIGINS.includes(location)) env = "qa";
  return env;
};

const getUrl = () => {
  const env = getEnviroment();
  return ENVIROMENTS[env];
};

export const getMetaloopUrl = lang => {
  const env = getEnviroment();

  if (!lang) return METALOOPENVIROMENTS[env];

  // if lang is de or es
  if (lang.includes("de") || lang.includes("es")) {
    return `${METALOOPENVIROMENTS[env]}/${lang.slice(0, 2)}`;
  }
  // return default
  return METALOOPENVIROMENTS[env];
};

export const isDemoEnviroment = () => {
  const location = window?.location?.origin || null;
  return DEMO_ORIGINS.includes(location) || triggeredDemoEnviromentInQa();
};

//In qa and in local we can simulate demo enviroment
const triggeredDemoEnviromentInQa = () => {
  const env = getEnviroment();
  if (env === "production") return false;

  if (localStorage.getItem("isDemoTriggered") === "true") {
    return true;
  }
  return false;
};

// Move to env
const sentryDSN = "https://362426451f8c4028bd66f1a9a1a7669c@sentry.io/1466218";

const GLOBAL_VARIABLES = {
  GQL_URL: `${getUrl()}graphql`,
  API_BASE_URL: `${getUrl()}`,
  API_AUTH_URL: `${getUrl()}users/login`,
  PRICE_HISTORY_EXCEL: `${getUrl()}api/v1/prices/history`,
  SEND_MAGIC_LINK: `${getUrl()}/api/v1/partner/generateMagicLink`,
  LOG_IN_MAGIC_LINK: `${getUrl()}/api/v1/partner/logInWithMagicLink`,
  LOOKER_AUTH_URL: `${getUrl()}api/looker/auth`,
  SENTRY_DSN: sentryDSN
};
export default GLOBAL_VARIABLES;
