import gql from "graphql-tag";

export default gql`
  mutation OnboardingCompanyInformationMutation(
    $token: String!
    $input: OnboardingCompanyInformationInput!
    $options: OnboardingMutationOptions
  ) {
    OnboardingCompanyInformationMutation(
      token: $token
      input: $input
      options: $options
    ) {
      success
    }
  }
`;
