import React from "react";
import _ from "lodash";
import { withStyles } from "@material-ui/core/styles";
import CategoryList from "./components/category/CategoryList";
import { withApollo } from "react-apollo";
import { ADD_CATEGORIES_TO_BUYER } from "../../../mutations/pricingMutations";
import GET_FULL_PRICING_DATA from "../../../queries/GetFullPricingData";
import { showError, showSuccess } from "../../core/shared/Notify";
import { withTranslation } from "react-i18next";

const styles = theme => ({});

class CategoryPage extends React.Component {
  saveCategoryData = async operation => {
    const {
      client,
      selectedCategories,
      selectedCompany,
      user: { token },
      t
    } = this.props;

    try {
      await client.mutate({
        mutation: ADD_CATEGORIES_TO_BUYER,
        variables: {
          categories: selectedCategories.map(
            ({ id, categoryId, linkedTo, prefferedWeights, accepted }) => ({
              id,
              categoryId,
              linkedTo,
              prefferedWeights,
              accepted
            })
          ),
          buyerId: selectedCompany
        },
        refetchQueries: () => [
          {
            query: GET_FULL_PRICING_DATA,
            variables: { token, buyerId: selectedCompany }
          }
        ],
        awaitRefetchQueries: true
      });

      showSuccess(
        operation === "add" ? t("Category added") : t("Category removed")
      );
    } catch (e) {
      showError(t("Category could not be changed"));

      console.log(e);
    }
  };
  removeCategoryFromAccepted = categoryId => {
    const { selectedCategories, setSelectedCategories } = this.props;

    let category = selectedCategories.find(sc => sc.id === categoryId);
    category.accepted = false;

    setSelectedCategories(selectedCategories);
    this.saveCategoryData("remove");
  };
  addCategoryToAccepted = categoryId => {
    const { selectedCategories, setSelectedCategories } = this.props;

    let category = selectedCategories.find(sc => sc.id === categoryId);
    category.accepted = true;

    setSelectedCategories(selectedCategories);
    this.saveCategoryData("add");
  };
  render() {
    const { selectedCategories, selectedProducts, user } = this.props;
    let categories = _.sortBy(
      _.sortBy(selectedCategories, sc => sc.name).reverse(),
      sc => sc.accepted
    )
      .reverse()
      .filter(sc => sc.name);

    return (
      <CategoryList
        categories={categories}
        products={selectedProducts}
        removeCategoryFromAccepted={this.removeCategoryFromAccepted}
        addCategoryToAccepted={this.addCategoryToAccepted}
        linkCategoryTo={this.linkCategoryTo}
        selectedCompany={this.props.selectedCompany}
        token={user.token}
        category={this.props.category}
      />
    );
  }
}

export default withApollo(withStyles(styles)(withTranslation()(CategoryPage)));
