import gql from "graphql-tag";

export default gql`
  query getUserRoles($token: String!, $partnerId: String!) {
    getAllRoles(token: $token, partnerId: $partnerId) {
      id
      name
      label
    }
  }
`;
