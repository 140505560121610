import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import ImportDataComponent from "../../components/core/shared/ImportDataComponent";
import useIsGroupAdmin from "../../hooks/useIsGroupAdmin";

const ImportPage = props => {
  const location = useLocation();
  const history = useHistory();
  const query = queryString.parse(location.search);
  const searchParams = new URLSearchParams(query);
  const type = searchParams.get("type");
  if (!type)
    history.push({
      pathname: "/sales"
    });

  const isGroupAdmin = useIsGroupAdmin();

  if (!isGroupAdmin)
    history.push({
      pathname: "/sales"
    });

  return <ImportDataComponent type={type} />;
};

ImportPage.defaultProps = {
  queryType: "open"
};

export default ImportPage;
