export const validateEmail = email =>
  String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

export const validateMultipleEmailsString = emails => {
  if (!emails) return false;
  const emailsArray = emails.split(",").map(email => email.trim());
  return (
    emailsArray.length > 1 && emailsArray.every(email => validateEmail(email))
  );
};

export const isObjectEmpty = obj => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop)) {
      return false;
    }
  }
  return true;
};

export const getFilenameAndExtension = filename => {
  const splitedFilename = filename.split(".");
  const extension = splitedFilename.pop();

  return [splitedFilename.join("."), extension];
};

// check file type if it's pdf or images
export const isFileImagePdf = doc => {
  if (!doc.fileType) return false;
  if (!doc.fileType.match(/^image\/(gif|jpe?g|png)$|^application\/(pdf)/i)) {
    return false;
  }
  return true;
};

export const DATE_FORMAT_STRING = "DD/MM/YYYY";
