import React, { useEffect, useState } from "react";
import Phone from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { makeStyles } from "@material-ui/core";
import es from "react-phone-input-2/lang/es.json";
import de from "react-phone-input-2/lang/de.json";
import _ from "lodash";

import "react-phone-input-2/lib/style.css";

const useStyles = makeStyles(theme => ({
  flagDropdown: {
    border: "none !important"
  },
  field: {
    display: "flex",
    flexDirection: "column",
    marginTop: "20px",
    maxWidth: "400px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)"
    }
  },
  label: {
    color: "#383545",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "20px" /* 142.857% */,
    letterSpacing: "0.14px",
    marginBottom: "6px"
  },
  error: {
    // You can extend the error class here if needed
  },
  inputClass: {
    "&:focus": {
      outline: "2px solid #52f597 !important"
    },
    borderRadius: "0 !important",
    width: "100% !important",
    height: "40px",
    lineHeight: "20px !important",
    border: "none !important",
    outline: "1px solid rgba(158, 158, 158, 0.6) !important",
    color: "#212121 !important",
    fontFamily: "Roboto !important",
    fontSize: "16px !important",
    fontStyle: "normal !important",
    fontWeight: "400 !important",
    letterSpacing: "0.15px !important",
    paddingLeft: "80px !important"
  },
  buttonClass: {
    width: "60px",
    borderTop: "0 !important",
    borderLeft: "0 !important",
    borderBottom: "0 !important",
    backgroundColor: "transparent !important",
    "& .selected-flag": {
      display: "flex",
      width: "100%",
      justifyContent: "center",
      padding: "0"
    }
  },
  dropdownClass: {
    borderRadius: "4px !important",
    top: "31px !important",
    left: "0 !important",
    "& li:nth-child(3)": {
      height: "0 !important"
    },
    "& li": {
      height: "40px !important",
      paddingLeft: "16px !important",
      display: "flex !important",
      alignItems: "center !important"
    }
  }
}));

const PhoneField = ({ error, label, value, onChange, hideLabel }) => {
  const { t, i18n } = useTranslation();
  const [defaultCountry, setDefaultCountry] = useState("");
  const classes = useStyles();
  const localizationObj = {
    es: es,
    de: de
  };
  const localization = localizationObj[i18n.language]
    ? { localization: localizationObj[i18n.language] }
    : {};

  useEffect(() => {
    if (value && value !== "") {
      return;
    }
    axios
      .get("https://ipapi.co/json/")
      .then(response => {
        const countryCode = response.data.country_code;

        setDefaultCountry(countryCode.toLowerCase());
      })
      .catch(error => {
        console.error("Error fetching geolocation data", error);
      });
  }, []);

  return (
    <div className={classes.field}>
      {!hideLabel && <label className={classes.label}>{label}</label>}

      <Phone
        country={defaultCountry}
        inputClass={classes.inputClass}
        dropdownClass={classes.dropdownClass}
        buttonClass={classes.buttonClass}
        onChange={(value, country) => {
          onChange(
            `+${
              !value.startsWith(country.dialCode) ? country.dialCode : ""
            }${value}`
          );
        }}
        disableCountryCode={value ? false : true}
        disableCountryGuess={true}
        placeholder={label}
        {...localization}
        value={value}
      />
      {error && (
        <div classes={classes.errorContainer}>
          <span className={classes.error}>{error ? t(error) : ""}</span>
        </div>
      )}
    </div>
  );
};

export default PhoneField;
