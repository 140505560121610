import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { SelectMenuProps } from "../../core/shared/constants";

const PickupsDropdown = ({
  options = [],
  value,
  callback,
  label,
  localization = false,
  disabled = false
}) => {
  const useStyles = makeStyles(theme => ({
    dropdownContainer: {
      width: "240px",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    }
  }));

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl
      variant="outlined"
      className={classes.dropdownContainer}
      disabled={disabled}
    >
      <InputLabel id="all-products-dropdown">{t(label)}</InputLabel>
      <Select
        labelId="all-products-dropdown"
        id="select-products"
        value={value}
        onChange={event => {
          callback(event.target.value);
        }}
        label={t(label)}
        className={classes.selectProduct}
        MenuProps={SelectMenuProps}
      >
        {options.length === 0 || options[0].value !== "all" ? (
          <MenuItem value="all">{t("All")}</MenuItem>
        ) : null}

        {options.map(option => (
          <MenuItem value={option.value} key={option.value}>
            {localization ? t(option.label) : option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default PickupsDropdown;
