import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import ForgotContainer from "../../containers/forgot/Forgot";
import LoginLogo from "../../components/login/LoginLogo";

const styles = theme => ({
  sectionWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "200px 0 50px",
    height: "100vh",
    "@media (max-width: 400px)": {
      padding: "48px 0 24px"
    }
  }
});

const ForgotPage = ({ classes }) => (
  <Grid item className={classes.sectionWrapper}>
    {/* <Logo /> */}
    <LoginLogo />
    <ForgotContainer />
  </Grid>
);

ForgotPage.defaultProps = {
  classes: {}
};

ForgotPage.propTypes = {
  classes: PropTypes.object
};

export default withStyles(styles)(ForgotPage);
