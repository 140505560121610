import React from "react";

import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  makeStyles,
  Box
} from "@material-ui/core";

export default ({
  title,
  subtitle,
  image,
  content,
  actions,
  menuInHeader,
  extraClasses,
  darkHeader
}) => {
  const useStyles = makeStyles(theme => ({
    titleContainer: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      letterSpacing: "0.25px",
      "text-transform": "capitalize"
    },
    card: {
      border: "none",
      boxShadow: "none",
      maxWidth: "480px",
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
      }
    },
    title: {
      borderBottom: "1px solid #E0E0E0",
      padding: "8px 8px 8px 20px",
      borderRadius: "4px 4px 0 0",
      background: darkHeader && theme.palette.primary.main,
      color: darkHeader && theme.palette.background.default
    },
    titleTypography: {
      fontSize: "16px",
      fontWeight: 500,
      lineHeight: "24px"
    },
    box: {
      backgroundColor: "#F9F9F9",
      height: "100%",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "4px"
    },
    image: {},
    content: {
      padding: "16px 20px 16px 20px",
      fontSize: "14px",
      lineHeight: "20px",
      fontWeight: 400,
      fontFamily: "Roboto",

      "&:last-child": {
        paddingBottom: "16px"
      }
    }
  }));

  const classes = useStyles();

  return (
    <Card classes={{ root: classes.card }}>
      <Box classes={{ root: classes.box }}>
        {(title || menuInHeader) && (
          <CardHeader
            title={
              <div className={classes.titleContainer}>
                {title ? title : ""}
                {menuInHeader ? menuInHeader : ""}
              </div>
            }
            classes={{ root: classes.title, title: classes.titleTypography }}
          />
        )}

        {image && (
          <CardMedia
            classes={{ root: classes.title }}
            component="img"
            height="194"
            image={image}
            alt={title}
          />
        )}
        {content && (
          <CardContent
            classes={{
              root: `${classes.content} ${
                extraClasses !== undefined ? extraClasses.content : ""
              } `
            }}
          >
            {content}
          </CardContent>
        )}
      </Box>
      {actions && <CardActions>{actions}</CardActions>}
    </Card>
  );
};
