import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import LocationsSelect from "./LocationsSelect";
import MaterialsChooser from "./MaterialsChooser";
import ContractsSelect from "./ContractsSelect";

const useStyles = makeStyles(theme => ({
  tableRoot: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    background: "#F9F9F9",
    "box-sizing": "border-box",
    "border-radius": "4px",
    "overflow-x": "auto",
    width: "90%"
  },
  tableHeader: {
    padding: "16px 8px",
    "border-bottom": "2px solid #E0E0E0",
    alignItems: "start",
    color: "black",
    fontSize: "14px",
    fontWeight: "500",
    "vertical-align": "middle"
  },
  row: {
    padding: " 16px 8px",
    fontSize: "16px"
  }
}));

const PairingTool = ({
  columnHeaders,
  rows,
  options,
  objectPaired,
  setObjectPaired,
  type
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const Component =
    type === "location"
      ? LocationsSelect
      : type === "contracts"
        ? ContractsSelect
        : MaterialsChooser;
  return (
    <table className={classes.tableRoot}>
      <tr>
        {columnHeaders.map((header, index) => (
          <th key={index} className={classes.tableHeader} style={header.style}>
            {t(header.name)}
          </th>
        ))}
      </tr>
      {Object.keys(rows).map(
        (row, indexRow) =>
          (rows[row] === null ||
            (rows[row]?.suggestion && !rows[row]?.suggestionAccepted)) && (
            <>
              <tr key={indexRow}>
                <td key={`${indexRow}_0}`} className={`${classes.row} `}>
                  {row}{" "}
                </td>
                <td>
                  <Component
                    options={options}
                    row={row}
                    objectPaired={objectPaired}
                    setObjectPaired={setObjectPaired}
                  />
                </td>
              </tr>
            </>
          )
      )}
    </table>
  );
};

export default PairingTool;
