import React, { useContext, useState } from "react";
import OnboardingForm from "../../components/onboarding/OnboardingForm";
import PhoneField from "../../components/onboarding/PhoneField";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Select, MenuItem, InputLabel, FormControl } from "@material-ui/core";
import { roleOptions } from "../../utils/constants";
import { useMutation } from "react-apollo";
import OnboardingPersonalInformationMutation from "../../mutations/OnboardingPersonalInformationMutation";
import Loader from "../../components/core/shared/Loader";
import _ from "lodash";
import { ONBOARDING_PERSONAL_INFORMATION } from "../../utils/constants";
import { getInputForSaveMutation } from "../../utils/Onboarding";
import useEventTracking from "../../hooks/useEventTracking";
import { SelectMenuProps } from "../../components/core/shared/constants";

const useStyles = makeStyles(theme => ({
  inputText: {
    marginTop: "20px",
    width: "400px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px)"
    }
  }
}));

const PersonalInformation = ({
  formData,
  setFormData,
  setNextStep,
  setPreviousStep,
  token,
  componentProperties,
  trackClickNextEvent
}) => {
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const [loadingMutation, setLoadingMutation] = useState(false);

  const [sendDataMutation] = useMutation(OnboardingPersonalInformationMutation);

  const header = t("onboarding_personal_header");
  const subheader = t("onboarding_personal_subheader");

  const fields = [
    {
      label: t("First Name"),
      type: "text",
      value: formData.firstName,
      name: "firstName"
    },
    {
      label: t("Last Name"),
      type: "text",
      value: formData.lastName,
      name: "lastName"
    },
    {
      specialComponent: (
        <RolesDropdown formData={formData} setFormData={setFormData} />
      ),
      name: "companyRole"
    },
    {
      label: t("Email"),
      type: "text",
      value: formData.email,
      name: "email"
    },
    {
      specialComponent: (
        <WorkPhone formData={formData} setFormData={setFormData} />
      ),
      name: "phone"
    },
    {
      specialComponent: (
        <MobileNumber formData={formData} setFormData={setFormData} />
      ),
      name: "mobile"
    }
  ];

  if (componentProperties.fields) {
    fields.forEach((field, index) => {
      if (!componentProperties.fields.includes(field.name)) {
        fields.splice(index, 1);
      }
    });
  }

  const requiredFields = JSON.parse(
    JSON.stringify(componentProperties.fields)
  ).filter(field => !componentProperties.optionals.includes(field));

  const handleNextStep = async () => {
    trackClickNextEvent();
    setLoadingMutation(true);

    const input = getInputForSaveMutation(
      ONBOARDING_PERSONAL_INFORMATION,
      formData
    );

    await sendDataMutation({ variables: { token, input } });

    setNextStep();
    setLoadingMutation(false);
  };

  if (loadingMutation) return <Loader />;

  return (
    <OnboardingForm
      formData={formData}
      setFormData={setFormData}
      header={header}
      subheader={subheader}
      fields={fields}
      requiredFields={requiredFields}
      setNextStep={handleNextStep}
      setPreviousStep={setPreviousStep}
      hideBackButton={true}
    />
  );
};

const RolesDropdown = ({ formData, setFormData }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <FormControl variant="outlined" className={classes.inputText}>
        <InputLabel id="companyRoleLabel">
          {t("onboarding_field_company_role")}
        </InputLabel>
        <Select
          id="Company Role"
          label={t("onboarding_field_company_role")}
          value={formData.companyRole}
          onChange={event => {
            setFormData({ ...formData, companyRole: event.target.value });
          }}
          name="companyRole"
          MenuProps={SelectMenuProps}
        >
          {roleOptions.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {t(item.name)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

const WorkPhone = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  if (_.isEmpty(formData)) return null;
  return (
    <PhoneField
      label={t("onboarding_field_work_phone")}
      required={true}
      value={formData.phone}
      onChange={value => {
        if (value) setFormData({ ...formData, phone: value });
      }}
      hideLabel={true}
    />
  );
};

const MobileNumber = ({ formData, setFormData }) => {
  const { t } = useTranslation();
  if (_.isEmpty(formData)) return null;

  return (
    <PhoneField
      label={t("Mobile Number")}
      value={formData.mobile}
      onChange={value => {
        if (value) setFormData({ ...formData, mobile: value });
      }}
      hideLabel={true}
    />
  );
};

export default PersonalInformation;
