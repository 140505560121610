import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";

import LoginLogo from "../../components/login/LoginLogo";
import ResetPasswordContainer from "../../containers/forgot/Reset";
import Title from "../../components/login/Title";

const styles = {
  container: {
    width: "100vw",
    height: "100vh"
  },
  fullHeight: {
    margin: 0,
    height: "100vh",
    overflow: "hidden"
  },
  sectionWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "200px 0 50px",
    // justifyContent: "center",
    height: "100vh",

    "@media (max-width: 400px)": {
      // justifyContent: "end",
      padding: "48px 0 24px"
    }
  },
  formWrapper: {
    marginTop: "0",
    width: "400px",

    "@media (max-width: 400px)": {
      width: "100%",
      padding: "0 15px"
    }
  },
  title: {
    marginTop: "20px",
    marginBottom: "32px",
    fontSize: "48px",
    lineHeight: "56px",
    "@media (max-width: 400px)": {
      fontSize: "28px",
      lineHeight: "36px",
      marginTop: "16px",
      marginBottom: "24px"
    }
  }
};

const ResetPage = ({ match, history, classes }) => {
  if (match && match.params && !match.params.token) history.push("/forgot");

  return (
    <Grid container className={classes.fullHeight}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid item className={classes.sectionWrapper}>
          <LoginLogo />

          <Title text={"Reset Password"} extraClasses={classes.title} />

          <Grid item className={classes.formWrapper}>
            <ResetPasswordContainer
              token={match.params.token}
              history={history}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ResetPage.defaultProps = {
  match: {},
  classes: {},
  history: {}
};

ResetPage.propTypes = {
  match: PropTypes.object,
  history: PropTypes.object,
  classes: PropTypes.object
};

export default withStyles(styles)(ResetPage);
