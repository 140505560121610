import useCompany from "./useCompany";
import useUserData from "./useUserData";
import { TrackEvent as OriginalTrackEvent } from "../modules/segment";
import { isDemoEnviroment } from "../config/config";
import { loadStateKey } from "../actions/DataPersistence";

const useEventTracking = () => {
  const user = useUserData();
  const company = useCompany();
  const accountId = company?.selectedCompany;
  const email = user?.email;
  const companyName = company?.name;
  const userRoles = company?.companyRoles;
  const isDemo = isDemoEnviroment();
  const stateUser = loadStateKey("s24-user", "user");
  const impersonating = stateUser?.impersonating;

  const TrackEvent = (eventName, eventProperties = {}) => {
    if (impersonating) {
      return;
    }
    OriginalTrackEvent(eventName, {
      ...eventProperties,
      accountId,
      email,
      companyName,
      userRoles,
      ...(isDemo ? { isDemo: true } : {})
    });
  };

  return {
    TrackEvent
  };
};

export default useEventTracking;
