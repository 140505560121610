/* eslint-disable no-unused-vars */

import thunk from "redux-thunk";
import logger from "redux-logger";
import { reducer as form } from "redux-form";
import { createStore, combineReducers, applyMiddleware } from "redux";
import userReducer from "../src/reducers/auth";
import { saveState } from "../src/actions/DataPersistence";

const DEBUG = false;

const rootReducer = combineReducers({
  form,
  user: userReducer
});

let middlewares = [thunk];

if (process.env.NODE_ENV !== "production" && DEBUG)
  middlewares = [...middlewares, logger];

const store = createStore(rootReducer, {}, applyMiddleware(...middlewares));

store.subscribe(() => {
  const state = store.getState();
  const { user } = state;
  saveState("s24-user", { user });
});

export default store;
