import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import Button from "../../formElements/Button";
import { withTranslation } from "react-i18next";
import FullSliderStep from "../../slider/FullSliderStep";

const styles = theme => ({
  container: {
    margin: "25px 0"
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  buttons: {
    marginTop: "2em",
    justifyContent: "space-evenly"
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "rgba(95,103,117,1)"
  },
  sliderItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(95,103,117,1)",
    margin: "50px 0"
  },
  itemButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(95,103,117,1)",
    padding: "10px 5px"
  },
  text: {
    marginBottom: "2em",
    textAlign: "center",
    width: "100%"
  }
});

class Weight extends React.Component {
  render() {
    const { classes, t, onSubmit, data, onNext, onBack } = this.props;

    return (
      <form
        onSubmit={ev => {
          ev.preventDefault();
          onSubmit(ev);
        }}
        className={classes.wrapper}
      >
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.item}>
            <Typography align="center" variant="body1" className={classes.text}>
              {t(
                "Was ist das kleinste Transportvolumen bei möchten Sie ankaufen?"
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.sliderItem}>
            <FullSliderStep weightRanges={data} onChange={onSubmit} />
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={classes.buttons}
            justify="center"
          >
            <Button
              className={classes.button}
              text={"< " + t("Züruck")}
              onClick={onBack}
            />
            <Button
              className={classes.button}
              text={t("Weiter") + " >"}
              onClick={onNext}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withRouter(withStyles(styles)(withTranslation()(Weight)));
