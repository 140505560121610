/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import { showError } from "../components/core/shared/Notify";
import Logo from "../components/core/Logo";
import { logoutUser } from "./../actions/auth";
import { setUrlToRedirect } from "./../utils/Redirection";

class RegisterPage extends React.Component {
  componentDidMount() {
    const { history } = this.props;
    setUrlToRedirect(history.location.pathname);
    showError("Not Authorized. Please login.");
    logoutUser();
    history.push("/login");
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container>
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          md={12}
          className={classes.logoWrapper}
        >
          <Logo />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          lg={12}
          md={12}
          className={classes.titleWrapper}
        >
          <h1 className={classes.title}>Not Authorized.</h1>
        </Grid>
      </Grid>
    );
  }
}

RegisterPage.defaultProps = {
  classes: {},
  history: {}
};

RegisterPage.propTypes = {
  classes: PropTypes.object,
  history: PropTypes.object
};

export default RegisterPage;
