import gql from "graphql-tag";

export default gql`
  query SearchMaterials(
    $token: String!
    $partnerId: String!
    $text: String!
    $language: String
    $limit: Int
  ) {
    SearchMaterials(
      token: $token
      partnerId: $partnerId
      text: $text
      language: $language
      limit: $limit
    ) {
      id
      alias
      aliasId
      internalName
      baseMetalName
      alloyName
      formName
      surfaceName
      isRetail
      isPro
      extra_alias
      image {
        imageId
        productId
        productName
      }
    }
  }
`;
