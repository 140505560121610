import React from "react";
import {
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  Paper,
  IconButton,
  makeStyles
} from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  flexContainer: {
    display: "flex",
    alignItems: "center",
    maxWidth: "196px",
    gap: "6px"
  },
  customTextField: {
    cursor: "pointer",
    padding: 0,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "150%",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
    marginLeft: "10px"
  },
  selected: {
    backgroundColor: theme.palette.action.selected
  },
  customDropdown: {
    position: "relative"
  },
  popper: {
    zIndex: 1000,
    width: "272px",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 5px 5px -3px, rgba(0, 0, 0, 0.14) 0px 8px 10px 1px, rgba(0, 0, 0, 0.12) 0px 3px 14px 2px",
    "& .MuiPaper-root": {
      boxShadow: "none !important"
    }
  },
  paper: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    zIndex: "1",
    borderRadius: "4px"
  },
  menuList: {
    backgroundColor: "white",
    borderRadius: "4px"
  }
}));

const CustomDropdown = ({
  month,
  year,
  onYearNavigate,
  marker,
  options = Array.from(
    { length: 6 },
    (_, i) => new Date().getFullYear() - 1 + i
  )
}) => {
  const [open, setOpen] = React.useState(false);

  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event?.target)) {
      return;
    }
    setOpen(false);
  };

  const handleMenuItemClick = option => {
    onYearNavigate(marker, option);
    handleClose();
  };

  const classes = useStyles();

  const handleIconClick = event => {
    event.stopPropagation();
    handleToggle();
  };

  return (
    <div className={classes.customDropdown}>
      <div className={classes.flexContainer}>
        <span
          readOnly
          onClick={handleToggle}
          className={classes.customTextField}
          ref={anchorRef}
        >
          {month + " " + year}
        </span>

        <IconButton onClick={handleIconClick} style={{ padding: 0 }}>
          {open ? <ArrowDropUp /> : <ArrowDropDown />}
        </IconButton>
      </div>
      <Popper
        className={classes.popper}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-start"
      >
        <Paper className={classes.paper}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              autoFocusItem={open}
              id="menu-list-grow"
              className={classes.menuList}
            >
              {options.map(option => (
                <MenuItem
                  key={option}
                  onClick={() => handleMenuItemClick(option)}
                  className={option === year ? classes.selected : ""}
                >
                  {option}
                </MenuItem>
              ))}
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
    </div>
  );
};

export default CustomDropdown;
