import React from "react";
import { makeStyles } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import Status from "../../../general/Status";

const useStyles = makeStyles(theme => ({
  statusTitle: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0 0 4px"
  },
  [theme.breakpoints.up("md")]: {
    statusClassName: {
      "& .status_root:first-child": {
        paddingLeft: "0"
      },
      "& .status_root:last-child": {
        paddingRight: "0"
      }
    }
  }
}));

const PickUpStatusBars = ({ item, className, disabled = false }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const statusData = item.status || {};

  const statusElements = [
    {
      title: "1." + t("Pickup"),
      position: 1,
      text: `${t(statusData.text)} ${
        statusData.period
          ? `[${statusData.t ? t(statusData.period) : statusData.period}]`
          : ""
      }`
    },
    {
      title: "2." + t("Status_Transport"),
      position: 2,
      text: t("Pickups_Organisation")
    },
    { title: "3." + t("Status_Transport"), position: 3, text: t("Documents") },
    { title: "4." + t("Status_Transport"), position: 4, text: t("In transit") },
    {
      title: "5." + t("Status_Transport"),
      position: 5,
      text: t("Sales_Delivered")
    }
  ];
  return (
    <div className={className}>
      <p className={classes.statusTitle}>{t("Pick up status")}</p>
      <div className={classes.statuses}>
        <Status
          className={classes.statusClassName}
          currentIndex={item.statusId}
          statusElements={statusElements}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default PickUpStatusBars;
