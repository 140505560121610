/* eslint-disable jsx-a11y/anchor-is-valid */
import { withTranslation } from "react-i18next";
import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import LoginButton from "../../components/login/button/LoginButton";
import { Field, reduxForm } from "redux-form";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import { IconButton } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import Error from "../login/Error";
import LoginInput from "../login/LoginInput";
const styles = theme => ({
  grid: {
    width: "100%",
    marginTop: "10px"
  },
  loginLink: {
    display: "flex",
    textDecoration: "none",
    justifyContent: "center",
    textAlign: "center",
    letterSpacing: "0.25px",
    color: "#276EF1"
  },
  passwordGrid: {
    position: "relative",
    marginTop: "20px"
  },
  error: {
    color: "#E11900",
    display: "block",
    fontWeight: "normal",
    marginTop: "12px"
  },
  button: {
    marginTop: "20px"
  },
  actionsGrid: {
    width: "100%",
    display: "flex",
    marginTop: "26px",
    flexDirection: "column",
    "& >:last-child": {
      marginTop: "4px"
    },

    "@media (max-width: 400px)": {
      marginTop: "16px"
    }
  },
  dontYouHaveAccountText: {
    marginRight: "5px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  redBorder: {
    borderColor: "green"
  },
  textField: {
    height: "40px",
    marginBottom: "10px",
    "& .MuiInputBase-input": {
      height: "40px",
      backgroundColor: "white!important",
      paddingTop: "0",
      paddingBottom: "0"
    },

    "& .MuiFormLabel-root.Mui-error": {
      color: "#E11900"
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E11900"
    },

    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)"
    },

    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 12px) scale(1)"
    },
    "& .MuiOutlinedInput-root": {
      height: "56x"
    }
  },
  formInput: {
    background: "white!important"
  },
  keepSignInButton: {
    marginTop: "8px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  noPaddingRight: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0"
    }
  }
});

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPassword: false,
      error: "",
      checkErrors: true
    };

    this.togglePassword = this.togglePassword.bind(this);
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handleKeepSignIn = this.handleKeepSignIn.bind(this);
    this.componentDidUpdate = this.componentDidUpdate.bind(this);
    this.onClick = this.onClick.bind(this);
  }

  togglePassword() {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }
  handlePasswordChange(event) {
    this.setState({
      password: event.target.value,
      error: ""
    });
  }
  handleEmailChange(event) {
    this.setState({
      email: event.target.value,
      error: ""
    });
  }

  handleKeepSignIn(event) {
    this.setState({
      keepSignIn: event.target.checked
    });
  }

  onClick() {
    this.setState({
      checkErrors: true
    });
  }
  componentDidUpdate(prevState, prevProps) {
    if (
      this.props.loginErrorMessage &&
      this.props.loginErrorMessage.length &&
      this.state.error !== prevProps.loginErrorMessage &&
      this.state.checkErrors
    ) {
      this.setState({
        error: this.props.loginErrorMessage,
        checkErrors: false
      });
    }
  }

  render() {
    const { classes, handleSubmit, t } = this.props;
    const { showPassword, email, keepSignIn } = this.state;

    return (
      <form onSubmit={handleSubmit} noValidate>
        <Grid item className={classes.grid}>
          <Field
            name="email"
            type="email"
            label={t("Email")}
            onChange={this.handleEmailChange}
            value={email}
            component={LoginInput}
            classes={classes}
            t={t}
            flagAsError={this.state.error.length !== 0}
          />
        </Grid>
        <Grid item className={classnames(classes.grid, classes.passwordGrid)}>
          <Field
            name="password"
            label={t("Password")}
            onChange={this.handlePasswordChange}
            type={showPassword ? "text" : "password"}
            value={email}
            component={LoginInput}
            classes={classes}
            className={classes.noPaddingRight}
            t={t}
            flagAsError={this.state.error.length !== 0}
            InputProps={{
              endAdornment: (
                <IconButton
                  className={classes.iconButton}
                  onClick={this.togglePassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              )
            }}
          />
        </Grid>

        {this.state.error ? (
          <Typography
            align="left"
            variant="caption"
            className={classes.error}
            gutterBottom
          >
            {t(this.state.error)}
          </Typography>
        ) : (
          <>
            <Field name="email" component={Error} alternativeError={true} />

            <Field name="password" component={Error} alternativeError={true} />
          </>
        )}

        <LoginButton
          className={classes.button}
          variant="contained"
          color="primary"
          type="submit"
          text={t("Einloggen")}
          onClick={this.onClick}
          fullWidth
        />

        {/* <FormControlLabel
          className={classes.keepSignInButton}
          control={
            <Checkbox 
              checked={keepSignIn} 
              onChange={this.handleKeepSignIn} 
              name="keepSignIn" 
            />
          }
          label="Keep me signed in"
        /> */}

        <Grid item className={classes.actionsGrid}>
          <Link
            className={classes.loginLink}
            to={`/forgot${email ? "?email=" + email : ""}`}
            key="Passwort vergessen"
          >
            <Typography align="center" variant="body2">
              {t("Forgot your password?")}
            </Typography>
          </Link>
          {/*Commented because ticket GSD-2347*/}
          {/* <Link
            className={classes.loginLink}
            to={`/register${email ? "?email=" + email : ""}`}
            key="Register Seller"
          >
            <Typography
              align="center"
              variant="body2"
              className={classes.dontYouHaveAccountText}
            >
              {" "}
              {t("Don't have an account?")}
            </Typography>
            <Typography align="center" variant="body2">
              {" "}
              {t("Create account")}
            </Typography>
          </Link> */}
        </Grid>
      </form>
    );
  }
}

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const isItValidEmail = function (email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};

const validate = values => {
  const errors = {};

  // Is email empty?
  if (!values["email"] || !values["email"].length) {
    errors["email"] = "Please enter your email";
    return errors;
  }
  // Is email valid?
  if (!isItValidEmail(values["email"])) {
    errors["email"] = "Please enter a valid email address";
    return errors;
  }

  if (!values["password"] || !values["password"].length) {
    errors["password"] = "Please enter your password";
    return errors;
  }
};

LoginForm.defaultProps = {
  classes: {},
  loginErrorMessage: ""
};

LoginForm.propTypes = {
  classes: PropTypes.object,
  loginErrorMessage: PropTypes.string
};

export default reduxForm({
  form: "loginForm",

  validate
})(withStyles(styles)(withTranslation()(LoginForm)));
