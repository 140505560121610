import gql from "graphql-tag";

export default gql`
  query GetPickupsData(
    $token: String!
    $partnerId: String!
    $status: String
    $productId: String
    $localizationId: String
    $searchTransactionId: String
    $startDate: String
    $endDate: String
    $date: String
    $limitedToFour: Boolean
    $showExpandedFirst: Boolean
    $expanded: [String]
  ) {
    PickupsData(
      token: $token
      partnerId: $partnerId
      status: $status
      productId: $productId
      localizationId: $localizationId
      searchTransactionId: $searchTransactionId
      startDate: $startDate
      endDate: $endDate
      date: $date
      limitedToFour: $limitedToFour
      showExpandedFirst: $showExpandedFirst
      expanded: $expanded
    ) {
      pickupData {
        _id
        productId
        transactionId
        pickupName
        companyName
        streetAddress
        zipCode
        city
        country
        pickupDate
        pickupOrderDate
        quantity
        items
        truckType
        truckNumberPlate
        imageSrc
        isContracted
        files
        packaging
        collectionType
        collectionDate
        collectionDateRange
        statusId
        status
        canConfirmPickup
        canReschedulePickup
        slaLevel
        documents {
          id
          documentType
          downloadLink
          documentName
          uploadedByMetaloop
          fileType
        }
        new
        aliasForLanguage
        accountId
      }
      filters
      initialSortedDate
      finalSortedDate
    }
    PickupsDataCount(token: $token, partnerId: $partnerId) {
      all
      pending
      scheduled
    }
  }
`;
