import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";

const styles = theme => ({
  centerAlignment: {
    textAlign: "center"
  }
});

class NotForMobilePage extends React.Component {
  render() {
    const { t, classes } = this.props;

    return <h1 className={classes.centerAlignment}>{t("NOT_FOR_MOBILE")}</h1>;
  }
}

export default withStyles(styles)(withTranslation()(NotForMobilePage));
