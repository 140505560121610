import React, { useState } from "react";
import InfoNavBarContext from "../../../providers/InfoNavBarProvider";

const InfoNavBar = ({ children }) => {
  const [openOffers, setOpenOffers] = useState(0);
  const [submittedOffers, setSubmittedOffers] = useState(0);

  const [soldTransactions, setSoldTransactions] = useState(0);
  const [transportTransactions, setTransportTransactions] = useState(0);
  const [openTransactions, setOpenTransactions] = useState(0);
  const [closedTransactions, setClosedTransactions] = useState(0);

  const [allPickups, setAllPickups] = useState(0);
  const [pendingPickups, setPendingPickups] = useState(0);
  const [scheduledPickups, setScheduledPickups] = useState(0);

  const values = {
    openOffers,
    setOpenOffers,
    submittedOffers,
    setSubmittedOffers,
    soldTransactions,
    setSoldTransactions,
    transportTransactions,
    setTransportTransactions,
    openTransactions,
    setOpenTransactions,
    closedTransactions,
    setClosedTransactions,
    allPickups,
    setAllPickups,
    pendingPickups,
    setPendingPickups,
    scheduledPickups,
    setScheduledPickups
  };

  return (
    <InfoNavBarContext.Provider value={values}>
      {children}
    </InfoNavBarContext.Provider>
  );
};

export default InfoNavBar;
