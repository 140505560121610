import React, { useCallback, useRef } from "react";
import {
  Button,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import FileList from "../../components/core/shared/uploadDialog/components/FileList";

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  headerContainer: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      minHeight: "56px",
      display: "flex",
      alignItems: "center",
      marginTop: "0px"
    }
  },
  headerRoot: {
    width: "100%",
    paddingTop: "48px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "8px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      position: "initial",
      padding: "0"
    },
    "&.advanced": {
      paddingTop: "48px",
      paddingBottom: "0"
    },
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "10px",
        padding: "0"
      },
      display: "flex",
      gap: "10px"
    },
    "& .MuiIconButton-root": {
      [theme.breakpoints.up("md")]: {
        right: "1px"
      },
      [theme.breakpoints.down("sm")]: {
        left: "1px",
        position: "initial"
      },
      position: "absolute",
      top: "1px"
    }
  },
  fileSelection: {
    margin: "0 -24px"
  },
  root: {
    [theme.breakpoints.up("md")]: {
      width: "640px"
    }
  },
  textfield: {
    marginBottom: "20px"
  },
  closeButton: {
    marginRight: "16px"
  }
}));

export default function ThirdStep({
  files,
  closingModal,
  finishClikced,
  setFinishClicked,
  driverTokenValidity,
  TrackEvent
}) {
  const classes = useStyles();
  const commentRef = useRef();

  const handleFinishClick = useCallback(() => {
    setFinishClicked(true);
    const comment =
      commentRef.current && commentRef.current.value
        ? commentRef.current.value
        : "";
    closingModal(comment);
  }, [closingModal, setFinishClicked, files, driverTokenValidity, TrackEvent]);

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.headerRoot}>
          <IconButton
            aria-label="delete"
            onClick={() => closingModal("closeTab")}
            className={classes.closeButton}
          >
            <ClearIcon />
          </IconButton>
          <Typography variant="h2" gutterBottom>
            {"Upload Weight Note"}
          </Typography>
        </div>
      </div>
      <DialogContent className={classes.root}>
        <DialogContentText>
          {"The files you selected were uploaded successfully."}
          <br />
          {"Do you have any comments to add to this transaction?"}
          <br />
          {"Thank you!"}
          <br />
        </DialogContentText>
        <TextField
          inputRef={commentRef}
          className={classes.textfield}
          label={"Add comment"}
          variant="outlined"
          fullWidth={true}
          multiline={true}
          minRows={4}
          disabled={finishClikced}
        />
        <FileList
          files={files}
          uploading={true}
          className={classes.fileSelection}
          isDriver={true}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          variant="contained"
          onClick={handleFinishClick}
          disabled={finishClikced}
          classes={{ root: classes.buttonRoot }}
        >
          {"FINISH"}
        </Button>
      </DialogActions>
    </>
  );
}
