import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import FormControl from "@material-ui/core/FormControl";
import ItemPriceCard from "../../../card/ItemPriceCard";
import { Hidden } from "@material-ui/core";
import Legend from "../../../card/Legend";
import { showError, showSuccess } from "../../../../core/shared/Notify";
import "./CategoryProducts.css";
import {
  onChangeItem,
  onChangeReference,
  onChangeLink
} from "../../../steps/helpers";
import {
  ADD_CATEGORIES_TO_BUYER,
  SAVE_ONE_PRICE_FOR_BUYER,
  SAVE_ONE_CATEGORY_TO_BUYER
} from "../../../../../mutations/pricingMutations";
import GET_FULL_PRICING_DATA from "../../../../../queries/GetFullPricingData";
import CategoryProductHeader from "./CategoryProductHeader";
import { withWizard } from "../../../../../providers/WizardProvider";
import { withStyles } from "@material-ui/core/styles";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { withApollo } from "react-apollo";
import CategoryProductsMobile from "./CategoryProductsMobile";
import withEventTracking from "../../../../../hooks/withEventTracking";

import CustomList from "../../../../../components/general/List";
import queryString from "query-string";

const styles = theme => ({
  wrapper: {
    background: "white"
  },
  categoryWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  productWrapper: {
    width: "calc(100% - 64px)",
    marginBottom: "24px",
    "&:first-child": {
      marginTop: "28px"
    }
  },
  categoryTitleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
    marginLeft: "50px",
    marginRight: "50px",
    marginTop: "50px",
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 10px"
  },
  categoryTitleSelectWrapper: {
    display: "flex"
  },
  title: {
    marginTop: "20px",
    paddingLeft: "50px",
    color: "grey"
  },
  subtitle: {
    paddingLeft: "50px",
    color: "grey"
  },
  pullRight: {
    color: theme.palette.primary.main,
    marginLeft: "20px",
    textTransform: "uppercase",
    marginTop: "-32px"
  },
  titleWithLink: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  topContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  selectContainer: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: "32px",
    minWidth: "240px"
  },
  selectLabelContainer: {
    marginTop: "24px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  selectLabel: {
    background: "#ffffff",
    paddingLeft: "5px",
    paddingRight: "5px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    mixBlendMode: "normal",
    transform: "translate(14px, -6px) !important"
  },
  select: {
    width: "264px",
    height: "40px",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  downloadButton: {
    background: "#7cb342",
    border: "none",
    borderRadius: "5px",
    padding: "10px",
    color: "white",
    fontWeight: "bold",
    cursor: "pointer"
  },
  categorySelectLabel: {
    color: "grey",
    fontSize: "20px",
    marginRight: "5px"
  },
  linkSelectLabel: {
    color: "grey",
    fontSize: "20px",
    marginLeft: "20px",
    marginRight: "10px",
    marginTop: "3px"
  },
  topTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  mobileWrapper: {
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: "50px",
    marginBottom: "120px",
    paddingBottom: "120px"
  }
});

class CategoryProducts extends React.Component {
  constructor(props) {
    super(props);
    this.selectedProductDOM = null;
    let query = queryString.parse(location.search);
    const searchParams = new URLSearchParams(query);
    this.state = {
      downloadPopup: false,
      categoryId: searchParams.get("categoryId")
    };
  }

  componentWillMount = () => {
    const { match, selectedCategories, TrackEvent } = this.props;
    const categoryId = this.state.categoryId;
    let category = selectedCategories.find(sc => sc.id === categoryId);
    if (category) {
      trackPriceCategorySelectedEvent(TrackEvent, category.name);
    }
  };

  saveCategoryData = async () => {
    const {
      t,
      client,
      selectedCategories,
      selectedCompany,
      user: { token }
    } = this.props;

    try {
      await client.mutate({
        mutation: ADD_CATEGORIES_TO_BUYER,
        variables: {
          categories: selectedCategories.map(
            ({ id, categoryId, linkedTo, prefferedWeights, accepted }) => ({
              id,
              categoryId,
              linkedTo,
              prefferedWeights,
              accepted
            })
          ),
          buyerId: selectedCompany
        },
        refetchQueries: () => [
          {
            query: GET_FULL_PRICING_DATA,
            variables: { token, buyerId: selectedCompany }
          }
        ],
        awaitRefetchQueries: true
      });

      showSuccess(t("Index changed") + "!");
    } catch (e) {
      showError(t("Error changing category index") + "!");

      console.log(e);
    }
  };
  saveOneCategoryData = async category => {
    const {
      t,
      client,
      selectedCompany,
      user: { token }
    } = this.props;

    try {
      await client.mutate({
        mutation: SAVE_ONE_CATEGORY_TO_BUYER,
        variables: {
          category: {
            id: category.id,
            categoryId: category.categoryId,
            linkedTo: category.linkedTo,
            prefferedWeights: category.prefferedWeights,
            accepted: category.accepted
          },
          buyerId: selectedCompany
        },
        refetchQueries: () => [
          {
            query: GET_FULL_PRICING_DATA,
            variables: { token, buyerId: selectedCompany }
          }
        ],
        awaitRefetchQueries: true
      });

      showSuccess(
        (category.linkedTo === "nolink"
          ? t("You have remove the link to all Products")
          : t("All Products have been linked to") +
            " " +
            (category.linkedTo === "lme" ? "LME Index" : "")) + "!"
      );
    } catch (e) {
      showError(t("Error changing category index") + "!");
      console.log(e);
    }
  };
  linkCategoryTo = (categoryId, linkedTo) => {
    const { selectedCategories, setSelectedCategories } = this.props;

    let category = selectedCategories.find(sc => sc.id === categoryId);
    category.linkedTo = linkedTo;

    setSelectedCategories(selectedCategories);
    this.saveOneCategoryData(category);
  };

  onSave = async productId => {
    const {
      t,
      client,
      selectedProducts,
      selectedCompany,
      user: { token },
      TrackEvent
    } = this.props;
    let allSelectedProducts = selectedProducts.filter(sp => sp);
    let product = allSelectedProducts.find(asp => asp.id === productId);
    product.isDirty = false;
    product.confirmed = true;
    try {
      await client.mutate({
        mutation: SAVE_ONE_PRICE_FOR_BUYER,
        variables: {
          buyerId: selectedCompany,
          productId: productId,
          priceData: allSelectedProducts.map(
            ({ id, confirmed, accepted, referencePrice, rules, linkedTo }) => ({
              id,
              confirmed,
              accepted,
              referencePrice,
              rules: rules.map(({ start, discount, accepted, price }) => ({
                start,
                discount,
                accepted,
                price
              })),
              linkedTo
            })
          )
        },
        refetchQueries: () => [
          {
            query: GET_FULL_PRICING_DATA,
            variables: { token, buyerId: selectedCompany }
          }
        ],
        awaitRefetchQueries: true,
        onError: error => console.log(error)
      });
      if (product) {
        const getRangeLabel = (i, label = "") => `range${i + 1}${label}`;
        TrackEvent("Product price edited", {
          product: product.productName,
          productLink: `https://app.scrap24.com/product/${product.productID}`,
          ...product.rules.reduce(
            (a, b, i) => ({
              ...a,
              [getRangeLabel(i, "PriceDiscount")]: b.discount,
              [getRangeLabel(i, "MyPriceDiscount")]: b.price,
              [getRangeLabel(i, "S24Index")]: b.indexPrice * 1000,
              [getRangeLabel(i, "Accepted")]: b.accepted
            }),
            {}
          )
        });
      }
      showSuccess(t("Product saved") + "!");
    } catch (e) {
      showError(t("Product couldnt be saved"));
    }
  };

  onChangeItem = (productId, price, start, type) => {
    const { selectedProducts, setSelectedProducts } = this.props;

    onChangeItem(
      productId,
      price,
      start,
      type,
      selectedProducts,
      setSelectedProducts
    );
  };

  changeReference = (productId, price, lockedOn) => {
    const { selectedProducts, setSelectedProducts } = this.props;

    onChangeReference(
      productId,
      price,
      lockedOn,
      selectedProducts,
      setSelectedProducts
    );
  };

  changeLink = (productId, value) => {
    const { setSelectedProducts, selectedProducts } = this.props;

    onChangeLink(productId, value, setSelectedProducts, selectedProducts);
  };

  onRemoveOrAddProduct = productId => {
    const { setSelectedProducts, selectedProducts } = this.props;

    let product = selectedProducts.find(sp => sp && sp.id === productId);

    product.accepted = !product.accepted;

    //activate all weights if it has no weights active
    if (product.accepted) {
      let rules = product.rules;
      let activeRules = rules.filter(r => r.accepted);

      if (!activeRules.length) rules.forEach(r => (r.accepted = true));
    }

    setSelectedProducts(selectedProducts);

    this.onSave(productId);
  };

  onRemoveOrAddWeightRange = (productId, start) => {
    const { setSelectedProducts, selectedProducts } = this.props;

    let product = selectedProducts.find(sp => sp && sp.id === productId);
    product.isDirty = true;
    let rule = product.rules.find(rl => rl.start === start);

    rule.accepted = !rule.accepted;

    setSelectedProducts(selectedProducts);
  };

  setSelectedProduct = element => {
    if (!element) return;
    const { params } = this.props.match;
    const { productId } = element.props;
    if (params.productId) {
      if (productId === params.productId) {
        this.selectedProductDOM = ReactDOM.findDOMNode(element);
      }
    }
  };

  scrollToProduct = () => {
    const { selectedProductDOM } = this;
    if (selectedProductDOM)
      document
        .getElementById("main-wrapper")
        .scrollTo(0, selectedProductDOM.offsetTop - 75);
  };

  componentDidMount() {
    this.scrollToProduct();
  }

  openPopup = () => {
    this.setState({ downloadPopup: true });
  };

  closePopup = () => {
    this.setState({ downloadPopup: false });
  };

  render() {
    const {
      t,
      classes,
      selectedCategories,
      selectedProducts,
      match,
      history,
      s24index,
      selectedCompany,
      token
    } = this.props;

    let category = selectedCategories.find(
      sc => sc.id === this.state.categoryId
    );

    let filteredProducts = selectedProducts.filter(
      sp => sp && sp.categoryId.indexOf(category.categoryId) > -1
    );

    let sortedByNameProducts = _.sortBy(
      filteredProducts,
      fp => fp.sort_order
    ).reverse();

    let productsWithS24Index = sortedByNameProducts.map(pr => {
      let priceIndex = s24index.priceData.find(
        s24 => s24.productId === pr.productID
      );

      if (priceIndex) {
        let rules = priceIndex.rules;
        pr.rules.forEach(prr => {
          let equalRule = rules.find(r => r.start === prr.start);

          if (!equalRule) {
            for (let r of rules) {
              if (r.start > prr.start) break;

              equalRule = r;
            }
          }

          if (!equalRule) {
            equalRule = rules[0];
          }

          prr.indexPrice = equalRule.price || 0;
        });
      }
      return { ...pr };
    });
    const categoryLinkOptions = [
      { value: "nolink", label: "No link" },
      { value: "lme", label: "LME Index" }
    ];
    const getCategoryLinkObject = categoryLink =>
      categoryLinkOptions.find(o => o.value === categoryLink);
    const updateSelectedCategory = value => {
      let query = queryString.parse(location.search);
      const searchParams = new URLSearchParams(query);
      searchParams.set("category", value[0].value);
      searchParams.set("categoryId", value[0].value);
      history.push({
        pathname: location.pathname,
        search: searchParams.toString()
      });
      this.state.categoryId = value[0].value;
    };

    return (
      <>
        <Hidden smDown>
          <div className={classes.wrapper}>
            <CategoryProductHeader
              selectedCompany={selectedCompany}
              token={token}
              category={category.name}
            />
            <div className={classes.topContainer}>
              <div className={classes.selectContainer}>
                <FormControl
                  variant="outlined"
                  className={classes.selectLabelContainer}
                >
                  <CustomList
                    data={selectedCategories.map(sc => ({
                      value: sc.id,
                      label: sc.name
                    }))}
                    placeholder={t("Category")}
                    onChange={value => {
                      updateSelectedCategory(value);
                    }}
                    defaultSelected={[
                      selectedCategories
                        .filter(sc => sc.id === this.state.categoryId)
                        .map(sc => ({
                          value: sc.id,
                          label: sc.name
                        }))[0]
                    ]}
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  className={classes.selectLabelContainer}
                >
                  <CustomList
                    data={categoryLinkOptions}
                    placeholder={t("Category linked to")}
                    onChange={value => {
                      this.linkCategoryTo(category.id, value[0].value);
                    }}
                    defaultSelected={[
                      getCategoryLinkObject(
                        (category && category.linkedTo) || "nolink"
                      )
                    ]}
                  />
                </FormControl>
              </div>
              <Legend />
            </div>
            <div className={classes.categoryWrapper}>
              {productsWithS24Index.map(sap => (
                <div className={classes.productWrapper} key={sap.id}>
                  <ItemPriceCard
                    productId={sap.id}
                    products={productsWithS24Index}
                    onChangeItem={this.onChangeItem}
                    onChangeReference={this.changeReference}
                    onSave={this.onSave}
                    onChangeLink={this.changeLink}
                    onRemoveOrAddProduct={this.onRemoveOrAddProduct}
                    onWeightCategoryChange={this.onRemoveOrAddWeightRange}
                    accepted={sap.accepted}
                  />
                </div>
              ))}
            </div>
          </div>
        </Hidden>
        <Hidden mdUp>
          <div className={classes.mobileWrapper}>
            <FormControl
              variant="outlined"
              className={classes.selectLabelContainer}
            >
              <CustomList
                data={categoryLinkOptions}
                placeholder={t("Category linked to")}
                onChange={value => {
                  this.linkCategoryTo(category.id, value[0].value);
                }}
                defaultSelected={[
                  getCategoryLinkObject(
                    (category && category.linkedTo) || "nolink"
                  )
                ]}
              />
            </FormControl>
            <CategoryProductsMobile
              products={productsWithS24Index}
              changeItem={this.onChangeItem}
              changeReference={this.changeReference}
              onSave={this.onSave}
              changeLink={this.changeLink}
              onRemoveOrAddProduct={this.onRemoveOrAddProduct}
              removeOrAddWeightRange={this.onRemoveOrAddWeightRange}
              categories={selectedCategories}
              match={match}
            />
          </div>
        </Hidden>
      </>
    );
  }
}

const trackPriceCategorySelectedEvent = (TrackEvent, category) => {
  TrackEvent("Price category selected", { category });
};

const StyledCategoryProducts = withRouter(
  withStyles(styles)(withTranslation()(withEventTracking(CategoryProducts)))
);

export default withWizard(
  withApollo(withStyles(styles)(StyledCategoryProducts))
);
