import { makeStyles } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  "@keyframes skeleton-loading": {
    from: {
      backgroundColor: "hsl(200, 20%, 80%)",
      color: "hsl(200, 20%, 80%)"
    },
    to: { backgroundColor: "hsl(200, 20%, 95%)", color: "hsl(200, 20%, 95%)" }
  },
  skeletonLoading: {
    animation: "$skeleton-loading 1s linear infinite alternate;",
    width: "max-content",
    pointerEvents: "none",
    userSelect: "none"
  },
  staticSkeleton: {
    width: "max-content",
    pointerEvents: "none",
    backgroundColor: "#E3E2E5",
    color: "#E3E2E5",
    userSelect: "none"
  }
}));

const Skeleton = ({
  children,
  active = true,
  animation = false,
  width = null,
  height = null,
  extraStyle = null
}) => {
  const classes = useStyles();

  const className = active
    ? animation
      ? classes.skeletonLoading
      : classes.staticSkeleton
    : "";

  const style = {
    ...(width && { width }),
    ...(height && { height }),
    ...extraStyle
  };

  const hideChildren = active && width && height;

  return (
    <div className={className} style={style}>
      {!hideChildren && children}
    </div>
  );
};

export default Skeleton;
