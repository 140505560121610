import React, { useContext, useState } from "react";
import SearchBar from "../../general/SearchBar";
import SearchMaterialQuery from "../../../queries/SearchMaterialQuery";
import UserContext from "../../../providers/UserProvider";
import { useTranslation } from "react-i18next";
import { useQuery, useLazyQuery } from "react-apollo";

const SearchMaterial = ({ selected, setSelected }) => {
  const { user, selectedCompany, userData } = useContext(UserContext);
  const { RawUserData } = userData;
  const [optionList, setOptionList] = useState([]);
  const { t, i18n } = useTranslation();

  // Function to set the material options from GraphQL query result
  const setMaterialOptions = data => {
    if (data?.SearchMaterials) {
      const materials = data.SearchMaterials.map(material => ({
        label: material.extra_alias || material.alias,
        value: material.id,
        ...material
      }));
      setOptionList(materials);
    } else {
      setOptionList([]);
    }
  };

  // Initial search query when the component mounts
  const { loading: initialLoading } = useQuery(SearchMaterialQuery, {
    fetchPolicy: "network-only",
    variables: {
      text: "",
      token: user.token,
      partnerId: selectedCompany,
      language: i18n?.language?.toUpperCase()
    },
    onCompleted: setMaterialOptions
  });

  // Search query that runs when the input changes
  const [getMaterials, { loading }] = useLazyQuery(SearchMaterialQuery, {
    fetchPolicy: "network-only",
    onCompleted: setMaterialOptions
  });

  // Event handler for the input change
  const handleChange = event => {
    const input = event.target.value;
    getMaterials({
      variables: {
        text: input,
        token: user.token,
        partnerId: selectedCompany,
        language: RawUserData.prefferedLanguage
      }
    });
  };

  return (
    <SearchBar
      id="materialSearchBarId"
      onSelect={setSelected}
      onChange={handleChange}
      optionList={optionList}
      placeholder={t("Search_Material")}
      loading={loading || initialLoading}
    />
  );
};

export default SearchMaterial;
