import { loadStateKey } from "../actions/DataPersistence";
import {
  LOGIN_ERROR,
  LOGOUT_USER,
  LOGIN_SUCCESS,
  SET_REDIRECT_LINK,
  SENT_EMAIL
} from "../actions/auth";

const authState = loadStateKey("s24-user", "user");

const DEFAULT_STATE = authState
  ? {
      id: authState.id,
      token: authState.token,
      sellerId: authState.sellerId,
      tokenExpires: authState.tokenExpires
    }
  : {
      id: null,
      token: null,
      sellerId: null,
      redirectLink: null,
      tokenExpires: null,
      loginErrorMessage: ""
    };

export default (
  state = DEFAULT_STATE,
  { user, type, error, sellerId, redirectLink, email } = {}
) => {
  switch (type) {
    case LOGIN_ERROR:
      return Object.assign({}, state, {
        id: null,
        token: null,
        sellerId: null,
        tokenExpires: null,
        loginErrorMessage: error
      });
    case LOGOUT_USER:
      return Object.assign({}, state, {
        id: null,
        token: null,
        sellerId: null,
        tokenExpires: null,
        loginErrorMessage: null
      });

    case SENT_EMAIL:
      return Object.assign({}, state, {
        id: null,
        token: null,
        sellerId: null,
        tokenExpires: null,
        emailMagicLinkSended: email
      });

    case LOGIN_SUCCESS:
      return Object.assign({}, state, {
        ...user,
        sellerId: null,
        loginErrorMessage: ""
      });
    case SET_REDIRECT_LINK:
      return Object.assign({}, state, {
        ...user,
        sellerId,
        redirectLink
      });
    default:
      return state;
  }
};
