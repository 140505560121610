const checkAnalytics = () => {
  if (typeof window !== "undefined" && window.analytics) {
    return true;
  }
  if (process.env.NODE_ENV !== "development") {
    console.warn("No Segment Analytics found");
  }
  return false;
};

export const TrackEvent = (eventName, eventProperties = {}) => {
  if (checkAnalytics()) {
    return window.analytics.track(eventName, eventProperties);
  }
  return console.log(`Event triggered: ${eventName}:`, eventProperties);
};

export const IdentifyEventUser = userData => {
  const user = getUserFromUserData(userData);
  if (!user) return;
  let company = {
    companyName: "",
    companySegment: ""
  };
  if (userData.selectedCompany) {
    company.accountRoles = userData.selectedCompany.roles;
    company.userRoles = userData.selectedCompany.companyRoles;
    company.companyName = userData.selectedCompany.name;
    company.companySegment = userData.selectedCompany.segment;
  }

  if (checkAnalytics()) {
    const waUser = window.analytics._user;
    if (!waUser || waUser.id() !== user._id) {
      return window.analytics.identify(user._id, { ...user, ...company });
    }
  } else {
    return console.log(`Identify event triggered for user ${user._id}:`, {
      ...user,
      ...company
    });
  }
};

const getUserFromUserData = ({ RawUserData } = {}) => {
  if (!(RawUserData && RawUserData._id)) return null;

  const { _id, avatarUrl, email, firstName, lastName, preferredLanguage } =
    RawUserData;

  return { _id, avatarUrl, email, firstName, lastName, preferredLanguage };
};
