import React, { useContext, useEffect } from "react";
import { useHistory } from "react-router";
import { Grid, Typography, Divider } from "@material-ui/core";

import OffersContext from "../../../providers/OffersProvider";

import offerElementStyles from "./offerElementStyles";
import OfferTrader from "./OfferTrader";
import OfferComment from "./OfferComment";
import OfferBid from "./OfferBid";
import OfferItem, { OfferItemMobile } from "./OfferItem";
import { Button } from "./inputComponents";

import CloseIcon from "@material-ui/icons/Close";
import { bidQtyCalc } from "../../../pages/offers/FinishOffers";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-apollo";
import { SET_VIEWED_TENDER } from "../../../mutations/offerMutations";
import GetOffersData from "../../../queries/GetOffersData";
import UserContext from "../../../providers/UserProvider";

const OfferElement = ({ offers, canBid }) => {
  const classes = offerElementStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { selectedOffer, biddingInfo } = useContext(OffersContext);
  const { user, selectedCompany } = useContext(UserContext);

  const [setViewedTender] = useMutation(SET_VIEWED_TENDER, {
    refetchQueries: () => [
      {
        query: GetOffersData,
        variables: { token: user.token, partnerId: selectedCompany }
      }
    ]
  });

  const offer = selectedOffer
    ? offers.find(o => o._id === selectedOffer)
    : null;

  useEffect(() => {
    if (offer && !isViewedTender(offer)) {
      setViewedTender({
        variables: {
          token: user.token,
          tenderId: offer._id,
          buyerId: selectedCompany
        }
      });
    }
  }, [selectedOffer]);

  if (!selectedOffer)
    return (
      <Grid
        container
        item
        xs
        justify="center"
        alignItems="center"
        direction="column"
        className={classes.elementContainer}
      >
        <Typography variant="h6">{t("No offer selected")}</Typography>
        <Typography variant="body2">
          {t(
            "To view an offer select one offer under open offers the left and the details will be shown here"
          )}
        </Typography>
      </Grid>
    );

  if (!offer) return null;

  let bidInfoEditing;

  const bidInfo = biddingInfo[offer._id];

  if (canBid) bidInfoEditing = hasEditingBid(bidInfo);

  if (offer.noLead) {
    return <h2>{t("Error_NoExistingLead")}</h2>;
  }

  return (
    <Grid className={classes.elementContainer}>
      <div className={classes.titleContainer}>
        <Typography
          variant="subtitle2"
          className={classes.offeredMaterialsText}
        >
          {t("Offered materials")} ({offer.products.length})
        </Typography>
        {bidInfoEditing && (
          <Button
            variant="contained"
            color="primary"
            onClick={() => history.push(history.location.pathname + "/finish")}
          >
            {t("Finish Bidding")}
          </Button>
        )}
      </div>
      <Divider />
      <div className={classes.scrollContainer}>
        <Grid item container>
          <Grid item xs>
            <OfferComment comment={offer.description} />
          </Grid>
          <Grid item className={classes.rightContainer}>
            <OfferTrader trader={offer.trader} />
          </Grid>
        </Grid>
        {offer.products.map((p, i) => {
          if (p === null) {
            return null;
          }
          return (
            <Grid container item style={{ flexGrow: 1 }} key={offer._id + i}>
              <Grid item xs>
                <OfferItem
                  product={p}
                  last={i + 1 === offer.products.length}
                  index={i + 1}
                />
              </Grid>
              <Grid item className={classes.rightContainer}>
                <OfferBid product={p} offer={offer} canBid={canBid} />
              </Grid>
            </Grid>
          );
        })}
        <Grid container item xs>
          <Grid item xs />
          <Grid item className={classes.bottomFinishBidButton}>
            {bidInfoEditing && (
              <Button
                variant="contained"
                color="primary"
                onClick={() =>
                  history.push(history.location.pathname + "/finish")
                }
              >
                {t("Finish Bidding")}
              </Button>
            )}
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};

export const OfferElementMobile = ({ offers, canBid, goBackAction }) => {
  const classes = offerElementStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { selectedOffer, biddingInfo } = useContext(OffersContext);
  const { user, selectedCompany } = useContext(UserContext);

  const [setViewedTender] = useMutation(SET_VIEWED_TENDER, {
    refetchQueries: () => [
      {
        query: GetOffersData,
        variables: { token: user.token, partnerId: selectedCompany }
      }
    ]
  });

  const offer = selectedOffer
    ? offers.find(o => o._id === selectedOffer)
    : null;

  useEffect(() => {
    if (offer && !isViewedTender(offer)) {
      setViewedTender({
        variables: {
          token: user.token,
          tenderId: offer._id,
          buyerId: selectedCompany
        }
      });
    }
  }, [selectedOffer]);

  if (!selectedOffer) return null;

  let bidInfoEditing;

  const bidInfo = biddingInfo[offer._id];

  if (canBid) bidInfoEditing = hasEditingBid(bidInfo, offer._id);

  if (offer.noLead) {
    return <h2>{t("Error_NoExistingLead")}</h2>;
  }

  return (
    <Grid container item className={classes.elementContainerMobile}>
      <Grid container item xs className={classes.selectedOfferHeader}>
        <Grid item className={classes.selectedOfferHeaderCloseIcon}>
          <CloseIcon onClick={goBackAction} />
        </Grid>
        <Grid item className={classes.selectedOfferHeaderText}>
          <Typography variant="h6">{offer.tenderID}</Typography>
        </Grid>
      </Grid>
      <Grid item container direction="column">
        <Grid item>
          <OfferComment comment={offer.description} />
        </Grid>
        <Grid item>
          <OfferTrader trader={offer.trader} />
        </Grid>
      </Grid>
      {offer.products.map((p, i) => (
        <Grid container item style={{ flexGrow: 1 }} key={offer._id + i}>
          <Grid item xs>
            <OfferItemMobile
              product={p}
              last={i + 1 === offer.products.length}
              index={i + 1}
            >
              <OfferBid product={p} offer={offer} canBid={canBid} />
            </OfferItemMobile>
          </Grid>
        </Grid>
      ))}
      {bidInfoEditing && (
        <Grid item xs className={classes.finishBidMobile}>
          <Grid container item xs alignItems="center">
            <Typography
              variant="body2"
              className={classes.finishBidMobileTitle}
            >
              {t("Products")} {bidQtyCalc(biddingInfo[offer._id])}/
              {offer.products.length}
            </Typography>
          </Grid>
          <Grid item>
            <Button
              style={{ color: "#FFFFFF" }}
              onClick={() =>
                history.push(history.location.pathname + "/finish")
              }
            >
              {t("Finish Bidding")}
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default OfferElement;

const hasEditingBid = bidInfo =>
  Object.keys(bidInfo).reduce((a, b) => !bidInfo[b].editing || a, false);

export const isViewedTender = offer => !!offer.accessDate;
