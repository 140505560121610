import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Info } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";

const TransactionsCard = ({
  title,
  children,
  className,
  tooltipText = null,
  disabled = false
}) => {
  const theme = useTheme();
  const useStyles = makeStyles({
    card: {
      background: "#F9F9F9",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
      boxSizing: "border-box",
      "& *": {
        boxSizing: "border-box"
      }
    },
    cardOpacity: {
      background: "rgba(249, 249, 249, 0.5)",
      border: "1px solid rgba(0, 0, 0, 0.05)",
      borderRadius: "4px",
      boxSizing: "border-box",
      "& *": {
        boxSizing: "border-box"
      }
    },
    card__header: {
      padding: "16px 20px",
      borderBottom: "1px solid #E0E0E0"
    },
    card__headerOpacity: {
      padding: "16px 20px",
      borderBottom: "1px solid rgba(224, 224, 224, 0.5)" // Border color with 0.5 opacity
    },
    card__title: {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      margin: 0
    },
    card__body: {
      padding: "16px 20px 20px 20px"
    },
    tooltip: {
      maxWidth: "320px",
      marginLeft: "4px",
      pointerEvents: "auto !important",
      opacity: "1 !important",
      filter: "none !important"
    },
    disabled: {
      pointerEvents: "none",
      opacity: "0.5",
      filter: "grayscale(100%)"
    }
  });
  const classes = useStyles();
  return (
    <div
      className={`${
        disabled ? classes.cardOpacity : classes.card
      } ${className}`}
    >
      <div
        className={`${
          disabled ? classes.card__headerOpacity : classes.card__header
        } ${className}`}
      >
        <h4 className={classes.card__title}>
          {disabled ? <span className={classes.disabled}>{title}</span> : title}
          {tooltipText && (
            <Tooltip
              arrow
              placement="top"
              title={
                <span style={{ whiteSpace: "pre-line" }}>{tooltipText}</span>
              }
              className={classes.tooltip}
            >
              <Info className={classes.icon} />
            </Tooltip>
          )}
        </h4>{" "}
      </div>
      <div className={`${classes.card__body} ${disabled && classes.disabled}`}>
        {children}
      </div>
    </div>
  );
};

export default TransactionsCard;
