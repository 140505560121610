import React, { useState } from "react";
import {
  Button,
  FormControl,
  IconButton,
  InputLabel,
  makeStyles,
  Select,
  TextField,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-apollo";
import useEventTracking from "../../../../hooks/useEventTracking";

import { GET_VAT_DETAILS } from "../../../../queries/AccountVats";
import addNewAccount from "../../../../assets/icons/addNewAccount.png";
import UpdatePaymentDetail from "../../../../mutations/updatePaymentDetail";
import { showError, showSuccess } from "../../../core/shared/Notify";
import { useValidation } from "../../../../hooks/useValidation";
import { SelectMenuProps } from "../../../core/shared/constants";

export const COMPANY_TAX_TYPES = [
  { value: "seller_vat", name: "Company with VAT" },
  { value: "seller_no_vat", name: "Small business without VAT" }
];

const EditVatForm = ({ token, company, cancel, vatDetails }) => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      maxWidth: 400,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #9E9E9E"
      }
    },
    btnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 23
    },
    inputText: {
      width: "100%"
    },
    addVatFlexContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px"
    },
    addContactContainer: {
      margin: "-12px 0 -7px 0",
      display: "flex",
      gap: 4,
      alignItems: "center",
      justifyContent: "flex-end",
      maxWidth: 480
    },
    taxIdentificationNumberWrap: {
      display: "flex",
      gap: "15px",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: "5px",
        alignItems: "flex-end"
      }
    },
    taxIdentificationNumberSpan: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "0.25px",
      color: "#212121",
      margin: "0"
    },
    taxIdentificationNumber: {
      flex: "1",
      maxWidth: "400px",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    taxIdentificationNumberBtn: {
      background: "transparent",
      backgroundColor: "transparent",
      outline: "none",
      border: "0",
      color: "#276EF1",
      cursor: "pointer"
    }
  }));

  const classes = useStyles();

  const { t } = useTranslation();

  const { errors, checkValidation, setErrors } = useValidation();

  const { TrackEvent } = useEventTracking();

  const [form, setForm] = useState(() => {
    const { companyTaxType, paymentCredentials, taxIdentificationNumber } =
      vatDetails;
    return {
      companyTaxType: companyTaxType || "",
      paymentCredentials: paymentCredentials || "",
      arrVAT: taxIdentificationNumber ? [...taxIdentificationNumber] : []
    };
  });

  const changeForm = (field, newData) => {
    let cleanError = { ...errors };
    delete cleanError[field];
    setErrors(cleanError);
    let editedForm = { ...form };

    editedForm[field] = newData;

    setForm(editedForm);
  };

  const handleTaxInformationChange = idx => evt => {
    const newTaxInformations = form.arrVAT.map((record, sidx) => {
      if (idx !== sidx) return record;
      return { ...record, value: evt.target.value };
    });
    setForm({
      ...form,
      arrVAT: newTaxInformations
    });
  };

  const onSetAsDefault = idx => evt => {
    const newTaxInformations = form.arrVAT.map((record, sidx) => {
      if (idx !== sidx)
        return {
          ...record,
          default: false
        };
      return { ...record, default: true };
    });
    setForm({
      ...form,
      arrVAT: newTaxInformations
    });
  };

  const handleAddTaxInformations = () => {
    setForm({
      ...form,
      arrVAT: form.arrVAT.concat([{ value: "", default: false }])
    });
  };

  const handleRemoveTaxInformations = idx => () => {
    setForm({
      ...form,
      arrVAT: form.arrVAT.filter((s, sidx) => idx !== sidx)
    });
  };

  const [updatePaymentInfo] = useMutation(UpdatePaymentDetail, {
    onCompleted: () => {
      TrackEvent("Saved payment details", {
        accountId: company
      });
    },
    refetchQueries: () => [
      {
        query: GET_VAT_DETAILS,
        variables: {
          token: token,
          company: company
        }
      }
    ]
  });
  const submitForm = event => {
    // check these required fields
    const errors = checkValidation({
      companyTaxType: form.companyTaxType,
      paymentCredentials: form.paymentCredentials
    });
    if (errors) return;

    const arrVAT = form.arrVAT || [];
    const formData = {
      ...form,
      defaultVAT: arrVAT.find(record => record.default)?.value,
      arrVAT: arrVAT.map(record => record.value)
    };
    updatePaymentInfo({
      variables: {
        input: {
          token,
          companyId: company,
          ...formData
        }
      }
    })
      .then(() => {
        showSuccess(t("Successfully updated"));
        cancel(true);
      })
      .catch(err => {
        if (err.message.includes("Invalid vat number")) {
          showError(t("Error_Invalid_VAT"), 5000);
        } else {
          showError(err.message);
        }
      });
  };

  return (
    <div className={classes.container}>
      <FormControl fullWidth className={classes.addVatFlexContainer}>
        {/* for debug purpose */}
        {/* <pre>{JSON.stringify(form.arrVAT, null, 2)}</pre> */}
        <div className={classes.container}>
          <FormControl
            variant="outlined"
            fullWidth
            error={errors?.companyTaxType}
          >
            <InputLabel id="TaxClasification">
              {t("Tax Clasification")}
            </InputLabel>
            <Select
              className={classes.inputText}
              labelId="TaxClasification"
              id="Tax Clasification"
              label={t("Tax Clasification")}
              defaultValue={form.companyTaxType}
              onChange={event => {
                changeForm("companyTaxType", event.target.value);
              }}
              MenuProps={SelectMenuProps}
            >
              {COMPANY_TAX_TYPES.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {t(item.name)}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>
              {errors?.companyTaxType && t("Required")}
            </FormHelperText>
          </FormControl>
        </div>
        {form.arrVAT.map((vat, index) => (
          <div
            key={`${vat.value || "taxInfo"}_${index}`}
            className={classes.taxIdentificationNumberWrap}
          >
            <TextField
              id="TaxIdentificationNumber"
              label={t("Tax Identification Number")}
              variant="outlined"
              defaultValue={vat.value}
              onBlur={handleTaxInformationChange(index)}
              error={errors?.taxIdentificationNumber}
              helperText={errors?.taxIdentificationNumber && t("Required")}
              className={classes.taxIdentificationNumber}
            />
            <p className={classes.taxIdentificationNumberSpan}>
              {vat.default ? (
                t("Default VAT number")
              ) : (
                <button
                  className={classes.taxIdentificationNumberBtn}
                  onClick={onSetAsDefault(index)}
                >
                  {t("Set as default")}
                </button>
              )}
            </p>
          </div>
        ))}
        <div className={classes.container}>
          <div className={classes.addContactContainer}>
            {t("Add VAT Number")}
            <IconButton onClick={handleAddTaxInformations}>
              <img src={addNewAccount} alt="AddNewAccount" />
            </IconButton>
          </div>
          <TextField
            id="paymentCredentials"
            label={t("IBAN Number")}
            className={classes.smallInputText}
            variant="outlined"
            defaultValue={form.paymentCredentials}
            onChange={event => {
              changeForm("paymentCredentials", event.target.value);
            }}
            error={errors?.paymentCredentials}
            helperText={errors?.paymentCredentials && t("Required")}
          />
          {/* <TextField
            multiline
            variant="outlined"
            label={t("Other Info")}
            InputProps={{ className: classes.textArea }}
            fullWidth
            minRows={4}
            defaultValue={form.otherInfo}
            onChange={event => {
              changeForm("otherInfo", event.target.value);
            }}
          /> */}
          <div className={classes.btnContainer}>
            <Button variant="outlined" color="primary" onClick={cancel}>
              {t("Cancel")}
            </Button>
            <Button variant="contained" color="primary" onClick={submitForm}>
              {t("SAVE")}
            </Button>
          </div>
        </div>
      </FormControl>
    </div>
  );
};

export default EditVatForm;
