import React from "react";

export default React.createContext({
  document: null,
  orderId: null,
  source: null,
  page: null,
  setOrderId: () => {},
  setSource: () => {},
  setPage: () => {},
  setDocument: () => {}
});
