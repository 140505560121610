import React, { useEffect } from "react";
import GetStarted from "./GetStarted";
import OnboardingFormPage from "./OnboardingFormPage";
import { useParams } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";
import { ONBOARDING_GET_STARTED } from "../../utils/constants";
import queryString from "query-string";
import useEventTracking from "../../hooks/useEventTracking";
import { IdentifyEventUser } from "../../modules/segment";

const Onboarding = () => {
  const { step } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { TrackEvent } = useEventTracking();
  const parsedQueryString = queryString.parse(location.search);
  const method = parsedQueryString.method;

  useEffect(() => {
    TrackEvent("Onboarding KYC Flow started", {
      method: method || "automatically"
    });
  }, []);

  const token = parsedQueryString?.token || location.state?.token;
  const sourceQueryObj = { ...parsedQueryString };
  delete sourceQueryObj.token;

  const identifyData = location.state?.identifyData;
  if (identifyData) IdentifyEventUser(identifyData);

  if (!step) {
    history.push(`/onboarding/${ONBOARDING_GET_STARTED}`, {
      token,
      sourceQueryObj
    });
    return null;
  }

  if (step === ONBOARDING_GET_STARTED) return <GetStarted />;

  return <OnboardingFormPage step={step} />;
};

export default Onboarding;
