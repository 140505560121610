import React from "react";
import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  header: {
    fontSize: "32px",
    fontWeight: "500",
    lineHeight: "40px",
    fontStyle: "normal",
    marginBottom: "12px",
    maxWidth: "980px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "24px",
      lineHeight: "28px"
    }
  },
  subHeader: {
    color: "var(--On-surface-Medium-emphasis, rgba(0, 0, 0, 0.60))",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    fontStyle: "normal",
    letterSpacing: "0.25px",
    maxWidth: "980px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      lineHeight: "24px"
    }
  },
  onboardingInfoActive: {
    marginBottom: "20px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "-16px"
    }
  },
  onboardingInfoInactive: {
    marginTop: "58px",
    marginBottom: "20px"
  }
}));

const Header = ({ userData, isOnboardingActive = false }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { RawUserData: user } = userData;

  return (
    <div
      className={
        isOnboardingActive
          ? classes.onboardingInfoActive
          : classes.onboardingInfoInactive
      }
    >
      <Typography className={classes.header}>
        {t("dashboard_header", {
          name: user.firstName,
          lastName: user.lastName
        })}
      </Typography>
      <Typography className={classes.subHeader}>
        {t("dashboard_subHeader")}
      </Typography>
    </div>
  );
};

export default Header;
