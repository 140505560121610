const createAnchorElement = () => {
  const element = document.createElement("a");
  element.innerText = "Download";
  element.style.position = "absolute";
  element.style.top = "-100px";
  element.style.left = "0px";
  return element;
};

const addSearchParamsToUrl = (url, params = {}) => {
  const urlObj = new URL(url);
  const searchParams = urlObj.searchParams;
  for (let param in params) {
    const value = params[param];
    if (value !== undefined) {
      searchParams.append(param, params[param]);
    }
  }
  return urlObj.toString();
};

export const downloadFromUrl = (documentUrl, filename) => {
  const element = createAnchorElement();
  //For being able to dowload blobs
  if (!documentUrl.startsWith("blob:")) {
    documentUrl = addSearchParamsToUrl(documentUrl, {
      download: true,
      filename
    });
  }
  element.setAttribute("href", documentUrl);
  element.setAttribute("download", filename);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const getDownloadUrl = documentUrl => {
  let url = documentUrl;
  if (!documentUrl.startsWith("blob:")) {
    url = addSearchParamsToUrl(documentUrl, {
      download: true
    });
  }
  return url;
};
