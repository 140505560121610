/* eslint import/no-webpack-loader-syntax: off */
// the above is a hack to import svg's as path
import React from "react";
import ModalWrapper from "../../components/transactions/layout/Modals/ModalClass";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Input,
  Chip,
  MenuItem
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/de";

const DAYS = [
  { name: "Monday", letter: "M" },
  { name: "Tuesday", letter: "T" },
  { name: "Wednesday", letter: "W" },
  { name: "Thursday", letter: "T" },
  { name: "Friday", letter: "F" },
  { name: "Saturday", letter: "S" },
  { name: "Sunday", letter: "S" }
];

const INTERVALS = [
  { name: "Every week", value: 1 },
  { name: "Every two weeks", value: 2 },
  { name: "Every three week", value: 3 }
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const styles = theme => ({
  modalPadder: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    padding: "20px 0 20px 0",
    [theme.breakpoints.down("xs")]: {
      padding: "0",
      justifyContent: "space-between",
      height: "100vh"
    }
  },
  wrapper: {
    width: "550px",
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      width: "100vw",
      flexGrow: "1",
      minHeight: "auto",
      padding: "0"
    }
  },
  formControl: {
    width: "100%",
    height: "100%"
  },
  chips: {
    display: "flex",
    flexWrap: "wrap"
  },
  chip: {
    margin: 2,
    backgroundColor: "rgba(31, 196, 119, 0.5)"
  },
  button: {
    width: "100%",
    marginTop: "20px",
    [theme.breakpoints.down("xs")]: {
      width: "100vw"
    }
  }
});

class AddZoneModal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      zipCodes: null,
      zoneName: null,
      days: [],
      dayOfTheWeek: [],
      interval: 1,
      startingDate: moment().toDate()
    };
  }
  handleChange = event => {
    this.setState({
      [event.target.id || event.target.name]: event.target.value
    });
  };

  handleChangeMultiple = event => {
    let { dayOfTheWeek } = [];
    let sortedDays = event.target.value.sort(
      (a, b) =>
        DAYS.findIndex(d => d.name === a) - DAYS.findIndex(d => d.name === b)
    );
    this.setState({
      days: sortedDays
    });
    dayOfTheWeek = sortedDays.map(d => DAYS.findIndex(dd => dd.name === d) + 1);
    this.setState({ dayOfTheWeek });
  };

  handleDateChange = date => {
    this.setState({ startingDate: date });
  };

  resetForm = () => {
    this.setState({
      zipCodes: null,
      zoneName: null,
      days: [],
      dayOfTheWeek: [],
      interval: 1,
      startingDate: moment().toDate()
    });
  };

  componentWillReceiveProps(nextProps) {
    const { editing } = nextProps;
    if (editing.editing) {
      this.setState({
        zipCodes: editing.zipCodes,
        zoneName: editing.zoneName,
        dayOfTheWeek: editing.dayOfTheWeek,
        days: editing.dayOfTheWeek
          ? editing.dayOfTheWeek.map(d => DAYS[d - 1].name)
          : [],
        interval: editing.interval,
        startingDate: editing.startingDate || this.state.startingDate
      });
    }
  }

  render() {
    const { classes, isModalOpen, onCloseModal, addZone, editZone, editing } =
      this.props;
    const { zoneName, dayOfTheWeek, zipCodes, days, interval, startingDate } =
      this.state;
    const { handleChange, handleChangeMultiple, handleDateChange, resetForm } =
      this;
    return (
      <ModalWrapper
        onCloseModal={() => {
          if (editing) resetForm();
          onCloseModal();
        }}
        isOpen={isModalOpen}
      >
        <div className={classes.modalPadder}>
          <Typography variant="h4">
            {editing.editing ? "Edit" : "Add"} Zone
          </Typography>
          <div className={classes.wrapper}>
            <FormControl className={classes.formControl}>
              <TextField
                label="Zone Name"
                id="zoneName"
                value={zoneName}
                margin="normal"
                variant="outlined"
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <TextField
                label="ZipCodes"
                required
                id="zipCodes"
                placeholder="8010, 8020-8030, 8040"
                value={zipCodes}
                margin="normal"
                variant="outlined"
                onChange={handleChange}
              />
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="select-multiple-days">Days</InputLabel>
              <Select
                multiple
                value={days}
                onChange={handleChangeMultiple}
                input={<Input id="select-multiple-days" />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip
                        key={value}
                        label={value}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {DAYS.map((day, i) => (
                  <MenuItem key={i} value={day.name}>
                    {day.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="interval">Interval</InputLabel>
              <Select
                label="Interval"
                value={interval}
                id="interval"
                onChange={handleChange}
                inputProps={{
                  name: "interval",
                  id: "interval"
                }}
              >
                <MenuItem key={"interval-0"} value={0}>
                  <i>Disabled</i>
                </MenuItem>
                {INTERVALS.map((i, ii) => (
                  <MenuItem key={"interval-" + ii} value={i.value}>
                    {i.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <DatePicker
                  autoOk
                  disablePast={editing.editing ? false : true}
                  label="Starting week"
                  name="date"
                  value={startingDate}
                  onChange={handleDateChange}
                  disabled={editing.editing ? "disabled" : null}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            <Button
              justify="center"
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={() => {
                if (editing.editing)
                  editZone(
                    editing._id,
                    zoneName,
                    dayOfTheWeek,
                    interval,
                    zipCodes,
                    resetForm
                  );
                else
                  addZone(
                    {
                      zoneName,
                      dayOfTheWeek,
                      zipCodes,
                      days,
                      interval,
                      startingDate
                    },
                    resetForm
                  );
              }}
            >
              {editing.editing ? "Edit" : "Add"} Zones
            </Button>
          </div>
        </div>
      </ModalWrapper>
    );
  }
}

export default withStyles(styles)(withTranslation()(AddZoneModal));
