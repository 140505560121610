import { useTranslation } from "react-i18next";
import { useNotifications } from "../../../../../hooks/Notifications";
import { useHistory } from "react-router-dom";

export default ({}) => {
  const { t } = useTranslation();
  const { pushNotification } = useNotifications();
  const history = useHistory();

  pushNotification(
    t("import_dialog_file_sent_header") +
      "\n " +
      t("import_dialog_file_sent_subheader"),
    { color: "success", fixed: true }
  );

  //We redirect the web to /sales
  history.push("/sales");

  return <></>;
};
