import React from "react";
import CustomDropdown from "./CustomDropdown";

import { Grid, IconButton, makeStyles } from "@material-ui/core";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";

import { format } from "date-fns";
import { useTranslation } from "react-i18next";
import moment from "moment";

const Header = props => {
  const useStyles = makeStyles({
    container: { width: "280px", paddingLeft: "0", paddingRight: "0" },
    iconContainer: {
      padding: 0
    },
    selectRoot: {
      width: "100%"
    },
    icon: {
      padding: "8px",
      "&:hover": {
        background: "none"
      }
    },
    selectInput: {
      borderBottom: "none"
    },
    popper: {
      position: "absolute",
      top: "98px",
      left: "100px",
      zIndex: 1
    },
    buttonContainer: {
      width: "88px",
      display: "flex",
      justifyContent: "space-between"
    }
  });

  const classes = useStyles();
  const { t } = useTranslation();

  const {
    date,
    nextDisabled,
    prevDisabled,
    onClickNext,
    onClickPrevious,
    onYearNavigate,
    marker
  } = props;

  const date_text = format(date, "MMMM yyyy").split(" ");
  const month_text = moment(date).locale("en").format("MMMM");
  const year_text = date_text[1];

  return (
    <div className={classes.container}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item className={classes.selectAndLabelContainer}>
          <CustomDropdown
            month={t(month_text)}
            year={year_text}
            onYearNavigate={onYearNavigate}
            marker={marker}
          />
        </Grid>

        <div className={classes.buttonContainer}>
          <Grid item className={classes.iconContainer}>
            <IconButton
              className={classes.icon}
              disabled={prevDisabled}
              onClick={onClickPrevious}
            >
              <ChevronLeft color={prevDisabled ? "disabled" : "action"} />
            </IconButton>
          </Grid>
          <Grid item className={classes.iconContainer}>
            <IconButton
              className={classes.icon}
              disabled={nextDisabled}
              onClick={onClickNext}
            >
              <ChevronRight color={nextDisabled ? "disabled" : "action"} />
            </IconButton>
          </Grid>
        </div>
      </Grid>
    </div>
  );
};

export default Header;
