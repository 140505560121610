import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "../../mainContainer/Container";
import RightContent from "../../mainContainer/RightContent";
import StepImage from "../../stepImages/StepImage";

import { ReactComponent as AddressImage } from "../../../../assets/images/address.svg";
import CompanyInfo from "../components/CompanyInfo";

const styles = theme => ({
  stepImage: {
    margin: "13%"
  }
});

class CompanyInformationScreen extends React.Component {
  render() {
    const {
      classes,
      selectedNumEmployee,
      setSelectedNumEmployee,
      selectedNumLocations,
      setSelectedNumLocations,
      setStep
    } = this.props;

    return (
      <>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <StepImage src={<AddressImage className={classes.stepImage} />} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RightContent>
                <CompanyInfo
                  onBack={() => setStep(1)}
                  onNext={() => setStep(3)}
                  setNumEmployee={setSelectedNumEmployee}
                  numEmployee={selectedNumEmployee}
                  setNumLocations={setSelectedNumLocations}
                  numLocations={selectedNumLocations}
                />
              </RightContent>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(CompanyInformationScreen);
