import gql from "graphql-tag";

export default gql`
  query getFullPricingData($token: String!, $buyerId: String!) {
    BuyerPricing(token: $token, buyerId: $buyerId) {
      location
      products {
        id
        productID
        confirmed
        accepted
        lme
        sort_order
        referencePrice
        rules {
          start
          price
          discount
          accepted
          margin
        }
        linkedTo
        categoryId
        productName
        short_description
        productImage {
          src
          alt
          id
        }
      }
      categories {
        id
        categoryId
        linkedTo
        prefferedWeights
        accepted
        sort_order
        name
        description
        parent
        categoryImage {
          src
          alt
          id
        }
      }
    }
    S24IndexPrices {
      _id
      date
      longDate
      priceData {
        productId
        rules {
          start
          price
        }
      }
      dateUpdated
    }
  }
`;
