import GenericCard from "../general/GenericCard";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  cardHeader: {
    width: "452px",
    height: "42px",
    display: "flex",
    alignItems: "center"
  },
  cardHeaderTypography: {
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "24px",
    fontStyle: "normal",
    color: "rgba(0, 0, 0, 0.87)",
    letterSpacing: "0.25px",
    marginLeft: "8px"
  },
  contentHeader: {
    fontSize: "20px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  contentSubHeader: {
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.60)",
    marginTop: "4px"
  },
  content: {
    padding: "20px !important"
  },
  contentText: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.25px"
  },
  textDiv: {
    paddingTop: "12px"
  }
}));

const SummaryCard = ({ logo, title, header, subheader, textFields }) => {
  const classes = useStyles();
  return (
    <GenericCard
      title={
        <div className={classes.cardHeader}>
          {logo}
          <Typography className={classes.cardHeaderTypography}>
            {title}
          </Typography>
        </div>
      }
      content={
        <>
          {header && (
            <Typography className={classes.contentHeader}>{header}</Typography>
          )}
          {subheader && (
            <Typography className={classes.contentSubHeader}>
              {subheader}
            </Typography>
          )}
          {textFields && (
            <div className={classes.textDiv}>
              {textFields.map((field, index) => (
                <Typography key={index} className={classes.contentText}>
                  {field.text}
                </Typography>
              ))}
            </div>
          )}
        </>
      }
      extraClasses={{ content: classes.content }}
    />
  );
};

export default SummaryCard;
