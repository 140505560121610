import { makeStyles } from "@material-ui/core/styles";
import CircleWithNumber from "./CircleWithNumber";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  step: {
    alignItems: "center",
    display: "flex",
    position: "relative",
    zIndex: 2,
    width: "100%",
    verticalAlign: "middle",
    backgroundColor: "#151124",
    paddingBottom: "10px",
    paddingTop: "10px"
  },
  text: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "20px",
    fontStyle: "normal"
  },
  textInactive: {
    color: "#95949C"
  },
  textActive: {
    color: "black"
  }
}));

const Step = ({ number, label, actualPosition, onClick, overrideClasses }) => {
  const classes = useStyles();
  const active = actualPosition === number;
  const textActiveClass = overrideClasses.textActive
    ? overrideClasses.textActive
    : classes.textActive;
  const textInactiveClass = overrideClasses.textInactive
    ? overrideClasses.textInactive
    : classes.textInactive;

  return (
    <div className={classes.step} onClick={onClick}>
      <CircleWithNumber
        number={number}
        activeStep={actualPosition}
        overrideClasses={overrideClasses}
      />
      <span
        style={{
          left: 20,
          minWidth: "200px",
          position: "absolute",
          top: 2,
          marginLeft: "12px",
          verticalAlign: "middle",
          height: "38px",
          display: "flex",
          alignItems: "center"
        }}
      >
        <Typography
          className={`${classes.text} ${
            active ? textActiveClass : textInactiveClass
          }`}
        >
          {label}
        </Typography>
      </span>
    </div>
  );
};

export default Step;
