import gql from "graphql-tag";

export const GET_CONTRACTED_INFO = gql`
  query GetConditionInfo($token: String!, $contractId: String!) {
    GetConditionInfo(token: $token, contractId: $contractId) {
      id
      alias
      imageSrc
      packaging
      slaLevel
      fullPickUpAddress
      aliasForLanguage
    }
  }
`;
const commonPickUpFieldsFragment = gql`
  fragment CommonPickUpFields on pickUpDetail {
    contractId
    conditionId
    partnerId
    statusId
    aliasId
    contractEndDate
    validTo
    priceFormatFixed
    priceFormatFormula
    quantity
    incoterms
    slaLevel
    pickupFrequency
    packaging
    contracted
    paymentTerms
    fixationTerms
    date
    longDate
    value
    label
    productId
    alias
    old_id
    hasOpenSales
    pickUpFullAddress {
      _id
      companyName
      streetAddress
      zipCode
      country
      city
      transportNotes
      contactId
      type
      default
      numOfAddresses
    }
    showAddressLabel
    location {
      location
      locationId
    }
    lme {
      date
      price {
        name
        sign
        value
      }
    }
    bdsv {
      date
      price
    }
    image {
      url
      imageId
      productId
      productName
    }
    hasSales
    pickups {
      confirmation_required
      pre_scheduled
      confirmed
    }
    hasMultiplePickupAddresses
    contractType
    date_of_next_pickup
    id_of_next_pickup
    collectedQty
    totalQuantity
    aliasForLanguage
  }
`;

export const GET_FULL_PRODUCT_DATA = gql`
  query getFullProductData(
    $token: String!
    $companyId: String!
    $location: String
  ) {
    FullProductData(token: $token, companyId: $companyId, location: $location) {
      contracted {
        ...CommonPickUpFields
      }
      priceRequested {
        ...CommonPickUpFields
      }
      priceReceived {
        ...CommonPickUpFields
      }
      noSaleInProgress {
        ...CommonPickUpFields
      }
      allLocations {
        location
        locationId
      }
      hasTransactions
    }
  }
  ${commonPickUpFieldsFragment}
`;

export const FLAG_AS_SALE_REQUESTED = gql`
  mutation saleRequested(
    $token: String!
    $productId: String!
    $partnerId: String!
    $packaging: String!
    $quantity: String!
    $frequency: String!
    $pickupDate: String
    $aditionalComments: String
    $file: Upload
  ) {
    saleRequested(
      token: $token
      productId: $productId
      partnerId: $partnerId
      packaging: $packaging
      quantity: $quantity
      frequency: $frequency
      pickupDate: $pickupDate
      aditionalComments: $aditionalComments
      file: $file
    ) {
      message
      leadId
    }
  }
`;

export const CONFIRM_SALE_REQUESTED = gql`
  mutation confirmSale($token: String!, $conditionId: String!) {
    confirmSale(token: $token, conditionId: $conditionId) {
      message
      pickup {
        _id
        transactionId
        productId
        pickupName
        companyName
        streetAddress
        zipCode
        city
        country
        pickupDate
        quantity
        items
        truckType
        truckNumberPlate
        imageSrc
        isContracted
        files
        packaging
        collectionType
        collectionDate
        collectionDateRange
        statusId
        status
        canConfirmPickup
        canReschedulePickup
        slaLevel
        new
        aliasForLanguage
      }
      pickups {
        confirmation_required
        pre_scheduled
        confirmed
      }
      scheduledPickups {
        _id
        transactionID
        quantity
        scheduledDate
      }
    }
  }
`;
