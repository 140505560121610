import {
  ONBOARDING_PERSONAL_INFORMATION,
  ONBOARDING_COMPANY_INFORMATION,
  ONBOARDING_TRANSPORT_INFORMATION,
  ONBOARDING_BILLING_AND_PAYMENT_INFORMATION
} from "./constants";
import { capitalize } from "@material-ui/core";

export const getInputForSaveMutation = (step, formData) => {
  switch (step) {
    case ONBOARDING_PERSONAL_INFORMATION:
      return {
        firstName: formData.firstName,
        lastName: formData.lastName,
        companyRole: formData.companyRole,
        email: formData.email,
        phone: formData.phone,
        mobile: formData.mobile
      };
    case ONBOARDING_COMPANY_INFORMATION:
      return {
        companyName: formData.companyName,
        streetAddress: formData.streetAddress,
        zipCode: formData.zipCode,
        city: formData.city,
        country: formData.country
      };

    case ONBOARDING_TRANSPORT_INFORMATION:
      return {
        companyName: formData.transportCompanyName,
        streetAddress: formData.transportStreetAndNumber,
        zipCode: formData.transportZipCode,
        city: formData.transportCity,
        country: formData.transportCountry
      };

    case ONBOARDING_BILLING_AND_PAYMENT_INFORMATION:
      return {
        UID: formData.vat,
        IBAN: formData.iban,
        name: formData.mainAccountingName,
        email: formData.mainAccountingEmail,
        sendInvite: formData.sendInvite
      };
    default:
      return {};
  }
};

export const saveCredentialsMutation = async (
  sendCloseMutation,
  token,
  keepToken = true,
  sourceQueryObj
) => {
  const { data } = await sendCloseMutation({
    variables: {
      token,
      keepToken
    }
  });
  const { id, token: newToken, tokenExpires } = data?.OnboardingLoginMutation;
  if (!newToken) return null;

  //Create the object for the new credentials
  let new_credentials = {
    user: {
      id: id,
      token: newToken,
      sellerId: null,
      tokenExpires: tokenExpires,
      loginErrorMessage: ""
    }
  };
  //We update the credentials of the session with the new ones of the impersonated user
  localStorage.setItem("s24-user", JSON.stringify(new_credentials));

  //If we have have a destination url we redirect there (example: sales/open)
  const url = sourceQueryObj?.url;
  if (url) {
    delete sourceQueryObj.url;
    //We concatenate the query string to the url (example: the transacionId)
    const getUrl = new URLSearchParams(sourceQueryObj).toString();
    window.location.replace(
      `${window.location.protocol}//${window.location.host}${url.startsWith("/") ? url : "/" + url}${getUrl ? "?" + getUrl : ""}`
    );
    return;
  }

  //We redirect the window to the dashboard
  window.location.replace(
    window.location.protocol + "//" + window.location.host + "/dashboard"
  );
};

export const getFieldsChangedInOnboardingStep = (
  initialForm,
  actualForm,
  step,
  cb = () => {}
) => {
  const initialFormData = getInputForSaveMutation(step, initialForm);
  const actualFormData = getInputForSaveMutation(step, actualForm);

  cb(actualFormData);

  return Object.keys({ ...initialFormData, ...actualFormData }).reduce(
    (a, b) => {
      const initialFieldValue = initialFormData[b];
      const actualFieldValue = actualFormData[b];

      if (initialFieldValue === actualFieldValue) {
        return a;
      }

      if (initialFieldValue) {
        a[getFieldNameWithPrefix("old", b)] = initialFieldValue;
      }

      if (actualFieldValue) {
        a[getFieldNameWithPrefix("new", b)] = actualFieldValue;
      }

      return a;
    },
    {}
  );
};

const getFieldNameWithPrefix = (prefix, fieldName) => {
  if (!prefix) {
    return fieldName;
  }

  return prefix.toString() + capitalize(fieldName[0]) + fieldName.slice(1);
};
