import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { Fade, IconButton, Button, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {
  ImageOutlined,
  CloseOutlined,
  SaveAltOutlined,
  // Send,
  RemoveOutlined,
  ZoomInOutlined,
  AddOutlined
} from "@material-ui/icons";
import Loader from "../Loader";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "100%",
    position: "fixed",
    zIndex: "1300",
    inset: "0",
    boxSizing: "border-box"
  },
  modal__backdrop: {
    boxSizing: "border-box",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: "flex",
    zIndex: -1,
    position: "fixed",
    alignItems: "center",
    justifyContent: "center",
    // backgroundColor: "rgba(0, 0, 0, 0.5)",
    background: "#151124",
    mixBlendMode: "multiply",
    opacity: "0.8"
    // -webkitTapHighlightColor: "transparent"
  },
  modal__header: {
    boxSizing: "border-box",
    height: "56px",
    padding: "8px",
    width: "100%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    color: "#fff"
  },
  modal__headerLeft: {
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    width: "250px"
  },
  modal__headerCenter: {
    width: "100%",
    flex: "1",
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    boxSizing: "border-box"
  },
  modal__headerLeftTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "inherit",
    margin: "0 0 0 8px",
    boxSizing: "border-box",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden"
  },
  modal__headerRight: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    justifyContent: "right",
    boxSizing: "border-box",
    width: "250px"
  },
  modal__body: {
    height: "calc(100% - 56px)",
    width: "100%",
    // maxWidth: "552.42px",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box"
  },
  modal__preview_area: {
    flex: "1",
    overflowY: "auto",
    // backgroundColor: "white",
    boxSizing: "border-box",
    position: "relative"
  },
  modal__zoom_area: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    boxSizing: "border-box",
    position: "fixed",
    // bottom: "0",
    left: "0",
    right: "0",
    bottom: "24px",
    left: "50%",
    transform: "translateX(-50%)",
    width: "240px"
  },
  controls: {
    display: "flex",
    alignItems: "center",
    backgroundColor: theme.palette.primary.main,
    color: "#fff",
    boxSizing: "border-box"
  },
  controls__left: {
    paddingLeft: "8px",
    paddingRight: "7px",
    boxSizing: "border-box",
    width: "120px" // TODO NOT NEEDE
  },
  controls__pagination: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    textAlign: "center",
    letterSpacing: "0.25px",
    boxSizing: "border-box"
  },
  SaveAltOutlined: {
    marginRight: "16px",
    boxSizing: "border-box"
  },
  paginationButton: {
    "& span": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      textTransform: "capitalize",
      display: "inline-flex",
      gap: "11px"
    }
  },
  loaderContainer: {
    position: "absolute",
    top: "0",
    bottom: "0",
    right: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "1"
  },
  [theme.breakpoints.down("xs")]: {
    modal__header: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0, 0.87)"
    },
    modal__headerCenter: {
      display: "none !important;"
    },
    modal__headerRight: {
      display: "none !important;"
    },
    modal__headerLeftTitle: {
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.25px"
    },
    modal__body: {
      width: "100%",
      maxWidth: "100%"
    },
    modal__zoom_area: {
      height: "80px",
      width: "100%",
      padding: "20px 16px",
      alignItems: "center",
      gap: "16px",
      backgroundColor: "#fff",
      boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.12)",
      position: "relative",
      bottom: "auto",
      left: "auto",
      right: "auto",
      transform: "none"
    }
  }
}));

const DocPreviewModal = props => {
  const classes = useStyles();

  const closeOnEscapeKeyDown = e => {
    if ((e.charCode || e.keyCode) === 27) {
      props.onClose();
    }
  };

  const onClickOnPreviewArea = event => {
    if (event.target.dataset.closedModal) {
      props.onClose();
    }
  };

  useEffect(() => {
    document.body.addEventListener("keydown", closeOnEscapeKeyDown);
    return function cleanup() {
      document.body.removeEventListener("keydown", closeOnEscapeKeyDown);
    };
  }, []);

  return ReactDOM.createPortal(
    <Fade in={props.visible} unmountOnExit>
      <div
        role="presentation"
        aria-labelledby="transition-modal-title"
        className={classes.modal}
      >
        {props.loading && (
          <div className={classes.loaderContainer}>
            <Loader />
          </div>
        )}
        <div
          className={classes.modal__backdrop}
          aria-hidden="true"
          onClick={props.onClose}
        ></div>
        {/* header */}
        <div className={classes.modal__header}>
          <div className={classes.modal__headerLeft}>
            <Hidden smUp>
              <IconButton
                aria-label="delete"
                color="inherit"
                onClick={props.onClose}
              >
                <CloseOutlined />
              </IconButton>
            </Hidden>
            <Hidden xsDown>
              <IconButton aria-label="delete" color="inherit">
                <ImageOutlined />
              </IconButton>
            </Hidden>
            <small
              className={classes.modal__headerLeftTitle}
              title={props.headerTitle}
            >
              {props.headerTitle}
            </small>
          </div>
          <div className={classes.modal__headerCenter}>
            <Button
              variant="text"
              color="inherit"
              startIcon={<SaveAltOutlined />}
              className={classes.SaveAltOutlined}
              onClick={props.onDownLoad}
              disabled={props.disableEvents}
            >
              Download
            </Button>
            {/* TODO Send email */}
            {/* <Button
              variant="text"
              color="inherit"
              startIcon={<Send />}
              onClick={props.onSendEmail}
            >
              Send Email
            </Button> */}
          </div>
          <div className={classes.modal__headerRight}>
            <IconButton
              aria-label="delete"
              color="inherit"
              onClick={props.onClose}
            >
              <CloseOutlined />
            </IconButton>
          </div>
        </div>

        <div className={classes.modal__body} onClick={onClickOnPreviewArea}>
          {/* preview area */}
          <div className={classes.modal__preview_area} data-closed-modal="true">
            {props.children}
          </div>

          {/* controls and download/send email button on mobile */}
          <div className={classes.modal__zoom_area}>
            <Hidden smUp>
              <Button
                variant="contained"
                color="primary"
                startIcon={<SaveAltOutlined />}
                onClick={props.onDownLoad}
                disabled={props.disableEvents}
              >
                Download
              </Button>
              {/* TODO send email */}
              {/* <Button
                variant="contained"
                color="primary"
                startIcon={<Send />}
                onClick={props.onSendEmail}
              >
                Send Email
              </Button> */}
            </Hidden>
            {props.hideControls ? null : (
              <Hidden xsDown>
                <div className={classes.controls}>
                  <div className={classes.controls__left}>
                    <Button
                      variant="text"
                      color="inherit"
                      className={classes.paginationButton}
                    >
                      <span className={classes.controls__pagination}>
                        <span>Page</span>
                        <span>{props.currentPage}</span>
                        <span>/</span>
                        <span>{props.totalPages}</span>
                      </span>
                    </Button>
                  </div>
                  <IconButton
                    aria-label="delete"
                    color="inherit"
                    onClick={props.onZoomOut}
                  >
                    <RemoveOutlined />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    color="inherit"
                    onClick={props.resetZoom}
                  >
                    <ZoomInOutlined />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    color="inherit"
                    onClick={props.onZoomIn}
                  >
                    <AddOutlined />
                  </IconButton>
                </div>
              </Hidden>
            )}
          </div>
        </div>
      </div>
    </Fade>,
    document.body
  );
};

export default DocPreviewModal;
