import { makeStyles } from "@material-ui/core";
import {
  InfoOutlined,
  ReportProblemOutlined,
  ErrorOutline,
  CheckCircleOutlined
} from "@material-ui/icons";
import classNames from "classnames";

const DEFAULT_GAP = 12;

const typeStyles = {
  info: {
    backgroundColor: "rgba(39, 110, 241, 0.10)",
    icon: InfoOutlined
  },
  warning: {
    backgroundColor: "rgba(255, 192, 67, 0.10)",
    icon: ErrorOutline
  },
  danger: {
    backgroundColor: "rgba(225, 25, 0, 0.10)",
    icon: ReportProblemOutlined
  },
  success: {
    backgroundColor: "rgba(82, 245, 151, 0.10)",
    icon: CheckCircleOutlined
  }
};

const getTypeStyles = type => {
  const style = typeStyles[type] || null;

  if (!style) return { icon: InfoOutlined };

  return style;
};

const useStyles = makeStyles(theme => ({
  container: {
    padding: "24px 24px 24px 20px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 16px 16px 12px"
    }
  }
}));

const InformationalComponent = ({
  type,
  gap = DEFAULT_GAP,
  noPadding,
  className,
  children
}) => {
  const classes = useStyles();

  const { icon: IconComponent, backgroundColor } = getTypeStyles(type);

  const style = {
    backgroundColor,
    gap: Number(gap) || DEFAULT_GAP,
    ...(noPadding ? { padding: 0 } : null)
  };

  return (
    <div className={classNames(classes.container, className)} style={style}>
      {IconComponent && <IconComponent />}
      <div style={{ width: "100%" }}>{children}</div>
    </div>
  );
};

export default InformationalComponent;
