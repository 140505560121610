import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";

import styles from "./LanguagePicker.module.css";

import { FALLBACK_LANGUAGE, LANGUAGES, FLAGS } from "../../utils/constants";

import { Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  countryNameTypography: {
    color: "black",
    opacity: 0.6,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    "& a": {
      color: "black"
    },
    marginLeft: "36px",
    cursor: "pointer",
    backgroundColor: "white",
    textDecoration: "underline",
    marginBottom: "100px"
  },
  countryNameTypographyOption: {
    color: "black",
    opacity: 0.6,
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "10px",
    letterSpacing: "0.4px",
    "& a": {
      color: "black"
    },
    marginLeft: "36px",
    cursor: "pointer",
    backgroundColor: "white",
    marginBottom: "100px"
  },
  conuntryNameBackground: {
    backgroundColor: "red",
    zIndex: 1100,
    width: "100%"
  }
}));

const LanguagePicker = ({
  current = FALLBACK_LANGUAGE,
  available = [],
  direction = "down",
  colDirection = ["up", "down"].indexOf(direction) !== -1 ? "right" : "down",
  rows,
  onChange = () => {},
  onChangeEnd = () => {}
}) => {
  const pickerRef = useRef();
  const [language, setLanguage] = useState(current);
  const classes = useStyles();
  const [visible, setVisible] = useState(false);

  const [style, setStyle] = useState({});

  const toggleLanguagePicker = () => {
    setVisible(!visible);
    pickerRef.current.classList.toggle("is--active");
    pickerRef.current.dataset.active =
      pickerRef.current.classList.contains("is--active");
  };

  useEffect(() => {
    let language = current;
    if (!LANGUAGES.includes(language)) {
      language = FALLBACK_LANGUAGE;
    }

    setLanguage(language);
  }, [current]);

  const handleChangeLanguage = lang => {
    onChange(lang);
    setTimeout(() => {
      onChangeEnd(lang);
    }, 300);
  };

  const generateAvailableLanguages = () =>
    available
      .filter(lang => lang !== language)
      .map((lang, i) => (
        <Fragment key={`lang-selector-${lang}`}>
          <div
            className={`language-picker__container ${styles.container}`}
            style={{
              zIndex: language === lang ? 2 : ""
            }}
            onClick={() => handleChangeLanguage(lang)}
          >
            <div className={`language-picker__language ${styles.language}`}>
              <img alt={FLAGS[lang]?.label} src={FLAGS[lang]?.img} />
            </div>
            <Typography
              className={classes.countryNameTypographyOption}
              style={
                visible ? { display: "inline-block" } : { display: "none" }
              }
            >
              {FLAGS[lang].label}
            </Typography>
          </div>
        </Fragment>
      ));

  const calculateStyle = useCallback(() => {
    let rectangle = pickerRef?.current?.getBoundingClientRect();

    let size = { maxHeight: "", maxWidth: "" };

    if (["down", "up"].indexOf(direction) !== -1) {
      if (direction === "down") {
        size.height = Math.floor(
          ((window.innerHeight - 20 - (rectangle?.top || 0)) / 30) * 30
        );
      } else {
        size.height = rectangle?.top || 0;
      }
      size.maxHeight = rows ? rows * 30 : size.height;

      size.width =
        (Math.ceil(
          (available.length - 1) / Math.floor((size?.height || 0) / 30)
        ) || 1) * 40;
    } else if (["left", "right"].indexOf(direction) != -1) {
      if (direction === "left") {
        size.width = Math.floor(
          ((window.innerWidth - 30 - (rectangle?.left || 0)) / 40) * 40
        );
      } else {
        size.width = rectangle?.right - 40 || 0;
      }
      size.maxWidth = rows ? rows * 40 : size.width;

      size.height =
        (Math.ceil(
          (available.length - 1) / Math.floor((size?.width || 0) / 40)
        ) || 1) * 30;
    }

    setStyle({
      width: `${size.width}px`,
      maxWidth: `${size.width}px`,
      height: `${size.height}px`,
      maxHeight: `${size.maxHeight}px`
    });
  }, [available.length, direction, rows]);

  useEffect(() => {
    if (rows && typeof rows !== "number") {
      throw new Error(`'rows' prop must be a number`);
    }

    if (["down", "up"].indexOf(direction) !== -1) {
      if (["left", "right"].indexOf(colDirection) === -1) {
        throw new Error(`'colDirection' prop must be 'left' or 'right'`);
      }
    } else if (["left", "right"].indexOf(direction) !== -1) {
      if (["down", "up"].indexOf(colDirection) === -1) {
        throw new Error(`'colDirection' prop must be 'down' or 'up'`);
      }
    } else {
      throw new Error(
        `'direction' prop must be 'down', 'up', 'left' or 'right'`
      );
    }
    calculateStyle();
  }, [direction, colDirection, rows, calculateStyle]);

  useEffect(() => {
    const handleResize = () => {
      calculateStyle();
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [calculateStyle]);

  return (
    <div
      ref={pickerRef}
      className={`language-picker ${styles.picker}`}
      data-direction={direction}
      data-col-direction={colDirection}
      onClick={toggleLanguagePicker}
    >
      <div
        className={`language-picker__current ${styles.current} ${styles.language}`}
        style={{ display: "inline-block" }}
      >
        <img alt={FLAGS[language]?.label} src={FLAGS[language]?.img} />
      </div>
      {available.length !== 0 && (
        <div
          className={`language-picker__selector ${styles.selector}`}
          style={style}
        >
          {generateAvailableLanguages()}
        </div>
      )}
      <Typography
        className={classes.countryNameTypography}
        style={{ zIndex: 5 }}
      >
        {" "}
        {FLAGS[language]?.label}
      </Typography>
    </div>
  );
};

export default LanguagePicker;
