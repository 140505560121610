import gql from "graphql-tag";

export default gql`
  mutation addOrEditContact(
    $input: ContactInput
    $company: String!
    $token: String!
    $id: String!
  ) {
    addOrEditContact(input: $input, company: $company, token: $token, id: $id) {
      message
    }
  }
`;
