import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import CategoryListHeader from "./CategoryListHeader";
import CategoryListHeaderMobile from "./CategoryListHeaderMobile";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "@material-ui/core/Hidden";
import CategoryElements from "./CategoryElements";
import CategoryMobileFooter from "./CategoryMobileFooter";
import InputLabel from "@material-ui/core/InputLabel";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import { SelectMenuProps } from "../../../../core/shared/constants";

const useStyles = makeStyles(theme => ({
  categorySelector: {
    width: "239px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: "50px"
    }
  },
  categorySelect: {
    height: "40px",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "0px",
      paddingLeft: "0px"
    }
  },
  categoryTitleWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 20px",
    marginLeft: "32px",
    marginRight: "32px",
    marginTop: "32px",
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 10px",
    color: "grey",
    fontSize: "20px"
  },
  textWrapper: {
    display: "flex",
    flexDirection: "column",
    paddingTop: "26px",
    paddingLeft: "32px",
    background: "white",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      marginRight: "16px",
      marginLeft: "16px",
      paddingLeft: "0px"
    }
  },
  selectLabel: {
    background: "#ffffff",
    paddingLeft: "5px",
    paddingRight: "5px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    mixBlendMode: "normal",
    transform: "translate(14px, -6px) !important"
  }
}));

const CategoryList = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const [showActiveCategories, setShowActiveCategories] = useState(true);

  const setSelectedCategory = event => {
    const value = event.target.value;
    let query = queryString.parse(location.search);
    const searchParams = new URLSearchParams(query);
    value
      ? searchParams.set("category", value)
      : searchParams.delete("category");
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  const {
    categories,
    removeCategoryFromAccepted,
    addCategoryToAccepted,
    products,
    linkCategoryTo,
    selectedCompany,
    token,
    category
  } = props;

  let selectedCategory = "all";
  let acceptedCategories = categories.filter(cat => cat.accepted);
  let notAcceptedCategories = categories.filter(cat => !cat.accepted);
  if ("all" !== category) {
    acceptedCategories = acceptedCategories.filter(cat => cat.id === category);
    notAcceptedCategories = notAcceptedCategories.filter(
      cat => cat.id === category
    );

    selectedCategory = categories.find(cat => cat.id === category)?.id;
  }

  return (
    <>
      <Hidden smDown>
        <CategoryListHeader selectedCompany={selectedCompany} token={token} />
      </Hidden>
      <div className={classes.textWrapper}>
        <FormControl variant="outlined" className={classes.categorySelector}>
          <InputLabel id="category-dropdown-label">{t("Category")}</InputLabel>
          <Select
            labelId="category-dropdown-label"
            id="select-category"
            value={selectedCategory}
            onChange={setSelectedCategory}
            maxVisibleOptions={"14"}
            label={t("Category")}
            className={classes.selectProduct}
            MenuProps={SelectMenuProps}
          >
            <MenuItem value="all">{t("All")}</MenuItem>
            {categories.map(item => (
              <MenuItem value={item.id} kay={item.id}>
                {t(item.name)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Hidden mdUp>
          <CategoryListHeaderMobile
            selectedCompany={selectedCompany}
            token={token}
          />
        </Hidden>
      </div>
      <Hidden smDown>
        <CategoryElements
          acceptedCategories={acceptedCategories}
          notAcceptedCategories={notAcceptedCategories}
          products={products}
          removeCategoryFromAccepted={removeCategoryFromAccepted}
          addCategoryToAccepted={addCategoryToAccepted}
          linkCategoryTo={linkCategoryTo}
          showActiveCategories={true}
          showInactiveCategories={true}
        />
      </Hidden>
      <Hidden mdUp>
        <CategoryElements
          acceptedCategories={acceptedCategories}
          notAcceptedCategories={notAcceptedCategories}
          products={products}
          removeCategoryFromAccepted={removeCategoryFromAccepted}
          addCategoryToAccepted={addCategoryToAccepted}
          linkCategoryTo={linkCategoryTo}
          showActiveCategories={showActiveCategories}
          showInactiveCategories={!showActiveCategories}
        />

        <CategoryMobileFooter
          showActive={showActiveCategories}
          activeText={t("Active categories")}
          setShowActive={setShowActiveCategories}
          inactiveText={t("Inactive categories")}
        />
      </Hidden>
    </>
  );
};

export default CategoryList;
