import { useTranslation } from "react-i18next";

import DropzoneDialog from "./1-DropzoneDialog";
import AnalyzingFile from "./2-AnalyzingFile";
import FixErrors from "./3-FixErrors";
import LocationPairing from "./4-LocationPairing";
import MaterialPairing from "./5-MaterialPairing";
import SendFile from "./6-SendFile";
import FileSent from "./7-FileSent";
import ContractsPairing from "./8-ContractsPairing";
import { makeStyles, Typography } from "@material-ui/core";

import {
  IMPORT_DATA_CONTRACT_TYPE,
  IMPORT_DATA_TRANSACTION_TYPE
} from "../utils/constants";
import { applyFnOrValue } from "../../../../../utils/SharedUtils";

//Change this for actual transaction keys
const TITLES = {
  [IMPORT_DATA_CONTRACT_TYPE]: {
    title: "dialog_title_import_con"
  },
  [IMPORT_DATA_TRANSACTION_TYPE]: {
    title: "dialog_title_import_tran"
  },
  default: {
    title: "UploadFiles_UploadOtherDocs"
  }
};

const getStepTitleByType = type => {
  const { title } = TITLES[type] || TITLES.default;
  return title || "";
};

const getStepIconByType = type => {
  const titleObj = TITLES[type] || TITLES.default;
  return titleObj.icon;
};

const getType = type => type;

export const CONTRACTS_STEPS = [
  {
    title: getStepTitleByType,
    component: DropzoneDialog,
    hideTitle: true,
    type: getType
  },
  {
    title: getStepTitleByType,
    component: AnalyzingFile,
    subtitle: "import_dialog_anaFile_subheader"
  },
  {
    title: getStepTitleByType,
    component: FixErrors,
    subtitle: "import_dialog_error_fix_them"
  },
  {
    title: getStepTitleByType,
    component: LocationPairing,
    subtitle: "import_dialog_locations_subheader"
  },
  {
    title: getStepTitleByType,
    component: MaterialPairing,
    subtitle: "import_dialog_materials_subheader"
  },
  {
    title: getStepTitleByType,
    component: SendFile,
    subtitle: "import_dialog_send_files_subheader"
  },
  {
    title: getStepTitleByType,
    component: FileSent
  }
];

export const TRANSACTIONS_STEPS = [
  {
    title: getStepTitleByType,
    titleIcon: getStepIconByType,
    component: DropzoneDialog,
    type: getType
  },
  {
    title: getStepTitleByType,
    titleIcon: getStepIconByType,
    component: AnalyzingFile,
    subtitle: "import_dialog_anaFile_subheader"
  },
  {
    title: getStepTitleByType,
    titleIcon: getStepIconByType,
    component: FixErrors,
    subtitle: "import_dialog_error_fix_them"
  },
  {
    title: getStepTitleByType,
    titleIcon: getStepIconByType,
    component: LocationPairing,
    subtitle: "import_dialog_locations_subheader"
  },
  {
    title: getStepTitleByType,
    titleIcon: getStepIconByType,
    component: MaterialPairing,
    subtitle: "import_dialog_materials_subheader"
  },
  {
    title: getStepTitleByType,
    titleIcon: getStepIconByType,
    component: ContractsPairing,
    subtitle: "import_dialog_contracts_subheader"
  },
  {
    title: getStepTitleByType,
    titleIcon: getStepIconByType,
    component: SendFile,
    subtitle: "import_dialog_send_files_subheader"
  },
  {
    title: getStepTitleByType,
    titleIcon: getStepIconByType,
    component: FileSent
  }
];

const useStyles = makeStyles(theme => ({
  headerContainer: {
    fontWeight: "400 !important",
    padding: "28px 32px",
    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)"
  },
  title: {
    fontSize: "32px",
    lineHeight: "40px",
    fontWeight: "400",
    color: "rgba(0, 0, 0, 0.87)",
    marginBottom: "8px",
    display: "block"
  },
  pageSubTitle: {
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)",
    fontSize: "16px",
    lineHeight: "24px"
  }
}));

export const StepTitleComponent = ({ type, title, subtitle }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const titleText = applyFnOrValue(title, type);

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.title}>
          {t(titleText)}
        </Typography>
        {subtitle && (
          <Typography variant="subtitle1" className={classes.pageSubTitle}>
            {t(subtitle)}
          </Typography>
        )}
      </div>
    </>
  );
};
