import React from "react";
import Grid from "@material-ui/core/Grid";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { ROUTES } from "../../components/core/shared/constants";
import Transport from "./Transport";
import CompanyInformation from "./CompanyInformation";
import Information from "./Information";
import PaymentInformation from "./PaymentInformation";
import Paper from "@material-ui/core/Paper";
import UserContext from "../../providers/UserProvider";
import { CheckPermissions } from "../../components/core/shared/CheckPermissions";
import AccountHeader from "../../components/account/AccountHeader";
import CompanyDocuments from "./CompanyDocuments";
import UserListPage from "./UserListPage";
import { Button } from "@material-ui/core";
import GeneralMobileContainerWrapper from "../../components/core/shared/GeneralMobileContainerWrapper";
import AddUser from "../../components/account/users/AddUser";
import EditUser from "../../components/account/users/EditUser";
import NotificationPreferences from "./NotificationPreferences";
import getUserRoles from "../../queries/GetUserRoles";
import { useQuery } from "react-apollo";

const useStyles = makeStyles(theme => ({
  mainWrapper: {
    alignContent: "flex-start",
    [theme.breakpoints.down("sm")]: {
      padding: "0"
    }
  },
  filtersWrapper: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center"
  },
  fullWidth: {
    width: "100%"
  },
  title: {
    margin: "34px 0 34px 0",
    display: "flex",
    justifyContent: "space-between"
  },
  tableHolder: {
    justifyContent: "flex-start"
  },
  selectCompanyMessage: {
    textAlign: "center"
  }
}));

const AccountContainer = ({ queryType, user, selectedCompany }) => {
  const theme = useTheme();
  const history = useHistory();
  const classes = useStyles(theme);
  const { t } = useTranslation();
  const params = useParams();

  const { loading, data } = useQuery(getUserRoles, {
    variables: {
      token: user.token,
      partnerId: selectedCompany,
      userId: user.id
    }
  });
  if (loading) return null;
  if (selectedCompany === "all") {
    return (
      <Paper>
        <div className={classes.selectCompanyMessage}>
          {t("Please select a company")}
        </div>
      </Paper>
    );
  } else {
    return (
      <Grid container className={classes.mainWrapper}>
        <UserContext.Consumer>
          {({ userData: { UserCompanies } }) => (
            <CheckPermissions
              selectedCompany={selectedCompany}
              userCompanies={UserCompanies}
              routes={ROUTES}
            >
              <Grid item xs={12}>
                {
                  {
                    companyInformation: (
                      <>
                        <AccountHeader
                          title={t("Company Information")}
                          subtitle={t(
                            "Here you can change your company information"
                          )}
                        />
                        <CompanyInformation
                          user={user}
                          selectedCompany={selectedCompany}
                        />
                      </>
                    ),
                    userInformation: (
                      <>
                        <AccountHeader
                          title={t("My Profile")}
                          subtitle={t(
                            "Here you can see or edit your contact information and change your password"
                          )}
                        />
                        <Information
                          user={user}
                          selectedCompany={selectedCompany}
                          userCompanies={UserCompanies}
                        />
                      </>
                    ),
                    users: (
                      <>
                        <AccountHeader
                          title={t("Users")}
                          subtitle={t(
                            "Here you can add or remove users and set their roles"
                          )}
                          extraActions={
                            <>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  history.push("/profile/users/add")
                                }
                              >
                                {t("Account_AddUser")}
                              </Button>
                            </>
                          }
                        />
                        <UserListPage
                          user={user}
                          selectedCompany={selectedCompany}
                        />
                      </>
                    ),
                    addUser: (
                      <>
                        <AccountHeader
                          title={t("Users")}
                          subtitle={t(
                            "Here you can add or remove users and set their roles"
                          )}
                        />
                        <GeneralMobileContainerWrapper>
                          <AddUser
                            onCancel={() => history.goBack()}
                            user={user}
                            selectedCompany={selectedCompany}
                          />
                        </GeneralMobileContainerWrapper>
                      </>
                    ),
                    editUser: (
                      <>
                        <AccountHeader
                          title={t("Users")}
                          subtitle={t(
                            "Here you can add or remove users and set their roles"
                          )}
                        />
                        <GeneralMobileContainerWrapper>
                          <EditUser
                            onCancel={() => history.goBack()}
                            user={user}
                            selectedCompany={selectedCompany}
                          />
                        </GeneralMobileContainerWrapper>
                      </>
                    ),
                    equipment: (
                      <>
                        <AccountHeader
                          title={t("Transport Information")}
                          subtitle={t(
                            "Here you can edit the information relating to your pickup and delivery addresses"
                          )}
                        />
                        <Transport
                          user={user}
                          selectedCompany={selectedCompany}
                        />
                      </>
                    ),
                    paymentInformation: (
                      <>
                        <AccountHeader
                          title={t("billing&payment_header")}
                          subtitle={t(
                            "Here you are able to change both your billing address and payment details"
                          )}
                        />
                        <PaymentInformation
                          user={user}
                          selectedCompany={selectedCompany}
                          params={params}
                        />
                      </>
                    ),
                    documents: (
                      <>
                        <AccountHeader
                          title={t("Documents")}
                          subtitle={t("Here you can find all your documents")}
                        />
                        <CompanyDocuments
                          user={user}
                          selectedCompany={selectedCompany}
                          params={params}
                        />
                      </>
                    ),
                    notifications: (
                      <>
                        <AccountHeader
                          title={t("Notifications")}
                          subtitle={t("notifications_page_subtitle")}
                          extraActions={
                            <span>
                              <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                                {t("Your assigned Company Role")}{" "}
                              </span>
                              <span style={{ color: "black" }}>
                                {data.getUserRoles[0]?.label}
                              </span>
                            </span>
                          }
                          column={true}
                        />
                        <NotificationPreferences
                          user={user}
                          selectedCompany={selectedCompany}
                        />
                      </>
                    )
                  }[queryType]
                }
              </Grid>
            </CheckPermissions>
          )}
        </UserContext.Consumer>
      </Grid>
    );
  }
};

export default AccountContainer;
