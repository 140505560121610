import { getEnviroment } from "../config/config";

export const reportToSlack = (actionType, target_object) => {
  const env = getEnviroment();
  if (env !== "production" || !process.env.REACT_APP_SLACK_TOKEN) return;

  fetch("https://app.scrap24.com/reportToSlack", {
    method: "POST",
    headers: {
      "Content-Type": "application/json; charset=utf-8"
    },
    body: JSON.stringify({
      token: process.env.REACT_APP_SLACK_TOKEN,
      actionType,
      target_object
    })
  });
};
