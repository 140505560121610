import React, { useEffect, useContext, useState } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-apollo";
import getAllRoles from "../../../queries/getAllRoles";
import INVITE_NEW_USER from "../../../mutations/inviteNewUser";
import { validateEmail } from "../../../utils/Format";
import CompanyUserQuery from "../../../queries/CompanyUserQuery";
import useEventTracking from "../../../hooks/useEventTracking";
import { showError } from "../../core/shared/Notify";
import {
  Hidden,
  Typography,
  FormControl,
  Button,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import { DropdownWithPills } from "../../general/DropdownWithPills/DropdownWithPills";
import { useNotifications } from "../../../hooks/Notifications";
import UserContext from "../../../providers/UserProvider";
import { PROFILE_LANGUAGES } from "../../../utils/constants";
import { SelectMenuProps } from "../../core/shared/constants";
import hasInjectionAttack from "../../../utils/checkInjectionAttack";
const useStyles = makeStyles(theme => ({
  subtitle: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "400",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0px 0px 16px 0px"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    maxWidth: 400,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #9E9E9E"
    }
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 16
  },
  headerContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0",
      gap: 16
    }
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: 0.25
  }
}));

export default ({ user, selectedCompany, onCancel }) => {
  const theme = useTheme();
  const { pushNotification } = useNotifications();
  const { t } = useTranslation();
  const classes = useStyles(theme);
  const [submit, setSubmit] = useState(false);
  const userContext = useContext(UserContext);
  const currentUser = userContext?.userData?.RawUserData;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [language, setLanguage] = useState(null);

  const [arrOfOptions, setArrOfOptions] = useState([]);

  const [selectedRole, setSelectedRole] = useState("");

  const { TrackEvent } = useEventTracking();

  const { data, loading, error } = useQuery(getAllRoles, {
    variables: { token: user.token, partnerId: selectedCompany },
    fetchPolicy: "network-only"
  });

  //Tracking that will happen one, on page load
  useEffect(() => {
    TrackEvent("New user addition started", {
      accountId: selectedCompany
    });
  }, []);

  const [
    inviteNewUser,
    {
      data: inviteNewUserData,
      loading: inviteNewUserLoading,
      error: inviteNewUserError
    }
  ] = useMutation(INVITE_NEW_USER);

  useEffect(() => {
    if (!inviteNewUserData) return;

    if (inviteNewUserData) {
      const successMessage =
        inviteNewUserData?.inviteUser?.message || "User invited successfully";
      pushNotification(t(successMessage), { fixed: true, color: "success" });
    }
    onCancel();
  }, [inviteNewUserData]);

  useEffect(() => {
    // TODO: we need to update this for multiple roles
    if (data?.getAllRoles && data.getAllRoles.length)
      setArrOfOptions(
        data.getAllRoles.map(d => ({
          label: d.label,
          value: d.name,
          key: d.id
        }))
      );
  }, [data]);

  const handleChange = event => {
    const {
      target: { value }
    } = event;
    if (value === selectedRole && selectedRole !== "") {
      setSelectedRole("");
      return;
    }
    setSelectedRole(value);
  };

  const isEmailValid = function () {
    return validateEmail(userEmail);
  };

  //function to add new user. This function will be called when the user clicks on the add button
  //in the add user window. This function will call the inviteNewUser mutation and refetch the
  //company users query
  const addUser = () => {
    setSubmit(true);

    if (
      hasInjectionAttack(firstName) ||
      hasInjectionAttack(lastName) ||
      hasInjectionAttack(userEmail)
    ) {
      showError(t("error_injection_attack"));
      setSubmit(false);
      return;
    }
    if (
      !firstName ||
      !lastName ||
      !userEmail ||
      !isEmailValid() ||
      !selectedRole
    )
      return null;

    inviteNewUser({
      variables: {
        token: user.token,
        input: {
          email: userEmail,
          firstName,
          lastName,
          role: selectedRole,
          companyId: selectedCompany,
          adminName: currentUser.firstName + ` ${currentUser.lastName}`,
          language
        }
      },
      refetchQueries: [
        {
          query: CompanyUserQuery,
          variables: { token: user.token, company: selectedCompany }
        }
      ]
    })
      .then(() => {
        TrackEvent("New user added", {
          accountId: selectedCompany,
          firstName,
          lastName,
          email: userEmail,
          role: selectedRole,
          language
        });
      })
      .catch(error => {
        if (
          error.graphQLErrors[0].message.replace(/[\[\]]/g, "") ===
          "exist_email"
        ) {
          showError(t("exist_email_message"));
          return;
        }
        showError(error.message);
      });
  };

  return (
    <div>
      <div className={classes.headerContainer}>
        <Hidden mdUp>
          <Close onClick={onCancel} fontSize="small" />
        </Hidden>

        <Typography className={classes.title}>{t("Add a new user")}</Typography>
      </div>

      <Typography variant="h6" gutterBottom className={classes.subtitle}>
        {t("You can add a user or more from here")}
      </Typography>

      <FormControl fullWidth className={classes.container}>
        <TextField
          id="firstName"
          label={t("First name")}
          variant="outlined"
          value={firstName}
          onChange={event => setFirstName(event.target.value)}
          error={!firstName && submit}
          helperText={!firstName && submit && t("This field is compulsory")}
        />
        <TextField
          id="lastName"
          label="Last name"
          variant="outlined"
          value={lastName}
          onChange={event => setLastName(event.target.value)}
          error={!lastName && submit}
          helperText={!lastName && submit && t("This field is compulsory")}
        />
        <TextField
          id="email"
          label="Email"
          variant="outlined"
          value={userEmail}
          onChange={event => setUserEmail(event.target.value)}
          error={(!userEmail || !isEmailValid()) && submit}
          helperText={
            (!userEmail && submit && t("This field is compulsory")) ||
            (!isEmailValid() && submit && t("The email address is not valid"))
          }
        />
        <DropdownWithPills
          handleChange={handleChange}
          arrOfOptions={arrOfOptions}
          arrOfSelected={[selectedRole]}
          label={t("Role")}
          error={!selectedRole && submit}
          errorMessage={
            !selectedRole && submit ? t("This field is compulsory") : null
          }
          multiple={false}
        />
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="preferredLanguage">{t("Language")}</InputLabel>
          <Select
            labelId="preferredLanguage"
            id="Language"
            label={t("Language")}
            defaultValue={language}
            onChange={event => setLanguage(event.target.value)}
            MenuProps={SelectMenuProps}
          >
            {PROFILE_LANGUAGES.map(item => (
              <MenuItem key={item.value} value={item.value}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div className={classes.btnContainer}>
          <Button
            variant="outlined"
            color="primary"
            onClick={onCancel}
            disabled={inviteNewUserLoading}
          >
            {t("Cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={addUser}
            disabled={inviteNewUserLoading}
          >
            {t("Account_Invite")}
          </Button>
        </div>
      </FormControl>
    </div>
  );
};
