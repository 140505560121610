import gql from "graphql-tag";

export default gql`
  mutation DeleteProductImage(
    $token: String!
    $partnerId: String!
    $productId: Int!
  ) {
    DeleteProductImage(
      token: $token
      partnerId: $partnerId
      productId: $productId
    ) {
      message
    }
  }
`;
