import React, { useState, useCallback } from "react";
import {
  createStyles,
  makeStyles,
  FormControl,
  InputLabel,
  InputAdornment,
  Select,
  MenuItem,
  Typography,
  Grid
} from "@material-ui/core";

import MomentUtils from "@date-io/moment";
import { DatePicker } from "@material-ui/pickers";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import Search from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import { Button } from "../offerElement/inputComponents";

import CloseIcon from "@material-ui/icons/Close";
import ClearIcon from "@material-ui/icons/Clear";
import CalendarTodaySharpIcon from "@material-ui/icons/CalendarTodaySharp";
import FilterListIcon from "@material-ui/icons/FilterList";
import { useTranslation } from "react-i18next";
import { filterBySearchFilters } from "../offerList/OfferList";
import { SelectMenuProps } from "../../core/shared/constants";
import { DATE_FORMAT_STRING } from "../../../utils/Format";

const componentStyles = makeStyles(
  createStyles(theme => ({
    SearchTransactionID: {
      border: "1px solid #9E9E9E",
      height: 40,
      minWidth: 320,
      padding: "0 16px",
      "& > input": {
        fontSize: 16,
        letterSpacing: 0.25,
        padding: 0
      },
      "&:hover": {
        border: "1px solid rgba(0, 0, 0, 0.87)"
      },
      [theme.breakpoints.down("md")]: {
        minWidth: "100%",
        marginBottom: 4,
        "&:hover": {
          border: "2px solid #52F597"
        }
      }
    },
    Dropdown: {
      minWidth: 240,
      "& .MuiInputBase-input": {
        padding: "10px 16px",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.5px",
        color: "rgba(0, 0, 0, 0.87)"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        lineHeight: "20px",
        letterSpacing: 0.15,
        color: "rgba(0, 0, 0, 0.6)",
        border: "1px solid #9E9E9E"
      },
      "& .MuiOutlinedInput-root": {
        height: 40
      },
      "& .MuiSelect-select": {
        "&:focus": {
          backgroundColor: "white"
        }
      },
      "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #9E9E9E"
      },
      [theme.breakpoints.down("md")]: {
        width: "100%"
      }
    },
    DateSelector: {
      border: "1px solid #9E9E9E",
      position: "relative",
      paddingRight: "15px",
      cursor: "pointer",
      width: 160,
      height: 40,
      "& > input": {
        padding: "10px 0 10px 16px",
        fontSize: "16px",
        lineHeight: "20px",
        letterSpacing: 0.15,
        color: "rgba(0, 0, 0, 0.87)"
      },
      "&:hover": {
        border: "1px solid rgba(0, 0, 0, 0.87)"
      },
      [theme.breakpoints.down("md")]: {
        width: "100%"
      }
    },
    FiltersButton: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "#FFFFFF",
      cursor: "pointer",
      background: "#151124",
      boxShadow:
        "0px 2px 6px rgba(0, 0, 0, 0.18), 0px 2px 4px rgba(0, 0, 0, 0.24)",
      borderRadius: "64px",
      position: "fixed",
      width: "126px",
      height: "48px",
      top: "calc(100% - 74px)",
      left: " calc(50% - 63px)",
      "&:hover": {
        color: "rgba(255,255,255,0.8)"
      },
      "& > svg": {
        marginRight: "8px"
      }
    },
    FilterPanel: {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      backgroundColor: "#FFFFFF",
      zIndex: 2,
      padding: "0px 16px",
      color: "color: rgba(0, 0, 0, 0.87)"
    },
    filtersHeader: {
      padding: "16px 0 20px"
    },
    filtersHeaderCloseIcon: {
      cursor: "pointer",
      "&:hover": {
        color: "#555555"
      }
    },
    filtersHeaderText: {
      paddingLeft: 16
    },
    filterRow: {
      paddingTop: "20px"
    },
    filterBtnWrapper: {
      paddingTop: "20px"
    }
  }))
);

const SearchTransactionID = ({ onChange, value }) => {
  const { t } = useTranslation();

  return (
    <FormControl fullWidth>
      <TextField
        id="searchTransactionID"
        placeholder={t("Search transaction ID")}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          )
        }}
        variant="outlined"
        onChange={onChange}
        value={value}
      />
    </FormControl>
  );
};

const ShowProductsDropdown = ({ onChange, products, value }) => {
  const classes = componentStyles();
  const { t } = useTranslation();

  return (
    <FormControl fullWidth variant="outlined" className={classes.Dropdown}>
      <InputLabel id="ShowProductsLabel">{t("Show Products")} </InputLabel>
      <Select
        labelId="ShowProductsLabel"
        defaultValue="all"
        label={t("Show Products") + " "}
        onChange={onChange}
        value={value}
        MenuProps={SelectMenuProps}
      >
        <MenuItem value={"all"} key={"all"}>
          {t("All")}
        </MenuItem>
        {products.map(p => (
          <MenuItem value={p.id} key={p.id}>
            {p.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const ExpiryDateSelector = ({ onChange, value, removeValue }) => {
  const classes = componentStyles();
  const { t } = useTranslation();

  return (
    <FormControl fullWidth variant="outlined">
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DatePicker
          name="date"
          emptyLabel={t("Expiry date")}
          InputProps={{
            className: classes.DateSelector,
            disableUnderline: true,
            endAdornment: (
              <InputAdornment position="end">
                {!value ? (
                  <CalendarTodaySharpIcon color="action" />
                ) : (
                  <ClearIcon
                    onClick={e => {
                      e.stopPropagation();
                      (removeValue || (() => {}))();
                    }}
                    style={{ cursor: "pointer" }}
                  />
                )}
              </InputAdornment>
            )
          }}
          onChange={onChange}
          value={value}
          format={DATE_FORMAT_STRING}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  );
};

const SortByDropdown = ({ onChange, value }) => {
  const classes = componentStyles();
  const { t } = useTranslation();

  return (
    <FormControl fullWidth variant="outlined" className={classes.Dropdown}>
      <InputLabel id="SortByLabel">{t("Sort by")} </InputLabel>
      <Select
        labelId="SortByLabel"
        defaultValue="latestFirst"
        label={t("Sort by") + " "}
        onChange={onChange}
        value={value}
        MenuProps={SelectMenuProps}
      >
        <MenuItem value="latestFirst" key="latest">
          {t("Latest to oldest")}
        </MenuItem>
        <MenuItem value="oldestFirst" key="oldestFirst">
          {t("Oldest to latest")}
        </MenuItem>
      </Select>
    </FormControl>
  );
};

const FiltersButton = ({ children, filters, resetFilters, offers }) => {
  const classes = componentStyles();
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);

  const filteredOffers = filterBySearchFilters(offers, filters);

  return (
    <>
      {showFilters && (
        <FiltersPanel
          filters={filters}
          closeAction={toggleFilters}
          resetFilters={resetFilters}
        >
          {children}
        </FiltersPanel>
      )}
      <div
        className={classes.FiltersButton}
        onClick={() => setShowFilters(!showFilters)}
      >
        <FilterListIcon />
        <Typography variant="button">
          {t("Filters")}
          {filters &&
            Object.keys(filters).length !== 0 &&
            ` (${filteredOffers.length})`}
        </Typography>
      </div>
    </>
  );
};

const FiltersPanel = ({ children, filters, closeAction, resetFilters }) => {
  const classes = componentStyles();
  const { t } = useTranslation();

  const hasFilters = filters && Object.keys(filters).length > 0;

  return (
    <div className={classes.FilterPanel}>
      <Grid container direction="column">
        <Grid container item className={classes.filtersHeader}>
          <CloseIcon
            className={classes.filtersHeaderCloseIcon}
            onClick={closeAction}
          />
          <Grid item xs className={classes.filtersHeaderText}>
            <Typography variant="h6">{t("Filters")}</Typography>
          </Grid>
        </Grid>
        {children && children.map ? (
          children.map((c, i) => (
            <Grid item key={i} className={classes.filterRow}>
              {c}
            </Grid>
          ))
        ) : (
          <Grid item className={classes.filterRow}>
            {children}
          </Grid>
        )}
        {hasFilters && (
          <Grid
            item
            container
            className={classes.filterBtnWrapper}
            justifyContent="flex-end"
          >
            <Button color="primary" variant="contained" onClick={resetFilters}>
              {t("Clear All")}
            </Button>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export {
  SearchTransactionID,
  ShowProductsDropdown,
  ExpiryDateSelector,
  SortByDropdown,
  FiltersButton
};
