import React from "react";
import { Redirect, useRouteMatch } from "react-router";
import { canSeeItem } from "../../../utils/SharedUtils";

export const CheckPermissions = ({
  children,
  selectedCompany,
  userCompanies,
  routes,
  isAdmin
}) => {
  if (isAdmin) return children;
  const match = useRouteMatch();

  const route = routes.find(r => r.to === match.path);

  if (
    route &&
    route.canSeeRules &&
    "all" !== selectedCompany && // Before all data loaded selected company is all and redirection does not work
    !canSeeItem(selectedCompany, userCompanies, route.canSeeRules)
  ) {
    return <Redirect to="/dashboard" />;
  }

  return children;
};
