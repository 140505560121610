import React from "react";
import { TextField, InputAdornment } from "@material-ui/core/";
import { makeStyles } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import { CircularProgress } from "@material-ui/core";
const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: "1px !important",
    paddingBottom: "1px !important"
  },
  input: {
    padding: "1px",
    backgroundColor: "red"
  },
  loadingIcon: {
    marginRight: "-45px"
  },
  searchIcon: {
    padding: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "auto",
    marginBottom: "2px",
    marginLeft: "5px",
    marginRight: "-4px"
  },
  textFieldHeight: {
    height: "40px"
  },

  optionWrapper: {
    paddingTop: 0,
    paddingBottom: 0,
    height: "35px",
    display: "flex",
    alignItems: "center"
  }
}));

const SearchBar = ({
  id = "searchbarId",
  onChange,
  onSelect,
  optionList,
  placeholder,
  loading = false
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);

  return (
    <Autocomplete
      classes={{
        option: classes.option
      }}
      getOptionSelected={(option, value) => option.label === value.label}
      value={value}
      filterOptions={e => e}
      onChange={(event, newValue) => {
        setValue(newValue);
        onSelect(newValue);
        setOpen(false);
      }}
      id={id}
      sx={{ width: 300 }}
      ListboxProps={{
        style: { maxHeight: "280px" }
      }}
      open={open}
      onOpen={() => {
        if (optionList.length !== 0) {
          setOpen(true);
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      getOptionLabel={option => option.label}
      options={optionList}
      loading={loading}
      renderOption={option => (
        <div className={classes.optionWrapper}> {option.label}</div>
      )}
      renderInput={params => (
        <TextField
          onChange={onChange}
          {...params}
          variant="outlined"
          className={classes.textFieldHeight}
          placeholder={placeholder}
          InputProps={{
            classes: {
              root: classes.root
            },
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={classes.searchIcon} />
              </InputAdornment>
            ),

            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <div className={classes.loadingIcon}>
                    <CircularProgress color="inherit" size={20} />
                  </div>
                ) : null}
              </React.Fragment>
            )
          }}
        />
      )}
    />
  );
};
export default SearchBar;
