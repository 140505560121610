import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { Info } from "@material-ui/icons";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
  title: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": "400",
    "font-size": "14px",
    "line-height": "20px",
    "letter-spacing": "0.25px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  subtitle: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": "400",
    "font-size": "14px",
    "line-height": "20px",
    "letter-spacing": "0.25px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  icon: {
    width: "20px",
    height: "20px"
  },
  row: {
    display: "flex",
    "flex-direction": "row"
  },
  tooltip: { maxWidth: 320 }
}));

const Sla = ({ level }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let tooltip_text = "";
  switch (level) {
    case "1":
      tooltip_text = t("SLA_Level1_Info").replaceAll("\\n", "\n");
      break;
    case "2":
      tooltip_text = t("SLA_Level2_Info").replaceAll("\\n", "\n");
      break;
    case "3":
      tooltip_text = t("SLA_Level3_Info").replaceAll("\\n", "\n");
      break;
    case "MetaloopAdmin":
      tooltip_text = t("SLA_LevelMetaloopAdmin_Info").replaceAll("\\n", "\n");
      break;
    default:
      tooltip_text = t("SLA_Level1_Info").replaceAll("\\n", "\n");
      break;
  }

  return (
    <div>
      <div className={classes.row}>
        <Typography variant="body2" className={classes.title}>
          {t("SLA")}
        </Typography>
        <Tooltip
          arrow
          placement="top"
          title={<span style={{ whiteSpace: "pre-line" }}>{tooltip_text}</span>}
          className={classes.tooltip}
        >
          <Info className={classes.icon} />
        </Tooltip>
      </div>
      {"\n"}
      <Typography variant="body2" className={classes.subtitle}>
        {t("SLA_Level", {
          level: level
            ? level === "MetaloopAdmin"
              ? "Metaloop Admin"
              : level
            : "1"
        })}
      </Typography>
    </div>
  );
};

export default Sla;
