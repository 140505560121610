import { useContext } from "react";
import UserContext from "../providers/UserProvider";
import { isAtGroupAdminAccount } from "../utils/SharedUtils";

const useIsGroupAdmin = () => {
  const { userData, selectedCompany } = useContext(UserContext);
  const { RawUserData, UserCompanies } = userData;
  const isGroupAdmin =
    RawUserData.isGroupAdmin &&
    isAtGroupAdminAccount(selectedCompany, UserCompanies);

  return isGroupAdmin;
};

export default useIsGroupAdmin;
