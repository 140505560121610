import React, { useState, useEffect } from "react";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import {
  Typography,
  FormControl,
  TextField,
  Button,
  Hidden,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText
} from "@material-ui/core";
import CompanyUserQuery from "../../../queries/CompanyUserQuery";
import getAllRoles from "../../../queries/getAllRoles";
import getSingleUser from "../../../queries/getSIngleUser";
import EDIT_USER from "../../../mutations/editUserWithRoles";
import { DropdownWithPills } from "../../../components/general/DropdownWithPills/DropdownWithPills";
import { validateEmail } from "../../../components/core/forms/validation/emailValidation";
import Close from "@material-ui/icons/Close";
import Loader from "../../../components/core/shared/Loader";
import useEventTracking from "../../../hooks/useEventTracking";
import { useNotifications } from "../../../hooks/Notifications";
import { Mutation } from "react-apollo";
import RESET_PASSWORD from "../../../mutations/resetPasswordMutation";
import { PROFILE_LANGUAGES } from "../../../utils/constants";
import {
  SelectMenuProps,
  METALOOP_ADMIN_ROLE_ID
} from "../../core/shared/constants";

const useStyles = makeStyles(theme => ({
  root: {},
  subtitle: {
    fontSize: "14px",
    lineHeight: "20px",
    fontWeight: "400",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0px 0px 16px 0px"
  },
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
    maxWidth: 400,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #9E9E9E"
    }
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 16
  },
  headerContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      padding: "16px 0",
      gap: 16
    }
  },
  title: {
    fontSize: 20,
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: 0.25
  }
}));

export default ({ user, selectedCompany, onCancel }) => {
  const { id: selectedUser } = useParams();
  const { pushNotification } = useNotifications();
  const { t } = useTranslation();
  const classes = useStyles();
  const [submit, setSubmit] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [language, setLanguage] = useState(null);

  const [arrOfOptions, setArrOfOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [errorMSG, setErrorMSG] = useState("");
  const [minimunOneAdminError, setMinimunOneAdminError] = useState(false);

  const { TrackEvent } = useEventTracking();

  const { data, loading, error } = useQuery(getAllRoles, {
    variables: { token: user.token, partnerId: selectedCompany },
    fetchPolicy: "network-only"
  });

  useEffect(() => {
    TrackEvent("User editing started", {
      accountId: selectedCompany,
      userId: user.id,
      email: user.email
    });
  }, []);

  const {
    data: editUserQueryData,
    loading: editUserQueryLoading,
    error: editUserQueryError
  } = useQuery(getSingleUser, {
    variables: {
      token: user.token,
      company: selectedCompany,
      id: selectedUser
    },
    fetchPolicy: "network-only"
  });

  const [
    editUser,
    { data: editUserData, loading: editUserLoading, error: editUserError }
  ] = useMutation(EDIT_USER);

  useEffect(() => {
    if (!editUserData) return;

    pushNotification(t("user_edit_correct"), { fixed: true, color: "success" });

    onCancel();
  }, [editUserData]);

  useEffect(() => {
    if (!editUserQueryError) return;
    const errorMessage = editUserQueryError?.message || "Error editing user";
    pushNotification(t(errorMessage), { fixed: true, color: "primary" });
  });

  useEffect(() => {
    if (editUserQueryData) {
      const { getSingleUser: userToEdit } = editUserQueryData;
      setFirstName(userToEdit.firstName);
      setLastName(userToEdit.lastName);
      setUserEmail(userToEdit.email);
      const filteredRoles = userToEdit.roles.filter(
        r => r.id !== METALOOP_ADMIN_ROLE_ID
      );
      setSelectedRole(filteredRoles[0].id);
      setLanguage(userToEdit.language);
    }
  }, [editUserQueryData]);

  useEffect(() => {
    if (data?.getAllRoles && data.getAllRoles.length)
      setArrOfOptions(
        data.getAllRoles.map(d => ({
          label: d.label,
          value: d.name,
          key: d.id
        }))
      );
  }, [data]);

  const handleChange = event => {
    const {
      target: { value }
    } = event;

    setMinimunOneAdminError(false);
    if (value === selectedRole && selectedRole !== "") {
      setSelectedRole("");
      return;
    }
    setSelectedRole(value);
    setErrorMSG("");
  };

  const isEmailValid = function () {
    return validateEmail(userEmail);
  };

  const onEditUser = () => {
    setSubmit(true);
    const userToEdit = editUserQueryData?.getSingleUser;
    const oldUserData = { ...userToEdit };
    if (!firstName || !lastName || !selectedRole) {
      return null;
    }
    editUser({
      variables: {
        input: {
          token: user.token,
          partnerId: selectedCompany,
          firstName,
          lastName,
          //TODO: This is a temp hack to get the admin or the first role
          role: selectedRole,
          userId: selectedUser,
          language
        }
      },
      refetchQueries: [
        {
          query: CompanyUserQuery,
          variables: { token: user.token, company: selectedCompany }
        }
      ]
    })
      .then(() => {
        const hasNewFirstName = firstName !== oldUserData.firstName;
        const hasNewLastName = lastName !== oldUserData.lastName;
        const hasNewEmail = userEmail !== oldUserData.email;
        const hasNewLanguage = language !== oldUserData.language;

        let evToTrack = {
          firstNameChanged: hasNewFirstName,
          lastNameChanged: hasNewLastName,
          emailChanged: hasNewEmail,
          languageChanged: hasNewLanguage
        };

        if (hasNewFirstName) {
          evToTrack.newFirstName = firstName;
        }

        if (hasNewLastName) {
          evToTrack.newLastName = lastName;
        }

        if (hasNewEmail) {
          evToTrack.newEmail = userEmail;
        }

        if (hasNewLanguage) {
          evToTrack.newLanguage = language;
        }

        const roleChanged = selectedRole !== oldUserData.roles[0]?.id;

        if (roleChanged) {
          if (selectedRole) {
            evToTrack.rolesAdded = [selectedRole];
            evToTrack.rolesRemoved = [oldUserData.roles[0].id];
          }
        }

        TrackEvent("User edited", evToTrack);
      })
      .catch(error => {
        const errorMessage = error.graphQLErrors[0].message.replace(
          /[\[\]]/g,
          ""
        );
        if (["main", "accounting", "group_admin"].includes(errorMessage)) {
          setErrorMSG(
            errorMessage === "main"
              ? t("downgrade_permission_message_main")
              : errorMessage === "group_admin"
                ? t("last_group_admin")
                : t("downgrade_permission_message_accounting")
          );
        }
        const errorBecauseAdmin =
          error.graphQLErrors[0].message ===
          "[Can't remove the last Company Admin]";
        if (errorBecauseAdmin) {
          setMinimunOneAdminError(true);
        }
      });
  };

  return (
    <div>
      <div className={classes.headerContainer}>
        <Hidden mdUp>
          <Close onClick={onCancel} size="small" />
        </Hidden>

        <Typography className={classes.title}>
          {t("User Account Options")}
        </Typography>
      </div>

      <Typography variant="h6" gutterBottom className={classes.subtitle}>
        {t("You can reset the password or change the role from here")}
      </Typography>

      {editUserQueryLoading && <Loader />}
      {!editUserQueryLoading && (
        <FormControl fullWidth className={classes.container}>
          <TextField
            id="firstName"
            label={t("First name")}
            variant="outlined"
            value={firstName}
            onChange={event => setFirstName(event.target.value)}
            error={!firstName && submit}
            helperText={!firstName && submit && t("This field is compulsory")}
          />
          <TextField
            id="lastName"
            label="Last name"
            variant="outlined"
            value={lastName}
            onChange={event => setLastName(event.target.value)}
            error={!lastName && submit}
            helperText={!lastName && submit && t("This field is compulsory")}
          />
          <TextField
            id="email"
            disabled={true}
            label="Email"
            variant="outlined"
            value={userEmail}
            onChange={event => setUserEmail(event.target.value)}
            error={(!userEmail || !isEmailValid()) && submit}
            helperText={
              (!userEmail || !isEmailValid()) &&
              submit &&
              t("This field is compulsory")
            }
          />
          <DropdownWithPills
            handleChange={handleChange}
            arrOfOptions={arrOfOptions}
            arrOfSelected={[selectedRole]}
            label={t("Role")}
            error={
              ((!selectedRole || minimunOneAdminError) && submit) || errorMSG
            }
            errorMessage={
              !selectedRole && submit
                ? t("This field is compulsory")
                : minimunOneAdminError && submit
                  ? t("Error_min_one_admin")
                  : null || errorMSG
                    ? errorMSG
                    : null
            }
            multiple={false}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="preferredLanguage">{t("Language")}</InputLabel>
            <Select
              labelId="preferredLanguage"
              id="Language"
              label={t("Language")}
              value={language || ""}
              onChange={event => setLanguage(event.target.value)}
              MenuProps={SelectMenuProps}
            >
              {PROFILE_LANGUAGES.map(item => (
                <MenuItem key={item.value} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <div className={classes.btnContainer}>
            <Mutation
              mutation={RESET_PASSWORD}
              onCompleted={data => {
                pushNotification(
                  t("Password reset link sent to {{email}}", {
                    email: userEmail
                  }),
                  { color: "success" }
                );
                onCancel();
              }}
              onError={error =>
                pushNotification(t(error.message), {
                  fixed: true,
                  color: "primary"
                })
              }
            >
              {(resetPassword, { data }) => (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() =>
                    resetPassword({
                      variables: {
                        userId: selectedUser,
                        token: user.token,
                        companyId: selectedCompany
                      }
                    })
                  }
                >
                  {" "}
                  {t("Send reset password link")}
                </Button>
              )}
            </Mutation>
          </div>

          <div className={classes.btnContainer}>
            <Button
              variant="outlined"
              color="primary"
              onClick={onCancel}
              disabled={editUserLoading}
            >
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onEditUser}
              disabled={editUserLoading}
            >
              {t("Save")}
            </Button>
          </div>
        </FormControl>
      )}
    </div>
  );
};
