import gql from "graphql-tag";

export default gql`
  mutation uploadFileToPartner(
    $file: Upload!
    $input: UploadFileToPartnerInput
  ) {
    uploadFileToPartner(file: $file, input: $input) {
      id
      src
      alt
    }
  }
`;
