import React, { useEffect } from "react";
import { PreferenceSection } from "./PreferenceSection";
import { makeStyles, Typography } from "@material-ui/core";
import { useQuery } from "react-apollo";
import { GET_NOTIFICATIONS_PREFERENCES } from "../../../queries/notificationPreferences";
import {
  Topics,
  TopicsDescription,
  NOTIFICATIONS_SUBTOPICS
} from "../../../components/core/shared/constants";
import { useTranslation } from "react-i18next";
import useEventTracking from "../../../hooks/useEventTracking";
import useQueryParams from "../../../hooks/useQueryParams";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 20
  }
}));

export const PreferenceList = ({ user, companyId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();
  const queryParams = useQueryParams();

  useEffect(() => {
    const paramSource = queryParams.get("source");
    TrackEvent("Open Notification Settings", {
      source: paramSource || "Account tab"
    });
  }, []);

  const { data, loading, error } = useQuery(GET_NOTIFICATIONS_PREFERENCES, {
    variables: { token: user.token, partnerId: companyId },
    fetchPolicy: "cache-and-network"
  });

  if (error) return <div>Error! {error.message}</div>;

  if (loading) return;

  let preferenceData = data?.GetNotificationsPreferencesForUser;

  const {
    subtopics,
    notificationPreferences,
    reasonsOfRequiredAndRestricted,
    requiredAndRestricted
  } = preferenceData;

  const subtopicsObj = {};
  for (let key of Object.keys(subtopics)) {
    subtopicsObj[key] = [];
    subtopics[key].map(subtopic => {
      const title = NOTIFICATIONS_SUBTOPICS[subtopic]?.title;

      subtopicsObj[key].push({
        status: notificationPreferences[subtopic] ? "OPTED_IN" : "OPTED_OUT",
        topic: t(title),
        id: subtopic,
        RAR: (requiredAndRestricted && requiredAndRestricted[subtopic]) || null,
        reason:
          (reasonsOfRequiredAndRestricted &&
            reasonsOfRequiredAndRestricted[subtopic]) ||
          null,
        description: t(TopicsDescription[subtopic])
      });
      delete notificationPreferences[subtopic];
    });
  }

  if (
    !preferenceData ||
    Object.keys(preferenceData?.notificationPreferences || []).length === 0
  )
    return (
      <Typography style={{ margin: "20px" }}>
        {t("notificationSettings_error")}
      </Typography>
    );

  const transformedArray = Object.keys(notificationPreferences).map(type => ({
    status: notificationPreferences[type] ? "OPTED_IN" : "OPTED_OUT",
    topic: t(Topics[type]),
    id: type,
    RAR: (requiredAndRestricted && requiredAndRestricted[type]) || null,
    reason:
      (reasonsOfRequiredAndRestricted &&
        reasonsOfRequiredAndRestricted[type]) ||
      null,
    description: t(TopicsDescription[type])
  }));

  return (
    <div className={classes.container}>
      <PreferenceSection
        NotificationPreference={transformedArray}
        token={user.token}
        partnerId={companyId}
        subtopics={subtopicsObj}
      />
    </div>
  );
};
