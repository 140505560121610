import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  makeStyles,
  TextField
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-apollo";

import AddTaxInformationNumber from "../../../../mutations/addVatNumber";
import verifyVatNumbers from "../../../../mutations/verifyVatNumbers";
import { GET_VAT_DETAILS } from "../../../../queries/AccountVats";
import { showError, showSuccess } from "../../../core/shared/Notify";
import useEventTracking from "../../../../hooks/useEventTracking";

const AddVatForm = ({ token, company, cancel }) => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      maxWidth: 400,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #9E9E9E"
      }
    },
    addVatFlexContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px"
    },
    flexContainer: {
      display: "flex",
      justifyContent: "space-between"
    },
    btnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 23
    }
  }));
  const classes = useStyles();

  const [submit, setSubmit] = useState(false);

  const [form, setForm] = useState({
    country: "",
    type: "VAT",
    value: "",
    default: false,
    validated: false
  });

  const { t } = useTranslation();

  const { TrackEvent } = useEventTracking();

  const [verifyVatNumber] = useMutation(verifyVatNumbers);

  const [addTaxVatInfo] = useMutation(AddTaxInformationNumber, {
    onCompleted: () => {
      TrackEvent("Added VAT Number", {
        accountId: company
      });
    },
    refetchQueries: () => [
      {
        query: GET_VAT_DETAILS,
        variables: {
          token: token,
          company: company
        }
      }
    ]
  });

  const onChange = event => {
    setForm({
      ...form,
      [event.target.name]:
        event.target.name === "default"
          ? event.target.checked
          : event.target.value
    });
  };

  const onSubmit = () => {
    setSubmit(true);

    if (!form.value) return null;

    addTaxVatInfo({
      variables: {
        token,
        company,
        data: {
          ...form
        }
      }
    })
      .then(() => {
        showSuccess(t("Successfully added"));
        cancel(true);
      })
      .catch(err => showError(err.message));
  };

  const onVerifyVatNumber = event => {
    const taxNumber = event.target.value;
    if (!taxNumber) return;
    verifyVatNumber({
      variables: {
        taxNumber: taxNumber.toUpperCase()
      }
    })
      .then(({ data }) => {
        const { country, validated } = data?.verifyVatNumber;
        // set country and validated
        setForm({
          ...form,
          value: taxNumber.toUpperCase(),
          country,
          validated
        });
      })
      .catch(err => showError(err.message));
  };

  return (
    <div className={classes.container}>
      <div className={classes.addVatFlexContainer}>
        <TextField
          id="VATnumber"
          label={t("VAT number")}
          variant="outlined"
          name="value"
          value={form.value}
          onChange={onChange}
          onBlur={onVerifyVatNumber}
          error={!form.value && submit}
          helperText={!form.value && submit && t("This field is compulsory")}
        />
        <div className={classes.flexContainer}>
          <FormControlLabel
            className={classes.keepSignInButton}
            control={
              <Checkbox
                checked={form.default}
                onChange={onChange}
                name="default"
              />
            }
            label={t("Set as default")}
          />
          <div className={classes.btnContainer}>
            <Button variant="outlined" color="primary" onClick={cancel}>
              {t("Cancel")}
            </Button>
            <Button variant="contained" color="primary" onClick={onSubmit}>
              {t("ADD")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddVatForm;
