/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { TextField } from "@material-ui/core";
import PlacesDropdown from "./PlacesDropdown";

import { useTranslation } from "react-i18next";
import { useGoogleApi } from "../../../hooks/GoogleAPI";
import { getCountryLongName } from "../../../utils/collections/countries";

const CityAndCountryInputs = props => {
  const {
    changeForm,
    classes,
    form,
    errors,
    setCity,
    setCountry,
    disabled,
    reset = false,
    onResetComplete = () => {},
    setCityAndCountry
  } = props;

  const { t, i18n } = useTranslation();
  const { placesService, autocompleteService } = useGoogleApi();

  const [predictions, setPredictions] = useState([]);
  const [currentCity, setCurrentCity] = useState(form.city);
  const [currentCountry, setCurrentCountry] = useState(form.country);

  useEffect(() => {
    if (reset) {
      setCurrentCity(form.city);
      setCurrentCountry(form.country);
      onResetComplete();
    }
  }, [reset, onResetComplete]);

  function getPlacePredictions(input) {
    autocompleteService.getPlacePredictions(
      { input, types: ["(cities)"], language: i18n.language },
      predictions => {
        setPredictions(
          predictions.map(prediction => ({
            id: prediction.place_id,
            label: prediction.description
          }))
        );
      }
    );
  }

  function changeCity(city) {
    setCurrentCity(city);
    setCity && setCity(city);
  }

  function changeCountry(country, city) {
    setCurrentCountry(country);

    setCountry && setCountry(country);
    changeForm && changeForm("city", [city, country]);
  }

  const handleChange = ({ id: placeId, label: city } = {}) => {
    if (!placeId) {
      changeCity();
      changeCountry();
      return;
    }

    changeCity(city);
    changeCountry();

    placesService.getDetails(
      { placeId, fields: ["address_components"], language: "en" },
      ({ address_components }, status) => {
        if (status !== "OK") return;

        const country = address_components.find(c =>
          c.types.includes("country")
        );

        changeCountry(country?.short_name, city);
        setCityAndCountry &&
          setCityAndCountry({
            city,
            country: country?.short_name
          });
      }
    );
  };

  const debouncedGetPlacePredictions = useCallback(
    debounce(getPlacePredictions, 500),
    []
  );

  useEffect(() => {
    debouncedGetPlacePredictions(currentCity);
  }, [currentCity]);

  return (
    <div className={classes.googleLocationContainer}>
      <PlacesDropdown
        classes={classes.inputText}
        data={predictions}
        placeholder={t("City")}
        selected={currentCity || ""}
        handleChange={handleChange}
        keyUp={city => {
          setCurrentCity(city);
          setCity && setCity(city);
        }}
        errors={errors?.city}
        helperText={errors?.city && "Required"}
        disabled={disabled}
      />

      <TextField
        id="country"
        label={t("Country")}
        className={classes.inputText}
        variant="outlined"
        disabled
        defaultValue=" "
        value={getCountryLongName(currentCountry, t)}
      />
    </div>
  );
};
export default CityAndCountryInputs;
