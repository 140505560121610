import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  container: {
    marginRight: "32px",
    display: "flex",
    flexDirection: "column",
    fontSize: "1.25rem",
    color: "grey",
    paddingTop: "20px",
    alignItems: "flex-end"
  },
  legend: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.001em",
    color: "#707070"
  },
  wrapper: {
    fontSize: "16px",
    color: "grey",
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "flex-end",
    "& > span": {
      margin: "5px 0px 5px 0px",
      display: "inline-block",
      height: "16px"
    },
    "& > span:first-child": {
      marginLeft: "0px !important"
    },
    "& > span:last-child": {
      marginRight: "0px !important"
    }
  },
  square: {
    width: "16px"
  },
  green: {
    backgroundColor: "#27AE60"
  },
  blue: {
    backgroundColor: "#2F80ED"
  },
  orange: {
    backgroundColor: "#F2994A"
  },
  legende: {
    fontWeight: "bold"
  },
  purple: {
    backgroundColor: "#9B51E0"
  },
  text: {
    marginRight: "16px !important",
    marginLeft: "6px !important",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)"
  }
}));

const Legend = props => {
  const { justPrices } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <span className={classes.legend}>{t("Legend")}</span>
      <div className={classes.wrapper}>
        {justPrices ? null : (
          <>
            <span className={classnames(classes.square, classes.green)} />
            <span className={classes.text}>{t("Price discount")}</span>
          </>
        )}
        <span className={classnames(classes.square, classes.orange)} />
        <span className={classes.text}>{t("My shop price")}</span>
        {justPrices ? null : (
          <>
            <span className={classnames(classes.square, classes.blue)} />
            <span className={classes.text}>{t("S24 Index")}</span>
          </>
        )}
        <span className={classnames(classes.square, classes.purple)} />
        <span className={classes.text}>
          {t("Committed price categories, automatic sales")}
        </span>
      </div>
    </div>
  );
};

export default Legend;
