import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Fab, Hidden, Typography } from "@material-ui/core";
import { MailOutline, Phone } from "@material-ui/icons";

import defaultAvatar from "../../assets/defaultAvatar.svg";
import InformationalComponent from "../general/InformationalComponent";

const useStyles = loading =>
  makeStyles(theme => ({
    "@keyframes skeleton-loading": {
      from: {
        backgroundColor: "hsl(200, 20%, 80%)",
        color: "hsl(200, 20%, 80%)"
      },
      to: { backgroundColor: "hsl(200, 20%, 95%)", color: "hsl(200, 20%, 95%)" }
    },
    contactContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: 480,
      padding: 20,
      background: "#F9F9F9",
      border: "1px solid #E0E0E0",
      borderRadius: 4,
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",
        "& > div": {
          flex: "1 0 50%"
        },
        "& > div:last-child": {
          flex: "0 1 100%"
        }
      }
    },
    avatarContainer: {
      maxWidth: 100,
      maxHeight: 100,
      marginLeft: 16,
      [theme.breakpoints.down("sm")]: {
        maxWidth: 80,
        maxHeight: 80,
        minWidth: 80,
        minHeight: 80
      },
      "& img": {
        position: "relative",
        display: "block",
        width: "100px",
        height: "100px",
        [theme.breakpoints.down("sm")]: {
          maxWidth: 80,
          maxHeight: 80,
          width: "80px",
          height: "80px"
        },
        "&:before": {
          content: "''",
          display: "block",
          position: "absolute",
          height: "100px",
          width: "100px",
          backgroundImage: `url(${defaultAvatar})`,
          backgroundSize: "cover",
          [theme.breakpoints.down("sm")]: {
            width: "80px",
            height: "80px"
          }
        }
      },
      ...getSkeletonLoading(loading, {
        width: "100px",
        "& img": { display: "none" }
      })
    },
    infoContainer: {
      "& > h6": {
        paddingBottom: 4,
        [theme.breakpoints.down("sm")]: {
          fontSize: 16,
          letterSpacing: 0.25
        },
        ...getSkeletonLoading(loading, {
          [theme.breakpoints.down("sm")]: {
            width: "unset"
          }
        })
      },
      "& a": {
        color: "rgba(0, 0, 0, 0.6)",
        ...getSkeletonLoading(loading)
      },
      "& > p": {
        color: "rgba(0, 0, 0, 0.6)",
        marginTop: 4,
        overflowWrap: "anywhere",
        ...getSkeletonLoading(loading, {
          [theme.breakpoints.down("sm")]: {
            width: "unset"
          }
        })
      }
    },
    infoMobileContainer: {
      marginTop: 8,
      display: "flex",
      flexDirection: "column",
      gap: 12,
      color: "rgba(0, 0, 0, 0.6)",
      "& a": {
        color: "rgba(0, 0, 0, 0.6)",
        ...getSkeletonLoading(loading)
      },
      "& .MuiFab-root": {
        boxShadow: "none",
        marginRight: 12
      },
      ...getSkeletonLoading(loading, { "& .MuiFab-root": { display: "none" } })
    },
    [theme.breakpoints.down("sm")]: {
      flex: {
        display: "flex",
        alignItems: "center",
        "& .MuiButtonBase": {
          width: "40px",
          flex: "0 0 40px"
        },
        "& .flexItem": {
          maxWidth: "150px"
        }
      }
    }
  }));

const ContactCard = ({ contact, loading }) => {
  const classes = useStyles(loading)();

  return (
    <div className={classes.contactContainer} key={contact.role}>
      <div className={classes.infoContainer}>
        <Typography variant="h6">{contact.role}</Typography>
        {contact.exists && (
          <Typography variant="body2">{contact.name}</Typography>
        )}
        <Hidden smDown>
          <ContactInformation contact={contact} />
        </Hidden>
      </div>
      <div className={classes.avatarContainer}>
        <img src={contact.image} alt="avatar" />
      </div>
      <Hidden mdUp>
        <ContactInformationMobile contact={contact} classes={classes} />
      </Hidden>
    </div>
  );
};

export default ContactCard;

const ContactInformation = ({ contact }) => {
  if (!contact.exists) return <NoContactInformation />;

  return (
    <>
      <Typography variant="body2">
        <u>
          <a href={`mailto:${contact.email}`}>{contact.email}</a>
        </u>
      </Typography>

      <Typography variant="body2">
        <u>
          <a href={`tel:${clearSpacesInPhoneNumber(contact.phone)}`}>
            {contact.phone}
          </a>
        </u>
      </Typography>
    </>
  );
};

const ContactInformationMobile = ({ contact, classes }) => {
  if (!contact.exists) return <NoContactInformation />;

  return (
    <div className={classes.infoMobileContainer}>
      {contact.email && (
        <div>
          <Fab color="primary" size="small" disableRipple>
            <MailOutline />
          </Fab>
          <a href={`mailto:${contact.email}`}>{contact.email}</a>
        </div>
      )}

      {contact.phone && (
        <div className={classes.flex}>
          <Fab color="primary" size="small" disableRipple>
            <Phone />
          </Fab>
          <u className="flexItem">
            <a href={`tel:${clearSpacesInPhoneNumber(contact.phone)}`}>
              {contact.phone}
            </a>
          </u>
        </div>
      )}
    </div>
  );
};

const noContactInformationStyle = makeStyles(theme => ({
  informationalComponent: {
    marginTop: "8px",
    [theme.breakpoints.down("sm")]: { marginTop: "12px" }
  },
  body2: { margin: 0, color: "rgba(0, 0, 0, 0.87)" }
}));

const NoContactInformation = () => {
  const { t } = useTranslation();
  const classes = noContactInformationStyle();

  return (
    <InformationalComponent
      gap={8}
      noPadding
      className={classes.informationalComponent}
    >
      <Typography variant="body2" className={classes.body2}>
        {t("Empty_AccountManager_Operational")}
      </Typography>
    </InformationalComponent>
  );
};

const getSkeletonLoading = (loading, styles = {}) =>
  loading
    ? {
        animation: "$skeleton-loading 1s linear infinite alternate;",
        width: "max-content",
        pointerEvents: "none",
        ...styles
      }
    : {};

const clearSpacesInPhoneNumber = phoneNumber =>
  (phoneNumber || "").replace(/\s/g, "");
