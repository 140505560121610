import React, { useState } from "react";
import { ReactComponent as BookmarkIcon } from "../../../../../assets/icons/bookmark.svg";
import { ReactComponent as BookmarkFilledIcon } from "../../../../../assets/icons/bookmark-filled.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Fab } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import UploadPricesPopup from "./UploadPricesPopup";
const useStyles = makeStyles(theme => ({
  mobileFooter: {
    height: "56px",
    width: "100%",
    background: theme.palette.primary.main,
    position: "fixed",
    bottom: "0px",
    left: "0px",
    display: "flex"
  },
  mobileFooterItem: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  mobileFooterText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "#FFFFFF",
    marginTop: "5px"
  },
  containerFab: {
    display: "flex",
    justifyContent: "end",
    top: "-73px",
    left: "-16px",
    width: "100%",
    position: "absolute"
  }
}));

const CategoryMobileFooter = props => {
  const { selectedCompany } = props;
  const classes = useStyles();
  const [uploadPopup, setUploadPopup] = useState(false);
  const { showActive, setShowActive, activeText, inactiveText } = props;

  const openUploadPopup = () => {
    setUploadPopup(true);
  };

  const closeUploadPopup = () => {
    setUploadPopup(false);
  };

  return (
    <div className={classes.mobileFooter}>
      {uploadPopup ? (
        <UploadPricesPopup
          buyerId={selectedCompany}
          open={uploadPopup}
          close={closeUploadPopup}
        />
      ) : null}

      <div className={classes.containerFab}>
        <Fab color="secondary" aria-label="add" onClick={openUploadPopup}>
          <AddIcon />
        </Fab>
      </div>

      <div
        className={classes.mobileFooterItem}
        onClick={() => setShowActive(true)}
      >
        {showActive ? <BookmarkFilledIcon /> : <BookmarkIcon />}
        <span className={classes.mobileFooterText}>{activeText}</span>
      </div>
      <div
        className={classes.mobileFooterItem}
        onClick={() => setShowActive(false)}
      >
        {!showActive ? <BookmarkFilledIcon /> : <BookmarkIcon />}
        <span className={classes.mobileFooterText}>{inactiveText}</span>
      </div>
    </div>
  );
};

export default CategoryMobileFooter;
