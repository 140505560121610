import gql from "graphql-tag";

export default gql`
  mutation ImportEnterpriseData(
    $token: String!
    $type: String!
    $companyId: String!
    $data: String!
  ) {
    ImportEnterpriseData(
      token: $token
      type: $type
      companyId: $companyId
      data: $data
    ) {
      message
    }
  }
`;
