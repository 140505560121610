import React, { useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import DashboardGraphContainer from "../../containers/dashboard/DashboardGraphContainer";
import { isAtGroupAdminAccount } from "../../utils/SharedUtils";
import TableauDashboard from "./TableauDashboard";
import { useLocation, useHistory } from "react-router-dom";
import config from "../../config/config";
import { useTranslation } from "react-i18next";

import useEventTracking from "../../hooks/useEventTracking";
import { isDemoEnviroment as _isDemoEnviroment } from "../../config/config";

const styles = () => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    lineHeight: 0
  }
});

const DashboardPage = ({ classes, user, selectedCompany, userData }) => {
  const { TrackEvent } = useEventTracking();
  useEffect(() => {
    TrackEvent("Partner page log in");
  }, []);
  const { RawUserData, UserCompanies } = userData;

  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const isDemoEnviroment = _isDemoEnviroment();

  const VIEWS = {
    map: { title: t("map_title"), dashboardId: 34 },
    stats: {
      title: t("detailed_stats_title"),
      dashboardId: 33
    },
    overview: {
      title: t("overview_title"),
      dashboardId: 32
    }
  };

  const eventNames = {
    map: "Side menu - Maps",
    stats: "Side menu - Detailed Stats",
    overview: "Side menu - Overview Dashboard"
  };

  const showDataDashboardCondition =
    RawUserData.isGroupAdmin &&
    isAtGroupAdminAccount(selectedCompany, UserCompanies);
  let view = null;
  if (showDataDashboardCondition) {
    const searchParams = new URLSearchParams(location.search);
    if (
      !searchParams.get("view") ||
      !Object.keys(VIEWS).includes(searchParams.get("view"))
    ) {
      searchParams.set("view", "overview");
      history.push({
        pathname: location.pathname,
        search: new URLSearchParams(searchParams).toString()
      });
      return null;
    }
    view = searchParams.get("view");
    TrackEvent(eventNames[view]);
  }

  return (
    <div className={classes.container}>
      {!showDataDashboardCondition ? (
        <DashboardGraphContainer
          user={user}
          selectedCompany={selectedCompany}
        />
      ) : (
        <TableauDashboard
          user={user}
          key={view}
          title={VIEWS[view].title}
          dashboardId={VIEWS[view].dashboardId}
          selectedCompany={selectedCompany}
        />
      )}
    </div>
  );
};

export default withStyles(styles)(DashboardPage);
