import { useTranslation } from "react-i18next";
import { getMetaloopUrl } from "../config/config";

const useMetaloopUrl = () => {
  const { i18n } = useTranslation();

  const metaloopBaseURL = getMetaloopUrl(i18n.language);
  return {
    metaloopBaseURL
  };
};

export default useMetaloopUrl;
