import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Url from "url-parse";

const styles = theme => ({
  image: {
    display: "inline-block",
    width: "100%",
    height: "100px",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundColor: "white",
    borderRadius: "0",
    backgroundPosition: "center",
    [theme.breakpoints.down("sm")]: {
      height: "80px"
    }
  },
  div: {
    backgroundColor: "red"
  }
});

const ItemImage = props => {
  const { classes, src, className } = props;

  let url = new Url(src);

  if (url.host !== window.location.host) url.set("host", "app.scrap24.com");
  return (
    <div
      className={classes.image + (className ? " " + className : "")}
      style={{ backgroundImage: `url(${url.href})` }}
    />
  );
};

export default withStyles(styles)(ItemImage);
