import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  makeStyles,
  TextField
} from "@material-ui/core";
import { useRef } from "react";
import { WEIGHTNOTE_UPLOAD_TYPE } from ".";
import CountdownBar from "../../../../general/CountdownBar";
import FileList from "../components/FileList";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  root: {
    [theme.breakpoints.up("md")]: {
      width: "640px"
    },
    "& > .MuiDialogContentText-root": {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "20px"
      },
      paddingBottom: "20px"
    },
    "& > .MuiDialogActions-root": {
      paddingBottom: "20px"
    }
  },
  textfield: {
    marginBottom: "20px"
  },
  fileList: {
    margin: "0 -24px -8px"
  },
  countdownBar: {}
}));

export default ({ orderType, type, files, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const commentRef = useRef();

  const submitWeightNote = () => {
    const comment =
      commentRef.current && commentRef.current.value
        ? commentRef.current.value
        : "";
    onClose(comment);
  };

  const isBuyerWeightnote =
    orderType === "purchase" && type === WEIGHTNOTE_UPLOAD_TYPE;

  return (
    <>
      <DialogContent className={classes.root}>
        <DialogContentText>
          {t("UploadDoneText1")}

          <br />
          {t("UploadDoneText2")}

          <br />
          {t("UploadDoneText3")}
        </DialogContentText>
        {isBuyerWeightnote && (
          <TextField
            inputRef={commentRef}
            className={classes.textfield}
            label={t("Add comment")}
            variant="outlined"
            fullWidth={true}
            multiline={true}
            minRows={4}
          />
        )}
        <FileList files={files} uploading={true} className={classes.fileList} />
      </DialogContent>
      {isBuyerWeightnote ? (
        <DialogActions>
          <Button
            color="secondary"
            variant="contained"
            onClick={submitWeightNote}
            classes={{ root: classes.buttonRoot }}
          >
            {t("Button_Finish")}
          </Button>
        </DialogActions>
      ) : (
        <div className={classes.countdownBar}>
          <CountdownBar seconds={5} onFinish={onClose} />
        </div>
      )}
    </>
  );
};
