import { useContext } from "react";
import DocPreviewProvider from "../providers/DocPreviewProvider";

const useDocPreview = () => {
  const { document, setDocument } = useContext(DocPreviewProvider);

  return {
    document,
    setDocument
  };
};

export default useDocPreview;
