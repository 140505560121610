import { makeStyles } from "@material-ui/core";
import { Select, MenuItem } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { SelectMenuProps } from "../../../../constants";

const useStyles = makeStyles(theme => ({
  inputText: {
    width: "100%"
  },
  row: {
    fontSize: "16px"
  },
  buttonRoot: {
    marginLeft: "10px",
    marginTop: "10px"
  },
  leftColumn: {
    flex: 1
  },
  container: {
    display: "flex",
    "justify-content": "space-between",
    "align-items": "center"
  },
  rightColumn: {
    flex: 1,
    display: "flex",
    "flex-direction": "column",
    "align-items": "center",
    marginBottom: "10px"
  }
}));

const LocationsSelect = ({ options, objectPaired, setObjectPaired, row }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {objectPaired[row]?.suggestion ? (
        <div className={classes.row}>
          <div className={classes.container}>
            <div className={classes.leftColumn}>
              {t("pairing_locations_suggestion")}
              <b>{objectPaired[row]?.suggestion}</b>
              {"?"}
            </div>
            <div className={classes.rightColumn}>
              <Button
                color="primary"
                classes={{ root: classes.buttonRoot }}
                variant="contained"
                onClick={() => {
                  const newObjectPaired = JSON.parse(
                    JSON.stringify(objectPaired)
                  );
                  newObjectPaired[row]["suggestionAccepted"] = true;
                  setObjectPaired(newObjectPaired);
                }}
              >
                {t("pairing_locations_yes")}
              </Button>
              <Button
                variant="outlined"
                color="primary"
                className={classes.buttonRoot}
                onClick={() => {
                  const newObjectPaired = JSON.parse(
                    JSON.stringify(objectPaired)
                  );
                  newObjectPaired[row] = null;
                  setObjectPaired(newObjectPaired);
                }}
              >
                {t("pairing_locations_no")}
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Select
          className={classes.inputText}
          labelId="locations"
          id="locations"
          label={t("col_head_metaloop_account")}
          defaultValue={"null"}
          onChange={event => {
            setObjectPaired({
              ...objectPaired,
              [row]: event.target.value
            });
          }}
          MenuProps={SelectMenuProps}
        >
          <MenuItem key={"null"} value={"null"}>
            {t("pairing_select_default")}
          </MenuItem>
          {options.map(item => (
            <MenuItem
              key={item.value}
              value={item.value}
              disabled={Object.values(objectPaired).some(
                value => value === item.value
              )}
            >
              {item.name}
            </MenuItem>
          ))}
        </Select>
      )}
    </>
  );
};

export default LocationsSelect;
