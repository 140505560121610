import React from "react";
import { makeStyles, Typography, Button, Hidden } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-apollo";
import { useHistory } from "react-router-dom";

import Loader from "../../components/core/shared/Loader";
import SmallHorizontalSlider from "../onboarding/SmallHorizontalSlider";
import InformationalComponent from "../general/InformationalComponent";

import OnboardingComponents from "../../queries/OnboardingComponents";
import useUserData from "../../hooks/useUserData";
import useCompany from "../../hooks/useCompany";

const useStyles = makeStyles(theme => ({
  header: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "20px",
    fontWeight: "500",
    lineHeight: "24px",
    fontStyle: "normal",
    marginBottom: "12px",
    letterSpacing: "0.25px",
    textTransform: "capitalize",
    marginBottom: "8px",
    marginRight: "12px"
  },
  subHeader: {
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    fontStyle: "normal",
    letterSpacing: "0.25px",
    maxWidth: "980px"
  },
  topDiv: {
    marginTop: "32px",
    marginBottom: "48px",
    width: "100%",
    maxWidth: "980px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginTop: "28px"
    }
  },
  insideDiv: {
    display: "flex",
    width: "100%"
  },
  textDiv: {
    maxWidth: "716px"
  },
  headerContainer: {
    display: "flex",
    alignItems: "center"
  },
  buttonContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: "auto",
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
      marginLeft: "-34px"
    }
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  infoClass: {
    width: "100%",
    maxWidth: "980px"
  }
}));

const OnboardingHeader = ({ onboardingToken }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const userData = useUserData();
  const companyData = useCompany();

  const { loading, data } = useQuery(OnboardingComponents, {
    variables: { token: onboardingToken },
    fetchPolicy: "network-only",
    errorPolicy: "ignore"
  });
  if (loading) return <Loader />;
  if (!data) return null;
  const onboardingComponents =
    data?.OnboardingInformationQuery?.onboardingComponents;

  const actualStep = data.OnboardingInformationQuery.onboardingStep + 1;

  const onSubmit = () => {
    history.push(`/onboarding/welcome?method=manual`, {
      token: onboardingToken,
      identifyData: { RawUserData: userData, selectedCompany: companyData }
    });
  };

  return (
    <div className={classes.topDiv}>
      <InformationalComponent type="info" className={classes.infoClass}>
        <div className={classes.insideDiv}>
          <div className={classes.textDiv}>
            <div className={classes.headerContainer}>
              <Typography className={classes.header}>
                {t("dashboard_onboarding_header")}{" "}
              </Typography>
              <div style={{ marginBottom: "8px" }}>
                <SmallHorizontalSlider
                  steps={onboardingComponents}
                  actualStep={actualStep}
                />
              </div>
            </div>

            <Typography className={classes.subHeader}>
              {t("dashboard_onboarding_subheader")}
            </Typography>
            <Hidden mdUp>
              <div className={classes.buttonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.button}
                  onClick={() => {
                    onSubmit();
                  }}
                >
                  {t("dashboard_onboarding_button")}
                </Button>
              </div>
            </Hidden>
          </div>
          <Hidden smDown>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  onSubmit();
                }}
              >
                {t("dashboard_onboarding_button")}
              </Button>
            </div>
          </Hidden>
        </div>
      </InformationalComponent>
    </div>
  );
};

export default OnboardingHeader;
