import { makeStyles } from "@material-ui/core/styles";

import { Grid, Typography } from "@material-ui/core";
import LoginLogo from "../../components/login/LoginLogo";
import Footer from "./Footer";
import onboarding_background from "../../assets/images/onboarding_background.png";

const useStyles = makeStyles(theme => ({
  title: {
    margin: "44px 0 8px",
    fontSize: "32px",
    fontWeight: "500",
    lineHeight: "40px",
    fontStyle: "normal"
  },
  subtitle: {
    fontSize: "16px",
    fontWeight: "400",
    lineHeight: "24px",
    fontStyle: "normal",
    letterSpacing: "0.25px",
    color: "black",
    opacity: 0.6,
    marginBottom: "20px"
  },
  container: {
    display: "flex",
    height: "100vh"
  },
  leftSide: {
    flex: 1,
    backgroundColor: "white"
  },
  rightSide: {
    flex: 1,
    background: `linear-gradient(141deg, rgba(21, 17, 36, 0.90) 38.95%, rgba(21, 17, 36, 0.14) 59%, rgba(21, 17, 36, 0.70) 83.93%), url(${onboarding_background})`,
    backgroundSize: "cover",
    backgroundPosition: "right center"
  },
  fullHeight: {
    margin: 0,
    height: "100vh",
    overflow: "hidden"
  },
  sectionWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: "200px 0 50px",
    height: "100vh",

    "@media (max-width: 400px)": {
      padding: "48px 0 24px"
    }
  },
  rightColumnDiv: { margin: "90px" },
  rightColumnHeader: {
    fontSize: "64px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "-0.5px",
    color: "white",
    marginBottom: "12px"
  },
  rightColumnSubHeader: {
    width: "420px",
    fontSize: "20px",
    color: "white",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "28px"
  }
}));

const TwoColumnsModule = props => {
  const classes = useStyles();
  const headers = props.headers;
  return (
    <div className={classes.container}>
      <div className={classes.leftSide}>
        <LeftColumn {...props} />
      </div>
      <div className={classes.rightSide}>
        <RightColumn headers={headers} />
      </div>
    </div>
  );
};

const LeftColumn = props => {
  const classes = useStyles();
  const {
    children,
    headers,
    hideFooter,
    logoOptions = null,
    overrideClasses = {}
  } = props;

  return (
    <Grid container className={classes.fullHeight}>
      <Grid item xs={12} md={12} lg={12}>
        <Grid
          item
          className={overrideClasses.sectionWrapper || classes.sectionWrapper}
        >
          <LoginLogo
            options={logoOptions || { height: "16px", width: "200px" }}
          />
          <Typography className={overrideClasses.title || classes.title}>
            {headers.leftColumnHeaderText}
          </Typography>
          <Typography className={classes.subtitle}>
            {headers.leftColumnSubHeaderText}
          </Typography>
          {children}
          {!hideFooter && <Footer />}
        </Grid>
      </Grid>
    </Grid>
  );
};

const RightColumn = ({ headers }) => {
  const classes = useStyles();

  return (
    <div className={classes.rightColumnDiv}>
      <Typography className={classes.rightColumnHeader}>
        {headers.rightColumnHeaderText}
      </Typography>
      <Typography className={classes.rightColumnSubHeader}>
        {headers.rightColumnSubHeaderText}
      </Typography>
    </div>
  );
};

export default TwoColumnsModule;
