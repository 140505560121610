import React from "react";
import Modal from "@material-ui/core/Modal";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  modal: {
    width: "640px",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    maxHeight: "calc(100vh - 40px)",
    overflowY: "auto",
    boxShadow: theme.shadows[5],
    border: "1px solid #707070",
    padding: "0",
    outline: 0,
    [theme.breakpoints.down("xs")]: {
      maxHeight: "100vh",
      width: "100%",
      height: "100%",
      border: "none",
      borderRadius: "0"
    }
  }
}));

const ModalClass = props => {
  const { isOpen, onCloseModal, children, className, disableBackdropClick } =
    props;

  const classes = useStyles();

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      disableBackdropClick={disableBackdropClick}
    >
      <Paper className={classes.modal + (className ? " " + className : "")}>
        {children}
      </Paper>
    </Modal>
  );
};

export default ModalClass;
