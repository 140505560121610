import React, { useContext, useCallback, useEffect } from "react";
import { useRouteMatch, useHistory } from "react-router";
import { Grid, Typography, Divider, Hidden, Button } from "@material-ui/core";
import _ from "lodash";

import NoOpenOffers from "../../components/offers/NoOpenOffers";
import OfferList, {
  OfferListMobile
} from "../../components/offers/offerList/OfferList";
import OfferElement, {
  OfferElementMobile
} from "../../components/offers/offerElement/OfferElement";
import OfferSearchBar from "../../components/offers/searchBar/OfferSearchBar";

import OffersContext from "../../providers/OffersProvider";

import containerStyles from "./containerStyles";
import ThankYouPopup from "../../components/offers/ThankYouPopup";
import { useTranslation } from "react-i18next";
import useEventTracking from "../../hooks/useEventTracking";

const OpenOffers = props => {
  const {
    openOffers,
    selectedOffer,
    setSelectedOffer,
    biddingInfo,
    setBiddingInfo
  } = useContext(OffersContext);
  const classes = containerStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const { tenderId } = match.params;

  useEffect(() => {
    TrackEvent("Open offers viewed");
  }, []);

  const goBack = useCallback(() => {
    setSelectedOffer(null);
    history.push("/offers/open/");
  }, []);

  const removeBiddingStatus = useCallback(() => {
    setBiddingInfo(prevBiddingInfo => {
      delete prevBiddingInfo.status;
      delete prevBiddingInfo.completedOffer;
      return { ...prevBiddingInfo };
    });
  }, []);

  const goToOffer = useCallback((offer = {}) => {
    history.push("/offers/open/" + offer._id);
  }, []);

  const getIndexOffer = useCallback((i = 0) => openOffers[i], [openOffers]);

  useEffect(() => {
    if (tenderId && selectedOffer !== tenderId) {
      setSelectedOffer(tenderId);
    }

    if (tenderId && selectedOffer) {
      const openOffer = openOffers.find(o => o._id === selectedOffer);
      if (!openOffer) {
        history.push("/offers/open/");
        setSelectedOffer(null);
      }
    }

    if (!tenderId && selectedOffer) {
      setSelectedOffer(null);
    }
  }, [tenderId, selectedOffer]);

  const categories = getProductsCategoriesForOffers(openOffers);

  return (
    <>
      <Hidden smDown>
        {biddingInfo && biddingInfo.status === "completed" && (
          <ThankYouPopup
            completedOffer={biddingInfo.completedOffer || ""}
            cancelAction={() => {
              removeBiddingStatus();
            }}
            doneAction={() => {
              removeBiddingStatus();
              goToOffer(getIndexOffer(0));
            }}
          />
        )}
      </Hidden>
      <Hidden mdUp>
        <MobileOpenOffers
          categories={categories}
          offers={openOffers}
          selectedOffer={selectedOffer}
          goBack={goBack}
        />
      </Hidden>
      <Hidden smDown>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.mainContainer}
        >
          <Grid item className={classes.container}>
            <Typography variant="h5" className={classes.header}>
              {t("Open offers")}
            </Typography>
            {openOffers.length === 0 && <NoOpenOffers />}
          </Grid>
          <Grid item>
            <Divider className={classes.divider} />
          </Grid>

          {openOffers.length > 0 && (
            <>
              <OfferSearchBar categories={categories} />
              <Grid item>
                <Divider className={classes.divider} />
              </Grid>
              <Grid className={classes.mainContentContainer}>
                <OfferList offers={openOffers} offerType="open" canBid={true} />
                <OfferElement
                  offers={openOffers}
                  offerType="open"
                  canBid={true}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Hidden>
    </>
  );
};

const MobileOpenOffers = ({ offers, selectedOffer, categories, goBack }) => {
  const classes = containerStyles();

  const { t } = useTranslation();
  const history = useHistory();

  const goToPricing = () => {
    history.push("/pricing/");
  };

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.mainContainerMobile}
    >
      {offers.length === 0 ? (
        <Grid item className={classes.containerMobile}>
          <NoOpenOffers />
          <Button
            variant="contained"
            color="primary"
            className={classes.pricingButton}
            onClick={goToPricing}
          >
            {t("Go to pricing")}
          </Button>
        </Grid>
      ) : (
        <Grid item className={classes.containerMobile}>
          <OfferSearchBar categories={categories} offers={offers} />
          <OfferListMobile offers={offers} offerType="open" canBid={true} />
          <OfferElementMobile
            offers={offers}
            offerType="open"
            selectedOffer={selectedOffer}
            goBackAction={goBack}
            canBid={true}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default OpenOffers;

export const getProductsCategoriesForOffers = (offers = []) => {
  let categories = [];
  offers.forEach((o = {}) => {
    (o.products || []).forEach(p => {
      if (p === null) {
        categories.push(...[]);
      } else {
        categories.push(...(p.categoryId || []));
      }
    });
  });
  return _.uniq(categories);
};
