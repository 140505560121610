import gql from "graphql-tag";

export default gql`
  mutation impersonateUser(
    $token: String!
    $objetiveId: String!
    $company: String!
  ) {
    impersonateUser(token: $token, objetiveId: $objetiveId, company: $company) {
      id
      token
      tokenExpires
    }
  }
`;
