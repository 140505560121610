import * as React from "react";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Collapse from "@material-ui/core/Collapse";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import moment from "moment";
import SortingIcon from "./SortingIcon";
import { DATE_FORMAT_STRING } from "../../../utils/Format";

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative"
  },
  MuiTableRoot: {
    width: "auto",
    minWidth: "100%"
  },
  MuiTableHead: {
    "& .MuiTableCell-head": {
      [theme.breakpoints.down("xs")]: {
        display: "none !important"
      }
    }
  },
  MuiTableBody: {
    "& .MuiTableCell-body": {
      [theme.breakpoints.down("xs")]: {
        display: "flex !important",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "row !important",
        textAlign: "left !important",
        "&:not(:last-child)": {
          borderWidth: 0
        }
      }
    },
    "& .MuiTableCell-title-span": {
      fontSize: "16px",
      fontWeight: "500",
      userSelect: "none",
      letterSpacing: "0.25px",
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.87)",
      display: "none",
      [theme.breakpoints.down("xs")]: {
        display: "block",
        fontSize: "14px"
      }
    }
  },
  progressContainer: {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    width: "100%",
    height: "100%",
    zIndex: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(255 , 255, 255,0.47)"
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1
  },
  tableCell: {
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  spacer: {
    padding: "0 !important",
    [theme.breakpoints.down("xs")]: {
      display: "none !important"
    }
  }
}));

function descendingComparator(a, b, orderBy) {
  if (orderBy === "pickupDate") {
    const dateA = moment(a[orderBy], DATE_FORMAT_STRING);
    const dateB = moment(b[orderBy], DATE_FORMAT_STRING);

    if (dateB.isBefore(dateA)) {
      return -1;
    }
    if (dateB.isAfter(dateA)) {
      return 1;
    }
    return 0;
  }
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function CustomTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
    size,
    checkboxSelection,
    columns,
    disabledSorting,
    responsive
  } = props;
  const classes = useStyles();
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead className={responsive ? classes.MuiTableHead : ""}>
      <TableRow>
        {checkboxSelection && (
          <TableCell padding="checkbox" size={size} role="columnheader">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                "aria-label": "select all desserts"
              }}
            />
          </TableCell>
        )}
        {columns.map((column, index) => {
          if (!column) return null;
          if (column.spacer) {
            return (
              <TableCell
                className={classes.spacer}
                key={column.field || `field_not_available_${index}`}
                align={
                  column.align
                    ? column.align
                    : column.type === "number"
                      ? "right"
                      : "left"
                }
                padding={column.padding ? column.padding : "normal"}
                sortDirection={orderBy === column.field ? order : false}
                size={size}
                style={{
                  display: column.hide ? "none" : "table-cell",
                  minWidth: column.width ? column.width : "auto"
                }}
                role="columnheader"
              />
            );
          }
          const active = orderBy === column.field;
          const disableSort =
            column.sortable === undefined ? true : column.sortable;

          return (
            <TableCell
              key={column.field || `field_not_available_${index}`}
              align={
                column.align
                  ? column.align
                  : column.type === "number"
                    ? "right"
                    : "left"
              }
              padding={column.padding ? column.padding : "normal"}
              sortDirection={orderBy === column.field ? order : false}
              size={size}
              style={{
                display: column.hide ? "none" : "table-cell",
                minWidth: column.width ? column.width : "auto"
              }}
              role="columnheader"
            >
              <TableSortLabel
                active={active}
                direction={active ? order : "asc"}
                onClick={createSortHandler(column.field)}
                disabled={disableSort}
                {...(!disableSort
                  ? {
                      IconComponent: () => (
                        <SortingIcon active={active} order={order} />
                      )
                    }
                  : {})}
              >
                {column.headerName}
                {orderBy === column.field ? (
                  <Box component="span" className={classes.visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}

CustomTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  size: PropTypes.oneOf(["medium", "small"]),
  checkboxSelection: PropTypes.bool,
  columns: PropTypes.array
};

const CustomTableContent = ({
  collapseId,
  setCollapseId,
  rowId,
  row,
  hover,
  handleClickOnRow,
  isItemSelected,
  labelId,
  checkboxSelection,
  columns,
  expandedRowRender,
  rowExpandable,
  role,
  disableSelectionOnClick,
  responsive,
  onRowClick
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const open = collapseId === rowId;
  const onClickOnRow = _event => {
    if (!checkboxSelection || disableSelectionOnClick) {
      return;
    }
    handleClickOnRow(rowId);
  };

  const onChangeOfCheckbox = _event => {
    if (!checkboxSelection) {
      return;
    }

    if (disableSelectionOnClick) {
      handleClickOnRow(rowId);
    }
  };

  const onSetCollapseId = () => {
    if (collapseId === rowId) {
      setCollapseId(null);
      return;
    }
    setCollapseId(rowId);
  };

  const handleClick = event => {
    onClickOnRow(event);
    if (onRowClick) {
      onRowClick(
        {
          record: row,
          onOpenCollapse: onSetCollapseId,
          collapseIsOpen: open,
          expandedRowRender,
          rowExpandable
        },
        event
      );
    }
  };

  return (
    <React.Fragment>
      <TableRow
        hover={hover}
        onClick={handleClick}
        role={role}
        aria-checked={isItemSelected}
        tabIndex={-1}
        selected={isItemSelected || open}
      >
        {checkboxSelection && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              checked={isItemSelected}
              inputProps={{
                "aria-labelledby": labelId
              }}
              onChange={onChangeOfCheckbox}
            />
          </TableCell>
        )}
        {columns.map((column, index) => {
          if (!column) return null;
          const isTransportOption = column.field === "transportOption";
          const trasnslateField = isTransportOption;

          const componentType = index === 0 ? "th" : "td";
          const alignment = column.align
            ? column.align
            : column.type === "number"
              ? "right"
              : "left";
          if (column.spacer) {
            return (
              <TableCell
                className={classes.spacer}
                component={componentType}
                align={alignment}
                colSpan={column.colSpan}
                key={column.field || `field_not_available_${index}`}
                width={column.width ? column.width : "auto"}
                style={{
                  display: column.hide ? "none" : "table-cell"
                }}
              />
            );
          }
          return (
            <TableCell
              component={componentType}
              align={alignment}
              colSpan={column.colSpan}
              key={column.field || `field_not_available_${index}`}
              width={column.width ? column.width : "auto"}
              style={{
                display: column.hide ? "none" : "table-cell"
              }}
            >
              {responsive && (
                <span className="MuiTableCell-title-span">
                  {column.headerName}
                </span>
              )}
              {column.renderCell
                ? column.renderCell({
                    record: row,
                    column,
                    onOpenCollapse: onSetCollapseId,
                    collapseIsOpen: open
                  })
                : column.valueFormatter
                  ? column.valueFormatter({
                      value: row[column.field],
                      record: row
                    })
                  : trasnslateField
                    ? t(row[column.field])
                    : row[column.field]}
            </TableCell>
          );
        })}
      </TableRow>
      {expandedRowRender && rowExpandable(row) && (
        <TableRow>
          <TableCell
            style={{
              paddingBottom: 0,
              paddingTop: 0,
              borderWidth: open ? 1 : 0
            }}
            colSpan={checkboxSelection ? columns.length + 1 : columns.length}
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              {expandedRowRender(row)}
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
};

const CustomTable = ({
  hover,
  columns,
  rows,
  checkboxSelection,
  size,
  expandedRowRender,
  rowExpandable,
  getRowId,
  disableSelectionOnClick,
  loading,
  disabledSorting,
  responsive,
  rowsPerPageOptions,
  rowCount,
  pageSize,
  onPageSizeChange,
  page,
  onPageChange,
  className,
  labelRowsPerPage,
  pagination,
  onRowClick,
  defaultCollapseId
}) => {
  // if page is defined, it controlled component
  const { current: isControlled } = React.useRef(page !== undefined);
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("pickupDate");
  const [selected, setSelected] = React.useState([]);
  const [innerPage, setPage] = React.useState(0);
  const [innerPageSize, setRowsPerPage] = React.useState(5);
  const [collapseId, setCollapseId] = React.useState(null);
  const { t } = useTranslation();

  // component work whether it's controlled or uncontrolled component
  const actualPage = isControlled ? page : innerPage;
  const rowsPerPage = isControlled ? pageSize : innerPageSize;

  // control mobile settings
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  React.useEffect(() => {
    setCollapseId(defaultCollapseId);
  }, [defaultCollapseId]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      // get row ids
      const newSelected = rows.map(row => getRowId(row));
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClickOnRow = rowId => {
    const selectedIndex = selected.indexOf(rowId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, rowId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    if (onPageChange) {
      onPageChange(newPage);
      return;
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    if (onPageSizeChange) {
      onPageSizeChange(parseInt(event.target.value, 10));
      if (onPageChange) {
        onPageChange(0);
      }
      return;
    }
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const classes = useStyles();

  const sortData = stableSort(rows, getComparator(order, orderBy));

  const computedDataSource = isControlled
    ? sortData
    : sortData.slice(
        actualPage * rowsPerPage,
        actualPage * rowsPerPage + rowsPerPage
      );

  return (
    <div className={`${classes.root} ${className || ""}`}>
      <TableContainer>
        <Table
          aria-labelledby="tableTitle"
          size={size}
          className={classes.MuiTableRoot}
        >
          <CustomTableHead
            numSelected={selected.length}
            order={order}
            checkboxSelection={checkboxSelection}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            columns={columns}
            disabledSorting={disabledSorting}
            responsive={responsive}
          />
          <TableBody className={responsive ? classes.MuiTableBody : ""}>
            {computedDataSource.map((row, index) => {
              const rowId = getRowId(row);
              const isItemSelected = isSelected(rowId);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <CustomTableContent
                  row={row}
                  hover={hover}
                  labelId={labelId}
                  checkboxSelection={checkboxSelection}
                  disableSelectionOnClick={disableSelectionOnClick}
                  columns={columns}
                  expandedRowRender={expandedRowRender}
                  rowExpandable={rowExpandable}
                  handleClickOnRow={handleClickOnRow}
                  role="checkbox"
                  key={rowId}
                  rowId={rowId}
                  isItemSelected={isItemSelected}
                  collapseId={collapseId}
                  setCollapseId={setCollapseId}
                  responsive={responsive}
                  onRowClick={onRowClick}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination && !isMobile && (
        <TablePagination
          rowsPerPageOptions={rowsPerPageOptions}
          component="div"
          count={rowCount || rows.length}
          rowsPerPage={rowsPerPage}
          page={actualPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage={t(labelRowsPerPage)}
        />
      )}
      {loading && (
        <div className={classes.progressContainer}>
          <CircularProgress color="secondary" />
        </div>
      )}
    </div>
  );
};

CustomTable.defaultProps = {
  columns: [],
  rows: [],
  rowExpandable: row => true,
  getRowId: row => row.id,
  disabledSorting: true,
  rowsPerPageOptions: [5, 10, 25],
  labelRowsPerPage: "Sales_TransactionspPage",
  pagination: true,
  defaultCollapseId: null
};

export default CustomTable;
