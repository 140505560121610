import React, { useState } from "react";
import {
  Button,
  FormControl,
  Hidden,
  makeStyles,
  Typography,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Close } from "@material-ui/icons";
import changeUserPreferredLanguage from "../../../../mutations/changeUserPreferredLanguage";
import { useMutation } from "@apollo/react-hooks";
import { showError, showSuccess } from "../../../core/shared/Notify";
import { PROFILE_LANGUAGES } from "../../../../utils/constants";
import UserNavBar from "../../../../queries/UserNavBar";
import { SelectMenuProps } from "../../../core/shared/constants";

const Form = ({ token, company, cancel, userData }) => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      maxWidth: 400,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #9E9E9E"
      }
    },
    headerContainer: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        padding: "16px 0",
        gap: 16
      }
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: 0.25
    },
    adornment: {
      cursor: "pointer"
    },
    btnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 16
    }
  }));

  const classes = useStyles();

  const { t, i18n } = useTranslation();

  const [language, setLanguage] = useState(() => {
    let preferredLanguage = userData.RawUserData.preferredLanguage;
    preferredLanguage = preferredLanguage === "DE" ? "en" : preferredLanguage;
    return preferredLanguage;
  });

  const [changeUserPreferredLanguageMutation] = useMutation(
    changeUserPreferredLanguage,
    {
      refetchQueries: () => [
        {
          query: UserNavBar,
          variables: {
            token,
            company
          }
        }
      ]
    }
  );

  const onChange = event => {
    setLanguage(event.target.value);
  };

  const onSubmit = () => {
    changeUserPreferredLanguageMutation({
      variables: {
        input: {
          id: userData.RawUserData._id,
          preferredLanguage: language
        }
      }
    })
      .then(() => {
        // change language...
        i18n.changeLanguage(language);
        showSuccess(t("Successfully changed"));
        cancel(true);
      })
      .catch(err => showError(err.message));
  };

  return (
    <FormControl fullWidth className={classes.container}>
      <div className={classes.headerContainer}>
        <Hidden mdUp>
          <Close onClick={() => cancel()} size="small" />
        </Hidden>
        <Typography className={classes.title}>{t("Language")}</Typography>
      </div>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="preferredLanguage">{t("Language")}</InputLabel>
        <Select
          labelId="preferredLanguage"
          id="Language"
          label={t("Language")}
          defaultValue={language}
          onChange={onChange}
          MenuProps={SelectMenuProps}
        >
          {PROFILE_LANGUAGES.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div className={classes.btnContainer}>
        <Button variant="outlined" color="primary" onClick={() => cancel()}>
          {t("Cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={onSubmit}>
          {t("Save")}
        </Button>
      </div>
    </FormControl>
  );
};

const EditLanguageForm = ({ cancel, userData, token }) => {
  const useStyles = makeStyles(theme => ({
    mobileContainer: {
      minWidth: "100%",
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
      position: "absolute",
      maxHeight: "100%",
      overflowY: "auto",
      zIndex: 100,
      background: "#FFFFFF",
      padding: "0 16px 30px"
    }
  }));

  const classes = useStyles();

  return (
    <>
      <Hidden smDown>
        <Form cancel={cancel} userData={userData} token={token} />
      </Hidden>
      <Hidden mdUp>
        <div className={classes.mobileContainer}>
          <Form cancel={cancel} userData={userData} token={token} />
        </div>
      </Hidden>
    </>
  );
};

export default EditLanguageForm;
