import React from "react";
import { withStyles } from "@material-ui/core";
import { Query } from "react-apollo";
import CompanyInformationQuery from "../../queries/CompanyInformation";
import Loader from "../../components/core/shared/Loader";
import { CheckErrors } from "../../ErrorTracking";
import { withTranslation } from "react-i18next";
import CompanyInformationContent from "../../components/account/companyInformation/CompanyInformationContent";

const styles = theme => ({
  wrapper: {
    padding: 32,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 16
    }
  }
});

class CompanyInformation extends React.Component {
  render() {
    const { classes, user, t } = this.props;
    return (
      <Query
        query={CompanyInformationQuery}
        variables={{
          token: user.token,
          company: this.props.selectedCompany
        }}
        fetchPolicy="cache-and-network"
      >
        {({ data, loading, error }) => {
          if (error) {
            return t("Error");
          }

          if (loading) return <Loader />;
          const { CompanyInformation: companyData } = data;

          return (
            <div className={classes.wrapper}>
              <CheckErrors>
                <CompanyInformationContent
                  data={companyData}
                  token={user.token}
                  company={this.props.selectedCompany}
                />
              </CheckErrors>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(withTranslation()(CompanyInformation));
