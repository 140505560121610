/* eslint-disable prettier/prettier */
import { createTheme } from "@material-ui/core/styles";

let theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#151124"
    },
    secondary: {
      main: "#52F597"
    },
    error: {
      main: "#E11900"
    },
    warning: {
      main: "#FFC043"
    },
    info: {
      main: "#276EF1"
    },
    background: {
      default: "#FFFFFF"
    }
  },
  typography: {
    fontFamily: ["Roboto", "Helvetica Neue", "Arial"],
    fontStyle: "normal",
    useNextVariants: true,

    body1: {
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "28px",
      letterSpacing: "0.44px"
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.1px"
    },
    body2: {
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.25px"
    },
    subtitle1: {
      fontWeight: "normal",
      fontSize: "16px",
      lineHeight: "20px",
      letterSpacing: "0.15px"
    },
    h1: {
      fontWeight: 300,
      fontSize: "96px",
      lineHeight: "112px",
      letterSpacing: "-1.5px"
    },
    h2: {
      fontWeight: 300,
      fontSize: "60px",
      lineHeight: "71px",
      letterSpacing: "-0.5px"
    },
    h3: {
      fontWeight: "normal",
      fontSize: "48px",
      lineHeight: "57px"
    },
    h4: {
      fontWeight: "normal",
      fontSize: "34px",
      lineHeight: "40px",
      letterSpacing: "0.25px"
    },
    h5: {
      fontWeight: "normal",
      fontSize: "24px",
      lineHeight: "32px"
    },
    h6: {
      fontWeight: 500,
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.25px"
    },
    button: {
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "16px"
    },
    caption: {
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px"
    }
  },
  overrides: {
    MuiListItemIcon: {
      root: {
        minWidth: "30px"
      }
    },
    MuiChip: {
      root: {
        "& $avatar": {
          color: "white"
        }
      },

      outlinedPrimary: {
        "&:focus": {
          color: "#151124",
          background: "rgba(0, 0, 0, 0.12)!important"
        },
        "&:hover": {
          background: "rgba(0, 0, 0, 0.08)!important"
        }
      },

      colorPrimary: {
        "&:hover": {
          // Styles for primary color
          color: "#151124",
          backgroundColor: "rgba(21, 17, 36, 0.04)!important"
        },
        "&:focus": {
          // Styles for primary color
          color: "white",
          backgroundColor: "#8A8892!important"
        },
        "&$disabled": {
          // Styles for primary color
          color: "#9e9e9e",
          backgroundColor: "rgba(0, 0, 0, 0.04)"
        }
      }
    },

    MuiSwitch: {
      root: {
        height: 40,
        width: 60
      },
      switchBase: {
        top: "1px",
        // Controls default (unchecked) color for the thumb
        color: "white"
      },
      colorSecondary: {
        "&$checked": {
          color: "#52F597"
        },
        "&$disabled$checked": {
          color: "#BDFBD7"
        }
      },
      colorPrimary: {
        // checked
        "&$checked": {
          // Styles for primary color
          color: "#151124"
        }
      },
      track: {
        // Controls default (unchecked) color for the track
        opacity: 0.08,
        backgroundColor: "#212121",

        "$checked$colorPrimary + &": {
          opacity: 1.0,
          background: "#B9B8BD"
        },

        "$checked$colorSecondary + &": {
          opacity: 1.0,
          backgroundColor: "#CBFCE0"
        },

        "$checked$disabled + &": {
          opacity: 0.38
        }
      }
    },

    MuiStepIcon: {
      text: {
        fill: "#FFF"
      },
      root: {}
    },
    MuiStepConnector: {
      lineHorizontal: {}
    },
    MuiTooltip: {
      popper: {
        zIndex: "1100"
      }
    },
    MuiTypography: {},
    MuiSnackbarContent: {
      root: {
        "&.MuiSnackbarContent-fixed": {
          "@media (min-width: 420px)": {
            width: "350px"
          }
        },
        "&.MuiSnackbarContent-primary": {
          backgroundColor: "#151124",
          "& .MuiButtonBase-root": {
            color: "#FFF"
          }
        },
        "&.MuiSnackbarContent-secondary": {
          backgroundColor: "#fff",
          color: "rgba(0, 0, 0, 0.87)",
          "& .MuiButtonBase-root": {
            color: "rgba(0, 0, 0, 0.87)"
          }
        },
        "&.MuiSnackbarContent-success": {
          backgroundColor: "#52F597",
          color: "rgba(0, 0, 0, 0.87)",
          "& .MuiButtonBase-root": {
            color: "rgba(0, 0, 0, 0.87)"
          }
        },
        "& > .MuiSnackbarContent-message": {
          minHeight: 40,
          padding: "0px",
          flexGrow: 1,
          display: "flex",
          alignItems: "center",
          columnGap: "12px",
          "& .MuiButtonBase-root": {
            padding: "6px",
            "& > .MuiButton-label ": {
              letterSpacing: "0.6px"
            }
          },
          "&  .MuiSvgIcon-root": {
            fontSize: "1.25rem"
          }
        },
        "& > .MuiSnackbarContent-action": {
          marginRight: "0px",
          paddingLeft: "12px",
          "& .MuiButtonBase-root": {
            marginRight: "12px",
            padding: "6px",
            "& > .MuiButton-label ": {
              letterSpacing: "0.6px"
            },
            "&:last-child": {
              marginRight: "0px"
            }
          },
          "&  .MuiSvgIcon-root": {
            fontSize: "1.25rem"
          }
        }
      }
    },
    MuiInputLabel: {
      outlined: {
        fontSize: "16px",
        lineHeight: "20px",
        color: "rgba(0, 0, 0, 0.6)",
        transform: "translate(15px, 10px) scale(1)",
        width: "95%",
        letterSpacing: "0.15px"
      },
      $disabled: {
        color: "rgba(0, 0, 0, 0.38)"
      }
    },
    MuiAutocomplete: {
      input: {
        height: "19px"
      }
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: "2px solid #52F597"
        },
        "&.Mui-disabled .MuiOutlinedInput-notchedOutline": {
          border: "1px solid #E0E0E0"
        },
        "&.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderWidth: "2px"
        },
        "&.Mui-disabled .MuiOutlinedInput-input": {
          color: "rgba(0, 0, 0, 0.38)"
        },
        "& .MuiInputAdornment-root": {
          color: "rgba(0, 0, 0, 0.6)"
        },
        "&.Mui-disabled .MuiInputAdornment-root": {
          color: "#9E9E9E"
        }
      },
      input: {
        padding: "10px 16px",
        lineHeight: "20px",
        height: "auto",
        color: "rgba(0, 0, 0, 0.87)",
        letterSpacing: "0.15px"
      },
      notchedOutline: {
        border: "1px solid #9E9E9E",
        "& legend > span": {
          paddingLeft: "4px",
          paddingRight: "4px"
        }
      },
      adornedEnd: {
        paddingRight: "12px"
      },
      adornedStart: {
        paddingLeft: "16px"
      }
    },
    MuiFormHelperText: {
      root: {
        color: "rgba(0, 0, 0, 0.6)",
        fontSize: "12px",
        lineHeight: "16px",
        letterSpacing: "0.4px",
        fontWeight: 400,
        marginTop: "2px",
        "& small": {
          fontSize: "12px"
        }
      }
    },
    MuiFormLabel: {
      root: {
        "&.Mui-error": {
          color: "rgba(0, 0, 0, 0.87)"
        }
      }
    }
  }
});

theme = createTheme(theme, {
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 0,
        padding: "12px 16px",
        height: "40px",
        letterSpacing: 1
      },
      containedPrimary: {
        boxShadow: "none",
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        "&:disabled": {
          backgroundColor: "#E0E0E0",
          color: "#9E9E9E"
        },
        "&:hover": {
          backgroundColor: theme.palette.primary.main,
          color: "#fff",
          boxShadow: "none"
        },
        "&:focus": {
          backgroundColor: "#8A8892",
          color: theme.palette.background.default,
          boxShadow: "none"
        }
      },
      outlinedPrimary: {
        border: `2px solid ${theme.palette.primary.main}`,
        "&:disabled": {
          border: "2px solid #E0E0E0",
          color: "#9E9E9E"
        },
        "&:hover": {
          backgroundColor: "rgba(21, 17, 36, 0.04)",
          border: `2px solid ${theme.palette.primary.main}`
        },
        "&:focus": {
          backgroundColor: "rgba(21, 17, 36, 0.12)"
        }
      },
      textPrimary: {
        "&:disabled": {
          color: "#9E9E9E"
        },
        "&:hover": {
          backgroundColor: "rgba(21, 17, 36, 0.04)"
        },
        "&:focus": {
          backgroundColor: "rgba(21, 17, 36, 0.12)"
        }
      }
    },
    MuiIconButton: {
      root: {
        padding: 8
      }
    },
    MuiRadio: {
      root: {
        padding: 8
      },
      colorSecondary: {
        "&$checked$disabled": {
          color: "#CBFCE0"
        }
      }
    },

    MuiCheckbox: {
      root: {
        padding: 8
      },
      colorSecondary: {
        "&$disabled$checked": {
          color: "#CBFCE0",
          "& div": {
            backgroundColor: "#B9B8BD !important"
          }
        }
      }
    },
    MuiSlider: {
      root: {
        width: 116,
        height: 4
      },
      thumb: {
        height: "14px !important",
        width: "14px !important",
        margin: "-5px 0 0 -6px !important"
      },
      track: {
        height: 4,
        borderRadius: 10
      },
      rail: {
        width: 120,
        height: 4,
        borderRadius: 10
      },
      mark: {
        margin: "1px 0 0 1px",
        height: 2,
        width: 2
      },
      thumbColorPrimary: {
        "&:hover": {
          boxShadow: "0px 0px 0px 8px rgb(21 17 36 / 0.12) !important"
        },
        "&:focus": {
          boxShadow: "0px 0px 0px 8px rgb(21 17 36 / 0.24) !important"
        }
      },
      thumbColorSecondary: {
        "&:hover": {
          boxShadow: "0px 0px 0px 8px rgb(134 248 182 / 0.12) !important"
        },
        "&:focus": {
          boxShadow: "0px 0px 0px 8px rgb(134 248 182 / 0.24) !important"
        }
      }
    },
    MuiFab: {
      root: {
        fontStyle: "normal",
        letterSpacing: 1.25
      },
      secondary: {
        "&:hover": {
          backgroundColor: `${theme.palette.secondary.main}`
        }
      },
      label: {
        display: "flex",
        gap: 8
      }
    },
    MuiDialog: {
      root: {
        "& .MuiPaper-rounded": {
          borderRadius: "4px"
        },
        "& .MuiPaper-root": {
          boxShadow:
            "0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)"
        }
      }
    },
    MuiDialogTitle: {
      root: {
        paddingTop: "40px",
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingBottom: "8px",
        "& .MuiTypography-root": {
          fontWeight: "500",
          fontSize: "20px",
          lineHeight: "24px",
          letterSpacing: "0.25px",
          color: "rgba(0, 0, 0, 0.87)"
        }
      }
    },

    MuiDialogContent: {
      root: {
        "& .MuiDialogContentText-root": {
          fontWeight: "400",
          fontSize: "16px",
          lineHeight: "24px",
          letterSpacing: "0.25px",
          color: "rgba(0, 0, 0, 0.6)",
          marginBottom: "0"
        }
      }
    },
    MuiDialogActions: {
      root: {
        padding: "8px"
      }
    },
    MuiTabs: {
      root: {
        "& .MuiButtonBase-root": {
          backgroundColor: theme.palette.primary.main,
          color: "rgba(255, 255, 255, 0.74)",
          letterSpacing: "1px",
          padding: "16px"
        },
        "& .MuiButtonBase-root.MuiTab-labelIcon": {
          paddingTop: "12px",
          paddingBottom: "12px",
          letterSpacing: "1.25px"
        },
        "& .MuiTab-labelIcon .MuiTab-wrapper > *:first-child": {
          marginBottom: "8px"
        },
        "& .MuiButtonBase-root.Mui-selected, & .MuiButtonBase-root.Mui-selected:hover":
          {
            color: "rgba(255, 255, 255, 1)"
          },
        "& .MuiButtonBase-root.Mui-disabled, & .MuiButtonBase-root.Mui-disabled:hover":
          {
            color: "rgba(255, 255, 255, 0.38)"
          },
        "& .MuiButtonBase-root:hover": {
          color: theme.palette.secondary.main
        },
        "& .MuiTabs-indicator": {
          height: "4px"
        }
      }
    },
    MuiDataGrid: {
      root: {
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          padding: 0
        },
        "& .MuiDataGrid-colCellTitle, & .MuiDataGrid-columnHeaderTitle": {
          fontWeight: "500",
          userSelect: "none"
        },
        "& .MuiDataGrid-colCell, & .MuiDataGrid-columnHeader": {
          fontSize: "16px",
          letterSpacing: "0.25px",
          color: "rgba(0, 0, 0, 0.87)",
          padding: "0 16px",
          [theme.breakpoints.down("xs")]: {
            fontSize: "14px"
          }
        },
        "& .MuiDataGrid-colCell:hover, & .MuiDataGrid-columnHeader:hover": {
          background: "rgba(0, 0, 0, 0.04)"
        },
        "& .MuiDataGrid-colCell:focus, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
          {
            background: "rgba(0, 0, 0, 0.12)",
            outline: "none"
          },
        // '& .MuiDataGrid-row': {},
        "& .MuiDataGrid-cell": {
          fontSize: "16px",
          letterSpacing: "0.25px",
          color: "rgba(0, 0, 0, 0.87)",
          padding: "0 16px",
          userSelect: "none",
          [theme.breakpoints.down("xs")]: {
            fontSize: "14px"
          }
        },
        "& .MuiDataGrid-cell:hover": {
          background: "rgba(0, 0, 0, 0.04)"
        },
        "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
          background: "rgba(0, 0, 0, 0.12)",
          outline: "none"
        },
        "& .MuiDataGrid-columnSeparator": {
          display: "none"
        }
      }
    },
    MuiTableHead: {
      root: {
        "& .MuiTableCell-head": {
          fontSize: "16px",
          fontWeight: "500",
          userSelect: "none",
          letterSpacing: "0.25px",
          lineHeight: "24px",
          color: "rgba(0, 0, 0, 0.87)",
          [theme.breakpoints.down("xs")]: {
            fontSize: "14px",
            lineHeight: "20px"
          }
        },
        "& .MuiTableCell-root": {
          padding: "15.5px 16px",
          [theme.breakpoints.down("xs")]: {
            padding: "16px"
          }
        },
        "& .MuiTableCell-head:focus, & .MuiTableCell-head:focus-within": {
          background: "rgba(0, 0, 0, 0.12)",
          outline: "none"
        }
      }
    },
    MuiTableRow: {
      root: {
        "&.Mui-selected, &.Mui-selected:hover": {
          backgroundColor: "#EAFEF3",
          boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)"
        },
        "& .MuiTableRow-hover:hover": {
          background: "rgba(0, 0, 0, 0.04)"
        },
        "& .MuiTableRow-hover:focus, & .MuiTableRow-hover:focus-within": {
          background: "rgba(0, 0, 0, 0.12)",
          outline: "none"
        }
      }
    },
    MuiTableCell: {
      root: {
        fontSize: "16px",
        fontWeight: "400",
        lineHeight: "24px",
        padding: "13px 16px",
        letterSpacing: "0.25px",
        color: "rgba(0, 0, 0, 0.87)",
        userSelect: "none",
        "& .MuiIconButton-sizeSmall": {
          padding: 0
        },
        [theme.breakpoints.down("xs")]: {
          fontSize: "14px",
          lineHeight: "20px",
          padding: "14px 16px"
        }
      }
    },
    MuiTablePagination: {
      root: {},
      actions: {
        color: "rgba(0, 0, 0, 0.6)",
        "& .MuiButtonBase-root": {
          padding: "8px"
        },
        "& .MuiIconButton-root.Mui-disabled": {
          color: "rgba(0, 0, 0, 0.38)"
        }
      }
    },
    MuiListItem: {
      root: {
        "&.Mui-selected, &.Mui-selected:hover": {
          backgroundColor: "#EAFEF3"
        }
      }
    }
  }
});

export default theme;
