import { CourierProvider } from "@trycourier/react-provider";
import { Inbox } from "@trycourier/react-inbox";
import { Toast } from "@trycourier/react-toast";

import useCompany from "../../../../hooks/useCompany";
import useUserData from "../../../../hooks/useUserData";

import { DesktopInboxTheme, ToastTheme } from "./theme";
import BellIcon from "./components/BellIcon";
import Header from "./components/Header";
import { formatDistanceStrict } from "date-fns";
import { useTranslation } from "react-i18next";
import { enGB as en, es, de, ro } from "date-fns/locale";

const COURIER_API_KEY = process.env.REACT_APP_COURIER_API_KEY;

export const NotificationsMenu = () => {
  const data = useUserData();
  const { selectedCompany } = useCompany();
  const { i18n } = useTranslation();

  const i18n_locale = i18n.language;
  const locales = { en, es, de, ro };
  const locale = locales[i18n_locale] || en;

  const getTimeAgo = (created, locale) => {
    const timeAgo = formatDistanceStrict(new Date(created), Date.now(), {
      addSuffix: true,
      roundingMethod: "floor",
      locale
    });
    return timeAgo;
  };

  if (selectedCompany === "all") {
    return null;
  }

  return (
    <>
      <CourierProvider clientKey={COURIER_API_KEY} userId={data._id}>
        <Toast position="bottom-right" theme={ToastTheme} />
        <Inbox
          openLinksInNewTab={false}
          renderIcon={BellIcon}
          renderHeader={Header}
          theme={DesktopInboxTheme}
          formatDate={isoDate => getTimeAgo(isoDate, locale)}
        />
      </CourierProvider>
    </>
  );
};
