import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  TextField,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import Button from "../../formElements/Button";
import { withTranslation } from "react-i18next";
import { withCookies } from "react-cookie";

const styles = theme => ({
  container: {
    margin: "25px 10px",
    padding: "20px"
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  buttons: {
    marginTop: "2em",
    justifyContent: "space-evenly"
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "rgba(95,103,117,1)"
  },
  itemButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(95,103,117,1)",
    padding: "10px 5px"
  },
  text: {
    marginBottom: "2em",
    textAlign: "center",
    width: "100%"
  },
  radioGroup: {
    flexDirection: "row",
    width: "100%"
  },
  textField: {
    width: "100%"
  },
  error: {
    color: "red",
    width: "100%",
    display: "none"
  },
  policyText: {
    fontSize: "0.9rem",
    "& > a": {
      color: "orange"
    }
  }
});

const optional = ["message", "signforinformation"];

class SignupFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ...props.companyData,
      checkbox: {
        signForInformation: false,
        acceptPolicy: false
      },
      dirty: {
        email: false || props.companyData.email.length > 0,
        gender: false || props.companyData.gender.length > 0,
        message: false || props.companyData.message.length > 0,
        nachname: false || props.companyData.nachname.length > 0,
        telefonnummer: false || props.companyData.telefonnummer.length > 0,
        unternehmen: false || props.companyData.unternehmen.length > 0,
        vorname: false || props.companyData.vorname.length > 0
      }
    };
  }

  isValidField = item =>
    optional.indexOf(item) > -1 || this.state[item].length > 0;

  isErrorField = item => this.state.dirty[item] && !this.isValidField(item);

  isValid = () => {
    let valid = true;
    Object.keys(this.state)
      .filter(e => e !== "dirty")
      .filter(e => e !== "checkbox")
      .forEach(item => {
        valid = valid && this.isValidField(item);
      });
    return valid;
  };

  handleChange = event => {
    const { setCompanyData } = this.props;
    event.persist();
    this.setState(
      prevState => {
        prevState[event.target.id || event.target.name] = event.target.value;
        prevState.dirty[event.target.id || event.target.name] = true;
        return prevState;
      },
      () => {
        this.toggleError(event);
      }
    );
    setCompanyData(event.target.id || event.target.name, event.target.value);
  };

  toggleError = ({ target }) => {
    let elem = target.id || target.name;
    this.setState({ dirty: { ...this.state.dirty, [elem]: true } }, () => {
      if (optional.indexOf(elem) > -1) return;
      let span = document.getElementById(elem + "-error");
      if (!this.isValidField(elem)) {
        span.style.display = "block";
      } else {
        span.style.display = "none";
      }
    });
  };

  handleCheckbox = event => {
    this.setState({
      checkbox: {
        ...this.state.checkbox,
        [event.target.id]: event.target.checked
      }
    });
  };

  render() {
    const { classes, t, onBack } = this.props;

    return (
      <form
        onSubmit={ev => {
          ev.preventDefault();
        }}
        className={classes.wrapper}
      >
        <Grid container className={classes.container} spacing={8}>
          <Grid item xs={12} className={classes.item}>
            <Typography variant="subtitle1" className={classes.text}>
              {t("Mit wem haben wir die Ehre?")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <RadioGroup
                aria-label="Gender"
                name="gender"
                className={classes.radioGroup}
                onChange={this.handleChange}
                value={this.state.gender}
              >
                <FormControlLabel
                  value={t("Herr")}
                  control={<Radio color="primary" />}
                  label="Herr"
                />
                <FormControlLabel
                  value={t("Frau")}
                  control={<Radio color="primary" />}
                  label="Frau"
                />
              </RadioGroup>
              <span className={classes.error} id="gender-error">
                {t("Gender ist erforderlich!")}
              </span>
            </FormControl>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="vorname"
              placeholder={t("Vorname") + "*"}
              variant="outlined"
              fullWidth
              onChange={this.handleChange}
              onBlur={this.toggleError}
              value={this.state.vorname}
              error={this.isErrorField("vorname")}
            />
            <span className={classes.error} id="vorname-error">
              {t("Vorname ist erforderlich!")}
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="nachname"
              placeholder={t("Nachname") + "*"}
              variant="outlined"
              fullWidth
              onChange={this.handleChange}
              onBlur={this.toggleError}
              value={this.state.nachname}
              error={this.isErrorField("nachname")}
            />
            <span className={classes.error} id="nachname-error">
              {t("Nachname ist erforderlich!")}
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="unternehmen"
              placeholder={t("Unternehmen") + "*"}
              variant="outlined"
              fullWidth
              onChange={this.handleChange}
              onBlur={this.toggleError}
              value={this.state.unternehmen}
              error={this.isErrorField("unternehmen")}
            />
            <span className={classes.error} id="unternehmen-error">
              {t("Unternehmen ist erforderlich!")}
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="telefonnummer"
              placeholder={t("Telefonnummer") + "*"}
              variant="outlined"
              fullWidth
              onChange={this.handleChange}
              onBlur={this.toggleError}
              value={this.state.telefonnummer}
              error={this.isErrorField("telefonnummer")}
            />
            <span className={classes.error} id="telefonnummer-error">
              {t("Telefonnummer ist erforderlich!")}
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="email"
              placeholder={t("E-Mail Adresse") + "*"}
              variant="outlined"
              fullWidth
              onChange={this.handleChange}
              onBlur={this.toggleError}
              value={this.state.email}
              error={this.isErrorField("email")}
            />
            <span className={classes.error} id="email-error">
              {t("E-Mail Adresse ist erforderlich!")}
            </span>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              id="message"
              placeholder={t("Ihre Nachricht an uns")}
              variant="outlined"
              multiline
              fullWidth
              onChange={this.handleChange}
              value={this.state.message}
            />
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="signForInformation"
                  checked={this.state.checkbox.signForInformation}
                  onClick={this.handleCheckbox}
                  color="primary"
                />
              }
              label={t(
                "Ich möchte über Neuigkeiten rund um Schrott24 informiert werden"
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" className={classes.policyText}>
              {t("AGREED_DATA_TO_BE_USED")}{" "}
              <a
                href="https://www.schrott24.de/disclaimer/"
                rel="noopener noreferrer"
                target="_blank"
              >
                {t("Datenschutzerklärung")}
              </a>
              .
            </Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <FormControlLabel
              control={
                <Checkbox
                  id="acceptPolicy"
                  checked={this.state.checkbox.acceptPolicy}
                  onClick={this.handleCheckbox}
                  color="primary"
                  value="acceptPolicy"
                />
              }
              label={t("PROTECTION_DATA_ACCEPTED")}
            />
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={classes.buttons}
            justify="center"
          >
            <Button
              disabled={false}
              className={classes.button}
              text={"< " + t("Züruck")}
              onClick={onBack}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withRouter(
  withStyles(styles)(withTranslation()(withCookies(SignupFields)))
);
