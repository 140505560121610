import gql from "graphql-tag";

export default gql`
  mutation OnboardingLoginMutation($token: String!, $keepToken: Boolean!) {
    OnboardingLoginMutation(token: $token, keepToken: $keepToken) {
      id
      token
      tokenExpires
    }
  }
`;
