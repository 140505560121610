import { Button, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import CustomDropzone from "../../uploadDialog/components/Dropzone";
import AddIcon from "@material-ui/icons/Add";
import { useCallback, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  root: {
    [theme.breakpoints.up("md")]: {
      width: "640px",
      "min-heigth": "0px"
    }
  },
  addIcon: {
    marginRight: "4px"
  },
  dropzoneContainer: {
    padding: "41px 21px 41px 21px",
    background: "rgba(21, 17, 36, 0.05)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    lineHeight: "43px",
    letterSpacing: ".25px",
    color: "#151124",
    marginTop: "8px",
    marginBottom: "24px",
    marginLeft: "24px"
  },
  dropzoneContent: {
    display: "flex",
    "& p": {
      margin: 0,
      lineHeight: "24px",
      fontWeight: "400",
      letterSpacing: "0.25px",
      "& > a": {
        color: "#276EF1",
        textDecoration: "none"
      }
    }
  },
  actionButtons: {
    paddingTop: "16px !important",
    [theme.breakpoints.down("sm")]: {
      background: "white",
      zIndex: "9",
      position: "fixed",
      bottom: "0",
      right: "0",
      left: "0",
      boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.12)",
      paddingTop: "24px!important"
    }
  },
  column: {
    width: "550px"
  }
}));

export default ({
  file,
  title,
  setFile,
  setPrevStepAction,
  setNextStepAction,
  accept
}) => {
  const classes = useStyles();
  const dropzoneRef = useRef();
  const { t } = useTranslation();
  const theme = useTheme();
  const openUploadDialog = useCallback(e => {
    e.preventDefault();
    dropzoneRef.current && dropzoneRef.current.open();
  }, []);

  const addFileAction = useCallback(
    uploadedFile => {
      setFile(uploadedFile[0]);
      setNextStepAction();
    },
    [file, setFile]
  );

  return (
    <CustomDropzone accept={accept} onDrop={addFileAction} ref={dropzoneRef}>
      {title}
      <div className={classes.column}>
        <div className={classes.dropzoneContainer}>
          <div className={classes.dropzoneContent}>
            <AddIcon className={classes.addIcon} />
            <p>
              {t("DragDropWindow1")}{" "}
              <a href="#" onClick={openUploadDialog}>
                {t("DragDropWindowBrowse")}
              </a>
              <br />
              {t("DragDropWindow2")}
            </p>
          </div>
        </div>

        <div className={classes.actionButtons}>
          <Button
            color="primary"
            classes={{ root: classes.buttonRoot }}
            variant="outlined"
            onClick={() => {
              setFile(null);
              setPrevStepAction();
            }}
            style={{ float: "right" }}
          >
            {t("Back")}
          </Button>
        </div>
      </div>
    </CustomDropzone>
  );
};
