import React from "react";
import NumberFormat from "react-number-format";
import _ from "lodash";

export default props => (
  <NumberFormat
    value={
      props.value
        ? _.isFinite(props.value)
          ? props.value
          : parseFloat(props.value)
        : 0
    }
    displayType={"text"}
    thousandSeparator={"."}
    decimalSeparator={","}
    decimalScale={props.nodec ? 0 : 2}
    fixedDecimalScale
  />
);
