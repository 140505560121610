const frequencyRates = [
  "Spot",
  "Daily",
  "Weekly",
  "Biweekly",
  "Every month",
  "Every second month",
  "Every three months",
  "Every four months",
  "Every five months",
  "Every six months",
  "Yearly"
];

export default frequencyRates;
