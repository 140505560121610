import gql from "graphql-tag";

export default gql`
  query GetEnterpriseAliases($token: String!, $companyId: String!) {
    getEnterpriseAliases(token: $token, companyId: $companyId) {
      id
      partnerId
      metaloopAliasId
      aliasName
    }
  }
`;
