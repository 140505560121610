import useEventTracking from "./useEventTracking";

function withEventTracking(Component) {
  return function WrappedComponent(props) {
    const { TrackEvent } = useEventTracking();
    return <Component {...props} TrackEvent={TrackEvent} />;
  };
}

export default withEventTracking;
