import gql from "graphql-tag";

export default gql`
  query getSingleUser($token: String!, $id: String!, $company: String!) {
    getSingleUser(token: $token, company: $company, id: $id) {
      id
      firstName
      lastName
      avatar
      email
      roles {
        id
        name
        label
        description
      }
      language
    }
  }
`;
