import gql from "graphql-tag";

export const GET_VAT_DETAILS = gql`
  query GET_VAT_DETAILS($token: String!, $company: String!) {
    GetVatsDetails(token: $token, company: $company) {
      companyTaxType
      paymentCredentials
      paymentOption
      paymentTerms
      taxIdentificationNumber {
        country
        value
        type
        default
        validated
      }
    }
  }
`;

export const GET_ACCOUNTING_EMAILS = gql`
  query GET_ACCOUNTING_EMAILS($token: String!, $company: String!) {
    GetAccountingEmails(token: $token, company: $company) {
      emails
    }
  }
`;
