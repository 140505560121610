import gql from "graphql-tag";

export default gql`
  mutation AddNewMaterialToCatalogue(
    $token: String!
    $partnerId: String!
    $productId: Int!
    $files: [Upload]
    $additionalComments: String
  ) {
    AddNewMaterialToCatalogue(
      token: $token
      partnerId: $partnerId
      productId: $productId
      files: $files
      additionalComments: $additionalComments
    ) {
      productId
    }
  }
`;
