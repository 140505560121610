import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import classnames from "classnames";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import useDocPreview from "../../../hooks/useDocPreview";
import DeleteDialog from "./DeleteDialog";
import REMOVE_FILE_MUTATION from "../../../mutations/removeFileMutation";
import { showError, showSuccess } from "./Notify";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    cursor: "pointer",
    textDecoration: "none",
    "&:not(:last-child)": {
      marginBottom: "4px"
    }
  },
  extension: {
    width: "26px",
    height: "17px",
    background: "#E3E2E5",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "9px",
    lineHeight: "16px",
    letterSpacing: "0.5px",
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.87)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  link: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "#276ef1",
    display: "block",
    flex: "1",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap"
  },
  deleteBtn: {
    padding: 0,
    order: 3
  },
  [theme.breakpoints.up("md")]: {
    hasDeleteOption: {
      "&:hover .MuiIconButton-root": {
        display: "flex"
      },
      "&:hover .extension__root": {
        display: "none"
      }
    },
    deleteBtn: {
      order: "initial",
      display: "none"
    }
  }
}));

const FileItem = ({
  token,
  file,
  fileId,
  orderId,
  showDeleteIcon,
  refetch
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const { setDocument } = useDocPreview();
  const { documentName, downloadLink, fileType } = file || {};

  const { extension, text, href } = React.useMemo(() => {
    let text = "";
    let extension = "";
    const lastIndex = documentName.lastIndexOf(".");
    if (lastIndex === -1) {
      extension = fileType.substring(fileType.lastIndexOf("/") + 1);
      text = documentName;
    } else {
      text = documentName.substring(0, lastIndex);
      extension = documentName.substring(lastIndex + 1);
    }
    const href = `${downloadLink}?filename=${documentName}`;

    text = decodeURIComponent(text);
    return {
      text,
      extension: extension || "txt",
      href
    };
  }, [documentName, downloadLink, fileType]);

  // use mutation here
  const [onRemove, { loading }] = useMutation(REMOVE_FILE_MUTATION);

  const onClick = event => {
    event.preventDefault();
    event.stopPropagation();
    if (event.currentTarget.nodeName === "BUTTON") {
      setShowModal(true);
      return;
    }
    setDocument(file, {
      orderId
    });
  };

  // handle delete here
  const onDelete = () => {
    onRemove({
      variables: {
        input: {
          token,
          orderId,
          imageId: fileId,
          collection: "PartnerUploads"
        }
      }
    })
      .then(() => {
        showSuccess(t("Successfully deleted"));
        setShowModal(false);
        // handle refectching of data if provided...
        if (refetch) {
          refetch();
        }
      })
      .catch(err => showError(err.message));
  };

  // use here, after hooks
  if (!file) {
    throw new Error("file is missing");
  }

  return (
    <>
      <a
        className={classnames(
          classes.root,
          showDeleteIcon && classes.hasDeleteOption
        )}
        target="_blank"
        onClick={onClick}
        href={href}
        rel="noopener noreferrer"
      >
        {showDeleteIcon && (
          <IconButton
            aria-label="delete"
            className={classes.deleteBtn}
            onClick={onClick}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
        <span className={classnames("extension__root", classes.extension)}>
          {extension}
        </span>
        <span className={classnames("link__root", classes.link)}>{text}</span>
      </a>
      <DeleteDialog
        title={"Delete_Document_question"}
        text={"Delete_Document_text"}
        delete_button_text={"Delete_Document_button"}
        onDelete={onDelete}
        isDeleting={showModal}
        closeModals={() => setShowModal(false)}
      />
    </>
  );
};

FileItem.propTypes = {
  file: PropTypes.object.isRequired,
  showDeleteIcon: PropTypes.bool,
  refetch: PropTypes.func
};

FileItem.defaultProps = {
  file: {
    documentName: "sample.pdf",
    documentType: "Sample",
    downloadLink: "https://via.placeholder.com/600/92c952",
    fileType: "image/png"
  },
  showDeleteIcon: false
};

export default FileItem;
