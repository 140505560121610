import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import queryString from "query-string";
import { bindActionCreators } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { checkUserAndSendMagicLink } from "../../actions/auth";
import LoginFastForm from "../../components/forms/LoginFastForm";
import i18next from "i18next";
import WaitingForEmail from "../../components/forms/WaitingForEmail";
import { withTranslation } from "react-i18next";
import { CheckErrors } from "../../ErrorTracking";

const styles = theme => ({});

class LoginFastContainer extends React.Component {
  constructor(props) {
    super(props);
    this.onLogin = this.onLogin.bind(this);
    let search = queryString.parse(window.location.search);
    this.isReset = search.reset ? true : false;
  }

  onLogin({ email }) {
    const { actions } = this.props;

    const { checkUserAndSendMagicLink: checkUserAndSendMagicLinkAction } =
      actions;
    checkUserAndSendMagicLinkAction(
      email.toLowerCase(),
      `${window.location.origin}`,
      i18next.languages[0]
    );
  }

  render() {
    const { loginErrorMessage, emailMagicLinkSended } = this.props;
    return (
      <>
        {emailMagicLinkSended === undefined ? (
          <CheckErrors>
            <LoginFastForm
              onSubmit={this.onLogin}
              loginErrorMessage={loginErrorMessage}
            />
          </CheckErrors>
        ) : (
          <WaitingForEmail
            email={emailMagicLinkSended}
            sendMail={this.onLogin}
          />
        )}
      </>
    );
  }
}

LoginFastContainer.defaultProps = {
  classes: {},
  redirectLink: "",
  loginErrorMessage: ""
};

LoginFastContainer.propTypes = {
  classes: PropTypes.object,
  actions: PropTypes.shape({
    checkUserAndSendMagicLink: PropTypes.func
  }).isRequired,
  history: PropTypes.object.isRequired,
  redirectLink: PropTypes.string,
  loginErrorMessage: PropTypes.string,
  emailMagicLinkSended: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ checkUserAndSendMagicLink }, dispatch)
});

const mapStateToProps = ({
  user: { redirectLink, loginErrorMessage, emailMagicLinkSended }
}) => ({
  redirectLink,
  loginErrorMessage,
  emailMagicLinkSended
});

LoginFastContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginFastContainer);

export default withRouter(
  withStyles(styles)(withTranslation()(LoginFastContainer))
);
