import deepExtend from "deep-extend";
import React from "react";
import styled from "styled-components";
import StyledTippy from "../StyledTippy";
import { ReactComponent as CloseIcon } from "../../../../../../assets/closeIcon.svg";
const StyledButton = styled.button(({ theme }) =>
  deepExtend(
    {},
    {
      cursor: "pointer",
      border: "none",
      background: "transparent",
      borderRadius: "6px",
      padding: 0,
      marginLeft: "6px",
      height: "24px",
      width: "24px",
      transition: "background 200ms ease-in-out",

      "&:hover": {
        background: "rgba(21,17,36,0.14)"
      }
    },
    theme.action
  )
);

const CloseAction = ({ onClick, tooltip, size, title }) => (
  <StyledTippy content={tooltip}>
    <StyledButton
      title={title ?? "close inbox"}
      className="close"
      onClick={onClick}
    >
      <CloseIcon />
    </StyledButton>
  </StyledTippy>
);

export default CloseAction;
