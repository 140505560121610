import React from "react";

import { makeStyles } from "@material-ui/core";
const AddressLabel = ({ isMobile = false, ...props }) => {
  const useStyles = makeStyles({
    labelContainer: {
      background: "#E3E2E5",
      padding: "5px 6px",
      display: "flex",
      width: "100%",
      fontWeight: "500",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "rgba(0, 0, 0, 0.87)"
    },
    labelContainerMobile: {
      background: "#E3E2E5",
      padding: "0.5px 5px 0px 5px",
      display: "flex",
      width: "100%",
      fontWeight: "500",
      fontSize: "10px",
      lineHeight: "16px",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
      color: "rgba(0, 0, 0, 0.87)"
    }
  });
  const classes = useStyles();
  return (
    <div
      className={
        isMobile ? classes.labelContainerMobile : classes.labelContainer
      }
    >
      {props.postCode} {props.city}
    </div>
  );
};
export default AddressLabel;
