import React from "react";
import { ProductFruits as OriginalProductFruits } from "react-product-fruits";
import useUserData from "./hooks/useUserData";
import useCompany from "./hooks/useCompany";
import { useTranslation } from "react-i18next";

const ProductFruits = () => {
  const { i18n } = useTranslation();
  const currentCompany = useCompany();
  const { email, firstName, lastName, pf } = useUserData();

  if (!currentCompany || !currentCompany.roles) return null;

  const workspaceCode = process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE;

  if (!workspaceCode) return null;

  const isSeller = currentCompany.roles.includes("seller");
  const isBuyer = currentCompany.roles.includes("buyer");
  const isTransporter = currentCompany.roles.includes("transporter");
  const isRetail = currentCompany.isRetail;

  const userInfo = {
    username: pf.username,
    email: email,
    firstname: firstName,
    lastname: lastName,
    role: (currentCompany.companyRoles || []).join(", "),
    hmac: {
      hash: pf.hmacHash,
      expiration: pf.expirationDate
    },
    props: { isSeller, isBuyer, isTransporter, isRetail }
  };

  if (i18n?.language?.includes("-")) return null;

  return (
    <OriginalProductFruits
      key={i18n.language} // key added here to re-render when language changes
      workspaceCode={workspaceCode}
      language={i18n.language}
      user={userInfo}
      lifeCycle="unmount"
      hideInAppCenterLauncher={true}
    />
  );
};

export default ProductFruits;
