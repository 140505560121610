import { createStyles, makeStyles } from "@material-ui/core";

const offerElementStyles = makeStyles(
  createStyles(theme => ({
    scrollbar: {
      height: "100%",
      flexBasis: 0,
      flexGrow: 1,
      borderLeft: "1px solid #EEEEEE",
      marginRight: "5px"
    },
    elementContainer: {
      fontWeight: "normal",
      lineHeight: "20px",
      fontSize: "14px",
      display: "flex",
      flexDirection: "column",
      letterSpacing: 0.25,
      color: "rgba(0, 0, 0, 0.6)",
      width: "100%",
      borderLeft: "1px solid #EEEEEE",
      "& > h6": {
        marginBottom: 8,
        letterSpacing: 0.25,
        color: "rgba(0, 0, 0, 0.87)"
      }
    },
    elementContainerMobile: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "normal",
      lineHeight: "20px",
      fontSize: "14px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.6)",
      height: "100%",
      position: "absolute",
      top: "0",
      left: "0",
      zIndex: "2",
      overflowY: "auto",
      backgroundColor: "white",
      paddingBottom: "80px"
    },
    rightContainer: {
      width: "313px",
      borderLeft: "1px solid #EEEEEE",
      borderRight: "1px solid #EEEEEE",
      borderBottom: "1px solid #EEEEEE"
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "16px 24px"
    },
    offeredMaterialsText: {
      fontSize: 20,
      fontWeight: 400,
      padding: "8px 0",
      color: "rgba(0, 0, 0, 0.87)"
    },
    finishBidButton: {
      padding: "0 8px"
    },
    selectedOfferHeader: {
      padding: "16px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    selectedOfferHeaderText: {
      paddingLeft: "16px",
      height: "24px"
    },
    selectedOfferHeaderCloseIcon: {
      cursor: "pointer",
      height: "24px",
      "&:hover": {
        color: "#333333"
      }
    },
    bottomFinishBidButton: {
      padding: 24
    },
    scrollContainer: {
      overflowY: "overlay",
      "&::-webkit-scrollbar": {
        width: 14,
        borderRadius: "8px"
      },
      "&::-webkit-scrollbar-track": {
        display: "none"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundClip: "padding-box",
        border: "4px solid rgba(0, 0, 0, 0)",
        background: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
          backgroundClip: "padding-box",
          border: "4px solid rgba(0, 0, 0, 0)",
          background: "rgba(0, 0, 0, 0.08)"
        }
      },
      "& > div:nth-child(even)": {
        backgroundColor: "#FFFFFF"
      }
    },
    finishBidMobile: {
      position: "fixed",
      display: "flex",
      width: 328,
      height: "fit-content",
      bottom: 0,
      left: "50%",
      transform: "translate(-50%, -16px)",
      padding: "6px 8px 6px 16px",
      background: "#151124",
      borderRadius: 4,
      boxShadow:
        "0px 3px 5px -1px rgba(0, 0, 0, 0.2)," +
        "0px 6px 10px rgba(0, 0, 0, 0.14)," +
        "0px 1px 18px rgba(0, 0, 0, 0.12)"
    },
    finishBidMobileTitle: {
      color: "#FFFFFF",
      letterSpacing: 0.17,
      lineHeight: "143%"
    },
    leftBorder: {
      border: "1px solid #EEEEEE"
    }
  }))
);

export default offerElementStyles;
