import { makeStyles } from "@material-ui/core";
import React, { useContext } from "react";
import ConfirmPickup from "../../components/pickups/ConfirmPickup";
import RequestPickUp from "../../components/pickups/RequestPickUp";
import ReschedulePickup from "../../components/pickups/ReschedulePickup";
import { GET_FULL_PRODUCT_DATA } from "../../queries/Products";
import { useQuery } from "@apollo/react-hooks";
import UserContext from "../../providers/UserProvider";
export const ConfirmOrRescheduleRoute = ({
  match,
  confirm_transaction_id,
  reschedule_transaction_id,
  request_pickUp
}) => {
  const useStyles = makeStyles(theme => ({
    mainContentWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      padding: "16px"
    },
    contentArea: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      mainContentWrapper: {
        padding: "32px"
      }
    },
    [theme.breakpoints.up("lg")]: {
      contentArea: {
        width: "88.5%"
      }
    }
  }));

  const classes = useStyles();
  const { user, selectedCompany } = useContext(UserContext);
  const { error, loading, data } = useQuery(GET_FULL_PRODUCT_DATA, {
    variables: { token: user.token, companyId: selectedCompany },
    fetchPolicy: "cache-and-network",
    skip: false
  });

  const { FullProductData } = data;

  const productData = [...FullProductData.contracted];

  return (
    <div className={classes.mainContentWrapper}>
      <div className={classes.contentArea}>
        {reschedule_transaction_id && (
          <ReschedulePickup pickupId={reschedule_transaction_id} />
        )}

        {confirm_transaction_id && (
          <ConfirmPickup pickupId={confirm_transaction_id} />
        )}

        {request_pickUp && (
          <RequestPickUp id={request_pickUp} contractedData={productData} />
        )}
      </div>
    </div>
  );
};
