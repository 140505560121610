import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Paper } from "@material-ui/core";

const styles = theme => ({
  wrapper: {
    margin: "0 auto",
    width: "80%",
    maxWidth: "1300px",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center"
  }
});

const Container = props => (
  <Paper elevation={8} className={props.classes.wrapper}>
    {props.children}
  </Paper>
);

export default withStyles(styles)(Container);
