import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { StatusContext } from "./context";

const useStyles = makeStyles(theme => ({
  tab: {
    position: "relative",
    display: "inline-flex",
    flex: "1",
    alignItems: "center",
    background: "0 0",
    border: "0",
    outline: "none",
    paddingTop: "11px",
    paddingBottom: "10px",
    boxSizing: "border-box",
    fontFamily: "Roboto",
    fontStyle: "normal",
    "& *, & *:before": {
      boxSizing: "border-box",
      fontFamily: "Roboto",
      fontStyle: "normal"
    },
    "&:before, &:after": {
      content: "''",
      whiteSpace: "pre",
      position: "absolute",
      top: "1px",
      left: 0,
      right: 0,
      width: "100%",
      height: "2px",
      background: "#D9D9D9"
    },
    "&.progress": {
      color: theme.palette.primary.main
    },
    "&.wait": {
      color: "rgba(0, 0, 0, 0.38)"
    },
    "&.finished": {
      color: "rgba(0, 0, 0, 0.6)"
    },
    "&.finished:before, &:not(:first-child).progress:before": {
      background: theme.palette.primary.main
    },
    "&.wait:before": {
      background: "#D9D9D9"
    },
    "&:last-child .status_button:before": {
      content: "''",
      whiteSpace: "pre",
      position: "absolute",
      top: 0,
      right: 0,
      height: "2px",
      width: "50%"
    },
    "&:last-child.progress .status_button:before": {
      background: "#D9D9D9"
    },
    "&:after": {
      width: "calc(100% - 9px)", // 90px
      zIndex: "-1"
    },
    "&.progress:after": {
      background: theme.palette.secondary.main,
      height: "4px",
      top: "0",
      zIndex: "1",
      left: "9px"
    },
    "&.disabledProgress:after": {
      background: "rgba(0, 0, 0, 0.38)",
      height: "4px",
      top: "0",
      zIndex: "1"
    },
    "&:first-child.progress:after": {
      width: "100%",
      left: "0"
    },
    "&:not(:first-child)": {
      paddingLeft: "9px"
    },
    [theme.breakpoints.up("sm")]: {
      "&:first-child": {
        paddingLeft: "20px"
      },
      "&:last-child": {
        paddingRight: "20px"
      },
      "&:not(:first-child)": {
        paddingLeft: "10px"
      },
      "&.progress:after": {
        left: "10px"
      },
      "&:first-child.progress:after": {
        left: "0",
        width: "100%"
      }
    }
  },
  tabTitle: {
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    margin: 0
  },
  tabSubTitle: {
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    margin: 0
  },
  tabWrapper: {
    height: 0,
    overflow: "hidden"
  }
}));

const StatusBar = ({
  className,
  children,
  title,
  position,
  disabled = false
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const { currentIndex, nArrays, collapsed } = React.useContext(StatusContext);

  const status = React.useMemo(() => {
    if (currentIndex === position) {
      return "progress";
    }
    if (currentIndex > position) {
      return "finished";
    }
    return "wait";
  }, [currentIndex, position]);

  if (!nArrays.includes(position) && isMobile) {
    return null;
  }

  const disabledClass =
    disabled && status === "progress" ? "disabledProgress" : "";

  return (
    <div
      className={`status_root ${
        classes.tab || ""
      } ${disabledClass} ${!disabled && status}  ${className || ""}`}
    >
      <div
        role="tab"
        aria-selected="false"
        className={`status_button ${collapsed ? classes.tabWrapper || "" : ""}`}
        tabIndex="0"
      >
        <p className={classes.tabTitle}>{title}</p>
        <div className={classes.tabSubTitle}>{children}</div>
      </div>
    </div>
  );
};

StatusBar.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  position: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

StatusBar.defaultProps = {
  className: ""
};

export default StatusBar;
