import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core/styles";
import CheckBoxRoundedIcon from "@material-ui/icons/CheckBoxRounded";
import React from "react";
import { useTranslation } from "react-i18next";
const useCheckedIconStyles = makeStyles({
  root: {
    height: 24,
    width: 24,
    zIndex: 1
  },
  innerDiv: {
    height: 16,
    width: 16,
    position: "absolute",
    transform: "translate(4px, 4px)",
    zIndex: -1
  }
});

const CheckedIcon = ({ color }) => {
  const classes = useCheckedIconStyles();
  const backgroundColor = color === "primary" ? "#ffffff" : "#151124";
  return (
    <span className={classes.root}>
      <div className={classes.innerDiv} style={{ backgroundColor }} />
      <CheckBoxRoundedIcon />
    </span>
  );
};

const useCheckboxStyles = makeStyles({
  checkbox: {
    marginRight: "-3px"
  },
  label: {
    fontSize: "14px",
    letterSpacing: "0.25px"
  }
});

function CheckboxWithLabel({
  label,
  onChange,
  color = "primary",
  checked = false,
  specialLabel = null,
  disabled = false
}) {
  const { t } = useTranslation();
  const classes = useCheckboxStyles();

  return (
    <FormControlLabel
      control={
        <Checkbox
          checked={checked}
          checkedIcon={<CheckedIcon color={color} />}
          className={classes.checkbox}
          color={color}
          onChange={onChange}
          disabled={disabled}
        />
      }
      classes={{ label: classes.label }}
      label={specialLabel || t(label)}
    />
  );
}

export default CheckboxWithLabel;
