import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useCallback, useState } from "react";
import Snackbar from "../components/general/Snackbar/Snackbar";

let pushNotification;

const Notifications = () => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState({
    message: "",
    color: "primary",
    fixed: false,
    horizontal: "center",
    vertical: "bottom",
    duration: 6000,
    hideCloseAction: false,
    action: []
  });

  const {
    message = "",
    color = "primary",
    fixed = false,
    horizontal = "center",
    vertical = "bottom",
    duration = 6000,
    hideCloseAction = false,
    action = []
  } = options;

  pushNotification = useCallback(
    (
      message,
      {
        color = "primary",
        fixed = false,
        anchorOrigin = { horizontal: "center", vertical: "bottom" },
        autoHideDuration,
        hideCloseAction = false
      } = {},
      action = []
    ) => {
      setOpen(false);
      setTimeout(() => {
        setOptions({
          message,
          color,
          fixed,
          horizontal: anchorOrigin.horizontal,
          vertical: anchorOrigin.vertical,
          duration: autoHideDuration,
          hideCloseAction,
          action
        });
        setOpen(true);
      }, 100);
    },
    []
  );

  const hideNotification = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <Snackbar
      color={color}
      open={open}
      fixed={fixed}
      anchorOrigin={{
        vertical: vertical,
        horizontal: horizontal
      }}
      message={message}
      autoHideDuration={duration}
      ContentProps={{
        "aria-describedby": "snackbar-message-id"
      }}
      onClose={hideNotification}
      action={[
        ...action,
        ...(!hideCloseAction
          ? [
              <IconButton onClick={hideNotification}>
                <Close />
              </IconButton>
            ]
          : [])
      ]}
    />
  );
};

const useNotifications = () => ({ pushNotification });

export default Notifications;
export { useNotifications };
