import React from "react";
import { Grid, Typography } from "@material-ui/core";
import moment from "moment";
import AttentionIcon from "@material-ui/icons/Error";
import useStandardLocalization from "../../../hooks/useStandardLocalization";
import offerListStyles from "./offerListStyles";
import { useTranslation } from "react-i18next";

const OfferListItem = ({ offer }) => {
  const classes = offerListStyles();
  const { t } = useTranslation();
  const { parseNumber, parseDate } = useStandardLocalization();
  const deadlineDate = offer.deadlineDate
    ? parseDate(offer.deadlineDate)
    : null;

  const deadlineToday = isToday(offer.deadlineDate);

  return (
    <>
      <Grid item className={classes.menuItemContent}>
        <Typography variant="subtitle2" className={classes.tenderID}>
          {offer.tenderID}
        </Typography>
        <Typography variant="body2" className={classes.bidBefore}>
          {deadlineDate
            ? t("Bid before") + " " + deadlineDate
            : t("No deadline date")}
        </Typography>
        <Grid
          container
          direction="column"
          item
          xs
          className={classes.productList}
        >
          {offer.products.map((c, i) => {
            if (c === null) return null;

            return (
              <Grid container item xs key={i}>
                <Grid item xs style={{ padding: "0 5px 8px 0" }}>
                  {c.productName}
                </Grid>
                <Grid item>{parseNumber(c.quantity)} t</Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
      {deadlineToday ? (
        <Grid container item className={classes.expiringTextContainer}>
          <Grid item xs>
            <Typography variant="body2" className={classes.expiringText}>
              {t("Offer expiring today")}
            </Typography>
          </Grid>
          <AttentionIcon color="primary" />
        </Grid>
      ) : (
        <span className={classes.spacer} />
      )}
    </>
  );
};

const isToday = date => (date ? moment(date).isSame(moment(), "day") : false);

export default OfferListItem;
