/* eslint-disable indent */
import React, { useEffect, useState, useContext, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardContent,
  Collapse,
  makeStyles,
  Button,
  Hidden,
  IconButton
} from "@material-ui/core";
import useStandardLocalization from "../../../hooks/useStandardLocalization";
import AddIcon from "@material-ui/icons/Add";
import AttachFile from "@material-ui/icons/AttachFile";
import PropTypes from "prop-types";
import classnames from "classnames";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { useQuery } from "react-apollo";
import { ReactComponent as TruckIcon } from "../../../assets/truckIcon.svg";
import PickupsCardMenu from "./PickupsCardMenu";
import ImgSvg from "../../../assets/images/fillings.png";
import Status from "../../general/Status";
import useEventTracking from "../../../hooks/useEventTracking";
import PickupsCardDocument from "./PickupsCardDocument";
import UploadDialog from "../../core/shared/uploadDialog";
import UserContext from "../../../providers/UserProvider";
import GetPickupsData from "../../../queries/GetPickupsData";
import ModalPickupCardDisplay from "./ModalPickupCardDisplay";
import moment from "moment-business-days";
import StyledTooltip from "../../core/StyledTooltip";

// styles
const useStyles = makeStyles(theme => ({
  root: {},
  muiPaperRoot: {
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    position: "relative",
    "&:not(:last-of-type)": {
      marginBottom: "20px"
    }
  },
  MuiCardContentRoot: {
    position: "relative",
    padding: "20px"
  },
  newItem: {
    background: "#EAFEF3"
  },
  defaultBackground: {
    background: "#F9F9F9"
  },
  figureWrap: {
    position: "relative",
    marginBottom: "8px"
  },
  imgWrap: {
    margin: "0",
    // height: "100px",
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover"
      // maxWidth: 450
    }
  },
  pickUpBadge: {
    padding: "1px 4px 0 4px",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
    position: "absolute",
    top: "12px",
    left: "-20px"
  },
  tagLine: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "4px"
  },
  flexItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "4px"
  },
  pickUpDateFormatted: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px"
  },
  pickupdate_black: {
    color: "rgba(0, 0, 0, 1)"
  },
  pickupdate_grey: {
    color: "rgba(0, 0, 0, 0.38)"
  },
  tagLineHeaderWrap: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "16px"
  },
  tagLineHeader: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0 0 12px"
  },
  pickupBadgeWrap: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    paddingLeft: "4px"
  },
  indicatorBadge: {
    width: "8px",
    height: "8px",
    borderRadius: "100%"
  },
  card_orange: {
    backgroundColor: "#FFC043"
  },
  card_green: {
    backgroundColor: theme.palette.secondary.main
  },
  card_red: {
    backgroundColor: "#E11900"
  },
  pickupIndicator: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: 0
  },
  panels: {},
  panelTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0"
  },
  panelDescription: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0"
  },
  panelDescription__mb: {
    marginBottom: "8px"
  },
  mobileButtoContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px",
    marginRight: "10px"
  },
  ctaWrap: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "16px"
  },
  // grid
  row: {
    display: "flex",
    flexWrap: "wrap"
  },
  gridRoot: {
    margin: "0 -8px"
  },
  figureCol: {
    flexBasis: "100%",
    width: "100%",
    padding: "0 8px"
  },
  detailCol: {
    flexBasis: "100%",
    width: "100%",
    padding: "0 8px"
  },
  fullBasis: {
    flexBasis: "100%",
    width: "100%"
  },
  oneSixEightCent: {
    flexBasis: "100%",
    width: "100%"
  },
  tagLineHeadercol: {
    flexBasis: "100%",
    width: "100%"
  },
  panelCol: {
    flexBasis: "50%",
    width: "50%"
  },
  panelCol2: {
    flexBasis: "100%",
    width: "100%"
  },
  panelWrapCol: {
    flexBasis: "50%",
    width: "50%"
  },
  threeSixEightCent: {
    flexBasis: "100%",
    width: "100%"
  },
  detailGridRoot: {
    margin: "0 -10px"
  },
  detailColRoot: {
    padding: "0 10px"
  },
  chipNewItem: {
    backgroundColor: "#151124",
    color: "#52F597",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "10px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    padding: "7px 4px 6px",
    width: "35px",
    height: "17px",
    alignSelf: "flex-start"
  },
  documentLabel: {
    marginTop: "5px",
    marginLeft: "10px",
    background: "#E3E2E5",
    padding: "0px 4px",
    display: "flex",
    width: "fit-content",
    fontWeight: "600",
    fontSize: "9px",
    lineHeight: "16px",
    letterSpacing: "0.5px",
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 0.87)"
  },
  [theme.breakpoints.up("md")]: {
    figureWrap: {
      marginBottom: "0",
      maxHeight: "220px"
    },
    tagLineHeaderWrap: {
      flexDirection: "row",
      gap: "20px"
    },
    panelDescription__mb: {
      marginBottom: "16px"
    },
    tagLineHeader: {
      marginBottom: "0"
    },
    imgWrap: {
      // height: "100px",
      overflow: "hidden"
    },
    ctaWrap: {
      marginTop: "0",
      width: "100%"
    },
    figureCol: {
      flexBasis: "116px",
      width: "116px"
    },
    detailCol: {
      flexBasis: "calc(100% - 116px)",
      width: "calc(100% - 116px)",
      paddingTop: "12px"
    },
    oneSixEightCent: {
      flexBasis: "188px",
      width: "188px"
    },
    tagLineHeadercol: {
      flexBasis: "calc(100% - 188px)",
      width: "calc(100% - 188px)"
    },
    panelCol: {
      flexBasis: "188px",
      width: "188px"
    },
    panelCol2: {
      flexBasis: "188px",
      width: "188px"
    },
    panelWrapCol: {
      flexBasis: "calc(100% - 188px)",
      width: "calc(100% - 188px)"
    },
    threeSixEightCent: {
      flexBasis: "calc(100% - 564px)",
      width: "calc(100% - 564px)"
    },
    statusWrap: {
      marginTop: "12px"
    },
    statusTitle: {
      fontWeight: "400",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px",
      color: "rgba(0, 0, 0, 0.6)",
      margin: "0 0 4px"
    },
    statusClassName: {
      "& .status_root:first-child": {
        paddingLeft: "0"
      },
      "& .status_root:last-child": {
        paddingRight: "0"
      }
    },
    MuiCardContentRoot: {
      "&:last-child": {
        paddingBottom: 20
      }
    },
    muiPaperRootExtra: {
      paddingBottom: "10px"
    },
    muiPaperRootExtraExpanded: {
      paddingBottom: "0"
    },
    expander: {
      position: "absolute",
      bottom: "0",
      right: "0",
      padding: "0 10px 0 0"
    },
    expanderCollapsed: {
      bottom: "24px"
    },
    panelDescEllipsis: {
      height: "40px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      marginBottom: 0
    },
    chipNewItem: {
      alignSelf: "center"
    },
    documentLabel: {
      marginTop: "0px",
      marginLeft: "0px",
      background: "#E3E2E5",
      padding: "0px 4px",
      display: "flex",
      width: "100%",
      fontWeight: "600",
      fontSize: "9px",
      lineHeight: "16px",
      letterSpacing: "0.5px",
      textTransform: "uppercase",
      color: "rgba(0, 0, 0, 0.87)"
    }
  }
}));

const PickupsCard = ({
  item,
  expandedByDefault,
  isGroupAdmin,
  isDemoEnviroment = false
}) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { parseNumber, parseDate } = useStandardLocalization();
  const { user, selectedCompany } = useContext(UserContext);
  const { TrackEvent } = useEventTracking();
  const statusData = item.status || {};
  const pickUpDateFormatted = parseDate(
    item.pickupDate || item.pickupOrderDate
  );
  const isNewItem = Boolean(item.new);
  const theme = useTheme();
  // ismobile states for mobile device
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  // upload modal states
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const [isExpanded, setExpanded] = useState(expandedByDefault);
  const [imageHeight, setImageHeight] = useState(
    isMobile ? "100px" : isExpanded ? height : "112px"
  );

  const statusTexts = {
    1: `${t("Pickup")} - ${t(statusData.text)} ${
      statusData.period
        ? `[${statusData.t ? t(statusData.period) : statusData.period}]`
        : ""
    }`,
    2: `${t("Status_Transport")} - ${t("Pickups_Organisation")}`,
    3: `${t("Status_Transport")} - ${t("Documents")}`,
    4: `${t("Status_Transport")} - ${t("In transit")}`,
    5: `${t("Status_Transport")} - ${t("Sales_Delivered")}`
  };

  const statusElements = [
    {
      title: "1." + t("Pickup"),
      position: 1,
      text: `${t(statusData.text)} ${
        statusData.period
          ? `[${statusData.t ? t(statusData.period) : statusData.period}]`
          : ""
      }`
    },
    {
      title: "2." + t("Status_Transport"),
      position: 2,
      text: t("Pickups_Organisation")
    },
    { title: "3." + t("Status_Transport"), position: 3, text: t("Documents") },
    { title: "4." + t("Status_Transport"), position: 4, text: t("In transit") },
    {
      title: "5." + t("Status_Transport"),
      position: 5,
      text: t("Sales_Delivered")
    }
  ];
  const [modalIsVisible, setModalIsVisible] = useState(false);

  const { data, refetch } = useQuery(GetPickupsData, {
    variables: { token: user?.token, partnerId: selectedCompany }
  });

  const handleRefetch = () => {
    if (refetch) {
      refetch();
    }
  };

  const cardConditions = useMemo(() => {
    // item is the pickup object
    const { status, pickupOrderDate, statusId } = item;
    const { pickupStatusConditionId } = status;
    const condition_has_passed_collectionDate =
      pickupOrderDate &&
      moment().startOf("day").utcOffset(0).isAfter(pickupOrderDate) &&
      statusId === 1;

    if (condition_has_passed_collectionDate) {
      return {
        cardStates: t("overdue"),
        cardStatesColor: "card_red",
        iconDateColor: "pickupdate_black",
        showReschedulteBtn: false,
        showConfirmBtn: false,
        showDocumentBtn: false,
        canReschedulePickup: false,
        canConfirmPickUp: false
      };
    }

    switch (pickupStatusConditionId) {
      case 1:
        return {
          cardStates: "",
          cardStatesColor: null,
          iconDateColor: "pickupdate_grey",
          showReschedulteBtn: false,
          showConfirmBtn: true,
          showDocumentBtn: false,
          canReschedulePickup: true,
          canConfirmPickUp: true
        };
      case 2:
        return {
          cardStates: t("confirmation pending"),
          cardStatesColor: "card_orange",
          iconDateColor: "pickupdate_grey",
          showReschedulteBtn: true,
          showConfirmBtn: false,
          showDocumentBtn: false,
          canReschedulePickup: true,
          canConfirmPickUp: false
        };
      case 3:
        return {
          cardStates: t("rescheduling pending"),
          cardStatesColor: "card_orange",
          iconDateColor: "pickupdate_grey",
          showReschedulteBtn: false,
          showConfirmBtn: false,
          showDocumentBtn: true,
          canReschedulePickup: false,
          canConfirmPickUp: false
        };
      case 4:
        return {
          cardStates: t("confirmed"),
          cardStatesColor: "card_green",
          iconDateColor: "pickupdate_black",
          showReschedulteBtn: true,
          showConfirmBtn: false,
          showDocumentBtn: false,
          canReschedulePickup: true,
          canConfirmPickUp: false
        };
      case 5:
        return {
          // confirmed, confirmed by both sides, rescheduling not possible anymore
          cardStates: t("confirmed"),
          cardStatesColor: "card_green",
          iconDateColor: "pickupdate_black",
          showReschedulteBtn: false,
          showConfirmBtn: false,
          showDocumentBtn: true,
          canReschedulePickup: false,
          canConfirmPickUp: false
        };
      default:
        return {};
    }
  }, [item, t]);

  // always stay open for mobile
  useEffect(() => {
    if (isMobile) {
      setExpanded(true);
      setImageHeight("100px");
    }
  }, [isMobile]);

  // toggle card on click on the toggle button
  const onToggleCard = () => {
    if (isMobile) return;
    if (!isExpanded) trackOnExpanded();
    setExpanded(prev => !prev);
    setImageHeight(isExpanded ? "112px" : height);
  };

  const trackOnExpanded = () => {
    TrackEvent("Pickup expanded", {
      orderId: item.transactionId
    });
  };

  //The image will be as height as the card
  useEffect(() => {
    if (isMobile) return;
    if (isExpanded) {
      const currentHeight =
        ref.current && ref.current.clientHeight
          ? ref.current.clientHeight
          : 500;
      setHeight(currentHeight);
      setImageHeight(currentHeight);
    }
  }, [isMobile, isExpanded]);

  //if the status is reschedule pending, we only show it if the collection type is date
  const hidePickUpDate =
    (item?.status?.pickupStatusConditionId === 3 &&
      item?.collectionType !== "date") ||
    false;

  const disableButton = isGroupAdmin && item.accountId !== selectedCompany;

  const documentCount = item?.documents?.length || 0;

  return (
    <Card
      className={classnames(
        classes.muiPaperRoot,
        isNewItem ? classes.newItem : classes.defaultBackground,
        isExpanded
          ? classes.muiPaperRootExtraExpanded
          : classes.muiPaperRootExtra
      )}
      variant="outlined"
    >
      <Collapse in={isExpanded} collapsedSize={170}>
        <CardContent className={classnames(classes.MuiCardContentRoot)}>
          <div className={classnames(classes.row, classes.gridRoot)}>
            {/* pickup card image wrapper */}
            <div className={classes.figureCol}>
              <div className={classes.figureWrap}>
                <figure
                  className={classes.imgWrap}
                  style={{
                    height: imageHeight
                  }}
                >
                  <img
                    src={item.imageSrc || ImgSvg}
                    width="100%"
                    height="100%"
                    alt="metal"
                  />
                </figure>
              </div>
            </div>
            <div className={classes.detailCol} ref={ref}>
              <div className={classnames(classes.row, classes.detailGridRoot)}>
                <div
                  className={classnames(
                    classes.oneSixEightCent,
                    classes.detailColRoot
                  )}
                >
                  <div className={classes.tagLine}>
                    <div
                      className={classnames(
                        classes.flexItem,
                        classes.pickUpDateFormatted,
                        classes[cardConditions.iconDateColor]
                      )}
                    >
                      <TruckIcon />
                      {hidePickUpDate ? "" : pickUpDateFormatted}
                    </div>
                    <PickupsCardMenu
                      id={item._id}
                      canReschedulePickup={cardConditions.canReschedulePickup}
                      canConfirmPickup={cardConditions.canConfirmPickUp}
                      setUploadDialogOpen={setUploadDialogOpen}
                      isDemoEnviroment={isDemoEnviroment}
                    />
                  </div>
                </div>
                <div
                  className={classnames(
                    classes.tagLineHeadercol,
                    classes.detailColRoot
                  )}
                >
                  <div className={classes.tagLineHeaderWrap}>
                    <h3 className={classes.tagLineHeader}>
                      {item.aliasForLanguage || item.pickupName}{" "}
                    </h3>
                    {isNewItem && (
                      <div className={classes.chipNewItem}>{t("New")}</div>
                    )}
                    <div className={classes.pickupBadgeWrap}>
                      <span
                        className={classnames(
                          classes.indicatorBadge,
                          classes[cardConditions.cardStatesColor]
                        )}
                      ></span>
                      <p className={classes.pickupIndicator}>
                        {cardConditions.cardStates}
                      </p>
                    </div>
                    {documentCount > 0 && (
                      <div
                        className={classes.pickupBadgeWrap}
                        style={{ marginLeft: "-14px" }}
                      >
                        <div className={classes.documentLabel}>
                          <AttachFile
                            style={{
                              height: "12px",
                              width: "12px",
                              marginTop: "2px"
                            }}
                          />
                          {`${documentCount} ${t("Documents")}`}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className={classnames(
                    classes.panelCol,
                    classes.detailColRoot
                  )}
                >
                  <div className={classes.panels}>
                    <p className={classes.panelTitle}>{t("PICKUP ADDRESS")}</p>
                    <p
                      className={classnames(
                        classes.panelDescription,
                        isExpanded ? "" : classes.panelDescEllipsis
                      )}
                    >
                      {item.companyName}
                      <br /> {item.streetAddress}
                      <br /> {item.zipCode} {item.city}
                      <br /> {item.country}
                    </p>
                  </div>
                </div>
                <div
                  className={classnames(
                    classes.panelWrapCol,
                    classes.detailColRoot
                  )}
                >
                  <div className={classes.row}>
                    <div className={classes.fullBasis}>
                      <div
                        className={classnames(
                          classes.row,
                          classes.detailGridRoot
                        )}
                      >
                        <div
                          className={classnames(
                            classes.panelCol2,
                            classes.detailColRoot
                          )}
                        >
                          <div className={classes.panels}>
                            <p className={classes.panelTitle}>
                              {t("Transaction ID")}
                            </p>
                            <p className={classes.panelDescription}>
                              {item.transactionId}
                            </p>
                          </div>
                        </div>
                        <div
                          className={classnames(
                            classes.panelCol2,
                            classes.detailColRoot
                          )}
                        >
                          <div className={classes.panels}>
                            <p className={classes.panelTitle}>
                              {t("Declared Quantity (kg)")}
                            </p>
                            <p
                              className={classnames(
                                classes.panelDescription,
                                classes.panelDescription__mb
                              )}
                            >
                              {parseNumber(item?.quantity)}
                            </p>
                          </div>
                        </div>
                        <div
                          className={classnames(
                            classes.panelCol2,
                            classes.detailColRoot
                          )}
                        >
                          <div className={classes.panels}>
                            <p className={classes.panelTitle}>
                              {t("Pickups_TransportDetails")}
                            </p>
                            {(item.truckNumberPlate || item.truckType) && (
                              <p
                                className={classnames(
                                  classes.panelDescription,
                                  classes.panelDescription__mb,
                                  isExpanded ? "" : classes.panelDescEllipsis
                                )}
                              >
                                {item.truckNumberPlate}
                                <br />
                                {item.truckType}
                              </p>
                            )}
                          </div>
                        </div>
                        {/* display on desktop only */}
                        <Hidden smDown>
                          <div
                            className={classnames(
                              classes.threeSixEightCent,
                              classes.detailColRoot
                            )}
                          >
                            <div className={classes.ctaWrap}>
                              {!isExpanded &&
                                cardConditions.showDocumentBtn && (
                                  <div className={classes.ctaWrap}>
                                    <Button
                                      variant="outlined"
                                      color="primary"
                                      fullWidth
                                      startIcon={<AddIcon />}
                                      onClick={() => setUploadDialogOpen(true)}
                                    >
                                      {t("Documents")}
                                    </Button>
                                  </div>
                                )}

                              {cardConditions.showReschedulteBtn &&
                                (isDemoEnviroment ? (
                                  <StyledTooltip
                                    text={t("demo_disabledFeature")}
                                    placement={"top"}
                                  >
                                    <div
                                      style={{
                                        display: "inline-block"
                                      }}
                                    >
                                      <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        disabled={true}
                                      >
                                        {t("Pickups_ReschedulePickup")}
                                      </Button>
                                    </div>
                                  </StyledTooltip>
                                ) : (
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    onClick={() => {
                                      TrackEvent("Reschedule pickup started", {
                                        orderId: item._id,
                                        clickSource: "Button"
                                      });
                                      history.push(
                                        `/pickups?reschedule=${item._id}`,
                                        {
                                          background: location
                                        }
                                      );
                                    }}
                                    disabled={disableButton}
                                  >
                                    {t("Pickups_ReschedulePickup")}
                                  </Button>
                                ))}
                              {cardConditions.showConfirmBtn && (
                                <Button
                                  variant="contained"
                                  color="primary"
                                  fullWidth
                                  onClick={() => {
                                    TrackEvent("Pick up confirmation started", {
                                      clickSource: "button",
                                      orderId: item._id
                                    });
                                    history.push(
                                      `/pickups?confirm=${item._id}`,
                                      {
                                        background: location
                                      }
                                    );
                                  }}
                                  disabled={disableButton}
                                >
                                  {t("Pickups_ConfirmPickup")}
                                </Button>
                              )}
                            </div>
                          </div>
                        </Hidden>
                      </div>
                    </div>
                    {/* display on desktop only */}
                    <Hidden smDown>
                      <>
                        {isExpanded && (
                          <div className={classes.fullBasis}>
                            <div
                              className={classnames(
                                classes.row,
                                classes.detailGridRoot
                              )}
                            >
                              <div
                                className={classnames(
                                  classes.panelCol,
                                  classes.detailColRoot
                                )}
                              >
                                <div className={classes.panels}>
                                  <p className={classes.panelTitle}>
                                    {t("Products_Packaging")}
                                  </p>
                                  <p
                                    className={classnames(
                                      classes.panelDescription,
                                      classes.panelDescription__mb
                                    )}
                                  >
                                    {t(item.packaging) || "-"}
                                  </p>
                                </div>
                              </div>
                              {/* manage pick up documents */}
                              <PickupsCardDocument
                                item={item}
                                user={user}
                                selectedCompany={selectedCompany}
                                setUploadDialogOpen={() => {
                                  TrackEvent("Upload document flow started", {
                                    orderId: item._id,
                                    clickSource: "Button"
                                  });
                                  setUploadDialogOpen(true);
                                }}
                                handleRefetch={handleRefetch}
                              />
                            </div>
                          </div>
                        )}
                      </>
                    </Hidden>
                  </div>
                </div>
                {/* show on mobile only */}
                <Hidden mdUp>
                  <div
                    className={classnames(
                      classes.fullBasis,
                      classes.detailColRoot
                    )}
                  >
                    <div className={classes.ctaWrap}>
                      <Button
                        type="button"
                        onClick={() => setModalIsVisible(true)}
                      >
                        {t("View More")}
                      </Button>

                      {cardConditions.showDocumentBtn && (
                        <div>
                          <Button
                            variant="outlined"
                            color="primary"
                            fullWidth
                            startIcon={<AddIcon />}
                            onClick={() => setUploadDialogOpen(true)}
                          >
                            {t("Documents")}
                          </Button>
                        </div>
                      )}
                      {cardConditions.showConfirmBtn && (
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            TrackEvent("Pick up confirmation started", {
                              clickSource: "button",
                              orderId: item._id
                            });
                            history.push(`/pickups?confirm=${item._id}`, {
                              background: location
                            });
                          }}
                          disabled={disableButton}
                        >
                          {t("Pickups_ConfirmPickup")}
                        </Button>
                      )}
                      {cardConditions.showReschedulteBtn &&
                        (isDemoEnviroment ? (
                          <StyledTooltip
                            text={t("demo_disabledFeature")}
                            placement={"top"}
                          >
                            <div
                              style={{
                                display: "inline-block"
                              }}
                            >
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                disabled={true}
                              >
                                {t("Pickups_ReschedulePickup")}
                              </Button>
                            </div>
                          </StyledTooltip>
                        ) : (
                          <Button
                            type="button"
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              TrackEvent("Reschedule pickup started", {
                                orderId: item._id,
                                clickSource: "Button"
                              });
                              history.push(`/pickups?reschedule=${item._id}`, {
                                background: location
                              });
                            }}
                            disabled={disableButton}
                          >
                            {t("Pickups_ReschedulePickup")}
                          </Button>
                        ))}
                    </div>
                  </div>
                </Hidden>
              </div>
            </div>
            {/*  */}
          </div>
          {/* status here */}
          <Hidden smDown>
            <div className={classes.statusWrap}>
              <p className={classes.statusTitle}>
                {`${t("Pick up status")}${!isExpanded ? ":" : ""} `}
                {!isExpanded && (
                  <span style={{ fontWeight: "500", color: "#151124" }}>
                    {statusTexts[item?.statusId]}
                  </span>
                )}
              </p>
              <div className={classes.statuses}>
                <Status
                  className={classes.statusClassName}
                  currentIndex={item.statusId}
                  statusElements={statusElements}
                  collapsed={!isExpanded}
                />
              </div>
            </div>
          </Hidden>
        </CardContent>
      </Collapse>
      {/* expander */}
      <Hidden smDown>
        <div
          className={classnames(
            classes.expander,
            !isExpanded && classes.expanderCollapsed
          )}
        >
          <IconButton aria-label="toggle card" onClick={onToggleCard}>
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </Hidden>

      {/* upload documents */}
      {uploadDialogOpen ? (
        <UploadDialog
          orderId={item._id}
          orderType="purchase"
          onClose={() => {
            handleRefetch();
            setUploadDialogOpen(false);
          }}
          seller={true}
        />
      ) : (
        <></>
      )}
      {/* mobile  view of expanded pick up card */}
      <Hidden mdUp>
        <ModalPickupCardDisplay
          open={modalIsVisible}
          onClose={() => setModalIsVisible(false)}
          classes={classes}
          item={item}
          user={user}
          selectedCompany={selectedCompany}
          parentClasses={classes}
          parentCardConditions={cardConditions}
        />
      </Hidden>
    </Card>
  );
};

PickupsCard.propTypes = {
  item: PropTypes.object
};

PickupsCard.defaultProps = {
  item: {
    isContracted: false
  }
};

export default PickupsCard;
