import gql from "graphql-tag";

export default gql`
  query getOffersData($token: String!, $partnerId: String!) {
    OffersData(token: $token, partnerId: $partnerId) {
      openOffers
      submittedOffers
    }
    Categories {
      _id
      id
      name
      description
      parent
    }
  }
`;
