import { Box, makeStyles, createStyles } from "@material-ui/core";
import VerticalSliderStep from "./VerticalSliderStep";

const useStyles = makeStyles(theme =>
  createStyles({
    root: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      width: "20px"
    },
    progressContainer: {
      backgroundColor: "#95949C",
      height: "100%",
      position: "relative",
      width: "0.1rem"
    },
    progressBar: {
      backgroundColor: "#52F597",
      position: "absolute",
      top: 0,
      transition: "height 150ms",
      width: "0.1rem",
      zIndex: 1
    },
    stepsContainer: {
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-between",
      position: "absolute"
    },

    stepCircle: {
      height: "1rem",
      width: "1rem"
    },
    animateComplete: {
      animation: "$growShrink .3s ease"
    },
    "@keyframes growShrink": {
      "0%": {
        transform: "scale(1)"
      },
      "50%": {
        transform: "scale(1.2)"
      },
      "100%": {
        transform: "scale(1)"
      }
    },
    mainDiv: {
      margin: "20px"
    }
  })
);

const VerticalSlider = ({
  steps,
  actualStep,
  handleSetActualStep,
  overrideClasses = {}
}) => {
  const barHeight = steps.length * (overrideClasses.stepLength || 60);
  const barSpaces = steps.length - 1;
  const progressBarHeight = ((actualStep - 1) / barSpaces) * barHeight;

  const classes = useStyles();
  return (
    <Box
      flexDirection="column"
      className={overrideClasses.mainDiv || classes.mainDiv}
    >
      <Box
        className={classes.root}
        style={{ height: `${barHeight}px` }}
        role="progressbar"
      >
        {steps.length > 1 && (
          <Box className={classes.progressContainer}>
            <Box
              className={classes.progressBar}
              style={{
                height: Math.min(barHeight, progressBarHeight)
              }}
            />
          </Box>
        )}
        <Box className={classes.stepsContainer}>
          {steps.map((step, i) => (
            <VerticalSliderStep
              key={step.label}
              label={step.label}
              number={i + 1}
              actualPosition={actualStep}
              onClick={() => {
                //Allow only to go back using the slider
                if (i > actualStep - 1) return;
                handleSetActualStep(i + 1);
              }}
              overrideClasses={overrideClasses}
            />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default VerticalSlider;
