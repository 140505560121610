import { useEffect, useRef, useState } from "react";
import * as pdfjsLib from "pdfjs-dist/build/pdf";
import "pdfjs-dist/build/pdf.worker";
import {
  EventBus,
  PDFLinkService,
  PDFViewer,
  PDFHistory,
  GenericL10n
} from "pdfjs-dist/web/pdf_viewer";
import { makeStyles } from "@material-ui/core/styles";

const USE_ONLY_CSS_ZOOM = true;
const TEXT_LAYER_MODE = 0; // DISABLE
const MAX_IMAGE_SIZE = 1024 * 1024;
// const CMAP_URL = "pdfjs-dist/cmaps/";
const CMAP_PACKED = true;
const DEFAULT_SCALE_VALUE = "0.75";

const useStyles = makeStyles(theme => ({
  viewContainer: {
    position: "absolute",
    overflow: "auto",
    width: "100%",
    height: "100%",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0"
  },
  pdfViewer: {
    "--scale-factor": "1",
    "& .page": {
      direction: "ltr",
      width: "816px",
      height: "1056px",
      margin: "0 auto",
      position: "relative",
      overflow: "visible",
      backgroundClip: "content-box",
      backgroundColor: "rgba(255, 255, 255, 1)",
      "&:not(:last-child)": {
        borderBottom: "9px solid transparent"
      },
      "&:last-child": {
        marginBottom: "64px"
      },
      "& canvas": {
        margin: "0",
        display: "block",
        width: "100%"
      }
    },
    "& .canvasWrapper": {
      overflow: "hidden",
      width: "100%",
      height: "100%",
      zIndex: "1"
    },
    "& .textLayer": {
      position: "absolute",
      textAlign: "initial",
      left: "0",
      top: "0",
      right: "0",
      bottom: "0",
      overflow: "hidden",
      opacity: "0.25",
      lineHeight: "1",
      textSizeAdjust: "none",
      forcedColorAdjust: "none",
      transformOrigin: "0 0",
      zIndex: "2",
      "& span, & br": {
        color: "transparent",
        position: "absolute",
        whiteSpace: "pre",
        cursor: "text",
        transformOrigin: "0% 0%"
      }
    },
    "& .annotationLayer": {
      position: "absolute",
      top: "0",
      left: "0",
      pointerEvents: "none",
      transformOrigin: "0 0",
      zIndex: "3"
    },
    "& .annotationEditorLayer": {
      background: "transparent",
      position: "absolute",
      top: "0",
      left: "0",
      fontSize: "calc(100px * var(--scale-factor))",
      transformOrigin: "0 0",
      cursor: "auto",
      zIndex: "4"
    }
  },
  [theme.breakpoints.down("xs")]: {
    pdfViewer: {
      "& .page": {
        "&:last-child": {
          marginBottom: "0"
        }
      }
    }
  }
}));

const usePDFViewer = props => {
  pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker",
    import.meta.url
  ).toString();
  const canvasRef = useRef(null);

  const pdfLoadingTask = useRef(null);
  const pdfDocument = useRef(null);
  const pdfViewer = useRef(null);
  const pdfHistory = useRef(null);
  const pdfLinkService = useRef(null);
  const eventBus = useRef(null);
  const l10n = useRef(null);
  const url = useRef(null);

  const [pageNum, setPageNum] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [progress, setProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const [docTitle, setDocTitle] = useState("");
  const [errorMessage, setErrorMsg] = useState("");

  const pdfCssStyles = useStyles();

  const open = async params => {
    if (pdfLoadingTask.current) {
      // We need to destroy already opened document
      await close();
      return await open(params);
    }

    const url = params.url;
    setTitleUsingUrl(url);

    // Loading document.
    setLoading(true);
    const loadingTask = pdfjsLib.getDocument({
      url,
      //maxImageSize: MAX_IMAGE_SIZE, Commented due to GSD-2974: Some documents are not loading
      // cMapUrl: CMAP_URL,
      cMapPacked: CMAP_PACKED
    });
    pdfLoadingTask.current = loadingTask;

    loadingTask.onProgress = function (progressData) {
      onProgress(progressData.loaded / progressData.total);
    };

    return loadingTask.promise.then(
      function (pdfDoc) {
        // Document loaded, specifying document for the viewer.
        pdfDocument.current = pdfDoc;
        pdfViewer.current?.setDocument(pdfDoc);
        pdfLinkService.current?.setDocument(pdfDoc);
        pdfHistory.current?.initialize({
          fingerprint: pdfDoc.fingerprints[0]
        });

        // loadingBar.hide(); // todo use memo
        setTitleUsingMetadata(pdfDoc);
        setTotalPages(pdfDoc.numPages);
        setLoading(false);
      },
      function (exception) {
        let errorMsg;

        if (exception instanceof pdfjsLib.InvalidPDFException) {
          errorMsg = "Invalid or corrupted PDF file.";
        } else if (exception instanceof pdfjsLib.MissingPDFException) {
          errorMsg = "Missing PDF file.";
        } else if (exception instanceof pdfjsLib.UnexpectedResponseException) {
          errorMsg = "Unexpected server response..";
        } else {
          errorMsg = "An error occurred while loading the PDF.";
        }
        setErrorMsg(errorMsg);
        setLoading(false);
      }
    );
  };

  const close = () => {
    // const errorWrapper = document.getElementById("errorWrapper");
    // errorWrapper.hidden = true;

    if (!pdfLoadingTask.current) {
      return Promise.resolve();
    }

    const promise = pdfLoadingTask.current.destroy();
    pdfLoadingTask.current = null;

    if (pdfDocument.current) {
      pdfDocument.current = null;

      pdfViewer.current?.setDocument(null);
      pdfLinkService.current?.setDocument(null, null);

      if (pdfHistory.current) {
        pdfHistory.current.reset();
      }
    }

    return promise;
  };

  const setTitleUsingUrl = urlLink => {
    url.current = urlLink; //todo set ref
    let title = pdfjsLib.getFilenameFromUrl(urlLink) || urlLink;
    try {
      title = decodeURIComponent(title);
    } catch (e) {
      // decodeURIComponent may throw URIError,
      // fall back to using the unprocessed url in that case
    }
    setTitle(title);
  };

  const setTitleUsingMetadata = pdfDoc => {
    pdfDoc.getMetadata().then(function (data) {
      const info = data.info,
        metadata = data.metadata;

      // Provides some basic debug information
      console.log(
        "PDF " +
          pdfDoc.fingerprints[0] +
          " [" +
          info.PDFFormatVersion +
          " " +
          (info.Producer || "-").trim() +
          " / " +
          (info.Creator || "-").trim() +
          "]" +
          " (PDF.js: " +
          (pdfjsLib.version || "-") +
          ")"
      );

      let pdfTitle;
      if (metadata && metadata.has("dc:title")) {
        const title = metadata.get("dc:title");
        // Ghostscript sometimes returns 'Untitled', so prevent setting the
        // title to 'Untitled.
        if (title !== "Untitled") {
          pdfTitle = title;
        }
      }

      if (!pdfTitle && info && info.Title) {
        pdfTitle = info.Title;
      }

      if (pdfTitle) {
        setTitle(pdfTitle + " - " + document.title);
      }
    });
  };

  const setTitle = title => {
    setDocTitle(title);
  };

  const onProgress = level => {
    const percent = Math.round(level * 100);
    // Updating the bar if value increases.
    setProgress(percent);
  };

  const zoomIn = ticks => {
    if (pdfViewer.current.isInPresentationMode) {
      return;
    }
    pdfViewer.current.increaseScale({
      drawingDelay: 400, // 400
      steps: undefined,
      scaleFactor: undefined
    });
  };

  const zoomOut = ticks => {
    if (pdfViewer.current.isInPresentationMode) {
      return;
    }
    pdfViewer.current.decreaseScale({
      drawingDelay: 400,
      steps: undefined,
      scaleFactor: undefined
    });
  };

  const onZoomIn = () => {
    zoomIn();
  };

  const onZoomOut = () => {
    zoomOut();
  };

  const resetZoom = () => {
    if (pdfViewer.current.isInPresentationMode) {
      return;
    }
    pdfViewer.current.currentScaleValue = DEFAULT_SCALE_VALUE;
  };
  const onDownLoad = () => {};

  const onSendEmail = () => {};

  const initUI = () => {
    const _eventBus = new EventBus();
    eventBus.current = _eventBus;

    const linkService = new PDFLinkService({
      eventBus: _eventBus
    });
    pdfLinkService.current = linkService;

    l10n.current = new GenericL10n(); // todo

    const pdfVwr = new PDFViewer({
      container: canvasRef?.current,
      viewer: canvasRef.current?.firstElementChild,
      eventBus: _eventBus,
      linkService,
      l10n: l10n.current,
      useOnlyCssZoom: USE_ONLY_CSS_ZOOM,
      textLayerMode: TEXT_LAYER_MODE
    });
    pdfViewer.current = pdfVwr;
    linkService.setViewer(pdfVwr);

    pdfHistory.current = new PDFHistory({
      eventBus: _eventBus,
      linkService
    });
    linkService.setHistory(pdfHistory.current);

    setPageNum(pdfViewer.current.currentPageNumber);

    _eventBus.on("pagesinit", function () {
      // We can use pdfViewer now, e.g. let's change default scale.
      pdfViewer.current.currentScaleValue = DEFAULT_SCALE_VALUE;
    });

    _eventBus.on(
      "pagechanging",
      function (evt) {
        const page = evt.pageNumber;
        setPageNum(page);
      },
      true
    );
  };

  useEffect(() => {
    if (props.visible && props.fileType === "application/pdf") {
      // document.addEventListener("DOMContentLoaded", initUI, true);
      initUI();
      // The offsetParent is not set until the PDF.js iframe or object is visible;
      // waiting for first animation.
      const animationStarted = new Promise(resolve =>
        window.requestAnimationFrame(resolve)
      );

      // We need to delay opening until all HTML is loaded.
      animationStarted.then(function () {
        open({
          url: props.docUrl
        });
      });
    } else {
      setTitle("");
      setPageNum(1);
      setTotalPages(0);
      setLoading(false);
      setErrorMsg("");
    }
  }, [props.visible, props.docUrl]);

  return {
    canvasRef,
    currentPage: pageNum,
    totalPages,
    progress,
    docTitle,
    errorMessage,
    pdfCssStyles,
    loading,
    onZoomIn,
    onZoomOut,
    resetZoom,
    onDownLoad,
    onSendEmail
  };
};

export default usePDFViewer;
