import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import FileItem from "./FileItem";

const useStyles = makeStyles(theme => ({
  wrap: {
    width: "100%"
  },
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0 0 4px"
  },
  [theme.breakpoints.up("md")]: {
    title: {
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px"
    }
  }
}));

const FileList = ({
  token,
  headingTitle,
  files,
  orderId,
  showDeleteIcon,
  refetch,
  disabled = false
}) => {
  const classes = useStyles();
  if (!files) {
    return null;
  }

  const extraStyle = disabled
    ? {
        pointerEvents: "none",
        userSelect: "none",
        opacity: "0.5"
      }
    : {};

  return (
    <div className={classes.wrap}>
      {headingTitle && <div className={classes.title}>{headingTitle}</div>}
      <div className={classes.items} style={extraStyle}>
        {files.map(file => (
          <FileItem
            key={file.id}
            file={file}
            fileId={file.id}
            token={token}
            orderId={orderId}
            showDeleteIcon={showDeleteIcon}
            refetch={refetch}
          />
        ))}
      </div>
    </div>
  );
};

FileList.propTypes = {
  headingTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  files: PropTypes.array.isRequired,
  orderId: PropTypes.string,
  showDeleteIcon: PropTypes.bool,
  refetch: PropTypes.func
};

FileList.defaultProps = {
  files: [],
  showDeleteIcon: false
};

export default FileList;
