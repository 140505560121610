import React, { useState, useEffect } from "react";
import { makeStyles, Grid, createStyles } from "@material-ui/core";
import PriceListHeader from "../../components/priceList/PriceListHeader";
import PriceListSearchBar from "../../components/priceList/PriceListSearchBar";
import PriceListTable from "../../components/priceList/PriceListTable";
import moment from "moment";
import useEventTracking from "../../hooks/useEventTracking";
import _ from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

const priceListPageStyle = makeStyles(
  createStyles(theme => ({
    mainContainer: {
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "48px"
      }
    }
  }))
);

const getFilters = location => {
  const searchParams = new URLSearchParams(location.search);
  //Categories need to be refactored to be an array of objects
  const categoryArray = searchParams.get("categories")?.split(",") || [];
  let newCategoryArray = [];
  if (categoryArray.length) {
    categoryArray.forEach((category, _) => {
      if (category !== "")
        newCategoryArray.push({ value: category, label: category });
    });
  }
  const productsArray = searchParams.get("products")?.split(",") || [];
  let newProducsArray = [];
  if (productsArray.length) {
    productsArray.forEach((product, _) => {
      if (product !== "")
        newProducsArray.push({ value: product, label: product });
    });
  }
  return {
    categories: newCategoryArray,
    products: newProducsArray,
    date: searchParams.get("date")
      ? moment(searchParams.get("date"), "YYYY-MM-DD")
      : moment().toDate()
  };
};

const trackPriceHistoryDebounced = _.debounce(obj => {
  const { filters, TrackEvent } = obj;
  const { categories, products, date } = filters;
  TrackEvent("Price history visited", {
    filteredProduct: products,
    category: categories,
    date
  });
}, 750);

const PriceListPage = ({ searchData }) => {
  const classes = priceListPageStyle();
  const [downloadableData, setDownloadableData] = useState(null);
  const location = useLocation();
  const { TrackEvent } = useEventTracking();
  const history = useHistory();
  const filters = getFilters(location);
  const setDownloadableDataMiddleman = value => {
    //Avoid rerender, only render when changes (when change = set state to null for filling again)
    if (!downloadableData) setDownloadableData(value);
  };

  const updateFilter = (type, value) => {
    let query = queryString.parse(location.search);
    const searchParams = new URLSearchParams(query);

    if (type === "date") {
      if (value) {
        const stringed_date = moment(value).format("YYYY-MM-DD");
        searchParams.set(type, stringed_date);
      } else {
        searchParams.delete(type);
      }
    } else {
      //We check if it is the first time we are setting the filter
      if (value.length) {
        let codes = "";
        for (let i = 0; i < value.length; i++) {
          codes += value[i].value + ",";
        }
        searchParams.set(type, codes);
      } else {
        //No value, we delete the filter
        searchParams.delete(type);
      }
    }
    setDownloadableData(null);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  const resetFilters = () => {
    let query = queryString.parse(location.search);
    const searchParams = new URLSearchParams(query);
    searchParams.delete("date");
    searchParams.delete("products");
    searchParams.delete("categories");
    // also reset query params
    history.replace(location.pathname);
    history.push({
      pathname: location.pathname,
      search: searchParams.toString()
    });
  };

  useEffect(
    () => trackPriceHistoryDebounced({ filters, TrackEvent }),
    [filters]
  );

  const { Categories, PriceProducts } = searchData;
  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.mainContainer}
    >
      <PriceListHeader
        priceData={downloadableData}
        filters={filters}
        productList={PriceProducts}
        updateFilter={updateFilter}
      />

      <PriceListSearchBar
        categoryList={Categories}
        productList={PriceProducts}
        filters={filters}
        resetFilters={resetFilters}
        updateData={setDownloadableDataMiddleman}
        updateFilter={updateFilter}
      />

      <PriceListTable
        filters={filters}
        productList={PriceProducts}
        updateData={setDownloadableDataMiddleman}
      />
    </Grid>
  );
};

export default PriceListPage;
