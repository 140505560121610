import { makeStyles, Button } from "@material-ui/core";
import { useState, useContext } from "react";
import { useQuery } from "react-apollo";

import { useTranslation } from "react-i18next";

import Loader from "../../Loader";
import PairingTool from "../components/PairingTool";
import { Locations_table_headers } from "../utils/constants";
import UserContext from "../../../../../providers/UserProvider";
import GetChildCompanys from "../../../../../queries/GetChildCompanys";
import FuzzySet from "fuzzyset";

const useStyles = makeStyles(theme => ({
  root: {
    width: "1000px",
    padding: "8px 24px",
    "& > .MuiDialogContentText-root": {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "20px"
      },
      paddingBottom: "20px"
    },
    "& > .MuiDialogActions-root": {
      paddingBottom: "20px"
    }
  },
  buttonClass: {
    marginTop: "16px",
    marginRight: "96px",
    float: "right"
  }
}));

export default ({ setNextStepAction, fileContent, setFileContent, title }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [locationsChecked, setLocationsChecked] = useState(false);
  const [locations, setLocations] = useState({});
  const [transformedChildCompanies, setTransformedChildCompanies] =
    useState(null);
  const [objectPaired, setObjectPaired] = useState({});
  const [everyLocationPaired, setEveryLocationPaired] = useState(false);
  const {
    selectedCompany,
    user: { token }
  } = useContext(UserContext);

  if (fileContent && !locationsChecked) {
    //We get the unique site_name from fileContent
    const uniqueLocationsArr = [
      ...new Set(fileContent.map(item => item.site_name))
    ];
    setLocationsChecked(true);
    const uniqueLocations = uniqueLocationsArr.reduce((obj, site) => {
      obj[site] = null;
      return obj;
    }, {});

    setLocations(uniqueLocations);
  }

  const canContinue = () => {
    if (Object.keys(locations).length !== Object.keys(objectPaired).length)
      return false;
    for (let location in locations) {
      if (
        !(objectPaired[location] && objectPaired[location] !== "null") ||
        (objectPaired[location] &&
          objectPaired[location].suggestion &&
          !objectPaired[location].suggestionAccepted)
      )
        return false;
    }
    return true;
  };

  const continueNextStep = () => {
    const newFileContent = fileContent.map(item => {
      if (
        objectPaired[item.site_name]?.suggestion &&
        objectPaired[item.site_name]?.suggestionAccepted
      )
        item.site_name = objectPaired[item.site_name].suggestion;
      return {
        ...item,
        ...(objectPaired[item.site_name]
          ? { metaloop_account_id: objectPaired[item.site_name] }
          : {})
      };
    });
    setFileContent(newFileContent);
    setNextStepAction();
  };

  const { data, loading } = useQuery(GetChildCompanys, {
    variables: {
      token: token,
      companyId: selectedCompany
    },
    fetchPolicy: "network-only"
  });

  if (
    !everyLocationPaired &&
    !loading &&
    data &&
    !transformedChildCompanies &&
    Object.keys(locations).length !== 0
  ) {
    const childCompanies = data.GetChildCompanys;
    const transformedChildCompanies = childCompanies
      .filter(company => !company.internalName)
      .map(company => ({
        value: company._id,
        name: company.name
      }));
    //We pair the locations with the names we have already stored in the database
    const newLoc = { ...locations };
    childCompanies.map(company => {
      if (
        company.internalName &&
        locations.hasOwnProperty(company.internalName)
      ) {
        newLoc[company.internalName] = company._id;
      }
    });

    //We create an array of the already stored accounts for the furrry set
    const alreadyStoredAccounts = childCompanies
      .filter(company => company.internalName)
      .map(company => company.internalName);
    //We create the fuzzy set
    const fuzzyNames = FuzzySet(alreadyStoredAccounts);
    //We iterate over the locations and try to find a match
    Object.keys(newLoc).map(location => {
      if (newLoc[location] !== null) return;
      const matches = fuzzyNames.get(location, null, 0.5);
      if (matches && matches[0] && matches[0][1]) {
        const suggestedName = matches[0][1];
        newLoc[location] = { suggestion: suggestedName };
      }
    });

    setLocations(newLoc);
    //We set the objects with the location preset as paired:
    const objectPaired_ = Object.fromEntries(
      Object.entries(newLoc).filter(([_, value]) => value !== null)
    );
    setObjectPaired(objectPaired_);
    setTransformedChildCompanies(transformedChildCompanies);

    //If everything is paired, we continue to the next step
    if (
      Object.values(newLoc).every(
        value => value !== null && typeof value === "string"
      )
    ) {
      const newFileContent = fileContent.map(item => ({
        ...item,
        ...(objectPaired_[item.site_name]
          ? { metaloop_account_id: objectPaired_[item.site_name] }
          : {})
      }));
      setFileContent(newFileContent);
      setEveryLocationPaired(true);
      setNextStepAction();
    }
  }

  return (
    <>
      {title}
      <div className={classes.root}>
        {locationsChecked && !loading ? (
          <PairingTool
            columnHeaders={Locations_table_headers}
            rows={locations}
            options={transformedChildCompanies || []}
            objectPaired={objectPaired}
            setObjectPaired={setObjectPaired}
            type={"location"}
          />
        ) : (
          <Loader />
        )}
        <Button
          color="secondary"
          classes={{ root: classes.buttonRoot }}
          variant="contained"
          onClick={() => {
            continueNextStep();
          }}
          disabled={!canContinue()}
          className={classes.buttonClass}
        >
          {t("import_dialog_continue")}
        </Button>
      </div>
    </>
  );
};
