import React from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import GetAppIcon from "@material-ui/icons/GetApp";
import GLOBAL_VARIABLES from "../../../../config/config";

const PickUpDownloadAllButton = ({
  sellerId,
  token,
  orderId,
  fullWidth,
  disableButtons = false
}) => {
  const downloadUrl = `${GLOBAL_VARIABLES.API_BASE_URL}api/v1/pickups/download/${sellerId}/${orderId}?token=${token}`;
  const { t } = useTranslation();

  return (
    <Button
      variant="outlined"
      color="primary"
      href={downloadUrl}
      fullWidth={fullWidth}
      startIcon={<GetAppIcon />}
      download
      disabled={disableButtons}
    >
      {t("Download all")}
    </Button>
  );
};

PickUpDownloadAllButton.propTypes = {
  sellerId: PropTypes.string,
  token: PropTypes.string,
  orderId: PropTypes.string,
  fullWidth: PropTypes.bool
};

PickUpDownloadAllButton.defaultProps = {
  fullWidth: true
};
export default PickUpDownloadAllButton;
