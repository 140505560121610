export const columns_contracts = [
  "contract_id",
  "supplier_name",
  "site_name",
  "material_name",
  "metaloop_material_id",
  "material_rate",
  "total_quantity"
];

//We create statically the column headers for the contract properties
export const columnHeaders_contracts = [
  {
    name: "col_head_cont_Ir",
    style: { minWidth: "50px" },
    id: "contract_id"
  },
  {
    name: "col_head_supp_name",
    style: { minWidth: "250px" },
    id: "supplier_name"
  },
  {
    name: "col_head_site_name",
    style: { minWidth: "150px" },
    id: "site_name"
  },
  {
    name: "col_head_mat_name",
    style: { minWidth: "300px" },
    id: "material_name"
  },
  {
    name: "col_head_met_mat_id",
    style: { minWidth: "50px" },
    id: "metaloop_material_id"
  },
  {
    name: "col_head_mat_rate",
    style: { minWidth: "100px" },
    id: "material_rate"
  },
  {
    name: "col_head_total_quan",
    style: { minWidth: "100px" },
    id: "total_quantity"
  }
];

export const columns_transactions = [
  "order_id",
  "date",
  "supplier_name",
  "site_name",
  "country_code",
  "plant_name",
  "material_name",
  "metaloop_material_id",
  "tonnage",
  "cost_per_ton_local",
  "total_cost_local",
  "currency",
  "cost_per_ton_eur",
  "total_cost_eur",
  "contract_id"
];

export const columnHeaders_transactions = [
  {
    name: "col_head_order_id",
    style: { minWidth: "65px" },
    id: "order_id"
  },
  {
    name: "col_head_date",
    style: { minWidth: "50px" },
    id: "date"
  },
  {
    name: "col_head_supp_name",
    style: { minWidth: "250px" },
    id: "supplier_name"
  },
  {
    name: "col_head_site_name",
    style: { minWidth: "150px" },
    id: "site_name"
  },
  {
    name: "col_head_count_code",
    style: { minWidth: "50px" },
    id: "country_code"
  },
  {
    name: "col_head_plant_name",
    style: { minWidth: "150px" },
    id: "plant_name"
  },
  {
    name: "col_head_mat_name",
    style: { minWidth: "300px" },
    id: "material_name"
  },
  {
    name: "col_head_met_mat_id",
    style: { minWidth: "50px" },
    id: "metaloop_material_id"
  },
  {
    name: "col_head_tonnage",
    style: { minWidth: "100px" },
    id: "tonnage"
  },
  {
    name: "col_head_cost_per_ton_local",
    style: { minWidth: "100px" },
    id: "cost_per_ton_local"
  },
  {
    name: "col_head_total_cost_local",
    style: { minWidth: "100px" },
    id: "total_cost_local"
  },
  {
    name: "col_head_currency",
    style: { minWidth: "50px" },
    id: "currency"
  },
  {
    name: "col_head_cost_per_ton_eur",
    style: { minWidth: "100px" },
    id: "cost_per_ton_eur"
  },
  {
    name: "col_head_total_cost_eur",
    style: { minWidth: "100px" },
    id: "total_cost_eur"
  },
  {
    name: "col_head_cont_Ir",
    style: { minWidth: "50px" },
    id: "contract_id"
  }
];

export const IMPORT_DATA_CONTRACT_TYPE = "contract";
export const IMPORT_DATA_TRANSACTION_TYPE = "transaction";

export const Locations_table_headers = [
  { name: "col_head_plant_name", style: { minWidth: "200px", width: "50%" } },
  {
    name: "col_head_metaloop_account",
    style: { minWidth: "200px", width: "50%" }
  }
];

export const Materials_table_headers = [
  { name: "col_head_mat_name", style: { minWidth: "200px", width: "50%" } },
  {
    name: "col_head_metaloop_material",
    style: { minWidth: "200px", width: "50%" }
  }
];

export const contracts_table_headers = [
  { name: "col_head_order_id", style: { minWidth: "100px", width: "13%" } },
  { name: "col_head_contract", style: { minWidth: "300px", width: "87%" } }
];

export const ACCEPTED_CURRENCY = ["EUR", "USD", "GBP"];
