import React from "react";
import { useState } from "react";

import {
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Hidden,
  IconButton,
  Select
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import CityAndCountryInputs from "../../general/GoogleAutocompleteApi/CityAndCountryInputs";
import { useValidation } from "../../../hooks/useValidation";
import { SelectMenuProps } from "../../core/shared/constants";

const useStyles = makeStyles(theme => ({
  googleLocationContainer: {
    display: "flex",
    flexDirection: "column"
  },
  buttonContainer: {
    maxWidth: "400px",
    display: "flex",
    justifyContent: "end"
  },
  inputText: {
    marginBottom: "20px",
    maxWidth: "400px"
  },
  smallInputText: {
    marginBottom: "20px",
    maxWidth: "190px",
    "@media (max-width: 960px)": {
      maxWidth: "400px"
    }
  },
  textArea: {
    marginBottom: "20px",
    maxWidth: "400px",
    height: "104px",
    alignItems: "baseline"
  },
  formWrapper: {
    "@media (max-width: 960px)": {
      maxWidth: "400px"
    }
  },
  formContainer: {
    paddingLeft: "32px",
    "@media (max-width: 960px)": {
      alignItems: "center",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      minWidth: "100%",
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
      position: "absolute",
      maxHeight: "100%",
      overflowY: "auto",
      zIndex: 100,
      background: "#FFFFFF",
      padding: "0 16px 30px"
    }
  },
  closeButton: {
    marginRight: "20px",
    "@media (max-width: 960px)": {
      marginRight: "3px",
      color: "rgba(0, 0, 0, 0.87)"
    }
  },
  sectionTitle: {
    display: "flex",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: "32px",
    marginBottom: "20px",
    "@media (max-width: 960px)": {
      marginBottom: "40px",
      display: "flex",
      alignItems: "center"
    },
    textTransform: "capitalize"
  },
  cancelButton: {
    marginRight: "16px"
  }
}));

const TransportForm = ({ data, type, contacts, cancelChange, saveForm }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const defaultContact = contacts.find(c => c.default);

  const [form, setForm] = useState({
    _id: data._id !== undefined ? data._id : null,
    companyName: data.companyName !== undefined ? data.companyName : null,
    streetAddress: data.streetAddress !== undefined ? data.streetAddress : null,
    zipCode: data.zipCode !== undefined ? data.zipCode : null,
    city: data.city !== undefined ? data.city : null,
    country: data.country !== undefined ? data.country : null,
    contactId: data.contactId ? data.contactId : defaultContact._id,
    transportNotes:
      data.transportNotes !== undefined ? data.transportNotes : null,
    type: data.type
  });

  const changeForm = (field, newData) => {
    let cleanError = { ...errors };
    delete cleanError[field];
    setErrors(cleanError);
    let editedForm = { ...form };

    if (field === "city") {
      editedForm[field] = newData[0];
      editedForm["country"] = newData[1];
    } else {
      editedForm[field] = newData;
    }

    setForm(editedForm);
  };

  const { errors, checkValidation, setErrors } = useValidation({
    _id: false,
    transportNotes: false
  });

  const submitForm = form => {
    const errors = checkValidation(form);

    if (errors) return;

    saveForm({
      ...form
    });
  };

  let address_text = "";
  switch (true) {
    case type === "Edit" && data.type === "pickup":
      address_text = "Edit_pickup_address";
      break;
    case type === "Edit" && data.type === "delivery":
      address_text = "Edit_delivery_address";
      break;

    default:
      address_text = `${type} ${
        data.type.charAt(0).toUpperCase() + data.type.slice(1)
      } Address`;
      break;
  }

  return (
    <div className={classes.formContainer}>
      <div className={classes.formWrapper}>
        <span className={classes.sectionTitle}>
          <Hidden mdUp>
            <IconButton
              onClick={event => cancelChange()}
              aria-controls="menu"
              className={classes.closeButton}
            >
              <Close size="small" />
            </IconButton>
          </Hidden>
          {t(address_text)}
        </span>

        <FormControl fullWidth>
          <TextField
            id="companyName"
            label={t("Company name")}
            variant="outlined"
            className={classes.inputText}
            defaultValue={form.companyName}
            onChange={event => {
              changeForm("companyName", event.target.value);
            }}
            error={errors?.companyName}
            helperText={errors?.companyName && t("Required")}
          />
          <TextField
            id="streetAddress"
            label={t("Street")}
            className={classes.inputText}
            variant="outlined"
            defaultValue={form.streetAddress}
            onChange={event => {
              changeForm("streetAddress", event.target.value);
            }}
            error={errors?.streetAddress}
            helperText={errors?.streetAddress && t("Required")}
          />
          <TextField
            id="zipCode"
            label={t("Zip Code")}
            className={classes.smallInputText}
            variant="outlined"
            defaultValue={form.zipCode}
            onChange={event => {
              changeForm("zipCode", event.target.value);
            }}
            error={errors?.zipCode}
            helperText={errors?.zipCode && t("Required")}
          />
          <CityAndCountryInputs
            changeForm={changeForm}
            classes={classes}
            form={form}
            errors={errors}
          />
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <InputLabel id="contact-dropdown">{t("Contact Name")}</InputLabel>
          <Select
            className={classes.inputText}
            labelId="contact-dropdown"
            id="contact-select"
            label={t("Contact Name")}
            defaultValue={form.contactId || defaultContact._id}
            onChange={event => {
              changeForm("contactId", event.target.value);
            }}
            error={errors?.contactId}
            helperText={errors?.contactId && t("Required")}
            MenuProps={SelectMenuProps}
          >
            {contacts.map(item => (
              <MenuItem key={item._id} value={item._id}>
                {item.name +
                  (item._id === defaultContact._id ? " (Main Contact)" : "")}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl variant="outlined" fullWidth>
          <TextField
            multiline
            maxRows={4}
            variant="outlined"
            label={t("Additional Comments")}
            InputProps={{ className: classes.textArea }}
            fullWidth
            defaultValue={form.transportNotes}
            onChange={event => {
              changeForm("transportNotes", event.target.value);
            }}
          />
        </FormControl>
        <div className={classes.buttonContainer}>
          <Button
            variant="outlined"
            color="primary"
            className={classes.cancelButton}
            onClick={() => cancelChange()}
          >
            {t("Cancel")}
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => submitForm(form)}
          >
            {t("Save")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default TransportForm;
