import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Typography } from "@material-ui/core";
import ItemImage from "../../../card/ItemImage";
import Switch from "@material-ui/core/Switch";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

const useStyles = makeStyles(theme => ({
  categoryCard: {
    background: "#F9F9F9",
    width: "400px",
    marginRight: "20px",
    marginTop: "12px",
    marginBottom: "8px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    transition: "0.5s",

    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginRight: "0px"
    }
  },
  categoryCardAccepted: {
    cursor: "pointer",
    "&:hover": {
      background: "#E9EDEE"
    }
  },
  categoryTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  active: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    marginTop: "8px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "4px"
    }
  },
  acceptContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center"
  },
  accepted: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    letterSpacing: "0.001em",
    color: "rgba(0, 0, 0, 0.6)",
    marginTop: "12px",
    marginBottom: "16px"
  },
  openButton: {
    width: "56px",
    background: "transparent",
    border: "none",
    marginRight: "4px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    fontFeatureSettings: "'liga' off",
    letterSpacing: "1px",

    color: "#151124",
    textTransform: "uppercase",

    cursor: "pointer"
  },
  controlsContainer: {
    padding: "0 20px",
    display: "flex",
    width: "100%"
  },
  controlsWrapper: {
    display: "flex",
    borderTop: "1px solid #E0E0E0",
    width: "100%",
    height: "64px"
  },
  imageItemWrapper: {
    width: "100px",
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      width: "80px"
    }
  },

  imageWrapper: {
    padding: "20px 20px 12px 20px",
    gap: "20px",
    display: "flex",
    width: "100%",
    justifyContent: "space-between"
  },
  titleWrapper: {
    paddingTop: "12px",
    display: "flex",
    flexDirection: "column"
  },
  buttonWrapper: {
    display: "flex"
  }
}));

const CategoryItem = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isEditing, setEditing] = useState(false);
  const history = useHistory();

  const handleEvent = (ev, categoryId, method) => {
    ev.stopPropagation();
    const { removeCategoryFromAccepted, addCategoryToAccepted } = props;

    if (method === "add") addCategoryToAccepted(categoryId);
    else if (method === "remove") removeCategoryFromAccepted(categoryId);
    setEditing(!isEditing);
  };

  const openCategory = () => {
    if (category.accepted) {
      let query = queryString.parse(location.search);
      const searchParams = new URLSearchParams(query);
      searchParams.set("categoryId", category.id);
      history.push({
        pathname: location.pathname,
        search: searchParams.toString()
      });
    }
  };

  const getClassName = (active, total) => {
    let percent = (active / total) * 100;

    if (percent < 50) return classes.red;
    if (percent < 75) return classes.warning;

    return classes.success;
  };

  const { category, products } = props;

  let cardClassname = classes.categoryCard;
  if (category.accepted) {
    cardClassname = classes.categoryCard + " " + classes.categoryCardAccepted;
  }

  return (
    <div className={cardClassname} onClick={openCategory}>
      <div className={classes.imageWrapper}>
        <div className={classes.titleWrapper}>
          <span className={classes.categoryTitle}>{category.name}</span>
          {products && products.length ? (
            <Typography
              variant="caption"
              className={
                classes.active +
                " " +
                getClassName(
                  products.filter(p => p.accepted).length,
                  products.length
                )
              }
            >
              {t("Active products") + ": "}
              {products.filter(p => p.accepted).length}/{products.length}
            </Typography>
          ) : null}
        </div>

        <div className={classes.imageItemWrapper}>
          {" "}
          <ItemImage src={category.categoryImage.src} />
        </div>
      </div>

      <div className={classes.controlsWrapper}>
        <div className={classes.controlsContainer}>
          <div className={classes.acceptContainer}>
            <div className={classes.toggleWrapper}>
              <span className={classes.accepted}>{t("Accepted")}</span>
              <Switch
                color="primary"
                defaultChecked={category.accepted}
                onClick={ev =>
                  handleEvent(
                    ev,
                    category.id,
                    category.accepted ? "remove" : "add"
                  )
                }
              />
            </div>
          </div>
          {category.accepted ? (
            <div className={classes.buttonWrapper}>
              <button className={classes.openButton}>{t("Open")}</button>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default CategoryItem;
