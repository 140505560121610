import React, { useState, useRef, useContext } from "react";
import {
  Typography,
  makeStyles,
  createStyles,
  Grid,
  Button as ButtonMui,
  TextField
} from "@material-ui/core";
import { Button, RadioOptions } from "./inputComponents";
import OffersContext from "../../../providers/OffersProvider";
import CheckIcon from "@material-ui/icons/Check";
import { SAVE_BID_TENDER } from "../../../mutations/offerMutations";
import UserContext from "../../../providers/UserProvider";
import { useMutation } from "react-apollo";
import GetOffersData from "../../../queries/GetOffersData";
import { useTranslation } from "react-i18next";
import classnames from "classnames";
import useEventTracking from "../../../hooks/useEventTracking";

const offerBidStyles = makeStyles(
  createStyles(theme => ({
    formContainer: {
      padding: "32px 24px",
      [theme.breakpoints.down("md")]: {
        padding: "32px 0"
      }
    },
    offerBidS1: {
      color: "rgba(0, 0, 0, 0.87)",
      lineHeight: "24px",
      letterSpacing: 0.25,
      [theme.breakpoints.down("md")]: {
        fontSize: 20,
        fontWeight: 500,
        letterSpacing: 0.15
      }
    },
    offerBidS2: {
      padding: "24px 0 4px",
      [theme.breakpoints.down("md")]: {
        padding: "12px 0 4px"
      }
    },
    price: {
      marginTop: "25px",
      marginBottom: "4px",
      display: "flex",
      alignItems: "center"
    },
    offerBidInput: {
      margin: "19px 0 20px"
    },
    offerBidButton: {
      marginTop: "20px"
    },
    yourMessageHeader: {
      margin: "8px 0 4px"
    },
    yourMessage: {
      color: "rgba(0, 0, 0, 0.6)"
    },
    buttonsContainer: {
      marginTop: 20,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      flexDirection: "row-reverse"
    },
    savedText: {
      display: "flex",
      gap: 4,
      alignItems: "center",
      color: "#151124",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: 0.25
    },
    checkIcon: {
      color: "#52F597"
    },
    black87: {
      color: "rgba(0, 0, 0, 0.87) !important"
    },
    submittedContainer: {
      backgroundColor: "#F9F9F9",
      padding: "32px 24px",
      height: "100%",
      [theme.breakpoints.down("md")]: {
        padding: "32px 0 24px",
        backgroundColor: "#FFFFFF"
      }
    }
  }))
);

const radioOptions = [
  { label: "Fixed price €/t", value: "fixed" },
  { label: "Index linked formula", value: "formula" }
];

const OfferBid = ({ offer, product, canBid }) => {
  const { t } = useTranslation();
  const classes = offerBidStyles();
  const { user, selectedCompany } = useContext(UserContext);
  const { biddingInfo, setBiddingInfo } = useContext(OffersContext);
  const { TrackEvent } = useEventTracking();

  const priceType = offer.tenderType;

  const priceTextPlaceHolder = priceType === "fixed" ? "Price €/t" : "Formula";
  const priceTextType = priceType === "fixed" ? "number" : "string";

  const formRef = useRef(null);

  let bidInfo = ((biddingInfo || {})[offer._id] || {})[product.randomId];

  if (!bidInfo) {
    const bidProduct = offer.buyers[0].bid.products.find(
      p => p.randomId === product.randomId
    );
    bidInfo = {
      price: priceType === "fixed" ? bidProduct.price : bidProduct.formula,
      comment: bidProduct.conditions,
      editing: false
    };
  }

  const priceBox = useRef();
  const commentBox = useRef();
  const [editing, setEditing] = useState(bidInfo.editing);

  const [saveBidTenderMutation] = useMutation(SAVE_BID_TENDER, {
    refetchQueries: () => [
      {
        query: GetOffersData,
        variables: { token: user.token, partnerId: selectedCompany }
      }
    ]
  });

  const saveAction = event => {
    event.preventDefault();
    const comment = commentBox.current.value;
    const price = priceBox.current.value;
    biddingInfo[offer._id][product.randomId] = {
      price,
      comment,
      editing: false
    };
    setBiddingInfo({ ...biddingInfo });

    saveBidTenderMutation({
      variables: {
        token: user.token,
        tenderId: offer._id,
        buyerId: selectedCompany,
        product: {
          productID: product.productID,
          randomId: product.randomId,
          quantity: product.quantity,
          conditions: comment,
          price
        }
      }
    });

    TrackEvent("Price submitted", { price, comments: comment });

    setEditing(false);
  };

  const editAction = () => {
    biddingInfo[offer._id][product.randomId].editing = true;
    setBiddingInfo({ ...biddingInfo });
    setEditing(true);
  };

  const focusBottom = evt => {
    formRef.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  radioOptions.forEach(i => {
    i.label = t(i.label);
  });

  if (canBid && editing) {
    return (
      <form
        onSubmit={saveAction}
        className={classes.formContainer}
        ref={formRef}
      >
        <Typography variant="subtitle1" className={classes.offerBidS1}>
          {t("Your offer")}
        </Typography>
        <Typography
          variant="subtitle2"
          className={classnames(classes.offerBidS2, classes.black87)}
        >
          {t("Price")} (€/t)
        </Typography>
        <RadioOptions
          options={radioOptions}
          name="priceType"
          value={priceType}
          onChange={e => {
            /*setPriceType(e.target.value)*/
          }}
        />
        <TextField
          defaultValue={bidInfo.price}
          label={t(priceTextPlaceHolder)}
          variant="outlined"
          inputRef={priceBox}
          fullWidth
          className={classes.offerBidInput}
          inputProps={{ type: "number" }}
        />
        <TextField
          defaultValue={bidInfo.comment}
          label={t("Comment")}
          variant="outlined"
          inputRef={commentBox}
          fullWidth
          multiline
          minRows={3}
        />
        <Grid container item xs justify="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.offerBidButton}
          >
            {t("Save")}
          </Button>
        </Grid>
      </form>
    );
  } else {
    return (
      <div className={classes.submittedContainer}>
        <Typography variant="subtitle1" className={classes.offerBidS1}>
          {t("Your offer")}
        </Typography>
        <Grid container>
          <Grid item xs>
            <Typography
              variant="subtitle2"
              className={classnames(classes.offerBidS2, classes.black87)}
            >
              {t(priceTextPlaceHolder)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" className={classes.price}>
              {bidInfo.price}
            </Typography>
          </Grid>
        </Grid>
        <Grid container direction="column">
          {bidInfo.comment && (
            <>
              <Grid item>
                <Typography
                  variant="subtitle2"
                  className={classnames(
                    classes.yourMessageHeader,
                    classes.black87
                  )}
                >
                  {t("Your message")}:
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2" className={classes.yourMessage}>
                  {bidInfo.comment}
                </Typography>
              </Grid>
            </>
          )}
          <div className={classes.buttonsContainer}>
            <Grid item>
              <Typography variant="body1" className={classes.savedText}>
                <CheckIcon className={classes.checkIcon} />
                {t("Saved")}
              </Typography>
            </Grid>
            {canBid && (
              <Grid item>
                <ButtonMui
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={editAction}
                >
                  <Typography variant="button">{t("Edit")}</Typography>
                </ButtonMui>
              </Grid>
            )}
          </div>
        </Grid>
      </div>
    );
  }
};

export default OfferBid;

const hasPriceData = bidInfo => bidInfo.price && bidInfo.comment;
