import React, { useState, useContext, useCallback } from "react";
import { DialogContent, Hidden } from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { useMutation } from "@apollo/react-hooks";

import CustomDialog from "../../../components/general/Dialog";
import ADD_NEW_MATERIAL_MUTATION from "../../../mutations/AddNewMaterialToCatalogueMutation";
import AddProductsForm from "./form.js";
import UserContext from "../../../providers/UserProvider";

import useCompany from "../../../hooks/useCompany";
import useEventTracking from "../../../hooks/useEventTracking";
import { useRedirection } from "../../../hooks/useRedirection";
import useUserData from "../../../hooks/useUserData";

const ProductsAddPage = () => {
  const { t } = useTranslation();

  const { user, selectedCompany } = useContext(UserContext);
  const { TrackEvent } = useEventTracking();
  const [state, setState] = useState({
    material: null,
    requestSale: true,
    comment: "",
    files: []
  });

  const { companyRoles, name: companyName } = useCompany();
  const { fullName, email } = useUserData();

  const setField = (field, value) => {
    setState(prevState => ({
      ...prevState,
      [field]: value
    }));
  };
  const [addNewMaterial, { data }] = useMutation(ADD_NEW_MATERIAL_MUTATION);

  const notificationAndRedirection = useRedirection();

  const materialNotAddedRedirection = () =>
    notificationAndRedirection({
      label: "Snackbar_NoMaterialAdded",
      url: "/products",
      color: "secondary"
    });

  const TrackSubmitEventOnAddProduct = useCallback(() => {
    const eventName = "Adding an existing material flow completed";

    TrackEvent(eventName, {
      accountId: selectedCompany,
      clickSource: "Button",
      roles: companyRoles,
      companyName,
      materialName: state.material?.alias || "",
      userName: fullName || email,
      saleRequested: state.requestSale
    });
  }, [state, selectedCompany, companyRoles, companyName, fullName, email]);

  const onSubmit = async () => {
    try {
      TrackSubmitEventOnAddProduct();
      const { data } = await addNewMaterial({
        variables: {
          token: user.token,
          partnerId: selectedCompany,
          productId: state.material.id,
          files: state.files,
          additionalComments: state.comment
        }
      });

      if (!data.AddNewMaterialToCatalogue.productId) {
        throw new Error("No productId returned from mutation");
      }

      // If we have no error and productId we can assume that the mutation was successful
      const isRequestingSale = !!state.requestSale;

      let objRedirection = {
        label: "Snackbar_MaterialAddedSuccessfully",
        url:
          "/products?sale-request=" + data.AddNewMaterialToCatalogue.productId,
        color: "success"
      };

      if (!isRequestingSale) {
        objRedirection.url = "/products";
        objRedirection.state = {
          refetch: true
        };
      }

      notificationAndRedirection(objRedirection);
    } catch (error) {
      materialNotAddedRedirection();
    }
  };

  return (
    <>
      <Hidden mdUp>
        <CustomDialog
          open={true}
          title={t("Header_AddMaterial")}
          onClose={materialNotAddedRedirection}
        >
          <DialogContent>
            <AddProductsForm
              state={state}
              setField={setField}
              onSubmit={onSubmit}
              onClose={materialNotAddedRedirection}
            />
          </DialogContent>
        </CustomDialog>
      </Hidden>
      <Hidden smDown>
        <AddProductsForm
          state={state}
          setField={setField}
          onSubmit={onSubmit}
          onClose={materialNotAddedRedirection}
        />
      </Hidden>
    </>
  );
};
export default ProductsAddPage;
