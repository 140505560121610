import gql from "graphql-tag";

export default gql`
  query UserDashboardQuery(
    $token: String!
    $company: String
    $startDate: String
    $endDate: String
  ) {
    UserDashboard(
      token: $token
      company: $company
      startDate: $startDate
      endDate: $endDate
    ) {
      hasPurchases
      openPurchasesNumber
      openPurchasesValue
      pastPurchasesNumber
      pastPurchasesValue
      hasSales
      openSalesNumber
      openSalesValue
      pastSalesNumber
      pastSalesValue
    }
    GetDashboardManagers(token: $token, companyId: $company) {
      operationalManager {
        image
        name
        email
        phone
      }
      commercialManager {
        image
        name
        email
        phone
      }
    }
  }
`;
