import React, { useRef } from "react";
import { useHistory } from "react-router";
import { useMediaQuery } from "@material-ui/core";
import { useTheme, withStyles } from "@material-ui/core/styles";
import { CourierProvider } from "@trycourier/react-provider";
import { default as Messages } from "@trycourier/react-inbox/dist/components/Messages2.0";
import { ThemeProvider } from "styled-components";
import { MobileInboxTheme } from "../../components/core/shared/notifications/theme";

const COURIER_API_KEY = process.env.REACT_APP_COURIER_API_KEY;

const styles = () => ({
  container: {
    display: "flex",
    height: "100%"
  }
});

const Notifications = ({ user, classes, selectedCompany }) => {
  const theme = useTheme();
  const history = useHistory();
  const matches = useMediaQuery(theme.breakpoints.up("md"));
  const ref = useRef(null);

  if (matches) {
    history.replace(`/dashboard`);
    return null;
  }

  if (selectedCompany === "all") {
    return null;
  }

  return (
    <CourierProvider clientKey={COURIER_API_KEY} userId={user.id}>
      <div className={classes.container}>
        <ThemeProvider theme={MobileInboxTheme}>
          <Messages
            isMobile={true}
            ref={ref}
            renderHeader={() => null}
            renderFooter={() => null}
          />
        </ThemeProvider>
      </div>
    </CourierProvider>
  );
};

export default withStyles(styles)(Notifications);
