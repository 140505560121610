import gql from "graphql-tag";
import React from "react";
import { flowRight as compose } from "lodash";

import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import Step1 from "../../components/verification/Step1";
import Step2 from "../../components/verification/Step2";
import Step3 from "../../components/verification/Step3";
import Step4 from "../../components/verification/Step4";
import { CheckErrors, reportError } from "../../ErrorTracking";
import {
  verificationSellerExists,
  updateVerificationPicture,
  removeVerificationPicture
} from "../../mutations/sellerVerification";
import {
  SELLER_VERIFICATION_IMAGES_QUERY,
  SELLER_VERIFICATION_DOCUMENT
} from "../../queries/SellerVerification";

class Verification extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      step: props.step
    };

    this.setStep = this.setStep.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }

  componentDidMount() {
    const { match, history, verificationSellerExistsMutation } = this.props;
    const { params } = match;
    const verificationCode = params.code;
    const verificationEmail = params.email;

    if (verificationCode && verificationEmail) {
      verificationSellerExistsMutation({
        variables: {
          input: {
            code: verificationCode,
            email: verificationEmail
          }
        }
      })
        .then(({ data: { verificationSellerExists } }) => {
          if (!verificationSellerExists) history.push("/");
        })
        .catch(err => {
          history.push("/");
          reportError(err);
        });
    } else {
      history.push("/");
    }
  }

  setStep(step) {
    this.setState({ step });
  }

  removeImage(stepToRemove) {
    const { match, images, removeVerificationPictureMutation } = this.props;
    const { params } = match;
    const verificationCode = params.code;
    const verificationEmail = params.email;

    const imagesToRemove = images.filter(({ step }) => step === stepToRemove);

    imagesToRemove.forEach(({ _id }) => {
      removeVerificationPictureMutation({
        variables: { _id },
        refetchQueries: [
          {
            query: SELLER_VERIFICATION_IMAGES_QUERY,
            variables: {
              code: verificationCode,
              email: verificationEmail
            }
          }
        ]
      });
    });
  }

  uploadFile(step, doc) {
    if (doc) {
      const { match, updateVerificationPictureMutation } = this.props;
      const { params } = match;
      const verificationCode = params.code;
      const verificationEmail = params.email;
      const file = doc[0];
      const input = {
        step,
        code: verificationCode,
        email: verificationEmail
      };

      updateVerificationPictureMutation({
        variables: { input, file },
        refetchQueries: [
          {
            query: SELLER_VERIFICATION_IMAGES_QUERY,
            variables: {
              code: verificationCode,
              email: verificationEmail
            }
          }
        ]
      });
    }
  }

  render() {
    const { step } = this.state;
    const { images, seller } = this.props;

    return (
      <CheckErrors>
        <div style={{ height: "100%", overflow: "auto" }}>
          {step === 1 && <Step1 next={() => this.setStep(2)} seller={seller} />}
          {step === 2 && (
            <Step2
              step={2}
              back={() => this.setStep(2)}
              next={() => this.setStep(21)}
              images={images.filter(image => image.step === 2)}
              onChange={({ imageStep2 }) => {
                this.uploadFile(2, imageStep2);
              }}
              side="up"
              title="Hochgeladener Ausweis"
              seller={seller}
              removeImage={this.removeImage}
            />
          )}
          {step === 21 && (
            <Step2
              step={21}
              back={() => this.setStep(2)}
              next={() => this.setStep(3)}
              images={images.filter(image => image.step === 21)}
              onChange={({ imageStep2 }) => {
                this.uploadFile(21, imageStep2);
              }}
              side="down"
              title="Senden Sie uns die Rückseite vom Ausweis"
              seller={seller}
              removeImage={this.removeImage}
            />
          )}
          {step === 3 && (
            <Step3
              back={() => this.setStep(21)}
              next={() => this.setStep(4)}
              images={images.filter(image => image.step === 3)}
              onChange={({ imageStep3 }) => {
                this.uploadFile(3, imageStep3);
              }}
              seller={seller}
              removeImage={this.removeImage}
            />
          )}
          {step === 4 && <Step4 seller={seller} />}
        </div>
      </CheckErrors>
    );
  }
}

Verification.defaultProps = {
  step: 1,
  seller: {
    email: "",
    sellerFullName: ""
  },
  images: []
};

Verification.propTypes = {
  step: PropTypes.number,
  match: PropTypes.object.isRequired,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      url: PropTypes.string,
      step: PropTypes.number,
      name: PropTypes.string
    })
  ),
  seller: PropTypes.shape({
    email: PropTypes.string,
    sellerFullName: PropTypes.string
  }),
  history: PropTypes.object.isRequired,
  verificationSellerExistsMutation: PropTypes.func.isRequired,
  updateVerificationPictureMutation: PropTypes.func.isRequired,
  removeVerificationPictureMutation: PropTypes.func.isRequired
};

export default compose(
  graphql(verificationSellerExists, {
    name: "verificationSellerExistsMutation"
  }),
  graphql(updateVerificationPicture, {
    name: "updateVerificationPictureMutation"
  }),
  graphql(removeVerificationPicture, {
    name: "removeVerificationPictureMutation"
  }),
  graphql(SELLER_VERIFICATION_IMAGES_QUERY, {
    props: ({ data: { SellerVerificationImages: images } }) => ({
      images
    }),
    options: ({
      match: {
        params: { code, email }
      }
    } = {}) => ({
      variables: { code, email }
    })
  }),
  graphql(SELLER_VERIFICATION_DOCUMENT, {
    props: ({ data: { SellerVerificationDocument: seller } }) => ({
      seller
    }),
    options: ({
      match: {
        params: { code, email }
      }
    } = {}) => ({
      variables: { code, email }
    })
  })
)(Verification);
