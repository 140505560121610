import gql from "graphql-tag";

export default gql`
  query AccountContacts($token: String!, $company: String!) {
    AccountContacts(token: $token, company: $company) {
      _id
      name
      position
      email
      mobile
      phone
      default
    }
  }
`;
