import React from "react";
import GenericCard from "../../../general/GenericCard";
import { useTranslation } from "react-i18next";
import { IconButton } from "@material-ui/core";
import { Edit, Language } from "@material-ui/icons";

import { makeStyles } from "@material-ui/core";
import EditLanguageForm from "../forms/EditLanguageForm";
import { PROFILE_LANGUAGES } from "../../../../utils/constants";

const LanguageCard = ({ token, company, mode, onSetMode, userData }) => {
  const useStyles = makeStyles({
    userCredentialsSectionContainer: {
      maxWidth: "480px",
      width: "100%"
    },
    cardContainer: {
      display: "flex",
      flexDirection: "column"
    },
    cardFirstCardRow: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    cardRow: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    icons: {
      color: "#666666"
    },
    paddingBottomContainer: {
      paddingBottom: "20px!important"
    },
    preferredLanguage: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)"
    }
  });

  const classes = useStyles();
  const { t } = useTranslation();
  const extraClasses = {
    content: classes.paddingBottomContainer
  };
  const onEdit = () => {
    onSetMode("EDIT_LANGUAGE");
  };

  if (mode === "EDIT_LANGUAGE") {
    return (
      <EditLanguageForm
        token={token}
        company={company}
        userData={userData}
        cancel={(isSuccess = false) => onSetMode("DEFAULT", isSuccess)}
      />
    );
  }
  if (mode === "DEFAULT") {
    const preferredLanguage = userData.RawUserData.preferredLanguage;
    const record = PROFILE_LANGUAGES.find(
      lang => lang.value === preferredLanguage
    );
    let Language_title = record?.name ? record.name : "English";
    return (
      <div className={classes.userCredentialsSectionContainer}>
        <GenericCard
          extraClasses={extraClasses}
          title={t("Language")}
          menuInHeader={
            <>
              <IconButton onClick={onEdit} aria-controls="menu">
                <Edit />
              </IconButton>
            </>
          }
          content={
            <div className={classes.cardContainer}>
              <div className={classes.cardFirstCardRow}>
                <Language className={classes.icons} />
                <span className={classes.preferredLanguage}>
                  {Language_title}
                </span>
              </div>
            </div>
          }
        />
      </div>
    );
  }
  return null;
};

export default LanguageCard;
