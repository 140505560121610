import React, { useState } from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useApolloClient } from "react-apollo";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import { Button, TextField } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import GET_MAGIC_LINK_TOKEN from "../../../queries/GetMagicLinkToken";
import SEND_RESETED_PASSWORD_MUTATION from "../../../mutations/sendResetedPassword";

import Logo from "../../../assets/logo_metaloop.svg";

import { loginUser as loginUserAction } from "../../../actions/auth";
import Loader from "../../core/shared/Loader";

const PASSWORD_MIN = 3;

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "20px",
    height: "100vh",
    "&>*": {
      marginTop: "40px"
    }
  },
  error: {
    color: "red"
  },
  form: {
    width: "250px"
  }
}));

export default ({ token, returnPath }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const history = useHistory();
  const client = useApolloClient();
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const onChangePassword = ev => setPassword(ev.target.value);

  //query to get the magic link token
  const {
    data: queryData,
    loading: queryLoading,
    error: queryError
  } = useQuery(GET_MAGIC_LINK_TOKEN, {
    variables: {
      token
    },
    onCompleted: data => {
      const { email } = data.GetMagicLinkToken;
      setEmail(email);
    },
    onError: error => {
      let url = returnPath || window.location.href;
      let path = url.pathname + url.search;
      redirectLink(path);
    },
    fetchPolicy: "network-only"
  });

  const [sendResetedPasswordMutation, { loading: isLoading }] = useMutation(
    SEND_RESETED_PASSWORD_MUTATION,
    {
      variables: {
        token,
        password
      },
      onCompleted: data => {
        const { partnerId } = data.sendResetedPassword;
        loginUser(returnPath || window.location.href, partnerId);
      },
      onError: error => {
        setError(error.message);
      }
    }
  );

  const redirectLink = returnPath => {
    const { pathname, searchParams } = new URL(
      returnPath,
      window.location.origin
    );
    searchParams.delete("reset_token");
    history.replace({
      pathname: pathname,
      search: searchParams.toString()
    });
  };

  const setPasswordFromToken = async () => {
    if (!password) {
      setError(t("Password cannot be empty"));
      return;
    }

    if (password.length < PASSWORD_MIN) {
      setError(t(`Password cannot be shorter than ${PASSWORD_MIN} characters`));
      return;
    }
    setError(null);

    sendResetedPasswordMutation();
  };

  const loginUser = (url, partnerId) => {
    let returnPath = url.pathname + url.search;
    dispatch(
      loginUserAction(email, password, null, null, () => {
        client.writeData({
          data: { selectedCompany: partnerId }
        });

        try {
          redirectLink(returnPath);
        } catch (e) {
          setError(e.message);
          history.replace("/");
        }
      })
    );
  };
  if (queryLoading) return <Loader />;

  return (
    <div className={classes.container}>
      <>
        <img src={Logo} alt="Schrott24 Logo" />
        <Typography color="textPrimary" variant="subtitle1">
          {t("Reset_password_from_token_text")}
        </Typography>
        <div className={classes.form}>
          <TextField
            name="username"
            fullWidth
            label={t("Username")}
            value={email}
            disabled
          />
          <br />
          <FormControl fullWidth className={classes.password}>
            <InputLabel htmlFor="adornment-password">
              {t("Password")}
            </InputLabel>
            <Input
              name="password"
              id="adornment-password"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={onChangePassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label={t("Toggle password visibility")}
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
          <Typography className={classes.error} variant="caption">
            {t(error)}
          </Typography>
        </div>
        <Button
          disabled={isLoading}
          variant="contained"
          color="primary"
          onClick={setPasswordFromToken}
        >
          {t("Reset password")}
        </Button>
      </>
    </div>
  );
};
