import gql from "graphql-tag";

//const SellerVerificationImagesQuery = gql`
export const SELLER_VERIFICATION_IMAGES_QUERY = gql`
  query ($code: String!, $email: String!) {
    SellerVerificationImages(code: $code, email: $email) {
      _id
      url
      name
      step
    }
  }
`;

//const SellerVerificationDocument = gql`
export const SELLER_VERIFICATION_DOCUMENT = gql`
  query ($code: String!, $email: String!) {
    SellerVerificationDocument(code: $code, email: $email) {
      email
      sellerFullName
    }
  }
`;
