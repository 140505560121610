import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles, Typography, Button } from "@material-ui/core";

import ProductDisplay from "../../../components/products/display";
import DragAndDrop from "../../../components/products/dragAndDrop";

import { useValidation } from "../../../hooks/useValidation";

import CustomTextField from "../../../components/general/CustomTextField";
import MultilineCustomTextField from "../../../components/general/MultilineCustomTextField";

const useStyles = makeStyles(theme => ({
  pageTitle: {
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.87)",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  materialInformationForm: {
    maxWidth: "524px",
    width: "100%",
    marginTop: "20px",
    display: "flex",
    gap: "20px",
    flexDirection: "column"
  },
  twoColumnsContainer: {
    display: "flex",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column"
    }
  },
  dragAndDropTitle: {
    fontWeight: "400",
    fontSize: "16px",
    color: "rgba(0, 0, 0, 0.6)",
    marginBottom: "-12px"
  },
  buttonsContainer: {
    display: "flex",
    gap: "16px",
    justifyContent: "end",
    marginTop: "14px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px"
    }
  },
  purposeContainer: {
    maxWidth: "524px",
    width: "100%",
    paddingTop: "48px",
    paddingBottom: "48px",
    paddingLeft: "32px",
    [theme.breakpoints.down("sm")]: {
      padding: "0",
      marginTop: "-24px",
      paddingBottom: "50px"
    }
  }
}));

function MaterialInformationForm({ state, setField, onSubmit, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { errors, checkValidation, hasErrors } = useValidation();

  const handleChangeOnField = (field, value) => {
    setField(field, value);

    if (field === "materialName") {
      checkValidation({
        materialName: value
      });
    }
  };

  const handleSubmit = () => {
    if (!hasErrors) {
      onSubmit();
    }
  };
  return (
    <div className={classes.purposeContainer}>
      {" "}
      <Typography variant="h4" className={classes.pageTitle}>
        {t("Header_SuggestMaterial")}
      </Typography>
      <div className={classes.materialInformationForm}>
        <CustomTextField
          label="Placeholder_NewMaterial"
          onChange={e => handleChangeOnField("materialName", e.target.value)}
          defaultValue={t("Placeholder_NewMaterial")}
          error={errors.materialName}
        />
        <ProductDisplay
          title={t("Placeholder_NewMaterial")}
          subtitle={t("Placeholder_NewMaterialSubheader")}
          description={t("Placeholder_NewMaterialBody")}
        />
        <div className={classes.twoColumnsContainer}>
          <CustomTextField
            label="Products_BaseMetal"
            onChange={e => handleChangeOnField("baseMetalName", e.target.value)}
          />
          <CustomTextField
            label="Products_Alloy"
            onChange={e => handleChangeOnField("alloyName", e.target.value)}
          />
        </div>

        <div className={classes.twoColumnsContainer}>
          <CustomTextField
            label="Products_ProductFrom"
            onChange={e => handleChangeOnField("formName", e.target.value)}
          />
          <CustomTextField
            label="Products_ProductSurface"
            onChange={e => handleChangeOnField("surfaceName", e.target.value)}
          />
        </div>

        <Typography variant="h6" className={classes.dragAndDropTitle}>
          {t("AddNewMaterial_AddFiles")}
        </Typography>
        <DragAndDrop
          files={state.files}
          setFiles={files => {
            handleChangeOnField("files", files);
          }}
        />
        <MultilineCustomTextField
          label="Additional Comments"
          onChange={e => handleChangeOnField("comment", e.target.value)}
          value={state.comment}
        />
      </div>
      <div className={classes.buttonsContainer}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit}>
          {t("Button_SuggestMaterial")}
        </Button>
      </div>
    </div>
  );
}

export default MaterialInformationForm;
