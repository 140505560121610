import gql from "graphql-tag";

export const GET_TRANSPORT_ADDRESSES = gql`
  query TransportAddressesQuery($token: String!, $company: String!) {
    AccountContacts(token: $token, company: $company) {
      _id
      name
      position
      email
      mobile
      phone
      default
    }
    TransportAddresses(token: $token, company: $company) {
      companyName
      streetAddress
      zipCode
      city
      country
      transportNotes
      type
      _id
      default
      contactId
      contactName
      contactEmail
      contactMobile
      contactPhone
      contactPosition
    }
  }
`;

export const SAVE_TRANSPORT_INFO = gql`
  mutation saveTransport(
    $token: String!
    $address: PartnerAddressInput
    $companyId: String!
  ) {
    saveTransport(token: $token, address: $address, companyId: $companyId) {
      message
    }
  }
`;

export const DELETE_COMPANY_ADDRESS = gql`
  mutation deleteAddressFromPartner(
    $token: String!
    $addressId: String
    $addressType: String
    $companyId: String!
  ) {
    deleteAddressFromPartner(
      token: $token
      addressId: $addressId
      addressType: $addressType
      companyId: $companyId
    ) {
      message
    }
  }
`;

export const CHANGE_DEFAULT_ADDRESS = gql`
  mutation changeDefaultAddress(
    $partnerId: String
    $addressId: String
    $token: String!
  ) {
    changeDefaultAddress(
      partnerId: $partnerId
      addressId: $addressId
      token: $token
    ) {
      message
    }
  }
`;
