/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Hidden from "@material-ui/core/Hidden";
import { ListItemText, ListItemIcon } from "@material-ui/core";
import UserContext from "../../../providers/UserProvider";
import { canSeeItem } from "../../../utils/SharedUtils.js";
import { ROUTES, isSelected } from "./constants";
import useEventTracking from "../../../hooks/useEventTracking";

const useStyles = makeStyles({
  container: {
    alignSelf: "stretch",
    border: "1px solid #EEE",
    textAlign: "center",
    width: "264px",
    background: "#FFF",
    display: "flex",
    flexDirection: "column",
    borderLeft: "0",
    flexShrink: 0,
    position: "absolute",
    height: "calc( 100vh - 48px )",
    top: "48px"
  },
  list: {
    color: "rgba(0, 0, 0, 0.6)",
    padding: 0,
    "& .Mui-selected": {
      backgroundColor: "#EAFEF3",
      borderRadius: "0"
    },
    "& .Mui-selected .MuiListItemText-primary": {
      color: "#151124 !important"
    },
    "& .Mui-selected .MuiListItemIcon-root": {
      color: "#151124 !important"
    },
    "& .MuiListItem-button": {
      color: "rgba(0, 0, 0, 0.6)"
    }
  },
  ListItem: {
    padding: "12px 32px",
    "& .MuiListItemIcon-root": {
      minWidth: "auto",
      marginRight: 16
    }
  },
  listItem: {
    padding: "12px 32px",
    "& .MuiListItemIcon-root": {
      minWidth: "auto"
    }
  },
  listItemIcon: {
    "& .MuiSvgIcon-root": {
      marginRight: "16px"
    }
  },
  listItemText: {
    margin: 0,
    "& .MuiListItemText-primary": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "24px",
      letterSpacing: "0.1px",
      color: "rgba(0, 0, 0, 0.6)",
      "text-transform": "Capitalize"
    }
  }
});

const LeftNavigation = () => {
  const { userData, selectedCompany } = useContext(UserContext);
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();
  const { TrackEvent } = useEventTracking();

  const trackClick = (to, text) => {
    if (to === "/dashboard") return false;
    TrackEvent("Sidebar navigation", {
      accountId: selectedCompany,
      page: to,
      linkPressed: text
    });

    return false;
  };

  return (
    <Hidden smDown>
      <div className={classes.container}>
        <List component="nav" className={classes.list}>
          {ROUTES.map(({ id, to, canSeeRules, children }) =>
            !canSeeItem(selectedCompany, userData.UserCompanies, canSeeRules)
              ? null
              : children &&
                location.pathname.split("/")[1] === to.split("/")[1] &&
                children.map(
                  ({
                    to: toChildren,
                    icon,
                    text,
                    id,
                    prepend,
                    append,
                    exact,
                    additionalSelected,
                    canSeeRules,
                    urlParams
                  }) =>
                    (!canSeeRules ||
                      canSeeItem(
                        selectedCompany,
                        userData.UserCompanies,
                        canSeeRules
                      )) && (
                      <ListItem
                        className={classes.listItem}
                        key={"nav_" + id}
                        {...(!(toChildren === undefined)
                          ? {
                              disabled: false,
                              selected: isSelected(
                                location,
                                toChildren,
                                to,
                                exact,
                                additionalSelected,
                                urlParams
                              ),
                              button: true,
                              onClick: () => {
                                if (true) {
                                  let loadingFrom = { sideMenu: true };

                                  trackClick(to, text) ||
                                    history.push(to + toChildren, {
                                      loadingFrom
                                    });
                                }
                              }
                            }
                          : null)}
                      >
                        <ListItemIcon className={classes.listItemIcon}>
                          {icon}
                        </ListItemIcon>
                        <ListItemText
                          className={classes.listItemText}
                          {...(!icon ? { inset: false } : null)}
                          primary={
                            <>
                              {prepend}
                              {t(text)}
                              {append}
                            </>
                          }
                        />
                      </ListItem>
                    )
                )
          )}
        </List>
      </div>
    </Hidden>
  );
};

export default LeftNavigation;
