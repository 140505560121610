import React from "react";
import {
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  ListItemSecondaryAction,
  IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import BeenHereIcon from "@material-ui/icons/Beenhere";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";

const styles = theme => ({
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid " + theme.palette.grey["100"],
    padding: 0,
    marginTop: 10,
    "& > li:nth-child(odd)": {
      backgroundColor: theme.palette.grey["100"]
    }
  }
});

class ZoneList extends React.PureComponent {
  printWeekdays(dayOfTheWeek) {
    let weekdays = ["M", "T", "W", "T", "F", "S", "S"];
    if (!dayOfTheWeek) dayOfTheWeek = [];

    return weekdays.map((w, i) => {
      if (dayOfTheWeek.indexOf(i + 1) > -1)
        return (
          <span style={{ fontWeight: "bold", textDecoration: "underline" }}>
            {w}
          </span>
        );
      else return <span>{w}</span>;
    });
  }
  downloadLink({ zoneName, partnerId, geoJSON }) {
    const json = JSON.stringify(geoJSON);
    const blob = new Blob([json], { type: "application/json" });
    const url = URL.createObjectURL(blob);

    var a = document.createElement("a");
    a.download = `${zoneName}-${partnerId}.json`;
    a.href = url;
    a.click();
  }

  render() {
    const { classes, zones, deleteZones, editZones } = this.props;
    const { printWeekdays } = this;
    return (
      <List className={classes.root}>
        {zones.map(zone => (
          <ListItem className={classes.items}>
            <ListItemAvatar>
              <BeenHereIcon />
            </ListItemAvatar>
            <ListItemText
              primary={
                zone.zoneName && zone.zoneName.trim().length > 0 ? (
                  zone.zoneName
                ) : (
                  <span style={{ fontStyle: "oblique" }}>-No Name-</span>
                )
              }
              secondary={<>{printWeekdays(zone.dayOfTheWeek)}</>}
            />
            <ListItemSecondaryAction>
              <IconButton
                edge="end"
                aria-label="edit"
                onClick={() => editZones(zone)}
              >
                <EditIcon />
              </IconButton>
              {/*<IconButton
                edge="end"
                aria-label="download"
                onClick={() => downloadLink(zone)}
              >
                <DownloadIcon />
              </IconButton>*/}
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => deleteZones(zone._id)}
              >
                <DeleteIcon />
              </IconButton>
            </ListItemSecondaryAction>
          </ListItem>
        ))}
      </List>
    );
  }
}

export default withStyles(styles)(withTranslation()(ZoneList));
