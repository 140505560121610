import React, { useContext } from "react";
import { ROUTES } from "../../components/core/shared/constants";
import { canSeeItem } from "../../utils/SharedUtils.js";
import UserContext from "../../providers/UserProvider";
import HomeMobileItem from "./HomeMobileItem";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "flex-start",
    minHeight: "fit-content",
    overflow: "auto",
    width: "100%",
    padding: "24px 16px 24px 16px",
    background: "#F5F5F5",
    "& div:nth-child(odd)": {
      marginRight: "16px"
    }
  }
}));

const HomeMobile = () => {
  const { selectedCompany, userData } = useContext(UserContext);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {ROUTES.map(
        ({ id, to, text, icon, canSeeRules, hideInMenu, showInMobileMenu }) =>
          !canSeeItem(selectedCompany, userData.UserCompanies, canSeeRules) ||
          (hideInMenu && !showInMobileMenu) ? null : (
            <HomeMobileItem to={to} icon={icon} text={text} key={id} />
          )
      )}
    </div>
  );
};

export default HomeMobile;
