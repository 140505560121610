import React from "react";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

const RightContent = props => (
  <div className={props.classes.wrapper}>{props.children}</div>
);

export default withStyles(styles)(RightContent);
