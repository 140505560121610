import "whatwg-fetch";
import config from "../config/config";
import { clearStorage } from "./DataPersistence";

const EMAIL_NOT_FOUND_ERROR =
  "There is no account associated to this email. Please check it is correct or create an";
const DEFAULT_LOGIN_ERROR =
  "An error occurred while loggin in, try again please";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_REDIRECT_LINK = "SET_REDIRECT_LINK";
export const SENT_EMAIL = "SENT_EMAIL";

export const loginError = ({ error }) => ({
  error,
  type: LOGIN_ERROR
});

export const loginSuccess = ({ user }) => ({
  user,
  type: LOGIN_SUCCESS
});

export const sentEmail = ({ email }) => ({
  email,
  type: SENT_EMAIL
});

export const logoutUser = () => {
  clearStorage();
  return {
    type: LOGOUT_USER
  };
};

export const setRedirectLink = ({ sellerId, redirectLink }) => ({
  type: SET_REDIRECT_LINK,
  sellerId,
  redirectLink
});

export const checkUserAndSendMagicLink = (email, link, lang) => dispatch => {
  const params = { email, link, lang };
  return fetch(config.SEND_MAGIC_LINK, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(params)
  })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      return response.json().then(res => {
        dispatch(loginError({ error: res.reason || EMAIL_NOT_FOUND_ERROR }));
        return res;
      });
    })
    .then(json => {
      if (json.exists === false) {
        dispatch(loginError({ error: EMAIL_NOT_FOUND_ERROR }));
      } else {
        dispatch(sentEmail({ email: email }));
      }
    })
    .catch(() => {
      dispatch(loginError({ error: EMAIL_NOT_FOUND_ERROR }));
    });
};

export const loginUserMagicLink =
  (magicLink, redirectLink = "", history, cb) =>
  dispatch => {
    const params = { magicLink };

    return fetch(config.LOG_IN_MAGIC_LINK, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return response.json().then(res => {
          throw new Error();
        });
      })
      .then(json => {
        if (json.id && json.token && json.tokenExpires) {
          if (redirectLink && history) {
            history.push(redirectLink);
          }
          return dispatch(loginSuccess({ user: json }));
        } else {
          throw new Error();
        }
      })
      .then(() => {
        history.push(redirectLink);
      })
      .catch(() => {
        history.push("/loginLink");
        dispatch(
          loginError({
            error:
              "This magic link is no longer valid. Please generate a new one by entering your email address above"
          })
        );
      });
  };

export const loginUser =
  (email, password, redirectLink = "", history, onSuccess = () => {}) =>
  dispatch => {
    const params = { email, password };

    return fetch(config.API_AUTH_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(params)
    })
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        return response.json().then(res => {
          dispatch(loginError({ error: res.reason || DEFAULT_LOGIN_ERROR }));
          return res;
        });
      })
      .then(json => {
        if (json.id && json.token && json.tokenExpires) {
          if (redirectLink && history) {
            history.push(redirectLink);
          }
          dispatch(loginSuccess({ user: json }));
          return onSuccess();
        }
        return dispatch(
          loginError({ error: json.reason || DEFAULT_LOGIN_ERROR })
        );
      })
      .catch(() => {
        dispatch(loginError({ error: DEFAULT_LOGIN_ERROR }));
      });
  };
