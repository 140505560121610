import React, { useMemo, useState } from "react";
import NoSearchableList from "./notSearchable";
import SearchableList from "./searchable";
const CustomList = ({
  searchable = false,
  onChange = () => {},
  defaultSelected = [],
  ...props
}) => {
  const [selected, setSelected] = useState([]);
  // use computed value to fill selected values
  const valueChange = useMemo(() => {
    if (selected.length) {
      return selected;
    }
    return defaultSelected;
  }, [defaultSelected, selected]);

  const handleChange = value => {
    if (Array.isArray(value)) {
      setSelected(value);
      onChange(value);
    } else {
      setSelected([value]);
      onChange([value]);
    }
  };

  if (searchable) {
    return (
      <SearchableList
        {...props}
        handleChange={handleChange}
        selected={valueChange}
      />
    );
  } else {
    return (
      <NoSearchableList
        {...props}
        handleChange={handleChange}
        selected={valueChange}
      />
    );
  }
};
export default CustomList;
