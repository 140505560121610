import gql from "graphql-tag";

export default gql`
  mutation updateAccountingEmail(
    $token: String!
    $company: String!
    $input: AccountingEmailInput!
  ) {
    updateAccountingEmail(token: $token, company: $company, input: $input) {
      message
    }
  }
`;
