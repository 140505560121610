import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import classnames from "classnames";

import FileList from "../../core/shared/FileList";
import useFilePartition from "../../../hooks/useFilePartition";
import PickUpDownloadAllButton from "./shared/PickUpDownLoadAllButton";

const useStyles = makeStyles(theme => ({
  panelTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0"
  },
  panelDescription: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    margin: "0"
  },
  ctaWrap: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    gap: "16px"
  },
  panelCol: {
    flexBasis: "50%",
    width: "50%"
  },
  threeSixEightCent: {
    flexBasis: "100%",
    width: "100%"
  },
  downloadAll: {
    padding: "10px 4px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "#151124",
    textTransform: "initial",
    height: "auto",
    backgroundColor: "transparent",
    "&:focus": {
      backgroundColor: "transparent"
    }
  },
  downloadAllWrap: {
    marginTop: "4px"
  },
  detailColRoot: {
    padding: "0 10px"
  },
  [theme.breakpoints.up("md")]: {
    panelCol: {
      flexBasis: "188px",
      width: "188px"
    },
    threeSixEightCent: {
      flexBasis: "calc(100% - 564px)",
      width: "calc(100% - 564px)"
    },
    ctaWrap: {
      width: "100%"
    }
  }
}));

const PickupsCardDocument = ({
  item,
  user,
  selectedCompany,
  setUploadDialogOpen,
  handleRefetch,
  disableButtons = false
}) => {
  const { _id: id, documents } = item;
  const classes = useStyles();
  const { t } = useTranslation();

  // partiton files intp my document and metaloop documents
  const [metaloopDocuments, myDocuments] = useFilePartition(documents);

  return (
    <>
      <div className={classnames(classes.panelCol, classes.detailColRoot)}>
        <div className={classes.panels}>
          <FileList
            headingTitle={
              <p className={classes.panelTitle}>{t("Metaloop Documents")}</p>
            }
            files={metaloopDocuments}
            orderId={id}
            disabled={disableButtons}
          />
        </div>
      </div>
      <div className={classnames(classes.panelCol, classes.detailColRoot)}>
        <div className={classes.panels}>
          <FileList
            headingTitle={
              <p className={classes.panelTitle}>{t("My Documents")}</p>
            }
            files={myDocuments}
            orderId={id}
            token={user?.token}
            showDeleteIcon
            refetch={handleRefetch}
            disabled={disableButtons}
          />
        </div>
      </div>
      <div
        className={classnames(classes.threeSixEightCent, classes.detailColRoot)}
      >
        <div className={classes.ctaWrap}>
          <Button
            variant="outlined"
            color="primary"
            fullWidth
            startIcon={<AddIcon />}
            onClick={() => setUploadDialogOpen(true)}
            disabled={disableButtons}
          >
            {t("DOCUMENTS")}
          </Button>
          {/* download all button */}
          {documents && documents.length > 0 && (
            <PickUpDownloadAllButton
              sellerId={selectedCompany}
              token={user?.token}
              orderId={id}
              disableButtons={disableButtons}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PickupsCardDocument;
