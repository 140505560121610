/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useContext } from "react";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import LogoMobile from "../LogoMobile";
import { ReactComponent as ArrowIcon } from "../../../assets/icons/arrow-left-white.svg";
import { useTranslation } from "react-i18next";
import { ROUTES, isSelected } from "./constants";
import { Tabs, Tab, Typography } from "@material-ui/core";
import DropDownNavMenu from "./DropDownNavMenu";
import UserContext from "../../../providers/UserProvider";
const useStyles = makeStyles(theme => ({
  mobileMenu: {
    position: "fixed",
    top: "0",
    width: "100%",
    height: "56px",
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: "16px",
    paddingRight: "16px",
    boxShadow:
      "0px 2px 4px rgba(0, 0, 0, 0.24), 0px 4px 8px rgba(0, 0, 0, 0.18)",
    zIndex: 100
  },
  mobileMenuRightSide: {
    display: "flex",
    alignItems: "center",
    color: "white"
  },
  mobileSubMenu: {
    position: "fixed",
    top: "56px",
    left: 0,
    width: "100%",
    height: "48px",
    background: theme.palette.primary.main,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    boxShadow:
      "0px 2px 4px rgba(0, 0, 0, 0.24), 0px 4px 8px rgba(0, 0, 0, 0.18)"
  },
  tabs: {
    width: "100%",
    height: "100%"
  },
  tab: {
    minWidth: "fit-content",
    whiteSpace: "nowrap"
  },
  routeName: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "#FFFFFF",
    marginLeft: "16px"
  }
}));

const MobileNavbar = ({ onLogout }) => {
  const history = useHistory();
  const location = history.location;
  const { t } = useTranslation();
  const { userData, selectedCompany } = useContext(UserContext);

  let route = ROUTES.find(
    i => location.pathname.split("/")[1] === i.to.split("/")[1]
  );

  const classes = useStyles();

  const goBack = () => {
    history.push("/home");
  };

  return (
    <Hidden mdUp>
      <div className={classes.mobileMenu}>
        {!route.isHomePage ? (
          <div>
            <ArrowIcon onClick={goBack} style={{ cursor: "pointer" }} />
            <span className={classes.routeName}>{t(route.text)}</span>{" "}
          </div>
        ) : (
          <LogoMobile />
        )}
        <DropDownNavMenu
          selectedCompany={selectedCompany}
          userData={userData}
          onLogout={onLogout}
          showName={false}
        />
        {route && route.children && route.children.length > 0 && (
          <div className={classes.mobileSubMenu}>
            <MobileNavbarSubTabs routes={route} />
          </div>
        )}
      </div>
    </Hidden>
  );
};

const MobileNavbarSubTabs = ({ routes }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const { location } = history;

  const { to } = routes;

  const childrens = routes.children.filter(i => i.to !== undefined);

  let route = childrens.findIndex(i =>
    isSelected(location, i.to, to, i.exact, null, i.urlParams)
  );

  return (
    <Tabs
      value={route}
      variant="scrollable"
      indicatorColor="secondary"
      textColor="primary"
      className={classes.tabs}
    >
      {childrens.map((r, i) =>
        r.isHomePage ? null : (
          <Tab
            value={i}
            key={i}
            onClick={() => history.push(to + r.to)}
            className={classes.tab}
            label={getLabel(
              <>
                {t(r.text)}
                {r.appendInMobile ? r.append : ""}{" "}
              </>,
              classes,
              i === route
            )}
          />
        )
      )}
    </Tabs>
  );
};

const getLabel = (text, isActive) => (
  <Typography variant="button">
    <span style={{ color: isActive ? "#FFFFFF" : "rgba(255, 255, 255, 0.74)" }}>
      {text}
    </span>
  </Typography>
);

export default MobileNavbar;
