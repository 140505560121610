import React from "react";
import { withTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  getMagicLinkText: {
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.6)",
    marginTop: "32px",
    marginBottom: "32px",
    marginLeft: "11.5px",
    marginRight: "11.5px"
  },
  separator: {
    height: "1px",
    width: "100%",
    backgroundColor: "#e0e0e0"
  },
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "400px",
    marginTop: "32px",
    marginBottom: "22px",
    height: "24px",
    "@media (max-width: 400px)": {
      width: "100%",
      padding: "0 16px",
      margin: "16px 0 6px"
    }
  }
});

class OrSeparator extends React.Component {
  render() {
    const { classes, t } = this.props;
    return (
      <div className={classes.container}>
        <div className={classes.separator}></div>
        <Typography className={classes.getMagicLinkText} variant="subtitle1">
          {" "}
          {t("Or")}{" "}
        </Typography>
        <div className={classes.separator}></div>
      </div>
    );
  }
}

export default withStyles(styles)(withTranslation()(OrSeparator));
