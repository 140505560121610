import gql from "graphql-tag";

export default gql`
  query CompanyInformationQuery($token: String!, $company: String!) {
    CompanyInformation(token: $token, company: $company) {
      id
      name
      uid
      branch
      address {
        street
        city
        country
        zip
      }
      shipingAddress {
        street
        city
        country
        zip
      }
    }
  }
`;
