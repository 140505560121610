import React from "react";
import { makeStyles } from "@material-ui/core";

import FileList from "../../../core/shared/FileList";

const useStyles = makeStyles(theme => ({
  panelTitle: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0"
  }
}));

const PickUpDocument = ({
  title,
  orderId,
  documents,
  className,
  headingTitle,
  showDeleteIcon
}) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <FileList
        headingTitle={
          headingTitle ? (
            headingTitle
          ) : (
            <p className={classes.panelTitle}>{title}</p>
          )
        }
        files={documents}
        orderId={orderId}
        showDeleteIcon={showDeleteIcon}
      />
    </div>
  );
};

PickUpDocument.defaultProps = {};

export default PickUpDocument;
