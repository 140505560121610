import { memo } from "react";

const googleAPIs = {
  placesService: null,
  autocompleteService: null
};

const GoogleAPI = () => {
  setupGooglePlacesService();
  setupGoogleAutocompleteService();

  return null;
};

export const useGoogleApi = () => googleAPIs;

export default memo(GoogleAPI, () => true);

const setupGoogleAutocompleteService = () => {
  const { autocompleteService } = googleAPIs;

  if (!autocompleteService) {
    googleAPIs.autocompleteService =
      new window.google.maps.places.AutocompleteService();
  }
};

const setupGooglePlacesService = () => {
  const { placesService } = googleAPIs;

  if (!placesService) {
    googleAPIs.placesService = new window.google.maps.places.PlacesService(
      document.createElement("div")
    );
  }
};
