import React from "react";
import ErrorBoundary from "./ErrorBoundary";

const startTracking = () => {};

const reportError = (error, errorInfo) => {};

const withReporter = (ErrorComponent, reporter) =>
  class extends React.Component {
    render() {
      return <ErrorComponent {...this.props} report={reporter} />;
    }
  };

const CheckErrors = withReporter(ErrorBoundary, reportError);

export { startTracking, reportError, CheckErrors };
