import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "react-apollo";

import { makeStyles } from "@material-ui/core/styles";
import { Hidden, Typography, useMediaQuery } from "@material-ui/core";

import VerticalSlider from "../../components/onboarding/VerticalSlider";
import { ReactComponent as SaveLogo } from "../../assets/icons/save.svg";
import { ReactComponent as SaveLogoDark } from "../../assets/icons/save_dark.svg";
import { ReactComponent as Logo } from "../../assets/logo.svg";
import Loader from "../../components/core/shared/Loader";
import {
  getInputForSaveMutation,
  saveCredentialsMutation,
  getFieldsChangedInOnboardingStep
} from "../../utils/Onboarding";
import useEventTracking from "../../hooks/useEventTracking";
import { roleOptions } from "../../utils/constants";

import OnboardingComponents from "../../queries/OnboardingComponents";
import OnboardingPersonalInformationMutation from "../../mutations/OnboardingPersonalInformationMutation";
import OnboardingCompanyInformationMutation from "../../mutations/OnboardingCompanyInformationMutation";
import OnboardingTransportInformationMutation from "../../mutations/OnboardingTransportInformationMutation";
import OnboardingBillingInformationMutation from "../../mutations/OnboardingBillingInformationMutation";
import OnboardingLoginMutation from "../../mutations/OnboardingLoginMutation";

import {
  ONBOARDING_PERSONAL_INFORMATION,
  ONBOARDING_COMPANY_INFORMATION,
  ONBOARDING_TRANSPORT_INFORMATION,
  ONBOARDING_BILLING_AND_PAYMENT_INFORMATION,
  ONBOARDING_CONGRATULATIONS,
  ONBOARDING_STEPS,
  REVERSE_ONBOARDING_STEPS,
  ONBOARDING_BO_COMPONENT_NAMES,
  ONBOARDING_STEPS_LIST_LABELS,
  ONBOARDING_BO_COMPONENT_NAMES_REVERSE
} from "../../utils/constants";
import PersonalInformation from "./PersonalInformation";
import CompanyInformation from "./CompanyInformation";
import TransportInformation from "./TransportInformation";
import BillingAndPayment from "./BillingAndPayment";
import Congratulations from "./Congratulations";
import LeftMenu from "./LeftMenu";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    height: "100vh"
  },
  leftSide: {
    flex: 1,
    backgroundColor: "#151124",
    maxWidth: "360px",
    flex: "0 0 30%" // flex-grow, flex-shrink, flex-basis
  },
  rightSide: {
    flex: 1,
    backgroundColor: "white",
    flex: "0 0 70%" // flex-grow, flex-shrink, flex-basis
  },
  logo: {
    paddingLeft: "50px",
    paddingTop: "48px",
    width: "240px",
    borderRightColor: "rgba(0,0,0,.12)",
    borderRightWidth: "1px",
    borderRightStyle: "solid",
    display: "flex",
    alignItems: "center",
    height: "48px"
  },
  overrideText: {
    fontSize: "16px",
    color: "white"
  },
  overrideCircleInactive: {
    color: "#151124",
    borderColor: "#95949C",
    backgroundColor: "#95949C"
  },
  overrideCircleActive: {
    color: "#151124",
    backgroundColor: "#52F597"
  },
  leftContent: {
    marginTop: "180px",
    marginLeft: "50px"
  },
  leftColTitle: {
    color: "white",
    textDecoration: "capitalize",
    fontWeight: "semibold",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "28px"
  },
  overrideMainDiv: {
    marginLeft: "5px",
    marginTop: "20px"
  },
  overrideCircle: {
    width: "20px",
    height: "20px",
    "border-radius": "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "bold"
  },
  overrideCircleAlreadyOver: {
    color: "#52F597",
    borderColor: "#52F597",
    backgroundColor: "#151124"
  },
  saveAndComplete: {
    color: "white",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    textDecoration: "underline",
    marginLeft: "12px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "8px",
      color: "black",
      opacity: 0.6
    }
  },
  saveAndCompleteDiv: {
    marginTop: "60px",
    display: "flex",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      position: "fixed",
      bottom: "32px",
      display: "flex",
      width: "100%",
      justifyContent: "center"
    }
  },
  removeSpace: {
    position: "initial",
    marginTop: "10px",
    marginBottom: "20px"
  }
}));

const Form = ({ step }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({});
  const [initialFormData, setInitialFormData] = useState({});
  const [actualStep, setActualStep] = useState(null);
  const [loadingSave, setLoadingSave] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const token = location.state?.token;
  const sourceQueryObj = location.state?.sourceQueryObj;
  if (!token) {
    history.push(`/`);
  }

  const [sendCloseMutation] = useMutation(OnboardingLoginMutation);

  const { loading, data } = useQuery(OnboardingComponents, {
    variables: { token },
    fetchPolicy: "network-only",
    errorPolicy: "ignore"
  });
  if (loading) return <Loader />;
  const onboardingComponents =
    data?.OnboardingInformationQuery?.onboardingComponents;

  const askedSteps = onboardingComponents
    .map(
      component =>
        ONBOARDING_BO_COMPONENT_NAMES_REVERSE[component.componentName]
    )
    .concat(ONBOARDING_CONGRATULATIONS);
  if (!actualStep) setActualStep(askedSteps.indexOf(step) + 1);

  const trackClickNextEvent = () => {
    const actualStepName = askedSteps[actualStep - 1];
    const trackEventIds = {
      [ONBOARDING_PERSONAL_INFORMATION]: "KYC Flow - Personal - Clicks next",
      [ONBOARDING_COMPANY_INFORMATION]: "KYC Flow - Company - Clicks next",
      [ONBOARDING_TRANSPORT_INFORMATION]: "KYC Flow - Transport - Clicks next",
      [ONBOARDING_BILLING_AND_PAYMENT_INFORMATION]:
        "KYC Flow - Billing - Clicks next"
    };
    const trackEventId = trackEventIds[actualStepName];
    const eventData = getFieldsChangedInOnboardingStep(
      initialFormData,
      formData,
      actualStepName,
      newData => {
        setInitialFormData({ ...formData, ...newData });
      }
    );
    trackEventId && TrackEvent(trackEventId, eventData);
  };

  const trackSaveAndCompleteLaterEvent = () => {
    const actualStepName = askedSteps[actualStep - 1];
    const trackEventIds = {
      [ONBOARDING_PERSONAL_INFORMATION]:
        "KYC Flow - Personal - Save and complete later",
      [ONBOARDING_COMPANY_INFORMATION]:
        "KYC Flow - Company - Save and complete later",
      [ONBOARDING_TRANSPORT_INFORMATION]:
        "KYC Flow - Transport - Save and complete later",
      [ONBOARDING_BILLING_AND_PAYMENT_INFORMATION]:
        "KYC Flow - Billing - Save and complete later"
    };
    const trackEventId = trackEventIds[actualStepName];
    const eventData = getFieldsChangedInOnboardingStep(
      initialFormData,
      formData,
      actualStepName
    );
    trackEventId && TrackEvent(trackEventId, eventData);
  };

  const handleSetActualStep = step => {
    const actualStepName = askedSteps[actualStep - 1];
    const trackEventIds = {
      [ONBOARDING_PERSONAL_INFORMATION]:
        "KYC Flow - Personal - Clicks on sidebar",
      [ONBOARDING_COMPANY_INFORMATION]:
        "KYC Flow - Company - Clicks on sidebar",
      [ONBOARDING_TRANSPORT_INFORMATION]:
        "KYC Flow - Transport - Clicks on sidebar",
      [ONBOARDING_BILLING_AND_PAYMENT_INFORMATION]:
        "KYC Flow - Billing - Clicks on sidebar"
    };
    const trackEventId = trackEventIds[actualStepName];
    trackEventId && TrackEvent(trackEventId);
    setActualStep(step);
    const newValue = askedSteps[step - 1];
    history.push({
      pathname: newValue,
      state: { token, sourceQueryObj }
    });
  };

  const validRole = roleOptions.find(
    role => role.value === data?.OnboardingPersonalInformationQuery.companyRole
  );

  const bothAddressAreTheSame =
    data.OnboardingCompanyInformationQuery &&
    data.OnboardingTransportInformationQuery &&
    checkSameAddress(
      data.OnboardingCompanyInformationQuery,
      data.OnboardingTransportInformationQuery
    );

  if (!formData.queryLoaded) {
    const initialFormData = {
      ...{
        firstName: data?.OnboardingPersonalInformationQuery?.firstName,
        lastName: data?.OnboardingPersonalInformationQuery?.lastName,
        email: data?.OnboardingPersonalInformationQuery?.email,
        companyRole: validRole && validRole.value,
        phone: data?.OnboardingPersonalInformationQuery?.phone
          ? `${
              data?.OnboardingPersonalInformationQuery?.phone?.startsWith("+")
                ? ""
                : "+"
            }${data?.OnboardingPersonalInformationQuery?.phone}`
          : "",
        mobile: data?.OnboardingPersonalInformationQuery?.mobile
          ? `${
              data?.OnboardingPersonalInformationQuery?.mobile.startsWith("+")
                ? ""
                : "+"
            }${data?.OnboardingPersonalInformationQuery?.mobile}`
          : ""
      },
      ...data.OnboardingCompanyInformationQuery,
      ...{
        sameAsCompanyAddress: bothAddressAreTheSame,
        transportCompanyName:
          data.OnboardingTransportInformationQuery?.companyName,
        transportStreetAndNumber:
          data.OnboardingTransportInformationQuery?.streetAddress,
        transportZipCode: data.OnboardingTransportInformationQuery?.zipCode,
        transportCity: data.OnboardingTransportInformationQuery?.city,
        transportCountry: data.OnboardingTransportInformationQuery?.country
      },
      ...{
        vat: data.OnboardingBillingInformationQuery?.UID,
        iban: data.OnboardingBillingInformationQuery?.IBAN,
        mainAccountingName: data.OnboardingBillingInformationQuery?.name,
        mainAccountingEmail: data.OnboardingBillingInformationQuery?.email
      },
      queryLoaded: true
    };
    setFormData(initialFormData);
    setInitialFormData(initialFormData);
    if (actualStep !== data.OnboardingInformationQuery?.onboardingStep) {
      handleSetActualStep(data.OnboardingInformationQuery?.onboardingStep + 1);
    }
  }

  const setNextStep = () => {
    setActualStep(actualStep + 1);
    const value = askedSteps[actualStep];
    history.push({
      pathname: value,
      state: { token, sourceQueryObj }
    });
  };

  const setPreviousStep = () => {
    setActualStep(actualStep - 1);
    const value = askedSteps[actualStep - 2];
    history.push({
      pathname: value,
      state: { token, sourceQueryObj }
    });
  };

  if (step === ONBOARDING_CONGRATULATIONS)
    return (
      <Congratulations
        formData={formData}
        setFormData={setFormData}
        setPreviousStep={setPreviousStep}
        token={token}
        askedSteps={askedSteps}
        sourceQueryObj={sourceQueryObj}
      />
    );

  const stepList = onboardingComponents.map(component => ({
    label: t(ONBOARDING_STEPS_LIST_LABELS[component.componentName])
  }));

  const saveMutationsList = {
    [ONBOARDING_PERSONAL_INFORMATION]: OnboardingPersonalInformationMutation,
    [ONBOARDING_COMPANY_INFORMATION]: OnboardingCompanyInformationMutation,
    [ONBOARDING_TRANSPORT_INFORMATION]: OnboardingTransportInformationMutation,
    [ONBOARDING_BILLING_AND_PAYMENT_INFORMATION]:
      OnboardingBillingInformationMutation,
    [ONBOARDING_CONGRATULATIONS]: OnboardingLoginMutation
  };

  if (loadingSave) return <Loader />;

  return (
    <>
      <Hidden smDown>
        <div className={classes.container}>
          <div className={classes.leftSide}>
            <LeftColumn
              actualStep={actualStep}
              handleSetActualStep={handleSetActualStep}
              stepList={stepList}
              saveMutationsList={saveMutationsList}
              saveCredentials={saveCredentialsMutation}
              askedSteps={askedSteps}
              token={token}
              sendCloseMutation={sendCloseMutation}
              formData={formData}
              setLoadingSave={setLoadingSave}
              sourceQueryObj={sourceQueryObj}
              trackSaveAndCompleteLaterEvent={trackSaveAndCompleteLaterEvent}
            />
          </div>
          <div className={classes.rightSide}>
            <RightColumn
              formData={formData}
              setFormData={setFormData}
              step={step}
              setNextStep={setNextStep}
              setPreviousStep={setPreviousStep}
              token={token}
              onboardingComponents={onboardingComponents}
              sourceQueryObj={sourceQueryObj}
              trackClickNextEvent={trackClickNextEvent}
            />
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <MobileForm
          formData={formData}
          setFormData={setFormData}
          step={step}
          setNextStep={setNextStep}
          setPreviousStep={setPreviousStep}
          actualStep={actualStep}
          handleSetActualStep={handleSetActualStep}
          stepList={stepList}
          token={token}
          onboardingComponents={onboardingComponents}
          saveMutationsList={saveMutationsList}
          saveCredentials={saveCredentialsMutation}
          askedSteps={askedSteps}
          setLoadingSave={setLoadingSave}
          sendCloseMutation={sendCloseMutation}
          trackClickNextEvent={trackClickNextEvent}
          trackSaveAndCompleteLaterEvent={trackSaveAndCompleteLaterEvent}
        />
      </Hidden>
    </>
  );
};

const LeftColumn = ({
  actualStep,
  handleSetActualStep,
  stepList,
  saveMutationsList,
  saveCredentials,
  askedSteps,
  token,
  sendCloseMutation,
  formData,
  setLoadingSave,
  sourceQueryObj,
  trackSaveAndCompleteLaterEvent
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const actualStepName = askedSteps[actualStep - 1];
  const saveMutation = saveMutationsList[actualStepName];
  const [sendDataMutation] = useMutation(saveMutation);

  const handleSave = async () => {
    setLoadingSave(true);
    trackSaveAndCompleteLaterEvent();
    const input = getInputForSaveMutation(actualStepName, formData);
    await sendDataMutation({
      variables: {
        token,
        input,
        options: { ignoreValidation: true, avoidIncreaseStep: true }
      }
    }).then(async () => {
      await saveCredentials(sendCloseMutation, token, true, sourceQueryObj);
    });
  };

  return (
    <>
      <div className={classes.logo}>
        <Logo height="12" width="150" />
      </div>
      <div className={classes.leftContent}>
        <Typography className={classes.leftColTitle}>
          {t("onboarding_slider_header")}
        </Typography>
        <VerticalSlider
          steps={stepList}
          actualStep={actualStep}
          handleSetActualStep={handleSetActualStep}
          overrideClasses={{
            textActive: classes.overrideText,
            circleInactive: classes.overrideCircleInactive,
            circleActive: classes.overrideCircleActive,
            mainDiv: classes.overrideMainDiv,
            stepLength: 60,
            circle: classes.overrideCircle,
            circleAlreadyOver: classes.overrideCircleAlreadyOver
          }}
        />
        <div className={classes.saveAndCompleteDiv} onClick={handleSave}>
          <SaveLogo />
          <Typography className={classes.saveAndComplete}>
            {t("onboarding_slider_save_and_later")}
          </Typography>
        </div>
      </div>
    </>
  );
};

const RightColumn = ({
  formData,
  setFormData,
  step,
  setNextStep,
  setPreviousStep,
  token,
  onboardingComponents,
  sourceQueryObj,
  trackClickNextEvent
}) => {
  const history = useHistory();
  const components = {
    [ONBOARDING_PERSONAL_INFORMATION]: PersonalInformation,
    [ONBOARDING_COMPANY_INFORMATION]: CompanyInformation,
    [ONBOARDING_TRANSPORT_INFORMATION]: TransportInformation,
    [ONBOARDING_BILLING_AND_PAYMENT_INFORMATION]: BillingAndPayment
  };

  const BO_COMPONENT_NAME = ONBOARDING_BO_COMPONENT_NAMES[step];
  const boComponent = onboardingComponents.find(
    component => component.componentName === BO_COMPONENT_NAME
  );

  if (!boComponent) {
    const nextStep = getCloserActivatedStep(onboardingComponents, step);
    if (nextStep) {
      history.push({
        pathname: nextStep,
        state: { token, sourceQueryObj }
      });
    }
  }

  const Component = components[step];
  if (!Component) {
    history.push("/onboarding");
    return null;
  }
  if (!boComponent) return null;
  return (
    <Component
      formData={formData}
      setFormData={setFormData}
      trackClickNextEvent={trackClickNextEvent}
      setNextStep={setNextStep}
      setPreviousStep={setPreviousStep}
      token={token}
      componentProperties={boComponent}
    />
  );
};

const MobileForm = ({
  formData,
  setFormData,
  step,
  setNextStep,
  setPreviousStep,
  actualStep,
  handleSetActualStep,
  stepList,
  token,
  onboardingComponents,
  saveMutationsList,
  saveCredentials,
  askedSteps,
  setLoadingSave,
  sendCloseMutation,
  sourceQueryObj,
  trackClickNextEvent,
  trackSaveAndCompleteLaterEvent
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const screen700 = useMediaQuery("(max-height: 700px) and (max-width: 768px)");
  const screen645 = useMediaQuery("(max-height: 645px) and (max-width: 768px)");

  const components = {
    [ONBOARDING_PERSONAL_INFORMATION]: PersonalInformation,
    [ONBOARDING_COMPANY_INFORMATION]: CompanyInformation,
    [ONBOARDING_TRANSPORT_INFORMATION]: TransportInformation,
    [ONBOARDING_BILLING_AND_PAYMENT_INFORMATION]: BillingAndPayment
  };

  const BO_COMPONENT_NAME = ONBOARDING_BO_COMPONENT_NAMES[step];
  const boComponent = onboardingComponents.find(
    component => component.componentName === BO_COMPONENT_NAME
  );

  if (!boComponent) {
    const nextStep = getCloserActivatedStep(onboardingComponents, step);
    if (nextStep) {
      history.push({
        pathname: nextStep,
        state: { token, sourceQueryObj }
      });
    }
  }

  const Component = components[step];
  if (!Component) {
    history.push("/onboarding");
    return null;
  }

  const actualStepName = askedSteps[actualStep - 1];
  const saveMutation = saveMutationsList[actualStepName];
  const [sendDataMutation] = useMutation(saveMutation);

  const handleSave = async () => {
    setLoadingSave(true);
    trackSaveAndCompleteLaterEvent();
    const input = getInputForSaveMutation(actualStepName, formData);
    await sendDataMutation({
      variables: {
        token,
        input,
        options: { ignoreValidation: true, avoidIncreaseStep: true }
      }
    }).then(async () => {
      await saveCredentials(sendCloseMutation, token, true, sourceQueryObj);
    });
  };

  const removeDivSpace =
    (screen700 &&
      actualStepName === ONBOARDING_BILLING_AND_PAYMENT_INFORMATION) ||
    screen645;

  if (!boComponent) return null;

  return (
    <>
      <LeftMenu
        actualStep={actualStep}
        handleSetActualStep={handleSetActualStep}
        stepList={stepList}
      />
      <div style={{ overflowY: "auto", height: "100vh" }}>
        <Component
          formData={formData}
          setFormData={setFormData}
          trackClickNextEvent={trackClickNextEvent}
          setNextStep={setNextStep}
          setPreviousStep={setPreviousStep}
          token={token}
          componentProperties={boComponent}
        />
        <div
          className={`${classes.saveAndCompleteDiv} ${
            removeDivSpace ? classes.removeSpace : ""
          }`}
          onClick={handleSave}
        >
          <SaveLogoDark />
          <Typography className={classes.saveAndComplete}>
            {t("onboarding_slider_save_and_later")}
          </Typography>
        </div>
      </div>
    </>
  );
};

const getCloserActivatedStep = (onboardingComponents, step) => {
  let found = false;
  let nextStep = REVERSE_ONBOARDING_STEPS[ONBOARDING_STEPS[step] + 1];
  if (onboardingComponents.length === 0) return null;
  while (!found) {
    const component = onboardingComponents.find(
      component =>
        component.componentName === ONBOARDING_BO_COMPONENT_NAMES[nextStep]
    );
    if (component) {
      found = true;
    } else {
      nextStep = REVERSE_ONBOARDING_STEPS[ONBOARDING_STEPS[nextStep] + 1];
    }
  }
  return nextStep;
};

const checkSameAddress = (companyInformation, transportInformation) => {
  if (!companyInformation || !transportInformation) return false;
  return (
    companyInformation.companyName === transportInformation.companyName &&
    companyInformation.streetAddress === transportInformation.streetAddress &&
    companyInformation.zipCode === transportInformation.zipCode &&
    companyInformation.city === transportInformation.city &&
    companyInformation.country === transportInformation.country
  );
};

export default Form;
