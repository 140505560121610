import React, { useState } from "react";

import { isSameDay, addYears, addMonths, setYear } from "date-fns";

import Menu from "../RangePicker/components/Menu";

const DateSinglePicker = props => {
  const today = new Date();

  const markers = {
    first: "first"
  };

  const { onChange, initialDate, minDate = 0, disableWeekends } = props;
  console.log(initialDate);
  const minDateValid = addYears(today, -1);
  const maxDateValid = addYears(today, 5);

  const [dateRange, setDateRange] = useState({
    startDate: initialDate,
    endDate: initialDate
  });
  const [hoverDay, setHoverDay] = useState();

  const { startDate, endDate } = dateRange;

  const [firstMonth, setFirstMonth] = useState(startDate ? startDate : today);

  const onDayClick = day => {
    setDateRange({ startDate: day, endDate: day });
    onChange(day);
    setHoverDay(day);
  };

  const onMonthNavigate = (marker, action) => {
    const firstNew = addMonths(firstMonth, action);

    setFirstMonth(firstNew);
  };
  const onYearNavigate = (marker, action) => {
    const firstNew = setYear(firstMonth, action);
    setFirstMonth(firstNew);
  };

  const onDayHover = date => {
    if (startDate && !endDate) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date);
      }
    }
  };

  const inHoverRange = day => false;

  const helpers = {
    inHoverRange
  };

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
    onYearNavigate
  };

  return (
    <>
      <Menu
        singleDate={true}
        dateRange={dateRange}
        minDate={minDateValid}
        maxDate={maxDateValid}
        firstMonth={firstMonth}
        secondMonth={firstMonth}
        helpers={helpers}
        handlers={handlers}
        markers={markers}
        disableWeekends={disableWeekends}
      />
    </>
  );
};

export default DateSinglePicker;
