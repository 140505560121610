import React from "react";
import { withTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

const styles = theme => ({
  button: {
    margin: "0 auto",
    color: "theme.palette.primary.main",
    boxShadow: "none!important",
    display: "block",
    height: "40px",
    background: theme.palette.secondary.main,
    fontSize: "14px",
    width: "100%",
    marginTop: "10px",
    "&:hover": {
      background: theme.palette.secondary.main
    }
  },
  orange: {
    background: "#F0A60E;",
    "&:hover": {
      background: "#F0A60E"
    }
  }
});

class LoginButton extends React.Component {
  render() {
    const {
      classes,
      t,
      text,
      type,
      color,
      onClick,
      variant,
      extraClasses,
      ...rest
    } = this.props;

    return (
      <Button
        disabled={this.props.disabled === true}
        variant={variant}
        color={color}
        type={type}
        onClick={onClick}
        {...rest}
      >
        {t(text)}
      </Button>
    );
  }
}

export default withStyles(styles)(withTranslation()(LoginButton));
