import gql from "graphql-tag";

export default gql`
  mutation OnboardingPersonalInformationMutation(
    $token: String!
    $input: OnboardingPersonalInformationInput!
    $options: OnboardingMutationOptions
  ) {
    OnboardingPersonalInformationMutation(
      token: $token
      input: $input
      options: $options
    ) {
      success
    }
  }
`;
