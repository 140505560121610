import gql from "graphql-tag";

export default gql`
  query getPartnerPricesForDay(
    $token: String!
    $partnerId: String
    $date: String
  ) {
    getPartnerPricesForDay(token: $token, partnerId: $partnerId, date: $date) {
      date
      longDate
      priceData {
        productId
        rules {
          start
          price
        }
      }
      dateUpdated
    }
  }
`;
