import React from "react";
import { makeStyles, Typography, IconButton, Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { MoreVert } from "@material-ui/icons";
import {
  getTitle,
  renderPriceValue
} from "../../../../products/card/ProductUtils";
import useStandardLocalization from "../../../../../hooks/useStandardLocalization";

import {
  ProductTitleAndValue,
  ImageContainer,
  getProductImageUrl,
  getQuantityString
} from "../../../../products/card/ProductUtils";

const useStyles = makeStyles(theme => ({
  productCard: {
    boxSizing: "border-box",
    width: "380px",
    minHeight: "152px",
    background: "#F9F9F9",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    padding: "19px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  productCardWrapper: {
    display: "flex",
    gap: "16px"
  },

  imageContainer: {
    height: "auto",
    [theme.breakpoints.down("sm")]: {
      height: "100px",
      display: "flex",
      position: "relative",
      overflow: "hidden",
      "& > img": {
        width: "100%",
        height: "100%",
        objectFit: "cover"
      }
    }
  },

  imagePlaceholder: {
    background: "lightgrey",
    width: 100,
    height: 112
  },
  productInfoContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
    width: "100%"
  },
  productNameContainer: {
    display: "flex",
    width: "100%",

    alignItems: "center"
  },
  productNamesContainer: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "12px"
    }
  },
  productName: {
    margin: "0 8px 0 0 ",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)"
  },

  productCardRow: {
    display: "flex",
    gap: "20px"
  },
  productImageContainer: {
    display: "flex",
    "& img": {
      objectFit: "cover"
    }
  },
  expandedButtonContainer: {
    marginTop: "5px"
  },
  productContainer: {
    display: "flex",
    flexDirection: "column",
    position: "relative",
    padding: 20,
    background: "#F9F9F9",
    border: "1px solid #E0E0E0",
    borderRadius: 4
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "16px 0 12px",
    alignItems: "center",
    "& h6": {
      letterSpacing: 0.25
    }
  },
  descriptionContainer: {
    display: "flex",
    flexWrap: "nowrap",
    gap: 16
  },
  subtitle: {
    color: "rgba(0, 0, 0, 0.6)",
    margin: 0,
    paddingTop: 8,
    fontSize: 12,
    letterSpacing: 0.4,
    lineHeight: "16px"
  }
}));

const MaterialCardInModal = ({ item, previewUrl, openUploadDialog }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  if (isMobile) {
    if (previewUrl) {
      return <MobileMaterialCard item={item} previewUrl={previewUrl} />;
    }

    return (
      <MobileEditMaterialPhotoCard
        item={item}
        openUploadDialog={openUploadDialog}
      />
    );
  }

  const imageHeight = 112;
  const imageUrl = getProductImageUrl(item, 100, imageHeight);

  return (
    <div className={classes.productCard}>
      <div className={classes.productCardWrapper}>
        <div className={classes.productImageContainer}>
          <ImageContainer
            item={item}
            classes={classes}
            imageResize={imageHeight}
            imageUrl={previewUrl || imageUrl}
            style={{}}
            avoidEditing={true}
          />
        </div>
        <div className={classes.productInfoContainer}>
          <div className={classes.productNameContainer}>
            <div className={classes.productNamesContainer}>
              <h5 className={classes.productName}>
                {item.aliasForLanguage || item.alias}
              </h5>
            </div>
          </div>

          <div className={classes.productCardRow}>
            <ProductTitleAndValue
              title={
                item.statusId === 1
                  ? t("Declared Quantity (mt)")
                  : t("Declared Quantity (kg)")
              }
              classes={classes}
            >
              {getQuantityString(item)}
            </ProductTitleAndValue>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileEditMaterialPhotoCard = ({ item, openUploadDialog }) => {
  const classes = useStyles();

  const imageUrl = getProductImageUrl(item, 340, 340);

  return (
    <div className={classes.productCard}>
      <div className={classes.productNameContainer}>
        <div className={classes.productNamesContainer}>
          <h5 className={classes.productName}>
            {item.aliasForLanguage || item.alias}
          </h5>
        </div>
      </div>
      <div className={classes.productCardWrapper} onClick={openUploadDialog}>
        {item.image ? (
          <img
            style={{ width: "100%" }}
            src={imageUrl}
            alt={item.image.productName}
          />
        ) : (
          <div className={classes.imagePlaceholder} />
        )}
      </div>
    </div>
  );
};

const MobileMaterialCard = ({ item, previewUrl }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { parseDate, parseCurrency } = useStandardLocalization();

  return (
    <div className={classes.productContainer}>
      <div className={classes.imageContainer}>
        <img src={previewUrl} alt={item?.image?.productName} />
      </div>

      <div className={classes.headerContainer}>
        <Typography variant="h6">
          {item.aliasForLanguage || item.alias}
        </Typography>

        <IconButton
          size="medium"
          aria-controls="menu"
          style={{ paddingTop: 0, paddingBottom: 0 }}
        >
          <MoreVert />
        </IconButton>
      </div>

      <Grid container className={classes.descriptionContainer}>
        <Grid item xs={6}>
          <Typography variant="body2" className={classes.subtitle}>
            {getTitle(t, item)}
          </Typography>
          {getQuantityString(item)}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" className={classes.subtitle}>
            {t("Price")}
          </Typography>
          {renderPriceValue(item, { parseCurrency })}
        </Grid>
      </Grid>

      <Grid container className={classes.descriptionContainer}>
        <Grid item xs={6}>
          <Typography variant="body2" className={classes.subtitle}>
            {t("Incoterms")}
          </Typography>
          {item.incoterms || "-"}
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2" className={classes.subtitle}>
            {t("Price Validity Date")}
          </Typography>
          {(item.validTo && parseDate(item.validTo)) || "-"}
        </Grid>
      </Grid>
    </div>
  );
};

export default MaterialCardInModal;
