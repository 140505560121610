import gql from "graphql-tag";

export const defaults = {
  selectedCompany: "all"
};

export const resolvers = {
  Mutation: {
    setCompany: (_, { selectedCompany }, { cache }) => {
      cache.writeData({ data: { selectedCompany } });
      return selectedCompany;
    }
  }
};

export const SET_COMPANY = gql`
  mutation setCompany($selectedCompany: String!) {
    setCompany(selectedCompany: $selectedCompany) @client
  }
`;

export const GET_SELECTED_COMPANY = gql`
  {
    selectedCompany @client
  }
`;
