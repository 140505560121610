import { makeStyles } from "@material-ui/core/styles";
import React, { useState } from "react";
import { Button, Hidden, Typography } from "@material-ui/core";
import SummaryCard from "../../components/onboarding/SummaryCard";
import { ReactComponent as Account } from "../../assets/icons/account.svg";
import { ReactComponent as Domain } from "../../assets/icons/domain.svg";
import { ReactComponent as EurSymbol } from "../../assets/icons/blackEurSymbol.svg";
import { ReactComponent as Truck } from "../../assets/icons/blackTruck.svg";
import { COUNTRY_LIST } from "../../utils/collections/countries";
import { roleOptions } from "../../utils/constants";
import {
  ONBOARDING_PERSONAL_INFORMATION,
  ONBOARDING_COMPANY_INFORMATION,
  ONBOARDING_TRANSPORT_INFORMATION,
  ONBOARDING_BILLING_AND_PAYMENT_INFORMATION
} from "../../utils/constants";
import TwoColumnsModule from "./TwoColumnsModule";
import MobileTwoRowsModule from "./MobileTwoRowsModule";
import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { useMutation } from "react-apollo";
import OnboardingLoginMutation from "../../mutations/OnboardingLoginMutation";
import { saveCredentialsMutation } from "../../utils/Onboarding";
import useEventTracking from "../../hooks/useEventTracking";

const useStyles = makeStyles(theme => ({
  body: {
    overflowY: "auto",
    maxHeight: "842px"
  },
  rightColumnDiv: { margin: "90px" },
  rightColumnHeader: {
    fontSize: "64px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "-0.5px",
    color: "white",
    marginBottom: "12px"
  },
  rightColumnSubHeader: {
    width: "420px",
    fontSize: "20px",
    color: "white",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "28px"
  },
  cardSpacing: {
    marginBottom: "20px"
  },
  buttonDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    width: "480px",
    marginTop: "20px"
  },
  buttonLeft: {
    marginRight: "auto",
    marginBottom: "20px",
    padding: "16 12"
  },
  buttonRight: {
    marginLeft: "auto",
    marginBottom: "20px",
    padding: "16 12"
  },
  title: {
    margin: "28px 0 8px",
    fontSize: "32px",
    fontWeight: "500",
    lineHeight: "40px",
    fontStyle: "normal"
  },
  sectionWrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: "100px",
    height: "100vh",

    "@media (max-width: 400px)": {
      padding: "48px 0 24px"
    }
  },
  [theme.breakpoints.down("sm")]: {
    title: {
      margin: "20px 0 20px",
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "28px"
    },
    buttonDiv: {
      width: "calc(100vw - 30px)",
      marginTop: "15px"
    },
    buttonDivSurrounding: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%"
    },
    cardSpacing: {
      maxWidth: "calc(100vw - 30px)"
    },
    body: {
      overflowY: "initial",
      maxHeight: "initial"
    },
    buttonLeft: {
      marginRight: "auto",
      marginBottom: "15px",
      padding: "16 12"
    },
    buttonRight: {
      marginLeft: "auto",
      marginBottom: "15px",
      padding: "16 12"
    }
  }
}));

const Congratulations = ({
  formData,
  setPreviousStep,
  token,
  askedSteps,
  sourceQueryObj = {}
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { TrackEvent } = useEventTracking();
  const [sendCloseMutation] = useMutation(OnboardingLoginMutation);
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (Object.keys(formData).length < 1)
    window.location.href = `/onboarding/${ONBOARDING_PERSONAL_INFORMATION}`;

  let countryName = null;
  if (formData.country) {
    const country = COUNTRY_LIST.find(
      country => country.value === formData.country
    );
    countryName = country ? country.label : null;
  }
  let transportCountryName = null;
  if (formData.transportCountry) {
    const country = COUNTRY_LIST.find(
      country => country.value === formData.transportCountry
    );
    transportCountryName = country ? country.label : null;
  }

  let roleName = null;
  if (formData.companyRole)
    roleName = roleOptions.find(
      role => role.value === formData.companyRole
    ).name;

  const onSubmit = () => {
    TrackEvent("KYC Flow - Congratulations - Clicks finish");
    setIsSubmitting(true);
    handleToDashboard();
  };

  const handlePreviousStep = () => {
    TrackEvent("KYC Flow - Congratulations - Clicks back");
    setPreviousStep();
  };

  const summarys = [
    askedSteps.includes(ONBOARDING_PERSONAL_INFORMATION) && {
      logo: <Account />,
      title: t("onboarding_slider_step_personal"),
      header: `${formData.firstName} ${formData.lastName}`,
      subheader: `${t(roleName)}`,
      textFields: [
        { text: `${formData.email}` },
        formData.phone ? { text: `${formData.phone}` } : {},
        formData.mobile ? { text: `${formData.mobile}` } : {}
      ]
    },
    askedSteps.includes(ONBOARDING_COMPANY_INFORMATION) && {
      logo: <Domain />,
      title: t("onboarding_slider_step_company"),
      header: `${formData.companyName}`,
      textFields: [
        { text: `${formData.streetAddress}` },
        {
          text: `${formData.zipCode ? formData.zipCode : ""} ${
            formData.city
          }, ${countryName}`
        }
      ]
    },
    askedSteps.includes(ONBOARDING_TRANSPORT_INFORMATION) && {
      logo: <Truck />,
      title: t("onboarding_slider_step_transport"),
      header: t("Pick up address"),
      textFields: [
        { text: `${formData.transportStreetAndNumber}` },
        {
          text: `${
            formData.transportZipCode ? formData.transportZipCode : ""
          } ${formData.transportCity}, ${transportCountryName}`
        }
      ]
    },
    askedSteps.includes(ONBOARDING_BILLING_AND_PAYMENT_INFORMATION) && {
      logo: <EurSymbol />,
      title: t("onboarding_slider_step_billing"),
      header: t("onboarding_congratulations_bank_info"),
      textFields: [
        {
          text: t("onboarding_congratulations_vat_desc", { vat: formData.vat })
        },
        { text: t("onboarding_congratulations_iban", { iban: formData.iban }) }
      ]
    }
  ];
  const headers = {
    leftColumnHeaderText: t("onboarding_congratulations_info_sum"),
    rightColumnHeaderText: t("onboarding_congratulations_header"),
    rightColumnSubHeaderText: t("onboarding_congratulations_subheader")
  };

  const handleToDashboard = async () => {
    await saveCredentialsMutation(
      sendCloseMutation,
      token,
      false,
      sourceQueryObj
    );
  };

  return (
    <>
      <Hidden smDown>
        <TwoColumnsModule
          headers={headers}
          hideFooter={true}
          logoOptions={{ height: "14px", width: "175px" }}
          overrideClasses={{
            title: classes.title,
            sectionWrapper: classes.sectionWrapper
          }}
        >
          <div className={classes.body}>
            {summarys.map(
              (summary, index) =>
                summary && (
                  <div className={classes.cardSpacing}>
                    <SummaryCard key={index} {...summary} />
                  </div>
                )
            )}
          </div>
          <div className={classes.buttonDiv}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.buttonLeft}
              onClick={() => {
                handlePreviousStep();
              }}
            >
              {t("Back")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonRight}
              onClick={() => {
                onSubmit();
              }}
              disabled={isSubmitting}
            >
              {t("onboarding_congratulations_to_dashboard")}
            </Button>
          </div>
        </TwoColumnsModule>
      </Hidden>
      <Hidden mdUp>
        <MobileVersion
          formData={formData}
          headers={headers}
          handlePreviousStep={handlePreviousStep}
          summarys={summarys}
          onSubmit={onSubmit}
          isSubmitting={isSubmitting}
        />
      </Hidden>
    </>
  );
};

const MobileVersion = ({
  headers,
  summarys,
  handlePreviousStep,
  onSubmit,
  isSubmitting
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <MobileTwoRowsModule
        headerText={headers.rightColumnHeaderText}
        subHeaderText={headers.rightColumnSubHeaderText}
        hideFooter={true}
        fullScroll={true}
        Buttons={
          <MobileButtonsDiv
            handlePreviousStep={handlePreviousStep}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
          />
        }
        logoOptions={{ height: "10px", width: "125px" }}
      >
        <Typography className={classes.title}>
          {headers.leftColumnHeaderText}
        </Typography>
        <div className={classes.body}>
          {summarys.map(
            (summary, index) =>
              summary && (
                <div className={classes.cardSpacing}>
                  <SummaryCard key={index} {...summary} />
                </div>
              )
          )}
        </div>
      </MobileTwoRowsModule>
    </>
  );
};

const MobileButtonsDiv = ({ handlePreviousStep, onSubmit, isSubmitting }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.buttonDivSurrounding}>
      <div className={classes.buttonDiv}>
        <Button
          variant="outlined"
          color="primary"
          className={classes.buttonLeft}
          onClick={() => {
            handlePreviousStep();
          }}
        >
          {t("Back")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonRight}
          onClick={onSubmit}
          disabled={isSubmitting}
        >
          {t("onboarding_congratulations_to_dashboard")}
        </Button>
      </div>
    </div>
  );
};

export default Congratulations;
