import React from "react";
import {
  makeStyles,
  createStyles,
  Grid,
  Typography,
  Divider
} from "@material-ui/core";
import classnames from "classnames";
import AttachmentOutlinedIcon from "@material-ui/icons/AttachmentOutlined";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useDocPreview from "../../../hooks/useDocPreview";
import useStandardLocalization from "../../../hooks/useStandardLocalization";
const offerItemStyles = makeStyles(
  createStyles(theme => ({
    container: {
      paddingTop: "32px",
      fontWeight: "normal",
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.4px",
      height: "100%",
      flexWrap: "nowrap",
      [theme.breakpoints.down("md")]: {
        paddingTop: "24px"
      }
    },
    headProduct: {
      paddingTop: "2px",
      paddingRight: "20px"
    },
    circle: {
      height: "24px",
      width: "24px",
      backgroundColor: "#52F597",
      color: "#151124",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
      borderRadius: "24px",
      marginRight: "8px"
    },
    headProductText: {
      color: "rgba(0, 0, 0, 0.87)",
      gap: 40,
      lineHeight: "24px",
      letterSpacing: 0.25,
      [theme.breakpoints.down("md")]: {
        gap: 24
      }
    },
    productInfo: {
      padding: "0 20px"
    },
    divider: {
      height: "100%",
      marginLeft: "11px",
      backgroundColor: "#B9B8BD",
      transform: "translateY(10px)"
    },
    leftColumn: {
      width: 60,
      flexDirection: "column",
      padding: "0 12px 0 24px",
      [theme.breakpoints.down("md")]: {
        padding: "0 8px 0 16px",
        width: 48
      }
    },
    rightColumn: {
      flexDirection: "column",
      borderBottom: "1px solid #EEEEEE"
    },
    borderBottom: {
      borderBottom: "1px solid #EEEEEE"
    },
    bodyProduct: {
      flexDirection: "column",
      padding: "24px 24px 34px 0",
      display: "block",
      [theme.breakpoints.down("md")]: {
        padding: "24px 16px 0 0"
      }
    },
    imagesContainer: {
      minWidth: "168px",
      maxWidth: "168px",
      height: "fit-content",
      padding: "8px",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "4px"
    },
    imagesContainerMobile: {
      width: "100%",
      height: "fit-content",
      padding: "12px",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      borderRadius: "4px",
      flexDirection: "column"
    },
    image: {
      width: "46px",
      height: "46px",
      background: "#EEEEEE",
      overflow: "hidden",
      borderRadius: 4,
      "& > img": {
        cursor: "pointer",
        width: "46px",
        height: "46px",
        maxWidth: "46px",
        maxHeight: "46px",
        objectFit: "cover"
      }
    },
    imageMobile: {
      width: "100%",
      paddingTop: "100%",
      background: "#EEEEEE",
      position: "relative",
      overflow: "hidden",
      borderRadius: 4,
      "& > img": {
        cursor: "pointer",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
      }
    },
    productName: {
      marginTop: "8px",
      color: "rgba(0, 0, 0, 0.87)",
      width: "100%"
    },
    productNameMobile: {
      marginTop: "12px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    imageNumber: {
      color: "rgba(0, 0, 0, 0.6)",
      fontWeight: 400
    },
    productProperty: {
      color: "rgba(0, 0, 0, 0.6)",
      minWidth: "160px"
    },
    productValue: {
      paddingLeft: "16px",
      color: "rgba(0, 0, 0, 0.87)",
      textTransform: "capitalize",
      overflowWrap: "anywhere"
    },
    productSummary: {
      marginBottom: 8
    },
    productHeader: {
      marginBottom: 4,
      color: "rgba(0, 0, 0, 0.87)"
    },
    marginTop28: {
      marginTop: "28px"
    },
    marginTop24: {
      marginTop: "24px"
    },
    marginTop16: {
      marginTop: "16px"
    },
    marginBottom8: {
      marginBottom: "8px"
    },
    propertiesTitle: {
      marginBottom: 4,
      [theme.breakpoints.down("md")]: {
        marginTop: 16
      }
    },
    materialPropertiesContainer: {
      maxWidth: 400
    },
    attachmentIcon: {
      height: "24px",
      transform: "translateY(-3px)",
      marginRight: "8px"
    },
    black87: {
      color: "rgba(0, 0, 0, 0.87)"
    },
    preserveWhiteSpaces: {
      whiteSpace: "pre-line",
      lineHeight: "20px",
      letterSpacing: 0.25
    },
    materialContainer: {
      display: "flex",
      gap: 20,
      marginTop: 16
    }
  }))
);

const MATERIAL_PROPERTIES = [
  { label: "Material category", value: "productCategories" },
  { label: "Material product", value: "productName" },
  { label: "Material number", value: "" },
  { label: "Quantity", value: "quantity" },
  { label: "Date available", value: "materialDateAvailable" },
  { label: "Loading capabilities", value: "" },
  { label: "Material stored in", value: "materialStorageType" },
  { label: "Transport types", value: "transportType" },
  { label: "Incoterms", value: "incoterms" }
];

const OfferItem = ({ product, index, last }) => {
  const classes = offerItemStyles();

  return (
    <>
      <Grid container item xs className={classes.container}>
        <Grid
          container
          item
          className={classnames(
            classes.leftColumn,
            last ? classes.borderBottom : null
          )}
        >
          <Grid item>
            <div className={classes.circle}>{index}</div>
          </Grid>
          <Grid item xs>
            {!last && (
              <Divider orientation="vertical" className={classes.divider} />
            )}
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs
          direction="column"
          className={classes.rightColumn}
        >
          <HeadProductRow product={product} />
          <Grid container item xs className={classes.bodyProduct}>
            <BodyProductColumns product={product} />
            <div className={classes.materialContainer}>
              {product.traderComment && (
                <MaterialProperties product={product} />
              )}
              <ImageProductBox product={product} />
            </div>
            {product.documents && product.documents.length > 0 && (
              <MaterialDocuments product={product} />
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const OfferItemMobile = ({ product, index, last, children }) => {
  const classes = offerItemStyles();

  return (
    <>
      <Grid container item xs className={classes.container}>
        <Grid container item className={classes.leftColumn}>
          <Grid item>
            <div className={classes.circle}>{index}</div>
          </Grid>
          <Grid item xs>
            {!last && (
              <Divider orientation="vertical" className={classes.divider} />
            )}
          </Grid>
        </Grid>
        <Grid container item xs direction="column">
          <HeadProductRow product={product} />
          <Grid container item xs className={classes.bodyProduct}>
            <Grid container item>
              <ImageProductBoxMobile product={product} />
            </Grid>
            <Grid container item className={classes.marginTop24}>
              <BodyProductColumns product={product} />
            </Grid>
            {product.traderComment && <MaterialProperties product={product} />}
            {product.documents.length > 0 && (
              <MaterialDocuments product={product} />
            )}
            {children}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const HeadProductRow = ({ product }) => {
  const classes = offerItemStyles();
  const { t } = useTranslation();
  const { parseNumber } = useStandardLocalization();
  return (
    <Grid container item className={classes.headProduct}>
      <Grid container item xs className={classes.headProductText}>
        <Grid item>
          <Typography variant="subtitle1">{product.productName}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            {parseNumber(product.quantity)} t
          </Typography>
        </Grid>
        <Grid item style={{ width: "88" }}>
          <Typography variant="subtitle1">
            {product.isRepeating
              ? t("Regular") + " - " + t(product.repeatingCicle)
              : t("Spot")}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const getUnits = label => {
  if (label === "quantity") {
    return " (t)";
  }
  return null;
};

const BodyProductColumns = ({ product }) => {
  const classes = offerItemStyles();
  const { t } = useTranslation();
  const { parseDate, parseNumber } = useStandardLocalization();

  return (
    <Grid container item xs direction="column">
      <Grid item>
        <Typography variant="subtitle2" className={classes.productHeader}>
          {t("Summary")}
        </Typography>
      </Grid>
      {MATERIAL_PROPERTIES.map(mp => (
        <Grid
          container
          item
          wrap="nowrap"
          className={classes.productSummary}
          key={mp.value}
        >
          <Grid item className={classes.productProperty}>
            <Typography variant="body2">
              {t(mp.label)}
              {getUnits(mp.value)}
            </Typography>
          </Grid>
          <Grid item xs className={classes.productValue}>
            <Typography variant="body2">
              {getVariable(product, mp.value, t, { parseDate, parseNumber }) ||
                "N/A"}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const ImageProductBox = ({ product }) => {
  const classes = offerItemStyles();
  const { t } = useTranslation();
  const { setDocument } = useDocPreview();

  const images = product.images || [];

  return (
    <Grid container item className={classes.imagesContainer}>
      <Grid container item wrap="nowrap" justify="space-between">
        {[0, 1, 2].map(i => (
          <div className={classes.image} key={i}>
            {images[i] && (
              <img
                src={images[i].src}
                alt={images[i].filename}
                onClick={() => {
                  const image = images[i];
                  setDocument({
                    fileType: "image/" + image.filename.split(".")?.slice(-1),
                    downloadLink: image.src,
                    documentName: image.filename
                  });
                }}
              />
            )}
          </div>
        ))}
      </Grid>
      <Typography variant="subtitle2" className={classes.productName}>
        {product.productName}
      </Typography>
      <Typography variant="caption" className={classes.imageNumber}>
        {(product.images || []).length} {t("images")}
      </Typography>
    </Grid>
  );
};

const ImageProductBoxMobile = ({ product }) => {
  const classes = offerItemStyles();
  const { t } = useTranslation();
  const { setDocument } = useDocPreview();

  const images = product.images || [];

  return (
    <Grid container item className={classes.imagesContainerMobile}>
      <Grid container item spacing={2}>
        {[0, 1, 2].map(i => (
          <Grid item xs={4} key={i}>
            <div className={classes.imageMobile}>
              {images[i] && (
                <img
                  src={images[i].src}
                  alt={images[i].filename}
                  onClick={() => {
                    const image = images[i];
                    setDocument({
                      fileType: "image/" + image.filename.split(".")?.slice(-1),
                      downloadLink: image.src,
                      documentName: image.filename
                    });
                  }}
                />
              )}
            </div>
          </Grid>
        ))}
      </Grid>
      <Typography variant="subtitle2" className={classes.productNameMobile}>
        {product.productName}
      </Typography>
      <Typography variant="caption" className={classes.imageNumber}>
        {(product.images || []).length} {t("images")}
      </Typography>
    </Grid>
  );
};

const MaterialProperties = ({ product }) => {
  const classes = offerItemStyles();
  const { t } = useTranslation();

  return (
    <Grid
      container
      item
      direction="column"
      className={classes.materialPropertiesContainer}
    >
      <Grid item className={classes.propertiesTitle}>
        <Typography variant="subtitle2" className={classes.black87}>
          {t("Material properties")}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body2" className={classes.preserveWhiteSpaces}>
          {product.traderComment || "-"}
        </Typography>
      </Grid>
    </Grid>
  );
};

const MaterialDocuments = ({ product }) => {
  const classes = offerItemStyles();
  const { t } = useTranslation();
  const { setDocument } = useDocPreview();

  const documents = product.documents;

  return (
    <Grid container item direction="column" className={classes.marginTop24}>
      <Grid item className={classes.marginBottom8}>
        <Typography variant="subtitle2" className={classes.black87}>
          {t("Supporting documentation")}
        </Typography>
      </Grid>
      {documents.map(d => (
        <Grid container item wrap="nowrap">
          <Grid item>
            <AttachmentOutlinedIcon className={classes.attachmentIcon} />
          </Grid>
          <Grid item>
            <Typography variant="body2">
              <Link
                onClick={event => {
                  event.preventDefault();
                  const document = d;
                  setDocument({
                    fileType: "application/pdf",
                    downloadLink: document.src,
                    documentName: document.filename
                  });
                }}
                style={{ color: "#276EF1", textDecoration: "none" }}
              >
                {d.filename}{" "}
              </Link>
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default OfferItem;

const getVariable = (data, variable, t, standarizationMethods) => {
  const { parseDate, parseNumber } = standarizationMethods;
  variable = variable.split(".");

  if (data[variable]) {
    if (variable[0] === "materialDateAvailable") {
      const dateStr = data[variable[0]];

      const [day, month, year] = dateStr.split("-");
      const formattedDateStr = `${year}-${month}-${day}`;
      const date = new Date(formattedDateStr);

      const DateString =
        parseDate(date).toString() === "Invalid Date"
          ? "N/A"
          : parseDate(date).toString();

      return DateString;
    } else if (variable[0] === "quantity") {
      return parseNumber(data[variable]);
    } else if (Array.isArray(data[variable]) && data[variable].length > 0) {
      return data[variable][0];
    } else if (
      variable[0] === "materialStorageType" ||
      variable[0] === "transportType"
    ) {
      return t(data[variable]);
    }
    return data[variable];
  }
  return undefined;
};
