import gql from "graphql-tag";

export const GET_NOTIFICATIONS_PREFERENCES = gql`
  query GetNotificationsPreferencesForUser($token: String!, $partnerId: ID!) {
    GetNotificationsPreferencesForUser(token: $token, partnerId: $partnerId) {
      notificationPreferences
      requiredAndRestricted
      reasonsOfRequiredAndRestricted
      subtopics
    }
  }
`;

export const SET_NOTIFICATIONS_PREFERENCES = gql`
  mutation setNotificationsPreferencesForUser(
    $token: String!
    $partnerId: ID!
    $preferences: Object!
  ) {
    setNotificationsPreferencesForUser(
      token: $token
      partnerId: $partnerId
      preferences: $preferences
    ) {
      success
    }
  }
`;
