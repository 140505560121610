import { makeStyles, Button } from "@material-ui/core";
import { useState, useContext } from "react";
import { useQuery } from "react-apollo";

import { useTranslation } from "react-i18next";

import Loader from "../../Loader";
import PairingTool from "../components/PairingTool";
import { contracts_table_headers } from "../utils/constants";
import UserContext from "../../../../../providers/UserProvider";
import getPartnerImportedContracts from "../../../../../queries/getPartnerImportedContracts";

const useStyles = makeStyles(theme => ({
  root: {
    width: "1000px",
    padding: "8px 24px",
    "& > .MuiDialogContentText-root": {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "20px"
      },
      paddingBottom: "20px"
    },
    "& > .MuiDialogActions-root": {
      paddingBottom: "20px"
    }
  },
  buttonClass: {
    marginTop: "16px",
    marginRight: "96px",
    float: "right"
  }
}));

export default ({ setNextStepAction, fileContent, setFileContent, title }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [analized, setAnalized] = useState(false);
  const [contracts, setContracts] = useState({});
  const [objectPaired, setObjectPaired] = useState({});
  const [transformedContracts, setTransformedContracts] = useState([]);

  const {
    selectedCompany,
    user: { token }
  } = useContext(UserContext);
  if (!analized) {
    const newContracts = {};
    //We select the rows with no contract id
    fileContent.map(item => {
      if (
        item.contract_id === null ||
        item.contract_id === "" ||
        item.contract_id === undefined
      )
        newContracts[item.order_id] = null;
    });
    if (Object.keys(newContracts).length === 0) {
      setNextStepAction();
      return null;
    }
    setContracts(newContracts);
    setObjectPaired(newContracts);
    setAnalized(true);
  }

  const canContinue = () => {
    if (Object.keys(contracts).length !== Object.keys(objectPaired).length)
      return false;
    for (let contract in contracts) {
      if (
        !(objectPaired[contract] && objectPaired[contract] !== "null") ||
        (objectPaired[contract] &&
          objectPaired[contract].suggestion &&
          !objectPaired[contract].suggestionAccepted)
      )
        return false;
    }
    return true;
  };

  const continueNextStep = () => {
    const newFileContent = fileContent.map(item => ({
      ...item,
      ...(objectPaired[item.order_id]
        ? { contract_id: objectPaired[item.order_id] }
        : {})
    }));
    setFileContent(newFileContent);
    setNextStepAction();
  };

  const { data, loading } = useQuery(getPartnerImportedContracts, {
    variables: {
      token: token,
      partnerId: selectedCompany
    },
    fetchPolicy: "network-only"
  });

  if (!loading && data && transformedContracts.length === 0) {
    const newContracts = data.getPartnerImportedContracts;
    const transformedContracts = newContracts.map(contract => {
      const name = contract.contract_name
        ? contract.contract_name
        : `${contract.contract_id} | ${contract.site_name}/${
            contract.supplier_name
          }, ${
            contract.material_name?.length > 35
              ? contract.material_name.substring(0, 35) + "..."
              : contract.material_name
          }`;
      return {
        value: contract.contract_id,
        name
      };
    });

    setTransformedContracts(transformedContracts);
  }

  return (
    <>
      {title}
      <div className={classes.root}>
        {analized && !loading ? (
          <PairingTool
            columnHeaders={contracts_table_headers}
            rows={contracts}
            options={transformedContracts}
            objectPaired={objectPaired}
            setObjectPaired={setObjectPaired}
            type={"contracts"}
          />
        ) : (
          <Loader />
        )}
        <Button
          color="secondary"
          classes={{ root: classes.buttonRoot }}
          variant="contained"
          onClick={() => {
            continueNextStep();
          }}
          className={classes.buttonClass}
        >
          {t("import_dialog_continue")}
        </Button>
      </div>
    </>
  );
};
