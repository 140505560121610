import React from "react";

import { Tooltip, Typography, makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  title: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": "500",
    "font-size": "14px",
    "line-height": "20px",
    color: "#FFF",
    marginBottom: "4px"
  },
  text: {
    "font-family": "Roboto",
    "font-style": "normal",
    "font-weight": "400",
    "font-size": "12px",
    "line-height": "16px",
    "letter-spacing": "0.12px",
    color: "#FFF"
  },
  tooltip: {
    background: "#151124",
    maxWidth: 264,
    padding: "0px",
    paddingTop: "12px !important",
    paddingBottom: "12px !important",
    paddingLeft: "16px !important",
    paddingRight: "16px !important"
  },
  arrow: {
    color: "#151124",
    fontSize: "large"
  }
}));

const StyledTooltip = ({ header, text, placement, children, style }) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={
        text ? (
          <React.Fragment>
            {header && (
              <Typography color="inherit" className={classes.title}>
                {header}
              </Typography>
            )}
            <Typography color="inherit" className={classes.text}>
              {text}
            </Typography>
          </React.Fragment>
        ) : (
          ""
        )
      }
      arrow
      placement={placement || "bottom"}
      classes={{ tooltip: classes.tooltip, arrow: classes.arrow, ...style }}
    >
      {children}
    </Tooltip>
  );
};

export default StyledTooltip;
