/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import LoginButton from "../../components/login/button/LoginButton";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from "react-i18next";
import "url-search-params-polyfill";
import LoginInput from "../login/LoginInput";
import Error from "../login/Error";

const styles = theme => ({
  grid: {
    width: "100%",
    marginTop: "0"
  },
  error: {
    color: "#E11900",
    display: "block",
    fontWeight: "normal",
    marginTop: "12px"
  },
  success: {
    color: `${theme.palette.primary["500"]} !important`
  },
  button: {
    width: "100%",
    marginTop: "20px"
  },
  actionsGrid: {
    width: "100%",
    display: "flex",
    marginTop: "0",
    justifyContent: "space-between"
  },
  form: {
    width: "400px",
    margin: "0 auto",

    "@media (max-width: 400px)": {
      width: "100%",

      padding: "0 16px"
    }
  },
  textField: {
    height: "40px",
    marginBottom: "10px",
    marginTop: "7px",
    "& .MuiInputBase-input": {
      height: "40px",
      backgroundColor: "white!important",
      paddingTop: "0",
      paddingBottom: "0"
    },

    "& .MuiFormLabel-root.Mui-error": {
      color: "#E11900"
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #E11900"
    },

    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)"
    },

    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 12px) scale(1)"
    }
  }
});

const ForgotForm = props => {
  const { classes, handleSubmit, forgotErrorMessage, t, change, email } = props;
  const [showError, setShowError] = useState(true);
  const [error, setError] = useState(0);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get("email");
    if (email) change("email", email);
  }, []);

  useEffect(() => {
    setError(forgotErrorMessage);
  }, [forgotErrorMessage]);
  const onClick = event => {
    setShowError(true);
  };
  const onChange = event => {
    setShowError(false);
  };
  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid container>
        <Grid item className={classes.grid}>
          <Field
            name="email"
            type="text"
            label={t("Email")}
            value={email}
            component={LoginInput}
            classes={classes}
            t={t}
            onChange={onChange}
            flagAsError={forgotErrorMessage.length !== 0}
          />
        </Grid>
        <Grid item className={classes.grid}>
          {error && showError ? (
            <Typography
              align="left"
              variant="caption"
              className={classes.error}
              gutterBottom
            >
              {t(error)}
            </Typography>
          ) : (
            <Field name="email" component={Error} alternativeError={true} />
          )}
          <LoginButton
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            text={t("request email")}
            onClick={onClick}
          />
        </Grid>
      </Grid>
    </form>
  );
};
const isItValidEmail = function (email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
};
const validate = values => {
  const errors = {};

  if (!isItValidEmail(values["email"])) {
    errors["email"] = "Please enter a valid email address";
  }

  if (!values["email"] || !values["email"].length) {
    errors["email"] = "Please enter your email";
  }

  return errors;
};

ForgotForm.defaultProps = {
  classes: {}
};

ForgotForm.propTypes = {
  classes: PropTypes.object,
  handleSubmit: PropTypes.func.isRequired,
  forgotErrorMessage: PropTypes.string.isRequired
};

const ForgotFormRedux = reduxForm({
  form: "forgotForm",
  validate
})(withStyles(styles)(withTranslation()(ForgotForm)));

const selector = formValueSelector("forgotForm");

export default connect(state => ({ email: selector(state, "email") }))(
  ForgotFormRedux
);
