import { SnackbarContent } from "../components/general/Snackbar/Snackbar";
import { useSnackbar } from "notistack";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

export const useStackableNotifications = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const pushNotification = (
    message,
    {
      color = "primary",
      fixed = false,
      anchorOrigin = { horizontal: "center", vertical: "bottom" },
      autoHideDuration,
      hideCloseAction = false
    } = {},
    action = []
  ) =>
    enqueueSnackbar(message, {
      anchorOrigin,
      autoHideDuration,
      content: (key, message) => (
        <SnackbarContent
          anchorPoint
          color={color}
          fixed={fixed}
          message={message}
          action={[
            ...action,
            ...(!hideCloseAction
              ? [
                  <IconButton onClick={() => closeSnackbar(key)}>
                    <Close />
                  </IconButton>
                ]
              : [])
          ]}
        />
      )
    });

  return { pushNotification };
};
