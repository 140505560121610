import gql from "graphql-tag";

export default gql`
  query getSimpleCategories {
    Categories {
      _id
      id
      name
      parent
    }
  }
`;
