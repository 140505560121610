import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Container from "../../mainContainer/Container";
import RightContent from "../../mainContainer/RightContent";
import StepImage from "../../stepImages/StepImage";
import Weight from "../components/Weight";
import { ReactComponent as ManWithConstruction } from "../../../../assets/images/man_with_construction.svg";

const styles = theme => ({
  stepImage: {
    margin: "13%"
  }
});

class WeightSelectScreen extends React.Component {
  render() {
    const { classes, weightRanges, setWeight, setStep } = this.props;
    return (
      <>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={6}>
              <StepImage
                src={<ManWithConstruction className={classes.stepImage} />}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RightContent>
                <Weight
                  onSubmit={setWeight}
                  data={weightRanges}
                  onNext={() => setStep(2)}
                  onBack={() => setStep(0)}
                />
              </RightContent>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }
}

export default withStyles(styles)(WeightSelectScreen);
