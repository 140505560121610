import React from "react";

export default React.createContext({
  token: null,
  company: null,
  type: null,
  offset: null,
  searchText: null,
  limit: null,
  startDate: null,
  endDate: null
});
