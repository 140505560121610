import React, { useState } from "react";
import WeightRanges from "./WeightRanges";
import ReferencePrice from "./ReferencePrice";
import { loadTutorial } from "../../../utils/PubSub";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    width: "100%",
    height: "240px",
    borderTop: "1px solid rgba(0, 0, 0, 0.12)"
  },
  referencePrice: { width: "234px" }
}));

const PriceArea = props => {
  const classes = useStyles();
  const [lockedOn, setLockedOn] = useState("discount");

  const changeLock = type => {
    if (type === lockedOn) {
      if (type === "discount") {
        setLockedOn("price");
      } else {
        setLockedOn("discount");
      }
    } else setLockedOn(type);
  };

  const {
    weightData,
    referencePrice,
    isEditing,
    justPrices,
    onChangeItem,
    onChangeReference,
    onWeightCategoryChange,
    isTutorial
  } = props;

  return (
    <div className={classes.container}>
      <WeightRanges
        justPrices={justPrices}
        weightData={weightData}
        isEditing={isEditing}
        onChange={onChangeItem}
        isTutorial={isTutorial}
        onWeightCategoryChange={onWeightCategoryChange}
        changeLock={changeLock}
        lockedOn={lockedOn}
      />
      {justPrices ? null : (
        <div id="reference-price-1" className={classes.referencePrice}>
          <ReferencePrice
            price={referencePrice}
            onChange={onChangeReference}
            lockedOn={lockedOn}
          />
          {loadTutorial()}
        </div>
      )}
    </div>
  );
};

export default PriceArea;
