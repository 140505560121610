import React, { useEffect } from "react";
import GetTableauTokenQuery from "../../queries/GetTableauTokenQuery";
import { makeStyles, Typography } from "@material-ui/core";
import { useQuery } from "react-apollo";
import Loader from "../../components/core/shared/Loader";
import { isDemoEnviroment as _isDemoEnviroment } from "../../config/config";

const useStyles = makeStyles(theme => ({
  flexContainer: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      marginTop: 48
    }
  },
  pageTitle: {
    flexGrow: 1,
    fontSize: 32
  },
  titleWrapper: {
    display: "flex",
    alignItems: "center",
    padding: "28px 32px",
    borderBottom: "1px solid #EEEEEE"
  },
  lookerContainer: {
    height: "100%",
    "& > iframe": {
      width: "100%",
      height: "100%"
    }
  }
}));

const urlIds = {
  32: "Main",
  33: "PriceAnalysis",
  34: "Map"
};

function useTableauScript() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://public.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js";
    script.type = "module"; // Ensure it is loaded as an ES module
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up the script on unmount
    };
  }, []);
}

const TableauDashboard = ({ user, title, dashboardId, selectedCompany }) => {
  const classes = useStyles();
  const isDemoEnviroment = _isDemoEnviroment();
  useTableauScript();

  const { data, loading, error } = useQuery(GetTableauTokenQuery, {
    variables: { token: user.token, partnerId: selectedCompany },
    fetchPolicy: "cache-and-network",
    pollInterval: 8 * 60 * 1000 //Token expirancy
  });

  if (error) {
    return "Error generating Tableau token";
  }

  const token = data?.GetTableauTokenQuery;
  const urlId = urlIds[dashboardId];

  const company_value = isDemoEnviroment
    ? "Embedded-Demo_17314253893100"
    : "enterprise_light";

  const src = `https://dub01.online.tableau.com/t/metaloop-embedded/views/${company_value}/${urlId}`;

  return (
    <>
      <div className={classes.flexContainer}>
        <div className={classes.titleWrapper}>
          <Typography variant="h4" className={classes.pageTitle}>
            {title}
          </Typography>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <tableau-viz
            id="tableauViz"
            src={src}
            toolbar="hidden"
            hide-tabs
            token={token}
          >
            {!isDemoEnviroment && (
              <viz-filter field="EnterpriseId" value={selectedCompany}>
                {" "}
              </viz-filter>
            )}
          </tableau-viz>
        )}
      </div>
    </>
  );
};

export default TableauDashboard;
