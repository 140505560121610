import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { ReactComponent as CloseIcon } from "../../../../../assets/icons/close.svg";
import { ReactComponent as ArrowLeftIcon } from "../../../../../assets/icons/arrow_left.svg";
import { ReactComponent as ArrowRightIcon } from "../../../../../assets/icons/arrow_right.svg";
import Button from "@material-ui/core/Button";
import CategoryProductLegendMobile from "./CategoryProductLegendMobile";
import CategoryProductPriceMobile from "./CategoryProductPriceMobile";
import FullLoadUpdate from "../../../PriceArea/FullLoadUpdate";
import FormControl from "@material-ui/core/FormControl";
import CustomList from "../../../../../components/general/List";

const useStyles = makeStyles(theme => ({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    background: "white",
    zIndex: "1000"
  },
  header: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "56px"
  },
  closeIcon: {
    marginLeft: "16px",
    marginRight: "16px"
  },
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  content: {
    paddingLeft: "16px",
    paddingRight: "16px",
    display: "flex",
    flexDirection: "column"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  legendButton: {
    width: "90px",
    height: "40px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFeatureSettings: "'liga' off",
    marginTop: "24px",
    marginBottom: "16px"
  },
  saveButton: {
    width: "90px",
    height: "36px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFeatureSettings: "'liga' off",
    marginTop: "24px",
    marginBottom: "16px"
  },
  footer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "16px",
    paddingRight: "16px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFeatureSettings: "'liga' off",
    color: "rgba(0, 0, 0, 0.6)",
    height: "37px",
    width: "100%",
    marginTop: "24px",
    position: "absolute",
    bottom: "10px"
  },
  ruleIndicator: {
    display: "flex"
  },
  currentRule: {
    width: "8px",
    height: "8px",
    background: "#52F597",
    marginRight: "8px",
    borderRadius: "50%"
  },
  rule: {
    width: "8px",
    height: "8px",
    background: "rgba(0, 0, 0, 0.38)",
    marginRight: "8px",
    borderRadius: "50%"
  },
  iconLeft: {
    marginRight: "20px"
  },
  iconRight: {
    marginLeft: "20px"
  },
  selectWrapper: {
    marginTop: "8px",
    marginBottom: "24px"
  },
  selectLabel: {
    background: "#ffffff",
    paddingLeft: "5px",
    paddingRight: "5px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "16px",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.6)",
    mixBlendMode: "normal",
    transform: "translate(14px, -6px) !important"
  },
  select: {
    width: "100%",
    height: "40px",
    marginBottom: "20px"
  }
}));

const CategoryProductPricesMobile = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    product,
    setShowPrices,
    changeReference,
    save,
    changeItem,
    removeOrAddWeightRange,
    changeLink
  } = props;
  const [showLegend, setShowLegend] = useState(false);
  const [ruleIndex, setRuleIndex] = useState(0);
  const [showFullLoad, setShowFullLoad] = useState(false);
  const [lockedOn, setLockedOn] = useState("discount");
  const [priceState, setPriceState] = useState(null);

  const closePrices = () => {
    setShowPrices(false);
  };

  const openLegend = () => {
    setShowLegend(true);
  };

  const back = () => {
    if (ruleIndex > 0) {
      setRuleIndex(ruleIndex - 1);
    }
  };

  const next = () => {
    if (ruleIndex < product.rules.length - 1) {
      setRuleIndex(ruleIndex + 1);
    }
  };

  const changeFullLoad = price => {
    setPriceState(price);
  };
  const categoryLinkOptions = [
    { value: "nolink", label: "No link" },
    { value: "lme", label: "LME Index" }
  ];
  const getCategoryLinkObject = categoryLink =>
    categoryLinkOptions.find(o => o.value === categoryLink);
  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <CloseIcon onClick={closePrices} className={classes.closeIcon} />
        <span className={classes.title}>{product.productName}</span>
      </div>
      <div className={classes.content}>
        <div className={classes.buttonContainer}>
          <Button
            color="primary"
            variant="outlined"
            onClick={openLegend}
            className={classes.legendButton}
          >
            {t("Legend")}
          </Button>
          {product.isDirty ? (
            <Button
              color="primary"
              variant="contained"
              onClick={() => save(product.id)}
              className={classes.saveButton}
            >
              {t("save")}
            </Button>
          ) : null}
        </div>

        <FormControl variant="outlined" className={classes.selectWrapper}>
          <CustomList
            data={categoryLinkOptions}
            placeholder={t("Category linked to")}
            onChange={value => {
              changeLink(product.id, value[0].value);
            }}
            defaultSelected={[
              getCategoryLinkObject((product && product.linkedTo) || "nolink")
            ]}
          />
        </FormControl>
        <CategoryProductPriceMobile
          product={product}
          index={ruleIndex}
          setShowFullLoad={setShowFullLoad}
          changeFullLoad={changeFullLoad}
          changeItem={changeItem}
          removeOrAddWeightRange={removeOrAddWeightRange}
          locked={lockedOn}
          priceState={priceState}
        />
      </div>
      <div className={classes.footer}>
        <div className={classes.previous} onClick={back}>
          <ArrowLeftIcon className={classes.iconLeft} />
          {t("Back")}
        </div>
        <div className={classes.ruleIndicator}>
          {product.rules.map((r, index) =>
            index === ruleIndex ? (
              <div className={classes.currentRule} key={index}></div>
            ) : (
              <div className={classes.rule} key={index}></div>
            )
          )}
        </div>
        <div className={classes.next} onClick={next}>
          {t("Next")}
          <ArrowRightIcon className={classes.iconRight} />
        </div>
      </div>
      {showLegend ? (
        <CategoryProductLegendMobile setShowLegend={setShowLegend} />
      ) : null}
      {showFullLoad ? (
        <FullLoadUpdate
          close={() => setShowFullLoad(false)}
          onChange={locked => {
            setLockedOn(locked);
            changeReference(product.id, priceState, locked);
          }}
        />
      ) : null}
    </div>
  );
};

export default CategoryProductPricesMobile;
