import React from "react";
import TransactionsContainer from "../../containers/transactions/Transactions";
import UserContext from "../../providers/UserProvider";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

const getSearchParams = location => {
  const searchParams = new URLSearchParams(location.search);

  return {
    type: location.pathname.split("/")[1] === "sales" ? "sale" : "purchase",
    status:
      (searchParams.get("status") === "complete"
        ? "closed"
        : searchParams.get("status")) ||
      (location.pathname.split("/")[1] === "sales" ? "all" : "open"),
    date: searchParams.get("date") || "all",
    startDate: searchParams.get("startDate") || null,
    endDate: searchParams.get("endDate") || null,
    product: searchParams.get("product") || "all",
    transactionId: searchParams.get("transactionId") || null,
    transaction: searchParams.get("transaction") || "all",
    statusId: searchParams.get("statusId") || null,
    transport: searchParams.get("transport") || "all",
    localization: searchParams.get("location") || "all",
    uploadInitialType: searchParams.get("upload") || null
  };
};

const checkStatus = (location, history) => {
  let query = queryString.parse(location.search);
  const searchParams = new URLSearchParams(query);

  if (searchParams.get("status")) return;

  const case_status =
    location.pathname.split("/")[1] === "sales" ? "all" : "open";
  searchParams.set("status", case_status);
  history.push({
    pathname: location.pathname,
    search: searchParams.toString()
  });
};

const TransactionsPage = props => {
  const location = useLocation();
  const history = useHistory();

  checkStatus(location, history);
  const {
    type,
    status,
    date,
    startDate,
    endDate,
    product,
    transactionId,
    transaction,
    statusId,
    transport,
    localization,
    uploadInitialType
  } = getSearchParams(location);

  const arrayedLocation = localization.split(",");
  return (
    <UserContext.Consumer>
      {({ user, selectedCompany, userData: { UserCompanies: userC } }) => (
        <TransactionsContainer
          {...props}
          user={user}
          userCompanies={userC}
          selectedCompany={selectedCompany}
          queryType={status}
          transactionType={type}
          date={date}
          startDate={startDate}
          endDate={endDate}
          product={product}
          localization={arrayedLocation}
          searchText={transactionId}
          transaction={transaction}
          statusId={statusId}
          transport={transport}
          uploadInitialType={transactionId ? uploadInitialType : null}
        />
      )}
    </UserContext.Consumer>
  );
};

TransactionsPage.defaultProps = {
  queryType: "open"
};

export default TransactionsPage;
