import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { PasswordAndNameCard } from "./card/PasswordAndNameCard";
import LanguageCard from "./card/LangaugeCard";
import BasicInformationCard from "./card/BasicInformationCard";
import useEventTracking from "../../../hooks/useEventTracking";
import { useTranslation } from "react-i18next";
import PricingRoles from "./pricingRoles";
import { isBuyerAccount } from "../../../utils/SharedUtils";

const InformationCards = props => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      gap: 20
    },
    checkboxContainer: {
      display: "flex",
      alignItems: "center"
    }
  }));

  const classes = useStyles();
  const { t } = useTranslation();
  const { userData, token, company, userCompanies } = props;
  const [mode, onSetMode] = useState("DEFAULT");
  const { TrackEvent } = useEventTracking();

  const isBuyer = isBuyerAccount(company, userCompanies);

  useEffect(() => {
    if (mode !== "DEFAULT") {
      TrackEvent("Edit account started", {
        accountId: company,
        section: "My profile",
        mode
      });
    }
  }, [mode]);

  const setMode = (newMode, isSuccess = "false") => {
    if (newMode === "DEFAULT") {
      if (isSuccess) {
        TrackEvent("Edit account completed", {
          section: "My profile",
          accountId: company,
          mode
        });
      } else {
        TrackEvent("Edit account cancelled", {
          accountId: company,
          section: "My profile",
          mode
        });
      }
    }
    onSetMode(newMode);
  };

  return (
    <div className={classes.container}>
      <BasicInformationCard
        token={token}
        company={company}
        userData={userData}
        mode={mode}
        onSetMode={setMode}
      />
      <PasswordAndNameCard
        token={token}
        company={company}
        userData={userData}
        mode={mode}
        onSetMode={setMode}
      />
      <LanguageCard
        token={token}
        company={company}
        userData={userData}
        mode={mode}
        onSetMode={setMode}
      />
      {mode === "DEFAULT" && isBuyer && (
        <PricingRoles token={token} company={company} userData={userData} />
      )}
    </div>
  );
};

export default InformationCards;
