import {
  Button,
  DialogActions,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
  DialogContentText
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  Clear as ClearIcon,
  InsertDriveFileOutlined
} from "@material-ui/icons";

import classNames from "classnames";
import CustomDropzone from "../../uploadDialog/components/Dropzone";
import AddIcon from "@material-ui/icons/Add";
import { useCallback, useRef, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import InformationalComponent from "../../../../general/InformationalComponent";
import CompareMaterialImages from "../components/CompareMaterialImages";
import Loader from "../../Loader";
import MaterialCardInModal from "../components/MaterialCardInModal";
import { useNotifications } from "../../../../../hooks/Notifications";

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  root: {
    [theme.breakpoints.up("md")]: {
      width: "840px"
    },
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px",
      paddingTop: "0px"
    }
  },
  headerContainer: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      minHeight: "56px",
      display: "flex",
      alignItems: "center"
    }
  },
  headerRoot: {
    width: "100%",
    paddingTop: "48px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "8px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      padding: "0",
      position: "fixed",
      background: "white"
    },
    "&.advanced": {
      paddingTop: "48px",
      paddingBottom: "0"
    },
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "10px",
        padding: "0"
      },
      display: "flex",
      gap: "10px"
    },
    "& .MuiIconButton-root": {
      [theme.breakpoints.up("md")]: {
        right: "1px"
      },
      [theme.breakpoints.down("sm")]: {
        left: "1px",
        position: "initial"
      },
      position: "absolute",
      top: "1px"
    }
  },
  addIcon: {
    marginRight: "4px"
  },
  dropzoneContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "42px 240px 42px 240px",
    background: "rgba(21, 17, 36, 0.05)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: "8px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "16px",
    lineHeight: "43px",
    letterSpacing: ".25px",
    color: "#151124",
    marginTop: "8px",
    marginBottom: "12px"
  },

  dropzoneContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: "8px",
    "& p": {
      margin: 0,
      lineHeight: "24px",
      fontWeight: "400",
      letterSpacing: "0.25px",
      "& > a": {
        color: "#276EF1",
        textDecoration: "none"
      }
    }
  },
  actionButtons: {
    paddingTop: "16px !important",
    [theme.breakpoints.down("sm")]: {
      background: "white",
      zIndex: "9",
      position: "fixed",
      bottom: "0",
      right: "0",
      left: "0",
      boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.12)",
      paddingTop: "24px!important"
    }
  },
  subHeader: {
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px",
      paddingRight: "16px"
    },
    paddingLeft: "24px",
    paddingRight: "24px"
  },
  subHeaderText: {
    color: "rgba(0, 0, 0, 0.60)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px" /* 142.857% */,
    letterSpacing: "0.25px"
  },
  informationalComponent: {
    [theme.breakpoints.down("sm")]: {
      margin: "12px 16px 20px 16px"
    },
    margin: "8px 24px 12px 24px",
    maxWidth: "792px"
  },
  uploadPhotoText: {
    color: "#151124",
    textAlign: "center",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Roboto",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px", // 150%
    letterSpacing: "0.25px",
    textDecorationLine: "underline",
    cursor: "pointer"
  },
  bottomText: {
    color: "rgba(0, 0, 0, 0.60)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px", // 142.857%
    letterSpacing: "0.25px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      marginTop: "20px",
      marginBottom: "88px"
    }
  },
  hereClass: {
    color: "var(--states-more-blue, #276EF1)",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.25px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px"
    }
  },
  loaderDiv: {
    width: "840px",
    height: "606px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%"
    }
  },
  closeButton: {
    color: "#767676",
    padding: "16px"
  },
  title: {
    [theme.breakpoints.down("sm")]: {
      paddingTop: "16px !important"
    }
  }
}));

export default props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [previewUrl, setPreviewUrl] = useState(null);
  const [disableButton, setDisableButton] = useState(true);
  const { pushNotification } = useNotifications();
  const { t } = useTranslation();

  const { setFile } = props;
  const addFilesAction = file => {
    const fileTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (!fileTypes.includes(file[0].type)) {
      pushNotification(t("newMatImg_snack_notSupportedFile"), {
        color: "primary"
      });
      return;
    }
    setDisableButton(false);
    setPreviewUrl(URL.createObjectURL(file[0]));
    setFile(file);
  };
  const ownVariables = {
    previewUrl,
    disableButton,
    addFilesAction
  };
  const newProps = { ...props, ...ownVariables };

  if (isMobile) {
    return <Mobile {...newProps} />;
  } else {
    return <Desktop {...newProps} />;
  }
};

const Desktop = ({
  title,
  onClose,
  setFile,
  accept,
  item,
  onSubmit,
  loading,
  previewUrl,
  disableButton,
  addFilesAction
}) => {
  const classes = useStyles();
  const dropzoneRef = useRef();
  const { t } = useTranslation();
  const theme = useTheme();
  const openUploadDialog = useCallback(e => {
    e.preventDefault();
    dropzoneRef.current && dropzoneRef.current.open();
  }, []);

  return (
    <>
      <div className={classes.headerContainer}>
        <div className={classes.headerRoot}>
          <Typography variant="h2" gutterBottom className={classes.title}>
            {title}
          </Typography>

          <IconButton
            aria-label="delete"
            onClick={onClose}
            className={classes.closeButton}
          >
            <ClearIcon />
          </IconButton>
        </div>
        <div className={classes.subHeader}>
          <DialogContentText>{t("newMatImg_subheader")}</DialogContentText>
        </div>
      </div>
      {loading ? (
        <div className={classes.loaderDiv}>
          <Loader />
        </div>
      ) : (
        <>
          <InformationalComponent
            type="info"
            className={classes.informationalComponent}
          >
            <Typography variant={"body1"}>{t("newMatImg_warning")}</Typography>
          </InformationalComponent>
          <DialogContent className={classNames(classes.root)}>
            <CompareMaterialImages item={item} previewUrl={previewUrl} />
            <CustomDropzone
              accept={accept}
              onDrop={addFilesAction}
              ref={dropzoneRef}
            >
              <div className={classes.dropzoneContainer}>
                <div className={classes.dropzoneContent}>
                  <AddIcon className={classes.addIcon} />
                  <p>{t("newMatImg_dragHere")}</p>
                </div>
                <div
                  className={classes.dropzoneContent}
                  onClick={openUploadDialog}
                >
                  <InsertDriveFileOutlined className={classes.addIcon} />
                  <span className={classes.uploadPhotoText}>
                    {t("newMatImg_uploadPhoto")}
                  </span>
                </div>
              </div>

              <Typography className={classes.bottomText}>
                {t("newMatImg_bottomText") + " "}
                <span className={classes.hereClass} onClick={onSubmit}>
                  {t("newMatImg_here")}
                </span>
                {"."}
              </Typography>
            </CustomDropzone>
          </DialogContent>

          <DialogActions className={classes.actionButtons}>
            <Button
              color="primary"
              classes={{ root: classes.buttonRoot }}
              variant="outlined"
              onClick={() => {
                setFile(null);
                onClose();
              }}
            >
              {t("newMatImg_cancel")}
            </Button>
            <Button
              classes={{ root: classes.buttonRoot }}
              elevation={0}
              color="secondary"
              variant="contained"
              onClick={onSubmit}
              disabled={disableButton}
            >
              {t("newMatImg_saveAndExit")}
            </Button>
          </DialogActions>
        </>
      )}
    </>
  );
};

const Mobile = ({
  title,
  onClose,
  setFile,
  accept,
  item,
  onSubmit,
  loading,
  previewUrl,
  disableButton,
  addFilesAction
}) => {
  const classes = useStyles();
  const dropzoneRef = useRef();
  const { t } = useTranslation();
  const theme = useTheme();
  const openUploadDialog = useCallback(e => {
    e.preventDefault();
    dropzoneRef.current && dropzoneRef.current.open();
  }, []);

  return (
    <CustomDropzone accept={accept} onDrop={addFilesAction} ref={dropzoneRef}>
      <div className={classes.headerContainer}>
        <div className={classes.headerRoot}>
          <IconButton
            aria-label="delete"
            onClick={onClose}
            className={classes.closeButton}
          >
            <ClearIcon />
          </IconButton>
          <Typography variant="h2" gutterBottom className={classes.title}>
            {title}
          </Typography>
        </div>
      </div>
      {loading ? (
        <div className={classes.loaderDiv}>
          <Loader />
        </div>
      ) : (
        <>
          {!previewUrl && (
            <>
              <div className={classes.subHeader}>
                <DialogContentText className={classes.subHeaderText}>
                  {t("newMatImg_subheader")}
                </DialogContentText>
              </div>
              <InformationalComponent
                type="info"
                className={classes.informationalComponent}
              >
                <Typography variant={"body2"}>
                  {t("newMatImg_warning")}
                </Typography>
              </InformationalComponent>
            </>
          )}
          <DialogContent className={classNames(classes.root)}>
            <DialogContentText
              className={classes.subHeaderText}
              style={{ marginBottom: "12px" }}
            >
              {previewUrl
                ? t("newMatImg_mob_preview")
                : t("newMatImg_mob_clickBelow")}
            </DialogContentText>
            <MaterialCardInModal
              item={item}
              previewUrl={previewUrl}
              openUploadDialog={openUploadDialog}
            />
            {!previewUrl && (
              <Typography className={classes.bottomText}>
                {t("newMatImg_bottomText") + " "}
                <span className={classes.hereClass} onClick={onSubmit}>
                  {t("newMatImg_here")}
                </span>
                {"."}
              </Typography>
            )}
          </DialogContent>

          <DialogActions className={classes.actionButtons}>
            <Button
              color="primary"
              classes={{ root: classes.buttonRoot }}
              variant="outlined"
              onClick={() => {
                setFile(null);
                onClose();
              }}
            >
              {t("newMatImg_cancel")}
            </Button>
            <Button
              classes={{ root: classes.buttonRoot }}
              elevation={0}
              color="secondary"
              variant="contained"
              onClick={onSubmit}
              disabled={disableButton}
            >
              {t("newMatImg_saveAndExit")}
            </Button>
          </DialogActions>
        </>
      )}
    </CustomDropzone>
  );
};
