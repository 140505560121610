import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import But from "@material-ui/core/Button";
import Selector from "../../formElements/Selector";
import Button from "../../formElements/Button";
import { withTranslation } from "react-i18next";

const styles = theme => ({
  container: {
    margin: "25px 0"
  },
  wrapper: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
  },
  buttons: {
    marginTop: "2em",
    justifyContent: "space-evenly"
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "rgba(95,103,117,1)"
  },
  itemButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(95,103,117,1)",
    padding: "10px 5px"
  },
  text: {
    marginBottom: "2em",
    textAlign: "center",
    width: "100%"
  }
});

class Fields extends React.Component {
  constructor(props) {
    super(props);

    this.checkThis = this.checkThis.bind(this);
    this.toggleAll = this.toggleAll.bind(this);
  }

  checkThis(checked, id) {
    let { selectedFields, onSubmit } = this.props;
    if (checked && selectedFields.indexOf(id) === -1) selectedFields.push(id);

    if (!checked && selectedFields.indexOf(id) > -1)
      selectedFields.splice(selectedFields.indexOf(id), 1);

    onSubmit(selectedFields);
  }

  toggleAll() {
    let { fields, selectedFields } = this.props;
    let checked = selectedFields.length !== fields.length;
    fields.forEach(item => {
      this.checkThis(checked, item._id);
    });
  }

  render() {
    const { classes, t, fields, onSubmit, selectedFields, onNext, onBack } =
      this.props;
    const { checkThis, toggleAll } = this;

    return (
      <form
        onSubmit={ev => {
          ev.preventDefault();
          onSubmit(ev);
        }}
        className={classes.wrapper}
      >
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.item}>
            <Typography variant="subtitle1" className={classes.text}>
              {t("Fuhrpark & Equipment")}
            </Typography>
          </Grid>
          <>
            {fields.map(field => (
              <Grid
                item
                xs={12}
                sm={6}
                md={6}
                className={classes.item}
                key={field._id}
              >
                <Selector
                  data={{
                    ...field,
                    accepted: selectedFields.indexOf(field._id) > -1
                  }}
                  onChange={(ev, checked) => checkThis(checked, field._id)}
                />
              </Grid>
            ))}
          </>
          <Grid item container xs={12} justify="center">
            <But size="small" onClick={toggleAll}>
              {selectedFields.length !== fields.length
                ? t("Alle auswählen")
                : t("Alle wiederufen")}
            </But>
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={classes.buttons}
            justify="center"
          >
            <Button
              className={classes.button}
              text={"< " + t("Züruck")}
              onClick={onBack}
            />
            <Button
              className={classes.button}
              text={t("Weiter") + " >"}
              onClick={onNext}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withRouter(withStyles(styles)(withTranslation()(Fields)));
