import Step2_standard from "./Step2_Standard";
import Step2_InTwoSteps from "./Step2_InTwoSteps";
import { useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";

export default props => {
  const { type } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  if (type === "other" && isMobile) {
    return <Step2_InTwoSteps {...props} />;
  } else {
    return <Step2_standard {...props} />;
  }
};
