import { useState } from "react";
import { makeStyles, Typography } from "@material-ui/core";
import Logo from "../../components/core/LogoMobile";
import SmallHorizontalSlider from "../../components/onboarding/SmallHorizontalSlider";
import VerticalSlider from "../../components/onboarding/VerticalSlider";
import { ReactComponent as SaveLogo } from "../../assets/icons/save.svg";
import { useTranslation } from "react-i18next";
import { Close } from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
    position: "fixed",
    left: 0,
    right: 0,
    width: "100%",
    zIndex: 50,
    height: "56px",
    backgroundColor: "#151124"
  },
  logoDiv: {
    marginLeft: "auto",
    marginRight: "20px"
  },
  sliderDiv: {
    marginRight: "auto",
    marginLeft: "20px"
  },
  leftMenu: {
    backgroundColor: "#151124",
    marginTop: "56px",
    height: "100%",
    width: "320px",
    position: "fixed",
    zIndex: 99
  },
  overrideText: {
    fontSize: "16px",
    color: "white"
  },
  overrideCircleInactive: {
    color: "#151124",
    borderColor: "#95949C",
    backgroundColor: "#95949C"
  },
  overrideCircleActive: {
    color: "#151124",
    backgroundColor: "#52F597"
  },
  leftContent: {
    marginTop: "100px",
    marginLeft: "36px"
  },
  leftColTitle: {
    color: "white",
    textDecoration: "capitalize",
    fontWeight: "semibold",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "28px"
  },
  overrideMainDiv: {
    marginLeft: "5px",
    marginTop: "20px"
  },
  overrideCircle: {
    width: "20px",
    height: "20px",
    "border-radius": "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "12px",
    fontWeight: "bold"
  },
  overrideCircleAlreadyOver: {
    color: "#52F597",
    borderColor: "#52F597",
    backgroundColor: "#151124"
  },
  saveAndComplete: {
    color: "white",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    textDecoration: "underline",
    marginLeft: "12px"
  },
  saveAndCompleteDiv: {
    position: "fixed",
    bottom: "32px",
    display: "flex",
    zIndex: 100
  },
  closeIcon: {
    position: "fixed",
    top: "64px",
    left: "288px"
  }
}));

const LeftMenu = ({ actualStep, handleSetActualStep, stepList }) => {
  const classes = useStyles();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const setMenuOpen = value => () => setIsMenuOpen(value);
  return (
    <>
      <header className={classes.root}>
        <div className={classes.sliderDiv} onClick={setMenuOpen(!isMenuOpen)}>
          <SmallHorizontalSlider steps={stepList} actualStep={actualStep} />
        </div>
        <div className={classes.logoDiv}>
          <Logo />
        </div>
      </header>
      <LeftMenuOpened
        {...{
          isMenuOpen,
          actualStep,
          handleSetActualStep,
          stepList,
          setMenuOpen
        }}
      />
    </>
  );
};

const LeftMenuOpened = ({
  isMenuOpen,
  actualStep,
  handleSetActualStep,
  stepList,
  setMenuOpen
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div
      className={classes.leftMenu}
      style={{ display: isMenuOpen ? "block" : "none" }}
    >
      <div className={classes.leftContent}>
        <div className={classes.closeIcon} onClick={setMenuOpen(false)}>
          <Close style={{ color: "white", height: "24px", width: "24px" }} />
        </div>
        <Typography className={classes.leftColTitle}>
          {t("onboarding_slider_header")}
        </Typography>
        <VerticalSlider
          steps={stepList}
          actualStep={actualStep}
          handleSetActualStep={handleSetActualStep}
          overrideClasses={{
            textActive: classes.overrideText,
            circleInactive: classes.overrideCircleInactive,
            circleActive: classes.overrideCircleActive,
            mainDiv: classes.overrideMainDiv,
            stepLength: 60,
            circle: classes.overrideCircle,
            circleAlreadyOver: classes.overrideCircleAlreadyOver
          }}
        />
        <div className={classes.saveAndCompleteDiv}>
          <SaveLogo />
          <Typography className={classes.saveAndComplete}>
            {t("onboarding_slider_save_and_later")}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default LeftMenu;
