import React, { useState, useRef } from "react";
import {
  Button,
  FormControl,
  IconButton,
  makeStyles,
  TextField,
  Hidden,
  Typography
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-apollo";

import addNewAccount from "../../../../assets/icons/addNewAccount.png";
import { showError, showSuccess } from "../../../core/shared/Notify";
import { Close } from "@material-ui/icons";
import { isObjectEmpty, validateEmail } from "../../../../utils/Format";
import { GET_ACCOUNTING_EMAILS } from "../../../../queries/AccountVats";
import updateAccountingEmail from "../../../../mutations/updateAccountingEmail";
import useEventTracking from "../../../../hooks/useEventTracking";

const EditAccountingEmail = ({ token, company, cancel, type, emails }) => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      maxWidth: 400,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #9E9E9E"
      }
    },
    headerContainer: {
      display: "flex",
      marginBottom: "20px",
      [theme.breakpoints.down("sm")]: {
        padding: "16px 0",
        gap: 16,
        marginBottom: "24px"
      }
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: 0.25
    },
    btnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 23
    },
    inputText: {
      width: "100%"
    },
    addVatFlexContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "20px"
    },
    addContactContainer: {
      margin: "-12px 0 -7px 0",
      display: "flex",
      gap: 4,
      alignItems: "center",
      justifyContent: "flex-end",
      maxWidth: 480
    },
    taxIdentificationNumberWrap: {
      display: "flex",
      gap: "15px",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: "5px",
        alignItems: "flex-end"
      }
    },
    taxIdentificationNumberSpan: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      display: "flex",
      alignItems: "center",
      letterSpacing: "0.25px",
      color: "#212121",
      margin: "0"
    },
    taxIdentificationNumber: {
      flex: "1",
      maxWidth: "400px",
      [theme.breakpoints.down("sm")]: {
        width: "100%"
      }
    },
    taxIdentificationNumberBtn: {
      background: "transparent",
      backgroundColor: "transparent",
      outline: "none",
      border: "0",
      color: "#276EF1",
      cursor: "pointer"
    },
    wrapper: {
      [theme.breakpoints.down("sm")]: {
        minWidth: "100%",
        top: 0,
        left: "50%",
        transform: "translateX(-50%)",
        position: "absolute",
        maxHeight: "100%",
        overflowY: "auto",
        zIndex: 100,
        background: "#FFFFFF",
        padding: "0 16px 30px"
      }
    }
  }));

  const classes = useStyles();

  const { TrackEvent } = useEventTracking();

  const { t } = useTranslation();

  const errorBag = useRef({}); // empty error
  const [errors, setErrors] = useState(() => {
    if (!emails) {
      return {};
    }
    const errs = {};
    emails.forEach((_elem, index) => {
      errs[`email_${index}`] = {
        error: false,
        helperText: ""
      };
    });
    return errs;
  });

  const [formIsValid, setFormIsValid] = useState(true);
  const [form, setForm] = useState(() => (emails ? [...emails] : [""]));

  const [updateAccountingEmailInfo] = useMutation(updateAccountingEmail, {
    onCompleted: data => {
      TrackEvent("Saved accounting email", {
        accountId: company
      });
    },
    refetchQueries: () => [
      {
        query: GET_ACCOUNTING_EMAILS,
        variables: {
          token: token,
          company: company
        }
      }
    ]
  });

  const onChangeBlur = idx => evt => {
    const newTaxInformations = form.map((record, sidx) => {
      if (idx !== sidx) return record;
      return evt.target.value;
    });
    setForm([...newTaxInformations]);
  };

  const handleFieldValidation = (value, idx) => {
    if (!value) {
      errorBag.current[`email_${idx}`] = {
        error: true,
        helperText: t("required")
      };
    } else if (!validateEmail(value)) {
      errorBag.current[`email_${idx}`] = {
        error: true,
        helperText: t("InvalidEmailLong")
      };
    } else {
      delete errorBag.current[`email_${idx}`];
    }
  };

  const onChange = idx => evt => {
    const value = evt.target.value;
    handleFieldValidation(value, idx);
    // check for errors in errorbag
    setErrors(errorBag.current);
    setFormIsValid(isObjectEmpty(errorBag.current));
  };

  const onAddMore = () => {
    setForm([...form, ""]);
  };

  const validateForm = () => {
    form.forEach(handleFieldValidation);
    setErrors(errorBag.current);
    const isValid = isObjectEmpty(errorBag.current);
    setFormIsValid(isValid);
    return isValid;
  };

  const submitForm = event => {
    // check validation...
    const isValid = validateForm();
    if (isValid) {
      updateAccountingEmailInfo({
        variables: {
          token,
          company,
          input: {
            emails: form
          }
        }
      })
        .then(() => {
          showSuccess(t("Successfully updated"));
          cancel(true);
        })
        .catch(err => showError(err.message));
    }
  };

  return (
    <FormControl fullWidth className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.headerContainer}>
          <Hidden mdUp>
            <Close onClick={cancel} size="small" />
          </Hidden>
          <Typography className={classes.title}>
            {t("Accounting Emails")}
          </Typography>
        </div>
      </div>
      <FormControl fullWidth className={classes.addVatFlexContainer}>
        {/* for debug purpose */}
        {/* <pre>{JSON.stringify(form.arrVAT, null, 2)}</pre> */}
        {form.map((record, index) => (
          <div
            key={`${record}_${index}`}
            className={classes.taxIdentificationNumberWrap}
          >
            <TextField
              id="TaxIdentificationNumber"
              label={t("Email")}
              variant="outlined"
              name={`email_${index}`}
              defaultValue={record}
              onBlur={onChangeBlur(index)}
              onChange={onChange(index)}
              className={classes.taxIdentificationNumber}
              error={errors[`email_${index}`]?.error}
              helperText={errors[`email_${index}`]?.helperText}
            />
          </div>
        ))}
        <div className={classes.container}>
          <div className={classes.addContactContainer}>
            {t("AddEmail")}
            <IconButton onClick={onAddMore}>
              <img src={addNewAccount} alt="AddNewAccount" />
            </IconButton>
          </div>
          <div className={classes.btnContainer}>
            <Button variant="outlined" color="primary" onClick={cancel}>
              {t("Cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={!formIsValid}
              onClick={submitForm}
            >
              {t("SAVE")}
            </Button>
          </div>
        </div>
      </FormControl>
    </FormControl>
  );
};

export default EditAccountingEmail;
