import React from "react";
import { useTranslation } from "react-i18next";
import { FiberManualRecord } from "@material-ui/icons";
import { makeStyles, Button } from "@material-ui/core";
import PickUpStatus from "./PickUpStatus";
import AddressLabel from "../../general/label/address";
import { isItAndAdminLoggedInParentCompany } from "../../../hooks/useUserData";
import { Edit } from "@material-ui/icons";
import { getEnviroment } from "../../../config/config";

const useStyles = makeStyles(theme => ({
  contractedBar: {
    left: "0",
    top: "37px",
    position: "absolute",
    minWidth: "87px",
    paddingLeft: "4px",
    paddingRight: "4px",
    height: "17px",
    background: "#52F597",
    fontWeight: "500",
    fontSize: "10px",
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    color: "#151124"
  },
  imagePlaceholder: {
    background: "lightgrey",
    width: 100,
    height: 112
  },
  greyStatus: {
    color: "rgba(0, 0, 0, 0.38)",
    "& > svg": {
      color: "rgba(0, 0, 0, 0.38)",
      fontSize: "12px",
      marginRight: "8px",
      marginLeft: "12px"
    }
  },
  greenStatus: {
    color: "rgba(0, 0, 0, 0.6)",
    "& > svg": {
      color: "#4ef191",
      fontSize: "12px",
      marginRight: "8px",
      marginLeft: "12px"
    }
  },
  pickUpStatusContainer: {
    display: "flex",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    alignItems: "center",
    letterSpacing: "0.4px",
    color: "rgba(0, 0, 0, 0.38)",
    marginRight: "8px"
  },
  productNameContainer: {
    display: "flex",
    width: "100%",
    alignItems: "center"
  },
  addressContainer: {
    display: "flex",
    gap: 16
  },
  productNamesContainer: {
    display: "flex"
  },
  productName: {
    margin: "0 8px 0 0 ",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)"
  },
  productTitle: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    marginTop: "16px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  productTitleAndValueContainer: {
    width: "168px"
  },

  button: {
    width: "208px",
    marginTop: "16px"
  }
}));

export const getQuantityString = item => {
  if (item.statusId === 1) {
    return `${item.collectedQty || 0}  mt`;
  } else {
    return `${item.totalQuantity || item.quantity || "-"} mt`;
  }
};

export const getTitle = (t, item) =>
  item?.statusId === 1 ? t("ProductCard_collected") : t("Materials_Total");

export const ProductTitleAndValue = ({ title, classes, children }) => (
  <div className={classes.productTitleAndValueContainer}>
    <div className={classes.productTitle}>{title}</div>
    <div className={classes.productValue}>{children}</div>
  </div>
);

export const ProductCardButton = ({
  item,
  onEventRequest,
  classes,
  t,
  disableButton
}) => {
  const getButtonVariant = () =>
    item.statusId === 4 ? "outlined" : "contained";

  const getButtonLabel = () => {
    switch (item.statusId) {
      case 1:
        return t("Request Pickup");
      case 3:
        return t("Confirm sale");
      default:
        return t("Sale Request");
    }
  };
  // use it as placeholder to keep same number and width of columns
  if (item.statusId === 2)
    return <div className={classes.buttonPlaceholder}> </div>;

  return (
    <Button
      className={classes.button}
      variant={getButtonVariant()}
      color="primary"
      onClick={() => {
        onEventRequest();
      }}
      disabled={disableButton}
    >
      {getButtonLabel()}
    </Button>
  );
};

export const renderPickUpStatus = (statusId, statusText, classes) => {
  const pickUpStatusClass =
    statusId === 2 || statusId === 3
      ? `${classes.pickUpStatusContainer} ${classes.greenStatus}`
      : `${classes.pickUpStatusContainer} ${classes.greyStatus}`;

  return (
    <div className={pickUpStatusClass}>
      <FiberManualRecord />
      <div className={classes.statusTextContainer}>{statusText}</div>
    </div>
  );
};

const adminGroupAccountLabel = (classes, item) => (
  <div className={classes.labelContainer}>
    <AddressLabel postCode={item?.location?.location} />
  </div>
);

export const pickUpStatusText = t => ({
  2: t("Price requested"),
  3: t("Price received"),
  4: t("No sale in progress")
});

export const CardTitleRow = ({ item, classes, t }) => {
  const renderAddressLabel = () => {
    if (item.showAddressLabel && item.pickUpFullAddress.numOfAddresses > 1) {
      return (
        <div className={classes.labelContainer}>
          <AddressLabel
            postCode={item.pickUpFullAddress.zipCode}
            city={item.pickUpFullAddress.city}
          />
        </div>
      );
    }
    return null;
  };

  const pickUpStatus = pickUpStatusText(t)[item.statusId] || "";

  return (
    <div className={classes.productNameContainer}>
      <div className={classes.productNamesContainer}>
        <h5 className={classes.productName}>
          {item.aliasForLanguage || item.alias}
        </h5>
        {isItAndAdminLoggedInParentCompany() &&
          adminGroupAccountLabel(classes, item)}
        {item.statusId === 2 || item.statusId === 3
          ? renderPickUpStatus(item.statusId, pickUpStatus, classes)
          : null}
        {item.statusId === 4
          ? renderPickUpStatus(item.statusId, pickUpStatus, classes)
          : null}
      </div>
      <div className={classes.addressContainer}>
        {renderAddressLabel()}

        <div className={classes.pickUpcomponentContainer}>
          <PickUpStatus pickups={item.pickups} productId={item.old_id} />
        </div>
      </div>
    </div>
  );
};

export const ContractedLabel = ({ item }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div>
      {item.statusId === 1 && (
        <div className={classes.contractedBar}>
          <div>{t("CONTRACTED")}</div>
        </div>
      )}
    </div>
  );
};

export const ImageContainer = ({
  item,
  classes,
  imageResize,
  imageUrl,
  style,
  changeImageAction,
  avoidEditing = false,
  handleChildClick
}) => (
  <>
    {item.image ? (
      <img
        width="100"
        height={imageResize}
        src={imageUrl}
        alt={item.image.productName}
        style={{ ...style }}
      />
    ) : (
      <div className={classes.imagePlaceholder} />
    )}
    {!avoidEditing && (
      <div style={{ height: imageResize }} class={classes.editImagePlaceholder}>
        <div onClick={handleChildClick}>
          <Edit onClick={() => changeImageAction("image", item)} />
        </div>
      </div>
    )}
  </>
);

export const renderPriceValue = (item, standarizationMethods) => {
  const { parseCurrency } = standarizationMethods;
  if (item.priceFormatFixed) {
    return `${parseCurrency(item.priceFormatFixed)}/mt`;
  }

  return item.priceFormatFormula || "-";
};

export const renderEstimatedPrice = (item, standarizationMethods) => {
  const { parseCurrency } = standarizationMethods;
  if (
    item.priceFormatFormula &&
    !item.priceFormatFormula.startsWith("LME") &&
    !item.priceFormatFormula.startsWith("BDSV")
  ) {
    return "-";
  }

  try {
    return (
      (item.priceFormatFormula &&
        `${parseCurrency(
          eval(
            item.priceFormatFormula
              .replace("LME", item.lme?.price?.value || 0)
              .replace("BDSV", item.bdsv?.price || 0)
          ).toFixed(2)
        )}/mt`) ||
      (item.priceFormatFixed && `${parseCurrency(item.priceFormatFixed)}/mt`)
    );
  } catch (error) {
    return "-";
  }
};

export const renderIndexConnection = (item, standarizationMethods) => {
  const { parseDate, parseCurrency } = standarizationMethods;
  if (item.bdsv) {
    return (
      (item.bdsv.date &&
        item.statusId !== 4 &&
        item.bdsv.price &&
        `${parseDate(item.bdsv.date)}  ${parseCurrency(item.bdsv.price)}/mt`) ||
      "-"
    );
  }

  return (
    (item.lme?.date &&
      item.statusId !== 4 &&
      item.lme?.price?.value &&
      `${parseDate(item.lme.date)}  ${parseCurrency(item.lme.price.value)}/mt`) ||
    "-"
  );
};

export const getProductImageUrl = ({ image }, width = 100, height = 244) => {
  if (!image) {
    return null;
  }

  const { url, productName, imageId } = image;

  if (url) {
    const env = getEnviroment();
    if (env === "development") return url;

    return `https://img.scrap24.com/resize?width=${width}&height=${height}&url=${url}`;
  }

  return `https://images.scrap24.com/${productName}-${width}x${height}-${imageId}`;
};
