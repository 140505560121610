import React, { useState } from "react";
import OnboardingForm from "../../components/onboarding/OnboardingForm";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Switch, Typography } from "@material-ui/core";
import CityAndCountryInputs from "../../components/general/GoogleAutocompleteApi/CityAndCountryInputs";
import { useMutation } from "react-apollo";
import OnboardingTransportInformationMutation from "../../mutations/OnboardingTransportInformationMutation";
import Loader from "../../components/core/shared/Loader";
import { ONBOARDING_TRANSPORT_INFORMATION } from "../../utils/constants";
import { getInputForSaveMutation } from "../../utils/Onboarding";
import useEventTracking from "../../hooks/useEventTracking";

const pairingNames = {
  sameAsCompanyAddress: "sameCompanyToggle",
  transportCompanyName: "companyName",
  transportStreetAndNumber: "streetAddress",
  transportZipCode: "zipCode",
  transportCity: "city"
};

const reversePairingNames = {
  sameCompanyToggle: "sameAsCompanyAddress",
  companyName: "transportCompanyName",
  streetAddress: "transportStreetAndNumber",
  zipCode: "transportZipCode",
  city: "transportCity"
};

const useStyles = makeStyles(theme => ({
  inputText: {
    marginTop: "20px",
    marginBottom: "0px !important",
    width: "400px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px) !important"
    }
  },
  smallInput: {
    width: "160px !important"
  },
  switchClass: {
    marginLeft: "-12px"
  },
  switchDiv: {
    marginBottom: "4px",
    marginTop: "28px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "-8px",
      marginTop: "16px"
    }
  },
  swithLabel: {
    color: "rgba(0, 0, 0, 0.60)",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "24px",
    letterSpacing: "0.25px"
  }
}));

const TransportInformation = ({
  formData,
  setFormData,
  setNextStep,
  setPreviousStep,
  token,
  componentProperties,
  trackClickNextEvent
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();
  const [resetCityAndCountryStates, setResetCityAndCountryStates] =
    useState(false);
  const [loadingMutation, setLoadingMutation] = useState(false);

  const [sendDataMutation] = useMutation(
    OnboardingTransportInformationMutation
  );

  const header = t("onboarding_transport_header");
  const subheader = t("onboarding_transport_subheader");

  const fields = [
    {
      specialComponent: (
        <SameAsCompanyAddress
          formData={formData}
          setFormData={setFormData}
          setResetCityAndCountryStates={setResetCityAndCountryStates}
        />
      ),
      name: "sameAsCompanyAddress",
      bypassValidation: true
    },
    {
      label: t("onboarding_field_company_name"),
      type: "text",
      value: formData.transportCompanyName,
      name: "transportCompanyName",
      extraProps: {
        disabled: formData.sameAsCompanyAddress
      }
    },
    {
      label: t("onboarding_field_street_and_number"),
      type: "text",
      value: formData.transportStreetAndNumber,
      name: "transportStreetAndNumber",
      extraProps: {
        disabled: formData.sameAsCompanyAddress
      }
    },
    {
      label: t("PLZ"),
      type: "text",
      value: formData.transportZipCode,
      name: "transportZipCode",
      extraClasses: classes.smallInput,
      extraProps: {
        disabled: formData.sameAsCompanyAddress
      }
    },
    {
      specialComponent: (
        <CityAndCountryInputs
          form={{
            ...formData,
            city: formData.transportCity,
            country: formData.transportCountry
          }}
          classes={classes}
          setCityAndCountry={cityAndCountry =>
            setFormData({
              ...formData,
              ...{
                transportCity: cityAndCountry.city,
                transportCountry: cityAndCountry.country
              }
            })
          }
          errors={{}}
          disabled={formData.sameAsCompanyAddress}
          reset={resetCityAndCountryStates}
          onResetComplete={() => {
            setResetCityAndCountryStates(false);
          }}
        />
      ),
      name: "transportCity"
    }
  ];

  if (componentProperties.fields) {
    fields.forEach((field, index) => {
      if (
        !componentProperties.fields.includes(pairingNames[field.name]) &&
        !field.bypassValidation
      ) {
        fields.splice(index, 1);
      }
    });
  }

  const requiredFields = JSON.parse(JSON.stringify(componentProperties.fields))
    .filter(field => !componentProperties.optionals.includes(field))
    .map(field => reversePairingNames[field])
    .filter(field => field);

  const handleNextStep = async () => {
    trackClickNextEvent();
    setLoadingMutation(true);

    const input = getInputForSaveMutation(
      ONBOARDING_TRANSPORT_INFORMATION,
      formData
    );

    await sendDataMutation({ variables: { token, input } });
    setNextStep();
    setLoadingMutation(false);
  };

  const handlePreviousStep = () => {
    TrackEvent("KYC Flow - Transport - Clicks back");
    setPreviousStep();
  };

  if (loadingMutation) return <Loader />;

  return (
    <OnboardingForm
      formData={formData}
      setFormData={setFormData}
      header={header}
      subheader={subheader}
      fields={fields}
      requiredFields={requiredFields}
      setNextStep={handleNextStep}
      setPreviousStep={handlePreviousStep}
    />
  );
};

const SameAsCompanyAddress = ({
  formData,
  setFormData,
  setResetCityAndCountryStates
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  if (!formData) return null;
  return (
    <div className={classes.switchDiv}>
      <Switch
        defaultChecked={formData.sameAsCompanyAddress}
        color="secondary"
        className={classes.switchClass}
        checked={formData.sameAsCompanyAddress}
        onChange={e => {
          if (e.target.checked) {
            setFormData({
              ...formData,
              transportCompanyName: formData.companyName,
              transportStreetAndNumber: formData.streetAddress,
              transportZipCode: formData.zipCode,
              transportCity: formData.city,
              transportCountry: formData.country,
              sameAsCompanyAddress: true
            });
            setResetCityAndCountryStates(true);
          } else {
            setFormData({
              ...formData,
              transportCompanyName: "",
              transportStreetAndNumber: "",
              transportZipCode: "",
              transportCity: null,
              transportCountry: null,
              sameAsCompanyAddress: false
            });
            setResetCityAndCountryStates(true);
          }
        }}
      />
      <Typography className={classes.swithLabel}>
        {t("onboarding_field_same_as_comp_info")}
      </Typography>
    </div>
  );
};

export default TransportInformation;
