import React from "react";
import { withTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  subTitle: {
    width: "400px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "18px",
    lineHeight: "26px",
    textAlign: "center",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.6)",
    marginBottom: "30px",
    "@media (max-width: 400px)": {
      fontSize: "20px",
      lineHeight: "24px"
    }
  }
});

class SubTitle extends React.Component {
  render() {
    const { classes, text, t, extraClasses } = this.props;
    return (
      <Typography
        align="center"
        variant="subtitle1"
        className={`${classes.subTitle} ${extraClasses}`}
      >
        {t(text)}
      </Typography>
    );
  }
}

SubTitle.defaultProps = {
  text: ""
};

SubTitle.propTypes = {
  text: PropTypes.string
};

export default withStyles(styles)(withTranslation()(SubTitle));
