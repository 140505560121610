import {
  IconButton,
  Grid,
  Typography,
  Chip,
  makeStyles
} from "@material-ui/core";
import {
  AlternateEmail,
  Create,
  Phone,
  PhoneAndroid
} from "@material-ui/icons";
import GenericCard from "../../../general/GenericCard";
import { useTranslation } from "react-i18next";
import GetUserRoles from "../../../../queries/GetUserRoles";
import InfoEditing from "../forms/InfoEditing";
import { useQuery } from "react-apollo";
import UserAvatar from "../../../user/Avatar";
import Skeleton from "../../../core/shared/Skeleton";

const BasicInformationCard = ({
  token,
  company,
  userData,
  mode,
  onSetMode
}) => {
  const useStyles = makeStyles(theme => ({
    contentContainer: {
      padding: "4px 0"
    },
    title: {
      fontSize: 28,
      lineHeight: "36px",
      paddingBottom: 4,
      [theme.breakpoints.down("sm")]: {
        fontSize: 24,
        lineHeight: "28px"
      }
    },
    subtitle: {
      fontSize: 14,
      lineHeight: "20px",
      color: "rgba(0, 0, 0, 0.6)",
      paddingBottom: 4
    },
    chipContainer: {
      display: "flex",
      gap: 8,
      paddingTop: 12,
      flexWrap: "wrap"
    },
    contactsContainer: {
      display: "flex",
      flexDirection: "column",
      gap: 4,
      fontSize: 16,
      lineHeight: "24px",
      paddingTop: 16
    },
    contactsIcon: {
      marginRight: 12
    },
    userAvatar: {
      marginLeft: 16,
      height: "80px",
      width: "80px"
    }
  }));

  const classes = useStyles();
  const { t } = useTranslation();

  const PRICING_WITHOUT_EMAIL_ROLE = 7;

  const {
    data: userRoles,
    loading,
    error
  } = useQuery(GetUserRoles, {
    variables: {
      token: token,
      partnerId: company,
      userId: userData.RawUserData._id
    },
    fetchPolicy: "cache-and-network"
  });

  if (error) return "Error...";

  if (mode === "EDIT_BASIC_INFO") {
    return (
      <InfoEditing
        cancel={(isSuccess = false) => onSetMode("DEFAULT", isSuccess)}
        userData={userData.RawUserData}
        token={token}
        company={company}
      />
    );
  }
  if (loading || mode === "DEFAULT") {
    return (
      <GenericCard
        title={
          loading ? (
            <Skeleton
              width={"150px"}
              height={"19px"}
              active={loading}
              animation={loading}
            />
          ) : (
            t("Basic Information")
          )
        }
        menuInHeader={
          <IconButton onClick={() => onSetMode("EDIT_BASIC_INFO")}>
            <Create color="action" />
          </IconButton>
        }
        content={
          <div className={classes.contentContainer}>
            <Grid container justifyContent="space-between" wrap="nowrap">
              <Grid item>
                <Typography className={classes.title}>
                  {loading ? (
                    <Skeleton
                      width={"160px"}
                      height={"34px"}
                      active={loading}
                      animation={loading}
                    />
                  ) : (
                    userData.RawUserData.firstName +
                    " " +
                    userData.RawUserData.lastName
                  )}
                </Typography>
                {userData.RawUserData.position && !loading && (
                  <Typography className={classes.subtitle}>
                    {userData.RawUserData.position}
                  </Typography>
                )}
                <div className={classes.chipContainer}>
                  {loading ? (
                    <Skeleton
                      width={"140px"}
                      height={"20px"}
                      active={loading}
                      animation={loading}
                    />
                  ) : (
                    userRoles?.getUserRoles.map(role =>
                      role.id === PRICING_WITHOUT_EMAIL_ROLE ? null : (
                        <Chip
                          size="small"
                          key={role.label}
                          label={role.label}
                        />
                      )
                    )
                  )}
                </div>
              </Grid>
              <Grid item>
                <div className={classes.userAvatar}>
                  {" "}
                  <UserAvatar
                    src={userData.RawUserData.avatarUrl}
                    name={(
                      userData.RawUserData.firstName +
                      " " +
                      userData.RawUserData.lastName
                    ).trim()}
                    email={userData.RawUserData.email}
                    width={80}
                    height={80}
                    loading={loading}
                  />
                </div>{" "}
              </Grid>
            </Grid>
            <div className={classes.contactsContainer}>
              <Grid container alignItems="center" wrap="nowrap">
                <AlternateEmail
                  fontSize="small"
                  className={classes.contactsIcon}
                  color="action"
                />
                <Skeleton
                  width={"210px"}
                  height={"24px"}
                  active={loading}
                  animation={loading}
                >
                  {userData.RawUserData.email}{" "}
                </Skeleton>
              </Grid>
              {userData.RawUserData.phone && (
                <Grid container alignItems="center" wrap="nowrap">
                  <Phone
                    fontSize="small"
                    className={classes.contactsIcon}
                    color="action"
                  />
                  <Skeleton
                    width={"112px"}
                    height={"24px"}
                    active={loading}
                    animation={loading}
                  >
                    {userData.RawUserData.phone}
                  </Skeleton>
                </Grid>
              )}
              {userData.RawUserData.mobile && !loading && (
                <Grid container alignItems="center" wrap="nowrap">
                  <PhoneAndroid
                    fontSize="small"
                    className={classes.contactsIcon}
                    color="action"
                  />
                  {userData.RawUserData.mobile}
                </Grid>
              )}
            </div>
          </div>
        }
      />
    );
  }
  return null;
};

export default BasicInformationCard;
