import React from "react";
import { withStyles } from "@material-ui/core";
import Select, { components } from "react-select";
import SearchIcon from "@material-ui/icons/Search";

const selectStyles = {
  menu: provided => ({
    ...provided,
    zIndex: 9999
  }),
  control: (provided, state) => ({
    ...provided,
    minWidth: 240,
    margin: 0,
    height: 40,
    minHeight: 40,
    border: "1px solid #9E9E9E",
    borderRadius: "0 ",
    boxSizing: "border-box",
    "&:hover": {
      borderColor: "rgba(0, 0, 0, 0.87)",
      boxShadow: "none"
    },
    borderColor: state.isFocused ? "#52F597" : "#9E9E9E"
  }),
  indicatorsContainer: provided => ({
    ...provided,
    height: 40
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: 40,
    width: "100%",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "rgba(0, 0, 0, 0.24)"
  }),
  placeholder: provided => ({
    ...provided,
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    letterSpacing: "0.25px",
    color: "#9E9E9E"
  })
};

const styles = theme => ({
  searchIcon: {
    color: "#626262",
    height: "100%",
    padding: "8px 0 8px 16px"
  }
});
class AutoCompleteTextField extends React.Component {
  constructor(props) {
    super(props);
    this.selectRef = null;
  }
  render() {
    const { classes, onChange, options, label } = this.props;

    return (
      <Select
        name="product"
        isClearable={false}
        onChange={e => {
          onChange(e);
          this.selectRef.blur();
        }}
        options={options}
        ref={ref => {
          this.selectRef = ref;
        }}
        className={classes.selectItems}
        backspaceRemovesValue={false}
        components={{
          Control: props => (
            <components.Control {...props}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              {props.children}
            </components.Control>
          ),
          DropdownIndicator: () => null,
          IndicatorSeparator: null
        }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        tabSelectsValue={false}
        placeholder={label}
        styles={selectStyles}
        openMenuOnClick={false}
      />
    );
  }
}

export default withStyles(styles)(AutoCompleteTextField);
