import React from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import SearchMaterial from "../../../components/products/searchMaterial/";
import ProductDisplay from "../../../components/products/display";
import CompositionDisplay from "../../../components/products/display/composition";
import CheckboxWithLabel from "../../../components/core/CheckboxWithLabel";
import DragAndDrop from "../../../components/products/dragAndDrop";

import MultilineCustomTextField from "../../../components/general/MultilineCustomTextField";
const AddProductsForm = ({ state, setField, onSubmit, onClose }) => {
  const useStyles = makeStyles(theme => ({
    addMaterialContainer: {
      maxWidth: "524px",
      width: "100%",
      paddingTop: "48px",
      paddingBottom: "48px",
      paddingLeft: "32px",
      [theme.breakpoints.down("sm")]: {
        padding: "0",
        marginTop: "-28px"
      }
    },
    mobileLink: {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
        marginTop: "-10px",
        marginBottom: "-4px",
        textAlign: "right"
      }
    },
    pageTitle: {
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    titleWrapper: {
      display: "flex",
      width: "100%",
      justifyContent: "space-between",
      [theme.breakpoints.down("sm")]: { display: "none" }
    },
    link: {
      fontSize: "16px",
      lineHeight: "24px",
      color: "#276EF1",
      textDecoration: "none"
    },
    mainContentWrapper: {
      marginTop: "20px",
      marginBottom: "10px",
      display: "flex",
      flexDirection: "column",
      gap: "20px",
      [theme.breakpoints.down("sm")]: { marginBottom: "2px" }
    },
    buttonsContainer: {
      display: "flex",
      gap: "16px",
      justifyContent: "end",
      marginTop: "14px"
    }
  }));

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.addMaterialContainer}>
      <div className={classes.titleWrapper}>
        <Typography variant="h4" className={classes.pageTitle}>
          {t("Header_AddMaterial")}
        </Typography>

        <Link className={classes.link} to="/products/propose">
          {t("AddMaterial_CantFind")}
        </Link>
      </div>
      <div className={classes.mainContentWrapper}>
        <SearchMaterial
          selected={state.material}
          setSelected={value => setField("material", value)}
        />
        <Link
          className={`${classes.mobileLink} ${classes.link}`}
          to="/products/propose"
        >
          {t("AddMaterial_CantFind")}
        </Link>
        <ProductDisplay image={state.material?.image}>
          {state.material ? (
            <CompositionDisplay
              alias={state.material?.label}
              baseMetal={state.material?.baseMetalName}
              alloy={state.material?.alloyName}
              form={state.material?.formName}
              surface={state.material?.surfaceName}
            />
          ) : (
            <></>
          )}
        </ProductDisplay>
        <DragAndDrop
          files={state.files}
          setFiles={files => {
            setField("files", files);
          }}
        />
        <MultilineCustomTextField
          label="Additional Comments"
          value={state.comment}
          onChange={e => setField("comment", e.target.value)}
        />
      </div>
      <CheckboxWithLabel
        checked={state.requestSale}
        label="Products_RequestSale"
        color="secondary"
        onChange={event => setField("requestSale", event.target.checked)}
      />
      <div className={classes.buttonsContainer}>
        <Button variant="outlined" color="primary" onClick={onClose}>
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={onSubmit}
          disabled={state.material === null}
        >
          {t("Button_AddMaterial")}
        </Button>
      </div>
    </div>
  );
};
export default AddProductsForm;
