import React from "react";

import { Query } from "react-apollo";

import Hidden from "@material-ui/core/Hidden";
import {
  GET_TRANSPORT_ADDRESSES,
  SAVE_TRANSPORT_INFO,
  DELETE_COMPANY_ADDRESS,
  CHANGE_DEFAULT_ADDRESS
} from "../../queries/TransportQuery";

import { useState, useEffect } from "react";
import TransportsMobile from "../../components/account/transport/TransportsMobile";
import TransportsDesktop from "../../components/account/transport/TransportsDesktop";
import { useMutation } from "@apollo/react-hooks";

import { showError, showSuccess } from "../../components/core/shared/Notify";
import Loader from "../../components/core/shared/Loader";
import { useTranslation } from "react-i18next";

import useEventTracking from "../../hooks/useEventTracking";

const AccountContainer = ({ user, selectedCompany }) => {
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const [editAddress, setEditAddress] = useState(null);
  const [addAddress, setAddAddress] = useState(null);

  useEffect(() => {
    if (editAddress)
      TrackEvent("Transport Address Edit Started", {
        accountId: selectedCompany,
        type: editAddress.type
      });

    if (addAddress)
      TrackEvent("Transport Address Adding Started", {
        accountId: selectedCompany,
        type: addAddress.type
      });
  }, [editAddress, addAddress]);

  const [saveTransport, { loading }] = useMutation(SAVE_TRANSPORT_INFO, {
    onCompleted: () => {
      if (editAddress)
        TrackEvent("Transport Address Saved", {
          accountId: selectedCompany,
          type: editAddress.type
        });

      if (addAddress)
        TrackEvent("Transport Address Added", {
          accountId: selectedCompany,
          type: addAddress.type
        });
    },
    refetchQueries: () => [
      {
        query: GET_TRANSPORT_ADDRESSES,
        variables: {
          token: user.token,
          company: selectedCompany
        }
      }
    ]
  });

  const [deleteTransport, {}] = useMutation(DELETE_COMPANY_ADDRESS, {
    refetchQueries: () => [
      {
        query: GET_TRANSPORT_ADDRESSES,
        variables: {
          token: user.token,
          company: selectedCompany
        }
      }
    ]
  });

  const [changeDefaultAddressMutation] = useMutation(CHANGE_DEFAULT_ADDRESS, {
    onCompleted: () => {
      TrackEvent("Transport Address Default changed", {
        accountId: selectedCompany
      });
    },
    refetchQueries: () => [
      {
        query: GET_TRANSPORT_ADDRESSES,
        variables: {
          token: user.token,
          company: selectedCompany
        }
      }
    ]
  });

  const saveForm = data => {
    saveTransport({
      variables: {
        token: user.token,
        address: data,
        companyId: selectedCompany
      }
    })
      .then(() => showSuccess(t("Address saved successfully")))
      .then(() => setEditAddress(null))
      .then(() => setAddAddress(null))
      .catch(err => showError(err.message));
  };

  const deleteAddress = data => {
    deleteTransport({
      variables: {
        token: user.token,
        addressId: data._id,
        addressType: data.type,
        companyId: selectedCompany
      }
    })
      .then(() => {
        TrackEvent("Transport Address Removed", {
          accountId: selectedCompany,
          type: data.type
        });
        showSuccess(t("Address deleted successfully"));
      })
      .then(() => setEditAddress(null))
      .then(() => setAddAddress(null))
      .catch(err => {
        if (
          err.graphQLErrors[0].message === "[Error_CannotDeleteLiveAddress]"
        ) {
          showError(t("Error_CannotDeleteLiveAddress"));
        } else {
          showError(err.message);
        }
      });
  };

  const changeDefaultAddress = data => {
    changeDefaultAddressMutation({
      variables: {
        partnerId: selectedCompany,
        addressId: data._id,
        token: user.token
      }
    })
      .then(() => {
        TrackEvent("Transport Address Default Changed", {
          accountId: selectedCompany,
          type: data.type
        });
        showSuccess(t("Default address changed successfully"));
      })
      .catch(err => showError(err.message));
  };

  const cancelChange = () => {
    if (editAddress)
      TrackEvent("Transport Address editing canceled", {
        accountId: selectedCompany
      });

    if (addAddress)
      TrackEvent("Transport Address adding canceled", {
        accountId: selectedCompany
      });

    setEditAddress(null);
    setAddAddress(null);
  };

  const handleChange = (type, data) => {
    if (type === "Edit") {
      setEditAddress(data);
    }
    if (type === "Add") {
      setAddAddress(data);
    }
    if (type === "Delete") {
      deleteAddress(data);
    }
  };

  return (
    <Query
      query={GET_TRANSPORT_ADDRESSES}
      fetchPolicy={"cache-and-network"}
      notifyOnNetworkStatusChange
      variables={{
        token: user.token,
        company: selectedCompany
      }}
    >
      {({ data, loading, error }) => {
        if (error) return error.message;
        if (loading) return <Loader />;

        const defaultDelivery = data.TransportAddresses.filter(
          address => address.type === "delivery" && address.default
        );
        const defaultPickUp = data.TransportAddresses.filter(
          address => address.type === "pickup" && address.default
        );

        const listOfDeliveriy = data.TransportAddresses.filter(
          address => address.type === "delivery" && !address.default
        );
        const listOfPickUps = data.TransportAddresses.filter(
          address => address.type === "pickup" && !address.default
        );

        return (
          <>
            {" "}
            <Hidden smDown>
              <TransportsDesktop
                saveForm={saveForm}
                cancelChange={cancelChange}
                handleChange={handleChange}
                changeDefaultAddress={changeDefaultAddress}
                addAddress={addAddress}
                editAddress={editAddress}
                defaultDelivery={
                  defaultDelivery.length > 0 ? defaultDelivery[0] : null
                }
                defaultPickUp={
                  defaultPickUp.length > 0 ? defaultPickUp[0] : null
                }
                listOfDeliveriy={listOfDeliveriy}
                listOfPickUps={listOfPickUps}
                data={data}
              />
            </Hidden>
            <Hidden mdUp>
              <TransportsMobile
                saveForm={saveForm}
                cancelChange={cancelChange}
                handleChange={handleChange}
                changeDefaultAddress={changeDefaultAddress}
                addAddress={addAddress}
                editAddress={editAddress}
                defaultDelivery={
                  defaultDelivery.length > 0 ? defaultDelivery[0] : null
                }
                defaultPickUp={
                  defaultPickUp.length > 0 ? defaultPickUp[0] : null
                }
                listOfDeliveriy={listOfDeliveriy}
                listOfPickUps={listOfPickUps}
                data={data}
              />
            </Hidden>
          </>
        );
      }}
    </Query>
  );
};

export default AccountContainer;
