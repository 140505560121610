import React from "react";
import Loader from "../../../core/shared/Loader";

class WizardCompletedScreen extends React.Component {
  componentDidMount() {
    document.location =
      "https://www.schrott24.de/partner-werden/anfrage-bestaetigung/";
  }
  render() {
    return <Loader />;
  }
}

export default WizardCompletedScreen;
