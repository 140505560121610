import Dropzone from "react-dropzone";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import React, { Fragment } from "react";
import { fieldInputPropTypes, fieldMetaPropTypes } from "redux-form";
import "./formDropzone.css";
import icon2assigment from "../../../assets/icon2assigment.png";
import icon4assigment from "../../../assets/icon4assigment.png";

const styles = {
  spanError: {
    textAlign: "center",
    width: "100%",
    position: "absolute",
    color: "red"
  },
  image: {
    marginTop: "1em",
    width: "50%"
  },
  imageFixed: {
    marginTop: "1em",
    width: "50%"
  },
  content: {},
  list: {
    minHeight: "50px",
    width: "80%",
    margin: "0 auto",
    display: "flex",
    marginTop: "2em"
  },
  listRow: {
    minHeight: "50px",
    width: "80%",
    margin: "0 auto",
    display: "flex",
    marginTop: "2em",
    flexDirection: "column"
  },
  smallImage: {
    width: "100px",
    height: "100px"
  },
  icon: {
    cursor: "pointer",
    position: "absolute",
    marginLeft: "3em",
    marginTop: "-.5em"
  }
};

class FormDropzoneCustom extends React.Component {
  constructor(props) {
    super(props);

    this.onHandleFileChange = this.onHandleFileChange.bind(this);
  }

  onHandleFileChange(filesToUpload) {
    const { input } = this.props;
    const { onChange } = input;

    const newFiles = [];

    filesToUpload.forEach(f => newFiles.push(f));

    onChange(newFiles);
  }

  render() {
    const {
      step,
      meta: { error, touched },
      input: { name },
      images,
      classes,
      boldText,
      extraText
    } = this.props;

    const image = images && images[0];

    return (
      <div className="dropzone">
        {image ? (
          <div className="dropzoneCustom">
            <img src={image.url} alt={image.name} className="currentImage" />
          </div>
        ) : (
          <Fragment>
            <Dropzone
              name={name}
              onDrop={filesToUpload => this.onHandleFileChange(filesToUpload)}
              className="dropzoneCustom"
            >
              <div className={classes.content}>
                <img
                  src={step === 2 ? icon2assigment : icon4assigment}
                  alt="icon2assigment"
                  className={step === 2 ? classes.image : classes.imageFixed}
                />
                <Typography variant="body2">{boldText}</Typography>
                {extraText && (
                  <Typography variant="caption">{extraText}</Typography>
                )}
              </div>
            </Dropzone>
            {touched && error && (
              <span className={classes.spanError}>{error}</span>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

FormDropzoneCustom.defaultProps = {
  classes: {},
  extraText: ""
};

FormDropzoneCustom.propTypes = {
  step: PropTypes.number.isRequired,
  meta: PropTypes.shape(fieldMetaPropTypes).isRequired,
  input: PropTypes.shape(fieldInputPropTypes).isRequired,
  classes: PropTypes.object,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      url: PropTypes.string,
      step: PropTypes.number,
      name: PropTypes.string
    })
  ).isRequired,
  boldText: PropTypes.string.isRequired,
  extraText: PropTypes.string
};

export default withStyles(styles)(FormDropzoneCustom);
