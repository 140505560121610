import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";

const styles = theme => ({
  wrapper: {
    padding: "10px 20px 10px 20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
});

const handleStyle = {
  height: "40px",
  width: "40px",
  border: "none",
  boxShadow: "0 1px 10px rgba(0,0,0,0.3)",
  marginTop: "-15px",
  marginLeft: "-20px"
};
const trackStyle = {
  height: "10px",
  backgroundColor: "#EEA52C"
};
const railStyle = {
  height: "10px"
};
const SliderContainer = props => (
  <div className={props.classes.wrapper}>
    <Range
      {...props}
      handleStyle={[handleStyle, handleStyle]}
      trackStyle={[trackStyle]}
      railStyle={railStyle}
    />
  </div>
);

export default withStyles(styles)(SliderContainer);
