import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

import { initReactI18next } from "react-i18next";
import { getEnviroment } from "../config/config";

const env = getEnviroment();
const env_mode = env === "development" || env === "qa" || env === "staging";

let env_path = env_mode ? "development" : "live";
let addPath_path = env_mode ? "DEV" : "LIVE";
let addPath_path2 = env_mode ? "qa" : "app";

var timestamp = Date.now().toString();

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: env_mode,
    compatibilityJSON: "v3",
    fallbackLng: "en",
    saveMissing: true,
    defaultNS: "default",
    interpolation: {
      escapeValue: false // not needed for react!!
    },
    react: {
      wait: true
    },
    backend: {
      addPath: `https://${addPath_path2}.scrap24.com/i18nmissingkey_PUI_${addPath_path}`,
      referenceLng: "en",
      defaultNS: "default",
      loadPath: `https://metaloop-translation.s3.eu-central-1.amazonaws.com/${env_path}/partner_ui/{{lng}}/{{ns}}.json?t=${timestamp}`
    }
  });
export default i18n;
