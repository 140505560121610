import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import WeightRange from "../../../PriceArea/WeightRange";
import { getCurrencySymbol } from "../../../../core/shared/CurrencyFormat";
import UserContext from "../../../../../providers/UserProvider";

const useStyles = makeStyles(theme => ({
  container: {
    background: "#F9F9F9",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.24)",
    borderRadius: "4px",
    height: "310px",
    width: "100%"
  },
  header: {
    height: "72px",
    background: "#F5F5F5",
    borderRadius: "4px 4px 0px 0px",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)"
  },
  fullLoad: {},
  priceContainer: {
    display: "flex",
    padding: "20px"
  },
  legend: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  priceBox: {
    width: "84px",
    height: "34px",
    borderRadius: "8px",
    border: "1px solid #F2994A",
    margin: "0 auto",
    marginRight: "12px",
    overflow: "hidden",
    "& > div": {
      height: "100%"
    }
  },
  innerInput: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "0.001em",
    color: "#F2994A",
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  column: {
    flex: 1
  },
  columnRight: {
    width: "112px",
    maxWidth: "112px"
  },
  quantityContainer: {
    marginTop: "28px"
  },
  discountContainer: {
    marginTop: "20px"
  },
  shopContainer: {
    marginTop: "20px",
    marginBottom: "20px"
  }
}));

const CategoryProductPriceMobile = props => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    product,
    index,
    setShowFullLoad,
    changeItem,
    removeOrAddWeightRange,
    changeFullLoad,
    priceState
  } = props;
  const { rules } = product;
  const { start, accepted, discount, price, indexPrice, margin } = rules[index];

  return (
    <UserContext.Consumer>
      {context => (
        <div className={classes.container}>
          <div>
            <div className={classes.header}>
              <span className={classes.legend}>{t("Full load")}</span>

              <TextField
                value={priceState || product.referencePrice}
                variant="outlined"
                margin="dense"
                type="number"
                className={classes.priceBox}
                inputProps={{
                  className: classes.innerInput
                }}
                onBlur={() => setShowFullLoad(true)}
                onChange={ev => {
                  changeFullLoad(ev.target.value);
                }}
              />
            </div>
            <div className={classes.priceContainer}>
              <div className={classes.column}>
                <div className={classes.infoContainer}>
                  <div className={classes.acceptedContainer}>
                    <span className={classes.legend}>{t("Accepted")}</span>
                  </div>
                  <div className={classes.quantityContainer}>
                    <span className={classes.legend}>{t("Quantity (Kg)")}</span>
                  </div>
                  <div className={classes.discountContainer}>
                    <span className={classes.legend}>
                      {t("Price discount") +
                        " (" +
                        getCurrencySymbol(context) +
                        ")"}
                    </span>
                  </div>
                  <div className={classes.shopContainer}>
                    <span className={classes.legend}>
                      {t("My shop price") +
                        " (" +
                        getCurrencySymbol(context) +
                        ")"}
                    </span>
                  </div>
                  <div className={classes.legendContainer}>
                    <span className={classes.legend}>
                      {t("S24 Index") + " (€)"}
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.columnRight}>
                <WeightRange
                  justPrices={false}
                  range={
                    index === rules.length - 1
                      ? [start, Infinity]
                      : [start, rules[index + 1].start]
                  }
                  accepted={accepted}
                  discount={discount}
                  pricePerTone={price}
                  isTutorial={false}
                  indexPrice={Math.round(indexPrice * 1000)}
                  isEditing={false}
                  margin={margin}
                  onWeightCategoryChange={() =>
                    removeOrAddWeightRange(product.id, start)
                  }
                  index={index}
                  onChange={(price, type) =>
                    changeItem(product.id, price, start, type)
                  }
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </UserContext.Consumer>
  );
};

export default CategoryProductPriceMobile;
