import gql from "graphql-tag";

export default gql`
  mutation SuggestNewMaterialToCatalogue(
    $token: String!
    $partnerId: String!
    $materialName: String
    $baseMetal: String
    $alloy: String
    $form: String
    $surface: String
    $files: [Upload]
    $additionalComments: String
  ) {
    SuggestNewMaterialToCatalogue(
      token: $token
      partnerId: $partnerId
      materialName: $materialName
      baseMetal: $baseMetal
      alloy: $alloy
      form: $form
      surface: $surface
      files: $files
      additionalComments: $additionalComments
    ) {
      message
    }
  }
`;
