import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import GetUserRoles from "../../../queries/GetUserRoles";
import updateEmailPricingRole from "../../../mutations/updateEmailPricingRole";
import { useQuery, useMutation } from "@apollo/react-hooks";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import { showSuccess } from "../../core/shared/Notify";
const PricingRoles = props => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      gap: 20
    },
    checkboxContainer: {
      display: "flex",
      alignItems: "center"
    }
  }));
  const PRICING_WITHOUT_EMAILS_ROLE_ID = 7;
  const [pricingEmailRole, setPricingEmailRole] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const { userData, token, company } = props;
  const {
    data: userRoles,
    loading,
    error
  } = useQuery(GetUserRoles, {
    variables: {
      token: token,
      partnerId: company,
      userId: userData.RawUserData._id
    },
    fetchPolicy: "cache-and-network"
  });

  useEffect(() => {
    setPricingEmailRole(
      userRoles?.getUserRoles.filter(
        item => item.id === PRICING_WITHOUT_EMAILS_ROLE_ID
      ).length > 0
    );
  }, [loading]);
  const [
    updateEmailPricingRoleMutation,
    { data: editUserData, loading: editUserLoading, error: editUserError }
  ] = useMutation(updateEmailPricingRole);

  const onEditRoles = () => {
    updateEmailPricingRoleMutation({
      variables: {
        token: token,
        partnerId: company,
        pricing: !pricingEmailRole
      }
    }).then(() => {
      showSuccess(t("Profile_SettingsSaved"));
    });
  };

  return (
    <div className={classes.checkboxContainer}>
      <Checkbox
        color="primary"
        disabled={editUserLoading}
        checked={pricingEmailRole}
        onChange={event => {
          setPricingEmailRole(event.target.checked);
          onEditRoles(event.target.checked);
        }}
      />
      <Typography>{t("Roles_SeePricing")}</Typography>
    </div>
  );
};

export default PricingRoles;
