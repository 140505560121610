/* eslint-disable jsx-a11y/anchor-is-valid */

import React from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import { Field, reduxForm } from "redux-form";
import { withStyles } from "@material-ui/core/styles";
import LoginButton from "../../components/login/button/LoginButton";
import { withTranslation } from "react-i18next";
import LoginInput from "../login/LoginInput";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { loginUser } from "../../actions/auth";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { IconButton } from "@material-ui/core";

import Error from "../login/Error";
const styles = theme => ({
  grid: {
    width: "100%",
    marginTop: "0",
    position: "relative",
    "&:first-child": {
      marginBottom: "20px"
    }
  },
  error: {
    color: "#B00020",
    textAlign: "center",
    display: "block",
    fontWeight: "normal",
    marginTop: "15px",
    marginBottom: "-10px"
  },
  button: {
    marginTop: "20px",
    width: "100%"
  },
  iconButton: {
    // position: "absolute",
    // right: "0",
    // top: "20px"
  },
  errorContainer: {
    width: "100%",
    marginTop: "10px",
    backgroundColor: "red",
    marginBottom: "-8px"
  },
  textField: {
    height: "40px",
    marginTop: "20px",
    marginBottom: "3px",
    "& .MuiInputBase-input": {
      height: "40px",
      backgroundColor: "white!important",
      paddingTop: "0",
      paddingBottom: "0"
    },

    "& .MuiFormLabel-root.Mui-error": {
      color: "#B00020"
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: "2px solid #B00020"
    },

    "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
      transform: "translate(14px, -6px) scale(0.75)"
    },

    "& .MuiInputLabel-outlined": {
      transform: "translate(14px, 12px) scale(1)"
    },
    "& .MuiOutlinedInput-root": {
      height: "56x"
    }
  },
  noPaddingRight: {
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: "0"
    }
  }
});

class ResetForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPasswordRepeat: false,
      showPassword: false,
      password: "",
      passwordRepeat: "",
      errorMessage: "",
      passwordError: false,
      passwordRepeatError: false
    };

    this.togglePassword = this.togglePassword.bind(this);
    this.togglePasswordRepeat = this.togglePasswordRepeat.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.handlePasswordRepeatChange =
      this.handlePasswordRepeatChange.bind(this);
  }
  togglePasswordRepeat() {
    this.setState({
      showPasswordRepeat: !this.state.showPasswordRepeat
    });
  }
  togglePassword() {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }

  handlePasswordRepeatChange(event) {
    this.setState({
      passwordRepeat: event.target.value
    });
  }

  handlePasswordChange(event) {
    this.setState({
      password: event.target.value
    });
  }

  render() {
    const { classes, handleSubmit, loginErrorMessage, t } = this.props;
    const { showPassword, showPasswordRepeat } = this.state;

    return (
      <form
        onSubmit={event => {
          event.stopPropagation();
          handleSubmit(event);
        }}
      >
        <Grid container>
          <Grid item className={classes.grid}>
            <Field
              name="password"
              autocomplete="password"
              type={showPassword ? "text" : "password"}
              style={{ width: "100%" }}
              placeholder="Password"
              component={LoginInput}
              classes={classes}
              className={classes.noPaddingRight}
              t={t}
              onChange={this.handlePasswordChange}
              label={t("Password")}
              flagAsError={this.state.passwordError}
              InputProps={{
                endAdornment: (
                  <IconButton
                    className={classes.iconButton}
                    onClick={this.togglePassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                )
              }}
            />
          </Grid>
          <Grid item className={classes.grid}>
            <Field
              name="repeatPassword"
              autocomplete="new-password"
              type={showPasswordRepeat ? "text" : "password"}
              style={{ width: "100%" }}
              placeholder="Repeat Password"
              component={LoginInput}
              classes={classes}
              className={classes.noPaddingRight}
              t={t}
              label={t("Repeat password")}
              flagAsError={
                loginErrorMessage.repeatPassword ||
                this.state.passwordRepeatError
              }
              onChange={this.handlePasswordRepeatChange}
              InputProps={{
                endAdornment: (
                  <IconButton
                    className={classes.iconButton}
                    onClick={this.togglePasswordRepeat}
                  >
                    {showPasswordRepeat ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                )
              }}
            />
          </Grid>{" "}
          <div className={classes.errorContainer}>
            <Field
              name="repeatPassword"
              component={Error}
              alternativeError={true}
            />
            <Field name="password" component={Error} alternativeError={true} />
          </div>
          <Grid item className={classes.grid}>
            <LoginButton
              className={classes.button}
              variant="contained"
              color="primary"
              type="submit"
              text={t("Reset Password")}
              fullWidth
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

ResetForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired
};

const validate = values => {
  const errors = {};
  if (values.repeatPassword !== values.password) {
    errors.password =
      "Your passwords do not match, please check your spelling and try again";
    errors.repeatPassword = " ";
  }

  let passwordIsEmpty = !values["password"] || !values["password"].length;
  let repeatPasswordIsEmpty =
    !values["repeatPassword"] || !values["repeatPassword"].length;

  if (passwordIsEmpty && repeatPasswordIsEmpty) {
    errors.password =
      "Please enter your password and then repeat the password to proceed";
    errors.repeatPassword = " ";
  } else if (repeatPasswordIsEmpty) {
    errors.repeatPassword =
      "Please repeat the password to ensure there are no typos";
    errors.password = "";
  } else if (passwordIsEmpty) {
    errors.repeatPassword = "";
    errors.password = "Please repeat the password to ensure there are no typos";
  }

  return errors;
};

ResetForm.defaultProps = {
  classes: {},
  loginErrorMessage: ""
};

ResetForm.propTypes = {
  actions: PropTypes.shape({
    loginUser: PropTypes.func
  }).isRequired,
  classes: PropTypes.object,
  loginErrorMessage: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ loginUser }, dispatch)
});

ResetForm = connect(mapDispatchToProps)(ResetForm);

export default reduxForm({
  form: "ResetForm",
  validate
})(withStyles(styles)(withTranslation()(ResetForm)));
