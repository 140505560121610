import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";

import CustomTable from "../general/Table";
import Skeleton from "../core/shared/Skeleton";

const useStyles = makeStyles(theme => ({
  alignAtTheEnd: { display: "flex", justifyContent: "flex-end" },
  root: {
    padding: "0 16px",
    marginBottom: "20px",
    maxWidth: "1082px",
    width: "100%",
    "& .materials": {
      maxWidth: "fit-content",
      whiteSpace: "nowrap"
    },
    "& .quantity": {
      // maxWidth: "120px",
      wordBreak: "break-all"
    },
    "& .purchases": {
      // maxWidth: "120px",
      wordBreak: "break-all"
    },
    "& .totalValue": {
      // maxWidth: "120px",
      wordBreak: "break-all"
    },
    "& .MuiTableContainer-root": {
      border: "1px solid rgba(0, 0, 0, 0.12)",
      boxShadow: "none",
      backgroundColor: "#FAFAFA",
      borderRadius: "4px"
    },
    "& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root": {
      borderBottom: "none"
    },
    [theme.breakpoints.down("sm")]: {
      order: 2,
      padding: "0",
      marginBottom: "24px",
      maxWidth: "auto",
      "& .MuiTableContainer-root": {
        borderRadius: "0"
      },
      "& .MuiTable-root": {
        minWidth: "max-content"
      },
      "& .MuiTableContainer-root": {
        backgroundColor: "#FFF"
      }
    }
  }
}));

const EmptyTransactionDesktopCart = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const columns = [
    {
      width: "16px",
      spacer: true
    },
    {
      field: "material",
      headerName: t("Material"),
      flex: 1,
      renderCell({}) {
        return <Skeleton height={"14px"} width={"240px"} />;
      }
    },
    {
      field: "amount",
      headerName: t("Quantity (kg)"),
      type: "number",
      renderCell({}) {
        return (
          <div className={classes.alignAtTheEnd}>
            <Skeleton height={"14px"} width={"60px"} />
          </div>
        );
      }
    },
    {
      field: "priceAmount",
      headerName: t("Purchase price / kg"),
      flex: 1,
      type: "number",
      renderCell({}) {
        return (
          <div className={classes.alignAtTheEnd}>
            <Skeleton height={"14px"} width={"60px"} />
          </div>
        );
      }
    },
    {
      headerName: t("Total Value"),
      flex: 1,
      type: "number",
      renderCell({}) {
        return (
          <div className={classes.alignAtTheEnd}>
            <Skeleton height={"14px"} width={"60px"} />
          </div>
        );
      }
    },
    {
      width: "16px",
      spacer: true
    }
  ];
  return (
    <CustomTable
      className={classes.root}
      columns={columns}
      rows={[{}]}
      pagination={false}
    />
  );
};

export default EmptyTransactionDesktopCart;
