/* eslint-disable react/prop-types */

import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { ALREADY_LOGGEDIN } from "./Routing";
import { bindActionCreators } from "redux";
import { loginUserMagicLink } from "./actions/auth";
class LoggedOutLayout extends React.Component {
  constructor(props) {
    super(props);
    this.checkLogin = this.checkLogin.bind(this);
    this.checkAlreadyLoggedIn = this.checkAlreadyLoggedIn.bind(this);
  }

  componentWillMount() {
    let magicLink = this.props.match.params.loginToken;
    if (magicLink) {
      const { actions, history } = this.props;
      const { loginUserMagicLink: loginUserMagicLinkAction } = actions;
      loginUserMagicLinkAction(magicLink, "", history);
    } else {
      this.checkLogin();
    }
  }

  componentWillReceiveProps(newProps) {
    this.checkLogin(newProps);
  }

  checkLogin(props) {
    const newProps = props || this.props;
    const { history } = this.props;
    const noUser = newProps.user && !newProps.user.token;
    const location = history.location;

    if (this.checkAlreadyLoggedIn() && !noUser) {
      const redirectLink =
        location && location.state && location.state.redirectLink;
      history.push(redirectLink || this.getDefaultLoginRedirect());
    }
  }

  getDefaultLoginRedirect() {
    if (window.screen.width <= 960) {
      return "/home";
    }
    return "/dashboard";
  }

  checkAlreadyLoggedIn() {
    const { location } = this.props;
    const { pathname } = location;

    return ALREADY_LOGGEDIN.includes(pathname);
  }

  render() {
    const { children, match, history, activePathname, location } = this.props;
    const childrenWithProps = () =>
      React.cloneElement(children, {
        match,
        history,
        activePathname,
        location
      });

    return childrenWithProps();
  }
}

const mapStateToProps = ({ user }) => ({
  user
});

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ loginUserMagicLink }, dispatch)
});

let LoggedOutLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoggedOutLayout);

export default withRouter(LoggedOutLayoutContainer);
