import React, { useContext } from "react";
import { Grid, Typography } from "@material-ui/core";
import classnames from "classnames";
import OffersContext from "../../../providers/OffersProvider";

import offerListStyles from "./offerListStyles";
import OfferListItem from "./OfferListItem";
import { useHistory } from "react-router";
import moment from "moment";
import { isViewedTender } from "../offerElement/OfferElement";
import useEventTracking from "../../../hooks/useEventTracking";
import { useTranslation } from "react-i18next";

const OfferList = ({ offers, canBid, offerType }) => {
  const classes = offerListStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const { selectedOffer, searchFilters } = useContext(OffersContext);

  offers = filterBySearchFilters(offers, searchFilters);
  return (
    <Grid container item className={classes.leftMenuContainer}>
      {offers.map(o => (
        <Grid
          key={o._id}
          container
          item
          direction="column"
          data-new={canBid && !isViewedTender(o)}
          className={classnames(
            classes.menuItem,
            selectedOffer === o._id ? classes.selectedMenuItem : null
          )}
          onClick={() => {
            if (selectedOffer === o._id) return;
            trackOffer(offerType, o._id, searchFilters, TrackEvent);
            history.push(o._id);
          }}
        >
          <OfferListItem offer={o} />
        </Grid>
      ))}
      {!(offers && offers.length > 0) && (
        <Typography
          variant="body2"
          align="center"
          style={{ width: "100%", padding: "20px" }}
        >
          {t("No offers with this filter")}
        </Typography>
      )}
    </Grid>
  );
};

export const OfferListMobile = ({ offers, canBid, offerType }) => {
  const classes = offerListStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const { selectedOffer, searchFilters } = useContext(OffersContext);

  offers = filterBySearchFilters(offers, searchFilters);

  return (
    <Grid container item>
      {offers.map(o => (
        <Grid
          key={o._id}
          container
          item
          direction="column"
          data-new={canBid && !isViewedTender(o)}
          className={classnames(classes.menuItem, classes.menuItemMobile)}
          onClick={() => {
            if (selectedOffer === o._id) return;
            trackOffer(offerType, o._id, searchFilters, TrackEvent);
            history.push(o._id);
          }}
        >
          <OfferListItem offer={o} />
        </Grid>
      ))}
      {!(offers && offers.length > 0) && (
        <Typography
          variant="body2"
          align="center"
          style={{ width: "100%", padding: "20px" }}
        >
          {t("No offers with this filter")}
        </Typography>
      )}
    </Grid>
  );
};

export default OfferList;

export const filterBySearchFilters = (offers, filters) => {
  if (!filters) return offers;
  const { transactionID, productsDropdown, expiryDate, sortDropdown } = filters;

  if (transactionID && transactionID.length > 0) {
    offers = offers.filter(o => o.tenderID.indexOf(transactionID) > -1);
  }

  if (productsDropdown && productsDropdown !== "all") {
    offers = offers.filter(
      o =>
        !!o.products.find(
          p => p.categoryId && p.categoryId.includes(parseInt(productsDropdown))
        )
    );
  }

  if (expiryDate) {
    offers = offers.filter(o =>
      moment(o.deadlineDate).isSame(moment(expiryDate), "day")
    );
  }

  if (sortDropdown) {
    if (sortDropdown === "oldestFirst") {
      offers = offers.sort(
        (a, b) =>
          moment(a.createDate).valueOf() - moment(b.createDate).valueOf()
      );
    } else if (sortDropdown === "latestFirst") {
      offers = offers.sort(
        (a, b) =>
          (moment(a.createDate).valueOf() - moment(b.createDate).valueOf()) * -1
      );
    }
  }

  return offers;
};

const trackOffer = (offerType, id, filters, TrackEvent) => {
  const {
    sortDropdown = "latestFirst",
    productsDropdown = "all",
    expiryDate
  } = filters || {};

  if (offerType !== "open" && offerType !== "submitted") return;

  const eventName =
    offerType === "open" ? "Open offer selected" : "Submitted offer selected";

  TrackEvent(eventName, {
    tenderSelected: id,
    filteredProducts: productsDropdown,
    filteredDates: expiryDate,
    sortMethod: sortDropdown
  });
};
