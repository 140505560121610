import React, { useCallback, useContext } from "react";
import {
  Grid,
  createStyles,
  makeStyles,
  Typography,
  Divider,
  Hidden
} from "@material-ui/core";

import { useRouteMatch, useHistory } from "react-router";
import { useMutation } from "react-apollo";

import OffersContext from "../../providers/OffersProvider";
import BiddingItems, {
  BiddingItemsMobile
} from "../../components/offers/BiddingItems";
import { Button } from "../../components/offers/offerElement/inputComponents";
import { PUBLISH_BID_TENDER } from "../../mutations/offerMutations";
import GetOffersData from "../../queries/GetOffersData";
import UserContext from "../../providers/UserProvider";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ThankYouPopup from "../../components/offers/ThankYouPopup";

const containerStyles = makeStyles(theme =>
  createStyles({
    mainContainer: {
      height: "100%"
    },
    header: {
      fontSize: 32,
      lineHeight: "40px",
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: 8
    },
    container: {
      padding: "28px 32px"
    },
    idText: {
      fontSize: 16,
      lineHeight: "24px",
      letterSpacing: 0.25,
      color: "rgba(0, 0, 0, 0.6)"
    },
    confirmButton: {
      display: "flex",
      alignItems: "center"
    },
    itemsContainer: {
      display: "flex",
      justifyContent: "flex-start",
      margin: 32,
      maxWidth: 480
    },
    submitButtonContainer: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%"
    },
    mainContainerMobile: {
      height: "100%",
      marginTop: "56px",
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 2,
      backgroundColor: "white",
      marginBottom: "56px",
      paddingBottom: "56px"
    },
    bidSummaryMenu: {
      width: "100%",
      height: "56px",
      background: theme.palette.primary.main,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      paddingLeft: "16px",
      paddingRight: "16px",
      boxShadow:
        "0px 2px 4px rgba(0, 0, 0, 0.24), 0px 4px 8px rgba(0, 0, 0, 0.18)"
    },
    bidSummaryMenuIcon: {
      cursor: "pointer",
      "&:hover": {
        color: "#333333"
      }
    },
    bidSummaryMenuText: {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.15px",
      color: "#FFFFFF",
      marginLeft: "36px"
    },
    biddingItemsMobile: {
      height: "100%",
      padding: "4px 16px",
      overflowY: "auto"
    },
    divider: {
      backgroundColor: "#EEEEEE"
    },
    confirmBidMobile: {
      color: "#FFFFFF",
      position: "fixed",
      display: "flex",
      width: 328,
      height: "fit-content",
      bottom: 0,
      left: "50%",
      transform: "translate(-50%, -16px)",
      padding: "6px 8px 6px 16px",
      background: "#151124",
      borderRadius: 4,
      boxShadow:
        "0px 3px 5px -1px rgba(0, 0, 0, 0.2)," +
        "0px 6px 10px rgba(0, 0, 0, 0.14)," +
        "0px 1px 18px rgba(0, 0, 0, 0.12)"
    }
  })
);

const FinishOffers = props => {
  let { openOffers, biddingInfo, setBiddingInfo, setSelectedOffer } =
    useContext(OffersContext);
  const { user, selectedCompany } = useContext(UserContext);
  const history = useHistory();
  const { t } = useTranslation();

  const [finishBidTenderMutation] = useMutation(PUBLISH_BID_TENDER, {
    refetchQueries: () => [
      {
        query: GetOffersData,
        variables: { token: user.token, partnerId: selectedCompany }
      }
    ]
  });

  const classes = containerStyles();
  const match = useRouteMatch();

  const removeBiddingStatus = useCallback(() => {
    setBiddingInfo(prevBiddingInfo => {
      delete prevBiddingInfo.status;
      delete prevBiddingInfo.completedOffer;
      return { ...prevBiddingInfo };
    });
  }, []);

  const { tenderId } = match.params;
  const offer = openOffers.find(o => o._id === tenderId);

  if (tenderId) {
    const openOffer = openOffers.find(o => o._id === tenderId);
    if (!openOffer) {
      history.push("/offers/open/");
      setSelectedOffer(null);
      return null;
    }
  }

  if (!biddingInfo)
    return (
      <>
        <Hidden mdUp>
          <div style={{ marginTop: "60px" }}>
            <h2>
              {t("Error_NoBiddingInfo")}
              <Link to={"/offers/open/" + offer._id}>{t("Go Back")}</Link>
            </h2>
          </div>
        </Hidden>
        <Hidden smDown>
          <h2>
            {t("Error_NoBiddingInfo")}
            <Link to={"/offers/open/" + offer._id}>{t("Go Back")}</Link>
          </h2>
        </Hidden>
      </>
    );
  else {
    let bidInfo = biddingInfo[offer._id];

    const bidQty = bidQtyCalc(bidInfo);

    const finishBidTender = () => {
      setBiddingInfo(prevBiddingInfo => ({
        ...prevBiddingInfo,
        status: "completed",
        completedOffer: offer._id
      }));
      finishBidTenderMutation({
        variables: {
          token: user.token,
          tenderId: offer._id,
          buyerId: selectedCompany,
          products: bidProducts(offer.products, bidInfo)
        }
      });
    };

    const goToOffer = item => {
      history.push("/offers/open/" + item._id);
    };

    return (
      <>
        <Hidden mdUp>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            className={classes.mainContainerMobile}
          >
            <div className={classes.biddingItemsMobile}>
              {offer.products.length > 0 && (
                <Grid container item xs={12} style={{ marginBottom: "70px" }}>
                  <BiddingItemsMobile offer={offer} bidInfo={bidInfo} />
                </Grid>
              )}
            </div>
            <Grid container item xs className={classes.confirmBidMobile}>
              <Grid container item xs alignItems="center">
                <Typography variant="body2">
                  {t("Products")} {bidQtyCalc(biddingInfo[offer._id])}/
                  {offer.products.length}
                </Typography>
              </Grid>
              <Grid item>
                <Button style={{ color: "#FFFFFF" }} onClick={finishBidTender}>
                  {t("Confirm and Submit")}
                </Button>
              </Grid>
            </Grid>
            {biddingInfo && biddingInfo.status === "completed" && (
              <ThankYouPopup
                completedOffer={biddingInfo.completedOffer || ""}
                cancelAction={() => {
                  removeBiddingStatus();
                }}
                doneAction={() => {
                  removeBiddingStatus();
                  goToOffer(offer);
                }}
              />
            )}
          </Grid>
        </Hidden>
        <Hidden smDown>
          <Grid
            container
            direction="column"
            wrap="nowrap"
            className={classes.mainContainer}
          >
            <Grid container item className={classes.container}>
              <Grid xs item>
                <Typography variant="h5" className={classes.header}>
                  {t("Bid Summary")} ({bidQty}/{offer.products.length})
                </Typography>
                <Typography variant="body2" className={classes.idText}>
                  {offer.tenderID}
                </Typography>
              </Grid>
              <Grid item className={classes.confirmButton}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={finishBidTender}
                >
                  {t("Confirm and Submit")}
                </Button>
              </Grid>
            </Grid>
            <Grid item>
              <Divider className={classes.divider} />
            </Grid>
            <Grid container item className={classes.itemsContainer}>
              {offer.products.length > 0 && (
                <BiddingItems offer={offer} bidInfo={bidInfo} />
              )}
              <div className={classes.submitButtonContainer}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={finishBidTender}
                >
                  {t("Confirm and Submit")}
                </Button>
              </div>
            </Grid>
          </Grid>
        </Hidden>
      </>
    );
  }
};

export default FinishOffers;

export const bidQtyCalc = bidInfo =>
  Object.keys(bidInfo).reduce((a, b) => (!bidInfo[b].editing ? a + 1 : a), 0);

export const bidProducts = (products, bidInfo) =>
  products.map(p => {
    const itemBid = bidInfo[p.randomId];
    const bid = itemBid ? itemBid.editing : false;
    const conditions = itemBid ? itemBid.comment : null;
    const price = itemBid ? itemBid.price : null;
    return {
      productID: p.productID,
      randomId: p.randomId,
      quantity: p.quantity,
      bid,
      conditions,
      price
    };
  });
