import React from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme =>
  createStyles({
    horizontalSlide: {
      display: "flex",
      alignItems: "center"
    },
    circle: {
      width: "8px",
      height: "8px",
      borderRadius: "50%",
      backgroundColor: "#95949C",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: "0 2px",
      position: "relative"
    },
    line: {
      width: "8px",
      height: "1px",
      backgroundColor: "red"
    },
    greenColor: {
      backgroundColor: "#52F597"
    },
    greyColor: {
      backgroundColor: "#95949C"
    }
  })
);

const SmallHorizontalSlider = ({ steps, actualStep }) => {
  const classes = useStyles();
  return (
    <div className={classes.horizontalSlide}>
      {steps.map((step, i) => (
        <HorizontalStep
          key={i}
          number={i + 1}
          actualPosition={actualStep}
          isLast={steps.length === i + 1}
        />
      ))}
    </div>
  );
};

const HorizontalStep = ({ number, actualPosition, isLast }) => {
  const classes = useStyles();
  const isActive = number === actualPosition;
  const isOver = number < actualPosition;

  return (
    <>
      <div
        className={`${classes.circle} ${
          isOver || isActive ? classes.greenColor : classes.greyColor
        }`}
      ></div>
      {!isLast && (
        <div
          className={`${classes.line} ${
            isOver ? classes.greenColor : classes.greyColor
          }`}
        ></div>
      )}
    </>
  );
};

export default SmallHorizontalSlider;
