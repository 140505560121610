import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import StatusBar from "./StatusBar";
import { StatusContext } from "./context";

const useStyles = makeStyles(theme => ({
  tabs: {
    display: "flex",
    alignItems: "flex-start",
    position: "relative",
    overflowX: "hidden",
    "& .status_button": {
      width: "90px",
      [theme.breakpoints.up("sm")]: {
        width: "180px"
      }
    }
  },
  wrap: {
    background: "#F9F9F9",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    margin: "0 auto",
    padding: "25px 27px",
    boxSizing: "border-box",
    width: "100%",
    maxWidth: "343px",
    "&:not(:last-child)": {
      marginBottom: "30px"
    },
    [theme.breakpoints.up("sm")]: {
      padding: "50px 0",
      maxWidth: "982px"
    }
  }
}));

/**
 *
 * @param className: The name for the class, by default it would be classes.wrap (see it in useStyles)
 * @param currentIndex: The current index of the status bar
 * @param startDate: Just important if statusElements are not received and using default
 * @param statusElement Array of elements of the status bar, options of the element:
 * @param collapsed boolean showing/hiding status bar when expanded or collapsed
 *        title: The title of the status option
 *        position: Where is this status option placed
 *        text: The text for the status option
 * @returns a Status component
 */
const Status = ({
  className,
  currentIndex,
  startDate = "99/99/9999",
  statusElements = [
    {
      title: "1.Pickup",
      position: 1,
      text: `Confirm Pickup for ${startDate}`
    },
    { title: "2.Transport", position: 2, text: "Organisation" },
    { title: "3.Transport", position: 3, text: "Documents" },
    { title: "4.Transport", position: 4, text: "In Transit" },
    { title: "5.Transport", position: 5, text: "Delivered" }
  ],
  collapsed,
  disabled = false
}) => {
  const classes = useStyles();
  let className_ = "";
  className ? (className_ = className) : (className_ = "");
  const values = React.useMemo(() => {
    let nIndex = currentIndex;
    if (currentIndex === 1) {
      nIndex = 2;
    }
    if (currentIndex === statusElements.length) {
      nIndex = currentIndex - 1;
    }
    return {
      currentIndex,
      nArrays: [nIndex - 1, nIndex, nIndex + 1],
      collapsed
    };
  }, [currentIndex, collapsed, statusElements.length]);

  return (
    <StatusContext.Provider value={values}>
      <div className={`${classes.tabs || ""} ${className_}`}>
        {statusElements.map(statusElement => (
          <Status.Bar
            key={statusElement.title}
            title={statusElement.title}
            position={statusElement.position}
            className={statusElement.className ? statusElement.className : ""}
            disabled={disabled}
          >
            {statusElement.text}
          </Status.Bar>
        ))}
      </div>
    </StatusContext.Provider>
  );
};

Status.Bar = StatusBar;

Status.propTypes = {
  className: PropTypes.string,
  currentIndex: PropTypes.number,
  startDate: PropTypes.string,
  statusElements: PropTypes.array,
  collapsed: PropTypes.bool
};

Status.defaultProps = {
  className: "",
  collapsed: false
};
export default Status;
