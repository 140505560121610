export { onChangeItem, onChangeReference, onChangeLink };

function onChangeItem(
  productId,
  price,
  start,
  type,
  selectedProducts,
  setSelectedProducts
) {
  price = parseInt(price);
  if (!price || price < 0) price = 0;
  //get product from priceData, maybe add if not found?
  let product = selectedProducts.find(p => p && p.id === productId);
  if (!product) return;

  product.isDirty = true;

  //change price or discount for weight range
  let rule = product.rules.find(rl => rl.start === start);

  if (type === "discount") {
    rule.discount = price;
    rule.price = product.referencePrice - price;
    if (rule.price < 0) {
      rule.discount = product.referencePrice;
      rule.price = 0;
    }
  } else {
    rule.price = price;
    rule.discount = product.referencePrice - price;
    if (rule.discount < 0) {
      rule.price = product.referencePrice;
      rule.discount = 0;
    }
  }

  setSelectedProducts(selectedProducts);
}

function onChangeReference(
  productId,
  price,
  lockedOn = "discount",
  selectedProducts,
  setSelectedProducts,
  setHighest = false
) {
  price = parseInt(price);
  if (!price) price = 0;
  //get product from priceData, maybe add if not found?
  let product = selectedProducts.find(p => p && p.id === productId);
  if (!product) return;
  product.isDirty = true;

  let { rules } = product;

  let highestRuleIndex = 0;

  rules.forEach((r, i) => {
    if (r.accepted) highestRuleIndex = i;
  });

  product.referencePrice = setHighest ? price + 50 : price;

  if (!setHighest) highestRuleIndex += 1;

  rules.forEach((rule, idx) => {
    if (idx >= highestRuleIndex && setHighest) {
      rule.discount = 50;
      rule.price = price;
    } else
      lockedOn === "discount"
        ? (rule.price = price - rule.discount)
        : (rule.discount = price - rule.price);
  });

  //compose the price data again

  setSelectedProducts(selectedProducts);
}

function onChangeLink(productId, value, setSelectedProducts, selectedProducts) {
  let product = selectedProducts.find(p => p && p.id === productId);
  if (!product) return;

  product.linkedTo = value;
  product.isDirty = true;

  setSelectedProducts(selectedProducts);
}
