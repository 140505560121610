import { useCallback, useState } from "react";

export const useValidation = (extraChecks = {}) => {
  const [errors, setErrors] = useState({});

  const checkValidation = useCallback(
    function (fields) {
      const errors = {};
      let hasErrors = false;

      Object.keys(fields).forEach(f => {
        const fieldValue = fields[f];
        const extraCheck = extraChecks[f];

        if (extraCheck === false) return;

        if (extraCheck && typeof extraCheck === "function") {
          const checkResponse = extraCheck(fieldValue);
          if (checkResponse) {
            hasErrors = true;
            errors[f] = checkResponse;
          }
        } else if (!fieldValue) {
          hasErrors = true;
          errors[f] = { error: true };
        }
      });

      setErrors(errors);

      return hasErrors;
    },
    [setErrors, extraChecks]
  );

  return {
    errors,
    setErrors,
    checkValidation,
    hasErrors: Object.keys(errors).length > 0
  };
};
