import React, { useCallback, useRef } from "react";
import {
  Button,
  DialogContent,
  DialogActions,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import CustomDropzone from "../../components/core/shared/uploadDialog/components/Dropzone";
import FileList from "../../components/core/shared/uploadDialog/components/FileList";

const useStyles = makeStyles(theme => ({
  mobileButtoContainer: {
    paddingLeft: "16px",
    paddingRight: "16px",

    paddingBottom: "16px"
  },
  buttonRoot: {
    boxShadow: "none",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  headerContainer: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      minHeight: "56px",
      display: "flex",
      alignItems: "center",
      marginTop: "0px"
    }
  },
  headerRoot: {
    width: "100%",
    paddingTop: "48px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingBottom: "8px",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      position: "initial",
      padding: "0"
    },
    "&.advanced": {
      paddingTop: "48px",
      paddingBottom: "0"
    },
    "& .MuiTypography-root": {
      fontWeight: "500",
      fontSize: "20px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "10px",
        padding: "0"
      },
      display: "flex",
      gap: "10px"
    },
    "& .MuiIconButton-root": {
      [theme.breakpoints.up("md")]: {
        right: "1px"
      },
      [theme.breakpoints.down("sm")]: {
        left: "1px",
        position: "initial"
      },
      position: "absolute",
      top: "1px"
    }
  },
  closeButton: {
    marginRight: "16px"
  },
  fileSelection: {
    margin: "0 -24px"
  },
  actionButtons: {
    paddingTop: "16px !important",
    [theme.breakpoints.down("sm")]: {
      background: "white",
      zIndex: "9",
      position: "fixed",
      bottom: "0",
      right: "0",
      left: "0",
      boxShadow: "0px -1px 0px rgba(0, 0, 0, 0.12)",
      paddingTop: "24px!important"
    }
  },
  root: {
    [theme.breakpoints.up("md")]: {
      width: "640px"
    }
  }
}));

export default function FirstStep({
  files,
  setFiles,
  setStep,
  closingModal,
  accept
}) {
  const classes = useStyles();
  const dropzoneRef = useRef();

  const openUploadDialog = useCallback(e => {
    e.preventDefault();
    dropzoneRef.current && dropzoneRef.current.open();
  }, []);

  const addFilesAction = useCallback(
    fileList => {
      const fileListArray = Array.from(fileList);
      setFiles([...files, ...fileListArray]);
    },
    [files, setFiles]
  );

  const handleNextStep = () => {
    if (files.length > 0) {
      setStep(2);
    }
  };

  const isNextButtonDisabled = files.length === 0;

  return (
    <CustomDropzone accept={accept} onDrop={addFilesAction} ref={dropzoneRef}>
      <div className={classes.headerContainer}>
        <div className={classes.headerRoot}>
          <IconButton
            aria-label="delete"
            onClick={() => closingModal("closeTab")}
            className={classes.closeButton}
          >
            <ClearIcon />
          </IconButton>
          <Typography variant="h2" gutterBottom>
            {"Upload Weight Note"}
          </Typography>
        </div>
      </div>
      <div className={classes.mobileButtoContainer}>
        <Button
          onClick={openUploadDialog}
          variant="outlined"
          color="primary"
          fullWidth
          startIcon={<CameraAltIcon />}
        >
          {"TAKE PHOTO"}
        </Button>
      </div>
      <div className={classes.mobileButtoContainer}>
        <Button
          onClick={openUploadDialog}
          variant="outlined"
          color="primary"
          fullWidth
          startIcon={<InsertDriveFileIcon />}
        >
          {"SELECT FILES"}
        </Button>
      </div>
      <DialogContent className={classes.root}>
        <FileList
          fileTypeSelector={false}
          files={files}
          setFiles={setFiles}
          className={classes.fileSelection}
          isDriver={true}
        />
      </DialogContent>
      <DialogActions className={classes.actionButtons}>
        <Button
          classes={{ root: classes.buttonRoot }}
          elevation={0}
          color="secondary"
          variant="contained"
          onClick={handleNextStep}
          disabled={isNextButtonDisabled}
        >
          {"NEXT"}
        </Button>
      </DialogActions>
    </CustomDropzone>
  );
}
