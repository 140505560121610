import React from "react";
import Typography from "@material-ui/core/Typography";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { useTranslation } from "react-i18next";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useState } from "react";

const TransactionsGallery = props => {
  const theme = useTheme();
  const useStyles = makeStyles({
    title: {
      fontSize: "14px",
      marginTop: "5px",
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: "0.1px"
    },
    subtitle: {
      fontSize: "12px",
      color: "rgba(0, 0, 0, 0.6)",
      fontWeight: 400,
      lineHeight: "16px"
    },
    modalToggleImgContainer: {
      minWidth: "168px",
      maxWidth: "168px",
      minHeight: "216px",
      border: "1px solid #E0E0E0",
      boxSizing: "border-box",
      borderRadius: "4px",
      width: "fit-content",
      padding: "8px",
      marginTop: "24px",
      "&:hover": {
        cursor: "pointer"
      }
    },
    modalToggleImg: {
      height: "152px",
      width: "152px",
      objectFit: "cover"
    },
    thumbnails: {
      width: "100px"
    },
    iconContainer: {
      minWidth: "10px"
    },
    whiteIcon: {
      color: "#FFFFFF",
      fill: "#FFFFFF"
    },
    imageIcon: {
      width: "16px",
      height: "16px"
    },
    iconImage: {
      cursor: "pointer",
      maxWidth: "200px"
    },
    iconItem: {
      cursor: "pointer",
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: theme.palette.secondary.main
    },
    bigWrapper: {
      maxWidth: "100vw",
      overflow: "hidden"
    }
  });

  const classes = useStyles();
  const { t } = useTranslation();
  const { photos } = props;
  const images = photos.map(item => item.src);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  return (
    <div>
      <div
        className={classes.modalToggleImgContainer}
        onClick={() => setModalIsOpen(true)}
      >
        <img
          src={images[0]}
          className={classes.modalToggleImg}
          alt="transaction"
        />
        <Typography className={classes.title} variant="h5">
          {t("Offer images")}
        </Typography>
        <Typography className={classes.subtitle} align="left" variant="h6">
          {images.length + " " + t("images")}
        </Typography>
      </div>

      {modalIsOpen && (
        <Lightbox
          reactModalStyle={{ overlay: { zIndex: 10000 } }}
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setModalIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
};

export default TransactionsGallery;
