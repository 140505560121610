import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  FormControl,
  Hidden,
  makeStyles,
  TextField,
  Typography
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { useMutation } from "@apollo/react-hooks";
import AccountContacts from "../../queries/AccountContacts";
import AddOrEditContact from "../../mutations/addOrEditContact";
import { showError, showSuccess } from "../core/shared/Notify";
import { validateEmail } from "../core/forms/validation/emailValidation";
import CheckboxWithLabel from "../core/CheckboxWithLabel";
import { DropdownWithPills } from "../general/DropdownWithPills/DropdownWithPills";
import { useLazyQuery } from "@apollo/react-hooks";
import getAllRoles from "../../queries/getAllRoles";
import Loader from "../core/shared/Loader";
import { canSeeItem } from "../../utils/SharedUtils";
import hasInjectionAttack from "../../utils/checkInjectionAttack";
import useCompany from "../../hooks/useCompany";
import { SPECIAL_CONTACT_POSITIONS } from "../../utils/constants";

const Form = props => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      maxWidth: 400,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #9E9E9E"
      }
    },
    headerContainer: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        padding: "16px 0",
        gap: 16
      }
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: 0.25
    },
    btnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 16
    }
  }));

  const classes = useStyles();
  const { t } = useTranslation();
  const companyObj = useCompany();

  const { cancel, type, token, company, contact, forcedPosition } = props;

  const name = contact?.name || "";

  const [submit, setSubmit] = useState(false);
  const [firstName, setFirstName] = useState(name.split(" ")[0]);
  const [lastName, setLastName] = useState(name.substr(name.indexOf(" ") + 1));
  const [companyPosition, setCompanyPosition] = useState(
    (forcedPosition && forcedPosition.label) || contact?.position || null
  );
  const [email, setEmail] = useState(contact?.email);
  const [phone, setPhone] = useState(contact?.phone);
  const [mobile, setMobile] = useState(contact?.mobile);
  const [inviteUser, setInviteUser] = useState(false);
  const [selectedRole, setSelectedRole] = useState("");
  const [arrOfOptions, setArrOfOptions] = useState([]);

  const [getAllRolesQuery, { data, loading }] = useLazyQuery(getAllRoles, {
    fetchPolicy: "cache-and-network"
  });
  const [addOrEditContactMutation] = useMutation(AddOrEditContact, {
    refetchQueries: () => [
      {
        query: AccountContacts,
        variables: {
          token: token,
          company: company
        }
      }
    ]
  });

  useEffect(() => {
    if (inviteUser) {
      if (!data || !data.getAllRoles)
        getAllRolesQuery({
          variables: {
            token: token,
            partnerId: company
          }
        });
    }
  }, [inviteUser]);

  useEffect(() => {
    if (data && data.getAllRoles && arrOfOptions.length === 0)
      setArrOfOptions(
        data.getAllRoles.map(d => {
          return { label: d.label, value: d.name, key: d.id };
        })
      );
  }, [data]);

  const canAddUser = canSeeItem(companyObj?.selectedCompany, [companyObj], {
    rules: "account_settings_users_ui"
  });
  const mobileRegExp = /^\+?\d{1,14}$/;

  const addContact = () => {
    setSubmit(true);

    if (
      hasInjectionAttack(firstName) ||
      hasInjectionAttack(lastName) ||
      hasInjectionAttack(email) ||
      hasInjectionAttack(companyPosition)
    ) {
      showError(t("error_injection_attack"));
      setSubmit(false);
      return;
    }

    if (
      !firstName ||
      !lastName ||
      !email ||
      !isEmailValid() ||
      (!phone && !mobile) ||
      (phone && !isPhoneValid()) ||
      (mobile && !isMobileValid())
    )
      return null;

    addOrEditContactMutation({
      variables: {
        token: token,
        company: company,
        id: contact?._id || "",
        input: {
          firstName: firstName,
          lastName: lastName,
          position: forcedPosition ? forcedPosition.value : companyPosition,
          email: email,
          phone: phone,
          mobile: mobile,
          inviteUser: inviteUser,
          role: inviteUser ? selectedRole : null
        }
      }
    })
      .then(() => {
        showSuccess(
          type === "add" ? t("Successfully added") : t("Successfully edited")
        );
        cancel(true, type, {
          firstName: firstName,
          lastName: lastName,
          position: companyPosition,
          email: email,
          phone: phone,
          mobile: mobile
        });
      })
      .catch(err => {
        if (
          err.graphQLErrors[0].message.replace(/[\[\]]/g, "") === "exist_email"
        ) {
          showError(t("exist_email_message"));
          return;
        }
        showError(err.message);
      });
  };
  const getEmailTextError = function () {
    if (!email && submit) {
      return t("This field is compulsory");
    }
    if (!isEmailValid() && submit) {
      return "Invaild email";
    }
  };

  const isEmailValid = function () {
    return validateEmail(email);
  };

  const isMobileValid = function () {
    return mobileRegExp.test(mobile);
  };

  const isPhoneValid = function () {
    return mobileRegExp.test(phone);
  };

  const handleRoleChange = event => {
    const {
      target: { value }
    } = event;
    if (value === selectedRole && selectedRole !== "") {
      setSelectedRole("");
      return;
    }
    setSelectedRole(value);
  };

  const handlePhoneChange = event => {
    const value = event.target.value.replace(/[^\d+]/g, "");
    setPhone(value);
  };

  const handleMobileChange = event => {
    const value = event.target.value.replace(/[^\d+]/g, "");
    setMobile(value);
  };

  const saveButtonText = inviteUser ? t("contact_save&invite") : t("Save");

  const predefinedPositionName = SPECIAL_CONTACT_POSITIONS[companyPosition]
    ? t(SPECIAL_CONTACT_POSITIONS[companyPosition])
    : companyPosition;

  return (
    <FormControl fullWidth className={classes.container}>
      <div className={classes.headerContainer}>
        <Hidden mdUp>
          <Close onClick={cancel} size="small" />
        </Hidden>
        <Typography className={classes.title}>
          {type === "add" && t("Additional Contact")}
          {type === "edit" && t("Edit Contact")}
        </Typography>
      </div>
      <TextField
        id="firstName"
        label={t("First Name")}
        variant="outlined"
        value={firstName}
        onChange={event => setFirstName(event.target.value)}
        error={!firstName && submit}
        helperText={!firstName && submit && t("This field is compulsory")}
      />
      <TextField
        id="lastName"
        label={t("Last Name")}
        variant="outlined"
        value={lastName}
        onChange={event => setLastName(event.target.value)}
        error={!lastName && submit}
        helperText={!lastName && submit && t("This field is compulsory")}
      />
      <TextField
        id="companyPosition"
        label={t("Account_CompanyPosition")}
        variant="outlined"
        value={predefinedPositionName || companyPosition}
        onChange={event => setCompanyPosition(event.target.value)}
        disabled={forcedPosition}
      />
      <TextField
        id="email"
        label={t("Email")}
        variant="outlined"
        value={email}
        onChange={event => setEmail(event.target.value)}
        error={(!email || !isEmailValid()) && submit}
        helperText={getEmailTextError()}
      />
      <TextField
        id="phone"
        label={t("Account_Phone")}
        variant="outlined"
        value={phone}
        onChange={event => handlePhoneChange(event)}
        error={((!phone && !mobile) || (phone && !isPhoneValid())) && submit}
        helperText={
          submit
            ? !phone && !mobile
              ? t("RequiredField_PhoneMobile")
              : phone && !isPhoneValid()
                ? t("contact_invalidPhoneNumber")
                : null
            : null
        }
      />
      <TextField
        id="mobile"
        label={t("Account_Mible")}
        variant="outlined"
        value={mobile}
        onChange={event => handleMobileChange(event)}
        error={((!phone && !mobile) || (mobile && !isMobileValid())) && submit}
        helperText={
          submit
            ? !phone && !mobile
              ? t("RequiredField_PhoneMobile")
              : mobile && !isMobileValid()
                ? t("contact_invalidMobileNumber")
                : null
            : null
        }
      />
      {canAddUser && (
        <>
          <div style={{ marginTop: "-8px" }}>
            <CheckboxWithLabel
              checked={inviteUser}
              label="contact_addAsAUser"
              color="secondary"
              onChange={event => {
                setInviteUser(event.target.checked);
              }}
            />
          </div>
          {inviteUser &&
            (loading ? (
              <Loader />
            ) : (
              <div style={{ marginTop: "-8px" }}>
                <DropdownWithPills
                  handleChange={handleRoleChange}
                  arrOfOptions={arrOfOptions}
                  arrOfSelected={[selectedRole]}
                  label={t("Role")}
                  error={!selectedRole && submit}
                  errorMessage={
                    !selectedRole && submit && inviteUser
                      ? t("This field is compulsory")
                      : null
                  }
                  multiple={false}
                />
              </div>
            ))}
        </>
      )}
      <div className={classes.btnContainer}>
        <Button variant="outlined" color="primary" onClick={cancel}>
          {t("Cancel")}
        </Button>
        <Button variant="contained" color="primary" onClick={addContact}>
          {saveButtonText}
        </Button>
      </div>
    </FormControl>
  );
};

const Contact = props => {
  const useStyles = makeStyles(theme => ({
    mobileContainer: {
      minWidth: "100%",
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
      position: "absolute",
      maxHeight: "100%",
      overflowY: "auto",
      zIndex: 100,
      background: "#FFFFFF",
      padding: "0 16px 30px"
    }
  }));

  const {
    cancel,
    type,
    token,
    company,
    contact,
    forcedPosition = null
  } = props;

  const classes = useStyles();

  return (
    <>
      <Hidden smDown>
        <Form
          cancel={cancel}
          type={type}
          token={token}
          company={company}
          contact={contact}
          forcedPosition={forcedPosition}
        />
      </Hidden>
      <Hidden mdUp>
        <div className={classes.mobileContainer}>
          <Form
            cancel={cancel}
            type={type}
            token={token}
            company={company}
            contact={contact}
            forcedPosition={forcedPosition}
          />
        </div>
      </Hidden>
    </>
  );
};

export default Contact;
