import {
  DialogContent,
  DialogContentText,
  makeStyles
} from "@material-ui/core";
import { useEffect, useMemo } from "react";
import ProgressBar from "../../../../general/ProgressBar";
import FileList from "../components/FileList";

import { useTranslation } from "react-i18next";
import { WEIGHTNOTE_UPLOAD_TYPE } from ".";
import {
  OTHER_DOC_FILE_TYPE,
  WEIGHTNOTE_FILE_TYPE
} from "../components/FileList/fileTypeOptions";

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("md")]: {
      width: "640px"
    },
    "& > .MuiDialogContentText-root": {
      [theme.breakpoints.down("sm")]: {
        paddingBottom: "20px"
      },
      paddingBottom: "20px"
    },
    "& > .MuiDialogActions-root": {
      paddingBottom: "20px"
    }
  },
  progressBar: {
    marginBottom: "16px"
  },
  fileList: {
    margin: "0 -24px -8px"
  }
}));

export default ({
  type,
  files,
  setNextStepAction,
  seller = false,
  onSetFiles
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      let resultOfFiles = [];
      for (let file of files) {
        let meta = {
          isUserImages: false,
          isExtraFile: false,
          uploadByBuyer: !seller,
          uploadBySeller: seller
        };

        if (file.documentType) {
          meta.documentType = file.documentType;
        } else if (type === WEIGHTNOTE_UPLOAD_TYPE) {
          meta.documentType = WEIGHTNOTE_FILE_TYPE;
        } else {
          meta.documentType = OTHER_DOC_FILE_TYPE;
        }
        resultOfFiles.push({ file: file, meta: meta });
      }

      onSetFiles(resultOfFiles);
      setTimeout(() => {
        setNextStepAction();
      }, 1000);
    })();
  }, []);

  const [actualFile, totalFiles, percentage] = useMemo(() => {
    const totalFiles = files.length;
    const uploadingFile = files.findIndex(f => !f.completed);
    const actualFile = uploadingFile === -1 ? totalFiles : uploadingFile;
    const percentage = (actualFile / totalFiles) * 100;
    return [actualFile, totalFiles, percentage];
  }, [files]);

  return (
    <>
      <DialogContent className={classes.root}>
        <DialogContentText>
          {t("The files you selected are being uploaded")} <br />
          {t(
            "Please do not close or refresh the browser window until the upload process is completed"
          )}
        </DialogContentText>
        <ProgressBar
          className={classes.progressBar}
          percentage={percentage}
          text={`${actualFile} of ${totalFiles} files uploaded`}
        />
        <FileList files={files} uploading={true} className={classes.fileList} />
      </DialogContent>
    </>
  );
};
