import deepExtend from "deep-extend";

const defaultTheme = {
  icon: {
    "& path": {
      fill: "white"
    },
    height: 24,
    width: 24
  },
  unreadIndicator: {
    top: 3,
    right: 3,
    padding: "4px",
    background: "#52F597"
  },
  container: { zIndex: 1, fontFamily: "Inter" },
  dismissInbox: {
    display: "none"
  },
  header: {
    borderBottom: "unset",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px -1px 0px 0px rgba(235, 230, 239, 1) inset",
    fontFamily: "Inter",
    "& [role=heading]": {
      fontSize: "14px",
      fontWeight: 600,
      lineHeight: "18px",
      letterSpacing: "0.5px",
      textAlign: "left",
      color: "#151124",
      textTransform: "uppercase",
      padding: "3px 0px"
    },
    ".message-count": {
      margin: "0 4px",
      fontSize: "12px",
      fontWeight: 500
    }
  },
  messageList: {
    container: {
      "&::-webkit-scrollbar": {
        width: "8px"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#eee",
        borderRadius: "20px",
        border: "3px solid #eee"
      },
      overflow: "auto",
      background: "rgba(255,255,255,1)",
      height: "400px",
      minHeight: "400px",
      "& > div[data-testid='inbox-message']": {
        "&:nth-last-child(2) .message-container::after": {
          content: "unset"
        }
      },
      "& > div[class^='LoadingMore__Styled']": {
        paddingTop: "12px",
        paddingBottom: "12px",
        boxShadow: "0px 1px 0px 0px rgba(235, 230, 239, 1) inset",
        background: "white",
        "& > div[class^='LoadingIndicator__Styled']": {
          "& > svg": {
            visibility: "hidden",
            height: 0,
            width: 0
          },
          "& > div[class^='LoadingIndicator__StyledContent']": {
            paddingTop: "0px",
            paddingBottom: "0px",
            "& > div[class^='LoadingMore__StyledLabel']": {
              marginTop: 0,
              fontFamily: "Inter",
              fontSize: "13px",
              fontWeight: 500,
              lineHeight: "20px",
              letterSpacing: "0.15px"
            }
          }
        }
      }
    },
    scrollTop: {
      padding: "4px 6px",
      background: "transparent",
      "&::before": {
        borderRadius: "16px",
        background:
          "linear-gradient(0deg, #E3E2E5, #E3E2E5),linear-gradient(0deg, #8A8892, #8A8892)",
        padding: "0 8px",
        color: "rgba(21, 17, 36, 1)",
        border: "1px solid rgba(138, 136, 146, 1)",
        fontFamily: "Inter",
        fontSize: "11px",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.16px",
        textAlign: "left",
        height: "20px"
      },
      "&.stickied": {
        boxShadow: "unset",
        "&::before": {
          marginTop: "3px"
        }
      }
    }
  },
  message: {
    container: {
      fontFamily: "Inter",
      padding: "12px 16px",
      borderBottomColor: "transparent",
      cursor: "pointer",
      "&::after": {
        bottom: "-1px",
        left: 0,
        content: "''",
        position: "absolute",
        width: "calc(100% - 32px)",
        height: "1px",
        background: "rgba(235, 230, 239, 1)",
        marginLeft: "16px"
      },
      "&:hover": {
        background: "rgba(245, 245, 245, 1)"
      }
    },
    clickableContainer: {
      padding: 0
    },
    timeAgo: {
      position: "absolute",
      right: "-8px",
      top: "-10px",
      fontSize: "10px",
      color: "#AAA"
    },
    title: {
      fontSize: "16px",
      fontWeight: 600,
      lineHeight: "19px",
      letterSpacing: "0px",
      textAlign: "left",
      color: "rgba(0, 0, 0, 0.87)"
    },
    textElement: {
      marginTop: "4px",
      fontSize: "13px",
      fontWeight: 400,
      lineHeight: "16px",
      letterSpacing: "0.15px",
      color: "rgba(0, 0, 0, 0.7)",
      maxWidth: "285px"
    },
    actionElement: {
      color: "rgba(21, 17, 36, 1)",
      background: "rgba(82, 245, 151, 1)",
      fontFamily: "Inter",
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: "14px",
      letterSpacing: "0.5px",
      textAlign: "center",
      padding: "9px 16px",
      borderRadius: "none",
      marginTop: "12px",
      height: "34px",
      textTransform: "uppercase",
      "&:hover": {
        color: "white",
        background: "black"
      },
      "&:focus": {
        color: "white",
        backgroundColor: "#8A8892"
      },
      "&:not(:first-of-type)": {
        border: "1px solid black",
        background: "transparent",
        "&:hover": {
          color: "#151124",
          backgroundColor: "rgba(21, 17, 36, 0.04)"
        },
        "&:focus": {
          color: "#151124",
          backgroundColor: "#8A8892"
        }
      }
    },
    unreadIndicator: { background: "#52F597", width: "3px" }
  }
};

const desktopTheme = {};

const mobileTheme = {
  container: {
    marginTop: "104px"
  },
  messageList: {
    container: {
      height: "calc(100vh - 104px)",
      maxHeight: "calc(100vh - 104px)",
      "& > div[class^='LoadingMore__Styled']": { display: "none" }
    }
  }
};

export const DesktopInboxTheme = deepExtend({}, defaultTheme, desktopTheme);
export const MobileInboxTheme = deepExtend({}, defaultTheme, mobileTheme);
