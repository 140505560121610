import { useState } from "react";

const useQuantityValidation = (quantity, t) => {
  const [helperText, setHelperText] = useState("");
  const [error, setError] = useState(false);

  const onBlur = () => {
    if (!quantity) {
      setHelperText(t("Error message Quantity field is mandatory"));
      setError(true);
    }
  };

  const onFocus = () => {
    if (!quantity) {
      setHelperText("");
      setError(false);
    }
  };
  return {
    helperText,
    error,
    onBlur,
    onFocus,
    onSubmit: onBlur
  };
};

export default useQuantityValidation;
