import gql from "graphql-tag";

export default gql`
  query getNonExistingMaterials(
    $token: String!
    $partnerId: String!
    $materialIds: [String]!
  ) {
    getNonExistingMaterials(
      token: $token
      partnerId: $partnerId
      materialIds: $materialIds
    ) {
      materialList
    }
  }
`;
