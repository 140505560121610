import { makeStyles } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  tableRoot: {
    border: "1px solid rgba(0, 0, 0, 0.12)",
    background: "#F9F9F9",
    "box-sizing": "border-box",
    "border-radius": "4px",
    "overflow-x": "auto"
  },
  tableHeader: {
    padding: "16px 8px",
    "border-bottom": "1px solid #E0E0E0",
    alignItems: "start",
    color: "black",
    fontSize: "14px",
    fontWeight: "500",
    "vertical-align": "middle"
  },
  row: {
    padding: " 8px 8px"
  },
  input: {
    background: "white"
  }
}));

const TextFieldTable = ({
  columnHeaders,
  fileContent,
  columns,
  handleChange,
  isValidNumber,
  newFileContent
}) => {
  const classes = useStyles();
  const [originalFileContent, setOriginalFileContent] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    setOriginalFileContent([...fileContent]);
  }, []);

  const getHelperText = (indexRow, column) => {
    const row = newFileContent[indexRow];
    if (row.errorsWithData[column]) {
      const obj = row.errorsWithData[column];
      const options = {
        required: t("textTable_helper_text_required"),
        type: t("textTable_helper_text_type", { type: obj.objType }),
        duplicate: t("textTable_helper_text_unique"),
        date: t("textTable_helper_text_date"),
        enum: t("textTable_helper_text_inv_value"),
        country_code: t("textTable_helper_text_inv_country_code"),
        wrong_material: t("textTable_helper_text_wrong_material")
      };
      return options[obj.type] || obj.type;
    }
    return "";
  };

  return (
    <table className={classes.tableRoot}>
      <tr>
        {columnHeaders.map((header, index) => (
          <th key={index} className={classes.tableHeader} style={header.style}>
            {t(header.name)}
          </th>
        ))}
      </tr>
      {fileContent.map(
        (row, indexRow) =>
          originalFileContent[indexRow]?.error && (
            <tr key={indexRow}>
              {columns.map((column, indexColumn) => (
                <td
                  key={`${indexRow}_${indexColumn}`}
                  className={classes.row}
                  style={columnHeaders[indexColumn].style}
                >
                  {originalFileContent[indexRow]?.error.includes(
                    columnHeaders[indexColumn].id
                  ) ? (
                    <TextField
                      defaultValue={row[column]}
                      helperText={getHelperText(indexRow, column)}
                      fullWidth={true}
                      onChange={event =>
                        handleChange(
                          event.target.value,
                          indexRow,
                          column,
                          isValidNumber
                        )
                      }
                      error={newFileContent[indexRow].error.includes(column)}
                      inputProps={{ className: classes.input }}
                    />
                  ) : (
                    row[column]
                  )}
                </td>
              ))}
            </tr>
          )
      )}
    </table>
  );
};

export default TextFieldTable;
