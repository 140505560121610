import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loginUser } from "../../actions/auth";
import LoginForm from "../../components/forms/LoginForm";
import { withTranslation } from "react-i18next";
import { CheckErrors } from "../../ErrorTracking";

class LoginContainer extends React.Component {
  constructor(props) {
    super(props);
    this.onLogin = this.onLogin.bind(this);
  }

  onLogin({ email, password }) {
    const { actions, history, location } = this.props;
    const redirectLink =
      location && location.state && location.state.redirectLink;
    const { loginUser: loginUserAction } = actions;
    loginUserAction(email, password, redirectLink, history);
  }

  render() {
    const { loginErrorMessage } = this.props;
    return (
      <CheckErrors>
        <LoginForm
          onSubmit={this.onLogin}
          loginErrorMessage={loginErrorMessage}
        />
      </CheckErrors>
    );
  }
}

LoginContainer.defaultProps = {
  redirectLink: "",
  loginErrorMessage: ""
};

LoginContainer.propTypes = {
  actions: PropTypes.shape({
    loginUser: PropTypes.func
  }).isRequired,
  history: PropTypes.object.isRequired,
  redirectLink: PropTypes.string,
  loginErrorMessage: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ loginUser }, dispatch)
});

const mapStateToProps = ({ user: { redirectLink, loginErrorMessage } }) => ({
  redirectLink,
  loginErrorMessage
});

LoginContainer = connect(mapStateToProps, mapDispatchToProps)(LoginContainer);

export default withRouter(withTranslation()(LoginContainer));
