import React from "react";
import WeightRange from "./WeightRange";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { getCurrencySymbol } from "../../core/shared/CurrencyFormat";
import UserContext from "../../../providers/UserProvider";

const styles = theme => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    padding: "22px 5px 20px 16px",
    flexGrow: "1"
  },
  infoContainer: {
    minWidth: "150px",
    minHeight: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "column",
    flexWrap: "none",
    position: "relative",
    paddingLeft: "8px",
    fontWeight: "bold",
    height: "100%"
  },
  acceptedContainer: {
    display: "flex",
    height: "50px",
    marginBottom: "24px",
    "& span": {
      fontWeight: 500
    }
  },
  legendContainer: {
    display: "flex",
    height: "40px",
    marginBottom: "16px"
  },
  legend: {
    height: "24px",
    marginRight: "5px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  item: {
    flexGrow: "1"
  },
  iconWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "48px",
    height: "104px",
    alignSelf: "normal",
    marginTop: "26px"
  },
  discountButton: {
    color: "#7cb342",
    marginTop: "18px"
  },
  priceButton: {
    color: "#64a7ff"
  },
  popup: {
    color: "grey"
  }
});

class WeightRanges extends React.Component {
  render() {
    const {
      classes,
      weightData,
      isEditing,
      justPrices,
      onWeightCategoryChange,
      onChange,
      isTutorial,
      t
    } = this.props;

    return (
      <UserContext.Consumer>
        {props => (
          <div className={classes.container}>
            {!isTutorial ? (
              <div className={classes.infoContainer}>
                <div className={classes.acceptedContainer}>
                  <span className={classes.legend}>{t("Accepted")}</span>
                </div>
                <div className={classes.legendContainer}>
                  <span className={classes.legend}>{t("Quantity (Kg)")}</span>
                </div>
                <div className={classes.legendContainer}>
                  <span className={classes.legend}>
                    {t("Price discount") +
                      " (" +
                      getCurrencySymbol(props) +
                      ")"}
                  </span>
                </div>
                <div className={classes.legendContainer}>
                  <span className={classes.legend}>
                    {t("My shop price") + " (" + getCurrencySymbol(props) + ")"}
                  </span>
                </div>
                <div className={classes.legendContainer}>
                  <span className={classes.legend}>
                    {t("S24 Index") + " (€)"}
                  </span>
                </div>
              </div>
            ) : null}
            <div
              className={classes.container}
              style={{ paddingBottom: "0px", paddingTop: "0px" }}
            >
              {weightData.map(
                (
                  { start, discount, price, indexPrice, accepted, id, margin },
                  idx
                ) => {
                  if (start === 0) return null;
                  return (
                    <div className={classes.item} key={id}>
                      <WeightRange
                        justPrices={justPrices}
                        range={
                          idx === weightData.length - 1
                            ? [start, Infinity]
                            : [start, weightData[idx + 1].start]
                        }
                        accepted={accepted}
                        discount={discount}
                        pricePerTone={price}
                        isTutorial={isTutorial}
                        indexPrice={indexPrice}
                        isEditing={isEditing}
                        margin={margin}
                        onWeightCategoryChange={() =>
                          onWeightCategoryChange(start)
                        }
                        index={idx}
                        onChange={(price, type) => onChange(price, start, type)}
                      />
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
      </UserContext.Consumer>
    );
  }
}

export default withStyles(styles)(withTranslation()(WeightRanges));
