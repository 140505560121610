import React, { useContext, useEffect, useCallback } from "react";
import { Grid, Typography, Divider, Hidden } from "@material-ui/core";

import { NoSubmitedOffers } from "../../components/offers/NoOpenOffers";
import OfferList, {
  OfferListMobile
} from "../../components/offers/offerList/OfferList";
import OfferElement, {
  OfferElementMobile
} from "../../components/offers/offerElement/OfferElement";
import OfferSearchBar from "../../components/offers/searchBar/OfferSearchBar";

import OffersContext from "../../providers/OffersProvider";
import { useRouteMatch, useHistory } from "react-router";
import containerStyles from "./containerStyles";
import { getProductsCategoriesForOffers } from "./OpenOffers";
import { useTranslation } from "react-i18next";
import useEventTracking from "../../hooks/useEventTracking";

const SubmittedOffers = props => {
  const { submittedOffers, selectedOffer, setSelectedOffer } =
    useContext(OffersContext);
  const classes = containerStyles();
  const match = useRouteMatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const { tenderId } = match.params;

  useEffect(() => {
    TrackEvent("Submitted offers viewed");
  }, []);

  const goBack = useCallback(() => {
    setSelectedOffer(null);
    history.push("/offers/submitted/");
  });

  if (tenderId && selectedOffer !== tenderId) {
    setSelectedOffer(tenderId);
    return null;
  }

  if (tenderId && selectedOffer) {
    const submittedOffer = submittedOffers.find(o => o._id === selectedOffer);
    if (!submittedOffer) {
      history.push("/offers/submitted/");
      setSelectedOffer(null);
      return null;
    }
  }

  if (!tenderId && selectedOffer) {
    setSelectedOffer(null);
    return null;
  }

  const categories = getProductsCategoriesForOffers(submittedOffers);

  return (
    <>
      <Hidden mdUp>
        <MobileSubmittedOffers
          categories={categories}
          offers={submittedOffers}
          selectedOffer={selectedOffer}
          goBack={goBack}
        />
      </Hidden>
      <Hidden smDown>
        <Grid
          container
          direction="column"
          wrap="nowrap"
          className={classes.mainContainer}
        >
          <Grid item className={classes.container}>
            <Typography variant="h5" className={classes.header}>
              {t("Submitted offers")}
            </Typography>
            {submittedOffers.length === 0 && <NoSubmitedOffers />}
          </Grid>
          <Grid item>
            <Divider className={classes.divider} />
          </Grid>

          {submittedOffers.length > 0 && (
            <>
              <OfferSearchBar categories={categories} />
              <Grid item>
                <Divider className={classes.divider} />
              </Grid>
              <Grid className={classes.mainContentContainer}>
                <OfferList
                  offers={submittedOffers}
                  offerType="submitted"
                  canBid={false}
                />
                <OfferElement
                  offers={submittedOffers}
                  offerType="submitted"
                  canBid={false}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Hidden>
    </>
  );
};

const MobileSubmittedOffers = ({
  offers,
  selectedOffer,
  categories,
  goBack
}) => {
  const classes = containerStyles();

  return (
    <Grid
      container
      direction="column"
      wrap="nowrap"
      className={classes.mainContainerMobile}
    >
      {offers.length === 0 ? (
        <Grid item className={classes.containerMobile}>
          <NoSubmitedOffers />
        </Grid>
      ) : (
        <Grid item className={classes.containerMobile}>
          <OfferSearchBar categories={categories} offers={offers} />
          <OfferListMobile
            offers={offers}
            offerType="submitted"
            canBid={false}
          />
          <OfferElementMobile
            offers={offers}
            offerType="submitted"
            selectedOffer={selectedOffer}
            goBackAction={goBack}
            canBid={false}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default SubmittedOffers;
