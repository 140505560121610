import React from "react";

import { IconButton, Typography, makeStyles } from "@material-ui/core";

const Day = props => {
  const useStyles = makeStyles(theme => ({
    leftBorderRadius: {
      borderRadius: "50% 0 0 50%"
    },
    rightBorderRadius: {
      borderRadius: "0 50% 50% 0"
    },
    buttonContainer: {
      marginBottom: "2px",
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        width: "14%"
      },
      "& .MuiIconButton-root": {
        color: "#151124",
        "&:hover": {
          background: "#52F597"
        }
      }
    },

    button: {
      height: 36,
      width: 36,
      marginRight: 2,
      padding: 0
    },
    lastButton: { marginRight: "0!important" },
    buttonText: {
      fontWeight: 400,
      fontSize: 12,
      lineHeight: "14px"
    },
    disabledText: {
      color: "#9E9E9E"
    },
    outlined: {
      border: `2px solid ${theme.palette.primary.main}`
    },
    filled: {
      fontWeight: "500",
      "&:hover": {
        background: `${theme.palette.primary.main} !important`,
        color: "#fff !important"
      },
      background: theme.palette.secondary.main
    },
    highlighted: {
      background: theme.palette.action.hover
    },
    forHeader: {
      height: "50px",
      marginBottom: "12px",
      marginTop: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        width: "14%",
        justifyContent: "flex-start"
      }
    },
    forHeaderText: {
      color: "#9e9e9e",
      textAlign: "center",
      fontSize: "12px",
      fontFamily: "Roboto",
      lineHeight: "166%",
      letterSpacing: "0.4px",
      display: "flex",
      height: "28px"
    }
  }));

  const classes = useStyles();

  const {
    startOfRange,
    endOfRange,
    disabled,
    outlined,
    filled,
    highlighted,
    onClick,
    forHeader = false,
    hidden = false,
    isEndOfWeek = false
  } = props;

  return (
    <div
      className={`
        ${classes.buttonContainer}
        ${startOfRange && classes.leftBorderRadius}
        ${forHeader && classes.forHeader}
        ${endOfRange && classes.rightBorderRadius}
        ${!disabled && highlighted && classes.highlighted}
      `}
    >
      <IconButton
        className={`  
        ${classes.button}
          ${isEndOfWeek && classes.lastButton}
          ${outlined && !hidden && classes.outlined}
          ${!disabled && filled && classes.filled}
        `}
        disabled={disabled}
        onClick={onClick}
      >
        {" "}
        {!hidden && (
          <Typography
            className={`
            ${disabled && classes.disabledText}
            ${forHeader && classes.forHeaderText}
          `}
            variant="body2"
          >
            {props.value}
          </Typography>
        )}
      </IconButton>
    </div>
  );
};

export default Day;
