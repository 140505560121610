import {
  Box,
  IconButton,
  makeStyles,
  TextField,
  Hidden,
  Typography,
  Chip
} from "@material-ui/core";
import CustomList from "../../../../../../components/general/List";
import FILE_TYPES_OPTIONS, {
  getDataTypesForOtherFileType
} from "./fileTypeOptions";
import { Check, Delete, Visibility } from "@material-ui/icons";
import Info from "@material-ui/icons/Info";
import classNames from "classnames";
import { useCallback, useState } from "react";
import useDocPreview from "../../../../../../hooks/useDocPreview";
import { getFilenameAndExtension } from "../../../../../../utils/Format";
import LoadingCircle from "../../../../../general/LoadingCircle";
import { acceptedFileExtensions } from "../../../../../../utils/constants";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(theme => ({
  infoText: { color: "rgba(0, 0, 0, 0.6)" },
  filelistHeaderContainer: {
    display: "flex"
  },
  fileExtension: {
    fileExtension: { color: "rgba(0, 0, 0, 0.87)" },
    width: "50px"
  },
  customListContainer: {
    maxWidth: "184px",
    marginLeft: "0px",
    width: "-webkit-fill-available"
  },
  root: {
    [theme.breakpoints.down("sm")]: {
      padding: "24px 16px "
    },
    background: "#F9F9F9",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    padding: "24px 24px 16px",
    color: "rgba(0, 0, 0, 0.6)",
    letterSpacing: "0.25px",
    lineHeight: "24px",
    fontWeight: "400",
    "& .MuiTypography-caption": {
      fontWeight: "400"
    }
  },
  firstSectionHeader: {
    width: "340px"
  },
  fileSelectionHeader: {
    color: "rgba(0, 0, 0, 0.87)",
    fontWeight: "500"
  },
  fileContainer: {
    width: "420px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    display: "flex",
    gap: "10px"
  },
  filelist: {
    display: "flex",
    flexDirection: "column",
    gap: "16px"
  },
  containerBox: {
    marginTop: "16px"
  },
  filePlaceholder: {
    display: "flex",
    gap: "8px",
    height: "24px",
    marginBottom: "24px",
    "& > span": {
      lineHeight: "24px"
    }
  },
  file: {
    display: "flex",
    gap: "8px",
    minHeight: "24px",
    "& > span": {
      lineHeight: "24px"
    }
  },
  captionSVG: {
    marginRight: "6px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  iconButton: {
    height: "24px",
    width: "24px",
    padding: 0,
    "&.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)"
    }
  },
  renameFile: {
    display: "flex",
    alignItems: "center",
    gap: "4px",
    "& >.MuiTextField-root": {
      maxWidth: "280px"
    }
  },
  alignItems: {
    alignItems: "center"
  }
}));

const FileList = ({
  className,
  files,
  setFiles,
  canRename,
  canPreview,
  canRemove,
  uploading,
  fileTypeSelector,
  disableInvoiceUpload = false,
  isDriver = false
}) => {
  const classes = useStyles();
  const { setDocument } = useDocPreview();
  const [long, setLong] = useState(false);
  const setDocumentType = useCallback(
    (index, option) => {
      setFiles(
        files.map((file, i) => {
          if (i === index) {
            let newFile = new File([file], files[index].name, {
              type: file.type
            });

            newFile.documentType = option[0].value;
            newFile.preview = file.preview;

            return newFile;
          }
          return file;
        })
      );
    },
    [files, setFiles]
  );

  const renameFile = useCallback(
    (index, name) => {
      if (name.length > 90) {
        setLong(true);
        return;
      } else {
        setLong(false);
      }
      setFiles(
        files.map((file, i) => {
          if (i === index) {
            let newFile = new File([file], name, {
              type: file.type
            });
            newFile.preview = file.preview;
            return newFile;
          }
          return file;
        })
      );
    },
    [files, setFiles]
  );

  const previewFile = useCallback(file => {
    setDocument({
      documentName: file.name,
      downloadLink: file.preview,
      fileType: file.type,
      isUploadPreview: true
    });
  }, []);

  const removeFile = useCallback(
    index => {
      files.splice(index, 1);
      setFiles([...files]);
    },
    [files, setFiles]
  );

  if (uploading) {
    canRename = false;
    canPreview = false;
    canRemove = false;
  }
  const { t } = useTranslation();

  return (
    <div>
      <div className={classNames(className, classes.root)}>
        <div className={classes.filelistHeaderContainer}>
          <Typography
            variant="body1"
            className={classNames(
              classes.fileSelectionHeader,
              classes.firstSectionHeader
            )}
          >
            {isDriver ? "Selected files" : t("DragDropSelectedFiles")}
          </Typography>
          {fileTypeSelector === true && files && files.length > 0 ? (
            <Typography variant="body1" className={classes.fileSelectionHeader}>
              {isDriver ? "Document type" : t("UploadDocumentType")}
            </Typography>
          ) : (
            <></>
          )}
        </div>
        {files && files.length > 0 ? (
          <div className={classes.filelist}>
            {files.map((file, i) => (
              <FileItem
                fileTypeSelector={fileTypeSelector}
                index={i}
                file={file}
                canRename={canRename}
                onRename={renameFile}
                canPreview={canPreview}
                onPreview={previewFile}
                canRemove={canRemove}
                onRemove={removeFile}
                uploading={uploading}
                setDocumentType={setDocumentType}
                disableInvoiceUpload={disableInvoiceUpload}
                isDriver={isDriver}
                long={long}
              />
            ))}
          </div>
        ) : (
          <Typography variant="body1" className={classes.filePlaceholder}>
            <span>
              {isDriver
                ? "No files selected yet"
                : t("DragDropNoFilesSelected")}
            </span>
            <ActionButtons disabled />
          </Typography>
        )}

        <Hidden mdDown>
          {!uploading && (
            <Box display="flex" alignItems="center" marginTop="24px">
              <Info className={classes.captionSVG} />

              <Typography variant="caption" className={classes.infoText}>
                {isDriver
                  ? "You can review the names of the selected files, preview or remove them from the upload queue."
                  : t("DragDropInfo")}
              </Typography>
            </Box>
          )}
        </Hidden>
      </div>
      <Hidden mdUp>
        {!uploading && (
          <Box display="flex" alignItems="center" marginTop="16px">
            <Info className={classes.captionSVG} />
            <Typography variant="caption" className={classes.infoText}>
              {isDriver
                ? "You can review the names of the selected files, preview or remove them from the upload queue."
                : t("DragDropInfo")}
            </Typography>
          </Box>
        )}
      </Hidden>
    </div>
  );
};

const FileItem = ({
  index,
  fileTypeSelector = false,
  file = {},
  canRename = true,
  onRename = () => {},
  canPreview = true,
  onPreview = () => {},
  canRemove = true,
  onRemove = () => {},
  setDocumentType = () => {},
  uploading,
  disableInvoiceUpload = false,
  isDriver = false,
  long
}) => {
  const classes = useStyles();

  const [filename, extension] = getFilenameAndExtension(file.name);
  const { t } = useTranslation();

  if (!acceptedFileExtensions.includes(extension.toLowerCase())) {
    onRemove(index);
    return;
  }

  const fileTypeName =
    file.documentType !== undefined
      ? FILE_TYPES_OPTIONS.filter(item => item.value === file.documentType)[0]
          .label
      : null;

  const dataTypes = getDataTypesForOtherFileType(t, disableInvoiceUpload);
  return (
    <span
      key={"file_" + index}
      className={classNames(classes.file, canRename && classes.alignItems)}
    >
      {canRename ? (
        <Typography variant="body1" className={classes.renameFile}>
          <TextField
            style={{ backgroundColor: "#ffffff" }}
            label=""
            value={filename}
            onChange={({ currentTarget }) => {
              onRename(index, currentTarget.value + "." + extension);
            }}
            variant="outlined"
            InputLabelProps={{ shrink: false }}
            error={long}
            helperText={
              long &&
              (isDriver
                ? "Please adjust the title to ensure it does not exceed 90 characters."
                : t("maxCharLength90_uploadFile"))
            }
          />

          <span className={classes.fileExtension}>.{extension}</span>
        </Typography>
      ) : (
        <div className={classes.fileContainer}>
          {uploading && (file.completed ? <Check /> : <LoadingCircle />)}
          <span>{file.name}</span>
        </div>
      )}

      {fileTypeSelector ? (
        <div className={classes.customListContainer}>
          <CustomList
            dense={false}
            searchable={false}
            data={dataTypes}
            placeholder={isDriver ? "Document type" : t("UploadDocumentType")}
            multiple={false}
            checkbox={false}
            onChange={option => setDocumentType(index, option)}
            overrideHeight={"34"}
          />
        </div>
      ) : file.documentType !== undefined ? (
        <Chip disabled label={t(fileTypeName)} onClick={() => {}} />
      ) : (
        <></>
      )}

      <ActionButtons
        canPreview={canPreview}
        onPreview={() => onPreview(file)}
        canRemove={canRemove}
        onRemove={() => onRemove(index)}
      />
    </span>
  );
};

const ActionButtons = ({
  canPreview = true,
  onPreview,
  canRemove = true,
  onRemove,
  disabled
}) => {
  const classes = useStyles();

  return (
    <>
      {canPreview && (
        <IconButton
          disabled={disabled}
          onClick={onPreview}
          className={classes.iconButton}
          disableFocusRipple
          disableRipple
        >
          <Visibility />
        </IconButton>
      )}
      {canRemove && (
        <IconButton
          disabled={disabled}
          onClick={onRemove}
          className={classes.iconButton}
          disableFocusRipple
          disableRipple
        >
          <Delete />
        </IconButton>
      )}
    </>
  );
};

export default FileList;
