import React from "react";
import ModalWrapper from "./../../transactions/layout/Modals/ModalClass";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
const useStyles = makeStyles(theme => ({
  container: {
    background: "white",
    width: "600px",
    boxShadow:
      "0px 19px 38px rgba(0, 0, 0, 0.3), 0px 15px 12px rgba(0, 0, 0, 0.22)",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    padding: "32px 24px 24px 24px",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 32px)",
      height: "auto"
    }
  },
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.15px",
    color: "rgba(0, 0, 0, 0.87)",
    marginBottom: "16px"
  },
  description: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.5px",
    color: "rgba(0, 0, 0, 0.6)",
    marginBottom: "34px"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-end"
    }
  },
  button: {
    marginRight: "16px"
  },
  buttonBottom: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "16px",
      marginRight: "16px"
    }
  }
}));

const FullLoadUpdate = props => {
  const { close, onChange } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const keepPrice = value => {
    onChange(value);
    close();
  };

  return (
    <ModalWrapper
      onCloseModal={close}
      isOpen={true}
      className={classes.container}
      noCloseButton={false}
      disableBackdropClick={false}
    >
      <span className={classes.title}>{t("Full load update")}</span>
      <span className={classes.description}>
        {t("You are about to update the prices with the full load price")}
      </span>
      <div className={classes.buttonContainer}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => keepPrice(null)}
        >
          {t("Keep my shop price")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => keepPrice("discount")}
          className={classes.buttonBottom}
        >
          {t("Keep my margin")}
        </Button>
      </div>
    </ModalWrapper>
  );
};

export default FullLoadUpdate;
