import React from "react";
import Grid from "@material-ui/core/Grid";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/core/styles";
import ImageIconAss from "../../assets/icon3assigment.png";

const styles = theme => ({
  root: {
    minWidth: "100%",
    minHeight: "100%",
    flexGrow: 1
  },
  wrapper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: "2em"
  },
  wrapperSub: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    marginTop: "1em"
  },
  title: {
    fontSize: "35px"
  },
  circle: {
    margin: "0 auto",
    padding: "20px",
    background: "#FCEFDB",
    borderRadius: "50%",
    width: "50vw",
    height: "50vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  imageFake: {
    width: "30vw",
    height: "25vw"
  }
});

const Step4 = ({ classes }) => (
  <div className={classes.root}>
    <Grid container spacing={24} justify="center" alignItems="center">
      <Grid item xs={12}>
        <div className={classes.wrapper}>
          <Typography
            variant="title"
            gutterBottom
            align="center"
            className={classes.title}
          >
            Vielen Dank!
          </Typography>
          <Typography variant="caption" gutterBottom align="center">
            Wir werden Ihre Daten prüfen und Sie verifizieren, sodass Sie
            Zahlungen erhalten können.
          </Typography>
          <Typography variant="caption" gutterBottom align="center">
            Dies ist ein einmaliger Prozess, für zukünftige Verkäufe müssen Sie
            die Verifizierung nicht erneut durchführen.
          </Typography>
        </div>
      </Grid>
      <Grid item xs={12}>
        <div className={classes.wrapperSub}>
          <div className={classes.circle}>
            <img
              src={ImageIconAss}
              alt="ImageIconAss"
              className={classes.imageFake}
            />
          </div>
        </div>
      </Grid>
    </Grid>
  </div>
);

Step4.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Step4);
