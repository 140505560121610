import React from "react";
import { makeStyles, createStyles, Grid, Typography } from "@material-ui/core";
import { Button } from "./offerElement/inputComponents";
import { useHistory } from "react-router";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import CommentIcon from "@material-ui/icons/Comment";
import useStandardLocalization from "../../hooks/useStandardLocalization";

const biddingStyles = makeStyles(
  createStyles(theme => ({
    masterContainer: {
      paddingBottom: "20px"
    },
    masterContainerMobile: {
      padding: "24px"
    },
    container: {
      background: "#F9F9F9",
      border: "1px solid rgba(0, 0, 0, 0.12)",
      padding: "24px 20px 20px",
      borderRadius: 4,
      color: "rgba(0, 0, 0, 0.6)",
      [theme.breakpoints.down("md")]: {
        padding: 20
      }
    },
    containerMobile: {
      marginTop: "20px"
    },
    infoValue: {
      marginLeft: 4,
      color: "rgba(0, 0, 0, 0.87)",
      fontWeight: 500
    },
    goBackButton: {
      marginTop: "20px",
      width: "100%",
      [theme.breakpoints.down("md")]: {
        marginTop: 16
      }
    },
    biddingItemsContainer: {
      display: "flex",
      flexDirection: "column",
      height: "fit-content"
    },
    productName: {
      fontSize: 24,
      marginBottom: 16,
      color: "rgba(0, 0, 0, 0.87)",
      [theme.breakpoints.down("md")]: {
        marginBottom: 12,
        fontSize: 20
      }
    },
    commentContainer: {
      marginTop: 16,
      flexWrap: "nowrap",
      [theme.breakpoints.down("md")]: {
        marginTop: 12
      }
    },
    commentText: {
      marginLeft: 12,
      lineHeight: "24px",
      letterSpacing: 0.25,
      color: "rgba(0, 0, 0, 0.87)",
      wordWrap: "break-word",
      maxWidth: "90%"
    }
  }))
);

const BiddingItems = ({ offer, bidInfo }) => {
  const classes = biddingStyles();

  return (
    <Grid container item className={classes.biddingItemsContainer}>
      {offer.products.map(o => {
        const bidInfoItem = bidInfo[o.randomId];
        return (
          <Grid container item className={classes.masterContainer}>
            <BiddingItemsItem offer={offer} o={o} bidInfoItem={bidInfoItem} />
          </Grid>
        );
      })}
    </Grid>
  );
};

export const BiddingItemsMobile = ({ offer, bidInfo }) => {
  const classes = biddingStyles();

  return (
    <Grid container item xs={12} style={{ height: "fit-content" }}>
      {offer.products.map(o => {
        const bidInfoItem = bidInfo[o.randomId];
        return (
          <Grid container item xs={12}>
            <BiddingItemsItem
              offer={offer}
              o={o}
              bidInfoItem={bidInfoItem}
              className={classes.containerMobile}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

const BiddingItemsItem = ({ offer, o, bidInfoItem, className }) => {
  const classes = biddingStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const { parseNumber, parseCurrency } = useStandardLocalization();

  return (
    <Grid
      container
      item
      direction="column"
      className={classnames(classes.container, className)}
    >
      <Grid item>
        <Typography variant="body1" className={classes.productName}>
          {o.productName}
        </Typography>
      </Grid>
      <Grid item container>
        <Typography variant="body1">{t("Quantity")}:</Typography>
        <Typography variant="body1" className={classes.infoValue}>
          {parseNumber(o.quantity)} mt
        </Typography>
      </Grid>
      {bidInfoItem && !bidInfoItem.editing && (
        <Grid item container>
          <Typography variant="body1">{t("Your bid")}:</Typography>
          <Typography variant="body1" className={classes.infoValue}>
            {parseCurrency(bidInfoItem.price)}/mt
          </Typography>
        </Grid>
      )}
      {bidInfoItem.comment && !bidInfoItem.editing && (
        <Grid item container className={classes.commentContainer}>
          <CommentIcon color="action" />
          <Typography variant="body1" className={classes.commentText}>
            {bidInfoItem.comment}
          </Typography>
        </Grid>
      )}
      {!(bidInfoItem && !bidInfoItem.editing) && (
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            className={classes.goBackButton}
            onClick={() => history.push("/offers/open/" + offer._id)}
          >
            {t("Go back to the materials")}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default BiddingItems;
