import React, { useCallback, useMemo, useState, useContext } from "react";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import {
  CONTRACTS_STEPS,
  TRANSACTIONS_STEPS,
  StepTitleComponent
} from "./steps";
import { IMPORT_DATA_CONTRACT_TYPE } from "./utils/constants";
import { useHistory } from "react-router-dom";

const ImportDataComponent = ({ type }) => {
  const [step, setStep] = useState(0);
  const [file, setFile] = useState(null);
  const [fileContent, setFileContent] = useState(null);
  const theme = useTheme();
  const history = useHistory();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const STEPS =
    type === IMPORT_DATA_CONTRACT_TYPE ? CONTRACTS_STEPS : TRANSACTIONS_STEPS;

  const setPrevStepAction = useCallback(() => {
    if (step === 0) history.push("/sales");
    setStep(step - 1);
  }, [step, setStep]);

  const setNextStepAction = useCallback(() => {
    if (step === STEPS.length - 1) return;

    setStep(step + 1);
  }, [step, setStep]);

  const setNext2StepAction = useCallback(() => {
    if (step === STEPS.length - 2) return;

    setStep(step + 2);
  }, [step, setStep]);

  const {
    title,
    titleIcon,
    subtitle,
    component: Component
  } = useMemo(() => STEPS[step], [step]);

  const fullTitle = StepTitleComponent({
    fullScreen,
    title,
    titleIcon,
    type,
    subtitle
  });

  return (
    <Component
      file={file}
      title={fullTitle}
      setFile={setFile}
      fullScreen={fullScreen}
      setPrevStepAction={setPrevStepAction}
      setNextStepAction={setNextStepAction}
      type={type}
      fileContent={fileContent}
      setFileContent={setFileContent}
      setNext2StepAction={setNext2StepAction}
    />
  );
};

export default ImportDataComponent;
