import React, { Fragment } from "react";
import { InputBase, Hidden, Button, useTheme } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";
import { useTranslation } from "react-i18next";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import PurchaseMobileFilters from "./PurchaseMobileFilters";
import FilterListIcon from "@material-ui/icons/FilterList";
import PrintSharpIcon from "@material-ui/icons/PrintSharp";
import SearchIcon from "@material-ui/icons/Search";
import { SelectMenuProps } from "../../../core/shared/constants";

const PurchaseDesktopFilters = props => {
  const theme = useTheme();

  const useStyles = makeStyles({
    inputLabel: {
      transform: "translate(14px, 13px) scale(1)"
    },
    selectProduct: {
      height: "40px",
      width: "240px"
    },
    productDropdown: {
      "& .MuiInputBase-input": {
        transform: "translateY(-1px)",
        padding: "10px 24px",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "16px",
        lineHeight: "24px",
        letterSpacing: "0.5px",
        color: "rgba(0, 0, 0, 0.87)"
      },
      width: "100%"
    },
    wrapper: {
      display: "flex",
      alignItems: "center",
      color: "#DEDEDE",
      marginBottom: "24px",
      padding: "0 32px",
      flexWrap: "wrap",
      gap: 20,
      "& .MuiSelect-root": {
        padding: "0 25px 0 16px",
        "&:focus": {
          backgroundColor: "white"
        }
      }
    },
    searchInputContainer: {
      "& .Mui-focused": {
        outline: "2px solid #52F597 !important"
      }
    },
    searchInput: {
      width: "240px",
      height: "40px",
      outline: "1px solid rgba(0, 0, 0, 0.24)",
      "&:hover": {
        outline: "1px solid rgba(0, 0, 0, 0.9)"
      },
      "& > input": {
        fontSize: "16px",
        letterSpacing: ".15px"
      }
    },
    inputGrey: {
      padding: "0 2px 0 12px"
    },
    absoluteBottom: {
      width: "100%",
      position: "fixed",
      bottom: "16px",
      zIndex: 10
    },
    buttonContainer: {
      width: "100%",
      textAlign: "center"
    },
    bottomFilter: {
      backgroundColor: theme.palette.primary.main,
      color: "white",
      borderRadius: "64px",
      boxShadow:
        "0px 2px 6px rgba(0, 0, 0, 0.18), 0px 2px 4px rgba(0, 0, 0, 0.24)",
      width: "126px",
      height: "48px",
      fontWeight: "500",
      fontSize: "14px"
    },
    filterListIcon: {
      marginRight: "15px"
    },
    searchMobileInputContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      padding: "0 24px 24px 16px",
      "& .Mui-focused": {
        outline: "2px solid #52F597"
      }
    },
    searchMobileInput: {
      marginRight: 24,
      width: "100%",
      height: "40px",
      outline: "1px solid #9E9E9E"
    },
    adornment: {
      padding: "0 2px 0 12px"
    }
  });

  const classes = useStyles();

  const { t } = useTranslation();

  const {
    transport,
    startDateChanged,
    endDateChanged,
    filterChanged,
    searchText,
    startDate,
    endDate,
    onFilterReset,
    total,
    product,
    products,
    transports,
    transaction,
    onChangeSelectedTransaction,
    onChangeSelectedProduct,
    onChangeSelectedTransport,
    downloadFile,
    changeShowText,
    isOpen,
    close,
    open,
    disabled = false
  } = props;

  const selectedTransactionChanged = event => {
    props.onChangeSelectedTransaction(event.target.value);
  };

  const selectedTransportChanged = event => {
    props.onChangeSelectedTransport(event.target.value);
  };
  const selectedProductChanged = event => {
    props.onChangeSelectedProduct(event.target.value);
  };

  return (
    <Fragment>
      <Hidden smDown>
        <div className={classes.wrapper}>
          <div className={classes.searchInputContainer}>
            <InputBase
              placeholder={t("Search transaction ID")}
              className={classes.searchInput}
              startAdornment={
                <InputAdornment position="start" className={classes.inputGrey}>
                  <Search color="action" />
                </InputAdornment>
              }
              onChange={filterChanged}
              value={searchText || ""}
              disabled={disabled}
            />{" "}
          </div>

          <div>
            <FormControl
              variant="outlined"
              className={classes.productDropdown}
              disabled={disabled}
            >
              <InputLabel
                id="all-products-dropdown"
                className={classes.inputLabel}
              >
                {t("Show products")}
              </InputLabel>
              <Select
                labelId="all-products-dropdown"
                id="select-products"
                value={props.product}
                onChange={selectedProductChanged}
                label={t("Show products")}
                className={classes.selectProduct}
                MenuProps={SelectMenuProps}
              >
                <MenuItem value="all">{t("All")}</MenuItem>

                {products
                  ? products.map(product => (
                      <MenuItem
                        value={product.productID}
                        key={product.productID}
                      >
                        {product.productName}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </div>

          <div>
            <FormControl
              variant="outlined"
              className={classes.productDropdown}
              disabled={disabled}
            >
              <InputLabel
                id="all-transport-dropdown"
                className={classes.inputLabel}
              >
                {t("Purchases_ShowTransport")}
              </InputLabel>
              <Select
                labelId="all-transport-dropdown"
                id="select-transport"
                value={props.transport}
                onChange={selectedTransportChanged}
                label={t("Show Transport")}
                className={classes.selectProduct}
                MenuProps={SelectMenuProps}
              >
                <MenuItem value="all">{t("All")}</MenuItem>

                {transports
                  ? _.compact(transports).map(transport => (
                      <MenuItem
                        value={transport.transportId}
                        key={transport.transportId}
                      >
                        {transport.option}
                      </MenuItem>
                    ))
                  : null}
              </Select>
            </FormControl>
          </div>
          <div>
            <FormControl
              variant="outlined"
              className={classes.productDropdown}
              disabled={disabled}
            >
              <InputLabel
                id="all-transaction-dropdown"
                className={classes.inputLabel}
              >
                {t("Show transaction")}
              </InputLabel>
              <Select
                labelId="all-transaction-dropdown"
                id="select-transaction"
                value={props.transaction}
                onChange={selectedTransactionChanged}
                label={t("Show transactions")}
                className={classes.selectProduct}
                MenuProps={SelectMenuProps}
              >
                <MenuItem value="all">{t("All")}</MenuItem>
                <MenuItem value="webshop">Webshop</MenuItem>
                <MenuItem value="schrott24">Metaloop</MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>
      </Hidden>
      <Hidden mdUp>
        <div className={classes.searchMobileInputContainer}>
          <InputBase
            placeholder={t("Search transaction ID")}
            className={classes.searchMobileInput}
            startAdornment={
              <InputAdornment position="start" className={classes.adornment}>
                <SearchIcon color="action" />
              </InputAdornment>
            }
            onChange={filterChanged}
            value={searchText || ""}
            disabled={disabled}
          />{" "}
          <PrintSharpIcon
            onClick={downloadFile}
            color="action"
            disabled={disabled}
          />
        </div>

        <PurchaseMobileFilters
          isOpen={isOpen}
          close={close}
          open={open}
          startDateChanged={startDateChanged}
          endDateChanged={endDateChanged}
          filterChanged={filterChanged}
          searchText={searchText}
          startDate={startDate}
          endDate={endDate}
          onFilterReset={onFilterReset}
          total={total}
          products={products}
          product={product}
          transport={transport}
          transports={transports}
          onChangeSelectedTransaction={onChangeSelectedTransaction}
          onChangeSelectedProduct={onChangeSelectedProduct}
          onChangeSelectedTransport={onChangeSelectedTransport}
          changeShowText={changeShowText}
          transaction={transaction}
          disabled={disabled}
        />

        <div className={classes.absoluteBottom}>
          <div className={classes.buttonContainer}>
            <Button className={classes.bottomFilter} onClick={open}>
              <FilterListIcon className={classes.filterListIcon} />
              {t("FILTERS")}
            </Button>
          </div>
        </div>
      </Hidden>
    </Fragment>
  );
};

export default PurchaseDesktopFilters;
