import React, { useContext, useState, memo } from "react";
import { useQuery } from "react-apollo";
import UserContext from "../../providers/UserProvider";
import GET_PARTNER_PRICES_FOR_DAY from "../../queries/GetPartnerPricesForDay";
import Loader from "../core/shared/Loader";
import {
  Grid,
  Typography,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
  makeStyles
} from "@material-ui/core";

import TablePagination from "@material-ui/core/TablePagination";
import classnames from "classnames";
import moment from "moment";
import { useTranslation } from "react-i18next";
import useStandardLocalization from "../../hooks/useStandardLocalization";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    overflow: "auto",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "96px"
    }
  },
  table: {
    minWidth: 900
  },
  idCell: {
    width: "168px"
  },
  firstCell: {
    paddingLeft: "32px !important",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "16px !important"
    }
  },
  lastCell: {
    paddingRight: "32px",
    [theme.breakpoints.down("sm")]: {
      paddingRight: "16px !important"
    }
  },
  headerCell: {
    fontSize: "16px",
    letterSpacing: "0.25px"
  }
}));

const Rules = ({ info }) => {
  const { parseNumber, parseCurrency } = useStandardLocalization();

  if (!info) return null;
  const width = 100 / info.length + "%";
  let start = info.map((rule, indx) => (
    <td key={`${rule.start}-${indx}`}>
      <Typography variant="subtitle2">{parseNumber(rule.start)} kg</Typography>
    </td>
  ));
  let end = info.map((rule, indx) => (
    <td style={{ width }} key={`${rule.start}-${indx}`}>
      {parseCurrency(rule.price)}
    </td>
  ));
  return (
    <table width="100%">
      <tbody>
        <tr>{start}</tr>
        <tr>{end}</tr>
      </tbody>
    </table>
  );
};

const filterData = (
  data,
  products,
  categories,
  updateData,
  currentPage,
  rowsPerPage
) => {
  if (categories && categories.length > 0) {
    data = data.filter(
      singleCategory =>
        categories.filter(category =>
          singleCategory.categoryId.includes(parseInt(category.value))
        ).length > 0
    );
  }

  if (products && products.length > 0) {
    data = data.filter(
      singleProduct =>
        products.filter(product => product.value === singleProduct.productID)
          .length > 0
    );
  }
  data = data.slice(currentPage * rowsPerPage, (currentPage + 1) * rowsPerPage);
  updateData(data);

  return data;
};

const PriceListTable = memo(({ filters, productList, updateData }) => {
  const [elementsPerPage, setElementsPerPage] = useState(5);

  const [currentPage, setCurrentPage] = useState(0);

  const { user, selectedCompany } = useContext(UserContext);
  const classes = useStyles();
  const { t } = useTranslation();

  const { date, categories, products } = filters;

  const { loading, error, data } = useQuery(GET_PARTNER_PRICES_FOR_DAY, {
    variables: {
      token: user && user.token,
      partnerId: selectedCompany,
      date: moment(date).format("DD-MM-YYYY")
    },
    fetchPolicy: "cache-and-network"
  });

  if (loading) return <Loader />;
  if (error) return <h1>GraphQL Error: {error}</h1>;

  const { getPartnerPricesForDay: prices } = data;

  if (!prices)
    return (
      <Grid container item xs alignItems="center" justify="center">
        <Grid item>
          <Typography variant="h5">{t("No data for this date")}</Typography>
        </Grid>
      </Grid>
    );

  const pricesData = joinProductsAndPrices(productList, prices);
  const dataTable = filterData(
    pricesData,
    products,
    categories,
    updateData,
    currentPage,
    elementsPerPage
  );
  return (
    <Grid item className={classes.root}>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell
              className={classnames(classes.idCell, classes.firstCell)}
            >
              <Typography variant="subtitle2" className={classes.headerCell}>
                {t("Product ID")}
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="subtitle2" className={classes.headerCell}>
                {t("Product Name")}
              </Typography>
            </TableCell>
            <TableCell className={classes.lastCell} />
          </TableRow>
        </TableHead>
        <TableBody>
          {dataTable.map(product => (
            <TableRow key={product.productID}>
              <TableCell className={classes.firstCell}>
                {product.productID}
              </TableCell>
              <TableCell>{product.productName}</TableCell>
              <TableCell align="right" className={classes.lastCell}>
                <Rules info={product.rules} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={pricesData.length}
        rowsPerPage={elementsPerPage}
        page={currentPage}
        onPageChange={(event, newPage) => {
          setCurrentPage(newPage);
        }}
        onRowsPerPageChange={event => {
          // Reset to the first page when changing items per page
          setCurrentPage(0);
          setElementsPerPage(event.target.value);
        }}
        labelRowsPerPage={t("Sales_TransactionspPage")}
      />
    </Grid>
  );
});

export default PriceListTable;

const joinProductsAndPrices = (products, prices) => {
  if (!prices) return null;
  prices = prices.priceData;
  let newProductsWithPrices = {};
  products.forEach(product => {
    newProductsWithPrices[product.productID] = product;
  });
  prices.forEach(price => {
    let key = price.productId.toString();
    if (newProductsWithPrices[key])
      newProductsWithPrices[key].rules = price.rules;
  });
  let result = [];
  Object.keys(newProductsWithPrices).forEach(elem => {
    result.push(newProductsWithPrices[elem]);
  });
  return result;
};
