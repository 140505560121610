import React, { useState } from "react";
import ModalWrapper from "./ModalClass";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import TransactionMobileCart from "../../tables/TransactionMobileCart";
import TransactionsGallery from "../../../core/shared/TransactionsGallery";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";
import CloseIcon from "@material-ui/icons/Close";
import TransactionsCard from "../../tables/TransactionsCard";
import FileList from "../../../core/shared/FileList";
import useFilePartition from "../../../../hooks/useFilePartition";
import UploadDialog from "../../../core/shared/uploadDialog";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import useEventTracking from "../../../../hooks/useEventTracking";
import { WEIGHTNOTE_KEY } from "../../../core/shared/uploadDialog/steps";

const UploadPricesPopup = props => {
  const useStyles = makeStyles({
    closeButton: {
      marginRight: "16px"
    },
    clip: {
      marginRight: 8
    },
    buttonsWrapper: {
      display: "flex",
      flexDirection: "column",
      gap: 16,
      margin: "0 0 24px"
    },
    commentWrapper: {
      marginBottom: "24px",
      color: "rgba(0, 0, 0, 0.60) !important"
    },
    inlineWrapper: {
      display: "block",
      fontStyle: "normal",
      fontWeight: "normal",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)"
    },
    property: {
      color: "rgba(0, 0, 0, 0.60)",
      fontWeight: "normal",
      marginRight: "5px",
      height: "fit-content"
    },
    modalPadding: {
      paddingLeft: "16px",
      paddingRight: "16px"
    },
    addIcon: {
      marginRight: "12px"
    },
    container: {
      padding: 0,
      width: "100%"
    },
    modalTitle: {
      fontWeight: 500,
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "8px"
    },
    titleContainer: {
      display: "flex",
      alignItems: "center",
      borderBottom: "1px solid rgba(128, 128, 128, 0.5)",
      paddingLeft: "30px",
      height: "60px"
    },
    buttonContainer: {
      padding: "30px"
    },
    marginTop: {
      marginTop: "24px"
    },
    value: {
      color: "black",
      fontWeight: "normal",
      marginLeft: "5px"
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end"
    },
    buttonCsv: {
      background: "#7cb342",
      border: "none",
      borderRadius: "5px",
      padding: "10px",
      color: "white",
      fontWeight: "bold",
      cursor: "pointer"
    },
    buttonPdf: {
      background: "orange",
      border: "none",
      borderRadius: "5px",
      padding: "10px",
      color: "white",
      fontWeight: "bold",
      cursor: "pointer",
      marginRight: "10px"
    },
    dropzone: {
      margin: "10px",
      width: "calc(100% - 20px)",
      marginTop: "60px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    },
    orderIdWrapper: {
      fontWeight: "500",
      fontSize: "20px",
      padding: "16px 0 40px",
      display: "flex",
      alignItems: "center"
    },
    gallery: {
      marginBottom: "24px"
    },
    downloadFilesLink: {
      display: "flex",
      textDecoration: "none",
      fontSize: "14px",
      color: "#276EF1",
      alignItems: "center",
      "&:visited": {
        color: "#276EF1"
      }
    },
    transactionCard: {
      marginBottom: "20px"
    }
  });

  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const { order, type } = props;

  // partition files into my document and metaloop document
  const [metaloopDocuments, myDocuments] = useFilePartition(order.documents);

  //MOBILE
  //We checK if the weigth note dialog should be open
  const location = useLocation();
  const history = useHistory();

  const query = queryString.parse(location.search);
  const isOpenWeightNote = query.openWeightNoteDialog;
  if (!modalIsOpen && isOpenWeightNote) {
    //We open the dialog
    setModalIsOpen(true);
    delete query.openWeightNoteDialog;
    //We replace the param from the query to avoid opening the dialog again
    history.replace({
      pathname: location.pathname || "/",
      search: queryString.stringify(query)
    });
  }

  return (
    <ModalWrapper
      onCloseModal={props.onCloseModal}
      isOpen={props.open}
      className={classes.container}
    >
      <div className={classes.modalPadding}>
        <div className={classes.orderIdWrapper}>
          <CloseIcon
            onClick={props.onCloseModal}
            className={classes.closeButton}
          />
          {props.order._id}
        </div>

        <div>
          <Typography className={classes.modalTitle} align="left" variant="h5">
            {t("Transaction information")}
          </Typography>

          <div className={classes.inlineWrapper}>
            <span className={classes.property}>
              {" "}
              {t("Transport option") + ":"}
            </span>
            {props.order.transportOption}
          </div>

          <div className={classes.inlineWrapper}>
            <span className={classes.property}> {t("Address") + ":"}</span>
            {props.order.deliveryPlace}
          </div>
        </div>

        <div className={classes.gallery}>
          {order && order.images && order.images.length ? (
            <TransactionsGallery photos={order.images} />
          ) : null}
        </div>

        <Typography className={classes.modalTitle} align="left" variant="h5">
          {t("Schrott24 comments")}
        </Typography>
        <Typography
          className={`${classes.inlineWrapper} ${classes.commentWrapper}`}
        >
          {props.order.ourComment
            ? props.order.ourComment
            : t("No comments here")}
        </Typography>

        <Typography variant="h5" className={classes.modalTitle}>
          {t("Your comments")}
        </Typography>
        <Typography
          className={`${classes.inlineWrapper} ${classes.commentWrapper}`}
        >
          {props.order.weightnoteComment
            ? props.order.weightnoteComment
            : t("No comments here")}
        </Typography>
      </div>

      <TransactionMobileCart
        cart={order.cart}
        address={order.deliveryPlace}
        date={order.date}
        transportOption={order.transportOption}
        statusId={order.statusId}
        isSeller={false}
      />
      <div className={classes.modalPadding}>
        {type === "open" ? (
          <div className={classes.buttonsWrapper}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              fullWidth
              onClick={() => {
                TrackEvent("Upload document flow started", {
                  orderId: order._id,
                  clickSource: "Button"
                });
                setModalIsOpen(true);
              }}
            >
              <AddIcon className={classes.addIcon} />
              {t("WEIGHTNOTE")}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              fullWidth
              onClick={() => {
                TrackEvent("Upload document flow started", {
                  orderId: order._id,
                  clickSource: "Button"
                });
                setUploadDialogOpen(true);
              }}
            >
              <AddIcon className={classes.addIcon} />
              {t("DOCUMENTS")}
            </Button>
          </div>
        ) : null}
        <TransactionsCard
          title={t("Metaloop Documents")}
          className={classes.transactionCard}
        >
          <FileList files={metaloopDocuments} orderId={order.id} />
        </TransactionsCard>
        <TransactionsCard
          title={t("My Documents")}
          className={classes.transactionCard}
        >
          <FileList files={myDocuments} orderId={order.id} />
        </TransactionsCard>
      </div>

      {uploadDialogOpen ? (
        <UploadDialog
          orderId={order._id}
          orderType="purchase"
          onClose={() => setUploadDialogOpen(false)}
        />
      ) : null}

      {modalIsOpen ? (
        // <UploadPicturesAndWeightnotesModal
        //   orderId={order._id}
        //   transactionID={order.transactionID}
        //   isModalOpen={true}
        //   onCloseModal={() => setModalIsOpen(false)}
        //   uploadModalStep={step}
        //   setStep={setStep}
        //   activeStep={step}
        //   weightnote={order.weightnote}
        // />
        <UploadDialog
          orderId={order._id}
          orderType="purchase"
          onClose={() => setModalIsOpen(false)}
          initialType={WEIGHTNOTE_KEY}
        />
      ) : null}
    </ModalWrapper>
  );
};

export default UploadPricesPopup;
