import gql from "graphql-tag";

export default gql`
  query allStorageTypes($token: String!) {
    allStorageTypes(token: $token) {
      name
      id
    }
  }
`;
