import React, { useState } from "react";
import {
  Typography,
  makeStyles,
  createStyles,
  TextField,
  Button,
  FormHelperText
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import {
  validateEmail,
  validateMultipleEmailsString
} from "../../utils/Format";

const useStyles = makeStyles(theme =>
  createStyles({
    root: { marginLeft: "260px", marginTop: "100px", maxWidth: "520px" },
    header: {
      color: "black",
      fontSize: "36px",
      fontWeight: "500",
      textDecoration: "capitalize",
      fontStyle: "normal",
      lineHeight: "44px"
    },
    subheader: {
      marginTop: "16px",
      color: "black",
      opacity: 0.6,
      fontSize: "20px",
      width: "520px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "28px",
      letterSpacing: "0.15px",
      marginBottom: "4px"
    },
    textField: {
      marginTop: "20px",
      width: "400px"
    },
    buttonLeft: {
      marginRight: "auto",
      marginBottom: "20px",
      padding: "16 12"
    },
    buttonRight: {
      marginLeft: "auto",
      marginBottom: "20px",
      padding: "16 12"
    },
    buttonDiv: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "row",
      width: "400px",
      marginTop: "20px"
    },
    [theme.breakpoints.down("sm")]: {
      root: {
        marginLeft: "15px",
        marginTop: "96px",
        width: "100%"
      },
      header: {
        fontSize: "24px",
        lineHeight: "normal",
        fontWeight: "500",
        marginBottom: "8px"
      },
      subheader: {
        fontSize: "16px",
        lineHeight: "normal",
        fontWeight: "400",
        marginTop: "0px",
        width: "calc(100% - 30px)"
      },
      textField: {
        width: "calc(100% - 30px)"
      },
      buttonDiv: {
        position: "fixed",
        top: "605px",
        width: "calc(100% - 30px)",
        justifyContent: "left"
      },
      buttonLeft: {
        width: "calc(50% - 15px)",
        marginRight: "15px"
      },
      buttonRight: {
        width: "calc(50% - 15px)",
        marginLeft: "15px"
      }
    },
    "@media (max-height: 750px) and (max-width: 768px)": {
      buttonDiv: {
        position: "initial",
        top: "initial"
      }
    }
  })
);

const OnboardingForm = ({
  formData,
  setFormData,
  header,
  subheader,
  fields,
  requiredFields,
  setNextStep,
  setPreviousStep,
  hideBackButton = false
}) => {
  const classes = useStyles();
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  const onSubmit = async () => {
    let newErrors = {};
    requiredFields.forEach(field => {
      if (!formData[field]) {
        newErrors = { ...newErrors, [field]: "Required" };
      }
    });

    //We have to validate the phone number
    if (
      requiredFields.includes("phone") &&
      formData.phone &&
      formData.phone.startsWith("+") &&
      formData.phone.length < 9
    ) {
      newErrors = { ...newErrors, phone: "Required" };
    }

    //We have to validate the mobile number
    if (
      requiredFields.includes("mobile") &&
      formData.mobile &&
      formData.mobile.startsWith("+") &&
      formData.mobile.length < 9
    ) {
      newErrors = { ...newErrors, mobile: "Required" };
    }

    const actualFieldList = fields.map(f => f.name);

    //We also have to validate emails
    const fieldsWithEmailFormat = ["email", "mainAccountingEmail"].filter(f =>
      actualFieldList.includes(f)
    );

    fieldsWithEmailFormat.map(field => {
      if (
        formData[field] &&
        !validateEmail(formData[field]) &&
        !(
          field === "mainAccountingEmail" &&
          validateMultipleEmailsString(formData[field])
        )
      ) {
        newErrors = { ...newErrors, [field]: "The email address is not valid" };
      }
    });

    setErrors(newErrors);
    if (Object.keys(newErrors).length > 0) return;
    setNextStep();
  };

  return (
    <div className={classes.root}>
      <Typography className={classes.header}>{header}</Typography>
      <Typography className={classes.subheader}>{subheader}</Typography>
      <div>
        {fields.map((field, index) => {
          if (field.specialComponent) {
            return (
              <>
                {field.specialComponent}
                {errors[field.name] && (
                  <FormHelperText error>{t(errors[field.name])}</FormHelperText>
                )}
              </>
            );
          }
          return (
            <>
              <TextField
                label={field.label}
                variant="outlined"
                fullWidth={true}
                value={field.value}
                onChange={event => {
                  setFormData({
                    ...formData,
                    [field.name]: event.target.value
                  });
                  if (errors[field.name]) {
                    setErrors({ ...errors, [field.name]: null });
                  }
                }}
                className={`${field.extraClasses} ${classes.textField}`}
                {...field.extraProps}
              />
              {errors[field.name] && (
                <FormHelperText error>{t(errors[field.name])}</FormHelperText>
              )}
            </>
          );
        })}
      </div>
      <div className={classes.buttonDiv}>
        {!hideBackButton && (
          <Button
            variant="outlined"
            color="primary"
            className={classes.buttonLeft}
            onClick={() => {
              setPreviousStep();
            }}
          >
            {t("Back")}
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          className={classes.buttonRight}
          onClick={() => {
            onSubmit();
          }}
          style={{
            marginLeft: hideBackButton ? "auto" : ""
          }}
        >
          {t("Button_Confirm")}
        </Button>
      </div>
    </div>
  );
};

export default OnboardingForm;
