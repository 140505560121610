import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import Logo from "../../../assets/logo.png";
import LogoSchrott from "../../../assets/logo_schrott.png";
const styles = theme => ({
  wrapper: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 0
  },
  logo: {}
});
const HeaderLogo = props => {
  const [metaloopLogo, setMetaloopLogo] = useState(false);

  useEffect(() => {
    if (
      window !== undefined &&
      window.location.hostname.indexOf("metaloop") > -1
    ) {
      setMetaloopLogo(true);
    }
  }, []);
  return (
    <div className={props.classes.wrapper}>
      <img alt="" className={props.classes.logo} src={Logo} />
    </div>
  );
};

export default withStyles(styles)(HeaderLogo);
