import React, { useEffect, useState } from "react";
import OnboardingForm from "../../components/onboarding/OnboardingForm";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import CheckboxWithLabel from "../../components/core/CheckboxWithLabel";
import { useMutation } from "react-apollo";
import OnboardingBillingInformationMutation from "../../mutations/OnboardingBillingInformationMutation";
import Loader from "../../components/core/shared/Loader";
import { ONBOARDING_BILLING_AND_PAYMENT_INFORMATION } from "../../utils/constants";
import { getInputForSaveMutation } from "../../utils/Onboarding";
import useEventTracking from "../../hooks/useEventTracking";
import { validateMultipleEmailsString } from "../../utils/Format";
import StyledTooltip from "../../components/core/StyledTooltip";

const useStyles = makeStyles(theme => ({
  mainAccountingHeader: {
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "28px",
    marginTop: "48px"
  },
  sendInvite: {
    marginTop: "22px"
  }
}));

const pairingNames = {
  vat: "UID",
  iban: "IBAN",
  mainAccountingName: "name",
  mainAccountingEmail: "email",
  sendInvite: "sendInvite"
};

const reversePairingNames = {
  UID: "vat",
  IBAN: "iban",
  name: "mainAccountingName",
  email: "mainAccountingEmail",
  sendInvite: "sendInvite"
};

const BillingAndPayment = ({
  formData,
  setFormData,
  setNextStep,
  setPreviousStep,
  token,
  componentProperties,
  trackClickNextEvent
}) => {
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const [loadingMutation, setLoadingMutation] = useState(false);
  const [isAccountingFieldMultipleEmails, setIsAccountingFieldMultipleEmails] =
    useState(false);

  const [sendDataMutation] = useMutation(OnboardingBillingInformationMutation);

  const header = t("onboarding_billing_header");
  const subheader = t("onboarding_billing_subheader");

  useEffect(() => {
    const isAccountingFieldMultipleEmails = validateMultipleEmailsString(
      formData.mainAccountingEmail
    );
    setIsAccountingFieldMultipleEmails(isAccountingFieldMultipleEmails);
    if (formData.sendInvite && isAccountingFieldMultipleEmails) {
      setFormData({
        ...formData,
        sendInvite: false
      });
    }
  }, [formData]);

  const fields = [
    {
      label: t("onboarding_field_vat"),
      type: "text",
      value: formData.vat,
      name: "vat"
    },
    {
      label: t("onboarding_field_iban"),
      type: "text",
      value: formData.iban,
      name: "iban"
    },

    {
      specialComponent: <MainAccountingHeader />,
      name: "billingHeader",
      bypassValidation: true
    },
    {
      label: t("Name"),
      type: "text",
      value: formData.mainAccountingName,
      name: "mainAccountingName"
    },
    {
      label: t("Email"),
      type: "text",
      value: formData.mainAccountingEmail,
      name: "mainAccountingEmail"
    },
    {
      specialComponent: (
        <SendInvite
          formData={formData}
          setFormData={setFormData}
          disabled={isAccountingFieldMultipleEmails}
          t={t}
        />
      ),
      name: "sendInvite",
      bypassValidation: true
    }
  ];

  if (componentProperties.fields) {
    fields.forEach((field, index) => {
      if (!componentProperties.fields.includes(pairingNames[field.name])) {
        if (
          !(
            field.bypassValidation &&
            componentProperties.fields.includes("email")
          )
        )
          fields.splice(index, 1);
      }
    });
  }

  const requiredFieldsOptions = formData.sendInvite
    ? ["mainAccountingEmail"]
    : [];
  const requiredFieldsBase = JSON.parse(
    JSON.stringify(componentProperties.fields)
  )
    .filter(field => !componentProperties.optionals.includes(field))
    .map(field => reversePairingNames[field])
    .filter(field => field);
  const requiredFields = requiredFieldsBase.concat(requiredFieldsOptions);

  const handleNextStep = async () => {
    trackClickNextEvent();
    setLoadingMutation(true);

    let input = getInputForSaveMutation(
      ONBOARDING_BILLING_AND_PAYMENT_INFORMATION,
      formData
    );

    const isTryingToUpdateMultipleEmail = validateMultipleEmailsString(
      input.email
    );
    if (isTryingToUpdateMultipleEmail) {
      input.email = null;
      input.sendInvite = false;
      input.name = null;
    }

    await sendDataMutation({ variables: { token, input } });
    setNextStep();
    setLoadingMutation(false);
  };

  const handlePreviousStep = () => {
    TrackEvent("KYC Flow - Billing - Clicks back");
    setPreviousStep();
  };

  if (loadingMutation) return <Loader />;

  return (
    <OnboardingForm
      formData={formData}
      setFormData={setFormData}
      header={header}
      subheader={subheader}
      fields={fields}
      requiredFields={requiredFields}
      setNextStep={handleNextStep}
      setPreviousStep={handlePreviousStep}
    />
  );
};

const MainAccountingHeader = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Typography className={classes.mainAccountingHeader}>
      {t("onboarding_field_main_acc_cont")}
    </Typography>
  );
};

const SendInvite = ({ formData, setFormData, disabled = false, t }) => {
  const classes = useStyles();
  return (
    <div className={classes.sendInvite}>
      {disabled ? (
        <StyledTooltip
          text={t("not_allowed_for_multiple_emails")}
          placement={"bottom"}
        >
          <div
            style={{
              display: "inline-block",
              cursor: "not-allowed"
            }}
          >
            <CheckboxWithLabel
              checked={formData.sendInvite}
              label="onboarding_field_send_invite"
              color="secondary"
              onChange={event => {
                setFormData({
                  ...formData,
                  sendInvite: event.target.checked
                });
              }}
              disabled={disabled}
            />
          </div>
        </StyledTooltip>
      ) : (
        <CheckboxWithLabel
          checked={formData.sendInvite}
          label="onboarding_field_send_invite"
          color="secondary"
          onChange={event => {
            setFormData({
              ...formData,
              sendInvite: event.target.checked
            });
          }}
        />
      )}
    </div>
  );
};

export default BillingAndPayment;
