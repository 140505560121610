import React from "react";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
  full: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
});

class Loader extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <div className={classes.full}>
        <CircularProgress color="primary" />
      </div>
    );
  }
}

export default withStyles(styles)(Loader);
