import gql from "graphql-tag";

export default gql`
  query UserData($token: String!, $company: String) {
    UserCompanies(token: $token, company: $company) {
      _id
      name
      branch
      segment
      isRetail
      usesPricing
      usesRouting
      rules
      roles
      currencySymbol
      companyRoles
      parentAccountId
      isGroupAccount
    }
    RawUserData(token: $token, company: $company) {
      _id
      email
      firstName
      lastName
      preferredLanguage
      avatarUrl
      phone
      mobile
      position
      isAdmin
      isGroupAdmin
      groupAccounId
      onboardingToken
      pf
    }
  }
`;
