import React from "react";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import PickupDropdown from "./PickupDropdown";
import DateDropdown from "../../general/DateDropdown";
import { isItAndAdminLoggedInParentCompany } from "../../../hooks/useUserData";
const PickupDesktopFilters = ({
  status,
  setDate,
  setProduct,
  setStatus,
  setReset,
  filters,
  date,
  product,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  localization,
  setLocalization,
  disabled = false
}) => {
  const { t } = useTranslation();

  return (
    <>
      {isItAndAdminLoggedInParentCompany() && (
        <PickupDropdown
          value={localization}
          options={filters.locations}
          callback={setLocalization}
          label={"filter_show_locations"}
        />
      )}

      <DateDropdown
        date={date}
        setDate={setDate}
        label={"Pick up Date"}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        disabled={disabled}
      />

      <PickupDropdown
        value={product}
        options={filters.products}
        callback={setProduct}
        label={"Show Products"}
        disabled={disabled}
      />
      <PickupDropdown
        value={status}
        options={filters.status}
        callback={setStatus}
        label={"Status"}
        localization={true}
        disabled={disabled}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          setReset(true);
        }}
        disabled={disabled}
      >
        {t("Sales_ClearFilters")}
      </Button>
    </>
  );
};

export default PickupDesktopFilters;
