import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Slider from "./Slider";
import WeightRanges from "./WeightRanges";

const styles = theme => ({
  wrapper: {}
});

class SliderWithWeightRanges extends React.Component {
  render() {
    const { classes, data, setRanges, minRange, maxRange } = this.props;
    return (
      <div className={classes.wrapper}>
        <Slider
          value={[minRange, maxRange]}
          min={0}
          max={data.length - 1}
          onChange={setRanges}
        />
        <WeightRanges
          data={data}
          minRange={minRange}
          maxRange={maxRange}
          inSlider
        />
      </div>
    );
  }
}

export default withStyles(styles)(SliderWithWeightRanges);
