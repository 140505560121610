import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import FullLoadUpdate from "./FullLoadUpdate";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    height: "100%",
    backgroundColor: "#F5F5F5",
    display: "flex",
    justifyContent: "center",
    flexDirection: "row",
    flexWrap: "none",
    borderLeft: "1px solid rgba(0, 0, 0, 0.12)"
  },
  wrapper: {
    display: "flex",
    alignItems: "center"
  },
  iconSmall: {
    fontSize: "16px"
  },
  priceBox: {
    width: "84px",
    height: "34px",
    borderRadius: "8px",
    border: "1px solid #F2994A",
    margin: "0 auto",
    overflow: "hidden",
    marginRight: "8px",
    "& > div": {
      height: "100%"
    }
  },
  innerInput: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "0.001em",
    color: "#F2994A",
    paddingLeft: "14px",
    paddingRight: "0px"
  },
  rightWrapper: {
    flexGrow: "1",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexDirection: "row",
    marginTop: "80px",
    width: "100%",
    "& > h4": {
      fontSize: "16px",
      color: "#000000",
      fontFamily: "Helvetica",
      fontWeight: "bold",
      textAlign: "center",
      marginTop: "16px",
      marginBottom: "0px"
    },
    "& > p": {
      fontSize: " 14px",
      color: "#000000",
      fontFamily: "Helvetica",
      textAlign: "center"
    }
  },
  titleContainer: {
    display: "flex"
  },
  iconWrapper2: {
    width: "24px",
    flexGrow: 0,
    marginLeft: "-12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  legend: {
    marginTop: "3px",
    marginRight: "5px"
  },
  popup: {
    color: "grey"
  }
}));

const ReferencePrice = props => {
  const { price, onChange } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [displayFullLoad, setDisplayFullLoad] = useState(false);
  const [priceState, setPriceState] = useState(null);

  return (
    <div className={classes.container}>
      {displayFullLoad ? (
        <FullLoadUpdate
          close={() => setDisplayFullLoad(false)}
          onChange={locked => {
            onChange(priceState, locked);
          }}
        />
      ) : null}
      <div className={classes.rightWrapper}>
        <div className={classes.titleContainer}>
          <span className={classes.legend}>{t("Full load")}</span>
        </div>
        <div className={classes.wrapper}>
          <TextField
            value={priceState || price}
            variant="outlined"
            margin="dense"
            type="number"
            className={classes.priceBox}
            inputProps={{
              className: classes.innerInput
            }}
            onBlur={() => setDisplayFullLoad(true)}
            onChange={ev => {
              setPriceState(ev.target.value);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ReferencePrice;
