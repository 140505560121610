import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import ModalWrapper from "../../../../transactions/layout/Modals/ModalClass";
import axios from "axios";
import GLOBAL_VARIABLES from "../../../../../config/config";
import Loader from "../../../../core/shared/Loader";
import Dropzone from "react-dropzone";
import CloseIcon from "@material-ui/icons/Close";
import { showError, showSuccess } from "../../../../core/shared/Notify";
import { ReactComponent as CrossIcon } from "../../../../../assets/icons/cross.svg";
//import { ReactComponent as CancelIcon } from "../../../../../assets/icons/cancel.svg";
import CancelIcon from "@material-ui/icons/Delete";
import { ReactComponent as PaperclipIcon } from "../../../../../assets/icons/paperclip.svg";
import useEventTracking from "../../../../../hooks/useEventTracking";
import { Hidden, Button } from "@material-ui/core";

const URL = GLOBAL_VARIABLES.API_BASE_URL + "/api/v1/prices/upload/";

const useStyles = makeStyles(theme => ({
  smallContainer: {
    padding: 0,
    borderRadius: "2px",
    width: "640px",
    [theme.breakpoints.down("md")]: {
      width: "100%",

      bottom: "0",
      top: "auto",
      padding: "16px"
    }
  },

  container: {
    padding: 0,
    borderRadius: "2px",
    width: "640px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      bottom: "0",
      top: "auto",
      padding: "16px"
    }
  },
  contentWrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "8px 24px 24px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      border: "0",
      boxShadow: "none",
      padding: "0",
      margin: "0",
      outline: "0",
      borderRadius: "0",
      bottom: "-125px",
      top: "auto"
    }
  },
  dropzone: {
    width: "592px",
    height: "132px",
    minHeight: "88px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(21, 17, 36, 0.05)",
    border: "1px solid rgba(0, 0, 0, 0.12)",
    boxSizing: "border-box",
    borderRadius: "4px",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      width: "calc(100%)",
      height: "80px"
    }
  },
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    marginBottom: "24px",
    [theme.breakpoints.down("md")]: {
      marginBottom: "12px"
    }
  },
  text: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "#151124"
  },
  crossIcon: {
    marginRight: "13px"
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "24px",
    marginBottom: "0",
    [theme.breakpoints.down("md")]: {
      marginTop: "16px",
      marginBottom: "0"
    }
  },
  browseButton: {
    marginRight: "16px"
  },
  uploadedDocumentContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center"
  },
  fileName: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "#276EF1",
    marginLeft: "9px",
    marginRight: "6px"
  },
  cancelIcon: {
    cursor: "pointer",
    width: "20px",
    height: "20px"
  },
  paperclipIcon: {
    width: "22px",
    height: "11px"
  },
  closeButtonWrapper: {
    display: "flex",
    justifyContent: "end",
    padding: "13px"
  },

  cancelIcon: {
    "&:hover": {
      cursor: "pointer"
    }
  },
  [theme.breakpoints.down("md")]: {
    uploadPrices: {
      transform: "none",
      left: "0",
      right: "0",
      bottom: "0",
      height: "auto"
    },
    mobileCloseDrawer: {
      padding: "0 16px 16px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& button": {
        display: "inline-block",
        borderRadius: "12px",
        backgroundColor: "#E0E0E0",
        outline: "none",
        width: "30px",
        height: "6px",
        border: "none",
        cursor: "pointer"
      }
    }
  }
}));

const UploadPricesPopup = props => {
  const { buyerId, close, open } = props;

  const classes = useStyles();
  const { t } = useTranslation();
  const [isUploading, setIsUploading] = useState(false);
  const [file, setFile] = useState(null);
  const [method, setMethod] = useState(null);
  const { TrackEvent } = useEventTracking();

  let dropzone = null;

  const onHandleFileChange = (file, method) => {
    setFile(file[0]);
    setMethod(method);
  };

  const openFileBrowser = () => {
    dropzone.node.click();
  };

  const saveFile = () => {
    if (file) {
      setIsUploading(true);
      const form_data = new FormData();
      form_data.append("file", file);
      const url = URL + buyerId;
      axios
        .post(url, form_data)
        .then(data => {
          setIsUploading(false);
          showSuccess(t("Prices uploaded"));
          close();
          TrackEvent("Prices uploaded", { method });
        })
        .catch(({ response }) => {
          showError(t("There was an error uploading the prices"));
          close();
          TrackEvent("Prices uploaded", {
            method,
            error: response.data.error
          });
        });
    }
  };

  const deleteDocument = () => {
    setFile(null);
  };

  return (
    <ModalWrapper
      onCloseModal={close}
      isOpen={open}
      className={`
        ${classes.uploadPrices} ${
          isUploading ? classes.container : classes.smallContainer
        }
      `}
    >
      <Hidden mdDown>
        <div className={classes.closeButtonWrapper}>
          <CloseIcon onClick={close} className={classes.cancelIcon} />
        </div>
      </Hidden>
      {isUploading ? (
        <Loader />
      ) : (
        <div className={classes.contentWrapper}>
          <Hidden mdUp>
            <div className={classes.mobileCloseDrawer}>
              <button onClick={() => close()}></button>
            </div>
          </Hidden>
          <span className={classes.title}>{t("Upload prices")}</span>
          {file ? (
            <div className={classes.uploadedDocumentContainer}>
              <PaperclipIcon className={classes.paperclipIcon} />
              <span className={classes.fileName}>{file.name}</span>

              <CancelIcon
                className={classes.cancelIcon}
                onClick={deleteDocument}
                color="action"
              />
            </div>
          ) : (
            <Dropzone
              name={"name"}
              onDrop={(filesToUpload, _, e) => {
                const method =
                  e.constructor.name === "SyntheticEvent"
                    ? "browse"
                    : "Drag and drop";
                onHandleFileChange(filesToUpload, method);
              }}
              className={"dropzoneCustom " + classes.dropzone}
              ref={el => (dropzone = el)}
            >
              <p className={classes.text}>
                <CrossIcon className={classes.crossIcon} />
                {t("Upload prices")}
              </p>
            </Dropzone>
          )}
          <div className={classes.buttonContainer}>
            <Button
              color="primary"
              variant="outlined"
              className={classes.browseButton}
              onClick={openFileBrowser}
            >
              {t("Browse")}
            </Button>
            <Button variant="contained" color="primary" onClick={saveFile}>
              {t("Save")}
            </Button>
          </div>
        </div>
      )}
    </ModalWrapper>
  );
};

export default UploadPricesPopup;
