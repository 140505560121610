import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  comments: {
    "& .MuiOutlinedInput-multiline": {
      padding: "10px 16px",
      "& fieldset": {
        borderColor: "#9E9E9E"
      },

      "&.Mui-focused fieldset": {
        borderColor: "#52f597"
      }
    }
  }
});

const MultilineCustomTextField = ({ label, onChange, value }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <TextField
      label={t(label)}
      variant="outlined"
      fullWidth={true}
      multiline={true}
      minRows={4}
      value={value}
      onChange={onChange}
      className={classes.comments}
    />
  );
};

export default MultilineCustomTextField;
