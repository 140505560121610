import React from "react";

import { useTranslation } from "react-i18next";

const FrequencyConversor = ({ item }) => {
  const { t } = useTranslation();
  const getFrequency = rrule => {
    if (!rrule) {
      return "-";
    }

    if (rrule.includes("COUNT=1")) {
      return "Spot";
    }
    if (!rrule.includes("=") && !rrule.includes(";")) {
      return rrule;
    }

    const freqRegex = /FREQ=([A-Z]+)/;
    const match = rrule.match(freqRegex);

    if (match) {
      return match[1].toLowerCase();
    } else {
      return "-";
    }
  };

  return (
    <>
      {" "}
      {t(
        getFrequency(item.pickupFrequency).replace(/^\w/, c => c.toUpperCase())
      ) || "-"}
    </>
  );
};

export default FrequencyConversor;
