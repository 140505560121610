import React from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  link: {
    textDecoration: "none",
    color: "white",
    "@media (max-width: 400px)": {
      width: "100%",
      padding: " 0 16px"
    }
  },

  outsideContainer: {
    width: "100%",
    maxWidth: "307px",
    margin: "0 auto",
    "@media (max-width: 400px)": {}
  },

  getMagicLinkText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "1px",
    textTransform: "uppercase",
    fontFeatureSettings: "'liga' off",
    color: theme.palette.primary.main
  },
  getMagicLinkText2: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "0.4px",
    textTransform: "lowercase",
    color: theme.palette.primary.main
  },
  blue: {
    background: "#14133A"
  },
  separator: {
    width: "1px",
    height: "18px",
    margin: "0 15px",
    backgroundColor: theme.palette.primary.main
  }
});

class LongMagicLink extends React.Component {
  render() {
    const { classes, t, color } = this.props;
    return (
      <div className={classes.outsideContainer}>
        <Button
          variant="outlined"
          color="primary"
          fullWidth
          component={Link}
          to={"/loginLink"}
        >
          <span className={classes.getMagicLinkText}>
            {t("get magic link")}
          </span>{" "}
          <span className={classes.separator}></span>
          <span className={classes.getMagicLinkText2}>
            {t("no password needed")}
          </span>
        </Button>
      </div>
      // <Link className={classes.link} to={"/loginLink"}>
      //   <div
      //     className={`${classes.outsideContainer}
      //     ${color === "blue" ? classes.blue : null}`}
      //   >
      //     <Typography className={classes.getMagicLinkText} variant="button">
      //       {" "}
      //       {t("get magic link")}{" "}
      //     </Typography>
      //     <div className={classes.separator}></div>
      //     <Typography className={classes.getMagicLinkText} variant="caption">
      //       {" "}
      //       {t("no password needed")}
      //     </Typography>
      //   </div>
      // </Link>
    );
  }
}

export default withStyles(styles)(withTranslation()(LongMagicLink));
