import gql from "graphql-tag";

export default gql`
  mutation SetMainContact(
    $token: String!
    $companyId: String!
    $contactId: String!
  ) {
    SetMainContact(
      token: $token
      companyId: $companyId
      contactId: $contactId
    ) {
      message
    }
  }
`;
