import {
  Snackbar as MuiSnackbar,
  SnackbarContent as MuiSnackbarContent
} from "@material-ui/core";
import classNames from "classnames";

import PropTypes from "prop-types";
import { forwardRef } from "react";

const Snackbar = forwardRef(
  (
    {
      color = "primary",
      fixed = false,
      message,
      action = [],
      autoHideDuration,
      onClose,
      ...props
    },
    ref
  ) => (
    <MuiSnackbar
      {...props}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
      ref={ref}
    >
      <SnackbarContent
        color={color}
        fixed={fixed}
        message={message}
        action={action}
      />
    </MuiSnackbar>
  )
);

Snackbar.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary"])
};

export const SnackbarContent = forwardRef(
  (
    { color = "primary", fixed = false, message, action = [], ...props },
    ref
  ) => (
    <MuiSnackbarContent
      className={classNames([
        `MuiSnackbarContent-${color}`,
        { [`MuiSnackbarContent-fixed`]: fixed }
      ])}
      ref={ref}
      message={message}
      action={action}
      {...props}
    />
  )
);

export default Snackbar;
