import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  makeStyles,
  TextField,
  Typography,
  Button,
  Hidden
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import EditCompanyAddress from "../../../mutations/editCompanyAddressMutation";
import { useMutation } from "@apollo/react-hooks";
import CompanyInformationQuery from "../../../queries/CompanyInformation";
import { showError, showSuccess } from "../../core/shared/Notify";
import CityAndCountryInputs from "../../general/GoogleAutocompleteApi/CityAndCountryInputs";

const Form = ({ data, cancel, token, company }) => {
  const useStyles = makeStyles(theme => ({
    container: {
      display: "flex",
      flexDirection: "column",
      gap: 20,
      maxWidth: 400,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%"
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #9E9E9E"
      }
    },
    headerContainer: {
      display: "flex",
      [theme.breakpoints.down("sm")]: {
        padding: "16px 0",
        gap: 16
      }
    },
    title: {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: "24px",
      letterSpacing: 0.25
    },
    zipInput: {
      maxWidth: 190,
      [theme.breakpoints.down("sm")]: {
        maxWidth: 156
      }
    },
    btnContainer: {
      display: "flex",
      justifyContent: "flex-end",
      gap: 16
    },
    inputText: {
      width: "100%"
    }
  }));

  const classes = useStyles();

  const { t } = useTranslation();

  const [submit, setSubmit] = useState(false);
  const [companyName, setCompanyName] = useState(data?.name);
  const [streetAddress, setStreetAddress] = useState(data?.address.street);
  const [zipCode, setZipCode] = useState(data?.address.zip);
  const [city, setCity] = useState(data?.address.city);
  const [country, setCountry] = useState(data?.address.country);
  const [EditCompanyAddressMutation] = useMutation(EditCompanyAddress, {
    refetchQueries: () => [
      {
        query: CompanyInformationQuery,
        variables: {
          token: token,
          company: company
        }
      }
    ]
  });

  const editCompanyAddress = () => {
    setSubmit(true);

    if (!companyName || !streetAddress || !city || !country) return null;

    EditCompanyAddressMutation({
      variables: {
        input: {
          _id: data.id,
          company: companyName,
          city: city,
          country: country.charAt(0) === " " ? country.slice(1) : country,
          streetAddress: streetAddress,
          zipCode: zipCode
        },
        token
      }
    })
      .then(() => {
        showSuccess(t("Successfully edited"));
        cancel(true);
      })
      .catch(err => showError(err.message));
  };

  return (
    <FormControl fullWidth className={classes.container}>
      <div className={classes.headerContainer}>
        <Hidden mdUp>
          <Close onClick={() => cancel()} size="small" />
        </Hidden>
        <Typography className={classes.title}>
          {t("Company address")}
        </Typography>
      </div>
      <TextField
        id="companyName"
        label={t("Company name")}
        variant="outlined"
        value={companyName}
        onChange={event => setCompanyName(event.target.value)}
        error={!companyName && submit}
        helperText={!companyName && submit && t("This field is compulsory")}
      />
      <TextField
        id="streetAddress"
        label={t("Street")}
        variant="outlined"
        value={streetAddress}
        onChange={event => setStreetAddress(event.target.value)}
        error={!streetAddress && submit}
        helperText={!streetAddress && submit && t("This field is compulsory")}
      />
      <TextField
        id="zipCode"
        label={t("Zip Code")}
        variant="outlined"
        className={classes.zipInput}
        value={zipCode}
        onChange={event => setZipCode(event.target.value)}
      />
      <CityAndCountryInputs
        classes={classes}
        form={{ country: country, city: city }}
        setCity={setCity}
        setCountry={setCountry}
        errors={{ city: !city && submit }}
      />
      <div className={classes.btnContainer}>
        <Button variant="outlined" color="primary" onClick={() => cancel()}>
          {t("Cancel")}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={editCompanyAddress}
        >
          {t("Save")}
        </Button>
      </div>
    </FormControl>
  );
};

const CompanyEditing = ({ data, cancel, token, company }) => {
  const useStyles = makeStyles(theme => ({
    mobileContainer: {
      minWidth: "100%",
      top: 0,
      left: "50%",
      transform: "translateX(-50%)",
      position: "absolute",
      maxHeight: "100%",
      overflowY: "auto",
      zIndex: 100,
      background: "#FFFFFF",
      padding: "0 16px 30px"
    }
  }));

  const classes = useStyles();

  return (
    <>
      <Hidden smDown>
        <Form data={data} cancel={cancel} token={token} company={company} />
      </Hidden>
      <Hidden mdUp>
        <div className={classes.mobileContainer}>
          <Form data={data} cancel={cancel} token={token} company={company} />
        </div>
      </Hidden>
    </>
  );
};

export default CompanyEditing;
