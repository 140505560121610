import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { Button, DialogActions, DialogContent } from "@material-ui/core";
import CustomDialog from "../../general/Dialog";
import { format } from "date-fns";
import DateRangePicker from "../../general/Picker/RangePicker";
import DateSinglePicker from "../../general/Picker/SinglePicker";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-GB";
import esLocale from "date-fns/locale/es";

const DateDropdownDateRangePicker = ({
  type,
  setDate,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  open,
  setOpen,
  tempStartDate,
  setTempStartDate,
  tempEndDate,
  setTempEndDate,
  dateOptions,
  setDateOptions,
  isOpen,
  setIsOpen
}) => {
  const useStyles = makeStyles(theme => ({
    dialog: {
      [theme.breakpoints.up("sm")]: {
        maxWidth: "none",
        margin: 0
      }
    },

    dialogRoot: {
      "& .MuiDialog-paper": {
        borderRadius: 0
      },
      [theme.breakpoints.down("sm")]: { padding: 0 },
      "& .MuiDialogContent-root": {
        [theme.breakpoints.down("sm")]: { padding: 0 }
      },
      "& .MuiDialog-paperWidthSm": {
        maxWidth: "none"
      },
      [theme.breakpoints.down("sm")]: {
        padding: 0
      },
      [theme.breakpoints.up("sm")]: {
        padding: theme.spacing(3)
      }
    },
    calendarIcon: {
      color: "red"
    },
    datePicker: {
      alignSelf: "center",
      "& .MuiPickersBasePicker-container": {
        alignItems: "center"
      },

      "& .MuiPickersBasePicker-pickerView": {
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          maxWidth: "100%",
          margin: 0,
          padding: 0
        }
      },
      "& .MuiPickersDatePickerRoot-toolbar": {
        display: "none"
      }
    },
    wrapper: {
      "& .MuiPickersDay-day": {
        color: "#151124",
        "&:hover": {
          background: "#86F8B6"
        }
      },
      "& .MuiPickersDay-daySelected": {
        background: "#86F8B6"
      },
      "& .MuiPickersDay-current": {
        background: "none",
        border: `2px solid ${theme.palette.primary.main}`
      },
      "& .MuiPickersDay-current.MuiPickersDay-daySelected": {
        background: "#86F8B6",
        border: "none"
      }
    },
    btnContainer: {
      display: "flex",
      justifyContent: "end",
      gap: 6,
      padding: "8px 16px"
    },
    toolbarContainer: {
      background: "#151124",
      color: "rgba(255, 255, 255, 0.74)",
      padding: "12px 18px",
      borderRadius: "4px 4px 0 0"
    },
    buttonContainer: { marginBottom: "16px", marginRight: "16px" }
  }));

  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const convertDate = date => {
    if (date !== null) {
      const dateParts = date.split("-");
      const dateObj = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);

      return dateObj;
    }
    return null;
  };
  const formatter = range => {
    if (
      range.startDate === null ||
      range.startDate === undefined ||
      range.endDate === null ||
      range.endDate === undefined
    )
      return;

    const startDate = format(range.startDate, "dd-MM-y");
    const endDate = format(range.endDate, "dd-MM-y");

    setTempStartDate(startDate);
    setTempEndDate(endDate);
  };

  const getDateOptionByValue = value =>
    dateOptions.find(option => option.value === value);

  const updateParameter = (parameter, startNewValue, endNewValue) => {
    setStartDate(startNewValue);
    setEndDate(endNewValue);

    setDateOptions(prevOptions => {
      const updatedOptions = [...prevOptions];
      const dateRangeOption = updatedOptions.find(
        option => option.value === parameter
      );
      if (dateRangeOption) {
        dateRangeOption["start"] = startNewValue;
        dateRangeOption["end"] = endNewValue;
      }
      return updatedOptions;
    });
  };

  const getDateForDatePicker = () => {
    if (tempStartDate !== null) {
      const [dd, mm, yyyy] = tempStartDate.split("-");
      return new Date(`${mm}-${dd}-${yyyy}`);
    } else {
      return new Date();
    }
  };
  const calendarLocale =
    i18n.language === "de"
      ? deLocale
      : i18n.language === "es"
        ? esLocale
        : enLocale;
  return (
    <>
      <CustomDialog
        classes={{ root: classes.dialogRoot, paper: classes.dialog }}
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <DialogContent className={classes.root}>
          {type === "dateRange" ? (
            <DateRangePicker
              initialDateRange={{
                startDate: convertDate(getDateOptionByValue("dateRange").start),
                endDate: convertDate(getDateOptionByValue("dateRange").end)
              }}
              onChange={range => formatter(range)}
              minDate={0}
              disableWeekends={false}
            />
          ) : (
            <>
              <DateSinglePicker
                initialDate={getDateForDatePicker()}
                onChange={date => {
                  formatter({ startDate: date, endDate: date });
                }}
                minDate={0}
                disableWeekends={false}
              />
            </>
          )}
        </DialogContent>

        <DialogActions className={classes.buttonContainer}>
          <Button
            onClick={() => {
              if (tempStartDate === null || tempEndDate === null) {
                const now = format(new Date(), "dd-MM-y");
                updateParameter(type, now, now);
              } else {
                updateParameter(type, tempStartDate, tempEndDate);
              }

              setOpen(false);
              setIsOpen(false);
            }}
            color="primary"
          >
            {t("OK")}
          </Button>
          <Button
            onClick={() => {
              if (startDate === null || endDate === null) {
                updateParameter(type, null, null);
                setDate("all");
                setTempStartDate(null);
                setTempEndDate(null);
              }
              setOpen(false);
              setIsOpen(false);
            }}
            color="primary"
          >
            {t("Cancel")}
          </Button>
        </DialogActions>
      </CustomDialog>
    </>
  );
};

export default DateDropdownDateRangePicker;
