import React from "react";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Checkbox
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const NotSearchableList = ({
  checkbox = false,
  searchable = false,
  dense = false,
  maxVisibleOptions = 5,
  data = [],
  selected = [],
  multiple = false,
  handleChange = () => {},
  placeholder = "",
  ...props
}) => {
  const onChange = event => {
    handleChange(
      multiple
        ? event.target.value.map(options => {
            if (options.label !== undefined) {
              return options;
            } else {
              var newoptions = data.filter(
                originalOption => originalOption.value === options
              )[0];
              return newoptions;
            }
          })
        : data.filter(
            originalOption => originalOption.value === event.target.value
          )[0]
    );
  };
  const overrideHeight = props.overrideHeight;
  const calculateDropdownHeight = ({
    checkbox,
    dense,
    data,
    maxVisibleOptions
  }) => {
    const heightNotDense = checkbox ? "50" : (overrideHeight ?? "46");
    const heightDense = checkbox ? "26.5" : "26.5";

    const multiplier = dense ? heightDense : heightNotDense;
    var maxHeight = maxVisibleOptions * multiplier + "px";

    if (maxVisibleOptions > data.length) {
      maxHeight = data.length * multiplier + "px";
    }

    return maxHeight;
  };

  const useStyles = makeStyles(theme => ({
    menuItem: {
      height: dense ? "25px" : "48px",
      paddingLeft: "11px"
    }
  }));
  const classes = useStyles();
  var maxHeight = calculateDropdownHeight({
    checkbox,
    dense,
    data,
    maxVisibleOptions
  });

  const MenuProps = {
    PaperProps: {
      style: {
        marginTop: "55px",
        maxHeight: maxHeight
      }
    }
  };

  const getLabelFromValue = selected => {
    if (multiple) {
      const labels = selected.map(selectedOption => {
        var valueSelected = null;
        if (typeof selectedOption === "object") {
          valueSelected = selectedOption.value;
        } else {
          valueSelected = selectedOption;
        }

        const label = data.filter(item => item.value === valueSelected);

        return label[0].label !== undefined ? label[0].label : label[0];
      });

      return labels.join(", ");
    }

    const label = data.filter(item => item.value === selected[0]);

    return label[0].label !== undefined ? label[0].label : "";
  };
  var value = [];

  if (selected.length > 0 && selected[0] !== undefined) {
    value = selected.map(item => item.value);
  }

  return (
    <FormControl
      variant="outlined"
      fullWidth
      displayEmpty={placeholder === undefined ? true : false}
    >
      <InputLabel>{placeholder}</InputLabel>
      <Select
        displayEmpty={placeholder === undefined ? true : false}
        label={placeholder.length > 0 ? placeholder : undefined}
        dense={dense}
        MenuProps={{
          variant: "menu",
          ...MenuProps
        }}
        renderValue={selected => getLabelFromValue(selected)}
        multiple={multiple}
        value={value}
        onChange={onChange}
      >
        {data.map(({ value, label }) => (
          <MenuItem value={value} key={value} className={classes.menuItem}>
            {checkbox ? (
              <>
                <Checkbox
                  color="primary"
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  style={{ height: dense ? "10px" : "30px" }}
                  checked={selected.map(item => item.value).includes(value)}
                />{" "}
                {label}
              </>
            ) : (
              <div className={classes.noCheckboxOptions}> {label}</div>
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default NotSearchableList;
