import React, { useContext, useEffect, memo } from "react";
import { Grid, Hidden, makeStyles } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import {
  SearchTransactionID,
  ShowProductsDropdown,
  ExpiryDateSelector,
  SortByDropdown,
  FiltersButton
} from "./inputComponents";
import OffersContext from "../../../providers/OffersProvider";

const useStyles = makeStyles({
  container: {
    maxWidth: 1600,
    padding: "24px 32px",
    display: "flex",
    gap: 20,
    flexWrap: "wrap"
  }
});

const OfferSearchBar = memo(({ categories, offers }) => {
  const {
    categories: fullCategories,
    searchFilters,
    setSearchFilters
  } = useContext(OffersContext);

  const history = useHistory();

  const classes = useStyles();

  useEffect(() => {
    // Executed only once

    if (history.action !== "PUSH") {
      setSearchFilters({});
    }
  }, []);

  const { transactionID, productsDropdown, expiryDate, sortDropdown } =
    searchFilters || {};

  const updateSearchTransactionID = value => {
    const tempSearchFilters = searchFilters || {};
    tempSearchFilters.transactionID = value;
    setSearchFilters({ ...tempSearchFilters });
  };

  const updateProductsDropdown = value => {
    const tempSearchFilters = searchFilters || {};
    tempSearchFilters.productsDropdown = value;
    setSearchFilters({ ...tempSearchFilters });
  };

  const updateExpiryDate = value => {
    const tempSearchFilters = searchFilters || {};
    tempSearchFilters.expiryDate = value;
    setSearchFilters({ ...tempSearchFilters });
  };

  const updateSortDropdown = value => {
    const tempSearchFilters = searchFilters || {};
    tempSearchFilters.sortDropdown = value;
    setSearchFilters({ ...tempSearchFilters });
  };

  const resetFilters = () => {
    setSearchFilters({});
  };

  categories = fullCategories.filter(fc =>
    categories.includes(parseInt(fc.id))
  );

  return (
    <>
      <Hidden mdUp>
        <SearchTransactionID
          onChange={({ target }) => updateSearchTransactionID(target.value)}
          value={transactionID || ""}
        />
        <FiltersButton
          filters={searchFilters}
          resetFilters={resetFilters}
          offers={offers}
        >
          <ShowProductsDropdown
            products={categories}
            onChange={({ target }) => updateProductsDropdown(target.value)}
            value={productsDropdown || "all"}
          />
          <ExpiryDateSelector
            onChange={date => updateExpiryDate(date)}
            removeValue={() => updateExpiryDate(null)}
            value={expiryDate || null}
          />
          <SortByDropdown
            onChange={({ target }) => updateSortDropdown(target.value)}
            value={sortDropdown || "latestFirst"}
          />
        </FiltersButton>
      </Hidden>
      <Hidden smDown>
        <Grid container item className={classes.container}>
          <Grid item>
            <SearchTransactionID
              onChange={({ target }) => updateSearchTransactionID(target.value)}
              value={transactionID || ""}
            />
          </Grid>
          <Grid item>
            <ShowProductsDropdown
              products={categories}
              onChange={({ target }) => updateProductsDropdown(target.value)}
              value={productsDropdown || "all"}
            />
          </Grid>
          <Grid item>
            <ExpiryDateSelector
              onChange={date => updateExpiryDate(date)}
              removeValue={() => updateExpiryDate(null)}
              value={expiryDate || null}
            />
          </Grid>
          <Grid item>
            <SortByDropdown
              onChange={({ target }) => updateSortDropdown(target.value)}
              value={sortDropdown}
            />
          </Grid>
        </Grid>
      </Hidden>
    </>
  );
});

export default OfferSearchBar;
