import React, { useRef } from "react";
import GenericCard from "../../general/GenericCard";
import GenericFileDisplay from "../../general/GenericCard/GenericFileDisplay";
import GenericCardActionButtons from "../../general/GenericCard/GenericCardActionButtons";
import { useTranslation } from "react-i18next";
import addNewAccount from "../../../assets/icons/addNewAccount.png";
import { useNotifications } from "../../../hooks/Notifications";
import { Box, CircularProgress } from "@material-ui/core";
import { MAX_PORTAL_UPLOAD_FILE_SIZE } from "../../../utils/constants";
export default ({ documents = [], onUpload, onRemove, loading }) => {
  const { t } = useTranslation();
  const { pushNotification } = useNotifications();

  const fileUpload = useRef(null);
  const onFileChange = e => {
    const file = e.target.files[0];
    const { size } = file;

    if (size > MAX_PORTAL_UPLOAD_FILE_SIZE) {
      pushNotification(t("exceeded_file_uploading_size_limit"), {
        color: "secondary",
        fixed: true
      });
      return;
    }

    onUpload(file);
  };
  return (
    <GenericCard
      title={
        <div style={{ padding: "8px 0" }}>{t("Certificate Documents")}</div>
      }
      content={documents.map((document, index) => (
        <GenericFileDisplay
          key={index}
          document={document}
          onDelete={() => onRemove(document.id)}
        />
      ))}
      actions={
        loading ? (
          <Box display="flex" justifyContent="end" width="100%" height="48px">
            <CircularProgress color="secondary" />
          </Box>
        ) : (
          <>
            <GenericCardActionButtons
              buttons={[
                {
                  text: "Add Document",
                  icon: addNewAccount,
                  onClick: () => fileUpload.current.click()
                }
              ]}
            />
            <input
              ref={fileUpload}
              type="file"
              id="file"
              style={{ display: "none" }}
              onChange={onFileChange}
            />
          </>
        )
      }
    />
  );
};
