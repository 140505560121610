import gql from "graphql-tag";

export default gql`
  query GetDocument(
    $token: String!
    $partnerId: String!
    $documentId: String!
  ) {
    GetDocument(token: $token, partnerId: $partnerId, documentId: $documentId) {
      id
      documentType
      downloadLink
      documentName
      uploadedByMetaloop
      fileType
    }
  }
`;
