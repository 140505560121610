import React from "react";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

// styles
const useStyles = makeStyles(theme => ({
  figureWrap: {
    position: "relative",
    marginBottom: "16px"
  },
  imgWrap: {
    margin: "0",
    position: "relative",
    width: "100%",
    "& img": {
      width: "100%",
      height: "100%",
      objectFit: "cover"
    }
  },
  pickUpBadge: {
    padding: "1px 4px 0 4px",
    fontWeight: "500",
    fontSize: "10px",
    lineHeight: "16px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    letterSpacing: "1.5px",
    textTransform: "uppercase",
    color: theme.palette.primary.main,
    background: theme.palette.secondary.main,
    position: "absolute"
    // top: "12px",
    // left: "-20px"
  },
  figureCol: {
    flexBasis: "100%",
    width: "100%",
    padding: "0 8px"
  },
  [theme.breakpoints.up("md")]: {
    figureWrap: {
      height: "100%",
      marginBottom: "0",
      maxHeight: "220px"
    },
    imgWrap: {
      overflow: "hidden"
    },
    figureCol: {
      flexBasis: "116px",
      width: "116px"
    }
  }
}));

const PickUpCardImageWrapper = ({ imageHeight, imageSrc }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.figureCol}>
      <div className={classes.figureWrap}>
        <figure
          className={classes.imgWrap}
          style={{
            height: imageHeight
          }}
        >
          <img src={imageSrc} width="100%" height="100%" alt="metal" />
        </figure>
      </div>
    </div>
  );
};

PickUpCardImageWrapper.defaultProps = {
  badgePosition: {
    top: "12px",
    left: "-20px"
  }
};

export default PickUpCardImageWrapper;
