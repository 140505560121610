/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useContext, useState, useEffect } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import Hidden from "@material-ui/core/Hidden";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { Toolbar } from "@material-ui/core";
import DropDownNavMenu from "./DropDownNavMenu";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import UserContext from "../../../providers/UserProvider";
import { canSeeItem } from "../../../utils/SharedUtils.js";
import LeftNavigation from "./LeftNavigation";
import { ROUTES } from "./constants";
import { NotificationsMenu } from "./notifications";
import { SupportMenu } from "./support";

const useStyles = makeStyles({
  tabBar: {
    flexGrow: 1,
    "& .MuiTab-root": {
      minWidth: "auto"
    }
  },
  logo: {
    paddingLeft: "8px",
    width: "240px",
    borderRightColor: "rgba(0,0,0,.12)",
    borderRightWidth: "1px",
    borderRightStyle: "solid",
    display: "flex",
    alignItems: "center",
    height: "48px"
  }
});

const Menu = ({ onLogout, leftNavigation }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match = useRouteMatch();

  const classes = useStyles();
  const { userData, selectedCompany } = useContext(UserContext);

  const canSeeSupportIcon = canSeeItem(
    selectedCompany,
    userData.UserCompanies,
    {
      rules: "support_icon_view"
    }
  );

  return (
    <Hidden smDown>
      <AppBar position="static">
        <Toolbar variant="dense">
          <div className={classes.logo}>
            <Logo />
          </div>
          <Tabs value={match.path} className={classes.tabBar}>
            {ROUTES.map(
              ({ id, to, text, canSeeRules, children, hideInMenu }) =>
                !canSeeItem(
                  selectedCompany,
                  userData.UserCompanies,
                  canSeeRules
                ) || hideInMenu ? null : (
                  <Tab
                    value={to}
                    key={"menu_" + id}
                    label={t(text)}
                    onClick={() =>
                      history.push(
                        to +
                          ((children && children.length && children[0].to) ||
                            "")
                      )
                    }
                  />
                )
            )}
          </Tabs>
          {canSeeSupportIcon && <SupportMenu />}
          <NotificationsMenu />
          <DropDownNavMenu
            selectedCompany={selectedCompany}
            userData={userData}
            onLogout={onLogout}
            showName={true}
          />
        </Toolbar>
      </AppBar>

      {leftNavigation ? <LeftNavigation /> : null}
    </Hidden>
  );
};

export default Menu;
