import React from "react";
import { withStyles } from "@material-ui/core/styles";
import UnauthWizardContainer from "./UnauthWizardContainer";
import HeaderComponent from "../../components/pricing/header/HeaderComponent";

const styles = theme => ({
  centerContent: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  wrapper: {
    position: "absolute",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    top: "0",
    left: "0",
    width: "100vw",
    height: "100vh",
    background: "white",
    overflowY: "auto",
    overflowX: "hidden",
    zIndex: "100"
  }
});

class UnauthWizardPage extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <>
        <div className={classes.wrapper}>
          <HeaderComponent />
          <div className={classes.centerContent}>
            <UnauthWizardContainer />
          </div>
        </div>
      </>
    );
  }
}

export default withStyles(styles)(UnauthWizardPage);
