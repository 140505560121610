import React, { useContext } from "react";
import { useQuery } from "react-apollo";
import UserContext from "../../providers/UserProvider";
import UserTransactionsQuery from "../../queries/UserTransactionsQuery";

export const TransactionsCounter = props => {
  const { user, selectedCompany } = useContext(UserContext);

  const { type, transactionType } = props;

  const { loading, data } = useQuery(UserTransactionsQuery, {
    variables: {
      productID: null,
      token: user.token,
      company: selectedCompany,
      type: type === "complete" ? "closed" : type,
      limit: 1,
      offset: 0,
      startDate: null,
      endDate: null,
      transaction: "all",
      searchText: null,
      transportType: "all",
      transactionType
    },
    fetchPolicy: "cache-and-network"
  });

  if (loading) return null;
  if (!(data && data.UserTransactions)) return null;

  return <span>&nbsp;({data.UserTransactions.actualTransactions})</span>;
};
