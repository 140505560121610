import gql from "graphql-tag";

export default gql`
  mutation sendResetedPassword($token: String!, $password: String!) {
    sendResetedPassword(token: $token, password: $password) {
      partnerId
      email
      password
    }
  }
`;
