import React, { useCallback, useState } from "react";
import {
  Button as ButtonMui,
  makeStyles,
  Menu,
  MenuItem
} from "@material-ui/core";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const buttonStyle = makeStyles({
  buttonRoot: { padding: "10px 16px" },
  buttonDropdownRoot: {
    padding: "10px 12px"
  },
  menuDropdownRoot: {
    "& .disabled": {
      cursor: "not-allowed",
      "& .MuiMenuItem-root": {
        pointerEvents: "none",
        color: "gray"
      }
    }
  },
  buttonLabel: {
    height: "16px",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "16px",
    letterSpacing: "1px"
  },
  expandMoreIcon: {
    transform: "translateY(1px)",
    marginLeft: "12px"
  }
});

export const Button = ({
  color,
  variant,
  children,
  onClick,
  className,
  style,
  type,
  disabled,
  fullWidth
}) => {
  const classes = buttonStyle();
  return (
    <ButtonMui
      type={type}
      variant={variant}
      color={color}
      disableElevation
      onClick={onClick}
      classes={{ root: classes.buttonRoot, label: classes.buttonLabel }}
      className={className}
      style={style}
      disabled={disabled}
      {...(fullWidth ? { fullWidth: true } : null)}
    >
      {children}
    </ButtonMui>
  );
};

export const DropdownButton = ({
  color,
  variant,
  children,
  onClick,
  className,
  style,
  type,
  options,
  disabled
}) => {
  const classes = buttonStyle();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback(event => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <div>
      <ButtonMui
        type={type}
        variant={variant}
        color={color}
        disableElevation
        onClick={handleClick}
        classes={{
          root: classes.buttonDropdownRoot,
          label: classes.buttonLabel
        }}
        className={className}
        style={style}
        aria-controls="dropdown-menu"
        aria-haspopup="true"
        disabled={disabled}
      >
        {children}{" "}
        <span style={{ marginLeft: "12px" }} className={classes.expandMoreIcon}>
          <ExpandMoreIcon />
        </span>
      </ButtonMui>
      <Menu
        id="dropdown-menu"
        className={classes.menuDropdownRoot}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        keepMounted
        open={!!anchorEl}
        onClose={handleClose}
      >
        {options.map(o => (
          <div className={o.disabled ? "disabled" : null}>
            <MenuItem
              onClick={() => {
                onClick(o.value);
                handleClose();
              }}
            >
              {o.label}
            </MenuItem>
          </div>
        ))}
      </Menu>
    </div>
  );
};

export default Button;
