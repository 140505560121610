import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import MaterialCardInModal from "./MaterialCardInModal";

const useStyles = makeStyles(theme => ({
  materialPreviewHeader: {
    color: "rgba(0, 0, 0, 0.60))",
    fontFeatureSettings: "'clig' off, 'liga' off",
    fontFamily: "Roboto",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px",
    letterSpacing: "0.25px",
    marginBottom: "8px"
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px"
  },
  column: {
    flex: "0 0 48%", // each column takes about half the width with some space in between
    display: "flex",
    flexDirection: "column"
  }
}));

const CompareMaterialImages = ({ item, previewUrl }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <div className={classes.column}>
        <Typography className={classes.materialPreviewHeader}>
          {t("newMatImg_currentView")}
        </Typography>
        <MaterialCardInModal item={item} />
      </div>
      <div className={classes.column}>
        <Typography className={classes.materialPreviewHeader}>
          {t("newMatImg_updatedView")}
        </Typography>
        <MaterialCardInModal item={item} previewUrl={previewUrl} />
      </div>
    </div>
  );
};

export default CompareMaterialImages;
