import { createStyles, makeStyles } from "@material-ui/core";

const offerListStyles = makeStyles(
  createStyles(theme => ({
    leftMenuContainer: {
      maxWidth: "320px",
      overflowY: "overlay",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      flexWrap: "nowrap",
      "&::-webkit-scrollbar": {
        width: 14,
        borderRadius: "8px"
      },
      "&::-webkit-scrollbar-track": {
        display: "none"
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundClip: "padding-box",
        border: "4px solid rgba(0, 0, 0, 0)",
        background: "rgba(0, 0, 0, 0.08)",
        "&:hover": {
          backgroundClip: "padding-box",
          border: "4px solid rgba(0, 0, 0, 0)",
          background: "rgba(0, 0, 0, 0.08)"
        }
      },
      "& > div:nth-child(even)": {
        backgroundColor: "#FFFFFF"
      }
    },
    menuItem: {
      border: "1px solid #EEE",
      borderTop: "0",
      borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
      cursor: "pointer",
      backgroundColor: "#F9F9F9",
      height: "fit-content",
      "&[data-new=true]": {
        backgroundColor: "#FFFFFF"
      }
    },
    menuItemMobile: {
      marginTop: "20px",
      borderRadius: 4,
      border: "1px solid rgba(0, 0, 0, 0.12)"
    },
    selectedMenuItem: {
      backgroundColor: "#EAFEF3 !important",
      boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)"
    },
    menuItemContent: {
      padding: "32px 32px 12px",
      fontWeight: "normal",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      color: "rgba(0, 0, 0, 0.6)",
      [theme.breakpoints.down("md")]: {
        padding: "24px 24px 8px"
      }
    },
    scrollbar: {
      height: "100%",
      width: "306px",
      borderLeft: "1px solid #EEEEEE",
      marginRight: "5px"
    },
    tenderID: {
      fontWeight: "500",
      fontSize: "16px",
      lineHeight: "24px",
      letterSpacing: 0.25,
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "2px"
    },
    bidBefore: {
      color: "rgba(0, 0, 0, 0.87)",
      paddingBottom: 12,
      letterSpacing: 0.25
    },
    productList: {
      paddingTop: 8,
      display: "block",
      fontSize: 16,
      lineHeight: "24px",
      letterSpacing: 0.25
    },
    expiringTextContainer: {
      display: "flex",
      justifyContent: "space-around",
      alignItems: "center",
      padding: "8px 32px",
      backgroundColor: "#FFC043",
      [theme.breakpoints.down("md")]: {
        padding: "8px 16px 8px 24px"
      }
    },
    expiringText: {
      color: "rgba(0, 0, 0, 0.87)"
    },
    spacer: {
      height: 12,
      [theme.breakpoints.down("md")]: {
        height: 8
      }
    }
  }))
);

export default offerListStyles;
