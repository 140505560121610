export const contracts_schema = {
  type: "object",
  properties: {
    contract_id: {
      type: ["integer"]
    },
    contract_name: {
      type: "string"
    },
    supplier_name: { type: "string" },
    site_name: { type: "string" },
    material_name: {
      type: "string",
      required: ["material_name", "metaloop_material_id"]
    },
    metaloop_material_id: {
      type: ["integer"],
      required: ["material_name", "metaloop_material_id"]
    },
    material_rate: { type: "string" },
    total_quantity: { type: ["number"] }
  },
  required: ["contract_id", "supplier_name", "site_name", "material_rate"],
  anyOf: [
    { required: ["material_name"] },
    { required: ["metaloop_material_id"] }
  ],
  additionalProperties: true
};

export const transactions_schema = {
  type: "object",
  properties: {
    order_id: {
      type: ["integer"]
    },
    date: {
      type: "string"
    },
    supplier_name: {
      type: "string"
    },
    site_name: {
      type: "string"
    },
    country_code: {
      type: "string"
    },
    plant_name: {
      type: "string"
    },
    material_name: {
      type: "string",
      required: ["material_name", "metaloop_material_id"]
    },
    metaloop_material_id: {
      type: ["integer"],
      required: ["material_name", "metaloop_material_id"]
    },
    tonnage: {
      type: ["number"]
    },
    cost_per_ton_local: {
      type: ["number"]
    },
    total_cost_local: {
      type: ["number"]
    },
    currency: {
      type: "string",
      enum: [
        "AED",
        "AFA",
        "ALL",
        "AMD",
        "ANG",
        "AOA",
        "ARS",
        "AUD",
        "AWG",
        "AZM",
        "BAM",
        "BBD",
        "BDT",
        "BGN",
        "BHD",
        "BIF",
        "BMD",
        "BND",
        "BOB",
        "BRL",
        "BSD",
        "BTN",
        "BWP",
        "BYR",
        "BZD",
        "CAD",
        "CDF",
        "CHF",
        "CLP",
        "CNY",
        "COP",
        "CRC",
        "CSD",
        "CUP",
        "CVE",
        "CYP",
        "CZK",
        "DJF",
        "DKK",
        "DOP",
        "DZD",
        "EEK",
        "EGP",
        "ERN",
        "ETB",
        "EUR",
        "FJD",
        "FKP",
        "GBP",
        "GEL",
        "GGP",
        "GHC",
        "GIP",
        "GMD",
        "GNF",
        "GTQ",
        "GYD",
        "HKD",
        "HNL",
        "HRK",
        "HTG",
        "HUF",
        "IDR",
        "ILS",
        "IMP",
        "INR",
        "IQD",
        "IRR",
        "ISK",
        "JEP",
        "JMD",
        "JOD",
        "JPY",
        "KES",
        "KGS",
        "KHR",
        "KMF",
        "KPW",
        "KRW",
        "KWD",
        "KYD",
        "KZT",
        "LAK",
        "LBP",
        "LKR",
        "LRD",
        "LSL",
        "LTL",
        "LVL",
        "LYD",
        "MAD",
        "MDL",
        "MGA",
        "MKD",
        "MMK",
        "MNT",
        "MOP",
        "MRO",
        "MTL",
        "MUR",
        "MVR",
        "MWK",
        "MXN",
        "MYR",
        "MZM",
        "NAD",
        "NGN",
        "NIO",
        "NOK",
        "NPR",
        "NZD",
        "OMR",
        "PAB",
        "PEN",
        "PGK",
        "PHP",
        "PKR",
        "PLN",
        "PYG",
        "QAR",
        "ROL",
        "RON",
        "RUB",
        "RWF",
        "SAR",
        "SBD",
        "SCR",
        "SDD",
        "SEK",
        "SGD",
        "SHP",
        "SIT",
        "SKK",
        "SLL",
        "SOS",
        "SPL",
        "SRD",
        "STD",
        "SVC",
        "SYP",
        "SZL",
        "THB",
        "TJS",
        "TMM",
        "TND",
        "TOP",
        "TRL",
        "TRY",
        "TTD",
        "TVD",
        "TWD",
        "TZS",
        "UAH",
        "UGX",
        "USD",
        "UYU",
        "UZS",
        "VEB",
        "VND",
        "VUV",
        "WST",
        "XAF",
        "XAG",
        "XAU",
        "XCD",
        "XDR",
        "XOF",
        "XPD",
        "XPF",
        "XPT",
        "YER",
        "ZAR",
        "ZMK",
        "ZWD"
      ]
    },
    cost_per_ton_eur: {
      type: ["number"]
    },
    total_cost_eur: {
      type: ["number"]
    },
    contract_id: {
      type: ["integer"]
    }
  },
  required: [
    "order_id",
    "supplier_name",
    "site_name",
    "country_code",
    "tonnage",
    "currency",
    "cost_per_ton_eur",
    "total_cost_eur"
  ],
  anyOf: [
    { required: ["material_name"] },
    { required: ["metaloop_material_id"] }
  ],
  additionalProperties: true
};
