import gql from "graphql-tag";

export default gql`
  query getCompaniesForAdmin($token: String!, $searchTerm: String) {
    getCompaniesForAdmin(token: $token, searchTerm: $searchTerm) {
      _id
      name
    }
  }
`;
