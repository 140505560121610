import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CustomDialog from "../../general/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";

import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiDialog-paper": {
      width: "100%",
      maxWidth: "640px",
      borderRadius: "0px",
      margin: "0",
      boxShadow: "0 19px 38px 0 rgba(0, 0, 0, 0.50)"
    },
    "& .MuiDialogContent-root": {
      minHeight: "fit-content",
      padding: "0 24px"
    },
    "& .dialog-title": {
      padding: "48px 24px 8px 24px",
      "@media (max-width: 960px)": {
        padding: "40px 16px 16px 8px"
      }
    },
    "& .MuiDialogActions-root": {
      gap: "8px",
      padding: "48px 24px 24px 24px"
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiDialog-paper": {
        width: "100%",
        maxWidth: "100%",
        borderRadius: "0px",
        boxShadow: "none"
      },
      "& .MuiIconButton-root": {
        left: "auto"
      },
      "& .fullScreen": {
        gap: "8px",
        alignItems: "flex-start",
        "& .MuiButtonBase-root": {
          paddingTop: "0"
        }
      },
      "& .MuiDialogContent-root": {
        padding: "0 16px"
      },
      "& .MuiDialogActions-root": {
        padding: "20px 16px 24px 16px"
      }
    }
  },
  delete_button_text: {
    border: "1px solid #E11900",
    color: "#E11900"
  }
}));

const DeleteDialog = ({
  title,
  text,
  delete_button_text,
  onDelete,
  isDeleting,
  closeModals
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <CustomDialog
      open={isDeleting}
      title={t(title)}
      rootClassName={classes.root}
      onClose={closeModals}
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t(text)}
          <br /> {t("Are you sure?")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          className={classes.delete_button_text}
          onClick={onDelete}
          variant="outlined"
          color="error"
        >
          {t(delete_button_text)}
        </Button>
        <Button onClick={closeModals} variant="contained" color="primary">
          {t("Cancel")}
        </Button>
      </DialogActions>
    </CustomDialog>
  );
};

export default DeleteDialog;
