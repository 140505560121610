import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useContext
} from "react";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import CustomDialog from "../../../general/Dialog";
import STEPS, { StepTitleComponent } from "./steps";
import classNames from "classnames";
import {
  INVOICE_UPLOAD_TYPE,
  WEIGHTNOTE_UPLOAD_TYPE,
  MATERIAL_IMAGES_UPLOAD_TYPE,
  WEIGHTNOTE_KEY,
  MATERIAL_IMAGES_KEY,
  INVOICE_KEY,
  OTHER_KEY
} from "./steps";
import { useNotifications } from "../../../../hooks/Notifications";
import UserContext from "../../../../providers/UserProvider";
import FINISH_AVISO_MUTATION from "../../../../mutations/finishAvisoAndComment";
import { useMutation } from "react-apollo";
import useEventTracking from "../../../../hooks/useEventTracking";
import { FILE_TYPES_OPTIONS_WITHOUT_TRANSLATE } from "./components/FileList/fileTypeOptions";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(theme => ({
  root: {
    "& > .dialog-title": {
      paddingTop: "48px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "0"
      },
      "& > .MuiTypography-h2": {
        [theme.breakpoints.down("sm")]: {
          position: "absolute",
          top: 0,
          paddingTop: "10px",
          paddingLeft: "24px"
        },
        display: "flex",
        gap: "10px"
      }
    },
    "&  > .MuiDialogContent-root": {
      paddingTop: "12px",
      minHeight: "0px",

      [theme.breakpoints.down("sm")]: {
        paddingTop: "0"
      }
    },
    "& > .MuiDialogActions-root": {
      paddingTop: "36px"
    }
  },
  hideTitle: {
    "& > .dialog-title": { display: "none" }
  }
}));

const INITIAL_TYPES = {
  [INVOICE_KEY]: INVOICE_UPLOAD_TYPE,
  [MATERIAL_IMAGES_KEY]: MATERIAL_IMAGES_UPLOAD_TYPE,
  [WEIGHTNOTE_KEY]: WEIGHTNOTE_UPLOAD_TYPE,
  [OTHER_KEY]: OTHER_KEY
};

const UploadDialog = ({
  orderType,
  orderId,
  onClose,
  initialType,
  accept = "image/*, application/pdf,",
  seller = false,
  disableInvoiceUpload = false
}) => {
  const [step, setStep] = useState(0);
  const [files, setFiles] = useState([]);
  const [type, setType] = useState(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { user, selectedCompany } = useContext(UserContext);
  const { TrackEvent } = useEventTracking();
  const [finishAvisoMutation] = useMutation(FINISH_AVISO_MUTATION);

  useEffect(() => {
    const type = INITIAL_TYPES[initialType];
    if (type) {
      setStep(1);
      setType(type);
    }
  }, []);

  const setPrevStepAction = useCallback(() => {
    if (step === 0) return;
    setStep(step - 1);
  }, [step, setStep]);

  const setNextStepAction = useCallback(() => {
    if (step === STEPS.length - 1) return;

    setStep(step + 1);
  }, [step, setStep]);

  const {
    title,
    titleIcon,
    hideTitle = false,
    disableClose,
    component: Component
  } = useMemo(() => STEPS[step], [step]);

  const fullTitle = StepTitleComponent({
    fullScreen,
    title,
    titleIcon,
    type
  });

  const { pushNotification } = useNotifications();
  const closingModal = commentInput => {
    const comment = typeof commentInput === "string" ? commentInput : "";

    if (commentInput === "limitExceeded") {
      pushNotification(t("exceeded_file_uploading_size_limit"), {
        color: "secondary",
        fixed: true
      });
      onClose();
      return;
    }

    if (step < 3) {
      pushNotification(t("Snackbar_Upload_NoFilesUploaded"), {
        color: "secondary",
        fixed: true
      });
    } else {
      let message = "";
      if (files.length > 1) {
        if (type === WEIGHTNOTE_UPLOAD_TYPE) {
          message = "Snackbar_Upload_MultipleWeightnotesNoComment";
        } else if (type === MATERIAL_IMAGES_UPLOAD_TYPE) {
          message = "Snackbar_Upload_MultipleImages";
        } else {
          message = "Snackbar_Upload_MultipleDocuments";
        }
      } else {
        if (type === WEIGHTNOTE_UPLOAD_TYPE) {
          message = "Snackbar_Upload_SingleWeightnoteNoComment";
        } else if (type === MATERIAL_IMAGES_UPLOAD_TYPE) {
          message = "Snackbar_Upload_SingleImage";
        } else {
          message = "Snackbar_Upload_SingleDocument";
        }
      }

      const docTypeMapping = {
        [WEIGHTNOTE_UPLOAD_TYPE]: "Weightnote",
        [MATERIAL_IMAGES_UPLOAD_TYPE]: "Material Image",
        [INVOICE_UPLOAD_TYPE]: "Invoice"
      };

      const docType =
        docTypeMapping[type] ||
        FILE_TYPES_OPTIONS_WITHOUT_TRANSLATE[files[0].documentType];

      //We do the mutation for sending the email and publish the history
      finishAvisoMutation({
        variables: {
          token: user?.token,
          selectedCompany: selectedCompany,
          input: { orderId, comment: comment },
          isAWeightNote: type === WEIGHTNOTE_UPLOAD_TYPE ? true : false
        }
      }).then(() => {
        TrackEvent("Upload document flow completed", {
          orderId,
          documentType: docType,
          documentName: files[0].name,
          clickSource: "Button"
        });
      });

      pushNotification(t(message), { color: "success", fixed: true });
    }
    onClose();
  };
  return (
    <CustomDialog
      open={true}
      title={fullTitle}
      onClose={closingModal}
      disableClose={disableClose}
      mode="advanced"
      fullScreen={fullScreen}
      maxWidth="xl"
      scroll="paper"
      PaperProps={{
        className: classNames(classes.root, hideTitle && classes.hideTitle)
      }}
    >
      <Component
        type={type}
        files={files}
        title={fullTitle}
        orderId={orderId}
        setType={setType}
        onClose={closingModal}
        setFiles={setFiles}
        orderType={orderType}
        fullScreen={fullScreen}
        setPrevStepAction={setPrevStepAction}
        setNextStepAction={setNextStepAction}
        seller={seller}
        accept={accept}
        disableInvoiceUpload={disableInvoiceUpload}
      />
    </CustomDialog>
  );
};

export default UploadDialog;
