import React from "react";
import gql from "graphql-tag";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { flowRight as compose } from "lodash";

import { graphql } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { loginUser } from "../../actions/auth";
import ResetForm from "../../components/forms/ResetForm";
import { CheckErrors } from "../../ErrorTracking";
import { resetPassword } from "../../mutations/resetPassword";

const styles = {
  paper: {
    padding: "3em",
    "@media (max-width: 500px)": {
      width: "80%",
      margin: "0 auto",
      minWidth: "5em"
    }
  }
};

class ResetPasswordContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loginErrorMessage: ""
    };

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit({ password } = {}) {
    const { token, history, resetPasswordMutation } = this.props;

    resetPasswordMutation({
      variables: {
        input: {
          token,
          password
        }
      }
    }).then(({ data: { resetPassword } }) => {
      if (resetPassword === "Invalid token") {
        return this.setState({ loginErrorMessage: resetPassword });
      } else {
        const { loginUser: loginUserAction } = this.props.actions;
        loginUserAction(resetPassword, password);
        history.push("/dashboard");
      }
    });
  }

  render() {
    const { loginErrorMessage } = this.state;

    return (
      <CheckErrors>
        <ResetForm
          onSubmit={this.onSubmit}
          loginErrorMessage={loginErrorMessage}
        />
      </CheckErrors>
    );
  }
}

ResetPasswordContainer.defaultProps = {
  classes: {},
  loginErrorMessage: ""
};

ResetPasswordContainer.propTypes = {
  token: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  actions: PropTypes.shape({
    loginUser: PropTypes.func
  }).isRequired,
  classes: PropTypes.object,
  resetPasswordMutation: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ loginUser }, dispatch)
});

const mapStateToProps = ({ user: { loginErrorMessage } }) => ({
  loginErrorMessage
});

ResetPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer);

export default compose(
  graphql(resetPassword, {
    name: "resetPasswordMutation"
  })
)(
  withStyles(styles)(
    connect(() => ({}), mapDispatchToProps)(ResetPasswordContainer)
  )
);
