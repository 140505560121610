import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import {
  Grid,
  Typography,
  Paper,
  Select,
  MenuItem,
  Input,
  IconButton
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/AddCircleOutline";
import RemoveIcon from "@material-ui/icons/RemoveCircleOutline";
import Button from "../../formElements/Button";
import { ReactComponent as MitarbeiterImage } from "../../../../assets/images/mitarbeiter.svg";
import { ReactComponent as StandorteImage } from "../../../../assets/images/standorte.svg";
import { withTranslation } from "react-i18next";

const styles = theme => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    justifyItems: "space-between",
    flexDirection: "column"
  },
  buttons: {
    display: "flex",
    marginTop: "2em",
    justifyContent: "space-evenly"
  },
  item: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    color: "rgba(95,103,117,1)"
  },
  itemButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "rgba(95,103,117,1)",
    padding: "10px 5px"
  },
  text: {
    marginBottom: "3em",
    textAlign: "center",
    width: "100%"
  },
  paperContainer: {
    padding: "10px 20px"
  },
  paperItem: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
    color: "rgba(95,103,117,1)",
    padding: "10px",
    "& > *:nth-child(2)": {
      margin: "20px 0"
    }
  },
  itemText: {
    fontSize: "1.2rem"
  },
  input: {
    width: "40px",
    textAlign: "center",
    "& input": {
      textAlign: "center"
    }
  }
});

const numEmployees = ["<10", "<20", "<30", "<50", "<70", "<100", "<150"];

class CompanyInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employees: props.numEmployee,
      locations: props.numLocations
    };
  }

  handleEmployeeChange = event => {
    const { setNumEmployee } = this.props;
    this.setState({ [event.target.name]: event.target.value });
    setNumEmployee(event.target.value);
  };

  handleLocationdChange = numLocations => {
    const { setNumLocations } = this.props;
    setNumLocations(numLocations);
  };

  addOne = () => {
    const newLocations = this.state.locations + 1;
    this.setState({ locations: newLocations });
    this.handleLocationdChange(newLocations);
  };

  removeOne = () => {
    let newLocations = this.state.locations - 1;
    newLocations = newLocations < 0 ? 0 : newLocations;
    this.setState({ locations: newLocations });
    this.handleLocationdChange(newLocations);
  };

  render() {
    const { classes, t, onSubmit, onNext, onBack } = this.props;

    return (
      <form
        onSubmit={ev => {
          ev.preventDefault();
          onSubmit(ev);
        }}
        className={classes.wrapper}
      >
        <Grid container className={classes.container}>
          <Grid item xs={12} className={classes.item}>
            <Typography variant="subtitle1" className={classes.text}>
              {t("Wieviele Mitarbeiter und Standorte hat Ihr Unternehmen?")}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.paperContainer}>
            <Paper elevation={1} className={classes.paperItem}>
              <Select
                color="primary"
                value={this.state.employees}
                onChange={this.handleEmployeeChange}
                inputProps={{
                  name: "employees",
                  id: "employees"
                }}
              >
                {numEmployees.map((item, i) => (
                  <MenuItem key={i} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
              <MitarbeiterImage />
              <Typography variant="h5" className={classes.itemText}>
                {t("Mitarbeiter")}
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.paperContainer}>
            <Paper elevation={1} className={classes.paperItem}>
              <div className={classes.locationInput}>
                <IconButton aria-label="Add" onClick={this.removeOne}>
                  <RemoveIcon color="primary" />
                </IconButton>
                <Input
                  color="primary"
                  className={classes.input}
                  readOnly
                  value={this.state.locations}
                />
                <IconButton aria-label="Remove" onClick={this.addOne}>
                  <AddIcon color="primary" />
                </IconButton>
              </div>
              <StandorteImage />
              <Typography variant="h5" className={classes.itemText}>
                {t("Standorte")}
              </Typography>
            </Paper>
          </Grid>
          <Grid
            item
            container
            xs={12}
            className={classes.buttons}
            justify="center"
          >
            <Button
              className={classes.button}
              text={"< " + t("Züruck")}
              onClick={onBack}
            />
            <Button
              disabled={this.state.locations === 0}
              className={classes.button}
              text={t("Weiter") + " >"}
              onClick={onNext}
            />
          </Grid>
        </Grid>
      </form>
    );
  }
}

export default withRouter(withStyles(styles)(withTranslation()(CompanyInfo)));
