import gql from "graphql-tag";

export default gql`
  mutation OnboardingTransportInformationMutation(
    $token: String!
    $input: OnboardingTransportInformationInput!
    $options: OnboardingMutationOptions
  ) {
    OnboardingTransportInformationMutation(
      token: $token
      input: $input
      options: $options
    ) {
      success
    }
  }
`;
