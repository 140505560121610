import React from "react";
import _ from "lodash";

const useFilePartition = files => {
  const [myDocuments, metaloopDocuments] = React.useMemo(() => {
    if (!files) {
      return [[], []];
    }
    return _.partition(files, file => {
      const documentType = file.documentType;
      const uploadedByMetaloop = file.uploadedByMetaloop;

      if (uploadedByMetaloop !== null) {
        return !uploadedByMetaloop;
      }

      return (
        documentType === "Extra Files Buyer" ||
        documentType === "Extra Files Seller" ||
        documentType === "Credit Note From Seller" ||
        documentType === "Credit Note From Buyer"
      );
    });
  }, [files?.length]);

  // partition files into metaloopDocuments, and myDocuments
  return [metaloopDocuments, myDocuments];
};

export default useFilePartition;
