import { PreferenceTopic } from "./PreferenceTopic";
import { makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(theme => ({
  header: {
    fontSize: "24px",
    color: "#000000",
    fontWeight: 500,
    padding: "35px 0px 10px 32px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "16px",
      padding: "15px 0px 5px 16px"
    }
  },
  topContainer: {
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "32px"
    }
  }
}));
export const PreferenceSection = ({
  NotificationPreference,
  token,
  partnerId,
  subtopics
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  if (!NotificationPreference) return null;

  return (
    <div className={classes.topContainer}>
      <div className={classes.header}>{t("Notification Topics")}</div>
      {NotificationPreference.map((preference, index) => (
        <PreferenceTopic
          token={token}
          partnerId={partnerId}
          topic={preference.topic}
          status={preference.status}
          description={preference.description}
          RAR={preference.RAR}
          reason={preference.reason}
          id={preference.id}
          subtopics={subtopics[preference.id]}
        />
      ))}
    </div>
  );
};
