import React, { useState, useEffect, useRef } from "react";
import { useMutation } from "react-apollo";
import { makeStyles } from "@material-ui/core/styles";
import checkDriverToken from "../../mutations/checkDriverToken";
import { useNotifications } from "../../hooks/Notifications";
import useEventTracking from "../../hooks/useEventTracking";
import Loader from "../../components/core/shared/Loader";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import ThirdStep from "./ThirdStep";
import { WEIGHTNOTE_FILE_TYPE } from "../../components/core/shared/uploadDialog/components/FileList/fileTypeOptions";
import DocPreviewProvider from "../../components/core/shared/DocPreviewProvider";
const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.up("md")]: {
      width: "640px"
    }
  }
}));

export default function FileUploadPage({
  accept = "image/*, application/pdf,"
}) {
  const classes = useStyles();
  const [step, setStep] = useState(null);
  const [files, setFiles] = useState([]);
  const { pushNotification } = useNotifications();
  const [checkDriverTokenMutation] = useMutation(checkDriverToken);
  const [finishClikced, setFinishClicked] = useState(false);
  const [driverTokenValidity, setDriverTokenValidity] = useState(null);
  const { TrackEvent } = useEventTracking();

  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("params");
  const firstCheck = useRef(false);
  useEffect(() => {
    checkDriverTokenMutation({
      variables: { token }
    })
      .then(response => {
        const { valid, message, data } = response.data.checkDriverToken;
        setDriverTokenValidity({ valid, message, data });
        if (!valid) {
          setStep(4);
        } else {
          if (!firstCheck.current) {
            setStep(1);
            TrackEvent("Upload Weight Note Link Opened", {
              orderId: data?.order?._id,
              partnerId: data?.partner?.partnerId,
              productId: data?.product?.productID,
              documentType: WEIGHTNOTE_FILE_TYPE,
              clickSource: "Scanned QR code or clicked link"
            });
            firstCheck.current = true;
          }
        }
      })
      .catch(error => {
        setDriverTokenValidity({
          valid: false,
          message: "Token validation failed."
        });
        setStep(4);
        console.log("Error while validating token", error);
      });
  }, [step]);

  const closingModal = commentInput => {
    setFinishClicked(true);
    const comment = commentInput;
    if (commentInput === "limitExceeded") {
      pushNotification("Please upload a file under 10 MB", {
        color: "secondary",
        fixed: true
      });
      setStep(1);
      return;
    } else if (commentInput === "closeTab") {
      window.close();
      window.history.back();
    }
    if (step < 3) {
      pushNotification("No files uploaded", {
        color: "secondary",
        fixed: true
      });
    } else {
      let message = "";
      if (files.length > 1) {
        message = "These weightnotes were uploaded successfully";
      } else {
        message = "This weightnote was uploaded successfully";
      }
      TrackEvent("Upload document flow completed", {
        orderId: driverTokenValidity?.data?.order?._id,
        documentType: WEIGHTNOTE_FILE_TYPE,
        documentName: files[0].name,
        clickSource: "Button",
        uploadByDriver: true,
        comment
      });
      pushNotification(message, { color: "success", fixed: true });
    }
  };

  const STEPS = [FirstStep, SecondStep, ThirdStep];
  const DefaultComponent = () => (
    <div>
      <h1>This session has expired</h1>
    </div>
  );

  const Component =
    step === null ? Loader : step > 3 ? DefaultComponent : STEPS[step - 1];

  return (
    <DocPreviewProvider
      token={driverTokenValidity?.data?.token}
      selectedCompany={driverTokenValidity?.data?.partner?.partnerId}
    >
      <div className={classes.root}>
        <Component
          files={files}
          setFiles={setFiles}
          setStep={setStep}
          closingModal={closingModal}
          accept={accept}
          finishClikced={finishClikced}
          setFinishClicked={setFinishClicked}
          driverTokenValidity={driverTokenValidity}
        />
      </div>
    </DocPreviewProvider>
  );
}
