import React, { memo } from "react";

import { Hidden, makeStyles } from "@material-ui/core";
import DateSelector from "../core/DateSelector";
import AutoCompleteTextField from "../core/AutoCompleteTextField";
import FiltersButton from "../core/FiltersButton";
import { Chip } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import CustomList from "../../components/general/List";
import Button from "@material-ui/core/Button";

const PriceListSearchBarStyles = makeStyles(theme => ({
  mobileContainer: {
    padding: "24px 16px"
  },
  container: {
    padding: "24px 32px",
    maxWidth: "1600px"
  },
  spacing: {
    paddingRight: "24px"
  },

  filtersContainer: {
    "& div:first-child": {
      zIndex: "500"
    }
  },
  datepickerWrapper: {
    display: "flex",
    alignItems: "center"
  },
  autocompleteWrapper: {
    zIndex: "99999"
  },
  flexContainer: {
    display: "flex",
    gap: "20px",
    padding: "24px 32px",
    maxWidth: "1600px"
  },
  flexItem1: {
    width: "320px"
  },
  flexItem2: {
    width: "240px"
  },
  flexItem3: {
    width: "240px"
  },
  chipContainerMobile: {
    padding: "0 16px"
  }
}));

const PriceListSearchBar = memo(
  ({
    categoryList,
    productList,
    filters,
    resetFilters,
    updateData,
    updateFilter
  }) => {
    const { categories, date, products } = filters;
    const classes = PriceListSearchBarStyles();
    const { t } = useTranslation();

    const getProductName = product =>
      productList.find(c => c.productID === product.value).productName;

    const handleDelete = productToDelete => {
      var tempProduct = products.filter(
        product => product.value !== productToDelete.value
      );

      updateFilter("products", tempProduct);
    };

    return (
      <>
        <Hidden mdUp>
          <div className={classes.chipContainerMobile}>
            {filters.products.map(item => (
              <Chip
                className={classes.chip}
                label={getProductName(item)}
                onClick={() => handleDelete(item)}
                onDelete={() => handleDelete(item)}
              />
            ))}
          </div>
          <div className={classes.filtersContainer}>
            <FiltersButton filters={filters} resetFilters={resetFilters}>
              <div>
                <CustomList
                  id="categories"
                  data={categoryList.map(c => ({ value: c.id, label: c.name }))}
                  placeholder={t("Category")}
                  multiple={true}
                  maxVisibleOptions={30}
                  onChange={value => updateFilter("categories", value)}
                />
              </div>
              <DateSelector
                onChange={e => {
                  updateData(null);
                  updateFilter("date", e.toDate());
                }}
                disableFuture
                value={date}
              />
            </FiltersButton>
          </div>
        </Hidden>
        <Hidden smDown>
          <div className={classes.flexContainer}>
            <div className={classes.flexItem1}>
              <AutoCompleteTextField
                onChange={item => {
                  var tempProduct = products;

                  if (
                    !tempProduct.filter(product => product.value === item.value)
                      .length > 0
                  ) {
                    tempProduct.push(item);
                  }

                  updateFilter("products", tempProduct);
                }}
                options={productList.map(c => ({
                  value: c.productID,
                  label: c.productID + " | " + c.productName
                }))}
                label={t("Product Name or ID")}
              />
            </div>
            <div className={classes.flexItem2}>
              <CustomList
                id="categories"
                data={categoryList.map(c => ({ value: c.id, label: c.name }))}
                placeholder={t("Category")}
                multiple={true}
                maxVisibleOptions={30}
                defaultSelected={categories}
                onChange={value => {
                  updateFilter("categories", value);
                }}
              />
            </div>
            <div className={classes.flexItem3}>
              <DateSelector
                onChange={e => {
                  updateData(null);
                  updateFilter("date", e.toDate());
                }}
                disableFuture
                value={date}
              />
            </div>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                resetFilters();
              }}
            >
              {t("Sales_ClearFilters")}
            </Button>
          </div>
        </Hidden>
      </>
    );
  }
);

export default PriceListSearchBar;
