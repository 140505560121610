import React, { useState } from "react";
import OnboardingForm from "../../components/onboarding/OnboardingForm";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import CityAndCountryInputs from "../../components/general/GoogleAutocompleteApi/CityAndCountryInputs";
import { useMutation } from "react-apollo";
import OnboardingCompanyInformationMutation from "../../mutations/OnboardingCompanyInformationMutation";
import Loader from "../../components/core/shared/Loader";
import { ONBOARDING_COMPANY_INFORMATION } from "../../utils/constants";
import { getInputForSaveMutation } from "../../utils/Onboarding";
import useEventTracking from "../../hooks/useEventTracking";

const useStyles = makeStyles(theme => ({
  inputText: {
    marginTop: "20px",
    width: "400px !important",
    marginBottom: "0px !important",
    maxWidth: "400px !important",
    [theme.breakpoints.down("sm")]: {
      width: "calc(100% - 30px) !important"
    }
  },
  smallInput: {
    width: "160px !important"
  }
}));

const CompanyInformation = ({
  formData,
  setFormData,
  setNextStep,
  setPreviousStep,
  token,
  componentProperties,
  trackClickNextEvent
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const [loadingMutation, setLoadingMutation] = useState(false);

  const [sendDataMutation] = useMutation(OnboardingCompanyInformationMutation);

  const header = t("onboarding_company_header");
  const subheader = t("onboarding_company_subheader");

  const fields = [
    {
      label: t("onboarding_field_company_name"),
      type: "text",
      value: formData.companyName,
      name: "companyName"
    },
    {
      label: t("onboarding_field_street_and_number"),
      type: "text",
      value: formData.streetAddress,
      name: "streetAddress"
    },
    {
      label: t("PLZ"),
      type: "text",
      value: formData.zipCode,
      name: "zipCode",
      extraClasses: classes.smallInput
    },
    {
      specialComponent: (
        <CityAndCountryInputs
          form={formData}
          classes={classes}
          setCityAndCountry={cityAndCountry =>
            setFormData({ ...formData, ...cityAndCountry })
          }
          errors={{}}
        />
      ),
      name: "city"
    }
  ];

  if (componentProperties.fields) {
    fields.forEach((field, index) => {
      if (!componentProperties.fields.includes(field.name)) {
        fields.splice(index, 1);
      }
    });
  }

  const requiredFields = JSON.parse(
    JSON.stringify(componentProperties.fields)
  ).filter(field => !componentProperties.optionals.includes(field));

  const handleNextStep = async () => {
    trackClickNextEvent();
    setLoadingMutation(true);

    const input = getInputForSaveMutation(
      ONBOARDING_COMPANY_INFORMATION,
      formData
    );

    await sendDataMutation({ variables: { token, input } });
    setNextStep();
    setLoadingMutation(false);
  };

  const handlePreviousStep = () => {
    TrackEvent("KYC Flow - Company - Clicks back");
    setPreviousStep();
  };

  if (loadingMutation) return <Loader />;

  return (
    <OnboardingForm
      formData={formData}
      setFormData={setFormData}
      header={header}
      subheader={subheader}
      fields={fields}
      requiredFields={requiredFields}
      setNextStep={handleNextStep}
      setPreviousStep={handlePreviousStep}
    />
  );
};

export default CompanyInformation;
