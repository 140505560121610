import EmptyPickUp from "./EmptyPickUp";

const LoadingPickups = () => {
  const elements = Array.from({ length: 5 }, (_, i) => i);
  return (
    <>
      {elements.map((_, index) => (
        <EmptyPickUp key={index} loading={true} />
      ))}
    </>
  );
};

export default LoadingPickups;
