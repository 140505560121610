import React, { useState } from "react";

import {
  isSameDay,
  isWithinInterval,
  isAfter,
  isBefore,
  addYears,
  addMonths,
  setYear,
  subDays
} from "date-fns";

import Menu from "./components/Menu";

const DateRangePicker = props => {
  const today = new Date();

  const markers = {
    first: "first",
    second: "second"
  };

  const { onChange, initialDateRange, minDate = 0, disableWeekends } = props;

  const minDateValid = subDays(minDate, 1) || addYears(today, -1);
  const maxDateValid = addYears(today, 5);

  const [dateRange, setDateRange] = useState({ ...initialDateRange });
  const [hoverDay, setHoverDay] = useState();

  const { startDate, endDate } = dateRange;

  const [firstMonth, setFirstMonth] = useState(startDate ? startDate : today);
  const [secondMonth, setSecondMonth] = useState(
    endDate ? endDate : addMonths(firstMonth, 1)
  );
  const onDayClick = day => {
    if (startDate && !endDate && !isBefore(day, startDate)) {
      const newRange = { startDate, endDate: day };
      onChange(newRange);
      setDateRange(newRange);
    } else {
      setDateRange({ startDate: day, endDate: undefined });
    }
    setHoverDay(day);
  };

  const onMonthNavigate = (marker, action) => {
    if (marker === markers.first) {
      const firstNew = addMonths(firstMonth, action);

      if (isBefore(firstNew, secondMonth)) {
        setFirstMonth(firstNew);
      }
    } else {
      const secondNew = addMonths(secondMonth, action);
      if (isBefore(firstMonth, secondNew)) {
        setSecondMonth(secondNew);
      }
    }
  };
  const onYearNavigate = (marker, action) => {
    if (marker === markers.first) {
      const firstNew = setYear(firstMonth, action);

      if (!isBefore(firstNew, secondMonth)) {
        setSecondMonth(firstNew);
      }
      setFirstMonth(firstNew);
    } else {
      const secondNew = setYear(secondMonth, action);
      if (!isBefore(firstMonth, secondNew)) {
        setFirstMonth(secondNew);
      }
      setSecondMonth(secondNew);
    }
  };

  const onDayHover = date => {
    if (startDate && !endDate) {
      if (!hoverDay || !isSameDay(date, hoverDay)) {
        setHoverDay(date);
      }
    }
  };

  const inHoverRange = day =>
    startDate &&
    !endDate &&
    hoverDay &&
    isAfter(hoverDay, startDate) &&
    isWithinInterval(day, {
      start: startDate,
      end: hoverDay
    });

  const helpers = {
    inHoverRange
  };

  const handlers = {
    onDayClick,
    onDayHover,
    onMonthNavigate,
    onYearNavigate
  };

  return (
    <Menu
      dateRange={dateRange}
      minDate={minDateValid}
      maxDate={maxDateValid}
      firstMonth={firstMonth}
      secondMonth={secondMonth}
      helpers={helpers}
      handlers={handlers}
      markers={markers}
      disableWeekends={disableWeekends}
    />
  );
};

export default DateRangePicker;
