import gql from "graphql-tag";

export default gql`
  query GetSingleProductByIdForRequest(
    $token: String!
    $productId: Int!
    $partnerId: String!
  ) {
    SingleProductByIdForRequest(
      token: $token
      productId: $productId
      partnerId: $partnerId
    ) {
      partnerId
      productId
      alias
      aliasForLanguage
      old_id
      image {
        imageId
        productId
        productName
        url
      }
    }
  }
`;
