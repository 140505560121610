import React from "react";
import { Card, CardContent, Tooltip, makeStyles } from "@material-ui/core";
import classnames from "classnames";
import { useTranslation } from "react-i18next";

import PickUpCardImageWrapper from "./PickUpCardImageWrapper";
import PickUpHeader from "./PickUpHeader";
import PickUpPanel from "./PickUpPanel";
import ImgSvg from "../../../../assets/images/fillings.png";
import { Info } from "@material-ui/icons";
import useStandardLocalization from "../../../../hooks/useStandardLocalization";

const useStyles = makeStyles(theme => ({
  muiPaperRoot: {
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    background: "#F9F9F9",
    position: "relative",
    "&:not(:last-of-type)": {
      marginBottom: "20px"
    }
  },
  MuiCardContentRoot: {
    position: "relative",
    padding: "20px"
  },
  // grid
  row: {
    display: "flex",
    flexWrap: "wrap"
  },
  gridRoot: {
    margin: "0 -8px"
  },
  detailCol: {
    flexBasis: "100%",
    width: "100%",
    padding: "0 8px"
  },
  detailGridRoot: {
    margin: "0 -10px"
  },
  panelWrapCol: {
    flexBasis: "50%",
    width: "50%"
  },
  detailColRoot: {
    padding: "0 10px"
  },
  fullBasis: {
    flexBasis: "100%",
    width: "100%"
  },
  slaPanel: {
    display: "inline-flex",
    alignItems: "center",
    gap: "6px"
  },
  [theme.breakpoints.up("md")]: {
    detailCol: {
      flexBasis: "calc(100% - 116px)",
      width: "calc(100% - 116px)",
      paddingTop: "12px"
    },
    panelWrapCol: {
      flexBasis: "calc(100% - 188px)",
      width: "calc(100% - 188px)"
    }
  },
  icon: {
    height: "20px",
    width: "20px"
  }
}));

const PickUpCardInModal = ({ item, showSla, imageHeight }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { parseDate, parseNumber } = useStandardLocalization();

  let tooltip_text = "";
  switch (item.slaLevel) {
    case "1":
      tooltip_text = t("SLA_Level1_Info").replaceAll("\\n", "\n");
      break;
    case "2":
      tooltip_text = t("SLA_Level2_Info").replaceAll("\\n", "\n");
      break;
    case "3":
      tooltip_text = t("SLA_Level3_Info").replaceAll("\\n", "\n");
      break;
    default:
      tooltip_text = t("SLA_Level1_Info").replaceAll("\\n", "\n");
      break;
  }

  const pickUpDateFormatted = parseDate(
    item.pickupDate || item.pickupOrderDate || item.collectionDate
  );

  return (
    <Card className={classes.muiPaperRoot} variant="outlined">
      <CardContent className={classnames(classes.MuiCardContentRoot)}>
        <div className={classnames(classes.row, classes.gridRoot)}>
          {/* pickup card image wrapper */}
          <PickUpCardImageWrapper
            imageHeight={imageHeight}
            imageSrc={item.imageSrc || ImgSvg}
            isContracted={item.isContracted}
          />
          {/*  */}
          <div className={classes.detailCol}>
            <div className={classnames(classes.row, classes.detailGridRoot)}>
              <PickUpHeader
                pickUpDateFormatted={pickUpDateFormatted}
                pickupName={item.aliasForLanguage || item.pickupName}
                cardConditions={{
                  iconDateColor: item.pickupDate
                    ? "pickupdate_black"
                    : "pickupdate_grey"
                }}
              />
              <PickUpPanel
                title={t("PICKUP ADDRESS")}
                description={
                  <>
                    {item.companyName}
                    <br /> {item.streetAddress}
                    <br /> {item.zipCode} {item.city}
                    <br /> {item.country}
                  </>
                }
              />
              <div
                className={classnames(
                  classes.panelWrapCol,
                  classes.detailColRoot
                )}
              >
                <div
                  className={classnames(classes.row, classes.detailGridRoot)}
                >
                  <div className={classes.fullBasis}>
                    <div
                      className={classnames(
                        classes.row,
                        classes.detailGridRoot
                      )}
                    >
                      <PickUpPanel
                        title={t("Transaction ID")}
                        variant="full"
                        description={item.transactionId}
                        hasMarginBottom
                      />
                      <PickUpPanel
                        title={t("Declared Quantity (kg)")}
                        variant="full"
                        description={parseNumber(item.quantity)}
                        hasMarginBottom
                      />
                      <PickUpPanel
                        title={t("Pickups_TransportDetails")}
                        variant="full"
                        description={
                          <>
                            {item.truckNumberPlate}
                            <br />
                            {item.truckType}
                          </>
                        }
                        hasMarginBottom
                      />
                      <PickUpPanel
                        title={t("Products_Packaging")}
                        variant="full"
                        description={t(item.packaging) || "-"}
                        hasMarginBottom
                      />
                      {/* slaLevel */}
                      {showSla && (
                        <PickUpPanel
                          title={
                            <div className={classes.slaPanel}>
                              {t("SLA")}
                              <Tooltip
                                arrow
                                placement="top"
                                title={
                                  <span
                                    style={{
                                      whiteSpace: "pre-line",
                                      zIndex: 20000
                                    }}
                                  >
                                    {tooltip_text}
                                  </span>
                                }
                                PopperProps={{
                                  style: { zIndex: 1300, maxWidth: 320 }
                                }}
                              >
                                <Info className={classes.icon} />
                              </Tooltip>
                            </div>
                          }
                          variant="full"
                          description={t("SLA_Level", {
                            level: item.slaLevel || "1"
                          })}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*  */}
        </div>
      </CardContent>
    </Card>
  );
};

PickUpCardInModal.defaultProps = {
  imageHeight: "152px"
};

export default PickUpCardInModal;
