import gql from "graphql-tag";

export default gql`
  mutation ChangeProductImage(
    $token: String!
    $partnerId: String!
    $productId: Int!
    $file: Upload!
  ) {
    ChangeProductImage(
      token: $token
      partnerId: $partnerId
      productId: $productId
      file: $file
    ) {
      message
    }
  }
`;
