import React from "react";

export default React.createContext({
  openOffers: 0,
  submittedOffers: 0,
  soldTransactions: 0,
  transportTransactions: 0,
  completeTransactions: 0,
  allPickups: 0,
  pendingPickups: 0,
  scheduledPickups: 0
});
