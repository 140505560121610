import React from "react";

import {
  Layers,
  Star,
  List,
  Restore,
  AccountCircle,
  Timelapse,
  Input,
  LabelOutlined,
  CheckCircleOutline,
  CheckCircleOutlineOutlined,
  FormatListBulletedOutlined,
  DashboardOutlined,
  CollectionsBookmarkOutlined,
  PinDropOutlined,
  LocalOfferOutlined,
  LocationCity,
  Euro,
  InsertDriveFileOutlined,
  LocalShippingOutlined,
  PeopleAltOutlined,
  NotificationsNoneOutlined
} from "@material-ui/icons";

import { ReactComponent as Materials } from "../../../assets/icons/home-materials.svg";
import { ReactComponent as Purchases } from "../../../assets/icons/home-purchases.svg";
import { ReactComponent as Sales } from "../../../assets/icons/home-sales.svg";

import { OfferCounter } from "../../../pages/offers/OffersRoute";
import { TransactionsCounter } from "../../../pages/transactions/TransactionsCounter";

import { PickupsCounter } from "../../../pages/pickups/PickupsCounter";
import { ProductsCounter } from "../../../pages/products/ProductsCounter";

const styles = {
  childrenItem: {
    fontSize: "15px"
  },
  childrenIcon: {
    width: "24px",
    height: "24px"
  }
};

export const ROUTES = [
  {
    id: "0",
    to: "/home",
    icon: "home",
    text: "Home",
    classname: "home",
    isHomePage: true,
    hideInMenu: true
  },
  {
    id: "1",
    to: "/dashboard",
    icon: <DashboardOutlined fontSize="large" />,
    text: "Dashboard",
    classname: "dashboard"
  },
  {
    id: "2",
    to: "/products",
    icon: <Materials />,
    text: "Materials",
    canSeeRules: {
      rules: "materials_ui"
    },
    children: [
      {
        id: "2.1",
        text: "MATERIALS"
      },
      {
        id: "2.2",
        to: "/",
        icon: <List />,
        text: "All Materials",
        appendInMobile: true,
        append: <ProductsCounter />,
        additionalSelected: ["/sale-request"]
      }
    ]
  },
  {
    id: "3",
    to: "/offers",
    icon: <CollectionsBookmarkOutlined fontSize="large" />,
    text: "Offers",
    canSeeRules: {
      rules: "offers_ui"
    },
    children: [
      {
        id: "3.1",
        text: "BUY"
      },
      {
        id: "3.2",
        to: "/open/",
        icon: <LabelOutlined />,
        text: "Open offers",
        appendInMobile: true,
        append: <OfferCounter type="open" />
      },
      {
        id: "3.3",
        to: "/submitted/",
        icon: <Input />,
        text: "Submitted offers",
        appendInMobile: true,
        append: <OfferCounter type="submitted" />
      }
    ]
  },
  {
    id: "4",
    to: "/pickups",
    icon: <PinDropOutlined fontSize="large" />,
    text: "Pickups",
    canSeeRules: {
      rules: "pickups_ui"
    },
    children: [
      {
        id: "4.1",
        text: "PICKUPS"
      },
      {
        id: "4.2",
        to: "?status=all",
        urlParams: true,
        icon: <List />,
        text: "All Pickups",
        appendInMobile: true,
        append: <PickupsCounter type="all" />
      },
      {
        id: "4.3",
        to: "?status=pending",
        urlParams: true,
        icon: <Input />,
        text: "Pre-shceduled",
        appendInMobile: true,
        append: <PickupsCounter type="pending" />
      },
      {
        id: "4.4",
        to: "?status=scheduled",
        urlParams: true,
        icon: <CheckCircleOutline />,

        text: "Confirmed",
        appendInMobile: true,
        append: <PickupsCounter type="scheduled" />
      }
    ]
  },
  {
    id: "5",
    to: "/orders",
    icon: <Purchases />,
    text: "Purchases",
    canSeeRules: {
      rules: "purchases_ui"
    },
    children: [
      {
        id: "5.1",
        text: "TRANSACTIONS"
      },
      {
        id: "5.2",
        to: "?status=open",
        urlParams: true,
        icon: <Timelapse />,
        text: "Open",
        appendInMobile: true,
        append: <TransactionsCounter type="open" transactionType="purchase" />
      },
      {
        id: "5.3",
        to: "?status=complete",
        urlParams: true,
        icon: <CheckCircleOutline />,
        text: "Completed",
        appendInMobile: true,
        append: <TransactionsCounter type="closed" transactionType="purchase" />
      }
    ]
  },
  {
    id: "6",
    to: "/sales",
    icon: <Sales />,
    text: "Sales",
    canSeeRules: {
      rules: "sales_ui"
    },
    children: [
      {
        id: "6.1",
        text: "TRANSACTIONS"
      },
      {
        id: "6.2",
        to: "?status=all",
        urlParams: true,
        icon: <FormatListBulletedOutlined style={styles.childrenIcon} />,
        text: "All",
        appendInMobile: true,
        append: <TransactionsCounter type="all" transactionType="sale" />
      },
      {
        id: "6.3",
        to: "?status=open",
        urlParams: true,
        icon: <LabelOutlined style={styles.childrenIcon} />,
        text: "Open",
        appendInMobile: true,
        append: <TransactionsCounter type="open" transactionType="sale" />
      },
      {
        id: "6.4",
        to: "?status=complete",
        urlParams: true,
        icon: <CheckCircleOutlineOutlined style={styles.childrenIcon} />,
        text: "Completed",
        appendInMobile: true,
        append: <TransactionsCounter type="closed" transactionType="sale" />
      }
    ]
  },
  {
    id: "7",
    to: "/pricing",
    icon: <LocalOfferOutlined fontSize="large" />,
    text: "Pricing",
    canSeeRules: {
      usesPricing: true,
      rules: "pricing_ui"
    },
    children: [
      {
        id: "7.1",
        text: "PRICES"
      },
      {
        id: "7.2",
        to: "?type=categories",
        exact: true,
        urlParams: true,
        text: "Categories",
        icon: <Star />
      },
      {
        id: "7.3",
        selectedIndes: "?type=prices",
        to: "?type=prices",
        urlParams: true,
        text: "Price History",
        icon: <Restore />
      }
    ]
  },
  {
    id: "8",
    to: "/zones",
    icon: <Layers />,
    text: "Zones",
    canSeeRules: {
      usesRouting: true,
      roles: "buyer"
    }
  },
  {
    id: "9",
    to: "/profile",
    text: "Account",
    icon: "account_circle",
    hideInMenu: true,
    children: [
      {
        id: "9.1",
        text: "ACCOUNT"
      },
      {
        id: "9.2",
        to: "/userInformation",
        exact: true,
        text: "My Profile",
        icon: <AccountCircle />
      },
      {
        id: "9.3",
        selectedIndes: "/companyInformation",
        to: "/companyInformation",
        text: "Company Information",
        icon: <LocationCity />,
        canSeeRules: {
          rules: "account_settings_company_ui"
        }
      },
      {
        id: "9.4",
        selectedIndes: "/paymentInformation",
        to: "/paymentInformation",
        text: "Billing & Payment",
        icon: <Euro />,
        canSeeRules: {
          rules: "account_settings_company_ui"
        }
      },
      {
        id: "9.5",
        selectedIndes: "/documents",
        to: "/documents",
        text: "Documents",
        icon: <InsertDriveFileOutlined />,
        canSeeRules: {
          rules: "account_settings_company_ui"
        }
      },
      {
        id: "9.6",
        selectedIndes: "/transport",
        to: "/transport",
        text: "Transport",
        icon: <LocalShippingOutlined />,
        canSeeRules: {
          rules: "account_settings_company_ui"
        }
      },
      {
        id: "9.7",
        selectedIndes: "/users",
        to: "/users",
        text: "Users",
        icon: <PeopleAltOutlined />,
        canSeeRules: {
          rules: "account_settings_users_ui"
        }
      },
      {
        id: "9.8",
        selectedIndes: "/notifications",
        to: "/notifications",
        text: "Notifications",
        icon: <NotificationsNoneOutlined />
      }
    ]
  },
  {
    id: "10",
    to: "/notifications",
    icon: <NotificationsNoneOutlined fontSize="large" />,
    text: "Notifications",
    classname: "notifications",
    hideInMenu: true,
    showInMobileMenu: true,
    children: [
      {
        id: "10.1",
        to: "/",
        icon: <List />,
        text: "Notifications",
        appendInMobile: true
      }
    ]
  }
];

export const isSelected = (
  location,
  childTo,
  parentTo,
  exact = false,
  additionalSelected,
  urlParams = false
) => {
  if (!location) return false;

  const { pathname, search } = location;

  if (childTo === undefined) return false;

  //We delete the "?" from the search as the status could not be the first param
  childTo = childTo.replace("?", "");

  if (urlParams) {
    return pathname === parentTo && search.includes(childTo);
  }
  if (exact) {
    return (
      pathname === `${parentTo}${childTo}` ||
      isSelected(location.state?.background, childTo, parentTo, exact)
    );
  }
  return (
    !!pathname.match(
      new RegExp(`${childTo.replace(/\?/, "\\?")}[a-zA-Z0-9]*$`)
    ) ||
    (additionalSelected &&
      additionalSelected.some(add => pathname === `${parentTo}${add}`)) ||
    isSelected(location.state?.background, childTo, parentTo, exact)
  );
};

export const SelectMenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  transformOrigin: {
    vertical: "top",
    horizontal: "left"
  },
  getContentAnchorEl: null
};

export const Topics = {
  offerAndContracts: "notificationSettingsTitle_offers",
  transport: "notificationSettingsTitle_transport", // in db : "transport"
  billingAndInvoicing: "notificationSettingsTitle_billing", // in db : "billingAndInvoicing"
  additionalDocuments: "notificationSettingsTitle_additionalDoc",
  accountManagement: "notificationSettingsTitle_accMan",
  product: "notificationSettingsTitle_prod",
  marketing: "notificationSettingsTitle_market"
};

export const TopicsDescription = {
  offerAndContracts: "notificationSettingsDesc_offers",
  transport: "notificationSettingsDesc_transport",
  billingAndInvoicing: "notificationSettingsDesc_billing",
  additionalDocuments: "notificationSettingsDesc_additionalDoc",
  accountManagement: "notificationSettingsDesc_accMan",
  product: "notificationSettingsDesc_prod",
  marketing: "notificationSettingsDesc_market"
};

export const METALOOP_ADMIN_ROLE_ID = 5;

export const NOTIFICATIONS_SUBTOPICS = {
  pickupNotes: {
    title: "notificationSettingsTitle_pickupNotes",
    description: null
  },
  transportDocuments: {
    title: "notificationSettingsTitle_transportDoc",
    description: null
  },
  weightNotes: {
    title: "notificationSettingsTitle_weightNotes",
    description: null
  }
};
