import React from "react";
import { withStyles } from "@material-ui/core/styles";
import WeightRange from "./WeightRange.js";

const styles = theme => ({
  wrapper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  }
});

const WeightRanges = props => {
  const { classes, data, minRange, maxRange, inSlider } = props;
  return (
    <div className={classes.wrapper}>
      {data.map((wr, idx) => {
        let nextWr = idx === data.length - 1 ? Infinity : data[idx + 1];
        return (
          <WeightRange
            selected={minRange <= idx && maxRange >= idx}
            key={wr}
            data={[wr, nextWr]}
            inSlider={inSlider}
          />
        );
      })}
    </div>
  );
};

export default withStyles(styles)(WeightRanges);
