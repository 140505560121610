import gql from "graphql-tag";

export default gql`
  mutation resetUserPassword(
    $userId: String!
    $companyId: String!
    $token: String!
  ) {
    resetUserPassword(userId: $userId, companyId: $companyId, token: $token) {
      message
    }
  }
`;
