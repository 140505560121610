import React from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";

import Logo from "../core/Logo";
import LogoMobile from "../core/LogoMobile";

const styles = theme => ({
  showInMobile: {
    display: "none",
    "@media (max-width: 400px)": {
      display: "flex"
    }
  },
  hideInMobile: {
    "@media (max-width: 400px)": {
      display: "none"
    }
  }
});

class LoginLogo extends React.Component {
  render() {
    const { classes, options } = this.props;
    return (
      <>
        <div className={classes.hideInMobile}>
          <Logo options={options} />
        </div>
        <div className={classes.showInMobile}>
          <LogoMobile />
        </div>
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(LoginLogo));
