import React, { useContext } from "react";
import { useQuery } from "@apollo/react-hooks";

import UserContext from "../../providers/UserProvider";
import { GET_FULL_PRODUCT_DATA } from "../../queries/Products";
import CompanyLocationProvider from "../../providers/CompanyLocationProvider";

export const ProductsCounter = () => {
  const { user, selectedCompany } = useContext(UserContext);
  const { showLocations } = useContext(CompanyLocationProvider);

  const { error, loading, data } = useQuery(GET_FULL_PRODUCT_DATA, {
    variables: {
      token: user.token,
      companyId: selectedCompany,
      location: showLocations
    },
    fetchPolicy: "cache-and-network"
  });

  if (loading || error) return null;

  const { FullProductData } = data;
  const productData = [
    ...FullProductData.contracted,
    ...FullProductData.priceReceived,
    ...FullProductData.priceRequested,
    ...FullProductData.noSaleInProgress
  ];

  return <span>&nbsp;({productData.length})</span>;
};
