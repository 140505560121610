import React from "react";
import classnames from "classnames";
import { IconButton, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddBoxIcon from "@material-ui/icons/AddBoxRounded";
import WeightRangeHeader from "../slider/WeightRange";
import { makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    flexDirection: "column",
    flexWrap: "none",
    position: "relative",
    height: "100%"
  },
  priceBox: {
    minWidth: "84px",
    width: "100%",
    borderRadius: "8px",
    border: "1px solid #000000",
    overflow: "hidden",
    textAlign: "right",
    fontSize: "14px",
    fontWeight: "bold",
    margin: 0
  },
  discountBox: {
    border: "1px solid #27AE60",
    color: "#27AE60"
  },
  pricePerTone: {
    border: "1px solid #F2994A",
    color: "#F2994A"
  },
  blue: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "0.001em",
    color: "#2F80ED",
    height: "12px",
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      paddingLeft: "15px",
      paddingRight: "0px"
    }
  },
  green: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "0.001em",
    color: "#27AE60",
    height: "12px",
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      paddingLeft: "15px",
      paddingRight: "0px"
    }
  },
  orange: {
    color: theme.palette.primary.main
  },
  orange_inside_box: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "0.001em",
    color: "#F2994A",
    height: "12px",
    [theme.breakpoints.up("md")]: {
      textAlign: "center",
      paddingLeft: "15px",
      paddingRight: "0px"
    }
  },
  disabled: {
    pointerEvents: "none",
    opacity: "0.5"
  },
  referencePrice: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "24px",
    textAlign: "center",
    letterSpacing: "0.001em",
    color: "#2F80ED"
  },
  marginPrice: {
    display: "flex",
    marginTop: "10px",
    justifyContent: "center",
    fontSize: "14px",
    color: "#4c6583",
    fontWeight: "bold",
    flex: 1
  },
  weightRange: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "10px",
    flex: 1,
    "& div": {
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "24px",
      textAlign: "center",
      letterSpacing: "0.001em",
      color: "rgba(0, 0, 0, 0.6)"
    }
  },
  iconButton: {
    position: "absolute",
    top: "-20px",
    right: "-15px",
    pointerEvents: "all"
  },
  spanDiscount: {
    display: "inline-block",
    flex: 1,
    marginBottom: "6px"
  },
  spanPrice: {
    display: "inline-block",
    flex: 1,
    marginBottom: "8px"
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flex: "6",
    border: "1px solid transparent",
    borderRadius: "5px",
    padding: "12px",
    width: "80%",
    minWidth: "112px",
    height: "168px"
  },
  fixed: {
    border: "1px solid #9B51E0"
  },
  acceptedButton: {
    pointerEvents: "auto",
    marginTop: "-10px",
    marginBottom: "6px"
  }
}));

const WeightRange = ({
  range,
  discount,
  pricePerTone,
  indexPrice,
  isEditing,
  onWeightCategoryChange,
  justPrices,
  onChange,
  accepted,
  index,
  isTutorial
}) => {
  const classes = useStyles();

  const toggleRange = (range, ev) => {
    onWeightCategoryChange(range);
  };

  return (
    <div
      className={classnames([
        classes.container,
        !isTutorial && !accepted ? classes.disabled : null
      ])}
    >
      {isEditing ? (
        <IconButton
          className={classes.iconButton}
          mini="true"
          onClick={onWeightCategoryChange}
        >
          {accepted ? (
            <CloseIcon fontSize="small" />
          ) : (
            <AddBoxIcon fontSize="small" />
          )}
        </IconButton>
      ) : null}
      <div className={classes.acceptedButton}>
        <Switch
          defaultChecked={accepted}
          color="primary"
          onClick={ev => toggleRange(range, ev)}
        />
      </div>
      <div
        className={
          classes.columnContainer + (index < 3 ? " " + classes.fixed : "")
        }
      >
        <div className={classnames(classes.weightRange)}>
          <WeightRangeHeader data={range} />
        </div>
        {justPrices ? null : (
          <>
            <span
              className={classes.spanDiscount}
              id={range[1] === Infinity ? "tutorial-discount-price-1" : null}
            >
              <TextField
                value={discount}
                type="number"
                variant="outlined"
                className={classnames(classes.priceBox, classes.discountBox)}
                margin="dense"
                inputProps={{ className: classes.green }}
                onChange={ev => onChange(ev.target.value, "discount")}
              />
            </span>
          </>
        )}
        <span
          className={classes.spanPrice}
          id={range[1] === Infinity ? "tutorial-price-price-1" : null}
        >
          <TextField
            value={pricePerTone}
            variant="outlined"
            type="number"
            margin="dense"
            className={classnames(classes.priceBox, classes.pricePerTone)}
            inputProps={{ className: classes.orange_inside_box }}
            onChange={ev => onChange(ev.target.value, "price")}
          />
        </span>

        {justPrices ? null : (
          <span
            id={range[1] === Infinity ? "tutorial-s24index-price-1" : null}
            className={classes.referencePrice}
          >
            {indexPrice}
          </span>
        )}
      </div>
      <div />
    </div>
  );
};

export default WeightRange;
