import React from "react";

import { makeStyles, Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(theme => ({
  compositionDisplayContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: "16px"
  },
  twoColumns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between"
  },
  titleAndValueContainer: {
    width: "100%"
  },
  componentFont: {
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  sectionValue: {
    color: "rgba(0, 0, 0, 0.87)"
  },
  componentTitle: {
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)"
  }
}));

const TitleAndValue = ({ title, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.titleAndValueContainer}>
      <Typography variant="h4" className={classes.componentFont}>
        {title}
      </Typography>

      <Typography
        variant="h4"
        className={`${classes.componentFont} ${classes.sectionValue}`}
      >
        {value}
      </Typography>
    </div>
  );
};
const CompositionDisplay = ({ baseMetal, alloy, form, surface, alias }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <div className={classes.compositionDisplayContainer}>
      <Typography
        variant="h4"
        className={`${classes.componentFont} ${classes.componentTitle}`}
      >
        {alias}
      </Typography>

      <div className={classes.twoColumns}>
        <TitleAndValue title={t("Products_BaseMetal")} value={baseMetal} />
        <TitleAndValue title={t("Products_Alloy")} value={alloy} />
      </div>
      <div className={classes.twoColumns}>
        <TitleAndValue title={t("Products_ProductFrom")} value={form} />
        <TitleAndValue title={t("Products_ProductSurface")} value={surface} />
      </div>
    </div>
  );
};
export default CompositionDisplay;
