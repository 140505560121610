import React from "react";
import { IconButton, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export default ({ buttons = [] }) => {
  const { t } = useTranslation();
  const useStyle = makeStyles(theme => ({
    buttonContainer: {
      display: "flex",
      marginBottom: "4px"
    },
    buttonWrap: {
      display: "flex",
      width: "480px",
      alignItems: "center",
      justifyContent: "end",
      marginRight: "20px",
      marginBottom: "12px"
    },
    AddCircleRounded: {
      backgroundColor: "red"
    },
    buttonLabel: {
      fontStyle: "normal",

      fontFamily: "Roboto",

      fontWeight: "400",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      color: "#151124",
      marginRight: "6px"
    },
    buttonIconContainer: {
      paddingRight: "0px"
    },
    buttonIcon: {
      color: "#52F597"
    },
    hover_green: {
      "&:hover": {
        backgroundColor: "rgba(82, 245, 151, 0.1)"
      }
    },
    wrapper: {}
  }));

  const classes = useStyle();

  return (
    <div className={classes.wrapper}>
      {buttons.map((button, index) => (
        <div key={index} className={classes.buttonContainer}>
          <div className={classes.buttonWrap}>
            <span className={classes.buttonLabel}>{t(button.text)} </span>
            <div className={classes.buttonIconContainer}>
              <IconButton
                onClick={button.onClick}
                aria-controls="menu"
                classes={{
                  root: classes.hover_green
                }}
              >
                <img
                  src={button.icon}
                  alt={button.text}
                  className={classes.buttonIcon}
                />
              </IconButton>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
