import logo from "../../assets/logo.svg";
import React from "react";

const Logo = () => (
  <img
    src={logo}
    alt="Logo"
    style={{
      width: "150.54px"
    }}
  />
);

export default Logo;
