import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useStackableNotifications } from "./StackableNotifications";

export const useRedirection = () => {
  const history = useHistory();
  const { pushNotification } = useStackableNotifications();
  const { t } = useTranslation();

  // If no label then just redirect
  // If no url then just show notification
  const notificationAndRedirection = ({
    label,
    fixed = false,
    url,
    color = "success",
    state
  }) => {
    if (label) {
      pushNotification(t(label), {
        color,
        fixed
      });
    }
    if (url) {
      let objHistory = url;
      if (state) {
        objHistory = {
          pathname: url,
          state: state
        };
      }

      history.push(objHistory);
    }
  };

  return notificationAndRedirection;
};
