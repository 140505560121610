import React from "react";
import gql from "graphql-tag";
import PropTypes from "prop-types";
import { graphql } from "react-apollo";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from "react-i18next";
import { CheckErrors, reportError } from "../../ErrorTracking";
import { forgotPassword } from "../../mutations/forgotPassword";

import ForgotForm from "../../components/forms/ForgotForm";
import Title from "../../components/login/Title";
import SubTitle from "../../components/login/SubTitle";
import OrSeparator from "../../components/login/OrSeparator";
import LongMagicLink from "../../components/login/button/LongMagicLink";

import WaitingForEmail from "../../components/forms/WaitingForEmail";
const styles = theme => ({
  subTitle: {
    marginTop: "8px",
    marginBottom: "16px",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    "@media (max-width: 400px)": {
      fontSize: "14px",
      lineHeight: "20px",
      marginTop: "10px",
      letterSpacing: "0.25px",
      width: "100%",
      padding: "0 16px"
    }
  },
  modifyTitle: {
    marginTop: "20px",
    marginBottom: "16px",
    fontSize: "48px",
    lineHeight: "56px",
    "@media (max-width: 400px)": {
      marginTop: "16px",
      marginBottom: "12px",
      fontSize: "28px",
      lineHeight: "36px"
    }
  },
  modifySubTitle: {
    marginBottom: "32px",
    width: "100%",
    maxWidth: "600px",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)",
    "@media (max-width: 400px)": {
      marginBottom: "24px",
      fontSize: "14px",
      lineHeight: "20px",
      letterSpacing: "0.25px",
      width: "100%",
      padding: "0 16px"
    }
  }
});

class ForgotContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      forgotErrorMessage: "",
      emailSent: false,
      email: ""
    };
    this.onForgotSubmit = this.onForgotSubmit.bind(this);
  }

  onForgotSubmit({ email }) {
    const { mutate, t } = this.props;
    this.setState({ email });
    mutate({
      variables: {
        input: {
          email: email.toLowerCase(),
          domain: window.location.hostname.split(".").slice(-1)[0],
          link: `${window.location.origin}`
        }
      }
    })
      .then(({ data: { forgotPassword } }) => {
        const { message } = forgotPassword;
        if (message) {
          return this.setState({ forgotErrorMessage: t(message) });
        }

        return this.setState({
          emailSent: true
        });
      })
      .catch(error => {
        this.setState({ forgotErrorMessage: t(error.message) });
        reportError(error);
      });
  }

  render() {
    const { forgotErrorMessage } = this.state;
    const { classes } = this.props;
    return (
      <CheckErrors>
        {!this.state.emailSent ? (
          <>
            <Title text={"Reset Password"} extraClasses={classes.modifyTitle} />
            <SubTitle
              extraClasses={classes.modifySubTitle}
              text={
                "Enter your email to receive a link where you can create a new password"
              }
            />
            <ForgotForm
              onSubmit={this.onForgotSubmit}
              forgotErrorMessage={forgotErrorMessage}
            />
            <OrSeparator />

            <SubTitle
              text={"Login without a password"}
              extraClasses={classes.subTitle}
            />
            <LongMagicLink />
          </>
        ) : (
          <WaitingForEmail
            email={this.state.email}
            sendMail={() => {}}
            redirect={false}
          />
        )}
      </CheckErrors>
    );
  }
}

ForgotContainer.defaultProps = {
  classes: {}
};

ForgotContainer.propTypes = {
  mutate: PropTypes.func.isRequired,
  classes: PropTypes.object
};

export default graphql(forgotPassword)(
  withTranslation()(withStyles(styles)(ForgotContainer))
);
