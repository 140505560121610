import { useCallback } from "react";

export const useRequestPickupHandler = (
  item,
  history,
  location,
  setHasExistingPickups,
  confirmSale,
  isDemoEnv = false
) => {
  const handleOnCompleted = useCallback(
    data => {
      if (item.statusId === 1) {
        if (data.RequestPickUpInfo.pickupStatusCondition && !isDemoEnv) {
          setHasExistingPickups(true);
        } else {
          history.push(`/pickups?request=${item.conditionId}`, {
            background: location
          });
        }
      } else {
        if (item.statusId === 3) {
          confirmSale(item, "button");
        } else {
          history.push(`/products?sale-request=${item.productId}`, {});
        }
      }
    },
    [item]
  );

  const handleOnError = useCallback(
    error => {
      console.log(error);
    },
    [item]
  );

  return { handleOnCompleted, handleOnError };
};
