import React, { useCallback, useMemo, useState, useEffect } from "react";
import { makeStyles, useMediaQuery, useTheme } from "@material-ui/core";
import CustomDialog from "../../general/Dialog";
import { STEPS_NO_UPLOAD, StepTitleComponent } from "./uploadDialog/steps";
import classNames from "classnames";
import {
  MATERIAL_IMAGES_UPLOAD_TYPE,
  WEIGHTNOTE_UPLOAD_TYPE
} from "./uploadDialog/steps";
import { useNotifications } from "../../../hooks/Notifications";

import { useTranslation } from "react-i18next";
const useStyles = makeStyles(theme => ({
  root: {
    "& > .dialog-title": {
      paddingTop: "48px",
      [theme.breakpoints.down("sm")]: {
        paddingTop: "0"
      },
      "& > .MuiTypography-h2": {
        [theme.breakpoints.down("sm")]: {
          position: "absolute",
          top: 0,
          paddingTop: "10px",
          paddingLeft: "24px"
        },
        display: "flex",
        gap: "10px"
      }
    },
    "&  > .MuiDialogContent-root": {
      paddingTop: "12px",

      [theme.breakpoints.down("sm")]: {
        paddingTop: "0"
      }
    }
  },
  hideTitle: {
    "& > .dialog-title": { display: "none" }
  }
}));

const UploadDialogWithoutSend = ({
  orderType,
  onClose,
  initialType,
  accept = "image/*, application/pdf,",
  seller = false,
  onSetFiles,
  disableInvoiceUpload = false
}) => {
  const STEPS = STEPS_NO_UPLOAD;
  const [step, setStep] = useState(0);
  const [files, setFiles] = useState([]);
  const [type, setType] = useState(null);
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (initialType !== undefined) {
      setStep(1);
      setType(initialType);
    }
  }, []);

  const setPrevStepAction = useCallback(() => {
    if (step === 0) return;
    setStep(step - 1);
  }, [step, setStep]);

  const setNextStepAction = useCallback(() => {
    if (step === STEPS.length - 1) return;

    setStep(step + 1);
  }, [step, setStep]);

  const {
    title,
    titleIcon,
    hideTitle = false,
    disableClose,
    component: Component
  } = useMemo(() => STEPS[step], [step]);

  const fullTitle = StepTitleComponent({
    fullScreen,
    title,
    titleIcon,
    type
  });

  const { pushNotification } = useNotifications();
  const closingModal = () => {
    if (step < 3) {
      pushNotification(t("Snackbar_Upload_NoFilesUploaded"), {
        color: "secondary",
        fixed: true
      });
    } else {
      let message = "";
      if (files.length > 1) {
        if (type === WEIGHTNOTE_UPLOAD_TYPE) {
          message = "Snackbar_Upload_MultipleWeightnotesNoComment";
        } else if (type === MATERIAL_IMAGES_UPLOAD_TYPE) {
          message = "Snackbar_Upload_MultipleImages";
        } else {
          message = "Snackbar_Upload_MultipleDocuments";
        }
      } else {
        if (type === WEIGHTNOTE_UPLOAD_TYPE) {
          message = "Snackbar_Upload_SingleWeightnoteNoComment";
        } else if (type === MATERIAL_IMAGES_UPLOAD_TYPE) {
          message = "Snackbar_Upload_SingleImage";
        } else {
          message = "Snackbar_Upload_SingleDocument";
        }
      }

      pushNotification(t(message), { color: "success", fixed: true });
    }
    onClose();
  };
  return (
    <CustomDialog
      open={true}
      title={fullTitle}
      onClose={closingModal}
      disableClose={disableClose}
      mode="advanced"
      fullScreen={fullScreen}
      maxWidth="xl"
      scroll="paper"
      PaperProps={{
        className: classNames(classes.root, hideTitle && classes.hideTitle)
      }}
    >
      <Component
        type={type}
        files={files}
        title={fullTitle}
        setType={setType}
        onClose={closingModal}
        setFiles={setFiles}
        orderType={orderType}
        fullScreen={fullScreen}
        setPrevStepAction={setPrevStepAction}
        setNextStepAction={setNextStepAction}
        seller={seller}
        accept={accept}
        onSetFiles={onSetFiles}
        disableInvoiceUpload={disableInvoiceUpload}
      />
    </CustomDialog>
  );
};

export default UploadDialogWithoutSend;
