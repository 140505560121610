import React, { Fragment } from "react";
import gql from "graphql-tag";
import { withStyles } from "@material-ui/core/styles";
import { Query } from "react-apollo";
import Loader from "../../components/core/shared/Loader";
import { GET_SELECTED_COMPANY } from "../../apollo-reducers";
import { CheckErrors } from "../../ErrorTracking";
import InformationContent from "../../components/account/information/InformationContent";

const styles = theme => ({
  wrapper: {
    padding: 32,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 16
    }
  }
});

class AccountContainer extends React.Component {
  render() {
    const { classes, user, userCompanies } = this.props;

    return (
      <Query query={GET_SELECTED_COMPANY}>
        {({ loading, error, data: selectedCompanyData }) => {
          if (error) return error.message;
          return (
            <Fragment>
              {loading ? (
                <Loader />
              ) : (
                <div className={classes.wrapper}>
                  <CheckErrors>
                    <InformationContent
                      token={user.token}
                      company={selectedCompanyData.selectedCompany}
                      userCompanies={userCompanies}
                    />
                  </CheckErrors>
                </div>
              )}
            </Fragment>
          );
        }}
      </Query>
    );
  }
}

export default withStyles(styles)(AccountContainer);
