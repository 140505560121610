import { makeStyles } from "@material-ui/core";
import CustomTable from "../../../components/general/Table";
import { useTranslation } from "react-i18next";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  tableRoot: {
    marginBottom: "20px",
    maxWidth: "600px",
    maxHeight: "262px",
    overflowY: "auto",
    width: "100%",
    "& .MuiTableContainer-root": {
      border: "1px solid rgba(0, 0, 0, 0.12)",
      boxShadow: "none",
      backgroundColor: "#FAFAFA",
      borderRadius: "4px",
      [theme.breakpoints.down("sm")]: {
        border: "none"
      }
    },
    "& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root": {
      borderBottom: "none"
    },
    [theme.breakpoints.down("sm")]: {
      order: 2,
      padding: "0",
      marginBottom: "0px",
      marginLeft: "-16px",
      marginRight: "-16px",
      width: "calc(100% + 32px)",
      maxWidth: "none",
      "& .MuiTableContainer-root": {
        borderRadius: "0"
      },
      "& .MuiTable-root": {
        minWidth: "max-content"
      },
      "& .MuiTableContainer-root": {
        backgroundColor: "#FFF"
      }
    }
  }
}));

export const ScheduledPickupsTable = ({ scheduledPickups }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const columns = [
    {
      width: "16px",
      spacer: true
    },
    {
      field: "scheduledDate",
      headerName: t("PickupModal_SaleConfirmed_PrescheduledDate"),
      renderCell({ record }) {
        return <>{moment(record.scheduledDate).format("DD.MM.YYYY")}</>;
      }
    },
    {
      field: "quantity",
      headerName: t("Quantity (mt)"),
      type: "number",
      valueFormatter: ({ value }) => value / 1000
    },
    {
      field: "transactionID",
      headerName: t("Material name")
    },
    {
      width: "16px",
      spacer: true
    }
  ];

  return (
    <CustomTable
      className={classes.tableRoot}
      columns={columns}
      page={1}
      rows={scheduledPickups}
      pagination={false}
    />
  );
};
