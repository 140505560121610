import React from "react";
import { withTranslation } from "react-i18next";

import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const styles = theme => ({
  error: {
    color: "#E11900",
    display: "block",
    fontWeight: "normal",
    fontSize: "12px",
    marginTop: "12px",
    fontFamily: "Roboto",
    fontWeight: "400",
    lineHeight: "16px",
    letterSpacing: "0.4px"
  }
});
class Error extends React.Component {
  render() {
    const {
      t,
      classes,
      meta: { touched, error }
    } = this.props;

    return (
      <>
        {touched ? (
          <Typography variant="caption" className={classes.error} gutterBottom>
            {t(error)}
          </Typography>
        ) : null}
      </>
    );
  }
}

export default withStyles(styles)(withTranslation()(Error));
