import { makeStyles } from "@material-ui/core/styles";
import onboarding_background_mobile from "../../assets/images/onboarding_background_mobile.png";
import Logo from "../../components/core/Logo";
import Footer from "./Footer";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  topDivMobile: {
    height: "240px",
    width: "100%",
    background: `linear-gradient(90deg, rgba(21, 17, 36, 0.80) 20%, rgba(21, 17, 36, 0.79) 85%), url(${onboarding_background_mobile})`,
    backgroundSize: "cover",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  downDivMobile: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%"
  },
  rightColumnHeader: {
    fontSize: "64px",
    fontStyle: "normal",
    fontWeight: "500",
    lineHeight: "normal",
    letterSpacing: "-0.5px",
    color: "white",
    marginBottom: "12px"
  },
  rightColumnSubHeader: {
    width: "420px",
    fontSize: "20px",
    color: "white",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "28px"
  },
  [theme.breakpoints.down("sm")]: {
    rightColumnHeader: {
      fontSize: "28px"
    },
    rightColumnSubHeader: {
      fontSize: "14px",
      lineHeight: "20px",
      fontStyle: "normal",
      fontWeight: "400",
      letterSpacing: "0.25px",
      width: "345px",
      textAlign: "center"
    },
    fullScroll: {
      overflowY: "auto",
      maxHeight: "calc(100vh - 70px)"
    },
    fullFullScroll: {
      overflowY: "auto",
      maxHeight: "100vh"
    }
  }
}));

const MobileTwoRowsModule = ({
  headerText,
  subHeaderText,
  children,
  hideFooter = false,
  fullScroll = false,
  Buttons,
  logoOptions = {}
}) => {
  const classes = useStyles();
  return (
    <>
      <div
        className={
          fullScroll
            ? Buttons
              ? classes.fullScroll
              : classes.fullFullScroll
            : ""
        }
      >
        <div className={classes.topDivMobile}>
          <Typography className={classes.rightColumnHeader}>
            {headerText}
          </Typography>
          <Typography className={classes.rightColumnSubHeader}>
            {subHeaderText}
          </Typography>
        </div>
        <div className={classes.downDivMobile}>
          {" "}
          <div style={{ marginTop: "60px" }}>
            <Logo options={logoOptions || { height: "12px", width: "150px" }} />
          </div>
          {children}
          {!hideFooter && <Footer />}
        </div>
      </div>
      {Buttons}
    </>
  );
};

export default MobileTwoRowsModule;
