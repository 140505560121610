import React from "react";

import { Grid, makeStyles } from "@material-ui/core";

import { differenceInCalendarMonths } from "date-fns";

import Month from "./Month";

const Menu = props => {
  const useStyles = makeStyles(theme => ({
    root: {
      flexWrap: "nowrap",
      [theme.breakpoints.down("sm")]: {
        flexWrap: "wrap",

        "& .MuiInputAdornment-root": {
          width: "100%",
          padding: "10px"
        }
      }
    },
    weekDaysContainer: {
      marginTop: 10,
      paddingLeft: 30,
      paddingRight: 30
    },
    daysContainer: {
      paddingLeft: 15,
      paddingRight: 15,
      marginTop: 15,
      marginBottom: 20
    }
  }));

  const classes = useStyles();

  const {
    dateRange,
    minDate,
    maxDate,
    firstMonth,
    secondMonth,
    helpers,
    handlers,
    markers,
    disableWeekends,
    singleDate
  } = props;
  const canNavigateCloser =
    differenceInCalendarMonths(secondMonth, firstMonth) >= singleDate ? 0 : 2;
  const canNavigateBack = differenceInCalendarMonths(minDate, firstMonth);
  const canNavigateForward = differenceInCalendarMonths(maxDate, secondMonth);

  const commonProps = {
    dateRange,
    minDate,
    maxDate,
    helpers,
    handlers,
    disableWeekends
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      className={classes.root}
    >
      <Month
        {...commonProps}
        value={firstMonth}
        navState={[canNavigateBack, canNavigateCloser]}
        marker={markers.first}
      />

      {!singleDate && (
        <Month
          {...commonProps}
          value={secondMonth}
          navState={[canNavigateCloser, canNavigateForward]}
          marker={markers.second}
        />
      )}
    </Grid>
  );
};

export default Menu;
