import gql from "graphql-tag";

export default gql`
  mutation finishAndAddComment(
    $input: FinishAvisoInput
    $token: String!
    $selectedCompany: String!
    $isAWeightNote: Boolean
  ) {
    finishAndAddComment(
      input: $input
      token: $token
      selectedCompany: $selectedCompany
      isAWeightNote: $isAWeightNote
    ) {
      message
    }
  }
`;
