import React from "react";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { useApolloClient } from "react-apollo";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import ModalWrapper from "../../transactions/layout/Modals/ModalClass";
import getCompaniesForAdmin from "../../../queries/GetCompaniesForAdmin";
import AutoCompleteTextFieldAsync from "../AutoCompleteTextFieldAsync";
import classNames from "classnames";
import { useHistory } from "react-router";
import { clearStorage } from "../../../actions/DataPersistence";

const useStyles = makeStyles(theme => ({
  orDiv: {
    textAlign: "center",
    margin: "20px 0"
  },
  selectWrapper: {
    padding: "48px 24px 52px",
    [theme.breakpoints.down("sm")]: {
      padding: "32px 16px"
    }
  },
  locationIcon: {
    width: "200px",
    height: "200px",
    backgroundColor: "rgba(238,165,44,0.17)",
    borderRadius: "400px",
    display: "flex",
    margin: "auto",
    marginTop: "40px",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
      height: "150px"
    }
  },
  icon: {
    width: "130px",
    height: "130px"
  },
  textInput: {
    border: `3px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    // margin: "30px 0 60px 0",
    width: "100%",
    borderRadius: "10px",
    "& svg": {
      fill: theme.palette.primary.main
    }
  },
  noBorder: {
    borderWidth: "0"
  },
  locationTitle: {
    margin: "0 0 8px",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.87)",
    [theme.breakpoints.down("sm")]: {
      letterSpacing: "0.15px"
    }
  },
  locationCaption: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)",
    margin: "0 0 20px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px"
    }
  },
  hyperlink: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    color: "blue",
    textDecoration: "underline",
    margin: "0 0 20px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
      lineHeight: "20px"
    }
  },
  modalWrapper: {
    border: "none",
    boxShadow:
      "0px 15px 12px rgba(0, 0, 0, 0.22), 0px 19px 38px rgba(0, 0, 0, 0.3)",
    borderRadius: "0",
    [theme.breakpoints.down("sm")]: {
      height: "calc(100% - 56px)",
      left: "auto",
      top: "auto",
      bottom: "0",
      transform: "none"
    }
  },
  modalAdmin: {
    height: "500px",
    [theme.breakpoints.down("sm")]: {
      height: "100vh",
      maxHeight: "100vh",
      width: "100%"
    }
  }
}));
export default ({ selectedCompany, userData, user }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles(theme);
  const client = useApolloClient();
  const history = useHistory();

  const { UserCompanies, RawUserData } = userData;

  const isAdmin = RawUserData && RawUserData.isAdmin;

  const getCompanies = async input => {
    const { data } = await client.query({
      fetchPolicy: "network-only",
      query: getCompaniesForAdmin,
      variables: {
        searchTerm: input,
        token: user.token
      }
    });
    if (data?.getCompaniesForAdmin)
      return data.getCompaniesForAdmin.map(company => ({
        value: company._id,
        label: company.name
      }));

    return [];
  };

  const selectCompany = e => {
    client.writeData({
      data: { selectedCompany: e.target.value }
    });
    if (e.isAdmin) {
      history.replace({
        pathname: "/"
      });
    }
  };

  const hasNoCompanies = UserCompanies.length === 0 && !isAdmin;

  const handleOnLogout = () => {
    clearStorage();
    window.location.reload();
  };

  return (
    <ModalWrapper
      isOpen={selectedCompany === "all" || selectedCompany === null}
      noCloseButton
      className={classNames([
        classes.modalWrapper,
        isAdmin ? classes.modalAdmin : ""
      ])}
    >
      <div className={classes.selectWrapper}>
        {hasNoCompanies && (
          <>
            <Typography variant="h3" className={classes.locationTitle}>
              {t("CompanyModal_noCompanies_topMessage")}
            </Typography>
            <Typography
              className={classes.locationCaption}
              style={{ display: "inline" }}
            >
              {t("CompanyModal_noCompanies_header")}
              <Typography
                className={classes.hyperlink}
                style={{ display: "inline" }}
                onClick={handleOnLogout}
              >
                {t("CompanyModal_noCompanies_subHeader")}
              </Typography>
            </Typography>
          </>
        )}
        {!hasNoCompanies && (
          <>
            <Typography variant="h3" className={classes.locationTitle}>
              {t("Standort - Auswahl")}
            </Typography>
            <Typography className={classes.locationCaption}>
              {t("Wählen Sie ein Standort Ihrer Wahl")}
              {". "}
              {t("Sie können jederzeit zwischen Ihren Standorten wechseln")}
              {"."}
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              color="primary"
              select
              value={selectedCompany}
              onChange={selectCompany}
            >
              <MenuItem key={"all"} value="all">
                {t("Standort auswählen")}
              </MenuItem>
              {UserCompanies.map(uc => (
                <MenuItem key={uc._id} value={uc._id}>
                  {uc.name}
                </MenuItem>
              ))}
            </TextField>
            {isAdmin ? (
              <>
                <div className={classes.orDiv}>OR</div>
                <AutoCompleteTextFieldAsync
                  loadOptions={getCompanies}
                  onChange={value =>
                    selectCompany({
                      target: { value: value.value },
                      isAdmin: true
                    })
                  }
                  label={t("CompanyModal_searchCompany")}
                />
              </>
            ) : null}
          </>
        )}
      </div>
    </ModalWrapper>
  );
};
