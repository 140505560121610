import React from "react";
import {
  makeStyles,
  Typography,
  useMediaQuery,
  useTheme
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  productDisplayContainer: {
    width: "100%",
    display: "flex",
    gap: "16px",
    flexDirection: "row",
    minHeight: "148px",
    background: "#F9F9F9",
    border: "1px solid #E0E0E0",
    borderRadius: "4px",
    padding: "19px",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      minHeight: "272px"
    }
  },
  imageContainer: {
    width: "100px",
    height: "148px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
    flexShrink: 0,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100px"
    }
  },
  cardText: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    letterSpacing: "0.25px",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  title: {
    fontSize: "20px",
    lineHeight: "24px",
    color: "rgba(0, 0, 0, 0.87)",
    marginBottom: "7px"
  },
  description: {
    fontWeight: "400"
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
    width: "100%"
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover"
  }
}));

const ProductDisplay = ({ title, image, subtitle, description, children }) => {
  const classes = useStyles();

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  // Determine image size based on screen size
  const imageResizeWdith = isSmallScreen ? 450 : 100;
  const imageResizeHeigh = isSmallScreen ? 100 : 148;

  const imageUrl = image
    ? `https://images.scrap24.com/${image.productName}-${imageResizeWdith}x${imageResizeHeigh}-${image.imageId}`
    : `https://images.scrap24.com/-${imageResizeWdith}x${imageResizeHeigh}-2v3Rm2xiAycWyiLqj.png}`;

  return (
    <div className={classes.productDisplayContainer}>
      <div className={classes.imageContainer}>
        <img src={imageUrl} alt={title} className={classes.image} />
      </div>
      <div className={classes.textContainer}>
        <Typography
          variant="h4"
          className={`${classes.cardText} ${classes.title}`}
        >
          {title}
        </Typography>

        {children ? (
          children
        ) : (
          <>
            <Typography variant="h5" className={classes.cardText}>
              {subtitle}
            </Typography>
            <Typography
              variant="h6"
              className={`${classes.cardText} ${classes.description}`}
            >
              {description}
            </Typography>
          </>
        )}
      </div>
    </div>
  );
};

export default ProductDisplay;
