import React from "react";
import { Link } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import useStandardLocalization from "../../../hooks/useStandardLocalization";
const NextPickUpContent = ({ item }) => {
  const history = useHistory();
  const { parseDate } = useStandardLocalization();

  return item.date_of_next_pickup ? (
    <Link
      component="button"
      onClick={() => {
        history.push(
          `/pickups?status=all&transactionId=${item.id_of_next_pickup}`
        );
      }}
      style={{ color: "rgba(0, 0, 0, 0.87)", textDecoration: "underline" }}
    >
      {parseDate(item.date_of_next_pickup)}
    </Link>
  ) : (
    "-"
  );
};

export default NextPickUpContent;
