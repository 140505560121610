import { useContext } from "react";
import UserProvider from "../providers/UserProvider";

const useCompany = () => {
  const { userData, selectedCompany } = useContext(UserProvider);

  const { UserCompanies } = userData || [];

  return {
    ...(UserCompanies?.find(item => item._id === selectedCompany) || {}),
    selectedCompany
  };
};

export default useCompany;
