import React from "react";
import { useInbox } from "@trycourier/react-hooks";
import styled from "styled-components";
import deepExtend from "deep-extend";

import MarkAllRead from "./actions/MarkAllRead";
import CloseInbox from "./actions/Close";
import { useTranslation } from "react-i18next";

const Container = styled.div(({ theme }) => {
  const primaryColor = theme.brand?.colors?.primary || "#9121C2";

  return deepExtend(
    {
      padding: "9px 6px",
      userSelect: "none",
      display: "flex",
      position: "relative",
      zIndex: 1,
      justifyContent: "space-between",
      alignItems: "center",
      fontSize: 16,
      fontWeight: 700,
      height: "42px",
      color: "rgb(36, 50, 75)",
      backgroundColor: "#F2F6F9",
      borderBottom: "1px solid rgb(222, 232, 240)",

      borderTopLeftRadius: theme?.brand?.inapp?.borderRadius ?? "12px",
      borderTopRightRadius: theme?.brand?.inapp?.borderRadius ?? "12px",

      ".message-count": {
        fontSize: 14,
        fontWeight: 400,
        margin: "0 3px",
        background: primaryColor,
        color: "white",
        borderRadius: "17px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 18,
        padding: "0 6px",
        minWidth: 28
      },

      ".actions": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
      }
    },
    theme?.header
  );
});

const Title = styled.div(({ theme }) => theme?.message?.title);

const HeadingContainer = styled.div(({ theme }) => {
  const primaryColor = theme.brand?.colors?.primary;

  const styles = {
    display: "flex",
    alignItems: "center",
    borderRadius: 6,
    paddingLeft: 6,
    transition: "background 200ms ease-in-out",

    "svg path": {
      fill: primaryColor
    }
  };

  return styles;
});

const TitleWrapper = ({ title, unreadMessageCount }) => (
  <>
    <Title role="heading">{title}</Title>
    {unreadMessageCount ? (
      <span
        aria-label={`unread message count ${unreadMessageCount}`}
        className="message-count"
      >
        {unreadMessageCount > 99 ? "99+" : unreadMessageCount}
      </span>
    ) : undefined}
  </>
);

const Header = ({
  labels,
  markAllAsRead,
  messages = [],
  title,
  unreadMessageCount
}) => {
  const { toggleInbox } = useInbox();
  const { t } = useTranslation();

  const handleCloseInbox = event => {
    event.preventDefault();
    toggleInbox(false);
  };

  return (
    <Container data-testid="header">
      <HeadingContainer tabIndex={0}>
        <TitleWrapper
          title={t(title)}
          unreadMessageCount={unreadMessageCount}
        />
      </HeadingContainer>
      <div className="actions">
        {messages.length > 0 && unreadMessageCount ? (
          <MarkAllRead
            label={labels?.markAllAsRead}
            onClick={() => markAllAsRead()}
          />
        ) : null}
        <CloseInbox onClick={handleCloseInbox} tooltip="Close Inbox" />
      </div>
    </Container>
  );
};

export default Header;
