import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  loader: {
    width: "24px",
    height: "24px",
    border: "2px solid rgba(0, 0, 0, 0.38)",
    borderBottomColor: "transparent",
    borderRadius: "50%",
    display: "inline-block",
    boxSizing: "border-box",
    animation: "$rotation 1s linear infinite"
  },
  "@keyframes rotation": {
    from: {
      transform: "rotate(0deg)"
    },
    to: {
      transform: "rotate(360deg)"
    }
  }
});

const LoadingCircle = () => {
  const classes = useStyles();

  return <div className={classes["loader"]} />;
};

export default LoadingCircle;
