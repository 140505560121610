import { useMutation } from "react-apollo";
import { useState } from "react";
import { Switch, makeStyles } from "@material-ui/core";
import { SET_NOTIFICATIONS_PREFERENCES } from "../../../queries/notificationPreferences";
import { useTranslation } from "react-i18next";
import StyledTooltip from "../../core/StyledTooltip";
import useEventTracking from "../../../hooks/useEventTracking";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "3px 0px 5px 32px",
    maxWidth: "400px",
    [theme.breakpoints.down("sm")]: {
      padding: "2px 0px 0px 16px"
    }
  },
  subtopicContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 0px 0px 20px",
    [theme.breakpoints.down("sm")]: {
      padding: "2px 0px 0px 16px"
    }
  },
  titleRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  subtopicTitleRow: {
    display: "flex",
    alignItems: "center",
    marginBottom: "-10px",
    justifyContent: "space-between",
    width: "100%"
  },
  title: {
    fontSize: "16px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "24px",
    letterSpacing: "0.25px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      fontSize: "14px"
    }
  },
  subtopicTitle: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#000000",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    textAlign: "left",
    [theme.breakpoints.down("sm")]: {
      flex: 1,
      fontSize: "14px"
    }
  },
  description: {
    fontSize: "14px"
  },
  descriptionContainer: {
    width: "342px",
    paddingTop: "0px",
    color: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "0px",
      width: "272px"
    }
  }
}));

export const PreferenceTopic = ({
  token,
  partnerId,
  topic,
  status,
  RAR,
  reason,
  description,
  id,
  isSubtopic = false,
  subtopics = {},
  isParentTopicActive = true
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { TrackEvent } = useEventTracking();

  const [setPreferences] = useMutation(SET_NOTIFICATIONS_PREFERENCES);

  const [statusToggle, setStatusToggle] = useState(status === "OPTED_IN");

  const handleStatusChange = () => {
    if (RAR === "required" || RAR === "restricted") {
      return;
    }

    try {
      setPreferences({
        variables: {
          token,
          partnerId,
          preferences: {
            [id]: !statusToggle
          }
        }
      }).then(() => {
        TrackEvent("Change Notification Settings", {
          type: id,
          newStatus: !statusToggle
        });
      });

      setStatusToggle(!statusToggle);
    } catch (err) {
      console.error("Error updating preferences", err);
      return err;
    }
  };
  const reasonText = getReasonText(reason, t);

  return (
    <div className={isSubtopic ? classes.subtopicContainer : classes.container}>
      <div className={isSubtopic ? classes.subtopicTitleRow : classes.titleRow}>
        <div className={isSubtopic ? classes.subtopicTitle : classes.title}>
          {topic}
        </div>
        {reasonText ? (
          <StyledTooltip text={reasonText} placement="top">
            <div
              style={{
                display: "inline-block",
                cursor: "not-allowed"
              }}
            >
              <Switch
                color="primary"
                disabled={RAR === "required" || RAR === "restricted"}
                checked={statusToggle}
                onChange={handleStatusChange}
              />
            </div>
          </StyledTooltip>
        ) : (
          <Switch
            color="primary"
            disabled={
              !isParentTopicActive || RAR === "required" || RAR === "restricted"
            }
            checked={!isParentTopicActive ? false : statusToggle}
            onChange={handleStatusChange}
          />
        )}
      </div>
      {description && (
        <div className={classes.descriptionContainer}>
          <div className={classes.description}>{description}</div>
        </div>
      )}
      {Object.keys(subtopics).length > 0 && (
        <div style={{ paddingBottom: "10px" }}>
          {Object.keys(subtopics).map(subtopic => (
            <PreferenceTopic
              token={token}
              partnerId={partnerId}
              topic={subtopics[subtopic].topic}
              status={subtopics[subtopic].status}
              description={null}
              RAR={subtopics[subtopic].RAR}
              reason={subtopics[subtopic].reason}
              id={subtopics[subtopic].id}
              isSubtopic={true}
              isParentTopicActive={statusToggle}
            />
          ))}
        </div>
      )}
    </div>
  );
};

function getReasonText(reason, t) {
  if (!reason) return null;

  if (reason.type === "contact") {
    if (reason.text === "main")
      return t("notificationSettingsTooltip_mainContact");

    if (reason.text === "billing")
      return t("notificationSettingsTooltip_billingContact");

    if (reason.text === "transport")
      return t("notificationSettingsTooltip_transportContact");

    return t("notificationSettingsTooltip_default");
  }
  if (reason.type === "role") {
    return t("notificationSettingsTooltip_role", {
      roleName: reason.text
    });
  }

  return t("notificationSettingsTooltip_default");
}
