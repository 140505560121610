import React from "react";
import classnames from "classnames";
import { makeStyles } from "@material-ui/core";
import { ReactComponent as TruckIcon } from "../../../../assets/truckIcon.svg";
const useStyles = makeStyles(theme => ({
  tagLine: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "4px"
  },
  oneSixEightCent: {
    flexBasis: "100%",
    width: "100%"
  },
  restToOneSixEightCent: {
    flexBasis: "100%",
    width: "100%"
  },
  pickUpDateFormatted: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "20px",
    lineHeight: "24px",
    letterSpacing: "0.25px"
  },
  detailGridRoot: {
    margin: "0 -10px"
  },
  detailColRoot: {
    padding: "0 10px"
  },
  flexItem: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "4px"
  },
  [theme.breakpoints.up("md")]: {
    oneSixEightCent: {
      flexBasis: "188px",
      width: "188px"
    },
    restToOneSixEightCent: {
      width: "calc(100% - 188px)",
      flexBasis: "calc(100% - 188px)",
      paddingLeft: 0
    }
  },
  pickupdate_black: {
    color: "rgba(0, 0, 0, 1)"
  },
  pickupdate_grey: {
    color: "rgba(0, 0, 0, 0.38)"
  }
}));

const DEFAULT_PARENT_CLASSES = {
  pickupdate_black: {
    color: "rgba(0, 0, 0, 1)"
  },
  pickupdate_grey: {
    color: "rgba(0, 0, 0, 0.38)"
  }
};

const PickUpHeader = ({
  pickUpDateFormatted,
  pickupName,
  cardConditions = {}
}) => {
  const classes = useStyles();
  const parentClasses = classes[cardConditions.iconDateColor];
  const blackColor = classes["pickupdate_black"];

  return (
    <>
      <div
        style={{ paddingBottom: 12 }}
        className={classnames(classes.oneSixEightCent, classes.detailColRoot)}
      >
        <div className={classes.tagLine}>
          <div
            className={classnames(
              classes.flexItem,
              classes.pickUpDateFormatted,
              parentClasses
            )}
          >
            <TruckIcon />
            {pickUpDateFormatted}
          </div>
        </div>
      </div>
      {pickupName && (
        <div
          style={{
            paddingBottom: 12
          }}
          className={classnames(
            classes.restToOneSixEightCent,
            classes.oneSixEightCent,
            classes.detailColRoot
          )}
        >
          <div
            className={classnames(
              classes.flexItem,
              classes.pickUpDateFormatted,
              blackColor
            )}
          >
            {pickupName}
          </div>
        </div>
      )}
    </>
  );
};

export default PickUpHeader;
