import EmptyPickUp from "./EmptyPickUp";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { DATE_FORMAT_STRING } from "../../utils/Format";

const EmptyPickUpView = () => {
  const { t } = useTranslation();
  const dataOpen = {
    date: moment().add(1, "days").format(DATE_FORMAT_STRING),
    materialName: t("emptyState_pickUpOpened"),
    state: t("confirmation pending"),
    cardColor: "card_orange"
  };

  const dataClosed = {
    date: moment().format(DATE_FORMAT_STRING),
    materialName: t("emptyState_pickUpClosed"),
    state: t("confirmed"),
    cardColor: "card_green"
  };

  return (
    <>
      <EmptyPickUp data={dataClosed} />
      <EmptyPickUp expandedByDefault={true} data={dataOpen} />
    </>
  );
};

export default EmptyPickUpView;
