import React from "react";

import { makeStyles, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  flexContainer: {
    display: "flex",
    justifyContent: "space-around",
    boxShadow: "inset 0px -1px 0px rgba(0, 0, 0, 0.12)",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  titleWrapper: {
    padding: "72px 16px 8px 16px"
  },
  pageTitle: {
    display: "none"
  },
  pageSubTitle: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "20px",
    letterSpacing: "0.25px",
    color: "rgba(0, 0, 0, 0.6)"
  },
  extraActions: {
    padding: "8px 16px 8px 16px"
  },
  extraActionsColumn: {
    padding: "15px 0px 0px 0px",
    color: "rgba(0, 0, 0, 0.6)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "18px"
    }
  },
  [theme.breakpoints.up("md")]: {
    flexContainer: {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between"
    },
    extraActions: {
      paddingRight: 32
    },
    pageSubTitle: {
      fontSize: "14px",
      lineHeight: "20px"
    },
    pageTitle: {
      fontSize: "24px",
      lineHeight: "28px",
      fontWeight: "400",
      color: "rgba(0, 0, 0, 0.87)",
      marginBottom: "8px",
      display: "block"
    },
    titleWrapper: {
      padding: "26px 32px"
    }
  }
}));

const AccountHeader = ({ title, subtitle, extraActions, column }) => {
  const classes = useStyles();

  return (
    <div className={classes.flexContainer}>
      <div className={classes.titleWrapper}>
        <Typography variant="h4" className={classes.pageTitle}>
          {title}
        </Typography>
        <Typography variant="subtitle1" className={classes.pageSubTitle}>
          {subtitle}
        </Typography>
        {extraActions && column && (
          <div className={classes.extraActionsColumn}>{extraActions}</div>
        )}
      </div>
      {extraActions && !column && (
        <div className={classes.extraActions}>{extraActions}</div>
      )}
    </div>
  );
};

export default AccountHeader;
