import gql from "graphql-tag";

export default gql`
  query CompanyDocuments($token: String!, $company: String!) {
    CompanyDocuments(token: $token, company: $company) {
      id
      documentName
      documentType
      downloadLink
      uploadedByMetaloop
      fileType
    }
  }
`;
