import React, { Fragment, useState } from "react";
import { withTranslation } from "react-i18next";
import { CalendarToday, Clear } from "@material-ui/icons";
import { withStyles, InputAdornment, TextField } from "@material-ui/core";
import DateDropdownDateRangePicker from "../general/DateDropdown/DateDropdownDateRangePicker";

const styles = theme => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    marginBottom: "8px",
    color: "#DEDEDE",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "baseline",
      justifyContent: "space-between",
      marginBottom: "4px"
    }
  },
  calendarWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "&>span": {
      margin: "0 10px 0 10px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%"
    }
  },
  textField: {
    cursor: "pointer"
  }
});

const TransactionStatsFilter = ({
  classes,
  startDateChanged,
  endDateChanged,
  startDate,
  endDate,
  onFilterReset,
  disabled,
  t
}) => {
  const [isDateRangePickupOpen, setDateRangePickupOpen] = useState(false);
  const [tempStartDate, setTempStartDate] = useState(null);
  const [tempEndDate, setTempEndDate] = useState(null);

  const hasDates = startDate && endDate;

  return (
    <Fragment>
      <div className={classes.wrapper}>
        <div className={classes.calendarWrapper}>
          <TextField
            placeholder={t("Pickups_SelectADateRange")}
            InputProps={{
              className: classes.textField,
              endAdornment: (
                <InputAdornment position="end">
                  {hasDates ? (
                    <Clear
                      onClick={e => {
                        e.stopPropagation();
                        onFilterReset();
                      }}
                    />
                  ) : (
                    <CalendarToday />
                  )}
                </InputAdornment>
              )
            }}
            disabled={disabled}
            variant="outlined"
            onClick={() => !disabled && setDateRangePickupOpen(true)}
            value={hasDates ? `${startDate} - ${endDate}` : ""}
          />
        </div>
      </div>
      <DateDropdownDateRangePicker
        type="dateRange"
        dateOptions={[
          {
            value: "dateRange",
            callback: () => {
              setDateRangePickupOpen(true);
            },
            start: startDate,
            end: endDate
          }
        ]}
        setDateOptions={() => {}}
        startDate={startDate}
        setStartDate={startDateChanged}
        tempStartDate={tempStartDate}
        setTempStartDate={setTempStartDate}
        tempEndDate={tempEndDate}
        setTempEndDate={setTempEndDate}
        endDate={endDate}
        setEndDate={endDateChanged}
        open={isDateRangePickupOpen}
        setOpen={setDateRangePickupOpen}
        setIsOpen={() => {}}
        setDate={() => {}}
      />
    </Fragment>
  );
};

export default withStyles(styles)(withTranslation()(TransactionStatsFilter));
