import React from "react";
import { withTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
const styles = theme => ({
  title: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "34px",
    lineHeight: "40px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "rgba(0, 0, 0, 0.87)",
    marginTop: "24px",
    marginBottom: "24px",
    "@media (max-width: 400px)": {
      fontSize: "20px",
      lineHeight: "24px",
      marginTop: "12px"
    }
  }
});

class Title extends React.Component {
  render() {
    const { classes, text, t, extraClasses } = this.props;
    return (
      <Typography
        align="center"
        variant="subtitle1"
        className={`${classes.title} ${extraClasses}`}
      >
        {t(text)}
      </Typography>
    );
  }
}

Title.defaultProps = {
  text: ""
};

Title.propTypes = {
  text: PropTypes.string
};

export default withStyles(styles)(withTranslation()(Title));
