import gql from "graphql-tag";

//TODODO: change to SaveCategoriesForBuyer
const ADD_CATEGORIES_TO_BUYER = gql`
  mutation saveCategoriesForBuyer(
    $categories: [BuyerPriceCategoriesInput]!
    $buyerId: String!
  ) {
    saveCategoriesForBuyer(categories: $categories, buyerId: $buyerId) {
      message
    }
  }
`;

const SAVE_ONE_PRICE_FOR_BUYER = gql`
  mutation saveOnePriceForBuyer(
    $buyerId: String!
    $productId: String!
    $priceData: [BuyerPriceMaterialsInput]!
  ) {
    saveOnePriceForBuyer(
      buyerId: $buyerId
      productId: $productId
      priceData: $priceData
    ) {
      message
    }
  }
`;

const SAVE_ONE_CATEGORY_TO_BUYER = gql`
  mutation saveOneCategoryForBuyer(
    $category: BuyerPriceCategoriesInput
    $buyerId: String!
  ) {
    saveOneCategoryForBuyer(category: $category, buyerId: $buyerId) {
      message
    }
  }
`;

export {
  ADD_CATEGORIES_TO_BUYER,
  SAVE_ONE_PRICE_FOR_BUYER,
  SAVE_ONE_CATEGORY_TO_BUYER
};
