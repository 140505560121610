import React from "react";

import {
  MenuItem,
  makeStyles,
  Chip,
  Select,
  FormControl,
  InputLabel,
  OutlinedInput,
  Box,
  FormHelperText
} from "@material-ui/core";
import { SelectMenuProps } from "../../core/shared/constants";

export const DropdownWithPills = ({
  isDisabled = false,
  label = "",
  arrOfOptions = [],
  arrOfSelected = [],
  handleChange = () => {},
  error = false,
  errorMessage = "",
  multiple = true
}) => {
  const useStyles = makeStyles({
    componentWrapper: {
      width: "100%",
      position: "relative"
    },
    inputOutlined: {
      height: "24px",
      display: "flex",
      alignContent: "center"
    },
    chips: {
      height: "24px"
    },
    placeholder: { transform: "translate(15px, 12px) scale(1)" }
  });

  const classes = useStyles();

  const getLabel = value =>
    arrOfOptions.find(option => option.key === value)?.label || value;

  return (
    <div className={classes.componentWrapper}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel id="selectComponentLabel" className={classes.placeholder}>
          {label}
        </InputLabel>
        <Select
          disabled={isDisabled}
          className={classes.componentWrapper}
          labelId="selectComponentLabel"
          id="selectWithChips"
          multiple={multiple}
          value={
            arrOfSelected.length
              ? arrOfSelected.filter(s => arrOfOptions.find(ao => ao.key === s))
              : []
          }
          onChange={handleChange}
          input={
            <OutlinedInput
              id="select-multiple-chip"
              label={label}
              classes={{ input: classes.inputOutlined }}
            />
          }
          renderValue={selected => (
            <Box sx={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
              {selected.slice(0, 2).map(value => (
                <Chip
                  key={value}
                  label={getLabel(value)}
                  className={classes.chips}
                />
              ))}
              {selected.length > 2 ? "..." : ""}
            </Box>
          )}
          MenuProps={SelectMenuProps}
          error={error}
        >
          {arrOfOptions.map(option => (
            <MenuItem key={option.key} value={option.key}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
        {error ? <FormHelperText error>{errorMessage}</FormHelperText> : null}
      </FormControl>
    </div>
  );
};
